<template>
    <form
        class="pb-5 mt-5 grid-cols-layout lg:grid"
        @submit.prevent="updateHtmlMinifiedSettings"
    >
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Minified HTML
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                Choose if you want to minify the html output.
            </p>
        </div>
        <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
            <div class="border-b border-gray-200 px-6 pb-5 pt-[23px]">
                <div class="flex flex-wrap justify-start gap-6 pb-5 pt-[23px]">
                <RadioInput
                    :model-value="minifyHtml"
                    label="Minify HTML"
                    :option="true"
                    name="Html minified"
                    @update:modelValue="minifyHtml = true"
                />
                <RadioInput
                    :model-value="minifyHtml"
                    label="Do not minify HTML"
                    :option="false"
                    name="Html minified"
                    @update:modelValue="minifyHtml = false"
                />
            </div>
                       
            </div>

            <div class="flex justify-end gap-4 px-6 py-3">
                    <AtomicButton
                        :disabled="minifyHtml === props.modelValue"
                        type="submit"
                        color="yellow"
                        size="sm"
                    >
                        Save changes
                    </AtomicButton>
            </div>
        </div>
    </form>
</template>
<script lang="ts" setup>

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const minifyHtml = ref(props.modelValue);

const updateHtmlMinifiedSettings = () => {
    emit('update:modelValue', minifyHtml.value);
};

</script>
