<template>
    <div class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                API token
            </h3>
            <p class="max-w-xs text-sm text-gray-500">Your Topolio API token</p>
        </div>

        <div class="mx-auto w-full rounded-md bg-white shadow-card lg:mx-0">
            <div class="mt-2 flex w-full border-b border-gray-200 p-6">
                <div class="text-gray-500 sm:w-2/12">API&nbsp;token:</div>
                <div
                    class="ml-2 flex max-w-xs overflow-hidden text-clip whitespace-nowrap text-gray-800 sm:ml-0 sm:w-10/12"
                >
                    <span v-for="key in globalProps.user.token" :key="key">
                        •
                    </span>
                </div>
            </div>

            <div class="flex justify-end">
                <div class="my-3 pr-6">
                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="sm"
                        @click="showCopyTokenModal = true"
                    >
                        Show token
                    </AtomicButton>
                </div>
            </div>
        </div>

        <CopyTokenToClipboard
            v-if="showCopyTokenModal"
            @close="showCopyTokenModal = false"
        />
    </div>
</template>

<script lang="ts" setup>
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

const showCopyTokenModal = ref(false);
const globalProps = computed(() => usePage<ITopolPageProps>().props);
</script>
