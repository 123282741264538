<template>
    <div class="py-12">
        <h1
            class="text-2xl font-medium leading-normal tracking-small text-gray-800"
        >
            Categories
        </h1>

        <inertia-link :href="route('kiosk.premade-templates.index')">
            <AtomicButton class="mr-2" color="yellow" size="sm">
                Back to Premade Templates
                <template #icon>
                    <BackspaceIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <AtomicButton
            class="mt-4 mb-2 mr-2"
            size="sm"
            color="yellow"
            @click="createNew = true"
        >
            Add
            <template #icon>
                <PlusCircleIcon
                    class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                />
            </template>
        </AtomicButton>

        <form
            v-if="createNew"
            class="flex flex-col"
            @submit.prevent="saveCategory"
        >
            <TextInput
                v-model="v$.form.name.$model"
                class="mt-5 w-52 sm:w-80"
                label="Name"
                type="text"
                placeholder="max 255 characters"
                :error="getError('name')"
                required
            />

            <div class="flex flex-row">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="sm"
                    class="mt-6 mr-2"
                    :disabled="loading || v$.$invalid"
                >
                    Save
                </AtomicButton>

                <AtomicButton
                    v-if="createNew"
                    color="white"
                    size="sm"
                    class="mt-6"
                    @click.prevent="handleClose"
                >
                    Cancel
                </AtomicButton>
            </div>
        </form>

        <DataTable
            v-if="categories.data.length"
            class="mt-10"
            :items="categories"
            :headers="headers"
        >
            <template #item-custom_edit="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.premade-templates.categories.edit', {
                            premadeTemplateCategory: row.id,
                        })
                    "
                >
                    <AtomicButton size="sm" color="white">
                        Edit
                        <template #icon>
                            <PencilSquareIcon
                                class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-custom_delete="{ row }">
                <AtomicButton
                    size="sm"
                    color="red"
                    @click="deleteCategory(row)"
                >
                    <template #icon>
                        <TrashIcon
                            class="h-6 w-6 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';

import route from 'ziggy-js';
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    BackspaceIcon,
} from '@heroicons/vue/24/outline';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import { router } from '@inertiajs/vue3';
import { reactive, ref } from 'vue';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { create } from '@/composables/useNotifications';
import ICategory from '@/types/Templates/ICategory';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import useConfirm from '@/composables/useConfirm';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';

defineProps<{
    categories: IPaginationData<ICategory>;
}>();

const createNew = ref(false);
const loading = ref(false);
const { show } = useConfirm();

const errors = ref<IInputErrors>({});

const state = reactive({
    form: {
        name: '',
    },
});

const rules = {
    form: {
        name: { required },
    },
};

const v$ = useVuelidate(rules, state);

const headers: IDataTableHeader[] = [
    {
        text: 'ID',
        id: 'id',
        sortable: true,
        sortableId: 'id',
    },
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'title',
    },
    { text: '', id: 'custom_edit' },
    { text: '', id: 'custom_delete' },
];

const saveCategory = () => {
    v$.value.form.name.$touch();
    if (v$.value.form.name.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('kiosk.premade-templates.categories.store'),
            state.form,
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'New category created',
                        type: 'success',
                    });
                    errors.value = {};
                    v$.value.$reset();
                    createNew.value = false;
                    state.form.name = '';
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Category not created',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const deleteCategory = async (category: ICategory) => {
    const confirmed = await show({
        header: 'Delete premade category',
        message: `Are you sure you want to delete category ${category.title}?`,
        type: 'danger',
        confirm: 'Delete category',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.premade-templates.categories.delete', {
                premadeTemplateCategory: category.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Category deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Category not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const handleClose = () => {
    v$.value.$reset();
    state.form.name = '';
    createNew.value = false;
    errors.value = {};
};
</script>

<style></style>
