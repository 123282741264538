<template>
    <div class="grid-cols-layout lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                <slot name="title" />
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                <slot name="description" />
            </p>
        </div>

        <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
            <div class="p-6" :class="{'border-b border-gray-200': hasAction}">
                <slot name="content" />

            </div>
            <div v-if="hasAction" class="p-3">
                <slot name="action" />

            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

const slots = useSlots();

const hasAction = computed(() => {
    return !!slots['action'];
});
</script>
