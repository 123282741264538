import { ref } from 'vue';
import axios from 'axios';
import route from 'ziggy-js';

const shouldDisplayProTrialBanner = ref(false);
const shouldDisplayPluginTrialBanner = ref(false);
const shouldDisplayPluginSubscriptionBanner = ref(false);
const shouldDisplayProPersonalSubscriptionBanner = ref(false);
const shouldDisplayProTeamSubscriptionBanner = ref(false);
const shouldDisplayBlackFridayDeal = ref(false);
const isPluginSubscribed = ref(false);
const isSubscribed = ref(false);
const shouldDisplayDeletedBanner = ref(false);

export const useBanners = () => {
    const fetchBanners = async () => {
        try {
            const res = await axios.get(route('user.banners.index'));

            if (res.data.success) {
                shouldDisplayProTrialBanner.value =
                    res.data.data.proTrialBanner;
                shouldDisplayProPersonalSubscriptionBanner.value =
                    res.data.data.proPersonalSubscriptionBanner;
                shouldDisplayProTeamSubscriptionBanner.value =
                    res.data.data.proTeamSubscriptionBanner;
                shouldDisplayPluginTrialBanner.value =
                    res.data.data.pluginTrialBanner;
                shouldDisplayPluginSubscriptionBanner.value =
                    res.data.data.pluginSubscriptionBanner;
                isPluginSubscribed.value = res.data.data.isPluginSubscribed;
                shouldDisplayBlackFridayDeal.value =
                    res.data.data.shouldDisplayBlackFridayDeal;
                shouldDisplayDeletedBanner.value = res.data.data.deletedBanner;
                isSubscribed.value = res.data.data.isSubscribed;
            }
        } catch (err) {
            () => undefined;
        }
    };

    return {
        fetchBanners,
        shouldDisplayProTrialBanner,
        shouldDisplayProPersonalSubscriptionBanner,
        shouldDisplayProTeamSubscriptionBanner,
        shouldDisplayPluginTrialBanner,
        shouldDisplayPluginSubscriptionBanner,
        shouldDisplayDeletedBanner,
        isPluginSubscribed,
        isSubscribed,
        shouldDisplayBlackFridayDeal,
    };
};

export default useBanners;
