<template>
    <div class="py-12">
        <h1
        v-if="title"
            class="text-2xl font-medium leading-normal text-gray-800 tracking-small"
        >
            {{ title }}
        </h1>

        <slot name="header" />

        <div class="relative grid">
            <div
                class="absolute bottom-0 w-full border-b border-gray-300"
            ></div>
            
            <div
                class="relative mt-[21px] flex flex-col border-b border-gray-300 pb-4 lg:h-[46px] lg:flex-row lg:border-b-0 lg:pb-0 overflow-x-auto"
            >
                <li
                    v-for="tab in tabs"
                    :key="tab.key"
                    class="z-10 whitespace-nowrap flex cursor-pointer select-none items-center px-2 py-2.5 focus:outline-none lg:px-4 xl:px-6"
                    :class="{
                        ' rounded-r border-l-2 border-orange-500 bg-gray-200 pl-[6px] lg:rounded-r-none lg:border-l-0 lg:border-b-2 lg:bg-gray-100 lg:px-4 lg:pb-2 xl:px-6':
                            tab.key === tabKey,
                    }"
                    @click="navigateTo(tab)"
                >
                    <component
                        :is="tab.icon"
                        class="w-5 transition duration-200 ease-in-out"
                        :class="{
                            'text-gray-600': tab.key === tabKey,
                            'text-gray-400': !(tab.key === tabKey),
                        }"
                    ></component>
    
                    <h2
                        class="ml-3 font-medium transition duration-200 ease-in-out lg:ml-2 xl:ml-3"
                        :class="{
                            'text-gray-600': tab.key === tabKey,
                            'text-gray-500': !(tab.key === tabKey),
                        }"
                    >
                        {{ tab.title }}
                    </h2>
                    
                    <span v-if="tab.new_feature" class="relative">
                        <span class="absolute transform -translate-y-1/2 left-3 top-1/2">
                            <NewFeatureIndicator
                            />
                        </span>
                    </span>
                </li>
            </div>
        </div>
        <slot />
    </div>
</template>

<script lang="ts" setup>
import TabItem from '@/types/Settings/TabItem';
import { router } from '@inertiajs/vue3';
import { PropType } from 'vue';

defineProps({
    title: {
        type: String,
        default: '',
    },
    tabKey: {
        type: String,
        default: '',
    },
    tabs: {
        type: Array as PropType<TabItem[]>,
        default: () => [],
    },
});

const navigateTo = (tab: TabItem) => {
    router.visit(tab.url, {
        preserveScroll: true,
    });
};
</script>
