<template>
    <div v-if="preparedSubscription !== undefined">
        <Modal v-if="paymentOpened" size="xxl" @close="$emit('close')">
            <template #header>
                <div v-if="order" class="text-gray-600">Your order</div>
                <div v-if="checkout" class="text-gray-600">
                    Add payment method
                </div>
            </template>

            <template #body>
                <div v-if="preparedSubscription" class="p-6 bg-white">
                    <div
                        v-if="order"
                        class="flex flex-col items-stretch justify-between md:flex-row"
                    >
                        <div
                            class="w-full rounded-md border border-gray-300 px-6 pt-6 pb-5 md:mr-2 md:w-1/2 lg:mr-4 lg:w-full lg:max-w-[462px]"
                        >
                            <h4
                                class="text-lg font-medium leading-[18px] tracking-tighter text-gray-800"
                            >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .type === 'pro' && personal
                                    "
                                    >Topol PRO Subscription</span
                                >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .type === 'pro' && !personal
                                    "
                                    >Topol Team Subscription</span
                                >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan === 'plugin_50'
                                    "
                                    >Topol Plugin for Startups</span
                                >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan === 'plugin_1000'
                                    "
                                    >Topol Plugin for Business</span
                                >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan ===
                                            'plugin_unlimited_monthly' ||
                                        preparedSubscription.subscription_plan
                                            .braintree_plan ===
                                            'plugin_unlimited_yearly'
                                    "
                                    >Topol Plugin Unlimited</span
                                >
                            </h4>

                            <p class="mt-[5px] max-w-sm text-sm text-gray-500">
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .type === 'pro'
                                    "
                                >
                                    <span v-if="!personal"
                                        >3 additional team seats and
                                    </span>
                                    50GB monthly data traffic is included in
                                    Topol
                                    {{ personal ? 'PRO' : 'Team' }} Plan.</span
                                >
                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan === 'plugin_50'
                                    "
                                    >Perfect fit for projects with growing user
                                    count. 50 users included in plan, additional
                                    user for $1.20/month. Paid monthly.</span
                                >

                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan === 'plugin_1000'
                                    "
                                    >Solution for projects with an established
                                    user base. 1000 users included in plan,
                                    additional user for $0.30/month. Paid
                                    monthly.</span
                                >

                                <span
                                    v-if="
                                        preparedSubscription.subscription_plan
                                            .braintree_plan ===
                                            'plugin_unlimited_monthly' ||
                                        preparedSubscription.subscription_plan
                                            .braintree_plan ===
                                            'plugin_unlimited_yearly'
                                    "
                                    >No limits. Fixed price, no hidden fees and
                                    unexpected costs. Unlimited users
                                    included.</span
                                >
                            </p>

                            <p
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan === 'plugin_50' ||
                                    preparedSubscription.subscription_plan
                                        .braintree_plan === 'plugin_1000'
                                "
                                class="max-w-sm mt-5 text-sm text-gray-500"
                            >
                                Additional users above plan are billed extra on
                                the end of billing period.
                            </p>

                            <div
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .type === 'pro'
                                "
                                class="mt-[22px] border-gray-300 pb-5"
                                :class="{ 'border-b': !personal }"
                            >
                                <div class="relative">
                                    <RadioInput
                                        v-model="period"
                                        option="year"
                                        name="period"
                                        label="Annual payment"
                                        size="lg"
                                    />
                                    <div
                                        class="absolute top-0 flex gap-2 left-40"
                                    >
                                        <div
                                            class="rounded-md bg-orange-500 px-2 py-[3px] text-center text-xs font-medium leading-normal text-gray-900 sm:block"
                                        >
                                            <span>SAVE 33%</span>
                                        </div>
                                        <!-- <div
                                            v-if="personal"
                                            class="rounded-md bg-black px-2 py-[3px] text-center text-xs uppercase font-bold tracking-wide leading-normal text-white sm:block"
                                        >
                                            <span>Black friday deal</span>
                                        </div> -->
                                    </div>
                                </div>
                                <RadioInput
                                    v-model="period"
                                    option="month"
                                    name="period"
                                    class="mt-3"
                                    label="Month-to-month payment"
                                    size="lg"
                                />
                            </div>

                            <div
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .type === 'pro' && !personal
                                "
                                class="mt-4"
                            >
                                <div class="text-sm font-medium text-gray-700">
                                    Add additional team seats
                                </div>

                                <div class="mt-[7px]">
                                    <div
                                        class="grid w-40 h-10 grid-cols-4 border border-gray-300 rounded-md shadow-button"
                                    >
                                        <div
                                            class="flex items-center justify-center border-r border-gray-300 cursor-pointer"
                                            @click="seats > 0 ? seats-- : null"
                                        >
                                            <MinusCircleIcon
                                                class="w-4 h-4 text-gray-500"
                                            />
                                        </div>
                                        <input
                                            v-model.number="seats"
                                            type="number"
                                            min="0"
                                            max="5000"
                                            class="col-span-2 text-sm text-center text-gray-600 select-te focus:outline-none"
                                        />
                                        <div
                                            class="flex items-center justify-center border-l border-gray-300 cursor-pointer"
                                            @click="seats++"
                                        >
                                            <PlusCircleIcon
                                                class="w-4 h-4 text-gray-500"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        class="mt-3 max-w-[420px] select-none text-sm text-gray-500"
                                    >
                                        <span
                                            v-if="
                                                preparedSubscription
                                                    .subscription_plan.type ===
                                                'pro'
                                            "
                                        >
                                        </span
                                        >You can add new members to your teams
                                        at any time. If you previously chose the
                                        annual plan, additional team members
                                        will be charged monthly at $15/mo until
                                        the annual plan is up for renewal
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan ===
                                        'plugin_unlimited_monthly' ||
                                    preparedSubscription.subscription_plan
                                        .braintree_plan ===
                                        'plugin_unlimited_yearly'
                                "
                                class="mt-5"
                            >
                                <div class="pb-5">
                                    <RadioInput
                                        v-model="period"
                                        option="month"
                                        name="period"
                                        class="mb-3"
                                        label="Month-to-month payment"
                                        size="lg"
                                    />
                                    <div class="relative">
                                        <RadioInput
                                            v-model="period"
                                            option="year"
                                            name="period"
                                            label="Annual payment"
                                            size="lg"
                                        />
                                        <div
                                            class="absolute left-40 top-0 hidden rounded-md bg-orange-500 px-2 py-[3px] text-center text-xs font-medium leading-normal text-gray-900 sm:block"
                                        >
                                            <span>SAVE 17% </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="mt-6 w-full rounded-md border border-blue-200 bg-blue-100 px-6 pt-6 pb-5 md:mt-0 md:ml-2 md:w-1/2 md:px-9 lg:ml-4 lg:w-full lg:max-w-[418px]"
                        >
                            <h4
                                class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
                            >
                                Summary
                            </h4>
                            <div
                                class="mt-4 flex items-start justify-between pb-[14px]"
                            >
                                <div>
                                    <p
                                        class="text-sm font-medium leading-normal text-gray-800"
                                    >
                                        Topol
                                        {{
                                            preparedSubscription
                                                .subscription_plan.name
                                        }}
                                    </p>
                                    <p
                                        v-if="
                                            preparedSubscription
                                                .subscription_plan.type ===
                                                'pro' && !personal
                                        "
                                        class="mt-[3px] text-sm leading-normal text-gray-500"
                                    >
                                        with
                                        {{
                                            additionalSeatsToPay >= 0
                                                ? additionalSeatsToPay
                                                : 0
                                        }}
                                        extra team seats (+3 seats free)
                                    </p>
                                    <p
                                        class="mt-[3px] text-sm leading-normal text-gray-500"
                                    >
                                        <span
                                            v-if="
                                                preparedSubscription
                                                    .subscription_plan
                                                    .braintree_plan ===
                                                'plugin_50'
                                            "
                                        >
                                            50
                                        </span>
                                        <span
                                            v-if="
                                                preparedSubscription
                                                    .subscription_plan
                                                    .braintree_plan ===
                                                'plugin_1000'
                                            "
                                        >
                                            1000
                                        </span>
                                        <span
                                            v-if="
                                                preparedSubscription
                                                    .subscription_plan
                                                    .braintree_plan ===
                                                    'plugin_unlimited_monthly' ||
                                                preparedSubscription
                                                    .subscription_plan
                                                    .braintree_plan ===
                                                    'plugin_unlimited_yearly'
                                            "
                                        >
                                            Unlimited
                                        </span>
                                        <span
                                            v-if="
                                                preparedSubscription
                                                    .subscription_plan.type ===
                                                'plugin'
                                            "
                                        >
                                            users included in plan.
                                        </span>
                                    </p>

                                    <p
                                        class="mt-[3px] text-sm leading-normal text-gray-500"
                                    >
                                        {{
                                            period === 'year'
                                                ? 'Annual'
                                                : 'Monthly'
                                        }}
                                        plan renews

                                        {{
                                            format(
                                                new Date(date),
                                                'MMMM d, yyyy'
                                            )
                                        }}
                                    </p>
                                </div>

                                <div
                                    class="text-sm font-medium leading-normal text-gray-800"
                                >
                                    ${{
                                        preparedSubscription.real_total_without_sale +
                                        discount
                                    }}/{{ period }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan ===
                                    'plugin_unlimited_yearly'
                                "
                                class="-mt-1 flex justify-between pb-[14px] text-sm font-bold text-gray-800"
                            >
                                <p>Discount for yearly payment</p>
                                <p>-${{ discount }}/year</p>
                            </div>

                            <!-- <div
                                v-if="
                                    preparedSubscription.discount !== 0
                                "
                                class="-mt-1 flex justify-between pb-[14px] text-sm font-bold text-gray-800"
                            >
                                <p>BLACK FRIDAY</p>
                                <p>-${{ preparedSubscription.real_discount_with_tax }}</p>
                            </div> -->

                            <div class="pt-5 border-t border-blue-200">
                                <div class="flex justify-between">
                                    <div
                                        class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
                                    >
                                        Total due today
                                    </div>
                                    <div
                                        class="text-lg font-bold text-gray-800"
                                    >
                                        ${{ preparedSubscription.total / 100 }}
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <button
                                        class="h-15 w-full rounded-md bg-[#4299E1] px-2 text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2"
                                        @click="handleSubmit"
                                    >
                                        Continue to checkout
                                    </button>

                                    <div
                                        class="pb-3 mt-5 text-xs text-gray-500"
                                    >
                                        You’ll be charged for individual
                                        products at the end of their free
                                        trial(s). Your subscription will
                                        continue until you cancel.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SubscriptionBuyModal
                        v-if="checkout"
                        ref="SubscriptionBuyModal"
                        :prepared-subscription="preparedSubscription"
                        :seats="seats"
                        :subscription-plan-id="newSubscriptionPlanId"
                        :billing-address="preparedSubscription.billing_address"
                        :countries="countries"
                        :personal="personal"
                        @success="
                            successModal = true;
                            paymentOpened = false;
                        "
                        @error="openErrorModal"
                        @updated-billing="getPreparedSubscription"
                    />
                </div>
            </template>
        </Modal>

        <SubscriptionBuyErrorModal
            v-if="errorModal"
            :message="errorModalMessage"
            @close="reopenOrderModal"
        />

        <SubscriptionBuySuccessModal
            v-if="successModal"
            :plan="subscriptionPlanId"
            @close="handleCloseSuccessModal"
        />
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import axios from 'axios';
import route from 'ziggy-js';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/solid';

import IApiResponse from '@/types/IApiResponse';
import PreparedSubscription from '@/types/Subscription/PreparedSubscription';
import { add, format } from 'date-fns';
import ISelectable from '@/types/Atomic/ISelectable';

const props = defineProps({
    subscriptionPlanId: {
        required: true,
        type: Number,
    },
    countries: {
        required: true,
        type: Array as PropType<ISelectable[]>,
    },
    personal: {
        required: true,
        type: Boolean,
    },
});

const emit = defineEmits(['close', 'reopen']);

const preparedSubscription = ref<PreparedSubscription>();

const period = ref('month');
const seats = ref(0);
const order = ref(true);
const checkout = ref(false);
const errorModal = ref(false);
const errorModalMessage = ref('');
const successModal = ref(false);
const newSubscriptionPlanId = ref(props.subscriptionPlanId);
const paymentOpened = ref(true);
const additionalSeatsToPay = ref(0);

const date = computed(() => {
    if (period.value === 'month') {
        return add(new Date(), {
            months: 1,
        });
    } else return add(new Date(), { years: 1 });
});

const discount = computed(() => {
    if (!preparedSubscription.value) return 0;

    return preparedSubscription.value.subscription_plan.braintree_plan ===
        'plugin_unlimited_yearly'
        ? 1200 * (1 + preparedSubscription.value.tax_rate / 100)
        : 0;
});

watch(
    () => period.value,
    (newValue) => {
        if (newValue === 'year') {
            if (preparedSubscription.value?.subscription_plan.type === 'pro') {
                newSubscriptionPlanId.value = props.personal ? 1 : 9;
            }
            if (
                preparedSubscription.value?.subscription_plan.type === 'plugin'
            ) {
                newSubscriptionPlanId.value = 7;
            }
        }
        if (newValue === 'month') {
            if (preparedSubscription.value?.subscription_plan.type === 'pro') {
                newSubscriptionPlanId.value = props.personal ? 2 : 10;
            }
            if (
                preparedSubscription.value?.subscription_plan.type === 'plugin'
            ) {
                newSubscriptionPlanId.value = 6;
            }
        }
        getPreparedSubscription();
    }
);

watch(
    () => seats.value,
    (newValue) => {
        if (newValue >= 0 && newValue <= 3000) {
            getPreparedSubscription();
        } else if (newValue > 3000) {
            seats.value = 3000;
        } else {
            seats.value = 0;
        }
    }
);

onMounted(async () => {
    await getPreparedSubscription();
});

const getPreparedSubscription = async () => {
    try {
        const res = await axios.get<IApiResponse<PreparedSubscription>>(
            route('subscriptions.prepare', {
                subscription_plan_id: newSubscriptionPlanId.value,
                additional_users: seats.value,
            })
        );
    
        preparedSubscription.value = res.data.data;
        additionalSeatsToPay.value = res.data.data.additional_users_to_pay_count;
    } catch (error) {
        () => undefined;
    }
};

const handleSubmit = () => {
    order.value = false;
    checkout.value = true;
};

const handleCloseSuccessModal = () => {
    emit('close');
};

const reopenOrderModal = () => {
    emit('reopen');
};

const openErrorModal = (message: string) => {
    errorModal.value = true;
    errorModalMessage.value = message;
    paymentOpened.value = false;
};
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
