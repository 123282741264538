<template>
    <div
        v-click-away="handleCloseMenu"
        tabindex="0"
        class="z-20 ml-4 focus:outline-none md:ml-5"
        data-cy="team-menu"
        @keydown.esc="isOpen = false"
    >
        <div
            class="flex max-w-[180px] cursor-pointer items-center sm:max-w-[224px]"
            data-cy="team-menu-current"
            @click="handleProfileMenu"
        >
            <div
                class="h-[30px] w-[30px] shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
            >
                <img
                    :src="
                        current.profile_photo_path
                            ? current.profile_photo_path
                            : current.profile_photo_url
                    "
                    class="h-[30px] w-[30px] rounded-full bg-white object-cover"
                />
            </div>

            <div
                class="ml-2 mr-[10px] select-none truncate text-sm font-medium text-white md:ml-4 md:mr-[38px]"
            >
                {{ current.name }}
            </div>
            <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="shrink-0"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.00003 0C4.26523 5.66374e-05 4.51954 0.105451 4.70703 0.293L7.70703 3.293C7.88919 3.4816 7.98998 3.7342 7.9877 3.9964C7.98543 4.2586 7.88026 4.50941 7.69485 4.69482C7.50944 4.88023 7.25863 4.9854 6.99643 4.98767C6.73423 4.98995 6.48163 4.88916 6.29303 4.707L4.00003 2.414L1.70703 4.707C1.51843 4.88916 1.26583 4.98995 1.00363 4.98767C0.741432 4.9854 0.49062 4.88023 0.305212 4.69482C0.119804 4.50941 0.0146347 4.2586 0.0123563 3.9964C0.0100779 3.7342 0.110873 3.4816 0.293031 3.293L3.29303 0.293C3.48052 0.105451 3.73484 5.66374e-05 4.00003 0ZM0.293031 9.293C0.480558 9.10553 0.734866 9.00021 1.00003 9.00021C1.26519 9.00021 1.5195 9.10553 1.70703 9.293L4.00003 11.586L6.29303 9.293C6.48163 9.11084 6.73423 9.01005 6.99643 9.01233C7.25863 9.0146 7.50944 9.11977 7.69485 9.30518C7.88026 9.49059 7.98543 9.7414 7.9877 10.0036C7.98998 10.2658 7.88919 10.5184 7.70703 10.707L4.70703 13.707C4.5195 13.8945 4.26519 13.9998 4.00003 13.9998C3.73487 13.9998 3.48056 13.8945 3.29303 13.707L0.293031 10.707C0.10556 10.5195 0.000244141 10.2652 0.000244141 10C0.000244141 9.73484 0.10556 9.48053 0.293031 9.293Z"
                    fill="#D2D6DC"
                />
            </svg>
        </div>

        <transition
            enter-active-class="transition duration-1000 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="isOpen"
                class="fixed top-[56px] left-0 z-[498] h-full w-[calc(100vw)] overflow-y-scroll bg-gray-800 bg-opacity-30 backdrop-blur-[2px] backdrop-filter md:hidden"
                @click.self="handleCloseMenu"
            ></div>
        </transition>

        <transition
            enter-active-class="transition duration-500 ease-out delay-100 md:duration-100"
            enter-from-class="transform translate-y-40 opacity-0 md:scale-95 md:translate-y-0"
            enter-to-class="transform translate-y-0 opacity-100 md:scale-100"
            leave-active-class="transition duration-200 ease-in md:duration-75"
            leave-from-class="transform opacity-100 md:scale-100"
            leave-to-class="transform translate-y-40 opacity-0 md:scale-95 md:translate-y-0"
        >
            <div
                v-if="isOpen"
                v-touch:swipe.bottom="handleCloseMenu"
                class="fixed inset-x-0 bottom-0 z-[499] mx-auto w-screen max-w-[640px] rounded-t-xl bg-white shadow-lg focus:outline-none md:absolute md:bottom-auto md:left-15 md:ml-0 md:mt-2 md:w-[336px] md:max-w-none md:origin-top-right md:rounded-md md:ring-1 md:ring-black md:ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                <div class="relative mx-auto divide-y divide-gray-200">
                    <div
                        role="none"
                        data-cy="team-menu-personal"
                        @click="switchToPersonalTeam"
                    >
                        <div
                            class="mt-[27px] select-none px-6 text-xs uppercase text-gray-400 md:mt-[14px]"
                        >
                            Personal Account
                        </div>

                        <div
                            class="group flex cursor-pointer items-center justify-between pt-[18px] pb-3 pl-6 pr-6 hover:bg-gray-50 hover:text-gray-900 md:pt-[11px] md:pr-4"
                        >
                            <div class="flex items-center">
                                <div
                                    class="h-[30px] w-[30px] flex-shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                                >
                                    <img
                                        :src="
                                            userProps.profile_photo_path
                                                ? userProps.profile_photo_path
                                                : userProps.profile_photo_url
                                        "
                                        class="h-[30px] w-[30px] rounded-full object-cover"
                                    />
                                </div>

                                <p
                                    class="ml-2 max-w-[180px] truncate text-sm font-medium text-gray-600 md:ml-4"
                                >
                                    {{ userProps.name }}
                                </p>
                            </div>
                            <div
                                class="text-gray-400 transition duration-200 ease-in-out group-hover:text-gray-600"
                                @click.stop="
                                    switchToPersonalTeamRedirectToSettings
                                "
                            >
                                <Cog8ToothIcon class="w-6 h-6" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="filteredTeamData.length"
                        class="custom-scroll mb-[7px] max-h-[140px] overflow-y-scroll pt-2.5 md:max-h-full md:overflow-hidden"
                        role="none"
                    >
                        <div class="px-6 text-xs text-gray-400 uppercase">
                            Teams
                        </div>

                        <div
                            v-for="(team, i) in filteredTeamData"
                            :key="i"
                            class="flex items-center justify-between py-3 pl-6 pr-6 cursor-pointer group hover:bg-gray-50 hover:text-gray-900 md:pr-4"
                            data-cy="team-menu-team"
                            @click="switchTeam(team.id)"
                        >
                            <div class="flex items-center">
                                <div
                                    class="h-[30px] w-[30px] flex-shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                                >
                                    <img
                                        :src="
                                            team.profile_photo_path
                                                ? team.profile_photo_path
                                                : team.profile_photo_url
                                        "
                                        class="h-[30px] w-[30px] rounded-full object-cover"
                                    />
                                </div>

                                <p
                                    class="ml-2 text-sm font-medium text-gray-500 md:ml-4"
                                >
                                    {{ team.name }}
                                </p>
                                <div
                                    v-if="team.user_id === userProps.id"
                                    data-cy="team-menu-owner-badge"
                                    class="mx-2.5 w-[69px] flex-shrink-0 rounded-2lg bg-[#E5EDFF] py-1 text-center md:mr-0"
                                >
                                    <p
                                        class="text-xs font-medium uppercase text-[#6875F5]"
                                    >
                                        Owner
                                    </p>
                                </div>
                            </div>
                            <div
                                class="text-gray-400 transition duration-200 ease-in-out group-hover:text-gray-500"
                                @click.stop="
                                    switchTeamRedirectToSettings(team.id)
                                "
                            >
                                <Cog8ToothIcon class="w-6 h-6" />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="invitations && invitations.length"
                        class="mb-[7px] max-h-[140px] overflow-scroll pt-2.5 md:max-h-full md:overflow-hidden"
                        role="none"
                    >
                        <div class="px-6 text-xs text-gray-400 uppercase">
                            Invitations
                        </div>
                        <div
                            v-for="invitation in invitations"
                            :key="invitation.id"
                            data-cy="team-menu-invitation"
                            class="flex items-center justify-between py-3 pl-6 pr-6 group hover:bg-gray-50 hover:text-gray-900 md:pr-4"
                        >
                            <div class="flex items-center">
                                <img
                                    class="w-8 h-8 border-gray-300 rounded-full"
                                    :src="
                                        invitation.team.profile_photo_path
                                            ? invitation.team.profile_photo_path
                                            : invitation.team.profile_photo_url
                                    "
                                />
                                <p
                                    class="ml-2 text-sm font-medium text-gray-500 md:ml-4"
                                >
                                    {{ invitation.team.name }}
                                </p>
                            </div>
                            <div class="flex">
                                <button
                                    type="button"
                                    :disabled="loading"
                                    class="ml-5 rounded-2lg bg-orange-500 py-1 px-2 text-center text-xs font-medium uppercase text-gray-800 disabled:cursor-default disabled:opacity-75 md:ml-2.5"
                                    @click="acceptInvitation(invitation)"
                                >
                                    Accept
                                </button>
                                <button
                                    type="button"
                                    :disabled="loading"
                                    class="ml-2.5 rounded-2lg bg-topol-red-600 py-1 px-2 text-center text-xs font-medium uppercase text-white disabled:cursor-default disabled:opacity-75"
                                    @click="refuseInvitation(invitation)"
                                >
                                    Refuse
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="mb-5 hidden px-6 pt-[15px] md:block"
                        role="none"
                    >
                        <AtomicButton
                            color="yellow"
                            size="lg"
                            @click="handleCreateTeamModal"
                        >
                            <template #icon>
                                <div class="mr-2">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                                            stroke="#252F3F"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </template>
                            Create new team
                        </AtomicButton>
                    </div>
                    <div class="pt-[19px] md:hidden">
                        <div
                            class="flex items-center justify-center py-1 mx-4 mb-8 bg-orange-500 rounded-md"
                        >
                            <div class="mr-2">
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                                        stroke="#252F3F"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div
                                class="text-sm leading-10 text-gray-800 cursor-pointer"
                                @click="createTeamModal = true"
                            >
                                Create new team
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <CreateTeamModal
            v-if="createTeamModal"
            @close="createTeamModal = false"
            @close-and-switch-to-team="handleCloseAndSwitchToTeam"
        />
    </div>
</template>

<script lang="ts" setup>
import IUser from '@/types/Members/IUser';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import route from 'ziggy-js';
import CreateTeamModal from './components/CreateTeamModal.vue';
import { Cog8ToothIcon } from '@heroicons/vue/24/outline';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IInvitation from '@/types/Members/IInvitation';

onMounted(() => {
    getInvitations();
});
const emit = defineEmits(['closeMenuMobile']);

const { fetchTeams, getCurrentTeamMembersCount, allTeams } =
    useTeamsAndMembers();
const { fetchBanners } = useBanners();
const { resetCurrentFolderWithoutFetching } = useFolders();

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const userProps = computed(() => globalProps.value.user as IUser);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const isOpen = ref(false);
const form = ref({
    id: {} as number,
});
const createTeamModal = ref(false);

const invitations = ref<IInvitation[]>([]);
const loading = ref(false);

const getInvitations = async () => {
    try {
        const response = await axios.get(route('user.invitations.index'));

        if (response.data.success) {
            invitations.value = response.data.data.filter(
                (invitation: IInvitation) =>
                    invitation.team !== null && invitation.team !== undefined
            );
        }
    } catch (err) {
        () => undefined;
    }
};

const acceptInvitation = async (invitation: IInvitation) => {
    loading.value = true;
    router.get(
        route('team.invitation.accept', { token: invitation.token }),
        {},
        {
            preserveState: false,
            onFinish: () => {
                loading.value = false;
            },
            onSuccess: () => {
                getInvitations();
                handleFetchTeams();
                handleFetchBannersAndMembers();
            },
        }
    );
};

const refuseInvitation = async (invitation: IInvitation) => {
    loading.value = true;
    router.get(
        route('team.invitation.refuse', { token: invitation.token }),
        {},
        {
            preserveState: false,
            onFinish: () => {
                loading.value = false;
            },
            onSuccess: () => {
                getInvitations();
                handleFetchTeams();
                handleFetchBannersAndMembers();
            },
        }
    );
};

const current = computed(() => {
    if (teamProps.value.personal_team && teamProps.value.owner_id === userProps.value.id) {
        return userProps.value;
    }
    return teamProps.value;
});

const handleProfileMenu = () => {
    emit('closeMenuMobile');
    isOpen.value = !isOpen.value;
};

const handleCreateTeamModal = () => {
    createTeamModal.value = true;
};

const handleCloseMenu = () => {
    isOpen.value = false;
};

const filteredTeamData = computed(() => {
    return allTeams.value.filter((team) => !(team.personal_team && team.owner_id === userProps.value.id));
});

const switchTeamRedirectToSettings = (teamId: number) => {
    if (teamProps.value.id === teamId) {
        isOpen.value = false;
        router.get(route('settings.team-settings.index'));
    } else {
        form.value.id = teamId;
        resetCurrentFolderWithoutFetching();
        router.post(route('teams.switch.store'), form.value, {
            onSuccess: () => {
                isOpen.value = false;
                router.get(route('settings.team-settings.index'));
                handleFetchBannersAndMembers();
            },
        });
    }
};

const switchToPersonalTeamRedirectToSettings = () => {
    const personalTeam = allTeams.value.find(
        (team) => team.personal_team === true
    );
    if (!personalTeam) return;
    switchTeamRedirectToSettings(personalTeam.id);
};

const switchTeam = (teamId: number) => {
    if (teamProps.value.id === teamId) {
        isOpen.value = false;
    } else {
        form.value.id = teamId;
        resetCurrentFolderWithoutFetching();
        router.post(route('teams.switch.store'), form.value, {
            onSuccess: () => {
                isOpen.value = false;
                handleFetchBannersAndMembers();
            },
        });
    }
};

const switchToPersonalTeam = () => {
    const personalTeam = allTeams.value.find(
        (team) => team.personal_team === true
    );
    if (!personalTeam) return;
    switchTeam(personalTeam.id);
};

const handleCloseAndSwitchToTeam = () => {
    resetCurrentFolderWithoutFetching();
    createTeamModal.value = false;
    handleFetchBannersAndMembers();
    handleFetchTeams();
};

const handleFetchTeams = () => {
    fetchTeams();
};

const handleFetchBannersAndMembers = () => {
    fetchBanners();
    getCurrentTeamMembersCount();
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.custom-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>
