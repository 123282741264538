<template>
    <div
        class="mb-1 transition duration-200 ease-in-out rounded"
        :class="{
            'border-2 border-[#4299E1]':
                isSelected === true,
            'border-2 border-gray-300':
                isSelected === false,
        }"
        @click="changeDefaultPaymentMethod"
    >
        <div
            class="flex cursor-pointer items-center justify-between px-4 pb-[14px] pt-[9px]"
        >
            <div class="flex items-center">
                <img
                    class="w-10 mr-5"
                    :src="paymentMethod.imageUrl"
                />
                <div v-if="isCreditCard(paymentMethod)" class="flex flex-col justify-center h-10">
                    <h4
                        class="text-base font-bold leading-normal text-gray-800"
                    >
                        Ending in {{ paymentMethod.last4 }}
                    </h4>
                    <p
                        class="-mt-[3px] text-xs font-medium uppercase leading-normal text-gray-600"
                    >
                        {{ paymentMethod.cardType }}
                    </p>
                </div>
                <div v-if="isPayPal(paymentMethod)" class="flex flex-col justify-center h-10">
                    <h4
                        class="text-base font-bold leading-normal text-gray-800 "
                    >
                        {{ paymentMethod.email }}
                    </h4>
                </div>
            </div>
            <div class="flex items-center">
                <div
                    v-if="!paymentMethod.default"
                    class="inline text-sm font-medium text-right hover:text-topol-blue-600"
                    @click.stop="deletePaymentMethod"
                >
                    {{ isCreditCard(paymentMethod) ? 'Remove card' : 'Remove PayPal' }}
                </div>

                <div
                    v-if="isSelected"
                    class="ml-6"
                >
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="20"
                            cy="20"
                            r="20"
                            fill="#38A169"
                        />
                        <path
                            d="M11 20.5L17.5 27L29.5 15"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { IPaymentMethod, ICreditCardPaymentMethod, IPayPalPaymentMethod } from '@/types/Subscription/IPaymentMethod';
import { PropType } from 'vue';

const props = defineProps({
    paymentMethod: {
        type: Object as PropType<IPaymentMethod>,
        required: true,
    },
    isSelected: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits([
    'deletePaymentMethod', 
    'changeDefaultPaymentMethod', 
]);

const changeDefaultPaymentMethod = () => {
    emit('changeDefaultPaymentMethod', props.paymentMethod);
};

const deletePaymentMethod = () => {
    emit('deletePaymentMethod', props.paymentMethod);
};

function isCreditCard(method: IPaymentMethod): method is ICreditCardPaymentMethod {
  return (method as ICreditCardPaymentMethod).bin !== undefined;
}

function isPayPal(method: IPaymentMethod): method is IPayPalPaymentMethod {
  return (method as IPayPalPaymentMethod).email !== undefined;
}

</script>