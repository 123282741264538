<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">
        
        <template #header>
            <div class="flex flex-wrap justify-between gap-4">
                <h1
                    class="mr-4 text-2xl font-medium leading-normal text-gray-800 tracking-small"
                >
                    Team members
                </h1>

                <AtomicButton
                    v-if="isOwner || isAdmin"
                    color="yellow"
                    size="lg"
                    @click="inviteMemberModalShow"
                >
                    <template #icon>
                        <div class="mr-2">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 7.5V10V7.5ZM15 10V12.5V10ZM15 10H17.5H15ZM15 10H12.5H15ZM10.8333 5.83333C10.8333 6.71739 10.4821 7.56523 9.85702 8.19036C9.2319 8.81548 8.38405 9.16667 7.5 9.16667C6.61594 9.16667 5.7681 8.81548 5.14298 8.19036C4.51786 7.56523 4.16667 6.71739 4.16667 5.83333C4.16667 4.94928 4.51786 4.10143 5.14298 3.47631C5.7681 2.85119 6.61594 2.5 7.5 2.5C8.38405 2.5 9.2319 2.85119 9.85702 3.47631C10.4821 4.10143 10.8333 4.94928 10.8333 5.83333V5.83333ZM2.5 16.6667C2.5 15.3406 3.02678 14.0688 3.96447 13.1311C4.90215 12.1935 6.17392 11.6667 7.5 11.6667C8.82608 11.6667 10.0979 12.1935 11.0355 13.1311C11.9732 14.0688 12.5 15.3406 12.5 16.6667V17.5H2.5V16.6667Z"
                                    stroke="#252F3F"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </template>
                    Invite new members
                </AtomicButton>
            </div>
        </template>

        <slot />
        
        <InviteMemberModal
            v-if="inviteMemberModal"
            @close="inviteMemberModal = false"
        />

    </BasicTabs>
  
</template>

<script lang="ts" setup>
import { ref, withDefaults } from 'vue';

import { UserGroupIcon, UserPlusIcon } from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import usePermissions from '@/composables/usePermissions';
import InviteMemberModal from './InviteMemberModal.vue';

interface MemberTabProps {
    tabKey: string;
}

interface IErrors {
    email?: string;
    role?: string;
}

withDefaults(defineProps<MemberTabProps>(), {
    tabKey: 'members',
});

const { isAdmin, isOwner } = usePermissions();
const inviteMemberModal = ref(false);
const inviteMemberModalErrors = ref<IErrors>();

const tabs: TabItem[] =
    isAdmin.value || isOwner.value
        ? [
              {
                  title: 'Team members',
                  key: 'members',
                  icon: UserGroupIcon,
                  url: route('members.index'),
              },
              {
                  title: 'Invitations',
                  key: 'invitations',
                  icon: UserPlusIcon,
                  url: route('invitations.index'),
              },
          ]
        : [
              {
                  title: 'Team members',
                  key: 'members',
                  icon: UserGroupIcon,
                  url: route('members.index'),
              },
          ];


const inviteMemberModalShow = () => {
    inviteMemberModalErrors.value = undefined;
    inviteMemberModal.value = true;
};
</script>
