<template>
    <div>
        <div class="py-12">
            <div class="text-2xl font-medium text-gray-800">
                Create template
            </div>
            <div class="mt-2">
                <inertia-link :href="route('kiosk.premade-templates.index')">
                    <AtomicButton size="sm" color="yellow">
                        <template #icon>
                            <BackspaceIcon class="w-6 h-6 mr-2" />
                        </template>
                        Back
                    </AtomicButton>
                </inertia-link>
            </div>
            <div
                class="p-6 mt-5 bg-white border border-gray-200 rounded-md shadow-md"
            >
                <div
                    class="flex flex-col pb-8 border-b border-gray-200 sm:flex-row"
                >
                    <div
                        class="flex flex-col sm:mr-10 lg:mr-5 lg:flex-row xl:mr-10"
                    >
                        <div class="lg:mr-5 xl:mr-10">
                            <TextInput
                                v-model="v$.form.name.$model"
                                label="Name"
                                class="w-full sm:w-80"
                                :error="getError('name')"
                                required
                                text-count
                                :text-count-max="40"
                                
                            >

                            <template #help>
                                Should be between 30 - 40 characters.
                            </template>
                        
                        </TextInput>

                            <SelectInput
                                v-model="v$.form.type.$model"
                                :data="types"
                                placeholder="Type (FREE/PRO)"
                                label="Type"
                                class="w-full mt-6 sm:w-80"
                                required
                                :error="getError('type')"
                            ></SelectInput>

                            <TextAreaInput
                                v-model="v$.form.description.$model"
                                label="Description"
                                :cols="40"
                                :rows="8"
                                class="w-full mt-6 sm:w-80"
                                :error="getError('description')"
                                text-count
                                :text-count-max="160"
                            >
                        
                            <template #help>
                                Should be between 110 - 160 characters.
                            </template>
                        
                        </TextAreaInput>
                        </div>
                        <div class="mt-4 lg:mt-0">
                            <SelectInput
                                v-model="v$.form.category_id.$model"
                                :data="categoryItems"
                                placeholder="Category"
                                label="Category"
                                class="w-full sm:w-80"
                                required
                                :error="getError('category_id')"
                            ></SelectInput>

                            <div class="mt-6">
                                <MultipleSelect
                                    v-model:value="selectedKeywords"
                                    class="w-full sm:w-80"
                                    label="Keywords"
                                    placeholder="Select keywords"
                                    :options="keywords"
                                />

                                <div clas="flex justify-between">
                                    <TextInput
                                        v-model="keywordNew"
                                        label="Add keyword"
                                        class="mt-6"
                                        :error="getError('keywordNew')"
                                    ></TextInput>

                                    <AtomicButton
                                        class="mt-2"
                                        color="purple"
                                        size="sm"
                                        @click="createKeyword"
                                    >
                                        Create new keyword
                                    </AtomicButton>
                                </div>

                                <div clas="flex">
                                    <div class="flex-shrink-0 mt-4">
                                        <label
                                            class="focus-ring-offset-2 mr-4 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-3 py-[9px] text-sm font-medium text-gray-600 transition duration-100 ease-in-out hover:text-gray-800 hover:shadow-button focus:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500"
                                            for="json-upload"
                                            :class="{
                                                'border-orange-500':
                                                    state.form.file,
                                                'border-gray-200':
                                                    !state.form.file,
                                            }"
                                        >
                                            Import JSON template
                                        </label>

                                        <input
                                            id="json-upload"
                                            ref="jsonInput"
                                            type="file"
                                            class="hidden"
                                            accept=".json"
                                            @change="onFileAdded"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="mt-10 max-h-[800px] w-full overflow-y-auto sm:mt-0 sm:max-w-[350px] lg:max-h-[400px]"
                    >
                        <div class="text-sm font-medium text-gray-800">
                            Choose starting design
                        </div>

                      

                        <div v-if="!template" class="flex flex-wrap gap-4 mt-2">
                            <div
                                v-for="premadeTemplate in premadeTemplates"
                                :key="premadeTemplate.id"
                                class="flex h-[200px] w-[150px] cursor-pointer items-center justify-center border hover:shadow-md"
                                :class="{
                                    'border-orange-500':
                                        state.form.premade_template_id === premadeTemplate.id,
                                    'border-gray-200':
                                        state.form.premade_template_id !== premadeTemplate.id,
                                }"
                                @click="selectTemplate(premadeTemplate)"
                            >
                                <img
                                    v-if="premadeTemplate.id !== 1"
                                    class="w-full h-full"
                                    :src="premadeTemplate.img_thumb_url"
                                />

                                <img
                                    v-else
                                    class="h-[120px] w-[96px]"
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/new-template.svg"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div v-else>
        
                            <div class="flex h-[380px] w-full border">
                            <iframe
                                :srcdoc="template.html"
                                class="w-full"
                            ></iframe>
                        </div>

                        </div>

                    </div>
                </div>
                <div class="flex justify-end">
                    <AtomicButton
                        size="md"
                        color="yellow"
                        class="mt-5"
                        :disabled="loading || v$.$invalid"
                        @click="createPremadeTemplate"
                        >Create</AtomicButton
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import TextInput from '@atomic/Inputs/TextInput.vue';
import TextAreaInput from '@atomic/Inputs/TextAreaInput.vue';
import SelectInput from '@atomic/Inputs/SelectInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { reactive, ref, onBeforeMount } from 'vue';
import route from 'ziggy-js';
import { BackspaceIcon } from '@heroicons/vue/24/outline';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import MultipleSelect from '@/components/Atomic/Inputs/MultipleSelect.vue';
import { router } from '@inertiajs/vue3';
import { create } from '@composables/useNotifications';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';
import IInputErrors from '@/types/Atomic/IInputErrors';

interface ITemplate {
    id: number;
    html: string;
}

interface IForm {
    form: {
        name: string;
        description: string;
        type: string;
        category_id?: number;
        keywords: number[];
        premade_template_id?: number;
        template_id?: number;
        file: string;
    };
}

const props = defineProps<{
    keywords: IKeyword[];
    categories: ICategory[];
    premadeTemplates: IPremadeTemplate[];
    template?: ITemplate;
}>();

const state: IForm = reactive({
    form: {
        name: '',
        description: '',
        type: '',
        category_id: undefined,
        keywords: [],
        template_id: props.template?.id,
        premade_template_id: undefined,
        file: '',
    },
});

const categoryItems = [] as ISelectable[];

onBeforeMount(() => {
    props.categories.forEach((a: ICategory) => {
        categoryItems.push({
            title: a.title as string,
            value: a.id,
        });
    });
});

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        description: {
            maxLength: maxLength(5000),
        },
        type: {
            required,
        },
        category_id: {
            required,
        },
    },
};

const selectedKeywords = ref([]);
const keywordNew = ref('');
const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);

const types = [
    { title: 'FREE', value: 'FREE' },
    { title: 'PRO', value: 'PRO' },
];

const createPremadeTemplate = () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        selectedKeywords.value.forEach((a: IKeyword) =>
            state.form.keywords.push(a.id)
        );

        router.post(route('kiosk.premade-templates.store'), state.form, {
            onSuccess: () => {
                create({
                    title: 'New template created',
                    type: 'success',
                });
                errors.value = {};
                v$.value.$reset();
            },
            onError: (err) => {
                errors.value = err;
                create({
                    title: 'Template not created',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const createKeyword = () => {
    router.post(
        route('kiosk.premade-templates.keywords.store'),
        {
            title: keywordNew.value,
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Keyword created',
                    type: 'info',
                });

                keywordNew.value = '';
            },
        }
    );
};

const selectTemplate = (template: IPremadeTemplate) => {
    state.form.premade_template_id = template.id;
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const onFileAdded = (e: Event) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
        if (e.target !== null && e.target.result !== null) {
            state.form.file = e.target.result as string;
        }
    };

    const target = e.target as HTMLInputElement;

    if (target !== null && target.files !== null && target.files.length === 1) {
        reader.readAsText(target.files[0]);
    }
};
</script>

<style></style>
