import axios from 'axios';
import IApiResponse from '@/types/IApiResponse';
import { ThreeDSecure, ThreeDSecureVerifyOptions } from 'braintree-web/modules/three-d-secure';
import route from 'ziggy-js';
import {
    client as BraintreeClient,
    threeDSecure as BraintreeThreeDSecure,
    Client,
} from 'braintree-web';

let threeDSecureInstance: ThreeDSecure | undefined = undefined;
let threeDSecureParameters: ThreeDSecureVerifyOptions | undefined = undefined;
let gatewayInstance: Client | undefined = undefined;
let paymentGatewayToken: string | undefined = undefined;

export const verifyCardByToken = async (
    token: string,
    billingAddressType: 'pro' | 'plugin',
    amount: number
) => {
    const nonce = await getNonceForToken(token);

    return verifyCard(nonce, billingAddressType, amount, token);
};

export const getNonceForToken = async (token: string) => {
    const response = await axios.post(
        route('braintree.payment-method.nonce.store'),
        {
            token: token,
        }
    );

    return response.data.data.nonce;
};

export const verifyCard = async (
    nonce: string,
    billingAddressType: 'pro' | 'plugin',
    amount: number,
    token?: string
) => {
    try {
        await getThreeDSecureParameters(
            nonce,
            billingAddressType,
            amount,
            token
        );

        await initThreeDSecureClient(billingAddressType);

        if (
            threeDSecureInstance === undefined ||
            threeDSecureParameters === undefined
        ) {
            throw new Error('Undefined 3d secure instance');
        }

        //For some unknown reason braintree only accepts zero as string...
        if (threeDSecureParameters.amount === 0) {
            //@ts-ignore
            threeDSecureParameters.amount = '0';
        }

        return threeDSecureInstance.verifyCard(threeDSecureParameters);
    } catch (err) {
        () => undefined;
    }
};

export const getThreeDSecureParameters = async (
    nonce: string,
    billingAddressType: 'pro' | 'plugin',
    amount: number,
    token?: string
) => {
    const response = await axios.post<IApiResponse<ThreeDSecureVerifyOptions>>(
        route('braintree.three-d-secure-parameters'),
        {
            billing_address_type: billingAddressType,
            amount: amount,
            card_token: token,
        }
    );

    threeDSecureParameters = response.data.data;
    threeDSecureParameters.amount = threeDSecureParameters.amount / 100;
    threeDSecureParameters.nonce = nonce;

    //@ts-ignore
    threeDSecureParameters.onLookupComplete = (data, next) => {
        next();
    };
};

export const initThreeDSecureClient = async (
    billingAddressType: 'pro' | 'plugin'
) => {
    try {
        if (!gatewayInstance) {
            await createClientInstance(billingAddressType);
        }

        threeDSecureInstance = await BraintreeThreeDSecure.create({
            version: 2,
            client: gatewayInstance,
        });
    } catch (err) {
        console.log(err);
        throw new Error('ThreeDSecure init error!');
    }
};

export const createClientInstance = async (
    billingAddressType: 'pro' | 'plugin'
) => {
    if (gatewayInstance) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await gatewayInstance.teardown(() => {});
    }

    if (threeDSecureInstance) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await threeDSecureInstance.teardown(() => {});
    }

    await getPaymentGatewayToken(billingAddressType);

    try {
        gatewayInstance = await BraintreeClient.create({
            authorization: paymentGatewayToken,
        });
    } catch (err) {
        () => undefined;
    }
};

export const getPaymentGatewayToken = async (
    billingAddressType: 'pro' | 'plugin'
) => {
    try {
        const response = await axios.post<IApiResponse<string>>(
            route('braintree.payment-gateway-token'),
            {
                billing_address_type: billingAddressType,
            }
        );

        paymentGatewayToken = response.data.data;
    } catch (err) {
        () => undefined;
    }
};
