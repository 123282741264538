<template>
    <thead class="bg-gray-50">
        <tr>
            <TableHeaderItem
            v-for="(header, i) in props.headers"
            :key="i"
            :header="header"
            :sort-by="sortBy"
            :sort-by-direction="sortByDirection"
            @update:sort-by="$emit('update:sortBy', $event)"
            @update:sort-by-direction="$emit('update:sortByDirection', $event)"
            ></TableHeaderItem>
        </tr>
    </thead>
</template>

<script lang="ts" setup>
import IDataTableHeader from '../types/IDataTableHeader';
import TableHeaderItem from './TableHeaderItem.vue';

const props = defineProps({
    headers: {
        type: Array as () => IDataTableHeader[],
        required: true,
    },
    sortBy: {
        type: String,
        default: '',
    },
    sortByDirection: {
        type: String,
        default: '',
    },
});


defineEmits(['update:sortBy', 'update:sortByDirection']);
</script>
