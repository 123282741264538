<template>
    <IntegrationModal
        title="iContact"
        documentation-url="https://support.topol.io/en/articles/8768896-export-to-icontact"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createIntegration"
        @edit="updateIntegration"
    >
        <template #inputs>
            <div class="mb-6 w-full">
                <TextInput
                    v-model="v$.name.$model"
                    class="mt-6"
                    placeholder="Enter your preferred integration name"
                    label="Name"
                    :error="getError('name')"
                />

                <TextInput
                    v-model="v$.account_id.$model"
                    class="mt-6 w-full"
                    placeholder="Your iContact Account ID"
                    label="Account ID"
                    required
                    :error="getError('account_id')"
                />

                <TextInput
                    v-model="v$.api_appid.$model"
                    class="mt-6 w-full"
                    placeholder="Your iContact App ID"
                    label="APP ID"
                    required
                    :error="getError('api_appid')"
                />

                <TextInput
                    v-model="v$.api_username.$model"
                    class="mt-6 w-full"
                    placeholder="Your iContact username"
                    label="Username"
                    required
                    :error="getError('api_username')"
                />

                <TextInput
                    v-model="v$.api_password.$model"
                    class="mt-6 w-full"
                    placeholder="Your iContact integration password"
                    label="Password"
                    required
                    :error="getError('api_password')"
                />
            </div>
        </template>
    </IntegrationModal>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IIContact, IIntegration } from '@/types/Integrations/IIntegration';
import useIntegrations from '../../composables/useIntegrations';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<IIContact>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});
const { icontactIntegrationModalOpened } = useIntegrations();
const state = reactive({
    name: props.integration?.name || '',
    account_id: props.integration?.data.account_id || '',
    api_appid: props.integration?.data.api_appid || '',
    api_password: '',
    api_username: props.integration?.data.api_username || '',
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
    account_id: {
        required,
        maxLength: maxLength(255),
    },
    api_appid: {
        required,
        maxLength: maxLength(255),
    },
    api_username: {
        required,
        maxLength: maxLength(255),
    },
    api_password: {
        required,
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};

const createIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('integrations.icontact.store'),
            {
                name: state.name,
                account_id: state.account_id,
                api_appid: state.api_appid,
                api_username: state.api_username,
                api_password: state.api_password,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Integration added',
                        text: 'iContact integration has been successfully added.',
                        type: 'success',
                    });
                    icontactIntegrationModalOpened.value = false;
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not added',
                        text: 'There was an error adding iContact integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('integrations.icontact.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
                account_id: state.account_id,
                api_appid: state.api_appid,
                api_username: state.api_username,
                api_password: state.api_password,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating iContact integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
