<template>
    <div>
        <div class="mx-6">
            <div>
                <div class="mt-4 flex flex-wrap justify-between gap-4">
                    <div class="flex gap-4">
                        <SortingButton
                            v-model:sortBy="selectedSortBy"
                            v-model:sortByDirection="selectedSortByDirection"
                            class="flex-shrink-0"
                        />

                        <PerPageButtonGroup
                            v-model="perPage"
                            :values="[20, 50, 100]"
                        ></PerPageButtonGroup>
                    </div>

                    <SearchInput
                        v-model="foldersAndTemplates.searchQuery.value"
                        class="max-w-xs"
                        placeholder="Search templates"
                    >
                    </SearchInput>
                </div>
            </div>
        </div>

        <div class="m-6 flex flex-col flex-wrap bg-white">
            <Folder
                v-for="folder in foldersAndTemplates.folders.value"
                :key="folder.id"
                :folder="folder"
                marketplace
                view="list"
                @open-folder="setDir(folder)"
            >
            </Folder>

            <TemplateBox
                v-for="template in foldersAndTemplates.templates.value"
                :key="template.id"
                marketplace
                :template="template"
                view="list"
                @openPreview="selectTemplate"
            />

            <div
                v-if="
                    !foldersAndTemplates.isLastPage.value &&
                    !foldersAndTemplates.loading.value
                "
                class="my-10 flex w-full justify-center"
            >
                <AtomicButton
                    :disabled="foldersAndTemplates.loading.value"
                    color="white"
                    @click="foldersAndTemplates.loadNextPage"
                    >Load more!</AtomicButton
                >
            </div>
        </div>
        <div
            v-if="
                foldersAndTemplates.templates.value.length === 0 &&
                foldersAndTemplates.folders.value.length === 0
            "
            class="mx-6 mb-6 text-gray-700"
        >
            There are no templates in this folder...
        </div>
    </div>
</template>
<script lang="ts" setup>
import ITemplate from '@/types/Templates/ITemplate';
import IFolder from '@/types/Templates/IFolder';

const emit = defineEmits(['select-template', 'select-folder']);

const foldersAndTemplates = useFoldersAndTemplates();
const selectedSortBy = ref<string>('');
const selectedSortByDirection = ref<'asc' | 'desc'>('asc');

const perPage = ref<number>(20);

onMounted(async () => {
    await foldersAndTemplates.fetch();
    perPage.value = foldersAndTemplates.per_page.value;
});

watch(selectedSortBy, () => {
    foldersAndTemplates.setSortBy(selectedSortBy.value);
});

watch(selectedSortByDirection, () => {
    foldersAndTemplates.setSortDirection(selectedSortByDirection.value);
});

watch(perPage, () => {
    foldersAndTemplates.setPerPage(perPage.value);
});

const setDir = async (folder?: IFolder) => {
    emit('select-folder', folder);
};

const selectTemplate = (template: ITemplate) => {
    emit('select-template', template);
};
</script>
