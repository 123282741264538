import { ref } from 'vue';

const drag = ref(false);
const templateId = ref('');
const folderId = ref('');

export const useDrag = () => {
    const folderDraggedOn = (id: string) => {
        folderId.value = id;
    };

    const isDraggedOn = (id: string) => {
        return folderId.value === id;
    };

    const startDrag = (id: string) => {
        drag.value = true;
        templateId.value = id;
    };

    const endDrag = () => {
        drag.value = false;
        templateId.value = '';
        folderId.value = '';
    };

    return {
        drag,
        startDrag,
        endDrag,
        templateId,
        isDraggedOn,
        folderDraggedOn,
    };
};

export default useDrag;
