<template>
     <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Billing">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { computed, RenderFunction } from 'vue';

import {
    PuzzlePieceIcon,
    DocumentTextIcon,
    ArrowPathIcon,
} from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

interface ILink {
    title: string;
    key: string;
    icon: RenderFunction;
    url: string;
}

interface DataTableProps {
    tabKey: string;
}

withDefaults(defineProps<DataTableProps>(), {
    tabKey: 'profile',
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);

let tabs = computed((): ILink[] => {
    const links: ILink[] = [
        {
            title: globalProps.value.team.personal_team
                ? 'PRO subscription'
                : 'Team subscription',
            key: 'pro',
            icon: ArrowPathIcon,
            url: route('billing.pro-subscriptions.index'),
        },
        {
            title: 'Invoices',
            key: 'invoices',
            icon: DocumentTextIcon,
            url: route('billing.invoices.index'),
        },
    ];

    if (globalProps.value.team.personal_team) {
        links.splice(1, 0, {
            title: 'Plugin subscription',
            key: 'plugin',
            icon: PuzzlePieceIcon,
            url: route('billing.plugin-subscriptions.index'),
        });
    }
    return links;
});
</script>
