<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Export to Sendy</div>
        </template>

        <template #body>
            <div class="p-6">
                <form @submit.prevent="exportToSendy">
                    <div>
                        <TextInput
                            v-model="sendyForm.form.title"
                            type="text"
                            label="Template title"
                            placeholder="Template title"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextInput
                            v-model="sendyForm.form.subject"
                            type="text"
                            label="Template subject"
                            placeholder="Template subject"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextInput
                            v-model="sendyForm.form.fromEmail"
                            type="email"
                            label="From email"
                            placeholder="From email address"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextInput
                            v-model="sendyForm.form.fromName"
                            type="text"
                            label="Your name"
                            placeholder="Enter name"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextInput
                            v-model="sendyForm.form.replyTo"
                            type="email"
                            label="Reply to"
                            placeholder="Reply to email address"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextInput
                            v-model="sendyForm.form.brandId"
                            type="text"
                            label="Brand ID"
                            required
                        />
                    </div>

                    <div class="mt-6">
                        <TextAreaInput
                            v-model="sendyForm.form.plainText"
                            label="Plain text"
                        />
                    </div>
                </form>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    data-cy="create-token-finish"
                    :disabled="loading"
                    @click="exportToSendy"
                >
                    Export
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Atomic/Modals/Modal.vue';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import { PropType, reactive, ref } from 'vue';
import { IIntegration, ISendy } from '@/types/Integrations/IIntegration';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import TextAreaInput from '@atomic/Inputs/TextAreaInput.vue';

const emit = defineEmits(['close']);

const props = defineProps({
    integration: {
        required: true,
        type: Object as PropType<IIntegration<ISendy>>,
        default: null,
    },
    templateId: {
        required: true,
        type: Number,
    },
    templateName: {
        required: true,
        type: String,
    },
});

interface IForm {
    form: {
        title: string;
        subject: string;
        fromName: string;
        fromEmail: string;
        replyTo: string;
        brandId: number;
        plainText: string;
    };
}

const sendyForm: IForm = reactive({
    form: {
        title: props.templateName ?? 'Untitled',
        subject: props.templateName ?? 'Untitled',
        fromName: JSON.parse(props.integration.data).fromName,
        fromEmail: JSON.parse(props.integration.data).fromEmail,
        replyTo: JSON.parse(props.integration.data).replyTo,
        brandId: JSON.parse(props.integration.data).brandId,
        integration_id: props.integration.id,
        plainText: '',
    },
});

const loading = ref(false);

const exportToSendy = async () => {
    loading.value = true;

    try {
        const response = await axios.post(
            route('templates.exports.sendy', {
                template: props.templateId,
            }),
            sendyForm.form
        );

        if (response.data.success) {
            loading.value = false;
            create({
                title: 'Successful export',
                text: 'Template has been successfully exported to Sendy.',
                type: 'success',
            });
            emit('close');
            return;
        }

        loading.value = false;
        create({
            title: 'Unsuccessful export',
            text: 'Template has not been exported to Sendy.',
            type: 'error',
        });
    } catch (e) {
        loading.value = false;
        create({
            title: 'Unsuccessful export',
            text: 'Template has not been exported to Sendy.',
            type: 'error',
        });
        return;
    }
};
</script>
