<template>
    <MetricsTabs tab-key="new-subscriptions">
        <DataTable
            class="w-full mt-4"
            title="New subscriptions"
            :headers="headers"
            :items="subscriptions"
            date-input
        >
        </DataTable>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';

defineProps<{
    subscriptions: IPaginationData<ICurrentTeam>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Plan',
        id: 'title',
    },
    {
        text: 'Subscribers (users)',
        id: 'users',
    },
    {
        text: 'Subscribers (teams)',
        id: 'teams',
    },
];
</script>

<style></style>
