<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2634.000000pt"
        height="981.000000pt"
        viewBox="0 0 2634.000000 981.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,981.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
        >
            <path
                d="M1980 8029 c-322 -70 -586 -128 -587 -129 -2 0 -3 -24 -3 -54 l0 -53 49 -6 c112 -13 225 -104 285 -230 70 -146 66 -6 66 -2239 0 -1934 -1 -2017 -19 -2105 -45 -215 -160 -350 -338 -393 l-43 -11 0 -49 0 -50 791 0 790 0 -3 51 -3 52 -52 13 c-154 39 -245 140 -300 329 -15 52 -17 119 -20 520 l-3 460 163 177 c122 134 165 174 173 165 6 -7 192 -309 414 -672 474 -776 480 -785 581 -889 87 -92 171 -146 274 -179 l70 -22 1435 0 1435 0 3 38 c2 20 2 37 0 37 -99 6 -233 35 -285 60 -116 57 -198 171 -246 338 l-22 77 -3 2448 c-2 2317 -3 2448 -20 2443 -9 -3 -260 -57 -557 -121 -297 -64 -557 -121 -577 -126 -38 -10 -38 -10 -38 -63 l0 -53 64 -5 c150 -12 257 -124 313 -328 17 -61 18 -179 18 -2115 l0 -2050 -22 -83 c-54 -203 -162 -334 -313 -383 -81 -26 -227 -28 -315 -4 -147 39 -304 144 -403 268 -29 36 -192 287 -362 557 -325 516 -375 588 -468 668 -253 219 -568 301 -897 235 l-30 -6 26 29 c140 157 662 728 724 793 342 359 731 646 1054 777 l106 44 0 47 0 48 -682 3 -683 2 0 -50 0 -50 53 -26 c150 -76 210 -183 187 -331 -26 -162 -140 -346 -402 -649 -95 -111 -306 -355 -468 -542 l-295 -342 -5 1927 c-3 1272 -8 1927 -15 1929 -5 1 -273 -56 -595 -127z"
            />
            <path
                d="M22540 7220 l0 -940 1400 0 c770 0 1400 2 1400 5 0 3 -128 209 -283 458 -156 249 -287 458 -291 465 -5 8 103 188 283 476 160 255 291 466 291 470 0 3 -630 6 -1400 6 l-1400 0 0 -940z"
            />
            <path
                d="M14901 7689 c-123 -24 -269 -133 -330 -248 -129 -243 -35 -535 212 -655 141 -69 283 -69 427 0 182 87 295 290 269 483 -38 279 -306 474 -578 420z"
            />
            <path
                d="M8575 6369 c-321 -41 -649 -206 -866 -436 -161 -171 -232 -337 -233 -543 0 -157 35 -259 121 -351 67 -71 136 -102 242 -107 158 -7 282 38 371 135 95 104 103 198 36 457 -55 213 -59 241 -46 324 28 183 154 320 345 378 82 25 274 25 364 0 224 -61 390 -232 475 -489 55 -167 75 -359 76 -717 l0 -226 -202 -47 c-112 -25 -276 -63 -366 -82 -182 -40 -419 -110 -622 -184 -360 -130 -540 -231 -700 -391 -144 -143 -228 -286 -267 -449 -22 -94 -13 -328 17 -431 110 -381 449 -592 955 -593 233 0 429 45 626 144 222 111 424 298 528 489 l28 51 6 -68 c10 -100 43 -217 89 -313 127 -264 412 -359 836 -279 56 11 132 29 167 40 l65 20 0 49 0 50 -61 0 c-83 0 -132 16 -182 61 -53 46 -91 115 -119 216 -22 77 -22 92 -28 1073 -7 1051 -7 1046 -55 1254 -122 525 -428 828 -938 931 -212 42 -484 56 -662 34z m883 -2221 c-4 -500 -5 -526 -26 -601 -48 -173 -153 -310 -322 -420 -151 -98 -272 -137 -426 -137 -169 0 -303 50 -404 150 -103 103 -157 245 -167 435 -7 145 11 229 76 360 94 188 170 259 431 397 115 61 242 115 490 209 184 70 339 128 343 128 5 1 7 -234 5 -521z"
            />
            <path
                d="M20669 6365 c-188 -30 -334 -77 -504 -161 -171 -85 -291 -172 -449 -324 -192 -187 -301 -331 -404 -536 -257 -513 -256 -1175 2 -1694 97 -194 185 -317 341 -479 331 -342 691 -514 1156 -551 469 -37 909 105 1277 414 370 311 606 734 668 1196 18 138 18 417 -1 550 -56 394 -226 733 -518 1037 -276 287 -571 456 -932 533 -130 29 -501 37 -636 15z m405 -135 c261 -66 454 -284 622 -702 124 -308 187 -661 187 -1048 1 -232 -11 -371 -48 -575 -135 -734 -535 -1215 -962 -1155 -238 33 -437 201 -583 490 -145 289 -237 617 -270 962 -18 188 -8 613 19 793 53 347 157 649 302 870 29 44 95 123 148 175 184 183 373 244 585 190z"
            />
            <path
                d="M10264 6276 c-3 -8 -4 -30 -2 -51 3 -34 6 -38 53 -53 165 -56 303 -201 438 -462 70 -135 73 -141 327 -798 272 -704 406 -1075 660 -1822 l128 -375 275 -3 275 -2 15 42 c597 1719 896 2558 977 2739 125 282 300 509 466 607 269 158 598 102 703 -118 l26 -55 0 -1335 c0 -1261 -1 -1339 -18 -1400 -55 -196 -170 -329 -316 -366 l-46 -11 -3 -52 -3 -51 785 0 786 0 0 50 0 49 -42 12 c-152 40 -257 163 -310 362 l-22 82 -3 1513 -4 1512 -1267 -2 -1267 -3 0 -44 0 -45 68 -17 c192 -48 301 -161 329 -342 21 -136 4 -197 -233 -839 -361 -980 -526 -1442 -598 -1678 -7 -21 -13 -8 -40 80 -97 319 -245 754 -413 1215 -237 646 -310 866 -343 1027 -29 144 -33 278 -10 354 28 95 113 163 228 182 l42 7 3 53 3 52 -821 0 c-693 0 -821 -2 -826 -14z"
            />
            <path
                d="M15700 6242 l0 -49 53 -12 c112 -25 214 -108 310 -253 65 -98 151 -267 213 -419 61 -149 1106 -2898 1132 -2979 38 -116 52 -193 59 -316 7 -137 -6 -249 -43 -364 -27 -86 -116 -264 -174 -350 -53 -78 -143 -158 -220 -194 -62 -30 -70 -31 -195 -31 -122 0 -134 2 -193 29 -108 50 -162 138 -162 263 0 46 10 71 76 202 69 133 77 156 81 218 15 203 -122 345 -348 360 -228 16 -413 -106 -480 -317 -27 -85 -29 -236 -5 -335 24 -97 95 -238 156 -313 101 -124 264 -232 420 -280 149 -45 371 -53 505 -17 130 35 308 145 399 246 84 93 238 380 314 584 47 129 180 525 476 1425 251 761 415 1226 643 1820 158 414 252 598 400 788 100 128 191 191 323 225 l55 14 0 49 0 49 -592 3 -593 2 0 -55 0 -55 38 0 c56 0 141 -27 193 -62 155 -104 184 -327 88 -688 -33 -121 -84 -274 -214 -635 -156 -431 -248 -698 -395 -1149 l-118 -358 -57 173 c-82 247 -227 652 -346 960 -141 368 -309 830 -358 983 -151 474 -109 709 140 773 l69 17 0 48 0 48 -825 0 -825 0 0 -48z"
            />
        </g>
    </svg>
</template>
