<template>
    <Tabs :selected-team="selectedTeam" tab-key="team-api-tokens">
        <div
            class="mt-12 flex h-10 min-w-[160px] flex-col text-base leading-normal"
        >
            <p class="text-gray-500">Unique users in current period</p>

            <div v-if="uniqueUsersLoading" class="mt-0.5 mb-[3px] flex">
                <LoadingSpinner size="small" />
            </div>

            <p
                v-else
                class="font-medium"
                :class="{
                    'text-green-600': !uniqueUsers.usersCountExceeded,
                    'text-red-600': uniqueUsers.usersCountExceeded,
                }"
            >
                {{ uniqueUsers.usersCount }}

                <span
                    v-if="uniqueUsers.maxUsers !== 999999999"
                    class="text-gray-800"
                >
                    / {{ uniqueUsers.maxUsers }}</span
                >
                <span v-else class="text-gray-800"> / ∞</span>
            </p>
        </div>

        <DataTable
            class="mt-4"
            :title="selectedTeam.name + `'s API Tokens `"
            searchable
            :headers="headers"
            :items="apiTokens"
        >
            <template #item-created_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-last_used_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <AtomicButton
                    color="red"
                    size="sm"
                    @click="handleExpireApiToken(row as IToken)"
                >
                    <template #icon>
                        <TrashIcon class="h-6 w-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IToken from '@/types/Plugin/IToken';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';

import Tabs from '../components/TeamTabs.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';
import { TrashIcon } from '@heroicons/vue/24/outline';
import useConfirm from '@/composables/useConfirm';
import LoadingSpinner from '@/components/Atomic/LoadingSpinner/LoadingSpinner.vue';
import { onMounted, ref } from 'vue';
import IPluginUniqueUsers from '@/types/Plugin/IPluginUniqueUsers';
import axios from 'axios';

const props = defineProps<{
    selectedTeam: ICurrentTeam;
    apiTokens: IPaginationData<IToken>;
}>();

const uniqueUsers = ref({} as IPluginUniqueUsers);
const uniqueUsersLoading = ref(true);

onMounted(async () => {
    const resUsers = await axios.get(
        route('kiosk.api-tokens.unique-users.index', {
            team: props.selectedTeam.id,
        })
    );
    uniqueUsers.value = resUsers.data.data;
    uniqueUsersLoading.value = false;
});

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Domains',
        id: 'domains',
    },

    {
        text: 'Last used',
        id: 'last_used_at',
        align: 'center',
        sortable: true,
        sortableId: 'last_used_at',
    },

    {
        text: 'Created at',
        id: 'created_at',
        align: 'center',
        sortable: true,
        sortableId: 'created_at',
    },
    {
        text: '',
        id: 'custom',
    },
];

const handleExpireApiToken = async (apiToken: IToken) => {
    const confirmed = await show({
        header: 'Expire Token',
        message: `Are you sure you want to expire this token?`,
        type: 'danger',
        confirm: 'Expire',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.teams.api-tokens.destroy', { apiToken: apiToken.id }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'API Token has been expired',
                        type: 'success',
                    });
                },
                onError: (e) => {
                    create({
                        title: 'API Token has not been expired',
                        text: `There was an error expiring the token. (code: ${e.code})`,
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
