<template>
    <EditorOptionsTabs tab-key="premade-templates" :token="token">
        <div class="mt-6 flex flex-wrap items-center justify-between gap-4">
            <p class="text-gray-500">
                You can select premade templates that will be available in your
                editor.
            </p>

            <div class="flex flex-wrap gap-4">
                <AtomicButton size="md" color="white" @click="handleSelectAll">
                    Select all
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="white"
                    @click="handleDeselectAll"
                >
                    Deselect all
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    :disabled="loading"
                    @click="save"
                >
                    Save
                </AtomicButton>
            </div>
        </div>

        <div class="mt-10 flex flex-wrap justify-start gap-8">
            <div
                v-for="(template, i) in premadeTemplates"
                :key="i"
                class="mb-4"
                @click="handleTemplateClick(template.id)"
            >
                <button class="relative">
                    <img
                        :src="template.img_thumb_url"
                        class="h-[332px] w-56 rounded-md transition duration-200 ease-in-out hover:shadow-md"
                        :class="
                            ids.includes(template.id)
                                ? 'ring-2 ring-yellow-500'
                                : 'opacity-60'
                        "
                    />

                    <div class="absolute top-4 right-2">
                        <CheckboxInput
                            :id="template.id"
                            :checked="ids.includes(template.id)"
                            :name="template.name"
                            :value="template.id"
                            size="lg"
                        />
                    </div>
                </button>
            </div>
        </div>
    </EditorOptionsTabs>
</template>

<script lang="ts" setup>
import IToken from '@/types/Plugin/IToken';
import { PropType } from 'vue';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import { create } from '@/composables/useNotifications';

const props = defineProps({
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
    premadeTemplates: {
        type: Array as PropType<IPremadeTemplate[]>,
        required: true,
    },
});

onMounted(() => {
    if (props.token.settings?.premade_templates) {
        props.token.settings?.premade_templates?.forEach((id) => {
            ids.value.push(id);
        });
    } else {
        props.premadeTemplates.forEach((template) => {
            ids.value.push(template.id);
        });
    }
});

const loading = ref(false);
const { show: showConfirmation } = useConfirm();

const save = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to save this options?`,
        type: 'info',
    });

    if (confirmed) {
        router.post(
            route('api-tokens.premade-templates.store', props.token.id),
            {
                ids: ids.value,
            },
            {
                preserveScroll: true,
                onStart: () => {
                    loading.value = true;
                },
                onSuccess: () => {
                    create({
                        title: 'Premade templates saved',
                        text: 'Premade templates options for this API Token has been saved successfully.',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Error',
                        text: 'Something went wrong while saving premade templates options for this API Token.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const handleTemplateClick = (id: number) => {
    if (ids.value.includes(id)) {
        ids.value = ids.value.filter((i) => i !== id);
    } else {
        ids.value.push(id);
    }
};

const ids = ref<number[]>([]);

const handleSelectAll = () => {
    ids.value = [];
    props.premadeTemplates.forEach((template) => {
        ids.value.push(template.id);
    });
};

const handleDeselectAll = () => {
    ids.value = [];
};
</script>
