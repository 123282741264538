<template>
    <div
        class="flex items-center"
        :class="{ 'sm:mr-2': isThirdChild, 'sm:ml-2': isFourthChild }"
    >
        <input
            ref="input"
            v-model="model"
            class="w-10 py-[7px] text-3xl font-medium leading-10 text-gray-600"
            :type="inputType"
            min="0"
            max="9"
            maxlength="1"
            pattern="[0-9]"
            :class="inputClasses"
            @input="handleOnChange"
            @keydown="handleOnKeyDown"
            @focus="handleOnFocus"
            @blur="handleOnBlur"
        />
        <span v-if="!isLastChild && separator">
            <span v-html="separator"></span>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, Ref, watch } from 'vue';

const props = defineProps({
    inputType: {
        type: String,
        default: 'tel',
    },
    value: {
        type: [String, Number],
        default: '',
    },
    separator: {
        type: String,
        default: '',
    },
    focus: {
        type: Boolean,
    },
    inputClasses: {
        type: String,
        default: '',
    },
    shouldAutoFocus: {
        type: Boolean,
    },
    isLastChild: {
        type: Boolean,
    },
    isThirdChild: {
        type: Boolean,
    },
    isFourthChild: {
        type: Boolean,
    },
});

const emit = defineEmits(['on-change', 'on-keydown', 'on-focus', 'on-blur']);
const model = ref(props.value || '');
const input = ref<HTMLInputElement | null>(
    null
) as Ref<HTMLInputElement>;
const handleOnChange = () => {
    model.value = model.value.toString();
    if (model.value.length > 1) {
        model.value = model.value.slice(0, 1);
    }
    return emit('on-change', model.value);
};
const isCodeNumeric = (charCode: number) =>
    (charCode >= 48 && charCode <= 57) ||
    (charCode >= 96 && charCode <= 105);
// numeric keys and numpad keys
const handleOnKeyDown = (event: KeyboardEvent) => {
    // Only allow characters 0-9, DEL, Backspace, Enter, Right and Left Arrows, and Pasting
    const keyEvent = event || window.event;
    const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
    if (
        isCodeNumeric(charCode) ||
        [8, 9, 13, 37, 39, 46, 86].includes(charCode)
    ) {
        emit('on-keydown', event);
    } else {
        keyEvent.preventDefault();
    }
};

const handleOnFocus = () => {
    input.value.select();
    return emit('on-focus');
};

const handleOnBlur = () => emit('on-blur');

watch(
    () => props.value,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            model.value = newValue;
        }
    }
);

watch(
    () => props.focus,
    (newFocusValue, oldFocusValue) => {
        // Check if focusedInput changed
        // Prevent calling function if input already in focus
        if (
            oldFocusValue !== newFocusValue &&
            input.value &&
            props.focus
        ) {
            input.value.focus();
            input.value.select();
        }
    }
);

onMounted(() => {
    if (input.value && props.focus && props.shouldAutoFocus) {
        input.value.focus();
        input.value.select();
    }
});

</script>

<style scoped></style>
