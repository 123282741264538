<template>
    <SubscriptionTabs tab-key="cancel-reasons" class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="cancelReasons"
        >
       
        </DataTable>


    <div class="mt-8">
        <h2 class="text-lg font-semibold text-gray-900">Other</h2>
      <div>
        <div class="inline-block py-2 align-middle">
          <table class="divide-y divide-gray-300">
            <thead>
              <tr class="divide-x divide-gray-200">
                <th scope="col" class="p-4 text-sm font-semibold text-left text-gray-900">Sub ID</th>
                <th scope="col" class="p-4 text-sm font-semibold text-left text-gray-900">Cancel reason</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="reason in other" :key="reason.user_id" class="divide-x divide-gray-200">
                <td class="p-4 text-sm font-medium text-gray-900">
                    <InertiaLink
                        :href="route('kiosk.users.activity.index', { user: reason.user_id })"
                        class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        > {{ reason.user_id }}
                    </InertiaLink>
                </td>
                <td class="p-4 text-sm text-gray-500">{{ reason.reason }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </SubscriptionTabs>
</template>

<script lang="ts" setup>
import ISubscription from '@/types/Subscription/ISubscription';

import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import route from 'ziggy-js';

defineProps<{
    cancelReasons: IPaginationData<ISubscription>;
        other: ICancelReason[];
}>();

interface ICancelReason {
    user_id: number;
    reason: string;
}

const headers: IDataTableHeader[] = [
    { text: 'Cancel reason', id: 'reason' },
    {
        text: 'Count',
        id: 'count',
    },
];
</script>

<style></style>
