<template>
    <form class="grid-cols-layout lg:grid" @submit.prevent="save">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Custom font sizes
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                Define font size options available in Topol editor. Font size is
                set in pixels. Maximum font size is 120px.
            </p>
        </div>
        <div
            class="relative mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
        >
            <div
                v-if="fontSizes != defaultFontSizes"
                class="absolute top-[20px] right-[26px] z-20 cursor-pointer text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                @click="resetFontSize"
            >
                Reset to default
            </div>
            <div class="border-b border-gray-200 px-6 py-5">
                <TextInput
                    v-model="fontSizes"
                    label="Available font sizes (pixels)"
                />
            </div>

            <div class="flex justify-end">
                <div class="my-3 pr-6">
                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="sm"
                        :disabled="fontSizes === settings.font_sizes.toString()"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from 'vue';
import IEditorSettings from '@/types/Settings/IEditorSettings';

const props = defineProps({
    settings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
});

const emit = defineEmits(['save']);

onMounted(() => {
    fontSizes.value = props.settings.font_sizes.toString();
});

const fontSizes = ref('');
const defaultFontSizes = ref('8,9,10,11,12,13,14,15,16,17,18,20,22,24,26,48');

const resetFontSize = () => {
    fontSizes.value = defaultFontSizes.value;
};

const save = () => {
    let fontSizesFinal = [];

    const a = fontSizes.value.split(',').map((i) => Number(i));
    let b = a.filter((v, i) => v && v <= 120 && v >= 1 && a.indexOf(v) == i);
    if (!b.length) {
        fontSizesFinal = props.settings.font_sizes;
    } else {
        fontSizesFinal = b.sort(function (a, b) {
            return a - b;
        });
    }

    emit('save', fontSizesFinal);
};
</script>
