<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Transfer to team</div>
        </template>

        <template #body>
            <div class="p-6">
                <TextInput
                    id="destinationTeam"
                    v-model="destinationTeam"
                    type="number"
                    required
                    autofocus
                    label="Destination team"
                />
            </div>
        </template>

        <template #footer>
            <div class="flex justify-end border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="transferToTeam()">
                    Transfer
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    data-cy="create-token-finish"
                    class="ml-2"
                    @click="$emit('close')"
                >
                    Close
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Atomic/Modals/Modal.vue';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import axios from 'axios';
import route from 'ziggy-js';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const props = defineProps<{
    team: ICurrentTeam;
}>();

const emit = defineEmits(['close']);

const destinationTeam = ref('');

const transferToTeam = async () => {
    const response = await axios.post(
        route('kiosk.teams.transfer-to-team', {
            team: props.team.id,
            destinationTeam: destinationTeam.value,
        })
    );

    if (response.status === 200) {
        emit('close');
        create({
            title: 'Team transfer started',
            type: 'success',
        });
    } else {
        create({
            title: 'Team transfer has not been started',
            type: 'error',
        });
    }
};
</script>
