<template>
    <InvoicesTabs tab-key="invoices">
        <div class="mb-12">
            <div class="flex flex-col pb-5 mt-9 lg:flex-row lg:justify-between">
                <DataTable
                    class="w-full"
                    title="Invoices"
                    :headers="headers"
                    :items="invoices"
                >
                    <template #item-issued_at="{ data }">
                        <div v-if="data" class="p-6 text-gray-600">
                            {{
                                format(
                                    new Date(data as string),
                                    'MMMM d, yyyy '
                                )
                            }}
                        </div>
                    </template>

                    <template #item-amount_currency="{ data }">
                        <div class="flex justify-center p-6 text-gray-500">
                            {{ data }}
                        </div>
                    </template>

                    <template #item-status="{ data }">
                        <div class="flex justify-center p-6 text-gray-500">
                            <span v-if="!data">Issued</span>
                            <span v-else-if="data === 'stavUhr.castUhr'"
                                >Paid (partially)</span
                            >
                            <span v-else>Paid</span>
                        </div>
                    </template>

                    <template #item-custom_download="{ row }">
                        <div
                            class="flex justify-center p-6 text-sm font-medium cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                            @click="downloadInvoice(row.id)"
                        >
                            Download invoice
                        </div>
                    </template>

                    <template #item-custom_show="{ row }">
                        <AtomicButton
                            size="sm"
                            color="white"
                            @click="showInvoice(row.id)"
                        >
                            <template #icon>
                                <MagnifyingGlassIcon class="w-6 h-6" />
                            </template>
                        </AtomicButton>
                    </template>
                </DataTable>
            </div>
        </div>
    </InvoicesTabs>
</template>
<script lang="ts" setup>
import IInvoice from '@/types/Invoices/IInvoice';
import { format } from 'date-fns';
import route from 'ziggy-js';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import usePdf from '@/composables/usePdf';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

defineProps<{
    invoices: IPaginationData<IInvoice>;
}>();

const { show, download } = usePdf();

const headers: IDataTableHeader[] = [
    {
        text: 'Internal ID',
        id: 'internal_id',
    },
    {
        text: 'Email',
        id: 'email',
    },
    {
        text: 'Date',
        id: 'issued_at',

        sortable: true,
        sortableId: 'datVyst',
    },
    {
        text: 'Invoice total',
        id: 'amount_currency',
        align: 'center',
        sortable: true,
        sortableId: 'sumCelkemMen',
    },
    {
        text: 'Status',
        id: 'status',
        align: 'center',
        sortable: true,
        sortableId: 'stavUhrK',
    },
    {
        text: '',
        id: 'custom_download',
    },
    {
        text: '',
        id: 'custom_show',
    },
];

const downloadInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    download(url, 'Invoice');
};

const showInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    show(url);
};
</script>

<style></style>
