<template>
    <div>
        <div class="chart-calculated-width relative h-64">
            <canvas ref="chartCtx"></canvas>
            <p class="absolute top-0 left-0 text-xs font-medium text-gray-500">
                Transferred data
            </p>
            <p
                class="absolute bottom-1.5 left-2 text-xs font-medium text-gray-500"
            >
                Day
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import Chart from 'chart.js/auto';
import { formatBytes } from '@utils/formatBytes';
import ITransferredBytes from '@/types/UsageStatistics/ITransferredBytes';

const props = defineProps<{
    usage: ITransferredBytes;
    selectedDataSet: Array<string>;
}>();

const chartCtx = ref<HTMLCanvasElement>();

const labels: string[] = [];

for (let i = 1; i < 32; i++) {
    labels.push(i.toString());
}

const traffic = computed(() => {
    let trafficArray: number[] = [];

    props.usage.thisMonth.forEach((element) =>
        trafficArray.push(element.bytes)
    );

    return trafficArray;
});

const trafficLast = computed(() => {
    let trafficLastArray: number[] = [];

    props.usage.lastMonth.forEach((element) =>
        trafficLastArray.push(element.bytes)
    );

    return trafficLastArray;
});

let myChart = null as Chart | null;

onMounted(() => {
    if (chartCtx.value === undefined) {
        return;
    }
    myChart = new Chart(chartCtx.value, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [
                {
                    data: trafficLast.value,
                    backgroundColor: '#BEE3F8',

                    barPercentage: 1,
                    categoryPercentage: 0.5,
                    hidden: false,
                },
                {
                    data: traffic.value,
                    backgroundColor: '#D6BCFA',
                    barPercentage: 1,
                    categoryPercentage: 0.5,
                    hidden: false,
                },
            ],
        },
        options: {
            interaction: {
                intersect: true,
                mode: 'index',
            },

            scales: {
                y: {
                    grid: {
                        color: '#e6e8eb',
                        drawBorder: false,
                    },
                    ticks: {
                        callback: (value) => {
                            if (value !== 0) {
                                return formatBytes(
                                    parseInt(value.toString()),
                                    0
                                );
                            }
                        },

                        maxTicksLimit: 6,
                        autoSkip: true,

                        color: '#6B7280',

                        font: {
                            family: 'Inter, Arial, sans-serif',
                        },
                    },
                },
                x: {
                    grid: {
                        display: false,
                    },

                    ticks: {
                        color: '#6B7280',
                        font: {
                            family: 'Inter, Arial, sans-serif',
                            size: 12,
                        },
                    },
                },
            },

            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                },
                tooltip: {
                    backgroundColor: '#252F3F',
                    caretSize: 0,
                    caretPadding: 5,
                    boxHeight: 12,
                    boxWidth: 12,
                    multiKeyBackground: 'rgba(0, 0, 0, 0)',
                    cornerRadius: 8,
                    bodySpacing: 8,
                    padding: 8,

                    xAlign: 'center',
                    bodyFont: {
                        family: 'Inter',
                        size: 13,
                        weight: 'normal',
                    },
                    callbacks: {
                        label: (toolTipItem) => {
                            return formatBytes(
                                parseInt(
                                    toolTipItem.dataset.data[
                                        toolTipItem.dataIndex
                                    ].toString()
                                ),
                                2
                            );
                        },

                        title: () => '',
                    },
                },
            },
        },
    });

    myChart?.render();
});

watch(
    () => props.selectedDataSet,
    () => {
        if (myChart === null) {
            return;
        }

        if (props.selectedDataSet.length === 0) {
            myChart.data.datasets[0].hidden = true;
            myChart.data.datasets[1].hidden = true;
        } else {
            myChart.data.datasets[0].hidden =
                !props.selectedDataSet.includes('lastMonth');
            myChart.data.datasets[1].hidden =
                !props.selectedDataSet.includes('thisMonth');
        }

        myChart?.update();
    }
);
</script>
<style>
.chartjs-tooltip-key {
    border-radius: 8px !important;
}

.chart-calculated-width {
    width: calc(100% - 20px);
}
</style>
