<template>
    <Tabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-api-tokens"
    >
        <div
            class="mt-12 flex h-10 min-w-[160px] flex-col text-base leading-normal"
        >
            <p class="text-gray-500">Unique users in current period</p>

            <div v-if="uniqueUsersLoading" class="mt-0.5 mb-[3px] flex">
                <LoadingSpinner size="small" />
            </div>

            <p
                v-else
                class="font-medium"
                :class="{
                    'text-green-600': !uniqueUsers.usersCountExceeded,
                    'text-red-600': uniqueUsers.usersCountExceeded,
                }"
            >
                {{ uniqueUsers.usersCount }}

                <span
                    v-if="uniqueUsers.maxUsers !== 999999999"
                    class="text-gray-800"
                >
                    / {{ uniqueUsers.maxUsers }}</span
                >
                <span v-else class="text-gray-800"> / ∞</span>
            </p>
        </div>

        <DataTable
            class="mt-4"
            :title="selectedUser.name + `'s API Tokens `"
            searchable
            :headers="headers"
            :items="apiTokens"
        >
            <template #item-created_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-last_used_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-bucket_blocked_at="{ data }">
                <div v-if="data" class="flex justify-center p-6 text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex gap-2">
                    <AtomicButton
                        v-if="row.bucket_blocked_at === null"
                        color="red"
                        size="sm"
                        @click="makeBucketPrivate(row as IToken)"
                    >
                        <template #icon>
                            <FireIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>

                    <AtomicButton
                        v-if="row.bucket_blocked_at !== null"
                        color="yellow"
                        size="sm"
                        @click="makeBucketPublic(row as IToken)"
                    >
                        <template #icon>
                            <SparklesIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>

                    <AtomicButton
                        v-if="row.expires_at !== null"
                        color="purple"
                        size="sm"
                        @click="handleActivateApiToken(row as IToken)"
                    >
                        <template #icon>
                            <BoltIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>

                    <AtomicButton
                        v-if="row.expires_at === null"
                        color="red"
                        size="sm"
                        @click="handleExpireApiToken(row as IToken)"
                    >
                        <template #icon>
                            <TrashIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IUser from '@/types/Members/IUser';
import IToken from '@/types/Plugin/IToken';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';

import Tabs from '../components/UserTabs.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';
import { TrashIcon, FireIcon, SparklesIcon, BoltIcon } from '@heroicons/vue/24/outline';
import useConfirm from '@/composables/useConfirm';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import IPluginUniqueUsers from '@/types/Plugin/IPluginUniqueUsers';
import LoadingSpinner from '@/components/Atomic/LoadingSpinner/LoadingSpinner.vue';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const props = defineProps<{
    selectedUser: IUser;
    apiTokens: IPaginationData<IToken>;
    userPersonalTeam: ICurrentTeam;
}>();

const uniqueUsers = ref({} as IPluginUniqueUsers);
const uniqueUsersLoading = ref(true);

onMounted(async () => {
    const resUsers = await axios.get(
        route('kiosk.api-tokens.unique-users.index', {
            user: props.selectedUser.id,
        })
    );
    uniqueUsers.value = resUsers.data.data;
    uniqueUsersLoading.value = false;
});

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Domains',
        id: 'domains',
    },

    {
        text: 'Last used',
        id: 'last_used_at',
        align: 'center',
        sortable: true,
        sortableId: 'last_used_at',
    },

    {
        text: 'Bucket blocked at',
        id: 'bucket_blocked_at',
        align: 'center',
        sortable: false,
        sortableId: 'bucket_blocked_at',
    },

    {
        text: 'Expires at',
        id: 'expires_at',
        align: 'center',
        sortable: true,
        sortableId: 'expires_at',
    },

    {
        text: 'Created at',
        id: 'created_at',
        align: 'center',
        sortable: true,
        sortableId: 'created_at',
    },
    {
        text: '',
        id: 'custom',
    },
];

const makeBucketPrivate = async (apiToken: IToken) => {
    const confirmed = await show({
        header: 'Make bucket private for selected Token',
        message: `Are you sure you want to make bucket private for this token?`,
        type: 'danger',
        confirm: 'Make private',
    });
    if (confirmed) {
        router.post(
            route('kiosk.api-tokens.make-bucket-private', {
                apiToken: apiToken.id,
            }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as private',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as private',
                        text: 'There was an error making bucket private.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const makeBucketPublic = async (apiToken: IToken) => {
    const confirmed = await show({
        header: 'Make bucket public for selected Token',
        message: `Are you sure you want to make bucket public for this token?`,
        type: 'danger',
        confirm: 'Make public',
    });
    if (confirmed) {
        router.post(
            route('kiosk.api-tokens.make-bucket-public', {
                apiToken: apiToken.id,
            }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as public',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as public',
                        text: 'There was an error making bucket public.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const handleExpireApiToken = async (apiToken: IToken) => {
    const confirmed = await show({
        header: 'Expire Token',
        message: `Are you sure you want to expire this token?`,
        type: 'danger',
        confirm: 'Expire',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.users.api-tokens.destroy', { apiToken: apiToken.id }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'API Token has been expired',
                        type: 'success',
                    });
                },
                onError: (e) => {
                    create({
                        title: 'API Token has not been expired',
                        text: `There was an error expiring the token. (code: ${e.code})`,
                        type: 'error',
                    });
                },
            }
        );
    }
};

const handleActivateApiToken = async (apiToken: IToken) => {
    const confirmed = await show({
        header: 'Activate Token',
        message: `Are you sure you want to activate this token?`,
        type: 'danger',
        confirm: 'Activate',
    });
    if (confirmed) {
        router.post(
            route('kiosk.users.api-tokens.activate', { apiToken: apiToken.id }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'API Token has been activated',
                        type: 'success',
                    });
                },
                onError: (e) => {
                    create({
                        title: 'API Token has not been activated',
                        text: `There was an error activating the token. (code: ${e.code})`,
                        type: 'error',
                    });
                },
            }
        );
    }
};

</script>
