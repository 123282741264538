<template>
    <Modal size="lg" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Webhook Secret Key</div>
        </template>

        <template #body>
            <div class="p-6 mb-6">
                <div class="flex flex-col">
                    <div v-if="webhook">
                        <div class="relative">
                            <textarea
                                ref="token"
                                style="resize: none"
                                rows="3"
                                readonly
                                class="mt-5 block w-full rounded-md border border-gray-300 bg-white pt-2 pb-2.5 pr-[22px] pl-3 text-sm leading-normal text-gray-800 placeholder-gray-400 shadow-sm transition duration-100 ease-in-out hover:bg-gray-50 focus:border-2 focus:border-gray-600 focus:pl-[11px] focus:pt-[7px] focus:pr-[21px] focus:pb-[9px] focus:outline-none md:w-full"
                                v-text="webhook.signature_secret_key"
                            ></textarea>
    
                            <transition
                                enter-active-class="transition duration-100 ease-out transform"
                                enter-from-class="scale-95 opacity-0"
                                enter-to-class="scale-100 opacity-100"
                                leave-active-class="transition duration-75 ease-in transform"
                                leave-from-class="scale-100 opacity-100"
                                leave-to-class="scale-95 opacity-0"
                            >
                                <Tooltip
                                    v-if="copiedSuccess"
                                    class="absolute top-[-55px]"
                                    style="left: 50%; transform: translate(-50%, 0)"
                                >
                                    Copied to clipboard!
                                </Tooltip>
                            </transition>
                        </div>

                        <AtomicButton
                            class="relative mt-5"
                            color="white"
                            size="lg"
                            @click="copyTextArea"
                        >
                            <template #icon>
                                <div class="mr-2">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 2C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V12C7 12.5304 7.21071 13.0391 7.58579 13.4142C7.96086 13.7893 8.46957 14 9 14H15C15.5304 14 16.0391 13.7893 16.4142 13.4142C16.7893 13.0391 17 12.5304 17 12V6.414C16.9999 5.88361 16.7891 5.37499 16.414 5L14 2.586C13.625 2.2109 13.1164 2.00011 12.586 2H9Z"
                                            fill="#9FA6B2"
                                        />
                                        <path
                                            d="M3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6V16H13C13 16.5304 12.7893 17.0391 12.4142 17.4142C12.0391 17.7893 11.5304 18 11 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
                                            fill="#9FA6B2"
                                        />
                                    </svg>
                                </div>
                            </template>
                            Copy token to clipboard
                        </AtomicButton>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-end p-6 border-t border-gray-200">
                <AtomicButton
                    color="white"
                    size="md"
                    @click="$emit('close')"
                >
                    Close
                </AtomicButton>
            </div>
        </template>
        
    </Modal>
</template>

<script lang="ts" setup>
import IWebhook from '@/types/Webhooks/IWebhook';
import { PropType } from 'vue';
import { useClipboard } from '@vueuse/core';

const props = defineProps({
    webhook: {
        type: Object as PropType<IWebhook>,
        default: null,
    },
});

defineEmits(['close']);

const clipboard = useClipboard();
const copiedSuccess = ref(false);

const copyTextArea = () => {
    clipboard.copy(props.webhook.signature_secret_key);

    copiedSuccess.value = true;
    setTimeout(() => {
        copiedSuccess.value = false;
    }, 1000);
};
</script>
