<template>
    <div data-cy="plugin-settings-edit-storage-modal">
        <Modal size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    Detach
                    {{ storage }}
                    bucket
                </div>
            </template>

            <template #body>
                <div class="p-6">
                    <div class="text-sm text-gray-500">
                        <div>
                            Are you sure you want to detach your custom
                            <span class="font-medium text-gray-700"
                                >{{ storage }} bucket?</span
                            >
                        </div>
                        <div class="mt-[21px]">
                            After detaching {{ storage }} bucket, all of your
                            Plugin users won't be able to access files on your
                            storage. You can reconnect your storage bucket again
                            later.
                        </div>
                    </div>
                    <div class="mt-4">
                        <CheckboxInput
                            v-model:checked="checked"
                            class="mt-[1px]"
                            size="md"
                            :label="checkboxLabel"
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        color="white"
                        size="md"
                        data-cy="plugin-settings-update-domains-cancel"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        :disabled="!checked"
                        size="md"
                        color="red"
                        data-cy="plugin-settings-update-domains-confirm"
                        @click="deleteStorage"
                    >
                        Detach now
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import IToken from '@/types/Plugin/IToken';

const props = defineProps({
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
    google: {
        type: Boolean,
        default: false,
    },
    amazon: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close', 'deleteStorage']);

const checked = ref(false);
const checkboxLabel = ref(
    props.amazon
        ? '<div class="text-sm font-medium text-gray-900">Confirm that you want to detach Amazon S3 bucket</div>'
        : '<div class="text-sm font-medium text-gray-900">Confirm that you want to detach Google Cloud bucket</div>'
);

const storage = computed(() => {
    if (props.amazon) {
        return 'Amazon S3';
    } else if (props.google) {
        return 'Google Cloud';
    } else return null;
});

const deleteStorage = () => {
    emit('deleteStorage', props.token.id);
};
</script>

<style></style>
