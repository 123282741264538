<template>
    <div>
        <div class="flex items-center justify-between">
            <label
                v-if="label"
                role="label"
                class="relative pr-3 pb-[3px] text-sm font-medium leading-normal text-gray-700"
                :class="{ 'text-gray-400': disabled }"
            >
                {{ label }}
                <img
                    v-if="required && !suppressRequiredIcon"
                    class="absolute top-0 right-0 h-2 w-2"
                    data-testid="input-required-indicator"
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/components/star-required.svg"
                    alt=""
                />
            </label>

            <span
                v-if="secondarylabel"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm leading-normal text-gray-500"
                >{{ secondarylabel }}</span
            >

            <span
                v-if="headeraction"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm font-medium leading-normal"
                :class="{
                    'text-gray-400 ': disabled,
                    'cursor-pointer text-topol-blue-500 hover:text-topol-blue-600':
                        !disabled,
                }"
                @click="!disabled ? $emit('headeraction') : null"
                >{{ headeraction }}</span
            >
        </div>

        <FloatingDropdown
            class="w-full cursor-pointer"
            :is-open="isOpen"
            placement="bottom-start"
            @close="onClose"
            @open="isOpen = true"
            @keydown.esc="isOpen = false"
        >
            <template #dropdown-button>
                <button
                    id="selectinput"
                    type="button"
                    class="group relative w-full cursor-pointer rounded-md border bg-white py-[9px] pl-3 pr-10 text-left text-sm shadow-button focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                    :disabled="readonly || disabled"
                    :class="{
                        'border-topol-alert-100 text-topol-alert-100': error,
                        'border-gray-300 text-gray-600 ring-gray-300 ring-opacity-50 hover:text-gray-800 focus:ring-2 ':
                            !error,
                        'bg-gray-50 text-gray-400 ring-gray-300': readonly,
                    }"
                >
                    <span
                        v-if="!modelValue"
                        class="block truncate group-disabled:text-gray-300"
                        :class="{
                            'text-topol-red-300': error,
                            'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500':
                                !error,
                        }"
                    >
                        {{ placeholder }}
                    </span>

                    <span
                        v-else
                        class="block truncate text-gray-600 group-hover:text-gray-800 group-focus:text-gray-800 group-disabled:text-gray-300"
                        :class="{
                            'text-gray-400': readonly,
                        }"
                    >
                        {{ title }}
                    </span>
                    <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 group-disabled:text-gray-300"
                        :class="{
                            'text-topol-alert-100': error,
                            'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500':
                                !error,
                        }"
                    >
                        <svg
                            class="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>
                </button>
            </template>
            <template #dropdown-content>
                <div
                    class="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg"
                >
                    <ul
                        tabindex="-1"
                        role="listbox"
                        class="max-h-60 overflow-auto rounded-md text-sm ring-1 focus:outline-none"
                        :class="{
                            'ring-topol-alert-100': error,
                            'ring-black ring-opacity-5': !error,
                            'pb-1': search,
                            'py-1': !search,
                        }"
                    >
                        <div
                            v-if="search"
                            class="sticky top-0 z-40 bg-white py-2"
                        >
                            <TextInput
                                v-model="searchText"
                                class="mx-2"
                                :placeholder="searchPlaceholder"
                            />

                            <div
                                v-if="searchNotFound"
                                class="mt-2 select-none py-2 pl-8 pr-4 text-base text-gray-700 sm:text-xs"
                            >
                                No results...
                            </div>
                        </div>

                        <li
                            v-for="(d, index) in filteredData"
                            :key="index"
                            :data-testid="d.value"
                            tabindex="0"
                            role="option"
                            class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-base text-gray-700 hover:bg-gray-700 hover:text-white focus:outline-none sm:text-xs"
                            @click="select(d)"
                        >
                            <span class="block truncate font-normal">
                                {{ d.title }}
                            </span>

                            <span
                                class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                            >
                                <svg
                                    v-if="d.value === modelValue"
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </span>
                        </li>
                    </ul>
                </div>
            </template>
        </FloatingDropdown>

        <div
            v-if="hasHelpSlot && !error"
            class="absolute mt-[3px] text-xs leading-5"
            :class="{
                'text-gray-400': disabled,
                'text-gray-500': !disabled,
            }"
        >
            <slot name="help" />
        </div>

        <div
            v-if="error"
            class="absolute mt-[3px] flex items-center text-topol-alert-100"
        >
            <svg
                data-testid="input-error-icon-indicator"
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
            >
                <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                />
            </svg>

            <div
                data-testid="input-error-message"
                class="ml-2 text-xs leading-5"
            >
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';
import ISelectable from '@/types/Atomic/ISelectable';
import TextInput from './TextInput.vue';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    secondarylabel: {
        type: String,
        default: '',
    },
    headeraction: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    data: {
        type: Array as PropType<ISelectable[]>,
        required: true,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: '',
    },
    suppressRequiredIcon: {
        type: Boolean,
        default: false,
    },
    search: {
        type: Boolean,
        default: false,
    },
    searchPlaceholder: {
        type: String,
        default: 'Search...',
    },
    unselectable: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'headeraction', 'blurred']);

const isOpen = ref(false);
const searchText = ref('');
const slots = useSlots();

const title = computed(() => {
    if (!props.modelValue) {
        return '';
    }
    const found = props.data.find((d) => d.value === props.modelValue);
    return found ? found.title : '';
});

const filteredData = computed(() => {
    if (searchText.value) {
        return props.data.filter((a) =>
            a.title.toLowerCase().includes(searchText.value.toLowerCase())
        );
    } else return props.data;
});

const searchNotFound = computed(() => {
    return filteredData.value.length ? false : true;
});

const hasHelpSlot = computed(() => {
    return !!slots.help;
});

const select = (value: ISelectable) => {
    if (props.unselectable) {
        if (value.value === props.modelValue) {
            emit('update:modelValue', null);
            return;
        }
    }

    emit('update:modelValue', value.value);
    isOpen.value = false;
};

const onClose = () => {
    if (isOpen.value) {
        isOpen.value = false;
        emit('blurred');
    }
};
</script>

<style></style>
