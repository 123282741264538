<template>
    <IntegrationsTabs tab-key="available">
        <div
            class="flex flex-wrap w-full my-10 gap-x-4 gap-y-8 md:grid md:grid-cols-2 lg:grid-cols-3"
        >
            <SendGridIntegrationBox
                @openNew="sendgridIntegrationModalOpened = true"
            />

            <SendyIntegrationBox
                @openNew="sendyIntegrationModalOpened = true"
            />

            <BrevoIntegrationBox
                @openNew="brevoIntegrationModalOpened = true"
            />

            <ConstantContactIntegrationBox
                @openNew="constantcontactIntegrationModalOpened = true"
            />

            <MailchimpIntegrationBox
                @openNew="mailchimpIntegrationModalOpened = true"
            />

            <KlaviyoIntegrationBox
                @openNew="klaviyoIntegrationModalOpened = true"
            />

            <MailjetIntegrationBox
                @openNew="mailjetIntegrationModalOpened = true"
            />

            <GmailIntegrationBox
                v-if="!featureDisabled('gmail')"
                :disabled="featureDisabled('gmail')"
                @openNew="gmailIntegrationModalOpened = true"
            />

            <HubspotIntegrationBox
                @openNew="hubspotIntegrationModalOpened = true"
            />

            <CampaignMonitorIntegrationBox
                @openNew="campaignmonitorIntegrationModalOpened = true"
            />

            <ActiveCampaignIntegrationBox
                @openNew="activecampaignIntegrationModalOpened = true"
            />

            <MoosendIntegrationBox
                @openNew="moosendIntegrationModalOpened = true"
            />

            <ClickSendIntegrationBox
                @openNew="clicksendIntegrationModalOpened = true"
            />

            <!-- BenchmarkEmail -->
            <BenchmarkEmailIntegrationBox
                @openNew="benchmarkemailIntegrationModalOpened = true"
            />

            <!-- Mailgun -->
            <MailgunIntegrationBox
                @openNew="mailgunIntegrationModalOpened = true"
            />

            <!-- AWeber -->
            <AWeberIntegrationBox
                @openNew="aweberIntegrationModalOpened = true"
            />

            <!-- MailUp -->
            <MailUpIntegrationBox
                @openNew="mailupIntegrationModalOpened = true"
            />

            <!-- MailerLite -->
            <MailerLiteIntegrationBox
                @openNew="mailerliteIntegrationModalOpened = true"
            />

            <!-- Acumbamail -->
            <AcumbamailIntegrationBox
                @openNew="acumbamailIntegrationModalOpened = true"
            />

            <!-- GetResponse -->
            <GetResponseIntegrationBox
                @openNew="getresponseIntegrationModalOpened = true"
            />

            <!-- ZohoCampaigns -->
            <ZohoCampaignsIntegrationBox
                @openNew="zohocampaignsIntegrationModalOpened = true"
            />

            <!-- ZohoMail -->
            <ZohoMailIntegrationBox
                @openNew="zohomailIntegrationModalOpened = true"
            />

            <!-- ZohoMarketing -->
            <ZohoMarketingIntegrationBox
            v-if="!featureDisabled('zoho_marketing')"
                :disabled="featureDisabled('zoho_marketing')"
                @openNew="zohomarketingIntegrationModalOpened = true"
            />

            <!-- SendPulse -->
            <SendPulseIntegrationBox
                @openNew="sendpulseIntegrationModalOpened = true"
            />

            <!-- OutlookWeb -->
            <OutlookWebIntegrationBox
                @openNew="outlookwebIntegrationModalOpened = true"
            />

            <!-- Close -->
            <CloseIntegrationBox
                @openNew="closeIntegrationModalOpened = true"
            />

            <!-- iContact -->
            <IContactIntegrationBox
                @openNew="icontactIntegrationModalOpened = true"
            />

            <!-- CleverReach -->
            <CleverReachIntegrationBox
                @openNew="cleverReachIntegrationModalOpened = true"
            />
        </div>
    </IntegrationsTabs>
</template>

<script lang="ts" setup>
import useIntegrations from '../composables/useIntegrations';

const props = defineProps({
    features: {
        type: Object,
        default: null,
    },
});

const {
    sendgridIntegrationModalOpened,
    sendyIntegrationModalOpened,
    brevoIntegrationModalOpened,
    constantcontactIntegrationModalOpened,
    mailchimpIntegrationModalOpened,
    klaviyoIntegrationModalOpened,
    mailjetIntegrationModalOpened,
    gmailIntegrationModalOpened,
    hubspotIntegrationModalOpened,
    campaignmonitorIntegrationModalOpened,
    activecampaignIntegrationModalOpened,
    moosendIntegrationModalOpened,
    clicksendIntegrationModalOpened,
    benchmarkemailIntegrationModalOpened,
    mailgunIntegrationModalOpened,
    aweberIntegrationModalOpened,
    mailupIntegrationModalOpened,
    mailerliteIntegrationModalOpened,
    acumbamailIntegrationModalOpened,
    getresponseIntegrationModalOpened,
    zohocampaignsIntegrationModalOpened,
    zohomailIntegrationModalOpened,
    zohomarketingIntegrationModalOpened,
    sendpulseIntegrationModalOpened,
    outlookwebIntegrationModalOpened,
    closeIntegrationModalOpened,
    icontactIntegrationModalOpened,
    cleverReachIntegrationModalOpened,
} = useIntegrations();

function featureDisabled(value: string) {
    if (!props.features) {
        return true;
    } else {
        //@ts-ignore
        return !props.features[value];
    }
}
</script>
