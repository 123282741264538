<template>
    <div class="flex justify-center w-full bg-gray-100">
        <div
            class="relative flex flex-col items-center w-full min-h-screen py-6 max-w-screen-2xl sm:justify-center"
        >
            <div>
                <Logo />
            </div>

            <div class="top-[20px] right-[20px] mt-5 sm:absolute sm:mt-0">
                <a href="https://topol.io/">
                    <AtomicButton size="md" color="yellow">
                        Go to homepage
                    </AtomicButton>
                </a>
            </div>

            <slot />
        </div>

        <Notifications />
    </div>
</template>
