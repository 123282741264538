<template>
    <div>
        <Modal :gray-header="false" size="md" @close="$emit('close')">
            <template #body>
                <div class="flex flex-col items-center p-6">
                    <svg
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="32"
                            cy="32"
                            r="28"
                            fill="white"
                            stroke="#C6F6D5"
                            stroke-width="8"
                        />
                        <path
                            d="M21 33L28.3333 40L43 26"
                            stroke="#48BB78"
                            stroke-width="6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <h4 class="mt-[22px] text-xl font-bold text-green-600">
                        We're sorry to see you go.
                    </h4>

                    <div class="mt-3 text-sm text-center text-gray-600">
                        <p>
                            Your subscription cancellation was successful. Until
                            the end of your billing period, you will still have
                            access to all the features of Topol.
                        </p>
                        <p
                            class="pt-5 mt-5 font-medium text-gray-800 border-t border-gray-300"
                        >
                            Before you leave, could you please tell us why
                            you've decided to cancel? Your feedback will help us
                            improve our service.
                        </p>

                        <LoadingSpinner v-if="loading" class="mt-3"  />

                        <MultipleSelectForm
                            v-else
                            v-model:value="selected"
                            v-model:text="text"
                            class="px-2 mt-3"
                            :options="options"
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between p-6 border-t border-gray-200">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Close
                    </AtomicButton>
                    <AtomicButton
                        :disabled="selected.length === 0 || loading"
                        size="md"
                        color="yellow"
                        @click="submit"
                    >
                        Submit
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
import ISelectable from '@/types/Atomic/ISelectable';

const props = defineProps({
    subscriptionId: {
        type: Number,
        required: true,
    },
});
const emit = defineEmits(['close']);

const options = ref<ISelectable[]>([]);
const selected = ref<ISelectable[]>([]);
const loading = ref(false);
const text = ref('');

onBeforeMount(() => {
    fetchOptions()
});


const fetchOptions = async () => {
    loading.value = true;
    try {
        const result = await axios.get(route('subscriptions.cancel-reasons.index'));

        if (result.data.data) {
            options.value = result.data.data;
        }
    } catch (error) {
       () => undefined;
    } finally {
        loading.value = false;
    }
}

const submit = () => {
    loading.value = true;
    const data = prepareData();

    router.put(
        route('subscriptions.update', {
            subscription: props.subscriptionId,
        }),
        {
            data: data,
        },
        {
            preserveState: true,
            preserveScroll: true,
            onSuccess: () => {
                create({
                    type: 'success',
                    title: 'Cancel reason submitted',
                    text: 'Your cancellation reason has been submitted successfully. Thank you!',
                });
                emit('close');
            },
            onError: () => {
                create({
                    title: 'Cancel reason not submitted',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const prepareData = () => {
    const data = selected.value.map((a) => a.value);
    if (text.value && data.includes('Other')) {
        const index = data.indexOf('Other');
        data[index] = `Other: ${text.value}`;
    }
    return data;
};
</script>
