<template>
    <CopyToClipboardButton :code="getPaymentRequestUrl()" />
</template>

<script lang="ts" setup>
import IPaymentRequest from '@/types/PaymentRequests/IPaymentRequest';
import { PropType } from 'vue';

const props = defineProps({
    paymentRequest: {
        type: Object as PropType<IPaymentRequest>,
            required: true,
        },
});

const getPaymentRequestUrl = () => {
    return 'https://app.topol.io/payment-requests/' + props.paymentRequest.uuid;
};

</script>