<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Share link</div>
        </template>

        <template #body>
            <div v-if="!link" class="p-6">
                <div class="mt-3 text-sm text-gray-600">
                    Here you can generate your share link with preferred
                    duration:
                </div>

                <VueSlider
                    v-if="!loading"
                    v-model="minutes"
                    class="mx-auto mt-10"
                    width="80%"
                    tooltip="always"
                    :min="2"
                    :max="1440"
                    :data="data"
                    :tooltip-formatter="tooltipFormatter"
                    :process-style="{ backgroundColor: '#5850EC' }"
                    :tooltip-style="{
                        backgroundColor: '#5850EC',
                        borderColor: '#5850EC',
                    }"
                    :dot-style="{ boxShadow: '0 0 5px 2px #5850EC' }"
                    />

                <AtomicButton
                    v-if="!loading"
                    :disabled="loading"
                    class="relative mt-5"
                    color="purple"
                    size="lg"
                    @click="createLink"
                >
                    Create share link
                </AtomicButton>

                <div v-if="loading" class="flex justify-center my-10">
                    <LoadingSpinner size="medium" />
                </div>
            </div>
            <div v-if="link" class="p-6">
                <div
                    class="relative mt-3 flex rounded bg-yellow-100 pb-[13px] pl-3.5 pr-6 pt-3"
                >
                    <svg
                        class="shrink-0"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.25685 3.09898C9.02185 1.73898 10.9788 1.73898 11.7428 3.09898L17.3228 13.019C18.0728 14.353 17.1098 15.999 15.5808 15.999H4.41985C2.88985 15.999 1.92685 14.353 2.67685 13.019L8.25685 3.09898ZM10.9998 13C10.9998 13.2652 10.8945 13.5196 10.707 13.7071C10.5194 13.8946 10.2651 14 9.99985 14C9.73463 14 9.48028 13.8946 9.29274 13.7071C9.10521 13.5196 8.99985 13.2652 8.99985 13C8.99985 12.7348 9.10521 12.4804 9.29274 12.2929C9.48028 12.1053 9.73463 12 9.99985 12C10.2651 12 10.5194 12.1053 10.707 12.2929C10.8945 12.4804 10.9998 12.7348 10.9998 13ZM9.99985 4.99998C9.73463 4.99998 9.48028 5.10534 9.29274 5.29287C9.10521 5.48041 8.99985 5.73476 8.99985 5.99998V8.99998C8.99985 9.2652 9.10521 9.51955 9.29274 9.70709C9.48028 9.89462 9.73463 9.99998 9.99985 9.99998C10.2651 9.99998 10.5194 9.89462 10.707 9.70709C10.8945 9.51955 10.9998 9.2652 10.9998 8.99998V5.99998C10.9998 5.73476 10.8945 5.48041 10.707 5.29287C10.5194 5.10534 10.2651 4.99998 9.99985 4.99998Z"
                            fill="#D69E2E"
                        />
                    </svg>
                    <div class="pl-3.5 text-sm leading-normal">
                        <p class="font-medium text-yellow-800">
                            Here is your generated link which will be available
                            just for <span v-html="formatedMinutes"></span>. Note that this link is limited to 100 uses.
                        </p>
                    </div>
                </div>

                <div class="relative">
                    <textarea
                        ref="token"
                        style="resize: none"
                        readonly
                        class="mt-5 block w-full rounded-md border border-gray-300 bg-white pt-2 pb-2.5 pr-[22px] pl-3 text-sm leading-normal text-gray-800 placeholder-gray-400 shadow-sm transition duration-100 ease-in-out hover:bg-gray-50 focus:border-2 focus:border-gray-600 focus:pl-[11px] focus:pt-[7px] focus:pr-[21px] focus:pb-[9px] focus:outline-none md:w-[470px]"
                        v-text="link"
                    ></textarea>

                    <transition
                        enter-active-class="transition duration-100 ease-out transform"
                        enter-from-class="scale-95 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in transform"
                        leave-from-class="scale-100 opacity-100"
                        leave-to-class="scale-95 opacity-0"
                    >
                        <Tooltip
                            v-if="copiedSuccess"
                            class="absolute top-[-55px]"
                            style="left: 50%; transform: translate(-50%, 0)"
                        >
                            Copied to clipboard!
                        </Tooltip>
                    </transition>
                </div>

                <AtomicButton
                    class="relative mt-5"
                    color="white"
                    size="lg"
                    @click="copyTextArea"
                >
                    <template #icon>
                        <div class="mr-2">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 2C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V12C7 12.5304 7.21071 13.0391 7.58579 13.4142C7.96086 13.7893 8.46957 14 9 14H15C15.5304 14 16.0391 13.7893 16.4142 13.4142C16.7893 13.0391 17 12.5304 17 12V6.414C16.9999 5.88361 16.7891 5.37499 16.414 5L14 2.586C13.625 2.2109 13.1164 2.00011 12.586 2H9Z"
                                    fill="#9FA6B2"
                                />
                                <path
                                    d="M3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6V16H13C13 16.5304 12.7893 17.0391 12.4142 17.4142C12.0391 17.7893 11.5304 18 11 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
                                    fill="#9FA6B2"
                                />
                            </svg>
                        </div>
                    </template>
                    Copy link to clipboard
                </AtomicButton>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between p-6 border-t border-gray-200">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    data-cy="create-token-finish"
                    @click="$emit('close')"
                >
                    Close
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Atomic/Modals/Modal.vue';
import { useClipboard } from '@vueuse/core';
import { ref } from 'vue';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import LoadingSpinner from '@/components/Atomic/LoadingSpinner/LoadingSpinner.vue';
import VueSlider, { DataObject, Value } from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

defineEmits(['close']);

const props = defineProps<{
    templateDataId: number;
}>();

const clipboard = useClipboard();
const copiedSuccess = ref(false);

const link = ref('');
const minutes = ref(5);
const loading = ref(false);

const formatedMinutes = computed(() => {
    if(minutes.value < 60) {
        return minutes.value + (minutes.value === 1 ? '&nbsp;minute' : '&nbsp;minutes');
    } else {
        const hours = Math.round(minutes.value / 60);
        return hours + (hours === 1 ? '&nbsp;hour' : '&nbsp;hours');
    }
});

const points = computed(() => {
    return [
    {
        value: 1,
        step: 1,
    },
    {
        value: 60,
        step: 60,
    },
    {
        value: 1440,
        step: 60,
    },
    ];
});

const data = computed(() => {
    let result: Value[] | object[] | DataObject | undefined = [];

    points.value.forEach((point, idx) => {
    const lastPointValue = points.value[points.value.length - 1].value;

    if (point.value === lastPointValue) {
        return;
    } else {
        const nextPoint = points.value[idx + 1];

        for (let i = point.value; i <= nextPoint.value; i += point.step) {
        result.push(i);
        }
    }
    });

    const uniqueValues = new Set(result);
    return [...uniqueValues];
});

const tooltipFormatter = (val: number) => {
  if (val < 60) {
    return val + (val === 1 ? ' minute' : ' minutes');
  } else {
    const hours = Math.round(val / 60);
    return hours + (hours === 1 ? ' hour' : ' hours');
  }
};

const copyTextArea = () => {
    clipboard.copy(link.value);

    copiedSuccess.value = true;
    setTimeout(() => {
        copiedSuccess.value = false;
    }, 1000);
};

const createLink = async () => {
    loading.value = true;
    const res = await axios.post(
        route('templates.store-preview-signed-url-n-seconds', {
            template: props.templateDataId,
            seconds: minutes.value * 60,
        })
    );
    link.value = res.data.data.url;
    loading.value = false;
};
</script>

<style scoped>
/* Set the theme color of the component */
$themeColor: #3498db;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
