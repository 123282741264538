<template>
    <div>
        <div class="bg items-center justify-between px-4 pt-4 md:flex md:gap-4">
            <div class="flex flex-wrap gap-4">
                <MultipleSelect
                    ref="MultipleSelectCategories"
                    v-model:value="selectedCategories"
                    placeholder="Select categories"
                    class="min-w-[170px] max-w-[240px]"
                    :options="categories"
                />

                <MultipleSelect
                    ref="MultipleSelectKeywords"
                    v-model:value="selectedKeywords"
                    placeholder="Select keywords"
                    class="min-w-[170px] max-w-[240px]"
                    :options="keywords"
                />
            </div>

            <div>
                <SearchInput
                    v-model="searchQuery"
                    class="mt-4 md:mt-0 lg:w-[350px] xl:w-[476px]"
                    placeholder="Search templates"
                >
                </SearchInput>
            </div>
        </div>

        <div class="mt-6 ml-4 flex flex-wrap">
            <div
                class="mr-6 mb-4 flex h-[332px] w-[224px] cursor-pointer flex-col items-center justify-center rounded-md bg-white shadow-card hover:shadow-lg"
                @click="createFromScratch"
            >
                <img
                    class="h-[120px] w-[96px]"
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/new-template.svg"
                    alt=""
                />

                <p
                    class="mx-auto mt-10 max-w-[150px] text-center text-gray-800"
                >
                    Start new template from scratch
                </p>
            </div>

            <TemplateBox
                v-for="template in templatesWithoutFromScratch"
                :key="template.id"
                class="mb-4 mr-6"
                :template="template"
                :keywords="template.keywords"
                :only-image="true"
                @openPreview="openPreview"
            />

            <div v-if="!isLastPage" class="mt-10 flex w-full justify-center">
                <AtomicButton
                    :disabled="isLoading"
                    color="white"
                    @click="loadNextPage"
                    >Load more!</AtomicButton
                >
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import { PropType } from 'vue';
import { useDebounceFn } from '@vueuse/shared';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import IMarketplaceTemplate from '@/types/Templates/IMarketplaceTemplate';

const props = defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
    type: {
        type: String as PropType<'premade-templates' | 'marketplace-templates'>,
        required: true,
    },
});

const emit = defineEmits([
    'open-template-preview',
    'create-template-from-scratch',
]);

const searchQuery = ref<string>('');
const selectedKeywords = ref<IKeyword[]>();
const selectedCategories = ref<ICategory[]>();

const {
    templates,
    fetchData,
    search,
    setKeywords,
    setCategories,
    loadNextPage,
    isLoading,
    isLastPage,
} =
    props.type === 'premade-templates'
        ? usePredefinedTemplates()
        : useMarketplaceTemplates('bought');

watch(selectedKeywords, () => {
    if (selectedKeywords.value !== undefined) {
        let keywords: number[] = [];
        selectedKeywords.value.forEach((keyword) => {
            keywords.push(keyword.id);
        });
        setKeywords(keywords);
    }
});

watch(selectedCategories, () => {
    if (selectedCategories.value !== undefined) {
        let categories: number[] = [];
        selectedCategories.value.forEach((category) => {
            categories.push(category.id);
        });
        setCategories(categories);
    }
});

watch(
    searchQuery,
    useDebounceFn(() => {
        search(searchQuery.value);
    }, 220)
);

onMounted(async () => {
    await fetchData();
});

const templatesWithoutFromScratch = computed(() => {
    if (props.type === 'premade-templates') {
        return templates.value.filter((a) => a.id !== 1);
    } else {
        return templates.value;
    }
});

const openPreview = (template: IPremadeTemplate | IMarketplaceTemplate) => {
    emit('open-template-preview', template);
};

const createFromScratch = () => {
    emit('create-template-from-scratch');
};
</script>
