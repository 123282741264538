<template>
    <div class="flex flex-col gap-2">
        <div v-for="(option, index) in options" :key="index">
            <CheckboxInput
                :label="option.title"
                size="md"
                :name="option.title"
                :checked="isOptionSelected(option)"
                @update:checked="check(option, $event)"
            />
        </div>
        <TextAreaInput
            v-if="otherOptionSelected"
            :cols="40"
            :rows="3"
            class="w-full"
            :model-value="text"
            @update:model-value="updateSelectedDescription"
        />
    </div>
</template>
<script lang="ts" setup>
import ISelectable from '@/types/Atomic/ISelectable';
import { PropType } from 'vue';

const props = defineProps({
    options: {
        type: Array as PropType<ISelectable[]>,
        required: true,
    },
    value: {
        type: Array as PropType<ISelectable[]>,
        default: () => [],
    },
    text: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update:value', 'update:text']);
const isOptionSelected = (option: ISelectable) => {
    return props.value.find((a) => a.value === option.value) !== undefined;
};

const otherOptionSelected = computed(() => {
    return props.value.find((a) => a.value === 'Other') !== undefined;
});

const updateSelectedDescription = (value: string) => {
    emit('update:text', value);
};

const check = (option: ISelectable, checked: boolean) => {
    let updatedValue = [...props.value];
    if (checked) {
        updatedValue.push(option);
    } else {
        updatedValue = updatedValue.filter((a) => a.value !== option.value);
        if (option.value === 'Other') {
            emit('update:text', '');
        }
    }
    emit('update:value', updatedValue);
};
</script>
