<template>
    <IntegrationModal
        title="Mailgun"
        documentation-url="https://support.topol.io/en/articles/8570307-export-to-mailgun"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createIntegration"
        @edit="updateIntegration"
    >
        <template #inputs>
            <div class="mb-6 w-full">
                <TextInput
                    v-model="v$.name.$model"
                    class="mt-6"
                    placeholder="Enter your preferred integration name"
                    label="Name"
                    :error="getError('name')"
                />

                <TextInput
                    v-model="v$.api_key.$model"
                    class="mt-6 w-full"
                    placeholder="Your Mailgun API key"
                    label="API key"
                    required
                    :error="getError('api_key')"
                />

                <TextInput
                    v-model="v$.domain.$model"
                    class="mt-6 w-full"
                    placeholder="your-domain.com"
                    label="Domain"
                    required
                    :error="getError('domain')"
                />

                <div class="mt-6">
                    <label
                        for="domain_region"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Domain region
                    </label>

                    <div class="mt-2 flex gap-4">
                        <RadioInput
                            v-model="state.domain_region"
                            option="us"
                            name="domain_region"
                            label="US"
                            size="md"
                        />

                        <RadioInput
                            v-model="state.domain_region"
                            option="eu"
                            name="domain_region"
                            label="EU"
                            size="md"
                        />
                    </div>
                </div>
            </div>
        </template>
    </IntegrationModal>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IMailgun, IIntegration } from '@/types/Integrations/IIntegration';
import useIntegrations from '../../composables/useIntegrations';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<IMailgun>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});
const { mailgunIntegrationModalOpened } = useIntegrations();
const state = reactive({
    name: props.integration?.name || '',
    api_key: '',
    domain: props.integration?.data.domain || '',
    domain_region: props.integration?.data.domain_region || 'us',
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
    api_key: {
        required,
        maxLength: maxLength(255),
    },
    domain: {
        required,
        maxLength: maxLength(255),
    },
    domain_region: {
        required,
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};
const createIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('integrations.mailgun.store'),
            {
                name: state.name,
                api_key: state.api_key,
                domain: state.domain,
                domain_region: state.domain_region,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Integration added',
                        text: 'Mailgun integration has been successfully added.',
                        type: 'success',
                    });
                    mailgunIntegrationModalOpened.value = false;
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not added',
                        text: 'There was an error adding Mailgun integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;

        router.put(
            route('integrations.mailgun.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
                api_key: state.api_key,
                domain: state.domain,
                domain_region: state.domain_region,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating Mailgun integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>
```
