<template>
    <div
        class="relative my-4 flex flex-col border-b border-gray-300 pb-4 lg:h-[46px] lg:flex-row lg:border-b-0 lg:pb-0"
    >
        <div class="absolute bottom-0 border-b border-gray-300 lg:w-full"></div>
        <li
            v-for="tab in tabs"
            :key="tab.key"
            class="z-10 flex cursor-pointer select-none items-center px-2 py-2.5 focus:outline-none lg:px-4 xl:px-6"
            :class="{
                ' max-w-xs rounded-r border-l-2 border-orange-500 bg-gray-200 pl-[6px] lg:rounded-r-none lg:border-l-0 lg:border-b-2 lg:bg-gray-100 lg:px-4 lg:pb-2 xl:px-6':
                    tab.key === tabKey,
            }"
            @click="navigateTo(tab)"
        >
            <component
                :is="tab.icon"
                class="w-5 transition duration-200 ease-in-out"
                :class="{
                    'text-gray-600': tab.key === tabKey,
                    'text-gray-400': !(tab.key === tabKey),
                }"
            ></component>

            <h2
                class="ml-3 font-medium transition duration-200 ease-in-out lg:ml-2 xl:ml-3"
                :class="{
                    'text-gray-600': tab.key === tabKey,
                    'text-gray-500': !(tab.key === tabKey),
                }"
            >
                {{ tab.title }}
            </h2>
        </li>
    </div>
</template>
<script lang="ts" setup>
import {
    BuildingStorefrontIcon,
    NewspaperIcon,
} from '@heroicons/vue/24/outline';
import { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';

interface TabItem {
    title: string;
    key: string;
    icon: FunctionalComponent<HTMLAttributes & VNodeProps>;
}

const emit = defineEmits(['select']);
const tabKey = ref('premade-templates');

const tabs: TabItem[] = [
    {
        title: 'Premade Templates',
        key: 'premade-templates',
        icon: NewspaperIcon,
    },
    {
        title: 'My Templates',
        key: 'marketplace-templates',
        icon: BuildingStorefrontIcon,
    },
];

const navigateTo = (tab: TabItem) => {
    tabKey.value = tab.key;
    emit('select', tab.key);
};
</script>
