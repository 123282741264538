<template>
    <div data-cy="plugin-settings-edit-domains-modal">
        <Modal size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    <span v-if="!currentKey">Setup </span>
                    <span v-else>Edit</span>
                    your OpenAI Text Assistant
                </div>
            </template>

            <template #body>
                <div class="p-6">
                    <div>
                        <TextInput
                            v-model="v$.form.key.$model"
                            placeholder="Enter your OpenAI token"
                            label="OpenAI token"
                            :error="getError('openai_key')"
                            required
                        />
                    </div>
                    <div class="mt-6 pb-[5px]">
                        <p class="text-sm leading-normal text-gray-500">
                            For more information how to setup OpenAI Text
                            Assistant
                            <a
                                href="https://docs.topol.io/guide/ai-assistant.html"
                                target="_blank"
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >check out our documentation</a
                            >.
                        </p>
                    </div>

                    <div>
                        <div class="mt-1 text-sm leading-normal text-gray-500">
                            <p v-if="currentKey">
                                Disable OpenAI Text Assistant by
                                <span
                                    class="cursor-pointer font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                    @click="deleteKey"
                                >
                                    deleting your token
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        size="md"
                        color="yellow"
                        :disabled="loading || v$.$invalid"
                        @click="saveKey"
                    >
                        <span v-if="!currentKey">Finish setup</span>
                        <span v-else>Update token</span>
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import Modal from '@atomic/Modals/Modal.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import IToken from '@/types/Plugin/IToken';
import IInputErrors from '@/types/Atomic/IInputErrors';

const props = defineProps({
    token: {
        type: Object as PropType<IToken>,
        default: () => ({}),
    },
});

const emit = defineEmits(['close', 'save', 'delete']);

const currentKey = ref(props.token.settings?.open_ai_api_key);

const state = reactive({
    form: {
        key: currentKey.value,
    },
});
const rules = {
    form: {
        key: { required },
    },
};
const v$ = useVuelidate(rules, state);

const loading = ref(false);
const errors = ref<IInputErrors>({});

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const saveKey = () => {
    if (v$.$invalid) {
        return;
    }

    loading.value = true;
    emit('save', state.form.key);
};

const deleteKey = () => {
    loading.value = true;
    emit('delete');
};
</script>

<style></style>
