<template>
    <svg
        width="570"
        height="163"
        viewBox="0 0 570 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M245.7 51.2999C245.7 37.2999 255.6 27.2 270.6 27.2C285.1 27.2 295.4 36.9999 295.4 51.2999C295.4 65.5999 285.2 75.5 270.6 75.5C255.7 75.5 245.7 65.2999 245.7 51.2999ZM282.7 51.2999C282.7 43.5999 277.5 38.3999 270.6 38.3999C263.3 38.3999 258.5 43.8999 258.5 51.2999C258.5 58.5999 263.5 64.2 270.6 64.2C277.3 64.2 282.7 58.9999 282.7 51.2999Z"
            fill="#1856ED"
        />
        <path
            d="M352.8 67.5L357.2 61.1C357.7 60.4 358.6 60.3 359.3 60.8C362.8 63.7 366.5 65.1 371.1 65.1C374.7 65.1 376.7 63.6 376.7 61.3C376.7 59.3 374.3 58.2 368.3 56.2C360.6 53.6 353.9 50 353.9 40.9C353.9 32.5 361.2 27 370.8 27C376.8 27 381.7 28.5 386.7 32C387.3 32.4 387.5 33.3 387.1 33.9L382.6 40.7C382.1 41.4 381.2 41.5 380.5 41C377.2 38.3 373.6 37.1 370.5 37.1C368.6 37.1 366.1 38.1 366.1 40.5C366.1 42.4 368.4 43.8 373 45.5C383.1 49.2 389.1 51.4 389.1 60.7C389.1 68.9 383.3 75.2 371 75.2C364 75.2 358 73 353.1 69.2C352.5 69 352.4 68.2 352.8 67.5Z"
            fill="#1856ED"
        />
        <path
            d="M400.1 59.9001V38.3001H396C395.2 38.3001 394.6 37.7001 394.6 36.9001V29.3001C394.6 28.5001 395.2 27.9001 396 27.9001H400.1V16.8001C400.1 16.0001 400.7 15.4001 401.5 15.4001H411.2C412 15.4001 412.6 16.0001 412.6 16.8001V27.9001H423.8C424.6 27.9001 425.2 28.5001 425.2 29.3001V36.9001C425.2 37.7001 424.6 38.3001 423.8 38.3001H412.6V58.1002C412.6 62.2002 413.7 64.1002 416.6 64.1002C417.8 64.1002 419.5 63.7002 421.2 63.1002C422 62.8002 422.8 63.2002 423 64.0002L425.3 71.4001C425.5 72.1001 425.2 72.8001 424.6 73.1001C421.9 74.5001 418.9 75.5002 413.3 75.5002C405.3 75.5002 400.1 70.4001 400.1 59.9001Z"
            fill="#1856ED"
        />
        <path
            d="M544.4 59.9001V38.3001H540.3C539.5 38.3001 538.9 37.7001 538.9 36.9001V29.3001C538.9 28.5001 539.5 27.9001 540.3 27.9001H544.4V16.8001C544.4 16.0001 545 15.4001 545.8 15.4001H555.5C556.3 15.4001 556.9 16.0001 556.9 16.8001V27.9001H568.1C568.9 27.9001 569.5 28.5001 569.5 29.3001V36.9001C569.5 37.7001 568.9 38.3001 568.1 38.3001H556.9V58.1002C556.9 62.2002 558 64.1002 560.9 64.1002C562.1 64.1002 563.8 63.7002 565.5 63.1002C566.3 62.8002 567.1 63.2002 567.3 64.0002L569.6 71.4001C569.8 72.1001 569.5 72.8001 568.9 73.1001C566.2 74.5001 563.2 75.5002 557.6 75.5002C549.7 75.5002 544.4 70.4001 544.4 59.9001Z"
            fill="#1856ED"
        />
        <path
            d="M303.1 27.8999H312.8C313.6 27.8999 314.2 28.4999 314.2 29.2999V34.1C318.2 29.4 323.5 27.2 329.3 27.2C339.2 27.2 346.2 32.5999 346.2 46.8999V73.2C346.2 74 345.6 74.5999 344.8 74.5999H335.1C334.3 74.5999 333.7 74 333.7 73.2V50C333.7 41.1 330.5 38.2999 324.7 38.2999C318.4 38.2999 314.2 42.7 314.2 51.1V73.0999C314.2 73.8999 313.6 74.5 312.8 74.5H303.1C302.3 74.5 301.7 73.8999 301.7 73.0999V29.1C301.7 28.5 302.3 27.8999 303.1 27.8999Z"
            fill="#1856ED"
        />
        <path
            d="M489.6 27.8999H499.3C500.1 27.8999 500.7 28.4999 500.7 29.2999V34.1C504.7 29.4 510 27.2 515.8 27.2C525.7 27.2 532.7 32.5999 532.7 46.8999V73.2C532.7 74 532.1 74.5999 531.3 74.5999H521.6C520.8 74.5999 520.2 74 520.2 73.2V50C520.2 41.1 517 38.2999 511.2 38.2999C504.9 38.2999 500.7 42.7 500.7 51.1V73.0999C500.7 73.8999 500.1 74.5 499.3 74.5H489.6C488.8 74.5 488.2 73.8999 488.2 73.0999V29.1C488.2 28.5 488.8 27.8999 489.6 27.8999Z"
            fill="#1856ED"
        />
        <path
            d="M429.6 51.3999C429.6 37.1999 439.1 27.2 451.6 27.2C457.4 27.2 462.3 29.5 465.6 33.5V29.2C465.6 28.4 466.2 27.7999 467 27.7999H476.7C477.5 27.7999 478.1 28.4 478.1 29.2V73.2C478.1 74 477.5 74.5999 476.7 74.5999H467.6C466.8 74.5999 466.2 74 466.2 73.2V68.7C462.9 73.1 458.2 75.5 451.9 75.5C439.1 75.5 429.6 65.7999 429.6 51.3999ZM466.2 51.5C466.2 43.9 461 38.3999 454.3 38.3999C447.5 38.3999 442.3 43.5 442.3 51.5C442.3 59 447.2 64.2 454.3 64.2C460.7 64.2 466.2 59.1 466.2 51.5Z"
            fill="#1856ED"
        />
        <path
            d="M221.6 8.30005C230.4 8.30005 237.8 11.3 243.3 15.8C243.9 16.3 244 17.1 243.6 17.8L237.7 26.1001C237.2 26.8001 236.2 26.9 235.6 26.3C231.8 23 227.1 21.0001 221.6 21.0001C209.1 21.0001 201 30.1 201 41.8C201 53.6 209.3 62.6001 221.4 62.6001C226.9 62.6001 231.6 60.6001 235.5 57.2001C236.1 56.6001 237.1 56.7 237.6 57.4L243.5 65.6C243.9 66.2 243.8 67.1 243.2 67.6C236.7 72.9 229 75.4 221.4 75.4C200.9 75.4 186.9 61.9 186.9 41.8C186.9 22.5 200 8.30005 221.6 8.30005Z"
            fill="#1856ED"
        />
        <path
            d="M441.8 130.5C441.8 116.1 451.8 106.3 466.3 106.3C472.4 106.3 478.8 108.2 483.6 112.2C484.2 112.7 484.3 113.5 483.8 114.1L478.9 121C478.4 121.7 477.4 121.8 476.7 121.2C474.3 118.9 470.9 117.5 467.1 117.5C459.5 117.5 454.5 122.8 454.5 130.4C454.5 138.1 459.7 143.3 467.1 143.3C471.2 143.3 474.5 141.8 477 139.5C477.6 138.9 478.7 139 479.2 139.7L484.2 146.7C484.6 147.3 484.5 148.1 484 148.6C480 152.2 474.2 154.6 466 154.6C451.9 154.6 441.8 144.9 441.8 130.5Z"
            fill="#1856ED"
        />
        <path
            d="M357.7 139V117.4H353.6C352.8 117.4 352.2 116.8 352.2 116V108.4C352.2 107.6 352.8 107 353.6 107H357.7V95.9C357.7 95.1 358.3 94.5 359.1 94.5H368.8C369.6 94.5 370.2 95.1 370.2 95.9V107H381.4C382.2 107 382.8 107.6 382.8 108.4V116C382.8 116.8 382.2 117.4 381.4 117.4H370.2V137.2C370.2 141.3 371.3 143.2 374.2 143.2C375.4 143.2 377.1 142.8 378.8 142.2C379.6 141.9 380.4 142.3 380.6 143.1L382.9 150.5C383.1 151.2 382.8 151.9 382.2 152.2C379.5 153.6 376.5 154.6 370.9 154.6C362.9 154.6 357.7 149.6 357.7 139Z"
            fill="#1856ED"
        />
        <path
            d="M495.6 139V117.4H491.5C490.7 117.4 490.1 116.8 490.1 116V108.4C490.1 107.6 490.7 107 491.5 107H495.6V95.9C495.6 95.1 496.2 94.5 497 94.5H506.7C507.5 94.5 508.1 95.1 508.1 95.9V107H519.3C520.1 107 520.7 107.6 520.7 108.4V116C520.7 116.8 520.1 117.4 519.3 117.4H508.1V137.2C508.1 141.3 509.2 143.2 512.1 143.2C513.3 143.2 515 142.8 516.7 142.2C517.5 141.9 518.3 142.3 518.5 143.1L520.8 150.5C521 151.2 520.7 151.9 520.1 152.2C517.4 153.6 514.4 154.6 508.8 154.6C500.9 154.6 495.6 149.6 495.6 139Z"
            fill="#1856ED"
        />
        <path
            d="M245.7 130.4C245.7 116.4 255.6 106.3 270.6 106.3C285.1 106.3 295.4 116.1 295.4 130.4C295.4 144.7 285.2 154.6 270.6 154.6C255.7 154.6 245.7 144.4 245.7 130.4ZM282.7 130.4C282.7 122.7 277.5 117.5 270.6 117.5C263.3 117.5 258.5 123 258.5 130.4C258.5 137.7 263.5 143.3 270.6 143.3C277.3 143.3 282.7 138.1 282.7 130.4Z"
            fill="#1856ED"
        />
        <path
            d="M303.1 106.8H312.8C313.6 106.8 314.2 107.4 314.2 108.2V113C318.2 108.3 323.5 106.1 329.3 106.1C339.2 106.1 346.2 111.5 346.2 125.8V152.1C346.2 152.9 345.6 153.5 344.8 153.5H335.1C334.3 153.5 333.7 152.9 333.7 152.1V128.9C333.7 120 330.5 117.2 324.7 117.2C318.4 117.2 314.2 121.6 314.2 130V152C314.2 152.8 313.6 153.4 312.8 153.4H303.1C302.3 153.4 301.7 152.8 301.7 152V108C301.7 107.4 302.3 106.8 303.1 106.8Z"
            fill="#1856ED"
        />
        <path
            d="M386.6 130.5C386.6 116.3 396.1 106.3 408.6 106.3C414.4 106.3 419.3 108.6 422.6 112.6V108.3C422.6 107.5 423.2 106.9 424 106.9H433.7C434.5 106.9 435.1 107.5 435.1 108.3V152.3C435.1 153.1 434.5 153.7 433.7 153.7H424.6C423.8 153.7 423.2 153.1 423.2 152.3V147.8C419.9 152.2 415.2 154.6 408.9 154.6C396.1 154.6 386.6 145 386.6 130.5ZM423.2 130.6C423.2 123 418 117.5 411.3 117.5C404.5 117.5 399.3 122.6 399.3 130.6C399.3 138.1 404.2 143.3 411.3 143.3C417.7 143.3 423.2 138.3 423.2 130.6Z"
            fill="#1856ED"
        />
        <path
            d="M221.6 87.5C230.4 87.5 237.8 90.5 243.3 95C243.9 95.5 244 96.3 243.6 97L237.7 105.3C237.2 106 236.2 106.1 235.6 105.5C231.8 102.2 227.1 100.2 221.6 100.2C209.1 100.2 201 109.3 201 121C201 132.8 209.3 141.8 221.4 141.8C226.9 141.8 231.6 139.8 235.5 136.4C236.1 135.8 237.1 135.9 237.6 136.6L243.5 144.8C243.9 145.4 243.8 146.3 243.2 146.8C236.7 152.1 229 154.6 221.4 154.6C200.9 154.6 186.9 141.1 186.9 121C186.9 101.6 200 87.5 221.6 87.5Z"
            fill="#1856ED"
        />
        <path
            d="M75.9 163C31 163 0.5 126.4 0.5 87.5C0.5 48 31.6 13.9 73 12.3C74.6 12.2 76 13.6 76 15.2V27.3C76 28.8 74.8 30.1 73.2 30.2C42.8 31.6 18.3 56.5 18.3 87.6C18.3 118 42.3 145.2 75.9 145.2C108.6 145.2 131.8 119.1 133.2 90.3C133.3 88.8 134.5 87.5 136.1 87.5H148.2C149.8 87.5 151.1 88.8 151.1 90.5C149.7 128.9 118.8 163 75.9 163Z"
            fill="#1856ED"
        />
        <path
            d="M76 123C56 123 40.5 107.1 40.5 87.4999C40.5 69.1999 54.6 53.8999 72.9 52.3999C74.6 52.2999 76 53.5999 76 55.2999V67.3999C76 68.7999 75 70.0999 73.5 70.2999C64.7 71.4999 58.3 78.9999 58.3 87.5999C58.3 97.1999 65.8 105.3 76 105.3C84.5 105.3 92.1 98.8999 93.3 90.0999C93.5 88.6999 94.8 87.5999 96.2 87.5999H108.3C110 87.5999 111.3 88.9999 111.2 90.6999C109.6 108.6 94.6 123 76 123Z"
            fill="#1856ED"
        />
        <path
            d="M145.7 72.7C144.3 43.4 120.9 19.2 90.8 17.8C89.3 17.7 88 16.5 88 14.9V2.9C88 1.3 89.3 0 91 0C130.1 1.5 162 32.8 163.5 72.5C163.6 74.1 162.2 75.5 160.6 75.5H148.5C147.1 75.5 145.8 74.3 145.7 72.7Z"
            fill="#FF9E1A"
        />
        <path
            d="M91.3 58.0999C89.5 57.7999 88.2 56.2999 88.2 54.7999V42.9999C88.2 41.2999 89.6 39.9999 91.3 40.0999C108.7 41.5999 122.1 55.4999 123.6 72.2999C123.7 73.9999 122.4 75.3999 120.7 75.3999H108C106.8 75.3999 105.9 74.5999 105.7 73.3999C104.8 66.2999 99.4 59.4999 91.3 58.0999Z"
            fill="#FF9E1A"
        />
    </svg>
</template>
