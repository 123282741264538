<template>
    <div>
        <div class="relative h-[400px] p-6">
            <p class="text-base font-normal text-gray-900">New integrations</p>
            <canvas ref="chartCtx"></canvas>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, PropType } from 'vue';
import Chart from 'chart.js/auto';
import IStat from '@/types/Stats/IStat';

const props = defineProps({
    stats: {
        type: Array as PropType<IStat[]>,
        default: null,
    },
});

const chartCtx = ref<HTMLCanvasElement>();

let myChart = null as Chart | null;

onMounted(() => {
    if (chartCtx.value === undefined) {
        return;
    }
    myChart = new Chart(chartCtx.value, {
        type: 'bar',
        data: {
            labels: props.stats.map((stat: IStat) => stat.name.toLowerCase()),
            datasets: [
                {
                    data: props.stats.map((stat: IStat) => stat.count),
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(75, 192, 192)',
                        'rgb(255, 205, 86)',
                        'rgb(201, 203, 207)',
                        'rgb(54, 162, 235)',
                    ],
                },
            ],
        },
        options: {
            indexAxis: 'y',

            scales: {
                x: {
                    ticks: {
                        stepSize: 1,
                    },
                },
            },

            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                },
                tooltip: {
                    backgroundColor: '#252F3F',
                    caretSize: 0,
                    caretPadding: 5,
                    boxHeight: 12,
                    boxWidth: 12,
                    multiKeyBackground: 'rgba(0, 0, 0, 0)',
                    cornerRadius: 8,
                    bodySpacing: 8,
                    padding: 8,

                    xAlign: 'center',
                    bodyFont: {
                        family: 'Inter',
                        size: 13,
                        weight: 'normal',
                    },
                },
            },
        },
    });

    myChart?.render();
});

watch(
    () => props.stats,
    () => {
        if (myChart?.data?.datasets[0].data) {
            (myChart.data.labels = props.stats.map((stat: IStat) => stat.name)),
                (myChart.data.datasets[0].data = props.stats.map(
                    (stat: IStat) => stat.count
                )),
                myChart.update();
        }
    }
);
</script>
<style scoped>
/* .chart-calculated-width {
    width: calc(100% - 20px);
} */
</style>
