export default () => {
    const download = async (url: string, filename?: string) => {
        const res = await axios.get(url, { responseType: 'blob' });
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const newUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = newUrl;

        const name = filename ?? 'File';
        link.setAttribute('download', name + '.pdf');

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    };

    const show = async (url: string) => {
        const res = await axios.get(url, { responseType: 'blob' });
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const newUrl = window.URL.createObjectURL(blob);
        window.open(newUrl, '_blank');
    };

    return {
        download,
        show,
    };
};
