<template>
    <div>
        <Editor v-bind="{ ...props }"></Editor>
    </div>
</template>

<script setup lang="ts">
import Editor from '@components/Editor/index.vue';
import { ISavedBlock, IStage } from '@topol.io/editor-vue';

type CurrentUser = {
    id: number;
    name: string;
    profile_photo_path: string;
    profile_photo_url: string;
};

interface Props {
    apiKey: string;
    token: string;
    definition: string;
    saveUrl: string;
    backUrl: string;
    savedBlocks: ISavedBlock[];
    saveSavedBlockUrl: string;
    getAutosaveUrl: string;
    getConversationsUrl: string;
    getCommentsUrl: string;
    sendTestEmailUrl: string;
    createAutosaveUrl: string;
    getAutosavesUrl: string;
    theme: string;
    fontSizes: string;
    defaultFonts: string;
    customFonts: string;
    smartMergeTags: string;
    mergeTags: string;
    currentUser: CurrentUser;
    ownerId: string | number;
    templateId: number;
    language?: string;
    colors: string;
    title?: string;
    stage?: IStage;
    enableMergeTagPreview?: boolean;
    imageCompressionOptions: string;
    getFeedsUrl: string;
    getProductsUrl: string;
    enableImageToTextRatio: boolean;
    chatAI: boolean;
    googlePromotionalAnnotation: boolean;
    lambdaStage: 'production' | 'develop';
    userRole: string;
    templateStatus: string|null;
    htmlMinified: boolean;
    enableSectionVariants: boolean;
    isSubscribed: boolean;
    testingEmails: string;
}

const props = withDefaults(defineProps<Props>(), {
    language: 'en',
    title: '',
    stage: 'production',
    lambdaStage: 'production',
});
</script>

<script lang="ts">
import BlankLayout from '@/Layouts/Blank.vue';

export default {
    layout: BlankLayout,
};
</script>
