<template>
    <div class="py-12">
        <div class="flex justify-between gap-2">
            <div class="flex gap-2">
                <AtomicButton
                    size="md"
                    color="yellow"
                    class="font-medium"
                    :disabled="!order.length"
                    @click="saveOrder"
                >
                    Save order
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    class="font-medium"
                    @click="restoreOrder"
                >
                    Restore defaults
                </AtomicButton>
            </div>

            <AtomicButton
                size="md"
                color="red"
                class="font-medium"
                @click="handleRerender"
            >
                Trigger Web Rerender
            </AtomicButton>
        </div>

        <div class="mt-10 flex flex-wrap justify-start gap-4">
            <div
                v-for="(template, i) in templatesWithoutFromScratch"
                :key="i"
                class="mb-4"
                @click="handleTemplateClick(template.id)"
            >
                <button class="relative">
                    <img
                        :src="template.img_thumb_url"
                        class="h-[332px] w-56 rounded-md transition duration-200 ease-in-out hover:shadow-md"
                    />
                    <div
                        v-if="order.includes(template.id)"
                        class="absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-md bg-gray-600 bg-opacity-70"
                    >
                        <div class="text-4xl font-black text-orange-500">
                            <!-- get index of template id  in order array-->
                            {{ order.indexOf(template.id) + 1 }}
                        </div>
                    </div>

                    <div
                        v-if="template.type === 'PRO'"
                        class="absolute top-[-10px] left-[30px] rounded-lg bg-orange-500 px-3 py-1 text-xs font-medium"
                    >
                        PRO
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IPaginationData from '@/types/Atomic/IPaginationData';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import useConfirm from '@/composables/useConfirm';
import { create } from '@/composables/useNotifications';

const props = defineProps<{
    premadeTemplates: IPaginationData<IPremadeTemplate>;
}>();

const templatesWithoutFromScratch = computed(() => {
    return props.premadeTemplates.filter((a: IPremadeTemplate) => a.id !== 1);
});

const order = ref<number[]>([]);
const { show: showConfirmation } = useConfirm();

const handleTemplateClick = (id: number) => {
    //push value if not in array, remove if in array
    if (order.value.includes(id)) {
        order.value = order.value.filter((item) => item !== id);
    } else {
        order.value.push(id);
    }
};

const saveOrder = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to save this order of templates?`,
        type: 'info',
    });

    if (confirmed) {
        router.post(
            route('kiosk.premade-templates.order.store'),
            {
                order: order.value,
            },
            {
                preserveScroll: true,
                preserveState: false,
            }
        );
    }
};

const restoreOrder = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to restore the default order?`,
        type: 'info',
    });
    if (confirmed) {
        router.put(
            route('kiosk.premade-templates.order.update'),
            {},
            {
                preserveScroll: true,
                preserveState: false,
            }
        );
    }
};

const handleRerender = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to trigger Topol web rerender? Remember not to do this too often.`,
        type: 'danger',
    });

    if (confirmed) {
        const res = await axios.post(
            route('kiosk.premade-templates.order.rerender.store')
        );
        if (res.data.success) {
            create({
                title: 'Rerender triggered',
                type: 'success',
            });
        } else {
            create({
                title: 'Something went wrong',
                type: 'error',
            });
        }
    }
};
</script>
