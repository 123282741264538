<template>
    <div>
        <SendGridIntegrationModal
            v-if="sendgridIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (sendgridIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <SendyIntegrationModal
            v-if="sendyIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (sendyIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <BrevoIntegrationModal
            v-if="brevoIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (brevoIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ConstantContactIntegrationModal
            v-if="constantcontactIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (constantcontactIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MailchimpIntegrationModal
            v-if="mailchimpIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (mailchimpIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <KlaviyoIntegrationModal
            v-if="klaviyoIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (klaviyoIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MailjetIntegrationModal
            v-if="mailjetIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (mailjetIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <GmailIntegrationModal
            v-if="gmailIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (gmailIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <HubspotIntegrationModal
            v-if="hubspotIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (hubspotIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <CampaignMonitorIntegrationModal
            v-if="campaignmonitorIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (campaignmonitorIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ActiveCampaignIntegrationModal
            v-if="activecampaignIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (activecampaignIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MoosendIntegrationModal
            v-if="moosendIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (moosendIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ClickSendIntegrationModal
            v-if="clicksendIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (clicksendIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <BenchmarkEmailIntegrationModal
            v-if="benchmarkemailIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (benchmarkemailIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MailgunIntegrationModal
            v-if="mailgunIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (mailgunIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <AWeberIntegrationModal
            v-if="aweberIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (aweberIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MailUpIntegrationModal
            v-if="mailupIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (mailupIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <MailerLiteIntegrationModal
            v-if="mailerliteIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (mailerliteIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <AcumbamailIntegrationModal
            v-if="acumbamailIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (acumbamailIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <GetResponseIntegrationModal
            v-if="getresponseIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (getresponseIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ZohoCampaignsIntegrationModal
            v-if="zohocampaignsIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (zohocampaignsIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ZohoMailIntegrationModal
            v-if="zohomailIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (zohomailIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <ZohoMarketingIntegrationModal
            v-if="zohomarketingIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (zohomarketingIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <SendPulseIntegrationModal
            v-if="sendpulseIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (sendpulseIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <OutlookWebIntegrationModal
            v-if="outlookwebIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (outlookwebIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <CloseIntegrationModal
            v-if="closeIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (closeIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <IContactIntegrationModal
            v-if="icontactIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (icontactIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />

        <CleverReachIntegrationModal
            v-if="cleverReachIntegrationModalOpened"
            :integration="selectedIntegration"
            @close="
                (cleverReachIntegrationModalOpened = false),
                    (selectedIntegration = undefined)
            "
        />
    </div>
</template>

<script lang="ts" setup>
import useIntegrations from '../composables/useIntegrations';

const {
    sendgridIntegrationModalOpened,
    sendyIntegrationModalOpened,
    brevoIntegrationModalOpened,
    constantcontactIntegrationModalOpened,
    mailchimpIntegrationModalOpened,
    klaviyoIntegrationModalOpened,
    mailjetIntegrationModalOpened,
    gmailIntegrationModalOpened,
    hubspotIntegrationModalOpened,
    campaignmonitorIntegrationModalOpened,
    activecampaignIntegrationModalOpened,
    moosendIntegrationModalOpened,
    clicksendIntegrationModalOpened,
    benchmarkemailIntegrationModalOpened,
    mailgunIntegrationModalOpened,
    aweberIntegrationModalOpened,
    mailupIntegrationModalOpened,
    mailerliteIntegrationModalOpened,
    acumbamailIntegrationModalOpened,
    getresponseIntegrationModalOpened,
    zohocampaignsIntegrationModalOpened,
    zohomarketingIntegrationModalOpened,
    zohomailIntegrationModalOpened,
    sendpulseIntegrationModalOpened,
    outlookwebIntegrationModalOpened,
    closeIntegrationModalOpened,
    icontactIntegrationModalOpened,
    cleverReachIntegrationModalOpened,
    selectedIntegration,
} = useIntegrations();
</script>
