<template>
    <div data-cy="plugin-settings-edit-domains-modal">
        <Modal size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    <span v-if="!currentSettings">Setup your own </span>
                    <span v-else>Edit </span>
                    <span v-if="google">Google Cloud</span>
                    <span v-if="amazon">Amazon S3</span>
                    bucket
                </div>
            </template>

            <template #body>
                <div class="p-6">
                    <div>
                        <TextInput
                            v-model="v$.form.settings.name.$model"
                            placeholder="Enter your bucket name"
                            label="Bucket name"
                            :error="getError('name')"
                            required
                        />

                        <TextInput
                            v-model="v$.form.settings.region.$model"
                            class="mt-6"
                            placeholder="europe-west3"
                            label="Region"
                            :error="getError('region')"
                            required
                        />

                        <TextInput
                            v-if="google"
                            v-model="state.form.settings.email"
                            class="mt-6"
                            placeholder="myowntopolioplugin@example-io.iam.gserviceaccount.com"
                            label="Client Email"
                            :error="getError('email')"
                            required
                            @blur="v$.form.settings.email.$touch()"
                        />

                        <TextInput
                            v-if="google"
                            v-model="v$.form.settings.projectID.$model"
                            class="mt-6"
                            placeholder="My-own-google-project-id"
                            label="Project ID"
                            required
                            :error="getError('projectID')"
                        />

                        <TextInput
                            v-if="amazon"
                            v-model="v$.form.settings.access_key.$model"
                            class="mt-6"
                            placeholder="Enter your access key"
                            label="Access key"
                            required
                            :error="getError('access_key')"
                        />

                        <TextAreaInput
                            class="mt-6"
                            placeholder="Enter your private key"
                            label="Private key"
                            required
                            :error="getError('private_key')"
                            :model-value="state.form.settings.private_key"
                            @update:model-value="handleUpdatePrivateKey"
                            @blur="v$.form.settings.private_key.$touch()"
                        />

                        <TextInput
                            v-if="amazon"
                            v-model="
                                v$.form.settings.storage_aws_cloudfront_url
                                    .$model
                            "
                            class="mt-6"
                            placeholder="https://d111111abcdef8.cloudfront.net"
                            label="CloudFront URL"
                            :error="getError('storage_aws_cloudfront_url')"
                        />
                    </div>
                    <div class="mt-6 pb-[5px]">
                        <p class="text-sm leading-normal text-gray-500">
                            For more information how to setup
                            <span v-if="google">Google Cloud</span>
                            <span v-if="amazon">Amazon S3</span>
                            bucket please <br />
                            <a
                                href="https://docs.topol.io/guide/custom-storage.html"
                                target="_blank"
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >check out our documentation</a
                            >.
                        </p>
                    </div>
                </div>

                <div
                    v-if="currentSettings"
                    class="border-t border-gray-200 p-6 px-6 pt-4 pb-[18px]"
                >
                    <div class="text-sm font-medium text-gray-700">
                        Detach
                        <span v-if="currentSettings"></span>
                        <span v-if="google">Google Cloud</span>
                        <span v-if="amazon">Amazon S3</span>
                        bucket
                    </div>
                    <div class="mt-[7px] text-sm">
                        After detaching
                        <span v-if="google">Google Cloud</span>
                        <span v-if="amazon">Amazon S3</span>
                        bucket, all of your Plugin users won't be able to access
                        files on your storage. You can reconnect your storage
                        bucket again later.
                    </div>
                    <button
                        class="mt-4 text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        @click="$emit('deleteModal')"
                    >
                        Begin detaching
                        <span v-if="google">Google Cloud</span>
                        <span v-if="amazon">Amazon S3</span>
                        bucket
                    </button>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        color="white"
                        size="md"
                        data-cy="plugin-settings-update-domains-cancel"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        size="md"
                        color="yellow"
                        data-cy="plugin-settings-update-domains-confirm"
                        :disabled="loading || v$.$invalid"
                        @click="saveStorage"
                    >
                        <span v-if="!currentSettings">Finish setup</span>
                        <span v-else>Update custom storage</span>
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { create } from '@/composables/useNotifications';
import route from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, maxLength, email } from '@vuelidate/validators';
import IToken from '@/types/Plugin/IToken';
import IInputErrors from '@/types/Atomic/IInputErrors';
import ITokenSettings from '@/types/Plugin/ITokenSettings';

const props = defineProps({
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
    google: {
        type: Boolean,
        default: false,
    },
    amazon: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close', 'save', 'deleteModal']);

const currentSettings = ref<ITokenSettings | null>(
    props.token.settings ?? null
);
const loading = ref(false);
const errors = ref<IInputErrors>({});

const state = reactive({
    form: {
        settings: {
            name: '',
            region: '',
            email: '',
            projectID: '',
            access_key: '',
            private_key: '',
            storage_aws_cloudfront_url: '',
            amazon: props.amazon,
            google: props.google,
        },
    },
});

const rules = {
    form: {
        settings: {
            name: {
                required,
                maxLength: maxLength(255),
            },
            region: {
                required,
                maxLength: maxLength(255),
            },
            email: {
                required: requiredIf(props.google),
                maxLength: maxLength(255),
                email,
            },
            projectID: {
                required: requiredIf(props.google),
                maxLength: maxLength(255),
            },
            access_key: {
                required: requiredIf(props.amazon),
                maxLength: maxLength(255),
            },
            private_key: {
                required,
            },
            storage_aws_cloudfront_url: {
                maxLength: maxLength(255),
            },
        },
    },
};
const v$ = useVuelidate(rules, state);

onMounted(() => {
    if (props.token.settings) {
        if (props.token.settings.storage_provider === 'gcs' && props.google) {
            state.form.settings.name =
                props.token.settings.storage_gcs_bucket ?? '';
            state.form.settings.region =
                props.token.settings.storage_gcs_region ?? '';
            state.form.settings.projectID =
                props.token.settings.storage_gcs_project_id ?? '';
            state.form.settings.email =
                props.token.settings.storage_gcs_client_email ?? '';
            state.form.settings.private_key =
                props.token.settings.storage_gcs_private_key ?? '';
        } else if (
            props.token.settings.storage_provider === 'aws' &&
            props.amazon
        ) {
            state.form.settings.name =
                props.token.settings.storage_aws_bucket ?? '';
            state.form.settings.region =
                props.token.settings.storage_aws_region ?? '';
            state.form.settings.access_key =
                props.token.settings.storage_aws_access_key ?? '';
            state.form.settings.private_key =
                props.token.settings.storage_aws_secret_key ?? '';
            state.form.settings.storage_aws_cloudfront_url =
                props.token.settings.storage_aws_cloudfront_url ?? '';
        }
    }
});

const saveStorage = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('api-tokens.storage.update', props.token.id),
            state.form,
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Settings updated',
                        text: 'Your API Token settings has been successfully updated.',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Settings not updated',
                        text: 'There has been an error updating your API Token settings.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const handleUpdatePrivateKey = (value: string) => {
    const updatedValue = value.replaceAll('\\n', '\n');
    state.form.settings.private_key = updatedValue;
};

const getError = (field: string) => {
    return (
        v$.value.form.settings[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>

<style></style>
