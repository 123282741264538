<template>
    <Modal v-if="opened" size="sm" @close="onCancel" @keyup.enter="onSuccess">
        <template v-if="confirmDialogData?.header" #header>
            <p
                :class="{
                    'text-topol-red-600': confirmDialogData?.type === 'danger',
                }"
            >
                {{ confirmDialogData?.header }}
            </p>
        </template>

        <template #body>
            <div v-if="confirmDialogData !== undefined" class="px-6 py-2 mt-2">
                <p>{{ confirmDialogData.message }}</p>
            </div>
            <TextInput
                v-if="confirmDialogData?.hasInput"
                v-model="inputData"
                class="px-6 pb-4"
                autofocus
                :error="errorMessage"
                :label="confirmDialogData?.inputData?.label"
                :placeholder="confirmDialogData?.inputData?.placeholder"
            />
        </template>
        <template #footer>
            <div
                class="flex justify-between px-6 py-4 mt-4 border-t border-gray-200"
            >
                <AtomicButton color="white" @click="onCancel">{{
                    confirmDialogData?.cancel === undefined
                        ? 'Cancel'
                        : confirmDialogData.cancel
                }}</AtomicButton>
                <AtomicButton
                    type="submit"
                    :disabled="!valid"
                    :color="
                        confirmDialogData?.type === 'danger' ? 'red' : 'yellow'
                    "
                    @click="onSuccess"
                    >{{
                        confirmDialogData?.confirm === undefined
                            ? 'Confirm'
                            : confirmDialogData.confirm
                    }}</AtomicButton
                >
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import Modal from '@atomic/Modals/Modal.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import useConfirm from '@composables/useConfirm';

const { opened, confirmDialogData, emitter } = useConfirm();

const inputData = ref('');
const valid = ref(true);

const errorMessage = computed(() => {
    if (valid.value) {
        return '';
    } else {       
        return confirmDialogData.value?.inputData?.validationErrorMessage;
    }
});

watch(inputData, (newValue) => {
    if (
        confirmDialogData.value &&
        confirmDialogData.value.inputData &&
        confirmDialogData.value.inputData.validator
    ) {
        valid.value = confirmDialogData.value?.inputData?.validator(newValue);
    }
});

watch(opened, (newValue) => {
    if (newValue) {
       if (
           confirmDialogData.value &&
           confirmDialogData.value.inputData &&
           confirmDialogData.value.inputData.defaultValue
       ) {
              inputData.value = confirmDialogData.value.inputData.defaultValue;
       }
    }
});

const onCancel = () => {
    if (emitter === undefined) {
        return;
    }

    inputData.value = '';
    emitter.emit('dialog-canceled');
};

const onSuccess = () => {
    if (emitter === undefined) {
        return;
    }

    if (inputData.value === '') {
        emitter.emit('dialog-confirmed');
    } else {
        emitter.emit('dialog-confirmed', inputData.value);

        inputData.value = '';
    }
};
</script>
