<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Mailchimp tags synchronization</div>
        </template>

        <template #body>
            <div class="mb-6 p-6">
                <div v-if="step === 1" class="flex flex-col gap-4">
                    <div class="text-gray-600">
                        Select your Mailchimp integration:
                    </div>

                    <div
                        v-for="integration in mailchimpIntegrations"
                        :key="integration.id"
                    >
                        <RadioInput
                            :model-value="selectedIntegrationId"
                            :option="integration.id"
                            :name="integration.name ?? integration.type"
                            :label="integration.name ?? integration.type"
                            size="lg"
                            @update:model-value="
                                selectIntegrationId(integration.id)
                            "
                        />
                    </div>
                </div>
                <div v-else class="flex flex-col gap-4">
                    <div class="text-gray-600">
                        Select your Mailchimp audience:
                    </div>

                    <div v-if="!loading">
                        <div v-for="list in mailchimpLists" :key="list.id">
                            <RadioInput
                                :model-value="selectedListId"
                                :option="list.id"
                                :name="list.name"
                                :label="list.name"
                                size="lg"
                                @update:model-value="selectListId(list.id)"
                            />
                        </div>
                    </div>

                    <div v-else>
                        <div class="flex justify-center py-5">
                            <LoadingSpinner size="medium" :delay="false" />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    @click="addMailchimpGroup"
                >
                    Confirm
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { IIntegration, IMailchimp } from '@/types/Integrations/IIntegration';
import IMailchimpList from '@/types/Settings/IMailchimpList';
import { PropType } from 'vue';

defineProps({
    mailchimpIntegrations: {
        type: Array as PropType<IIntegration<IMailchimp>[]>,
        default: () => [],
    },
});

const mailchimpLists = ref<IMailchimpList[]>([]);
const selectedListId = ref(0);
const selectedIntegrationId = ref(0);
const loading = ref(false);

const step = ref(1);

const emit = defineEmits(['close', 'store']);

const selectListId = (id: number) => {
    selectedListId.value = id;
};

const selectIntegrationId = (id: number) => {
    selectedIntegrationId.value = id;
    step.value = 2;
    getMailchimpLists();
};

const addMailchimpGroup = () => {
    const list = mailchimpLists.value.find(
        (list) => list.id === selectedListId.value
    );
    emit('store', list, selectedIntegrationId.value);
};

const getMailchimpLists = async () => {
    loading.value = true;
    try {
        const result = await axios.get(
            route('integrations.mailchimp.lists.index', {
                integration: selectedIntegrationId.value,
            })
        );

        if (result.status === 200) {
            mailchimpLists.value = result.data;
            selectedListId.value = mailchimpLists.value[0].id;
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};
</script>
