<template>
    <div class="">
        <div
            v-for="(domain, index) in domainsActual"
            :key="index"
            data-cy="plugin-settings-domain-list"
            class="mt-[11px] flex justify-between gap-1 px-6 text-sm font-medium leading-normal sm:max-w-[470px]"
        >
            <p class="min-w-0 break-words text-gray-800">
                {{ domain }}
            </p>
            <p
                v-if="readyToRemove !== index"
                class="cursor-pointer select-none text-topol-blue-500 hover:text-topol-blue-600"
                @click="showIndex(index)"
            >
                Remove
            </p>
            <p
                v-if="readyToRemove === index"
                class="cursor-pointer select-none text-right text-topol-red-600"
                @click="removeDomain(index)"
            >
                Really remove?
            </p>
        </div>

        <form
            class="mt-[22px] flex flex-col items-start border-t border-gray-200 px-6 pt-4 pb-6 sm:flex-row sm:justify-between"
            @submit.prevent="addDomains"
        >
            <TextInput
                v-model="newDomains"
                class="w-full lg:w-[470px] xl:w-[620px]"
                placeholder="Enter your domain(s)"
                label="Add domain(s) to the list"
                data-cy="plugin-settings-new-domains-input"
            >
                <template #help>
                    List of allowed domains separated by comma.
                </template>
            </TextInput>
            <AtomicButton
                :disabled="!newDomains"
                color="yellow"
                size="md"
                class="mt-6 w-[122px] shrink-0 sm:ml-6"
                data-cy="plugin-settings-new-domains-add"
                type="submit"
            >
                Add domains
            </AtomicButton>
        </form>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import IToken from '@/types/Plugin/IToken';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    domains: {
        type: String,
        default: '',
    },
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
});

const domainsActual = ref<string[]>(
    props.domains ? props.domains.split(',') : []
);
const readyToRemove = ref<number | null>(null);
const newDomains = ref<string>('');
const form = ref({
    token: props.token,
    domains: '',
});

const showIndex = (index: number) => {
    readyToRemove.value = index;
};

const removeDomain = (index: number) => {
    domainsActual.value.splice(index, 1);
    readyToRemove.value = null;
    form.value.domains = domainsActual.value.join(',');
    updateDomains();
};

const updateDomains = async () => {
    try {
        const res = await axios.put(
            route('api-tokens.update', {
                apiToken: props.token.id,
            }),
            form.value
        );
        if (res.status === 204) {
            create({
                title: 'Domains updated',
                text: 'API Token domains have been successfully updated.',
                type: 'success',
            });
            router.visit(route('api-tokens.index'), {
                preserveScroll: true,
            });
        }
    } catch (err) {
        create({
            title: 'Domains not updated',
            text: 'There has been an error updating your domains.',
            type: 'error',
        });
    }
};

const addDomains = () => {
    let domains = newDomains.value.replace(/ /g, '').split(',');

    let filteredDomains = domains.filter(function (el) {
        return el !== '';
    });

    if (props.domains) {
        let domainsNew: string[] = [];
        domainsNew = domainsActual.value.concat(filteredDomains);
        form.value.domains = domainsNew.join(',');
    } else {
        form.value.domains = newDomains.value;
    }
    newDomains.value = '';

    if (form.value.domains) {
        updateDomains();
    }
};
</script>

<style></style>
