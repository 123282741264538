<template>
    <OpenAILogsTabs tab-key="users-per-team" class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="logs"
            :title="`Open AI logs - ${team.name}`"
            searchable
            date-input
        >
        </DataTable>
    </OpenAILogsTabs>
</template>

<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IOpenAILog from '@/types/OpenAILogs/IOpenAILog';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

defineProps<{
    logs: IPaginationData<IOpenAILog>;
    team: ICurrentTeam;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Team ID',
        id: 'team_id',
        sortable: true,
        sortableId: 'team_id',
    },
    {
        text: 'User',
        id: 'user',
        sortable: true,
        sortableId: 'user',
    },
    {
        text: 'Total tokens',
        id: 'total_tokens',
        sortable: true,
        sortableId: 'total_tokens',
    },
];
</script>
