<template>
    <div
        class="mt-4 w-full overflow-x-auto rounded-md bg-white py-[18px] shadow-md sm:overflow-hidden"
    >
        <div class="flex flex-col justify-between gap-4 px-6 sm:flex-row">
            <div class="flex flex-col sm:flex-row sm:items-center">
                <div class="mr-6">
                    <p class="font-medium text-gray-600">
                        {{ selectedOptionTitle }}
                    </p>
                </div>
            </div>

            <div class="flex flex-col gap-2 sm:flex-row">
                <SelectInput
                    :model-value="selectedOption"
                    placeholder="Data"
                    class="w-48"
                    :data="mainStatsOptions"
                    @update:model-value="handleSelect"
                />
            </div>
        </div>
        <div class="chart-calculated-width relative h-64 w-full p-6">
            <canvas id="chartMain" ref="chartCtx"></canvas>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';

const chartCtx = ref<HTMLCanvasElement>();

const props = defineProps({
    dateFrom: {
        type: String,
        default: null,
    },
    dateTo: {
        type: String,
        default: null,
    },
});

const data = ref([]);
let myChart = null as Chart | null;

const selectedOption = ref('new_registrations.verified_count');

const mainStatsOptions = [
    {
        title: 'New users',
        value: 'new_registrations.total_count',
    },
    {
        title: 'New verified users',
        value: 'new_registrations.verified_count',
    },
    {
        title: 'Active users daily',
        value: 'active_users.daily_count',
    },
    {
        title: 'Active users weekly',
        value: 'active_users.weekly_count',
    },
    {
        title: 'New subs',
        value: 'subscriptions.new.total.users',
    },
    {
        title: 'New PRO monthly',
        value: 'subscriptions.new.Pro_monthly_15.users',
    },
    {
        title: 'New PRO yearly',
        value: 'subscriptions.new.Pro_yearly_120.users',
    },
    {
        title: 'New Plugin 50',
        value: 'subscriptions.new.plugin_50.users',
    },
    {
        title: 'New Plugin 1000',
        value: 'subscriptions.new.plugin_1000.users',
    },
    {
        title: 'New Plugin unlimited monthly',
        value: 'subscriptions.new.plugin_unlimited_monthly.users',
    },
    {
        title: 'New Plugin unlimited yearly',
        value: 'subscriptions.new.plugin_unlimited_yearly.users',
    },
    {
        title: 'New Topol team monthly',
        value: 'subscriptions.new.topol_team_monthly.teams',
    },
    {
        title: 'New Topol team yearly',
        value: 'subscriptions.new.topol_team_yearly.teams',
    },
    {
        title: 'MRR total',
        value: 'revenue.mrr',
    },
    {
        title: 'Integrations',
        value: 'integrations.total',
    },
];

const selectedOptionTitle = computed(() => {
    return mainStatsOptions.find(
        (option) => option.value === selectedOption.value
    )?.title;
});

const handleSelect = (value: string) => {
    selectedOption.value = value;
    fetchData();
};

const fetchData = async () => {
    const res = await axios.get(
        route('kiosk.dashboard-main-data.index', {
            date_to: props.dateTo,
            date_from: props.dateFrom,
            option: selectedOption.value,
        })
    );

    if (res.status === 200) {
        data.value = res.data;
    }
};

onMounted(async () => {
    if (chartCtx.value === undefined) {
        return;
    }

    myChart = new Chart(chartCtx.value, {
        type: 'line',
        data: {
            labels: data.value[1],
            datasets: [
                {
                    label: selectedOptionTitle.value,
                    data: data.value[0],
                    fill: false,
                    borderColor: 'rgb(88,80,236)',
                    tension: 0.1,
                },
            ],
        },
        options: {
            interaction: {
                intersect: true,
                mode: 'index',
            },

            scales: {
                y: {
                    grid: {
                        color: '#e6e8eb',
                        drawBorder: false,
                    },
                    ticks: {
                        maxTicksLimit: 6,
                        autoSkip: true,

                        color: '#6B7280',

                        font: {
                            family: 'Inter, Arial, sans-serif',
                        },
                    },
                },
                x: {
                    grid: {
                        display: false,
                    },

                    ticks: {
                        color: '#6B7280',
                        font: {
                            family: 'Inter, Arial, sans-serif',
                            size: 12,
                        },
                    },
                },
            },

            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    backgroundColor: '#252F3F',
                    caretSize: 0,
                    caretPadding: 5,
                    boxHeight: 12,
                    boxWidth: 12,
                    multiKeyBackground: 'rgba(0, 0, 0, 0)',
                    cornerRadius: 8,
                    bodySpacing: 8,
                    padding: 8,

                    xAlign: 'center',
                    bodyFont: {
                        family: 'Inter',
                        size: 13,
                        weight: 'normal',
                    },
                },
            },
        },
    });
    myChart.render();
});

watch(
    () => data.value,
    () => {
        if (myChart?.data?.datasets[0].data) {
            (myChart.data.labels = data.value[1]),
                (myChart.data.datasets[0].data = data.value[0]);
            myChart.data.datasets[0].label = selectedOptionTitle.value;
            myChart.update();
        }
    }
);

watch([() => props.dateFrom, () => props.dateTo], () => {
    fetchData();
});
</script>
<style scoped>
/* .chart-calculated-width {
    width: calc(100% - 20px);
} */
</style>
