<template>
    <TemplateManagerTabs
        class="pb-80"
        tab-key="manager"
        :categories="categories"
        :keywords="keywords"
    >
        <div class="mt-10 flex flex-wrap justify-between gap-4">
            <div class="flex flex-wrap gap-4">
                <MarketplaceSortingButton
                    v-model:sortBy="selectedSortBy"
                    v-model:sortByDirection="selectedSortByDirection"
                    class="flex-shrink-0"
                />

                <PerPageButtonGroup
                    v-model="perPage"
                    :values="[20, 50, 100]"
                ></PerPageButtonGroup>

                <MultipleSelect
                    ref="MultipleSelectCategories"
                    v-model:value="selectedCategories"
                    placeholder="Select categories"
                    class="min-w-[170px] max-w-[240px]"
                    :options="categories"
                />

                <MultipleSelect
                    ref="MultipleSelectKeywords"
                    v-model:value="selectedKeywords"
                    placeholder="Select keywords"
                    class="min-w-[170px] max-w-[240px]"
                    :options="keywords"
                />
            </div>

            <SearchInput
                v-model="searchQuery"
                placeholder="Search marketplace"
                class="max-w-xs"
            />
        </div>

        <div class="mt-6 flex flex-col bg-white">
            <MarketplaceTemplateManagerTemplateListBox
                v-for="template in templates"
                :key="template.id"
                :template="template"
                @edit="handleShowEditTemplateModal"
            />
        </div>

        <div v-if="!isLastPage" class="mt-10 flex w-full justify-center">
            <AtomicButton
                :disabled="isLoading"
                color="white"
                @click="loadNextPage"
                >Load more</AtomicButton
            >
        </div>

        <div v-if="templates.length === 0 && !isLoading">
            <div
                class="mt-6 flex w-full flex-col-reverse items-center justify-center rounded-[4px] border border-gray-200 text-center sm:flex sm:flex-row sm:text-left"
            >
                <div>
                    <h2
                        class="mb-2 max-w-md text-2xl font-medium text-gray-600"
                    >
                        There are no templates yet or your search did not match
                        any templates.
                    </h2>
                </div>
                <div class="mt-10 ml-4 flex-shrink-0">
                    <img
                        class="h-[240px] w-[240px]"
                        src="https://d5aoblv5p04cg.cloudfront.net/application/images/illustrations-empty.png"
                        alt="No templates added yet"
                    />
                </div>
            </div>
        </div>

        <EditMarketplaceTemplateModal
            v-if="selectedTemplate"
            :marketplace-template="selectedTemplate"
            :categories="categories"
            :keywords="keywords"
            @close="selectedTemplate = null"
            @template-updated="handleTemplateUpdated"
        />
    </TemplateManagerTabs>
</template>

<script lang="ts" setup>
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { PropType } from 'vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import { useDebounceFn } from '@vueuse/core';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const {
    templates,
    fetchData,
    search,
    setKeywords,
    setCategories,
    loadNextPage,
    isLoading,
    isLastPage,
    setSortBy,
    setSortDirection,
    setPerPage,
} = useMarketplaceTemplates('created');

onMounted(async () => {
    await fetchData();
});

const selectedKeywords = ref<IKeyword[]>();
const selectedCategories = ref<ICategory[]>();
const selectedTemplate = ref<IMarketplaceTemplate | null>(null);
const selectedSortBy = ref<string>('');
const selectedSortByDirection = ref<'asc' | 'desc'>('asc');
const searchQuery = ref<string>('');

const perPage = ref<number>(20);

watch(selectedSortBy, () => {
    setSortBy(selectedSortBy.value);
});

watch(selectedSortByDirection, () => {
    setSortDirection(selectedSortByDirection.value);
});

watch(perPage, () => {
    setPerPage(perPage.value);
});

watch(selectedKeywords, () => {
    if (selectedKeywords.value !== undefined) {
        let keywords: number[] = [];
        selectedKeywords.value.forEach((keyword) => {
            keywords.push(keyword.id);
        });
        setKeywords(keywords);
    }
});

watch(selectedCategories, () => {
    if (selectedCategories.value !== undefined) {
        let categories: number[] = [];
        selectedCategories.value.forEach((category) => {
            categories.push(category.id);
        });
        setCategories(categories);
    }
});

watch(
    searchQuery,
    useDebounceFn(() => {
        search(searchQuery.value);
    }, 220)
);

const handleShowEditTemplateModal = (template: IMarketplaceTemplate) => {
    selectedTemplate.value = template;
};

const handleTemplateUpdated = () => {
    selectedTemplate.value = null;
    fetchData();
};
</script>
