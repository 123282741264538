<template>
    <div class="flex my-2">
        <TextInput
            v-model="modelColor"
            :label="name"
            placeholder="#ffffff"
        ></TextInput>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['inputColor']);

const modelColor = computed({
    get() {
        return props.color;
    },
    set(val: string) {
        emit('inputColor', val);
    },
});
</script>
