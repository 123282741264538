<template>
    <Tabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-invoices"
    >
        <DataTable
            class="mt-12"
            :title="selectedUser.name + `'s Invoices `"
            searchable
            :headers="headers"
            :items="invoices"
        >
            <template #item-issued_at="{ data, row }">
                <div v-if="data" class="p-6 text-gray-600">
                    {{ format(new Date(data as string), 'MMMM d, yyyy ') }}
                </div>
            </template>

            <template #item-type="{ data }">
                <div
                    class="flex justify-center text-xs font-medium text-gray-700"
                >
                    <div
                        v-if="data === 'PRO'"
                        class="rounded-md bg-orange-200 px-2 py-[3px]"
                    >
                        PRO Subscription
                    </div>
                    <div
                        v-else-if="data === 'Team'"
                        class="rounded-md bg-orange-300 px-2 py-[3px]"
                    >
                        PRO team Subscription
                    </div>
                    <div
                        v-else-if="data === 'Plugin'"
                        class="rounded-md bg-teal-200 px-2 py-[3px]"
                    >
                        Plugin Subscription
                    </div>
                    <div
                        v-else-if="data === 'Additional data'"
                        class="rounded-md bg-purple-200 px-2 py-[3px]"
                    >
                        Additional data
                    </div>
                    <div v-else class="rounded-md bg-green-200 px-2 py-[3px]">
                        Additional users
                    </div>
                </div>
            </template>

            <template #item-amount_currency="{ data }">
                <div class="flex justify-center p-6 text-gray-500">
                    {{ data }}
                </div>
            </template>

            <template #item-status="{ data }">
                <div class="flex justify-center p-6 text-gray-500">
                    <span v-if="!data">Issued</span>
                    <span v-else-if="data === 'stavUhr.castUhr'"
                        >Paid (partially)</span
                    >
                    <span v-else>Paid</span>
                </div>
            </template>

            <template #item-custom_download="{ row }">
                <div
                    class="flex cursor-pointer justify-center p-6 text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    @click="downloadInvoice(row.id)"
                >
                    Download invoice
                </div>
            </template>

            <template #item-custom_show="{ row }">
                <AtomicButton
                    size="sm"
                    color="white"
                    @click="showInvoice(row.id)"
                >
                    <template #icon>
                        <MagnifyingGlassIcon class="h-6 w-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IUser from '@/types/Members/IUser';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';
import route from 'ziggy-js';
import Tabs from '../components/UserTabs.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IInvoice from '@/types/Invoices/IInvoice';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import usePdf from '@/composables/usePdf';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

defineProps<{
    selectedUser: IUser;
    invoices: IPaginationData<IInvoice>;
    userPersonalTeam: ICurrentTeam;
}>();

const { show, download } = usePdf();

const headers: IDataTableHeader[] = [
    {
        text: 'Date',
        id: 'issued_at',

        sortable: true,
        sortableId: 'datVyst',
    },
    {
        text: 'Description',
        id: 'type',
        align: 'center',
        sortable: true,
        sortableId: 'popis',
    },
    {
        text: 'Invoice total',
        id: 'amount_currency',
        align: 'center',
        sortable: true,
        sortableId: 'sumCelkemMen',
    },
    {
        text: 'Status',
        id: 'status',
        align: 'center',
        sortable: true,
        sortableId: 'stavUhrK',
    },
    {
        text: 'Status',
        id: 'status',
        align: 'center',
        sortable: true,
        sortableId: 'stavUhrK',
    },
    {
        text: '',
        id: 'custom_download',
    },
    {
        text: '',
        id: 'custom_show',
    },
];

const downloadInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    download(url, 'Invoice');
};

const showInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    show(url);
};
</script>
