<template>
    <transition
        leave-active-class="transition duration-200 ease-in transform"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="opened"
            class="flex flex-col items-center justify-center border-b border-gray-200 bg-yellow-100 py-4 pl-4 pr-6 sm:flex-row"
        >
            <div>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.25685 3.0991C9.02185 1.7391 10.9788 1.7391 11.7428 3.0991L17.3228 13.0191C18.0728 14.3531 17.1098 15.9991 15.5808 15.9991H4.41985C2.88985 15.9991 1.92685 14.3531 2.67685 13.0191L8.25685 3.0991ZM10.9998 13.0001C10.9998 13.2653 10.8945 13.5197 10.707 13.7072C10.5194 13.8947 10.2651 14.0001 9.99985 14.0001C9.73463 14.0001 9.48028 13.8947 9.29274 13.7072C9.10521 13.5197 8.99985 13.2653 8.99985 13.0001C8.99985 12.7349 9.10521 12.4805 9.29274 12.293C9.48028 12.1055 9.73463 12.0001 9.99985 12.0001C10.2651 12.0001 10.5194 12.1055 10.707 12.293C10.8945 12.4805 10.9998 12.7349 10.9998 13.0001ZM9.99985 5.0001C9.73463 5.0001 9.48028 5.10546 9.29274 5.29299C9.10521 5.48053 8.99985 5.73488 8.99985 6.0001V9.0001C8.99985 9.26532 9.10521 9.51967 9.29274 9.70721C9.48028 9.89474 9.73463 10.0001 9.99985 10.0001C10.2651 10.0001 10.5194 9.89474 10.707 9.70721C10.8945 9.51967 10.9998 9.26532 10.9998 9.0001V6.0001C10.9998 5.73488 10.8945 5.48053 10.707 5.29299C10.5194 5.10546 10.2651 5.0001 9.99985 5.0001Z"
                        fill="#D69E2E"
                    />
                </svg>
            </div>
            <div
                class="mt-2 flex max-w-xs flex-col pl-[14px] text-center text-sm sm:mt-0 sm:text-left md:max-w-sm lg:max-w-none 2xl:flex-row 2xl:items-center"
            >
                <p
                    class="font-medium text-yellow-800"
                    :class="{
                        'lg:max-w-2xl 2xl:max-w-none': type === 'plugin',
                    }"
                >
                    <span v-if="type === 'pro-team' || type === 'pro-personal'">
                        You are now switched to our Free plan.
                    </span>
                    <span v-if="type === 'plugin'" class="lg:max-w-sm">
                        Your plugin API Tokens are now suspended, please select
                        appropriate Plugin subscription to continue.
                    </span>

                    <span v-if="type === 'old-app-pro'" class="lg:max-w-sm">
                        <b>Your prepaid plan is outdated.</b> Team subscriptions
                        have been separated and are now paid individually.<br />Keep
                        your team account active and update your subscription
                        before the 31st of October 2022!<br />
                    </span>
                </p>
                <p class="text-yellow-700">
                    <span v-if="type === 'pro-team' || type === 'pro-personal'">
                        To continue using

                        {{
                            type === 'pro-personal' ? 'TOPOL Pro' : 'Topol Team'
                        }}
                        with all it’s features you need to subscribe to a paid
                        plan.
                    </span>
                </p>
            </div>
            <div
                class="mt-4 flex items-center sm:mt-0 sm:pl-5 md:pl-5 lg:pl-20 2xl:pl-16"
            >
                <a
                    v-if="type === 'old-app-pro'"
                    href="https://support.topol.io/en/articles/6622160-switching-to-the-new-billing-plan"
                    target="_blank"
                >
                    <AtomicButton class="mr-4" size="md" color="white">
                        Learn more
                    </AtomicButton>
                </a>

                <AtomicButton
                    class="mr-4"
                    size="md"
                    color="yellow"
                    @click="handleSubscribe"
                    >Subscribe now</AtomicButton
                >
            </div>
        </div>
    </transition>
</template>
<script lang="ts" setup>
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import { ref } from 'vue';
import route from 'ziggy-js';

const props = defineProps<{
    type: 'pro-personal' | 'pro-team' | 'plugin' | 'old-app-pro';
}>();

const opened = ref(true);

const handleSubscribe = () => {
    if (props.type === 'plugin') {
        router.visit(route('billing.plugin-subscriptions.index'));
    } else {
        router.visit(route('billing.pro-subscriptions.index'));
    }
};
</script>
