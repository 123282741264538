<template>
    <div
        class="mt-4 flex flex-col gap-6 rounded-md border border-gray-300 bg-gray-50 p-6 pb-6"
    >
        <div class="flex gap-4">
            <TextInput
                v-model="v$.givenName.$model"
                type="text"
                required
                name="givenName"
                label="First name"
                placeholder="First name"
                :error="getError('givenName')"
                @blur="touch('givenName')"
            />

            <TextInput
                v-model="v$.surname.$model"
                type="text"
                required
                name="surname"
                label="Last name"
                placeholder="Last name"
                :error="getError('surname')"
                @blur="touch('surname')"
            />
        </div>

        <TextInput
            v-model="state.email"
            type="text"
            name="Email"
            label="Email"
            placeholder="Email"
            :error="getError('email')"
            @blur="touch('email')"
        />

        <TextInput
            v-model="v$.phoneNumber.$model"
            type="text"
            name="Phone"
            label="Phone number"
            placeholder="Phone number"
            :error="getError('phoneNumber')"
            @blur="touch('phoneNumber')"
        />

        <SelectInput
            v-model="state.countryCode"
            placeholder="Choose your country of residence"
            :data="countries"
            label="Country"
            required
            :error="getError('countryCode')"
            :disabled="countriesLoading"
            search
            @blurred="touch('countryCode')"
        />

        <TextInput
            v-model="v$.streetAddress.$model"
            type="text"
            required
            name="streetAddress"
            label="Address"
            placeholder="Address"
            :error="getError('streetAddress')"
            @blur="touch('streetAddress')"
        />

        <TextInput
            v-model="v$.extendedAddress.$model"
            type="text"
            name="extendedAddress"
            label="Address (optional)"
            placeholder="Address (optional)"
            :error="getError('extendedAddress')"
            @blur="touch('extendedAddress')"
        />

        <TextInput
            v-model="v$.locality.$model"
            type="text"
            required
            name="locality"
            label="City"
            placeholder="City"
            :error="getError('locality')"
            @blur="touch('locality')"
        />

        <div class="flex gap-4">
            <TextInput
                v-model="v$.region.$model"
                type="text"
                name="region"
                label="Region (optional)"
                placeholder="Region (optional)"
                :error="getError('region')"
                @blur="touch('region')"
            />

            <TextInput
                v-model="v$.postalCode.$model"
                type="text"
                required
                name="postalCode"
                label="Postal Code"
                placeholder="Postal Code"
                :error="getError('postalCode')"
                @blur="touch('postalCode')"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import ITeamCardAdditionalInformation from '@/types/Members/ITeamCardAdditionalInformation';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, email } from '@vuelidate/validators';
import { PropType } from 'vue';

const props = defineProps({
    cardAdditionalInformation: {
        type: Object as PropType<ITeamCardAdditionalInformation>,
        default: () => ({
            given_name: '',
            surname: '',
            phone_number: '',
            street_address: '',
            extended_address: '',
            locality: '',
            region: '',
            postal_code: '',
            country_code: '',
            email: '',
        }),
    },
    // TODO: add errors
    errors: {
        type: Object,
        default: () => ({
            givenName: '',
            surname: '',
        }),
    },
});

const emit = defineEmits([
    'update:checked',
    'update:cardAdditionalInformation',
]);

const state = reactive({
    givenName: props.cardAdditionalInformation.given_name ?? '',
    surname: props.cardAdditionalInformation.surname ?? '',
    phoneNumber: props.cardAdditionalInformation.phone_number ?? '',
    streetAddress: props.cardAdditionalInformation.street_address ?? '',
    extendedAddress: props.cardAdditionalInformation.extended_address ?? '',
    locality: props.cardAdditionalInformation.locality ?? '',
    region: props.cardAdditionalInformation.region ?? '',
    postalCode: props.cardAdditionalInformation.postal_code ?? '',
    countryCode: props.cardAdditionalInformation.country_code ?? '',
    email: props.cardAdditionalInformation.email ?? '',
});
const rules = {
    givenName: {
        required,
        maxLength: maxLength(255),
    },
    surname: {
        required,
        maxLength: maxLength(255),
    },
    phoneNumber: {
        maxLength: maxLength(255),
    },
    streetAddress: {
        required,
        maxLength: maxLength(255),
    },
    extendedAddress: {
        maxLength: maxLength(255),
    },
    locality: {
        required,
        maxLength: maxLength(255),
    },
    region: {
        maxLength: maxLength(255),
    },
    postalCode: {
        required,
        maxLength: maxLength(255),
    },
    countryCode: {
        required,
    },
    email: {
        email,
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const countries = ref([]);
const countriesLoading = ref(false);

onMounted(async () => {
    countriesLoading.value = true;
    try {
        const res = await axios.get(route('countries.index'));
        countries.value = res.data.data;
    } catch (error) {
        () => undefined;
    } finally {
        countriesLoading.value = false;
    }
});

const getError = (field: string) => {
    return (
        v$.value[field]?.$errors[0]?.$message ||
        props.errors.value?.[field] ||
        ''
    );
};

const touch = (field: string) => {
    v$.value[field].$touch();

    if (v$.value.$invalid) {
        return;
    } else {
        emit('update:cardAdditionalInformation', {
            given_name: state.givenName,
            surname: state.surname,
            phone_number: state.phoneNumber,
            street_address: state.streetAddress,
            extended_address: state.extendedAddress,
            locality: state.locality,
            region: state.region,
            postal_code: state.postalCode,
            country_code: state.countryCode,
            email: state.email,
        });
    }
};
</script>
