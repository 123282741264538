<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Subscriptions">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { ArchiveBoxXMarkIcon, SwatchIcon } from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';

defineProps({
    tabKey: {
        type: String,
        default: 'subscriptions',
    },
});

let tabs: TabItem[] = [
    {
        title: 'Subscriptions',
        key: 'subscriptions',
        icon: SwatchIcon,
        url: route('kiosk.subscriptions.index'),
    },
    {
        title: 'Recent Subscriptions',
        key: 'recent-subscriptions',
        icon: SwatchIcon,
        url: route('kiosk.recent-subscriptions.index'),
    },
    {
        title: 'Active Plugin Subscriptions',
        key: 'active-plugin-subscriptions',
        icon: SwatchIcon,
        url: route('kiosk.active-plugin-subscriptions.index'),
    },
    {
        title: 'Cancel Reasons',
        key: 'cancel-reasons',
        icon: ArchiveBoxXMarkIcon,
        url: route('kiosk.subscriptions.cancel-reasons.index'),
    },
];
</script>
