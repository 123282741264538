<template>
    <IntegrationModal
        title="Outlook Web"
        documentation-url="https://support.topol.io/en/articles/8694487-export-to-outlook-web"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createOAuthUrl"
        @edit="updateIntegration"
    >
        <template #inputs>
            <TextInput
                v-model="v$.name.$model"
                class="mt-6"
                placeholder="Enter your preferred integration name"
                label="Name"
                :error="getError('name')"
            />
        </template>
    </IntegrationModal>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IIntegration, IMailchimp } from '@/types/Integrations/IIntegration';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<IMailchimp>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});

const state = reactive({
    name: props.integration?.name || '',
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};

const createOAuthUrl = async () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        try {
            const result = await axios.get(
                route('integrations.outlook-web.o-auth-two-url', {
                    name: state.name,
                })
            );

            if (result.data.success) {
                emit('close');
                window.location.href = result.data.data;
            } else {
                create({
                    title: 'Integration not added',
                    text: 'There was an error adding Outlook Web integration.',
                    type: 'error',
                });
            }
        } catch (e) {
            create({
                title: 'Integration not added',
                text: 'There was an error adding Outlook Web integration.',
                type: 'error',
            });
        } finally {
            loading.value = false;
        }
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('integrations.outlook-web.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating Outlook Web integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
