<template>
    <button
        type="button"
        class="group relative flex items-center gap-1"
        @click="onOpen"
    >
        <ShoppingCartIcon
            class="group-hover:shake h-6 w-6"
            :class="{
                'text-orange-500': cart && cart.shopping_cart_items?.length > 0,
                'text-white':
                    !cart ||
                    !cart.shopping_cart_items ||
                    cart.shopping_cart_items.length === 0,
            }"
        >
        </ShoppingCartIcon>
        <div
            class="mr-6 hidden text-xs font-medium sm:block"
            :class="{
                'text-orange-500': cart && cart.shopping_cart_items?.length > 0,
                'text-white':
                    !cart ||
                    !cart.shopping_cart_items ||
                    cart.shopping_cart_items.length === 0,
            }"
        >
            My Cart
        </div>
        <div
            v-if="cart && cart.shopping_cart_items.length > 0"
            class="absolute top-[-7px] right-[9px] hidden h-4 w-4 items-center justify-center rounded-full bg-gray-900 text-xs text-white shadow-md ring-1 ring-gray-300 sm:flex"
        >
            {{ cart?.shopping_cart_items?.length }}
        </div>
    </button>
</template>

<script lang="ts" setup>
import useMarketplaceCart from '@/composables/useMarketplaceCart';
import { ShoppingCartIcon } from '@heroicons/vue/24/solid';

const { open, cart, fetchCart } = useMarketplaceCart();

onMounted(() => {
    fetchCart();
});
const onOpen = () => {
    open();
};
</script>

<style scoped></style>
