<template>
    <Dropdown
        v-model="selectedItem"
        class="text-sm"
        type="medium"
        placeholder="Sort by"
        :items="sortOptions"
    >
        <template #left-icon>
            <button
                :disabled="sortBy === ''"
                class="w-5 h-5 mr-2 text-gray-500 hover:text-gray-500 focus:outline-none"
                @click.stop="changeSortDirection"
            >
                <svg
                    v-if="sortDirection === 'asc'"
                    class="text-gray-500"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H12C12.2652 2 12.5196 1.89464 12.7071 1.70711C12.8946 1.51957 13 1.26522 13 1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0H1ZM1 4C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H8C8.26522 6 8.51957 5.89464 8.70711 5.70711C8.89464 5.51957 9 5.26522 9 5C9 4.73478 8.89464 4.48043 8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4H1ZM1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H5C5.26522 10 5.51957 9.89464 5.70711 9.70711C5.89464 9.51957 6 9.26522 6 9C6 8.73478 5.89464 8.48043 5.70711 8.29289C5.51957 8.10536 5.26522 8 5 8H1ZM13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V10.586L9.707 9.293C9.5184 9.11084 9.2658 9.01005 9.0036 9.01233C8.7414 9.0146 8.49059 9.11977 8.30518 9.30518C8.11977 9.49059 8.0146 9.7414 8.01233 10.0036C8.01005 10.2658 8.11084 10.5184 8.293 10.707L11.293 13.707C11.4805 13.8945 11.7348 13.9998 12 13.9998C12.2652 13.9998 12.5195 13.8945 12.707 13.707L15.707 10.707C15.8892 10.5184 15.99 10.2658 15.9877 10.0036C15.9854 9.7414 15.8802 9.49059 15.6948 9.30518C15.5094 9.11977 15.2586 9.0146 14.9964 9.01233C14.7342 9.01005 14.4816 9.11084 14.293 9.293L13 10.586V5Z"
                        fill="currentColor"
                    />
                </svg>

                <svg
                    v-if="sortDirection === 'desc'"
                    class="text-gray-500 rotate-180"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H12C12.2652 2 12.5196 1.89464 12.7071 1.70711C12.8946 1.51957 13 1.26522 13 1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0H1ZM1 4C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H8C8.26522 6 8.51957 5.89464 8.70711 5.70711C8.89464 5.51957 9 5.26522 9 5C9 4.73478 8.89464 4.48043 8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4H1ZM1 8C0.734784 8 0.48043 8.10536 0.292893 8.29289C0.105357 8.48043 0 8.73478 0 9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H5C5.26522 10 5.51957 9.89464 5.70711 9.70711C5.89464 9.51957 6 9.26522 6 9C6 8.73478 5.89464 8.48043 5.70711 8.29289C5.51957 8.10536 5.26522 8 5 8H1ZM13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V10.586L9.707 9.293C9.5184 9.11084 9.2658 9.01005 9.0036 9.01233C8.7414 9.0146 8.49059 9.11977 8.30518 9.30518C8.11977 9.49059 8.0146 9.7414 8.01233 10.0036C8.01005 10.2658 8.11084 10.5184 8.293 10.707L11.293 13.707C11.4805 13.8945 11.7348 13.9998 12 13.9998C12.2652 13.9998 12.5195 13.8945 12.707 13.707L15.707 10.707C15.8892 10.5184 15.99 10.2658 15.9877 10.0036C15.9854 9.7414 15.8802 9.49059 15.6948 9.30518C15.5094 9.11977 15.2586 9.0146 14.9964 9.01233C14.7342 9.01005 14.4816 9.11084 14.293 9.293L13 10.586V5Z"
                        fill="currentColor"
                    />
                </svg>
            </button>
        </template>

        <template #right-icon>
            <ChevronDownIcon class="w-4 h-4 ml-2" />
        </template>
    </Dropdown>
</template>

<script lang="ts" setup>
import { ChevronDownIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
    sortBy: {
        type: String,
        required: true,
    },

    sortByDirection: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['update:sortBy', 'update:sortByDirection']);

const sortDirection = ref('asc');
const sortOptions = [
    { title: 'Created', id: 'created_at' },
    { title: 'Last Updated', id: 'updated_at' },
    { title: 'Name', id: 'name' },
    { title: 'Status', id: 'status' },
];

const changeSortDirection = () => {
    if (sortDirection.value === 'asc') {
        sortDirection.value = 'desc';
    } else {
        sortDirection.value = 'asc';
    }
    emit('update:sortByDirection', sortDirection.value);
};

const selectedItem = computed({
    get() {
        const option = sortOptions.find((o) => o.id === props.sortBy);
        if (option) {
            return 'Sorted by: ' + option.title;
        }

        return '';
    },
    set(val: string) {
        if (props.sortBy === val) {
            emit('update:sortBy', '');
        } else {
            emit('update:sortBy', val);
        }
    },
});
</script>

<style></style>
