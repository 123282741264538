import useTemplates from './useTemplates';
import { create } from '@composables/useNotifications';

const { fetchData } = useTemplates();
const { initializeCurrentFolder } = useFolders();
const { cancelMultipleSelect } = useTemplates();
const foldersAndTemplates = useFoldersAndTemplates();

const timeout = ref(0);
const listeningForEvents = ref(false);

export const useMultipleTemplateActions = (
    userId: number,
) => {
    
    const startLoading = () => {    
        foldersAndTemplates.loading.value = true;
    
        timeout.value = setTimeout(async () => {
            create({
                title: 'Templates are being processed. It may take a while.',
                type: 'info',
            });
            await fetchData();
            foldersAndTemplates.loading.value = false;
        }, 5000);
    }
    
    const cancelLoading = async () => {
        clearTimeout(timeout.value);
        await fetchData();
        foldersAndTemplates.loading.value = false;
        cancelMultipleSelect();
    }
    
    const listenForEvents = () => {   
        if (listeningForEvents.value === true) {
            return;
            }

        listeningForEvents.value = true;
        
        window.Echo.private('App.Topol.Users.User.' + userId)
            .listen('.App\\Topol\\Templates\\Events\\MultipleTemplatesStatusUpdated', async () => {
                handleEvent('Templates status updated', 'success');
            })
            .listen('.App\\Topol\\Templates\\Events\\MultipleTemplatesDeleted', async () => {
                handleEvent('Templates deleted', 'success');
            })
            .listen('.App\\Topol\\Templates\\Events\\MultipleTemplatesMoved', async () => {
                handleEvent('Templates moved', 'success');
                await initializeCurrentFolder();
            })
            .listen('.App\\Topol\\Templates\\Events\\MultipleTemplatesDuplicated', async () => {
                handleEvent('Templates duplicated', 'success');
            })
            .listen('.App\\Topol\\Templates\\Events\\MultipleTemplatesExceptionThrown', async () => {
                handleEvent('There was an error processing the templates. Please try again later.', 'error');
            });
    };

    const handleEvent = async (event: string, type: 'success'|'error') => {
        create({
            title: event,
            type: type,
        });
        foldersAndTemplates.resumeSearch();
        cancelLoading();
    }

    const stopListeningForEvents = () => {
        listeningForEvents.value = false;
    }

    return {
        startLoading,
        cancelLoading,
        listenForEvents,
        handleEvent,
        stopListeningForEvents,

    }
}

