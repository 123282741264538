<template>
    <Tabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-teams"
    >
        <DataTable
            class="mt-12"
            :title="selectedUser.name + `'s Teams `"
            searchable
            :headers="headers"
            :items="userTeams"
        >
            <template #item-joined_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex w-full justify-center gap-2">
                    <AtomicButton
                        color="white"
                        size="sm"
                        @click="handleShowTeam(row as ICurrentTeam)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>

                    <AtomicButton
                        v-if="row.bucket_blocked_at === null"
                        color="red"
                        size="sm"
                        @click="makeBucketPrivate(row as ICurrentTeam)"
                    >
                        <template #icon>
                            <FireIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>

                    <AtomicButton
                        v-if="row.bucket_blocked_at !== null"
                        color="yellow"
                        size="sm"
                        @click="makeBucketPublic(row as ICurrentTeam)"
                    >
                        <template #icon>
                            <SparklesIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IUser from '@/types/Members/IUser';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';

import {
    MagnifyingGlassIcon,
    FireIcon,
    SparklesIcon,
} from '@heroicons/vue/24/outline';

import Tabs from '../components/UserTabs.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { create } from '@composables/useNotifications';
import useConfirm from '@composables/useConfirm';
const { show } = useConfirm();

defineProps<{
    selectedUser: IUser;
    userTeams: IPaginationData<ICurrentTeam>;
    userPersonalTeam: ICurrentTeam;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Role',
        id: 'role',
        sortable: true,
        sortableId: 'role',
    },
    {
        text: 'Joined at',
        id: 'joined_at',
        align: 'center',
    },
    {
        text: 'Bucket blocked at',
        id: 'bucket_blocked_at',
        align: 'center',
    },
    {
        text: '',
        id: 'custom',
    },
];

const handleShowTeam = function (team: ICurrentTeam) {
    router.get(
        route('kiosk.teams.feature-management.index', {
            team: team.id,
        })
    );
};

const makeBucketPrivate = async (team: ICurrentTeam) => {
    const confirmed = await show({
        header: 'Make bucket private for selected Team',
        message: `Are you sure you want to make bucket private for this team?`,
        type: 'danger',
        confirm: 'Make private',
    });
    if (confirmed) {
        router.post(
            route('kiosk.teams.make-bucket-private', { team: team.id }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as private',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as private',
                        text: 'There was an error making bucket private.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const makeBucketPublic = async (team: ICurrentTeam) => {
    const confirmed = await show({
        header: 'Make bucket public for selected Team',
        message: `Are you sure you want to make bucket public for this team?`,
        type: 'danger',
        confirm: 'Make public',
    });
    if (confirmed) {
        router.post(
            route('kiosk.teams.make-bucket-public', { team: team.id }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as public',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as public',
                        text: 'There was an error making bucket public.',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
