<template>
    <Modal size="full" @close="$emit('close')">
        <template #header>
            <h2 class="text-lg">Publish new marketplace template</h2>
        </template>

        <template #body>
            <div class="bg-gray-100 pb-10">
                <div
                    v-if="!selectedTemplate"
                    class="flex flex-wrap items-center justify-start gap-3 border-b border-gray-300 bg-white p-6"
                >
                    <Breadcrumb
                        v-if="!foldersAndTemplates.searchingMode.value"
                        :path-items="foldersAndTemplates.currentPath.value"
                        :with-template="!!selectedTemplate"
                        @path-clicked="setDir"
                    ></Breadcrumb>
                </div>

                <NewMarketplaceTemplateModalTemplates
                    v-if="!selectedTemplate"
                    @select-template="selectTemplate"
                    @select-folder="setDir"
                />

                <NewMarketplaceTemplateModalPreview
                    v-else
                    :template="selectedTemplate"
                    @close="selectedTemplate = null"
                    @continue="preview = false"
                />

                <PublishMarketplaceTemplateModal
                    v-if="selectedTemplate && !preview"
                    :template="selectedTemplate"
                    :categories="categories"
                    :keywords="keywords"
                    @back="preview = true"
                    @close="preview = true"
                />
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import Modal from '@atomic/Modals/Modal.vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import ITemplate from '@/types/Templates/ITemplate';
import IFolder from '@/types/Templates/IFolder';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const foldersAndTemplates = useFoldersAndTemplates();
const selectedTemplate = ref<ITemplate | null>(null);

defineEmits(['close', 'publish']);

const preview = ref<boolean>(false);

const selectTemplate = (template: ITemplate) => {
    selectedTemplate.value = template;
    preview.value = true;
};

const setDir = async (folder?: IFolder) => {
    if (selectedTemplate.value) {
        selectedTemplate.value = null;
    }
    await foldersAndTemplates.setDir(folder);
};

onMounted(() => {
    setDir();
});

onUnmounted(() => {
    setDir();
});
</script>

<style></style>
