<template>
    <div>
        <Modal size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    <span v-if="!font?.url">
                        Add a new 
                    </span>
                    <span v-else>
                        Edit
                    </span>
                    font</div>
            </template>
            <template #body>
                <div class="px-6 mb-6">
                    <div v-if="step === 1">
                        <TextInput
                            v-model="v$.label.$model"
                            class="w-full mt-5 sm:w-80"
                            placeholder="Enter your custom font name"
                            label="Font name"
                            :error="getError('label')"
                            required
                            autofocus
                        />

                        <TextInput
                            v-model="state.url"
                            class="w-full mt-5 sm:w-80"
                            placeholder="https://fonts.googleapis.com/css?family=Open+Sans"
                            label="Font URL"
                            :error="getError('url')"
                            required
                            @blur="v$.url.$touch()"
                        />

                        <div  v-if="font?.url" class="mt-5">
                            <div class="text-sm font-medium text-gray-700">
                            Delete font
                            </div>
                            <div class="mt-2 text-sm">
                                Permanently delete this font from the custom fonts list. 
                            </div>
                            <AtomicButton
                            class="mt-4"
                                color="red"
                                size="sm"
                                @click="$emit('delete')"
                            >
                                Delete font
                            </AtomicButton>
                        </div>
                    </div>

                    <div v-else>
                        <div v-if="fontCheckLoading">
                            <LoadingSpinner class="my-32" size="medium" />
                        </div>

                        <div v-if="fontFamilies && !fontCheckLoading">
                            <SelectInput
                                v-model="state.style"
                                class="w-full mt-5 sm:w-80"
                                placeholder="Choose a font family"
                                :data="fontFamilies"
                                label="Font family"
                                :disabled="fontFamilies.length === 1"
                            />

                            <MultipleSelect
                                v-model:value="selectedFallbackFonts"
                                class="w-full mt-5 sm:w-80"
                                label="Fallback fonts"
                                placeholder="Select fonts"
                                :options="fallbackFonts"
                            />

                            <FontComponent
                                class="mt-6"
                                checked
                                :font="state"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between p-6 border-t border-gray-200">
                    <AtomicButton
                        v-if="step === 1"
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>

                    <AtomicButton
                        v-if="step === 2"
                        color="white"
                        size="md"
                        @click="goBack"
                    >
                        Back
                    </AtomicButton>

                    <AtomicButton
                        v-if="step === 1"
                        :disabled="fontCheckLoading || v$.$invalid"
                        size="md"
                        color="yellow"
                        @click="getFontFamilies"
                    >
                        Continue
                    </AtomicButton>

                    <AtomicButton
                        v-else
                        :disabled="fontCheckLoading || v$.$invalid"
                        size="md"
                        color="yellow"
                        @click="addFont"
                    >
                        {{ font?.url ? "Save " : "Add " }}font
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import Modal from '@atomic/Modals/Modal.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, url } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';
import useFonts from '../composables/useFonts';
import IMultiSelectable from '@/types/Atomic/IMultiSelectable';

const props = defineProps({
    font: {
        type: Object as PropType<IFont>,
        required: false,
        default: () => {
            return {
                label: '',
                style: '',
                url: '',
            };
        },
    },
});
const emit = defineEmits(['close', 'save', 'delete']);

const fontCheckLoading = ref(false);
const fontFamilies = ref<ISelectable[]>([]);
const step = ref(1);
const selectedFallbackFonts = ref([]);
const fallbackFonts = ref<IMultiSelectable[]>([]);

const { loadExternalStylesheet, defaultFonts } = useFonts();

onBeforeMount(() => {
    populateFallbackFonts();
});

const populateFallbackFonts = () => {
    fallbackFonts.value = defaultFonts
        .map((font) => {
            return {
                title: font.label,
            };
        })
        .concat([
            {
                title: 'serif',
            },
            {
                title: 'sans-serif',
            },
        ]);
};

const state = reactive({
    label: props.font?.label ?? '',
    style: props.font?.style ?? '',
    url: props.font?.url ?? '',
});

const rules = {
    label: { required, maxLength: maxLength(255) },
    url: { required, url, maxLength: maxLength(255) },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || '';
};

const addFont = () => {
    const selectedFallbackFontsArray = selectedFallbackFonts.value.map(
        (item: IMultiSelectable) => item.title
    );
    const styleArray = state.style.split(',').map((style) => style.trim());
    const uniqueElementsArray = [
        ...new Set([...styleArray, ...selectedFallbackFontsArray]),
    ];

    const addBrackets = (element: string) => {
        return element.includes(' ') ? `'${element}'` : element;
    };

    const styleWithFallback = uniqueElementsArray.map(addBrackets).join(', ');

    emit('save', {
        label: state.label,
        style: styleWithFallback,
        url: state.url,
        enabled: true,
    });
};

const goBack = () => {
    step.value = 1;
    fontFamilies.value = [];
    state.style = '';
};

const getFontFamilies = async () => {
    if (v$.value.url.$invalid || v$.value.label.$invalid) {
        return;
    } else {
        try {
            fontCheckLoading.value = true;
            const response = await axios.get(
                route('editor-preferences.custom-fonts.families.index', {
                    url: state.url,
                })
            );

            if (response.data.success === true) {                
                step.value = 2;

                response.data.data.forEach((element: string) => {
                    fontFamilies.value.push({
                        title: element,
                        value: element,
                    });
                });
                
                state.style = fontFamilies.value[0].title;

                loadExternalStylesheet(state.url);
            } else {
                create({
                    title: 'Error',
                    text: 'There was an error fetching font families.',
                    type: 'error',
                });
            }
        } catch (error) {
            () => undefined;
        } finally {
            fontCheckLoading.value = false;
        }
    }
};
</script>
