<template>
    <div class="py-12" data-cy="kiosk-announcements-edit">
        <h1
            class="text-2xl font-medium leading-normal text-gray-800 tracking-small"
        >
            Edit
        </h1>

        <form class="flex flex-col" @submit.prevent="updateAnnouncement">
            <TextInput
                v-model="v$.form.title.$model"
                class="my-6 w-52 sm:w-80"
                label="Title"
                type="text"
                placeholder="max 255 characters"
                data-cy="edit-announcement-title"
                :error="getError('title')"
            />

            <TextInput
                v-model="dateComputed"
                label="Date"
                type="datetime-local"
                class="w-40 mb-6"
            />

            <SelectInput
                v-model="state.form.type"
                class="mb-6 w-52 sm:w-80"
                :data="types"
                label="Type of announcement"
                placeholder="Type"
            />

            <EditorTinyMce
                v-model="v$.form.data.$model"
                api-key="y01dz3hyuzk77t9a3vl38gmcb3v62v3ft8pb8v2kpjuyyequ"
                data-cy="edit-announcement-data"
                :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help',
                }"
            />

            <div v-if="getError('data')">
                <div class="mt-2 text-sm text-topol-red-600">
                    {{ getError('data') }}
                </div>
            </div>

            <TextInput
                v-model="v$.form.button_text.$model"
                class="mt-6 w-52 sm:w-80"
                label="Button Text"
                type="text"
                placeholder="max 255 characters"
                :error="getError('button_text')"
                data-cy="edit-announcement-button-text"
            />

            <TextInput
                v-model="v$.form.button_url.$model"
                class="mt-6 w-52 sm:w-80"
                label="Button URL"
                type="text"
                placeholder="max 2000 characters"
                :error="getError('button_url')"
                data-cy="edit-announcement-button-url"
            />

            <div class="flex flex-row mt-4">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="sm"
                    data-cy="edit-announcement-submit"
                    :disabled="loading || v$.$invalid"
                >
                    Save changes
                </AtomicButton>

                <AtomicButton
                    class="ml-2"
                    size="sm"
                    color="red"
                    data-cy="delete-announcement-submit"
                    @click.prevent="deleteAnnouncement(announcement)"
                >
                    Delete
                    <template #icon>
                        <TrashIcon
                            class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </div>
        </form>
    </div>
</template>

<script lang="ts" setup>
import EditorTinyMce from '@tinymce/tinymce-vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import route from 'ziggy-js';
import { PropType } from 'vue';
import IAnnouncement from '@/types/Announcements/IAnnouncement';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import useConfirm from '@/composables/useConfirm';
import IInputErrors from '@/types/Atomic/IInputErrors';

const props = defineProps({
    announcement: {
        type: Object as PropType<IAnnouncement>,
        required: true,
    },
});

const { show } = useConfirm();

const state = reactive({
    form: {
        title: props.announcement.title,
        publishedAt: props.announcement.published_at,
        type: props.announcement.type,
        data: props.announcement.data,
        button_text: props.announcement.button_text,
        button_url: props.announcement.button_url,
    }
});

const rules = {
    form: {
        title: {
            maxLength: maxLength(255),
        },
        data: {
            required,
            maxLength: maxLength(5000),
        },
        button_text: {
            maxLength: maxLength(255),
        },
        button_url: {
            maxLength: maxLength(2000),
        },
    },
};

const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);
const types = [
    { title: 'News', value: 'news' },
    { title: 'Report', value: 'report' },
    { title: 'Warning', value: 'warning' },
    { title: 'Critical', value: 'critical' },
];
const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const dateComputed = computed({ 
    get: () => {
        return state.form.publishedAt
            ? new Date(state.form.publishedAt)
                  .toISOString()
                  .substring(0, 19)
            : '';
    },
    set: (newValue: string) => {
        state.form.publishedAt = newValue;
    },
});

const updateAnnouncement = () => {
    v$.value.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.patch(
            route('kiosk.announcements.update', {
                announcement: props.announcement.id,
            }),
            state.form,
            {
                preserveScroll: true,
                onError: (err) => {
                    errors.value = err;
                },
                onSuccess: () => {
                    errors.value = {};
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const deleteAnnouncement = async (announcement: IAnnouncement) => {
    const confirmed = await show({
        header: `Delete announcement`,
        message: `Are you sure you want to delete this announcement?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.announcements.destroy', {
                announcement: announcement.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Announcement deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Announcement not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};


</script>

<style></style>
