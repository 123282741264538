<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Blocked resources">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { GlobeAltIcon, ArchiveBoxIcon } from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';

interface TabProps {
    tabKey: string;
}

withDefaults(defineProps<TabProps>(), {
    tabKey: 'blocked-domains',
});

const tabs: TabItem[] = [
    {
        title: 'Domains',
        key: 'blocked-domains',
        icon: GlobeAltIcon,
        url: route('kiosk.blocked-domains.index'),
    },
    {
        title: 'Buckets',
        key: 'blocked-buckets',
        icon: ArchiveBoxIcon,
        url: route('kiosk.blocked-buckets.index'),
    },
];
</script>
