<template>
    <TransitionRoot :show="true" appear as="template">
        <HUIDialog as="div" @close="$emit('close')" @click="$emit('clicked')">
            <div class="fixed inset-0 z-[9998] overflow-y-scroll">
                <div class="w-full min-h-screen px-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-200 ease-out"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="fixed inset-0 bg-black opacity-25"
                            data-testid="modal-background-overlay"
                        />
                    </TransitionChild>

                    <span class="h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <TransitionChild
                        as="template"
                        class="overflow-visible"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <div
                            data-testid="modal-width"
                            :class="{
                                'mt-20 w-full md:w-[416px]': size === 'sm',
                                'mt-20 w-full md:w-[518px]': size === 'md',
                                'mt-10 w-full md:w-[590px]': size === 'lg',
                                'mt-10 w-full md:w-[680px]': size === 'xl',
                                'mt-10 w-full lg:w-[960px]': size === 'xxl',
                                'mt-10 min-h-[calc(100vh-56px)] w-full bg-gray-100 md:min-h-[calc(100vh-64px)]':
                                    size === 'full',
                            }"
                            class="my-8 inline-block transform overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl transition-all"
                        >
                            <DialogTitle
                                as="h3"
                                class="flex rounded-t-[4px] items-center justify-between pl-6 pt-4 pb-[12px] text-lg leading-normal text-gray-600"
                                :class="{
                                    'border-b border-gray-200 bg-gray-50':
                                        grayHeader,
                                }"
                            >
                                <div class="truncate">
                                    <slot name="header"></slot>
                                </div>
                                <button
                                    data-testid="modal-close-button"
                                    class="mr-6"
                                    @click="$emit('close')"
                                >
                                    <XMarkIcon
                                        class="w-6 h-6 text-gray-500 hover:text-gray-700"
                                    />
                                </button>
                            </DialogTitle>

                            <slot name="body"></slot>

                            <slot name="footer"> </slot>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </HUIDialog>
    </TransitionRoot>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import {
    TransitionRoot,
    TransitionChild,
    Dialog as HUIDialog,
    DialogOverlay,
    DialogTitle,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

defineProps({
    size: {
        validator(val: string) {
            return ['sm', 'md', 'lg', 'xl', 'xxl', 'full'].includes(val);
        },
        default: 'md',
    },
    grayHeader: {
        type: Boolean,
        default: true,
    },
});

defineEmits(['close', 'clicked']);

const modal = ref<HTMLElement>();

onMounted(() => {
    if (modal.value !== undefined) {
        modal.value.focus();
    }
});
</script>
