<template>
    <IntegrationsTabs tab-key="active">
        <div v-if="integrations.length !== 0">
            <div
                class="flex flex-wrap w-full my-10 gap-x-4 gap-y-8 md:grid md:grid-cols-2 lg:grid-cols-3"
            >
                <SendGridIntegrationBox
                    v-for="integration in sendgridIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditSendGridIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'SendGrid')
                    "
                />

                <SendyIntegrationBox
                    v-for="integration in sendyIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditSendyIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Sendy')
                    "
                />

                <BrevoIntegrationBox
                    v-for="integration in brevoIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditBrevoIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Brevo')
                    "
                />

                <ConstantContactIntegrationBox
                    v-for="integration in constantcontactIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditConstantContactIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'ConstantContact'
                        )
                    "
                />

                <MailchimpIntegrationBox
                    v-for="integration in mailchimpIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMailchimpIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Mailchimp')
                    "
                />

                <KlaviyoIntegrationBox
                    v-for="integration in klaviyoIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditKlaviyoIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Klaviyo')
                    "
                />

                <MailjetIntegrationBox
                    v-for="integration in mailjetIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMailjetIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Mailjet')
                    "
                />

                <GmailIntegrationBox
                    v-for="integration in gmailIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditGmailIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Gmail')
                    "
                />

                <HubspotIntegrationBox
                    v-for="integration in hubspotIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditHubspotIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Hubspot')
                    "
                />

                <CampaignMonitorIntegrationBox
                    v-for="integration in campaignMonitorIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditCampaignMonitorIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'CampaignMonitor'
                        )
                    "
                />

                <ActiveCampaignIntegrationBox
                    v-for="integration in activeCampaignIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditActiveCampaignIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'ActiveCampaign'
                        )
                    "
                />

                <MoosendIntegrationBox
                    v-for="integration in moosendIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMoosendIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Moosend')
                    "
                />

                <ClickSendIntegrationBox
                    v-for="integration in clickSendIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditClickSendIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'ClickSend')
                    "
                />

                <BenchmarkEmailIntegrationBox
                    v-for="integration in benchmarkEmailIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditBenchmarkEmailIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'BenchmarkEmail'
                        )
                    "
                />

                <MailgunIntegrationBox
                    v-for="integration in mailgunIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMailgunIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Mailgun')
                    "
                />

                <AWeberIntegrationBox
                    v-for="integration in aweberIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditAWeberIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'AWeber')
                    "
                />

                <MailUpIntegrationBox
                    v-for="integration in mailupIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMailUpIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'MailUp')
                    "
                />

                <MailerLiteIntegrationBox
                    v-for="integration in mailerLiteIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditMailerLiteIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'MailerLite')
                    "
                />

                <AcumbamailIntegrationBox
                    v-for="integration in acumbamailIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditAcumbamailIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Acumbamail')
                    "
                />

                <GetResponseIntegrationBox
                    v-for="integration in getResponseIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditGetResponseIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'GetResponse'
                        )
                    "
                />

                <ZohoCampaignsIntegrationBox
                    v-for="integration in zohoCampaignsIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditZohoCampaignsIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'ZohoCampaigns'
                        )
                    "
                />

                <ZohoMailIntegrationBox
                    v-for="integration in zohoMailIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditZohoMailIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'ZohoMail')
                    "
                />

                <ZohoMarketingIntegrationBox
                    v-for="integration in zohoMarketingIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="
                        openEditZohoMarketingIntegrationModal(integration)
                    "
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'ZohoMarketing'
                        )
                    "
                />

                <SendPulseIntegrationBox
                    v-for="integration in sendPulseIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditSendPulseIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'SendPulse')
                    "
                />

                <OutlookWebIntegrationBox
                    v-for="integration in outlookWebIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditOutlookWebIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'OutlookWeb')
                    "
                />

                <CloseIntegrationBox
                    v-for="integration in closeIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditCloseIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'Close')
                    "
                />

                <IContactIntegrationBox
                    v-for="integration in icontactIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditIContactIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(integration.id, 'IContact')
                    "
                />

                <CleverReachIntegrationBox
                    v-for="integration in cleverReachIntegrations"
                    :key="integration.id"
                    :integration="integration"
                    active
                    @openEdit="openEditCleverReachIntegrationModal(integration)"
                    @openRemove="
                        openRemoveIntegrationModal(
                            integration.id,
                            'CleverReach'
                        )
                    "
                />
            </div>

            <RemoveIntegrationModal
                v-if="
                    removeIntegrationModalOpened &&
                    removeIntegrationType !== undefined
                "
                :integration-type="removeIntegrationType"
                @close="closeRemoveIntegrationModal()"
                @remove="deleteIntegration()"
            ></RemoveIntegrationModal>
        </div>

        <div
            v-else
            class="mt-6 flex w-full flex-col-reverse items-center justify-center rounded-[4px] border border-gray-200 text-center sm:flex-row sm:text-left"
        >
            <div>
                <h2 class="max-w-md mb-2 text-2xl font-medium text-gray-600">
                    You haven’t added any integrations yet.
                </h2>

                <p class="max-w-md mb-8 text-sm font-medium text-gray-500">
                    You can add integrations to export your templates to your
                    favorite email marketing tools.
                </p>

                <div class="flex justify-center sm:justify-start">
                    <inertia-link :href="route('integrations.available.index')">
                        <AtomicButton color="yellow" size="lg">
                            See integrations
                        </AtomicButton>
                    </inertia-link>
                </div>
            </div>
            <div class="flex-shrink-0 mt-10 ml-4">
                <img
                    class="h-[240px] w-[240px]"
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/illustrations-empty.png"
                    alt="No templates added yet"
                />
            </div>
        </div>
    </IntegrationsTabs>
</template>

<script lang="ts" setup>
import { create } from '@composables/useNotifications';
import { ref, onMounted, PropType, computed } from 'vue';
import {
    ISendgrid,
    ISendy,
    IBrevo,
    IMailchimp,
    IConstantContact,
    IIntegration,
    IKlaviyo,
    IMailjet,
    IGmail,
    IHubspot,
    ICampaignMonitor,
    IActiveCampaign,
    IMoosend,
    IClickSend,
    IBenchmarkEmail,
    IMailgun,
    IAWeber,
    IMailUp,
    IMailerLite,
    IAcumbamail,
    IGetResponse,
    IZohoCampaigns,
    IZohoMail,
    IZohoMarketing,
    ISendPulse,
    IOutlookWeb,
    IClose,
    IIContact,
    ICleverReach,
} from '@/types/Integrations/IIntegration';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import useIntegrations from '../composables/useIntegrations';

const props = defineProps({
    integrations: {
        // eslint-disable-next-line @typescript-eslint/ban-types
        type: Array as PropType<IIntegration<{}>[]>,
        required: true,
    },
    features: {
        type: Object,
        default: null,
    },
    type: {
        type: String,
        default: null,
    },
});

const {
    sendgridIntegrationModalOpened,
    sendyIntegrationModalOpened,
    brevoIntegrationModalOpened,
    constantcontactIntegrationModalOpened,
    mailchimpIntegrationModalOpened,
    klaviyoIntegrationModalOpened,
    mailjetIntegrationModalOpened,
    gmailIntegrationModalOpened,
    hubspotIntegrationModalOpened,
    campaignmonitorIntegrationModalOpened,
    activecampaignIntegrationModalOpened,
    moosendIntegrationModalOpened,
    clicksendIntegrationModalOpened,
    benchmarkemailIntegrationModalOpened,
    mailgunIntegrationModalOpened,
    aweberIntegrationModalOpened,
    mailupIntegrationModalOpened,
    mailerliteIntegrationModalOpened,
    acumbamailIntegrationModalOpened,
    getresponseIntegrationModalOpened,
    zohocampaignsIntegrationModalOpened,
    zohomailIntegrationModalOpened,
    zohomarketingIntegrationModalOpened,
    sendpulseIntegrationModalOpened,
    outlookwebIntegrationModalOpened,
    closeIntegrationModalOpened,
    icontactIntegrationModalOpened,
    cleverReachIntegrationModalOpened,
    selectedIntegration,
} = useIntegrations();

const inertiaPostOptions = ref({
    preserveScroll: true,
    onSuccess: () => {
        create({
            title: 'Integration added',
            type: 'success',
        });
    },
    onError: () => {
        create({
            title: 'Integration not added',
            text: 'There was an error adding your integration.',
            type: 'error',
        });
    },
});

//Integrations
const removeIntegrationModalOpened = ref(false);
const removeIntegrationType = ref<string>();
const removeIntegrationId = ref<number>();

function openRemoveIntegrationModal(
    integrationId: number,
    integrationType: string
) {
    removeIntegrationModalOpened.value = true;
    removeIntegrationId.value = integrationId;
    removeIntegrationType.value = integrationType;
}

function closeRemoveIntegrationModal() {
    removeIntegrationModalOpened.value = false;
    removeIntegrationId.value = undefined;
    removeIntegrationType.value = undefined;
}

function deleteIntegration() {
    router.delete(
        //@ts-ignore
        route('integrations.delete', {
            integration: removeIntegrationId.value,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Integration removed',
                    text: 'Your integration has been successfully removed.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Integration not removed',
                    text: 'There was an error removing your team integration.',
                    type: 'error',
                });
            },
        }
    );
    // todo handle errors
    closeRemoveIntegrationModal();
}

//SendGrid
const sendgridIntegrations = computed((): IIntegration<ISendgrid>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'SENDGRID') {
            return integration as IIntegration<ISendgrid>;
        }
    });
});

function openEditSendGridIntegrationModal(
    integration: IIntegration<ISendgrid>
) {
    sendgridIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Sendy
const sendyIntegrations = computed((): IIntegration<ISendy>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'SENDY') {
            return integration as IIntegration<ISendy>;
        }
    });
});

function openEditSendyIntegrationModal(integration: IIntegration<ISendy>) {
    sendyIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Brevo
const brevoIntegrations = computed((): IIntegration<IBrevo>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'BREVO') {
            return integration as IIntegration<IBrevo>;
        }
    });
});

function openEditBrevoIntegrationModal(integration: IIntegration<IBrevo>) {
    if (integration.data.api_key) {
        brevoIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

//Constant Contact
const constantcontactIntegrations = computed(
    (): IIntegration<IConstantContact>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'CONSTANT_CONTACT') {
                return integration as IIntegration<IConstantContact>;
            }
        });
    }
);

function openEditConstantContactIntegrationModal(
    integration: IIntegration<IConstantContact>
) {
    if (integration.data.access_token_data) {
        constantcontactIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

const createConstantContactIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('constantcontact')) {
        router.post(
            route('integrations.constant-contact.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//Mailchimp
const mailchimpIntegrations = computed((): IIntegration<IMailchimp>[] => {
    return props.integrations.filter((integration) => {
        if (integration.type === 'MAILCHIMP') {
            return integration as IIntegration<IMailchimp>;
        }
    });
});

function openEditMailchimpIntegrationModal(
    integration: IIntegration<IMailchimp>
) {
    mailchimpIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Klaviyo
const klaviyoIntegrations = computed((): IIntegration<IKlaviyo>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'KLAVIYO') {
            return integration as IIntegration<IKlaviyo>;
        }
    });
});

function openEditKlaviyoIntegrationModal(integration: IIntegration<IKlaviyo>) {
    klaviyoIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Mailjet
const mailjetIntegrations = computed((): IIntegration<IMailjet>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'MAILJET') {
            return integration as IIntegration<IMailjet>;
        }
    });
});

function openEditMailjetIntegrationModal(integration: IIntegration<IMailjet>) {
    mailjetIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Gmail
const gmailIntegrations = computed((): IIntegration<IGmail>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'GMAIL') {
            return integration as IIntegration<IGmail>;
        }
    });
});

function openEditGmailIntegrationModal(integration: IIntegration<IGmail>) {
    gmailIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

const createGmailIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('gmail')) {
        router.post(
            route('integrations.gmail.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//Hubspot
const hubspotIntegrations = computed((): IIntegration<IHubspot>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'HUBSPOT') {
            return integration as IIntegration<IHubspot>;
        }
    });
});

function openEditHubspotIntegrationModal(integration: IIntegration<IHubspot>) {
    hubspotIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

const createHubspotIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('hubspot')) {
        router.post(
            route('integrations.hubspot.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//CampaignMonitor
const campaignMonitorIntegrations = computed(
    (): IIntegration<ICampaignMonitor>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'CAMPAIGN_MONITOR') {
                return integration as IIntegration<ICampaignMonitor>;
            }
        });
    }
);

const openEditCampaignMonitorIntegrationModal = (
    integration: IIntegration<ICampaignMonitor>
) => {
    campaignmonitorIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createCampaignMonitorIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('campaignmonitor')) {
        router.post(
            route('integrations.campaign-monitor.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//ActiveCampaign
const activeCampaignIntegrations = computed(
    (): IIntegration<IActiveCampaign>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'ACTIVE_CAMPAIGN') {
                return integration as IIntegration<IActiveCampaign>;
            }
        });
    }
);

function openEditActiveCampaignIntegrationModal(
    integration: IIntegration<IActiveCampaign>
) {
    activecampaignIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Moosend
const moosendIntegrations = computed((): IIntegration<IMoosend>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'MOOSEND') {
            return integration as IIntegration<IMoosend>;
        }
    });
});

function openEditMoosendIntegrationModal(integration: IIntegration<IMoosend>) {
    moosendIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//ClickSend
const clickSendIntegrations = computed((): IIntegration<IClickSend>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'CLICKSEND') {
            return integration as IIntegration<IClickSend>;
        }
    });
});

function openEditClickSendIntegrationModal(
    integration: IIntegration<IClickSend>
) {
    clicksendIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//BenchmarkEmail
const benchmarkEmailIntegrations = computed(
    (): IIntegration<IBenchmarkEmail>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'BENCHMARK_EMAIL') {
                return integration as IIntegration<IBenchmarkEmail>;
            }
        });
    }
);

function openEditBenchmarkEmailIntegrationModal(
    integration: IIntegration<IBenchmarkEmail>
) {
    benchmarkemailIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//Mailgun
const mailgunIntegrations = computed((): IIntegration<IMailgun>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'MAILGUN') {
            return integration as IIntegration<IMailgun>;
        }
    });
});

function openEditMailgunIntegrationModal(integration: IIntegration<IMailgun>) {
    mailgunIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
}

//AWeber
const aweberIntegrations = computed((): IIntegration<IAWeber>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'AWEBER') {
            return integration as IIntegration<IAWeber>;
        }
    });
});

const openEditAWeberIntegrationModal = (integration: IIntegration<IAWeber>) => {
    aweberIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createAWeberIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('aweber')) {
        router.post(
            route('integrations.aweber.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//MailUp
const mailupIntegrations = computed((): IIntegration<IMailUp>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'MAILUP') {
            return integration as IIntegration<IMailUp>;
        }
    });
});

const openEditMailUpIntegrationModal = (integration: IIntegration<IMailUp>) => {
    mailupIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createMailUpIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('mailup')) {
        router.post(
            route('integrations.mailup.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//MailerLite
const mailerLiteIntegrations = computed((): IIntegration<IMailerLite>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'MAILER_LITE') {
            return integration as IIntegration<IMailerLite>;
        }
    });
});

function openEditMailerLiteIntegrationModal(
    integration: IIntegration<IMailerLite>
) {
    if (integration.data.api_key) {
        mailerliteIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}
// Acumbamail
const acumbamailIntegrations = computed((): IIntegration<IAcumbamail>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'ACUMBAMAIL') {
            return integration as IIntegration<IAcumbamail>;
        }
    });
});

function openEditAcumbamailIntegrationModal(
    integration: IIntegration<IAcumbamail>
) {
    if (integration.data.api_key) {
        acumbamailIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

// GetResponse
const getResponseIntegrations = computed((): IIntegration<IGetResponse>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'GET_RESPONSE') {
            return integration as IIntegration<IGetResponse>;
        }
    });
});

function openEditGetResponseIntegrationModal(
    integration: IIntegration<IGetResponse>
) {
    if (integration.data.api_key) {
        getresponseIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

//ZohoCampaigns
const zohoCampaignsIntegrations = computed(
    (): IIntegration<IZohoCampaigns>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'ZOHO_CAMPAIGNS') {
                return integration as IIntegration<IZohoCampaigns>;
            }
        });
    }
);

const openEditZohoCampaignsIntegrationModal = (
    integration: IIntegration<IZohoCampaigns>
) => {
    zohocampaignsIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createZohoCampaignsIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');
    const location = urlParams.get('location');

    if (code && location && type?.includes('zoho-campaigns')) {
        router.post(
            route('integrations.zoho-campaigns.store'),
            {
                code: code,
                location: location,
            },
            inertiaPostOptions.value
        );
    }
};

//ZohoMail
const zohoMailIntegrations = computed((): IIntegration<IZohoMail>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'ZOHO_MAIL') {
            return integration as IIntegration<IZohoMail>;
        }
    });
});

const openEditZohoMailIntegrationModal = (
    integration: IIntegration<IZohoMail>
) => {
    zohomailIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createZohoMailIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');
    const location = urlParams.get('location');

    if (code && location && type?.includes('zoho-mail')) {
        router.post(
            route('integrations.zoho-mail.store'),
            {
                code: code,
                location: location,
            },
            inertiaPostOptions.value
        );
    }
};

//ZohoMarketing
const zohoMarketingIntegrations = computed(
    (): IIntegration<IZohoMarketing>[] => {
        //@ts-ignore
        return props.integrations.filter((integration) => {
            if (integration.type === 'ZOHO_MARKETING') {
                return integration as IIntegration<IZohoMarketing>;
            }
        });
    }
);

const openEditZohoMarketingIntegrationModal = (
    integration: IIntegration<IZohoMarketing>
) => {
    zohomarketingIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createZohoMarketingIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');
    const location = urlParams.get('location');

    if (code && location && type?.includes('zoho-marketing')) {
        router.post(
            route('integrations.zoho-marketing.store'),
            {
                code: code,
                location: location,
            },
            inertiaPostOptions.value
        );
    }
};

// SendPulse
const sendPulseIntegrations = computed((): IIntegration<ISendPulse>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'SENDPULSE') {
            return integration as IIntegration<ISendPulse>;
        }
    });
});

function openEditSendPulseIntegrationModal(
    integration: IIntegration<ISendPulse>
) {
    if (integration.data.client_id) {
        sendpulseIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

//OutlookWeb
const outlookWebIntegrations = computed((): IIntegration<IOutlookWeb>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'OUTLOOK_WEB') {
            return integration as IIntegration<IOutlookWeb>;
        }
    });
});

const openEditOutlookWebIntegrationModal = (
    integration: IIntegration<IOutlookWeb>
) => {
    outlookwebIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createOutlookWebIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code && props.type === 'outlook') {
        router.post(
            route('integrations.outlook-web.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

//Close
const closeIntegrations = computed((): IIntegration<IClose>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'CLOSE') {
            return integration as IIntegration<IClose>;
        }
    });
});

const openEditCloseIntegrationModal = (integration: IIntegration<IClose>) => {
    closeIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createCloseIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('close')) {
        router.post(
            route('integrations.close.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

// IContact
const icontactIntegrations = computed((): IIntegration<IIContact>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'ICONTACT') {
            return integration as IIntegration<IIContact>;
        }
    });
});

function openEditIContactIntegrationModal(
    integration: IIntegration<IIContact>
) {
    if (integration.data.api_password) {
        icontactIntegrationModalOpened.value = true;
        selectedIntegration.value = integration;
    }
}

//CleverReach
const cleverReachIntegrations = computed((): IIntegration<ICleverReach>[] => {
    //@ts-ignore
    return props.integrations.filter((integration) => {
        if (integration.type === 'CLEVERREACH') {
            return integration as IIntegration<ICleverReach>;
        }
    });
});

const openEditCleverReachIntegrationModal = (
    integration: IIntegration<ICleverReach>
) => {
    cleverReachIntegrationModalOpened.value = true;
    selectedIntegration.value = integration;
};

const createCleverReachIntegration = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const type = urlParams.get('type');

    if (code && type?.includes('cleverreach')) {
        router.post(
            route('integrations.cleverreach.store'),
            {
                code: code,
            },
            inertiaPostOptions.value
        );
    }
};

onMounted(() => {
    createConstantContactIntegration();
    createGmailIntegration();
    createHubspotIntegration();
    createCampaignMonitorIntegration();
    createAWeberIntegration();
    createMailUpIntegration();
    createZohoCampaignsIntegration();
    createZohoMailIntegration();
    createZohoMarketingIntegration();
    createOutlookWebIntegration();
    createCloseIntegration();
    createCleverReachIntegration();
});
</script>
