<template>
    <canvas ref="preview" />
</template>

<script lang="ts" setup>
import useColorPicker from './useColorPicker';

const props = defineProps({
        color: {
            type: String,
            default: '#000000',
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 30,
        },
    });

const alphaSize = 5;
const preview = ref<Element | null>(null);

const { createAlphaSquare } = useColorPicker();

watch( () => props.color, () => {
    renderColor()}
);

onMounted(() => {
    renderColor();
});

const renderColor = () => {
    const canvas = preview.value;
    const width = props.width;
    const height = props.height;
    const size = alphaSize;
    const canvasSquare = createAlphaSquare(size);

    if(!canvas) return;
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    ctx.fillStyle = ctx.createPattern(canvasSquare, 'repeat');
    ctx.fillRect(0, 0, width, height);

    ctx.fillStyle = props.color;
    ctx.fillRect(0, 0, width, height);
};
</script>
