<template>
    <div class="p-6 mt-10 bg-white rounded-md shadow-md">
        <div
            class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="flex flex-col sm:flex-row sm:items-center">
                <div
                    class="h-20 w-20 shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                >
                    <img
                        :src="
                            selectedUser.profile_photo_path
                                ? selectedUser.profile_photo_path
                                : selectedUser.profile_photo_url
                        "
                        class="object-cover w-20 h-20 bg-white rounded-full"
                    />
                </div>
                <div class="flex flex-col mt-2 sm:mt-0 sm:ml-10">
                    <h2 class="text-2xl font-bold text-gray-800">
                        {{ selectedUser.name }}
                    </h2>
                    <div class="text-xl font-medium text-gray-500">
                        {{ selectedUser.email }}
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
            >
                <div class="flex ml-8">
                    <AtomicButton
                        size="sm"
                        color="purple"
                        @click="handleImpersonate(selectedUser)"
                    >
                        <template #icon>
                            <EyeIcon class="w-6 h-6 mr-2" />
                        </template>
                        Impersonate
                    </AtomicButton>
                </div>

                <div class="ml-2">
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        class="mt-5 sm:mt-0"
                        @click="handleResetPassword(selectedUser)"
                    >
                        <template #icon>
                            <AtSymbolIcon class="w-6 h-6 mr-2" />
                        </template>
                        Reset password
                    </AtomicButton>
                </div>

                <div v-if="!personalTeam.flexibee_id" class="ml-2">
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        class="mt-5 sm:mt-0"
                        @click="createFlexibeeId()"
                    >
                        <template #icon>
                            <DocumentTextIcon class="w-6 h-6 mr-2" />
                        </template>
                        Create Flexibee ID
                    </AtomicButton>
                </div>

                <div class="ml-2">
                    <AtomicButton
                        size="lg"
                        color="red"
                        class="mt-5 sm:mt-0"
                        @click="handleDeleteUser(selectedUser)"
                    >
                        <template #icon>
                            <TrashIcon class="w-6 h-6 mr-2" />
                        </template>
                        Delete user
                    </AtomicButton>
                </div>
            </div>
        </div>


        <div class="flex justify-end gap-2">
            <AtomicButton
                color="yellow"
                size="sm"
                @click="showPaymentRequestModal = true"
            >
                <template #icon>
                    <BanknotesIcon class="w-6 h-6 mr-2" />
                </template>

                Payment request
            </AtomicButton>

            <AtomicButton
                v-if="personalTeam.bucket_blocked_at === null"
                size="sm"
                color="red"
                class="mt-5 sm:mt-0"
                @click="makeBucketPrivate(selectedUser)"
            >
                <template #icon>
                    <FireIcon class="w-6 h-6 mr-2" />
                </template>
                Make private
            </AtomicButton>

            <AtomicButton
                v-if="personalTeam.bucket_blocked_at !== null"
                color="yellow"
                size="sm"
                @click="makeBucketPublic(selectedUser)"
            >
                <template #icon>
                    <SparklesIcon class="w-6 h-6 mr-2" />
                </template>

                Make public
            </AtomicButton>
        </div>

        <div class="grid gap-4 mt-5 sm:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="text-sm font-medium text-gray-800">ID:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{ selectedUser.id }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Flexibee ID:
                    </div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            personalTeam.flexibee_id
                                ? 'TOP_' + personalTeam.id
                                : '-'
                        }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">Joined:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            format(
                                new Date(selectedUser.email_verified_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Last login:
                    </div>

                    <div
                        v-if="selectedUser.last_login_at"
                        class="ml-1 text-sm text-gray-500"
                    >
                        {{
                            format(
                                new Date(selectedUser.last_login_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Marked for deletion at:
                    </div>

                    <div
                        v-if="selectedUser.deleted_at"
                        class="ml-1 text-sm text-gray-500"
                    >
                        {{
                            format(
                                new Date(selectedUser.deleted_at),
                                "d. L. yyyy"
                            )
                        }}
                    </div>
                </div>

                <div class="mt-1 text-sm text-gray-500">
                    <div class="font-medium text-gray-800">Subscription:</div>
                    <div v-if="selectedUser.pro_subscription">
                        PRO
                        <span>
                            ({{
                                selectedUser.pro_subscription.braintree_plan
                            }})</span
                        >
                    </div>
                    <div v-if="selectedUser.plugin_subscription">
                        Plugin
                        <span>
                            ({{
                                selectedUser.plugin_subscription.braintree_plan
                            }})</span
                        >
                    </div>
                </div>

                <div class="flex items-center mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Two-factor authentication:
                    </div>

                    <div
                        v-if="selectedUser.two_factor_enabled"
                        class="flex items-center ml-1 text-sm font-medium text-green-600"
                    >
                        Enabled
                        <span
                            class="ml-1 text-xs font-medium underline cursor-pointer text-topol-red-600 hover:opacity-90"
                            @click="
                                handleDisableTwoFactorAuthentication(
                                    selectedUser
                                )
                            "
                            >(disable)</span
                        >
                    </div>
                    <div
                        v-else
                        class="ml-1 text-sm font-medium text-topol-red-600"
                    >
                        Disabled
                    </div>
                </div>

                <div class="flex items-center mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Personal Team ID
                    </div>

                    <div class="ml-1 text-sm font-medium text-topol-red-600">
                        {{ personalTeam.id }}
                    </div>
                </div>

                <div class="flex items-center mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Bucket blocked at:
                    </div>

                    <div class="ml-1 text-sm font-medium text-topol-red-600">
                        {{ personalTeam.bucket_blocked_at }}
                    </div>
                </div>

                <div class="mt-4">
                    <div class="text-sm font-medium text-gray-800">Note:</div>
                    <div class="max-w-xs mt-1 text-base text-gray-500">
                        {{ selectedUser.admin_note }}
                    </div>
                    <div
                        class="mt-1 text-xs font-medium text-blue-500 underline cursor-pointer hover:opacity-90"
                        @click="editNote(selectedUser)"
                    >
                        add/edit note
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="text-sm font-medium text-gray-80">
                    PRO trial ends:

                    <TextInput
                        v-model="proDateComputed"
                        type="datetime-local"
                        class="w-48 mt-3"
                    />
                    <AtomicButton
                        size="sm"
                        color="purple"
                        class="mt-2"
                        @click="handleExtendTrial('pro')"
                    >
                        Extend Pro Trial
                    </AtomicButton>
                </div>

                <div class="mt-4 text-sm font-medium text-gray-80">
                    Plugin trial ends:

                    <TextInput
                        v-model="pluginDateComputed"
                        type="datetime-local"
                        class="w-48 mt-3"
                    />

                    <AtomicButton
                        size="sm"
                        color="purple"
                        class="mt-2"
                        @click="handleExtendTrial('plugin')"
                    >
                        Extend Plugin Trial
                    </AtomicButton>
                </div>
            </div>
        </div>
    </div>

    <MakePaymentRequestModal
        v-if="showPaymentRequestModal"
        :selected-user="selectedUser"
        :personal-team="personalTeam"
        @close="showPaymentRequestModal = false"
    ></MakePaymentRequestModal>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import {
    TrashIcon,
    EyeIcon,
    AtSymbolIcon,
    DocumentTextIcon,
    FireIcon,
    SparklesIcon,
    BanknotesIcon,
} from '@heroicons/vue/24/outline';

import route from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import IUser from '@/types/Members/IUser';
import useConfirm from '@/composables/useConfirm';
import { create } from '@/composables/useNotifications';
import axios from 'axios';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import MakePaymentRequestModal from "@/Pages/Kiosk/PaymentRequests/components/MakePaymentRequestModal.vue";

const props = defineProps<{
    selectedUser: IUser;
    personalTeam: ICurrentTeam;
}>();

const { show } = useConfirm();

const proDate = ref('');
const pluginDate = ref('');

const showPaymentRequestModal = ref(false);

const handleDeleteUser = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Delete user ${selectedUser.name}`,
        message: `Are you sure you want to delete user ${selectedUser.name}?`,
        type: 'danger',
        confirm: 'Delete user',
    });
    if (confirmed) {
        await deleteUser(selectedUser);
    }
};

const handleResetPassword = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Reset password for user ${selectedUser.name}`,
        message: `Are you sure you want to sent reset password link for user ${selectedUser.name}?`,
        type: 'danger',
        confirm: 'Reset password',
    });
    if (confirmed) {
        await resetPassword(selectedUser);
    }
};

const handleImpersonate = (selectedUser: IUser) => {
    router.get(route('kiosk.users.impersonate', { user: selectedUser.id }));
};

const deleteUser = async (selectedUser: IUser) => {
    try {
        const res = await axios.delete(
            route('kiosk.users.delete', { user: selectedUser.id })
        );
        if (res.data.success) {
            create({
                title: `User deleted`,
                type: 'success',
            });
            router.get(route('kiosk.users.index'));
        } else {
            create({
                title: 'User not deleted',
                text: `There was an error deleting user.`,
                type: 'error',
            });
        }
    } catch (err) {
        create({
            title: 'User not deleted',
            text: `There was an error deleting user.`,
            type: 'error',
        });
    }
};

const resetPassword = async (selectedUser: IUser) => {
    try {
        const res = await axios.post(
            route('kiosk.users.reset-password', { user: selectedUser.id })
        );
        if (res.data.success) {
            create({
                title: `Reset link has been sent`,
                type: 'success',
            });
        } else {
            create({
                title: 'Reset link has not been sent',
                text: `There was an error sending reset password link user.`,
                type: 'error',
            });
        }
    } catch (err) {
        create({
            title: 'Reset link has not been sent',
            text: `There was an error sending reset password link user.`,
            type: 'error',
        });
    }
};

const handleDisableTwoFactorAuthentication = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Disable 2FA for user ${selectedUser.name}`,
        message: `Are you sure you want to disable two-factor authentication for user ${selectedUser.name}?`,
        type: 'danger',
        confirm: 'Disable',
    });
    if (confirmed) {
        await disableTwoFactorAuthentication(selectedUser);
    }
};

const disableTwoFactorAuthentication = (selectedUser: IUser) => {
    router.put(
        route('kiosk.users.2fa.disable', { user: selectedUser.id }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: `2FA for ${selectedUser.name} disabled`,
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: `2FA for ${selectedUser.name} not disabled`,
                    text: `There was an error disabling 2FA.`,
                    type: 'error',
                });
            },
        }
    );
};

const editNote = async (selectedUser: IUser) => {
    const note = await show({
        header: 'Add / edit note',
        hasInput: true,
        confirm: 'Update',
        inputData: {
            label: 'Enter note',
        },
    });

    if (note) {
        router.put(
            route('kiosk.users.update', { user: selectedUser.id }),
            {
                note: typeof note === 'string' ? note : null,
            },
            {
                preserveScroll: true,
            }
        );
    }
};

const handleExtendTrial = async (type: 'pro' | 'plugin') => {
    const confirmed = await show({
        header: `Extend ${type} trial`,
        message: `Are you sure you want to update trial date of user?`,
        type: 'info',
        confirm: 'Update',
    });

    if (confirmed) {
        router.put(
            route('kiosk.users.trial-extend.update', {
                user: props.selectedUser.id,
            }),
            {
                type: type,
                date: type === 'pro' ? proDate.value : pluginDate.value,
            },
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    create({
                        title: `Trial extended`,
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Trial not deleted',
                        text: `There was an error extending trial.`,
                        type: 'error',
                    });
                },
                onFinish: () => {
                    proDate.value = '';
                    pluginDate.value = '';
                },
            }
        );
    }
};

const proDateComputed = computed({
    get: function () {
        return props.selectedUser.pro_trial_ends_at
            ? new Date(props.selectedUser.pro_trial_ends_at)
                  .toISOString()
                  .substring(0, 19)
            : '';
    },
    set: function (newValue: string) {
        proDate.value = newValue;
    },
});

const pluginDateComputed = computed({
    get: function () {
        return props.selectedUser.plugin_trial_ends_at
            ? new Date(props.selectedUser.plugin_trial_ends_at)
                  .toISOString()
                  .substring(0, 19)
            : '';
    },
    set: function (newValue: string) {
        pluginDate.value = newValue;
    },
});

const createFlexibeeId = async () => {
    const response = await axios.post(
        route('kiosk.teams.flexibee.store', {
            team: props.personalTeam.id,
        })
    );

    if (response.status === 200) {
        create({
            title: `Flexibee ID created`,
            type: 'success',
        });
        router.get(route('kiosk.users.index'));
    } else {
        create({
            title: 'Flexibee ID not created',
            text: `There was an error creating Flexibee ID.`,
            type: 'error',
        });
    }
};

const makeBucketPrivate = async (user: IUser) => {
    const confirmed = await show({
        header: 'Make bucket private for selected User',
        message: `Are you sure you want to make bucket private for this user?`,
        type: 'danger',
        confirm: 'Make private',
    });
    if (confirmed) {
        router.post(
            route('kiosk.users.make-bucket-private', { user: user.id }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as private',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as private',
                        text: 'There was an error making bucket private.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const makeBucketPublic = async (user: IUser) => {
    const confirmed = await show({
        header: 'Make bucket public for selected User',
        message: `Are you sure you want to make bucket public for this user?`,
        type: 'danger',
        confirm: 'Make public',
    });
    if (confirmed) {
        router.post(
            route('kiosk.users.make-bucket-public', { user: user.id }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Bucket has been marked as public',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Bucket has not been marked as public',
                        text: 'There was an error making bucket public.',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
