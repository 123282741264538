<template>
     <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Invoices">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { DocumentTextIcon, DocumentIcon } from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';

defineProps({
    tabKey: {
        type: String,
        default: 'invoices',
    },
});

let tabs: TabItem[] = [
    {
        title: 'Invoices',
        key: 'invoices',
        icon: DocumentTextIcon,
        url: route('kiosk.invoices.index'),
    },
    {
        title: 'Legacy Invoices',
        key: 'legacy-invoices',
        icon: DocumentIcon,
        url: route('kiosk.legacy-invoices.index'),
    },
];
</script>
