<template>
    <EditorOptionsTabs tab-key="options" :token="token">
        <form class="mt-6 mb-24" @submit.prevent="submit">
            <div class="flex flex-wrap items-center justify-between gap-4">
                <p class="text-gray-500">
                    For more information on how to set up your default options
                    <a
                        class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://docs.topol.io/guide/i18n.html"
                        target="_blank"
                        >check out Plugin documentation</a
                    >.
                </p>

                <div class="flex flex-wrap justify-end gap-4 sm:flex-nowrap">
                    <AtomicButton
                        type="button"
                        color="white"
                        size="md"
                        @click="copyJsonModalShow = true"
                    >
                        Copy JSON
                    </AtomicButton>

                    <AtomicButton
                        type="button"
                        color="white"
                        size="md"
                        :disabled="loading"
                        @click="resetToDefaults"
                    >
                        Reset to defaults
                    </AtomicButton>

                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="md"
                        :disabled="loading || !form.isDirty"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>

            <Title v-model="form.title" class="mt-11" />

            <Languages v-model="form.language" />

            <Themes v-model="form.light" />

            <MenuAlign v-model="form.mainMenuAlign" />

            <TopBar v-model="form.removeTopBar" />

            <TopBarOptions v-model="form.topBarOptions" />

            <WindowBar v-model="form.windowBar" />

            <DisableAlerts v-model="form.disableAlerts" />

            <SettingsTab v-model="form.hideSettingsTab" />

            <HtmlMinified v-model="form.htmlMinified" />

            <MobileFirst v-model="form.mobileFirstEnabled" />

            <FontSizes
                v-model="form.fontSizes"
                :default-value="defaults.fontSizes"
            />

            <ImageSize
                v-model="form.imageMaxSize"
                :default-value="defaults.imageMaxSize"
            />

            <Autosaves v-model="form.enableAutosaves" />

            <AutosaveInterval
                v-model="form.autosaveInterval"
                :default-value="defaults.autosaveInterval"
            />

            <div class="mt-4 flex flex-wrap justify-end gap-4 sm:flex-nowrap">
                <AtomicButton
                    type="button"
                    color="white"
                    size="md"
                    :disabled="loading"
                    @click="resetToDefaults"
                >
                    Reset to defaults
                </AtomicButton>

                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="md"
                    :disabled="loading || !form.isDirty"
                >
                    Save changes
                </AtomicButton>
            </div>
        </form>

        <CopyJsonModal
            v-if="copyJsonModalShow"
            :settings="props.token.settings?.editor_settings"
            @close="copyJsonModalShow = false"
        />
    </EditorOptionsTabs>
</template>

<script lang="ts" setup>
import IToken from '@/types/Plugin/IToken';
import { useForm } from '@inertiajs/vue3';
import { PropType } from 'vue';
import { create } from '@/composables/useNotifications';

const props = defineProps({
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
});

const { show: showConfirmation } = useConfirm();
const loading = ref(false);
const copyJsonModalShow = ref(false);

const defaults = {
    fontSizes: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 48],
    imageMaxSize: 2097152,
    autosaveInterval: 120,
};

const form = useForm({
    title: props.token.settings?.editor_settings?.title ?? '',
    language: props.token.settings?.editor_settings?.language ?? 'en',
    light: props.token.settings?.editor_settings?.light ?? false,
    mainMenuAlign:
        props.token.settings?.editor_settings?.mainMenuAlign ?? 'left',
    removeTopBar: props.token.settings?.editor_settings?.removeTopBar ?? false,
    topBarOptions: props.token.settings?.editor_settings?.topBarOptions ?? [],
    windowBar: props.token.settings?.editor_settings?.windowBar ?? [],
    disableAlerts:
        props.token.settings?.editor_settings?.disableAlerts ?? false,
    hideSettingsTab:
        props.token.settings?.editor_settings?.hideSettingsTab ?? false,
    enableAutosaves:
        props.token.settings?.editor_settings?.enableAutosaves ?? false,
    htmlMinified: props.token.settings?.editor_settings?.htmlMinified ?? false,
    mobileFirstEnabled:
        props.token.settings?.editor_settings?.mobileFirstEnabled ?? false,
    fontSizes:
        props.token.settings?.editor_settings?.fontSizes ?? defaults.fontSizes,
    imageMaxSize:
        props.token.settings?.editor_settings?.imageMaxSize ??
        defaults.imageMaxSize,
    autosaveInterval:
        props.token.settings?.editor_settings?.autosaveInterval ??
        defaults.autosaveInterval,
});

const submit = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to save this options?`,
        type: 'info',
    });
    if (confirmed) {
        loading.value = true;
        form.post(route('api-tokens.editor-options.store', props.token.id), {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Editor options saved',
                    text: 'Editor options for this API Token has been saved successfully.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Editor options not saved',
                    text: 'Editor options for this API Token has not been saved.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const resetToDefaults = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want to reset your options?`,
        type: 'info',
    });
    if (confirmed) {
        loading.value = true;
        router.delete(
            route('api-tokens.editor-options.destroy', props.token.id),
            {
                preserveScroll: true,
                preserveState: false,
                onSuccess: () => {
                    router.reload({ only: ['token'] });
                    create({
                        title: 'Editor options saved',
                        text: 'Editor options for this API Token has been saved successfully.',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Editor options not saved',
                        text: 'Editor options for this API Token has not been saved.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>
