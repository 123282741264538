<template>
    <SettingsTabs tab-key="api-tokens">
        <div
            class="mt-[44px] grid-cols-layout pb-5 lg:grid"
            :class="{
                'border-b border-gray-300': tokens.length > 0,
            }"
            @submit.prevent="createApiToken"
        >
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                >
                    Create API token
                </h3>
                <p class="max-w-xs text-sm text-gray-500">
                    API tokens allow third-party services to authenticate with
                    our application on your behalf.
                </p>
            </div>
            <form
                class="mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
            >
                <div class="flex flex-wrap border-b border-gray-200 pb-2 pl-6">
                    <TextInput
                        v-model="v$.form.name.$model"
                        class="my-5 mr-6 w-full sm:w-80"
                        label="Name"
                        placeholder="Type in name"
                        :error="getError('name')"
                    />
                </div>

                <div class="flex flex-wrap border-b border-gray-200 p-5 pl-6">
                    <div class="w-full pb-2">
                        <label
                            role="label"
                            class="relative pr-3 text-sm font-medium leading-normal text-gray-700"
                            >Permissions</label
                        >
                    </div>
                    <div class="w-1/2">
                        <CheckboxInput
                            v-model:checked="state.form.read"
                            name="readPermission"
                            label="Read"
                            size="md"
                            class="pb-5"
                        />
                    </div>
                    <div class="w-1/2">
                        <CheckboxInput
                            v-model:checked="state.form.create"
                            :option="true"
                            name="createPermission"
                            label="Create"
                            size="md"
                        />
                    </div>
                    <div class="w-1/2">
                        <CheckboxInput
                            v-model:checked="state.form.update"
                            name="updatePermission"
                            label="Update"
                            size="md"
                        />
                    </div>
                    <div class="w-1/2">
                        <CheckboxInput
                            v-model:checked="state.form.delete"
                            name="deletePermission"
                            label="Delete"
                            size="md"
                        />
                    </div>
                </div>

                <div class="flex justify-end">
                    <div class="my-3 pr-6">
                        <AtomicButton
                            type="submit"
                            color="yellow"
                            size="sm"
                            :disabled="loading"
                        >
                            Create
                        </AtomicButton>
                    </div>
                </div>
            </form>
        </div>

        <div
            v-if="tokens.length > 0"
            class="mt-[44px] grid-cols-layout pb-5 lg:grid"
        >
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                >
                    Manage API Tokens
                </h3>
                <p class="max-w-xs text-sm text-gray-500">
                    You may delete any of your existing tokens if they are no
                    longer needed.
                </p>
            </div>
            <form
                class="mx-auto flex w-full flex-wrap items-center rounded-md bg-white py-5 shadow-card lg:mx-0"
            >
                <div
                    v-for="token in tokens"
                    :key="token.id"
                    class="mx-6 mt-3 flex w-full justify-between"
                >
                    <div class="flex items-center">
                        {{ token.name }}
                    </div>
                    <div>
                        <AtomicButton
                            type="button"
                            color="yellow"
                            size="sm"
                            class="mr-2"
                            :disabled="loading"
                            @click="editPermissions(token)"
                        >
                            Permissions
                        </AtomicButton>
                        <AtomicButton
                            type="button"
                            color="red"
                            size="sm"
                            :disabled="loading"
                            @click="deleteApiToken(token)"
                        >
                            Delete
                        </AtomicButton>
                    </div>
                </div>
            </form>
        </div>

        <UpdateApiTokenPermissions
            v-if="selectedApiToken"
            :api-token="selectedApiToken"
            @close="selectedApiToken = undefined"
        />

        <ApiTokenSecretModal
            v-if="newApiToken"
            :api-token="newApiToken"
            @close="newApiToken = undefined"
        />
    </SettingsTabs>
</template>
<script setup lang="ts">
import route from 'ziggy-js';
import SettingsTabs from '../components/SettingsTabs.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import CheckboxInput from '@atomic/Inputs/CheckboxInput.vue';
import useConfirm from '@composables/useConfirm';
import UpdateApiTokenPermissions from './components/UpdateApiTokenPermissions.vue';
import axios, { AxiosError } from 'axios';
import ApiTokenSecretModal from '@/Pages/Settings/APITokens/components/ApiTokenSecretModal.vue';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { PropType } from 'vue';
import IInputErrors from '@/types/Atomic/IInputErrors';

interface IApiToken {
    id: number;
    name: string;
    abilities: string[];
    tokenable_id: number;
    tokenable_type: string;
    created_at: string;
    updated_at: string;
    last_used_at: string;
}

interface IForm {
    form: {
        name: string;
        create: boolean;
        read: boolean;
        update: boolean;
        delete: boolean;
    };
}

const { show } = useConfirm();

defineProps({
    tokens: {
        type: Array as PropType<IApiToken[]>,
        required: true,
    },
});
const loading = ref(false);
const selectedApiToken = ref<IApiToken | undefined>(undefined);
const newApiToken = ref<string | undefined>(undefined);
const errors = ref<IInputErrors>({});

const state: IForm = reactive({
    form: {
        name: '',
        create: false,
        read: false,
        update: false,
        delete: false,
    },
});

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
    },
};

const v$ = useVuelidate(rules, state);

const createApiToken = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        try {
            loading.value = true;
            const response = await axios.post(
                route('settings.api-tokens.store'),
                state.form
            );
            if (response.status === 200) {
                newApiToken.value = response.data.data.plainTextToken;
                state.form = {
                    name: '',
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                };
                v$.value.$reset();
                errors.value = {};
                router.reload({ only: ['tokens'] });

                create({
                    title: 'API token created successfully',
                    type: 'success',
                });
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                errors.value = err.response?.data.errors;
            }
        } finally {
            loading.value = false;
        }
    }
};

const deleteApiToken = async (token: { id: number }) => {
    const confirmed = await show({
        header: `Delete API token`,
        message: `Are you sure you want to delete this API token?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (!confirmed) {
        return;
    }

    loading.value = true;
    router.delete(route('settings.api-tokens.destroy', token.id), {
        preserveScroll: true,
        onFinish: () => {
            loading.value = false;
        },
        onSuccess: () => {
            create({
                title: 'API token deleted successfully',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Failed to delete API token',
                type: 'error',
            });
        },
    });
};

const editPermissions = (token: IApiToken) => {
    selectedApiToken.value = token;
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>

<style></style>
