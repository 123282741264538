<template>
    <MetricsTabs tab-key="unused-api-tokens">
        <DataTable
            class="w-full mt-10"
            title="Teams with unused api token"
            :headers="headers"
            :items="teams"
            searchable
        >
            <template #item-personal_team="{ data }">
                <div class="flex justify-center p-6 text-gray-600">
                    {{ data }}
                </div>
            </template>

            <template #item-created_at="{ data }">
                <div v-if="data" class="p-6 text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>
        </DataTable>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { format } from 'date-fns';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';

defineProps<{
    teams: IPaginationData<ICurrentTeam>;
}>();

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id', sortable: true, sortableId: 'id' },
    { text: 'Name', id: 'name', sortable: true, sortableId: 'name' },
    {
        text: 'Personal team',
        id: 'personal_team',
        align: 'center',
        sortable: true,
        sortableId: 'personal_team',
    },
    {
        text: 'Created',
        id: 'created_at',
        sortable: true,
        sortableId: 'created_at',
    },
];
</script>

<style></style>
