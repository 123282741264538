<template>
    <MetricsTabs tab-key="storage-usage">
        <form class="p-6" @submit.prevent="submit">
            <TextInput
                v-model="date"
                label="Select month"
                type="month"
                class="w-40 mb-6"
            />

            <AtomicButton
                size="md"
                color="yellow"
                type="submit"
                :disabled="loading"
            >
                Send email with CSV export
            </AtomicButton>
        </form>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import axios from 'axios';
import route from 'ziggy-js';
import { ref } from 'vue';
import { create as createNotification } from '@composables/useNotifications';

const date = ref('');
const loading = ref(false);
const submit = async () => {
    loading.value = true;
    try {
        const result = await axios.post(route('kiosk.metrics.storage-usage.store'), {
            date: date.value,
        });

        if (result.status === 200) {
            createNotification({
                type: 'success',
                title: 'Storage Usage sent successfully',
            });
        } else {
            createNotification({
                type: 'error',
                title: 'Something went wrong',
            });
        }
    } catch (err) {
        createNotification({
                type: 'error',
                title: 'Something went wrong',
            });
    } finally {
        loading.value = false;
    }
};
</script>

<style></style>
