import IFolder from '@/types/Templates/IFolder';
import { useStorage } from '@vueuse/core';
import { ref } from 'vue';
import useTemplates from './useTemplates';
import useFolders from './useFolders';

const loading = ref(false);
const searchingMode = ref(false);
const searchQuery = ref('');
const defaultView = ref<'list' | 'grid'>('grid');
const view = useStorage('templateView', defaultView);

const {
    search: searchTemplates,
    fetchData: fetchTemplates,
    setSortDirection: setSortDirectionTemplates,
    setSortBy: setSortByTemplates,
    searchQuery: searchQueryTemplates,
    isLastPage,
    loadNextPage,
    templates,
    currentPage,
    setPerPage,
    per_page,
    cancelMultipleSelect,
} = useTemplates();

const {
    search: searchFolders,
    initializeCurrentFolder,
    setCurrentFolder,
    currentFolder,
    currentPath,
    searchQuery: searchQueryFolders,
    deleteFolder,
    renameFolder: renameFolderFolders,
    createFolder,
    folders,
    setSortDirection: setSortDirectionFolders,
    setSortBy: setSortByFolders,
} = useFolders();

export const useFoldersAndTemplates = () => {

    async function search(searchQuery: string) {
        loading.value = true;
        cancelMultipleSelect();

        if (searchQuery === '') {
            searchingMode.value = false;
        } else {
            searchingMode.value = true;
        }

        await Promise.all([
            searchTemplates(searchQuery),
            searchFolders(searchQuery),
        ]);

        loading.value = false;
    }

    async function cancelSearch() {
        if (searchingMode.value) {
            searchQuery.value = '';
        }
    }

    async function resumeSearch() {
        if (searchingMode.value) {
            await search(searchQuery.value);
        }
    }

    async function fetch() {
        loading.value = true;
        
        await initializeCurrentFolder();
        await fetchTemplates();

        loading.value = false;
    }

    async function setDir(folder?: IFolder) {    
        loading.value = true;    
        cancelMultipleSelect();    

        searchQueryFolders.value = '';
        searchQueryTemplates.value = '';

        cancelSearch();

        currentPage.value = 1;

        await Promise.all([setCurrentFolder(folder), fetchTemplates()]);

        loading.value = false;
    }

    async function setSortDirection(sortDir: 'asc' | 'desc') {
        loading.value = true;
        cancelMultipleSelect();

        await Promise.all([
            setSortDirectionTemplates(sortDir),
            setSortDirectionFolders(sortDir),
        ]);

        loading.value = false;
    }

    async function setSortBy(sortByKey: string) {
        loading.value = true;
        cancelMultipleSelect();

        await Promise.all([
            setSortByTemplates(sortByKey),
            setSortByFolders(sortByKey),
        ]);

        loading.value = false;
    }

    async function renameFolder(folder: IFolder, newName: string) {
        await renameFolderFolders(folder, newName);

        await resumeSearch();
    }

    function setView(value: 'list' | 'grid') {
        view.value = value;
    }

    return {
        //shared
        loading,
        search,
        fetch,
        setDir,
        setSortDirection,
        setSortBy,
        searchingMode,
        searchQuery,
        cancelSearch,
        resumeSearch,

        //templates
        currentPage,
        isLastPage,
        loadNextPage,
        templates,
        setPerPage,
        per_page,

        //folders
        deleteFolder,
        renameFolder,
        createFolder,
        folders,
        currentFolder,
        currentPath,

        //view
        view,
        setView,
    };
};
