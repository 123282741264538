<template>
    <EditorPreferencesTabs :features="features" tab-key="miscellaneous">
        <div class="mb-[98px] mt-[44px]">
           
            <TestingEmailsSettings
                class="border-b border-gray-300"
                :model-value="editorSettings.testing_emails"
                @update:modelValue="updateTestingEmails"
            />

            <HtmlMinifiedSettings
                :model-value="editorSettings.html_minified"
                @update:modelValue="updateHtmlMinified"
            />
        </div>
    </EditorPreferencesTabs>
</template>
<script lang="ts" setup>
import SelectInput from '@atomic/Inputs/SelectInput.vue';
import RadioInput from '@atomic/Inputs/RadioInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import route from 'ziggy-js';
import { PropType } from 'vue';
import languages from '@/data/editor-languages';
import IEditorSettings from '@/types/Settings/IEditorSettings';
import EditorPreferencesTabs from '../components/EditorPreferencesTabs.vue';
import { create } from '@composables/useNotifications';
import { useForm } from '@inertiajs/vue3';

defineProps({
    editorSettings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
    features: {
        type: Object,
        default: null,
    },
});

const updateHtmlMinified = (value: boolean) => {

    router.put(route('editor-preferences.html-minified.update'), {
        html_minified: value
    }, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Minify HTML settings updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Minify HTML settings not updated',
                text: 'There was an error updating your minify HTML settings.',
                type: 'error',
            });
        },
    });
};

const updateTestingEmails = (emails: string[]) => {
    router.put(route('editor-preferences.testing-emails.update'), {
        emails: emails
    }, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Testing emails updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Testing emails not updated',
                text: 'There was an error updating your testing emails.',
                type: 'error',
            });
        },
    });
};
</script>

<style></style>
