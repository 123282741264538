<template>
    <MetricsTabs tab-key="integrations">
        <DataTable
            class="w-full mt-10"
            title="Integrations"
            :headers="headers"
            :items="integrations"
        >
        </DataTable>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IIntegrationMetrics from '@/types/Metrics/IIntegrationMetrics';

defineProps<{
    integrations: IPaginationData<IIntegrationMetrics>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'title',
    },
    {
        text: 'Usage',
        id: 'usage',
    },
];
</script>

<style></style>
