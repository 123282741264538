<template>
    <div>
        <div class="mt-[35px] rounded border border-gray-200 bg-gray-50">
            <div
                class="relative mt-3 flex flex-col items-start sm:flex-row sm:items-center"
            >
                <button
                    class="z-10 px-2 text-sm font-medium leading-normal focus:outline-none sm:px-6"
                    :class="{
                        'pb-2.5 text-gray-500': selectedTab !== 'domains',
                        'rounded-r border-l-2 border-orange-500 pb-2.5 text-gray-600 sm:rounded-r-none sm:border-b-2 sm:border-l-0 sm:pb-[8px]':
                            selectedTab === 'domains',
                    }"
                    @click="changeTo('domains')"
                >
                    Active for domains
                </button>

                <button
                    class="z-10 px-2 text-sm font-medium leading-normal focus:outline-none sm:px-6"
                    :class="{
                        'pb-2.5 text-gray-500': selectedTab !== 'storage',
                        'rounded-r border-l-2 border-orange-500 pb-2.5 text-gray-600 sm:rounded-r-none sm:border-b-2 sm:border-l-0 sm:pb-[8px]':
                            selectedTab === 'storage',
                    }"
                    @click="changeTo('storage')"
                >
                    Storage options
                </button>

                <button
                    class="z-10 px-2 text-sm font-medium leading-normal focus:outline-none sm:px-6"
                    :class="{
                        'pb-2.5 text-gray-500': selectedTab !== 'installations',
                        'rounded-r border-l-2 border-orange-500 pb-2.5 text-gray-600 sm:rounded-r-none sm:border-b-2 sm:border-l-0 sm:pb-[8px]':
                            selectedTab === 'installations',
                    }"
                    @click="changeTo('installations')"
                >
                    Installations
                </button>

                <!-- <button
                    class="z-10 px-2 text-sm font-medium leading-normal focus:outline-none sm:px-6"
                    :class="{
                        'pb-2.5 text-gray-500': selectedTab !== 'options',
                        'rounded-r border-l-2 border-orange-500 pb-2.5 text-gray-600 sm:rounded-r-none sm:border-b-2 sm:border-l-0 sm:pb-[8px]':
                            selectedTab === 'options',
                    }"
                    @click="changeTo('options')"
                >
                    Editor options
                </button> -->

                <div
                    class="absolute bottom-0 w-full border-b border-gray-300"
                ></div>
            </div>

            <div v-if="selectedTab === 'domains'">
                <div class="mb-6 pt-4">
                    <p class="px-6 text-sm leading-normal text-gray-500">
                        API Token is currently active for following domains. For
                        more info about domains
                        <a
                            href="https://docs.topol.io/guide/getting-started.html"
                            target="_blank"
                        >
                            <span
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >check out our documentation</span
                            > </a
                        >.
                    </p>
                    <div class="mt-1 mb-[15px]">
                        <EditDomains :domains="token.domains" :token="token" />
                    </div>
                </div>
            </div>

            <div v-if="selectedTab === 'storage'" class="mt-4 mb-6 ml-6 mr-10">
                <div>
                    <div class="text-sm leading-normal text-gray-500">
                        <div v-if="!token?.settings?.storage_provider">
                            You are using default Google Cloud storage option.
                            You can use your own Google Cloud or Amazon S3
                            storage.
                        </div>

                        <div v-if="token?.settings?.storage_provider === 'gcs'">
                            You are using
                            <span class="text-gray-700"
                                >Custom Google Cloud</span
                            >
                            storage option. If you want to use your own Amazon
                            S3 storage or revert back to default Google Cloud
                            Storage, you need to detach your current storage.
                        </div>
                        <div v-if="token?.settings?.storage_provider === 'aws'">
                            You are using
                            <span class="text-gray-700">Amazon S3</span>
                            storage option. If you want to use your own Google
                            Cloud storage or revert back to default Google Cloud
                            Storage, you need to detach your current storage.
                        </div>

                        For more information
                        <a href="https://docs.topol.io" target="_blank"
                            ><span
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >check out our documentation</span
                            ></a
                        >.
                    </div>

                    <div class="mt-[30px] flex flex-wrap gap-3">
                        <button
                            v-if="token?.settings?.storage_provider !== 'aws'"
                            class="rounded-md border border-gray-300 bg-white px-2 pt-5 pb-[11px] transition duration-100 ease-in-out focus:border-white focus:outline-none focus:ring-2 focus:ring-gray-300 sm:w-[260px] sm:px-0"
                            @click="$emit('googleModal', token.id)"
                        >
                            <div class="mx-auto mb-[19px] flex justify-center">
                                <img
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/google_cloud.png"
                                    alt="Google Cloud"
                                    class="h-full w-full sm:h-6 sm:w-[156px]"
                                />
                            </div>
                            <p
                                class="max-w-[156px] text-xs font-medium leading-normal text-gray-800 sm:max-w-none"
                            >
                                <span
                                    v-if="
                                        token?.settings?.storage_provider ===
                                        'gcs'
                                    "
                                    >Custom Google Cloud storage options</span
                                >
                                <span v-else
                                    >Setup your own Google Cloud storage</span
                                >
                            </p>
                        </button>

                        <button
                            v-if="token?.settings?.storage_provider !== 'gcs'"
                            class="rounded-md border border-gray-300 bg-white px-2 pt-5 pb-[11px] transition duration-100 ease-in-out focus:border-white focus:outline-none focus:ring-2 focus:ring-gray-300 sm:w-[260px] sm:px-0"
                            @click="$emit('amazonModal', token.id)"
                        >
                            <div class="mx-auto mb-[15px] flex justify-center">
                                <img
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/aws.png"
                                    alt="Amazon S3"
                                    class="h-8 w-[54px]"
                                />
                            </div>
                            <p
                                class="max-w-[156px] text-xs font-medium leading-normal text-gray-800 sm:max-w-none"
                            >
                                <span
                                    v-if="
                                        token?.settings?.storage_provider ===
                                        'aws'
                                    "
                                    >Custom Amazon S3 storage options</span
                                >
                                <span v-else
                                    >Setup your own Amazon S3 storage</span
                                >
                            </p>
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="selectedTab === 'installations'">
                <InstallationsTab :token="token" />
            </div>

            <div v-if="selectedTab === 'options'">
                <EditorOptionsTab :token="token" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IToken from '@/types/Plugin/IToken';
import { PropType } from 'vue';

type TabOptions = 'domains' | 'storage' | 'installations' | 'options';

defineProps({
    token: {
        type: Object as PropType<IToken>,
        required: true,
    },
});

defineEmits(['googleModal', 'amazonModal']);

const selectedTab = ref<TabOptions>('domains');

onMounted(() => {
    if (window.location.hash === '#storage-options') {
        selectedTab.value = 'storage';
    } else if (window.location.hash === '#installations') {
        selectedTab.value = 'installations';
    } else {
        selectedTab.value = 'domains';
    }
});

const changeTo = (to: TabOptions) => {
    selectedTab.value = to;

    if (to === 'domains') {
        if (history.pushState) {
            history.pushState(null, '', '#domains');
        } else {
            location.hash = '#domains';
        }
    } else if (to === 'storage') {
        if (history.pushState) {
            history.pushState(null, '', '#storage-options');
        } else {
            location.hash = '#storage-options';
        }
    } else {
        if (history.pushState) {
            history.pushState(null, '', '#installations');
        } else {
            location.hash = '#installations';
        }
    }
};
</script>

<style></style>
