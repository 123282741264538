<template>
    <div>
        <Modal @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">Edit template</div>
            </template>
            <template #body>
                <div class="bg-white">
                    <form
                        class="flex justify-center px-6 sm:px-0"
                        @submit.prevent="submit"
                    >
                        <div class="mt-4 flex flex-col gap-6 pb-6">
                            <div
                                class="h-96 overflow-auto sm:w-96 lg:w-[420px]"
                            >
                                <img
                                    :src="'https://s3-eu-west-1.amazonaws.com/topolio/screens/team-3260/screenshots/252844.jpg?v=1678390998'"
                                    class="h-[1200px] w-full object-cover object-top"
                                />
                            </div>

                            <TextInput
                                v-model="v$.form.name.$model"
                                required
                                class="w-full sm:w-96 lg:w-[420px]"
                                label="Name"
                                placeholder="Enter name (max. 50 characters)"
                                :error="getError('name')"
                            />

                            <TextAreaInput
                                v-model="v$.form.description.$model"
                                required
                                :rows="6"
                                class="w-full sm:w-96 lg:w-[420px]"
                                label="Description"
                                placeholder="Enter description (max. 200 characters)"
                                :error="getError('description')"
                            />

                            <SelectInput
                                v-model="v$.form.category_id.$model"
                                :data="categoryItems"
                                placeholder="Category"
                                label="Category"
                                class="w-full sm:w-96 lg:w-[420px]"
                                required
                                :error="getError('category_id')"
                            ></SelectInput>

                            <MultipleSelect
                                v-model:value="selectedKeywords"
                                label="Keywords"
                                class="w-full sm:w-96 lg:w-[420px]"
                                placeholder="Select keywords"
                                :options="keywords"
                            />

                            <CheckboxInput
                                v-model:checked="state.form.published_at"
                                size="lg"
                                name="free"
                                class="mt-2"
                                :label="
                                    state.form.published_at
                                        ? 'Published to marketplace'
                                        : 'Unpublished'
                                "
                                :has-error="!state.form.published_at"
                            />

                            <div>
                                <div
                                    class="mt-[7px] text-sm sm:w-96 lg:w-[420px]"
                                >
                                    You can either unpublish the template, or
                                    you can archive it. After archiving this
                                    template, it will no longer be visible in
                                    the Marketplace or you Marketplace Manager.
                                    You can still unarchive it later.
                                </div>
                                <button
                                    type="button"
                                    class="mt-2 text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                    @click="handleArchive"
                                >
                                    Archive
                                </button>
                            </div>

                            <div
                                class="flex justify-between gap-4 border-t border-gray-300 pt-6"
                            >
                                <AtomicButton
                                    color="white"
                                    @click="$emit('close')"
                                >
                                    Cancel
                                </AtomicButton>

                                <AtomicButton color="yellow" type="submit">
                                    Update
                                </AtomicButton>
                            </div>
                        </div>
                    </form>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import IInputErrors from '@/types/Atomic/IInputErrors';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { PropType } from 'vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import ISelectable from '@/types/Atomic/ISelectable';
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import Modal from '@/components/Atomic/Modals/Modal.vue';

const props = defineProps({
    marketplaceTemplate: {
        type: Object as PropType<IMarketplaceTemplate>,
        required: true,
    },
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const categoryItems = [] as ISelectable[];
const selectedKeywords = ref<IKeyword[]>([]);

onBeforeMount(() => {
    props.categories.forEach((a: ICategory) => {
        categoryItems.push({
            title: a.title as string,
            value: a.id,
        });
    });

    props.keywords.forEach((a) => {
        props.marketplaceTemplate.keywords?.forEach((b) => {
            if (a.id === b.id) {
                selectedKeywords.value.push(a);
            }
        });
    });
});

const emit = defineEmits(['template-updated', 'close']);

interface IForm {
    form: {
        name: string;
        description: string | null;
        category_id: number;
        keywords: number[];
        published_at: boolean;
    };
}

const state: IForm = reactive({
    form: {
        name: props.marketplaceTemplate.name,
        description: props.marketplaceTemplate.description,
        category_id: props.marketplaceTemplate.category.id,
        keywords: [],
        published_at: props.marketplaceTemplate.published_at !== null,
    },
});
const rules = {
    form: {
        name: { required },
        description: { required },
        category_id: {
            required,
        },
    },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const errors = ref<IInputErrors>({});
const { show } = useConfirm();

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;

        try {
            selectedKeywords.value.forEach((a: IKeyword) =>
                state.form.keywords.push(a.id)
            );

            router.put(
                route('marketplace.created-templates.update', {
                    marketplaceTemplate: props.marketplaceTemplate.id,
                }),
                state.form,
                {
                    preserveScroll: true,
                    onSuccess: () => {
                        create({
                            title: 'Template updated',
                            type: 'success',
                        });

                        emit('template-updated');
                        errors.value = {};
                        v$.value.$reset();
                    },
                    onError: (err) => {
                        errors.value = err;
                        create({
                            title: 'Template not updated',
                            type: 'error',
                        });
                    },
                    onFinish: () => {
                        loading.value = false;
                    },
                }
            );
        } catch (err) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const handleArchive = async () => {
    const confirmed = await show({
        type: 'danger',
        header: 'Archive template',
        message: 'Are you sure you want to archive this template?',
        confirm: 'Confirm',
    });

    if (confirmed) {
        router.delete(
            route('marketplace.created-templates.destroy', {
                marketplaceTemplate: props.marketplaceTemplate.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Template archived',
                        type: 'success',
                    });

                    emit('template-updated');
                    errors.value = {};
                    v$.value.$reset();
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Template not archived',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>
