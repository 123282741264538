<template>
    <SettingsTabs tab-key="team-settings">
        <div
            class="mt-[44px] grid-cols-layout border-b border-gray-300 pb-5 lg:grid"
            @submit.prevent="updateTeamName"
        >
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                >
                    Team name
                </h3>
                <p class="max-w-xs text-sm text-gray-500">
                    Please enter your team's name.
                </p>
            </div>
            <form
                class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
            >
                <div class="flex flex-wrap pl-6 border-b border-gray-200">
                    <TextInput
                        v-model="v$.form.name.$model"
                        class="w-full my-5 mr-6 sm:w-80"
                        label="Team name"
                        placeholder="Type in team name"
                        :error="getError('name')"
                    />
                </div>

                <div class="flex justify-end">
                    <div class="pr-6 my-3">
                        <AtomicButton
                            type="submit"
                            color="yellow"
                            size="sm"
                            :disabled="
                                !v$.form.$dirty || v$.form.$error || loading
                            "
                        >
                            Save changes
                        </AtomicButton>
                    </div>
                </div>
            </form>
        </div>

        <div class="pb-5 mt-5 grid-cols-layout lg:grid">
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                >
                    Team's photo
                </h3>
                <p class="max-w-xs text-sm text-gray-500">
                    Update your team's photo here.
                </p>
            </div>
            <div class="w-full mx-auto bg-white rounded-md shadow-card">
                <div class="flex flex-wrap items-center py-6 pl-6">
                    <div
                        class="mr-5 h-[72px] w-[72px] overflow-hidden rounded-full ring-1 ring-gray-300"
                    >
                        <img
                            :src="
                                imgUrl
                                    ? imgUrl
                                    : 'https://d5aoblv5p04cg.cloudfront.net/application/images/profile-blank.png'
                            "
                            class="h-[72px] w-[72px] object-cover"
                        />
                    </div>

                    <div class="relative">
                        <AtomicButton
                            class="mt-5 mr-4 sm:mt-0"
                            color="white"
                            size="md"
                            @click="clickToUpload"
                        >
                            Upload profile photo
                        </AtomicButton>
                        <input
                            id="teamImageUpload"
                            ref="fileTeamPhoto"
                            type="file"
                            accept="image/*"
                            class="absolute block w-full opacity-0"
                            @change="addPhoto"
                        />
                    </div>

                    <button
                        v-if="imgUrl"
                        class="flex pl-2 mt-5 mr-4 focus:outline-none sm:mr-0 sm:mt-0"
                        @click="removePhoto"
                    >
                        <svg
                            class="text-gray-400"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.29303 4.29302C4.48056 4.10555 4.73487 4.00023 5.00003 4.00023C5.26519 4.00023 5.5195 4.10555 5.70703 4.29302L10 8.58602L14.293 4.29302C14.3853 4.19751 14.4956 4.12133 14.6176 4.06892C14.7396 4.01651 14.8709 3.98892 15.0036 3.98777C15.1364 3.98662 15.2681 4.01192 15.391 4.0622C15.5139 4.11248 15.6255 4.18673 15.7194 4.28062C15.8133 4.37452 15.8876 4.48617 15.9379 4.60907C15.9881 4.73196 16.0134 4.86364 16.0123 4.99642C16.0111 5.1292 15.9835 5.26042 15.9311 5.38242C15.8787 5.50443 15.8025 5.61477 15.707 5.70702L11.414 10L15.707 14.293C15.8892 14.4816 15.99 14.7342 15.9877 14.9964C15.9854 15.2586 15.8803 15.5094 15.6948 15.6948C15.5094 15.8802 15.2586 15.9854 14.9964 15.9877C14.7342 15.99 14.4816 15.8892 14.293 15.707L10 11.414L5.70703 15.707C5.51843 15.8892 5.26583 15.99 5.00363 15.9877C4.74143 15.9854 4.49062 15.8802 4.30521 15.6948C4.1198 15.5094 4.01463 15.2586 4.01236 14.9964C4.01008 14.7342 4.11087 14.4816 4.29303 14.293L8.58603 10L4.29303 5.70702C4.10556 5.51949 4.00024 5.26518 4.00024 5.00002C4.00024 4.73486 4.10556 4.48055 4.29303 4.29302Z"
                                fill="currentColor"
                            />
                        </svg>
                        <p class="pl-2 text-sm font-medium text-gray-600">
                            Remove photo
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </SettingsTabs>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { usePage } from '@inertiajs/vue3';
import { create } from '@composables/useNotifications';
import { router } from '@inertiajs/vue3';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IInputErrors from '@/types/Atomic/IInputErrors';

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(
    () => globalProps.value.team as ICurrentTeam
);

const state = reactive({
    form: {
        name: teamProps.value.name,
    },
});
const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
    },
};
const v$ = useVuelidate(rules, state);
  
const imgUrl = ref(teamProps.value.profile_photo_path ?? '');
const img = ref<File>();
const loading = ref(false);
const errors = ref<IInputErrors>({});
const fileTeamPhoto = ref<HTMLInputElement>();

const updateTeamName = () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;

        router.put(route('settings.team-settings.update'), state.form, {
            preserveScroll: true,
            onSuccess: () => {
                errors.value = {};
                v$.value.$reset();
                create({
                    title: 'Team name updated',
                    type: 'success',
                });
            },
            onError: (err) => {
                errors.value = err;
                create({
                    title: 'Team name not updated',
                    text: 'There was an error updating your team name.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const clickToUpload = () => {
    if (fileTeamPhoto.value !== undefined){
        fileTeamPhoto.value.click();
    }
};
        
const addPhoto = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    img.value = files[0];

    imgUrl.value = URL.createObjectURL(img.value);
    let formData = new FormData();
    formData.append('photo', img.value);
    formData.append('_method', 'put');
    router.post(route('settings.team-settings.update'), formData, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Team photo updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Team photo not updated',
                text: 'There was an error updating your team photo.',
                type: 'error',
            });
        },
    });
};

const removePhoto = () => {
    const element = document.querySelector(
        '#teamImageUpload'
    ) as HTMLInputElement;

    if (element !== null) {
        img.value = undefined;
        imgUrl.value = '';
        router.delete(route('settings.team-settings.destroy'), {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Team photo updated',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Team photo not updated',
                    text: 'There was an error updating your team photo.',
                    type: 'error',
                });
            },
        });
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.updateTeamInformation?.[field] ||
        ''
    );
};

</script>

<style></style>
