<template>
    <transition
        leave-active-class="transition duration-200 ease-in transform"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="opened"
            class="flex flex-col items-center justify-center border-b border-gray-200 bg-yellow-100 py-4 pl-4 pr-6 sm:flex-row"
        >
            <div>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.25685 3.0991C9.02185 1.7391 10.9788 1.7391 11.7428 3.0991L17.3228 13.0191C18.0728 14.3531 17.1098 15.9991 15.5808 15.9991H4.41985C2.88985 15.9991 1.92685 14.3531 2.67685 13.0191L8.25685 3.0991ZM10.9998 13.0001C10.9998 13.2653 10.8945 13.5197 10.707 13.7072C10.5194 13.8947 10.2651 14.0001 9.99985 14.0001C9.73463 14.0001 9.48028 13.8947 9.29274 13.7072C9.10521 13.5197 8.99985 13.2653 8.99985 13.0001C8.99985 12.7349 9.10521 12.4805 9.29274 12.293C9.48028 12.1055 9.73463 12.0001 9.99985 12.0001C10.2651 12.0001 10.5194 12.1055 10.707 12.293C10.8945 12.4805 10.9998 12.7349 10.9998 13.0001ZM9.99985 5.0001C9.73463 5.0001 9.48028 5.10546 9.29274 5.29299C9.10521 5.48053 8.99985 5.73488 8.99985 6.0001V9.0001C8.99985 9.26532 9.10521 9.51967 9.29274 9.70721C9.48028 9.89474 9.73463 10.0001 9.99985 10.0001C10.2651 10.0001 10.5194 9.89474 10.707 9.70721C10.8945 9.51967 10.9998 9.26532 10.9998 9.0001V6.0001C10.9998 5.73488 10.8945 5.48053 10.707 5.29299C10.5194 5.10546 10.2651 5.0001 9.99985 5.0001Z"
                        fill="#D69E2E"
                    />
                </svg>
            </div>
            <div
                class="mt-2 flex max-w-xs flex-col pl-[14px] text-center text-sm sm:mt-0 sm:text-left md:max-w-sm lg:max-w-none 2xl:flex-row 2xl:items-center"
            >
                <p
                    class="font-medium text-yellow-800"
                    :class="{
                        'lg:max-w-2xl 2xl:max-w-none': type === 'plugin',
                    }"
                >
                    <span v-if="type === 'pro'">
                        Your free 14-days trial period has ended on
                        <span v-if="date">{{
                            format(new Date(date), 'MMMM d, yyyy')
                        }}</span
                        >. You are now switched to our Free plan.
                    </span>
                    <span v-if="type === 'plugin'" class="lg:max-w-sm">
                        Your free TOPOL Plugin 60-days trial period has ended on
                        <span v-if="date">{{
                            format(new Date(date), 'MMMM d, yyyy')
                        }}</span
                        >. Your plugin API Tokens are now suspended, please
                        select appropriate Plugin subscription to continue.
                    </span>
                </p>

                <p class="text-yellow-700">
                    <span v-if="type === 'pro'">
                        To continue using Topol PRO with all it’s features you
                        need to subscribe to a paid plan.
                    </span>
                </p>
            </div>
            <div
                class="mt-4 flex items-center sm:mt-0 sm:pl-5 md:pl-5 lg:pl-20 2xl:pl-16"
            >
                <AtomicButton
                    class="mr-4"
                    size="md"
                    color="yellow"
                    @click="handleSubscribe"
                    >Subscribe now</AtomicButton
                >
                <AtomicButton size="md" color="white" @click="opened = false">
                    <template #icon>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.18526 10.3434L9.53881 9.9898L9.18526 9.63625L6.93301 7.384C6.87376 7.32141 6.84103 7.23825 6.84178 7.15197C6.84254 7.06457 6.8776 6.98097 6.9394 6.91916C7.0012 6.85736 7.08481 6.82231 7.17221 6.82155C7.25848 6.8208 7.34164 6.85353 7.40424 6.91278L9.65649 9.16502L10.01 9.51858L10.3636 9.16502L12.6158 6.91278C12.6784 6.85353 12.7616 6.8208 12.8479 6.82155C12.9353 6.82231 13.0189 6.85736 13.0807 6.91916C13.1425 6.98097 13.1775 7.06457 13.1783 7.15197C13.1791 7.23825 13.1463 7.3214 13.0871 7.384L10.8348 9.63625L10.4813 9.9898L10.8348 10.3434L13.0871 12.5956C13.1463 12.6582 13.1791 12.7414 13.1783 12.8276C13.1775 12.915 13.1425 12.9986 13.0807 13.0604C13.0189 13.1222 12.9353 13.1573 12.8479 13.1581C12.7616 13.1588 12.6784 13.1261 12.6158 13.0668L10.3636 10.8146L10.01 10.461L9.65649 10.8146L7.40048 13.0706L7.40042 13.0705L7.39439 13.0768C7.36364 13.1086 7.32685 13.134 7.28619 13.1515C7.24552 13.169 7.20178 13.1782 7.15752 13.1785C7.11326 13.1789 7.06937 13.1705 7.0284 13.1537C6.98743 13.137 6.95022 13.1122 6.91892 13.0809C6.88762 13.0496 6.86287 13.0124 6.84611 12.9714C6.82935 12.9305 6.82092 12.8866 6.8213 12.8423C6.82169 12.7981 6.83088 12.7543 6.84835 12.7137C6.86582 12.673 6.89121 12.6362 6.92305 12.6055L6.9231 12.6055L6.92925 12.5994L9.18526 10.3434Z"
                                fill="black"
                                stroke="#6B7280"
                            />
                        </svg>
                    </template>
                </AtomicButton>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" setup>
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import { ref } from 'vue';
import route from 'ziggy-js';
import { format } from 'date-fns';

const props = defineProps<{
    type: 'pro' | 'plugin';
    date: string | undefined;
}>();

const opened = ref(true);

const handleSubscribe = () => {
    if (props.type === 'pro') {
        router.visit(route('billing.pro-subscriptions.index'));
    } else {
        router.visit(route('billing.plugin-subscriptions.index'));
    }
};
</script>
