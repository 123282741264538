<template>
    <Tabs :selected-team="selectedTeam" tab-key="transactions">
        <DataTable
            class="mt-12"
            title="Transactions"
            searchable
            :headers="headers"
            :items="transactions"
        >
            <template #item-amount="{ data, row }">
                <div class="flex justify-center p-6 text-gray-500">
                    {{ data }} {{ row.currencyIsoCode }}
                </div>
            </template>

            <template #item-createdAt="{ data }">
                <div class="flex justify-center p-6 text-gray-500">
                    {{ format(new Date(data.date), 'd. L. yyyy HH:mm:ss') }}
                </div>
            </template>

            <template #item-actions="{ row }">
                <div class="flex justify-end p-6">
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        @click="showInvoice(row.id as string)"
                    >
                        <template #icon>
                            <DocumentTextIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import { DocumentTextIcon } from '@heroicons/vue/24/outline';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';

import Tabs from '../components/TeamTabs.vue';
import useConfirm from '@composables/useConfirm';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import usePdf from '@/composables/usePdf';

const props = defineProps<{
    selectedTeam: ICurrentTeam;
    transactions: IPaginationData<ICurrentTeam>;
}>();

const { download } = usePdf();

const headers: IDataTableHeader[] = [
    {
        text: 'ID',
        id: 'id',
        sortable: true,
        sortableId: 'id',
    },
    {
        text: 'Status',
        id: 'status',
        sortable: true,
        sortableId: 'status',
    },
    {
        text: 'Amount',
        id: 'amount',
        align: 'center',
        sortable: true,
        sortableId: 'amount',
    },
    {
        text: 'Created at',
        id: 'createdAt',
        align: 'center',
        sortable: true,
        sortableId: 'createdAt',
    },
    {
        text: '',
        id: 'actions',
        align: 'right',
        sortable: false,
    },
];

const { show: showConfirmation } = useConfirm();

const showInvoice = async (transactionId: string) => {
    const response = await axios.get(
        route('kiosk.transactions.invoices.show', {
            transaction: transactionId,
        })
    );

    if (response.data.success) {
        download(
            route('kiosk.invoices.download-pdf', {
                invoice: response.data.data.id,
            }),
            'Invoice'
        );
    } else {
        const confirmed = await showConfirmation({
            message: `Do you really want to create invoice for this transaction?`,
            type: 'danger',
        });

        if (confirmed) {
            const response = await axios.post(
                route('kiosk.transactions.invoices.store', {
                    transaction: transactionId,
                }),
                {
                    teamId: props.selectedTeam.id,
                }
            );

            if (response.data.success) {
                download(
                    route('kiosk.invoices.download-pdf', {
                        invoice: response.data.data.id,
                    }),
                    'Invoice'
                );
            } else {
                create({
                    title: 'Unsuccessful invoice creation',
                    text: 'Invoice has not been created',
                    type: 'error',
                });
            }
        }
    }
};
</script>
