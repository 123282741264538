<template>
    <div class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="logs"
            searchable
            title="Testing Logs"
        >
            <template #item-created_at="{ data }">
                <div class="flex p-6">
                    <div v-if="data">
                        {{
                            format(
                                new Date(data as string),
                                'd. L. yyyy HH:mm:ss'
                            )
                        }}
                    </div>
                </div>
            </template>

            <template #item-actions="{ row }">
                <div class="flex justify-end p-6">
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        @click="openLog(row as ITestingLog)"
                    >
                        <template #icon>
                            <CodeBracketSquareIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                    <AtomicButton
                        class="ml-2"
                        size="sm"
                        color="red"
                        @click="deleteLog(row as ITestingLog)"
                    >
                        <template #icon>
                            <TrashIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import route from 'ziggy-js';

import { TrashIcon, CodeBracketSquareIcon } from '@heroicons/vue/24/outline';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ITestingLog from '@/types/TestingLogs/ITestingLog';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import useConfirm from '@/composables/useConfirm';

defineProps<{
    logs: IPaginationData<ITestingLog>;
}>();

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id', sortable: true, sortableId: 'id' },
    { text: 'Name', id: 'name', sortable: true, sortableId: 'name' },
    {
        text: 'Created',
        id: 'created_at',
        sortable: true,
        sortableId: 'created_at',
    },
    { text: '', id: 'actions' },
];

const openLog = async (log: ITestingLog) => {
    window.open(route('kiosk.testing-logs.show', log.id), '_blank');
};

const deleteLog = async (log: ITestingLog) => {
    const confirmed = await show({
        header: `Delete log`,
        message: `Are you sure you want to delete this log?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.testing-logs.destroy', {
                log: log.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Log deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Log not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
