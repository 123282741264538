<template>
    <Modal size="full" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Products from your feed</div>
        </template>

        <template #body>
            <div class="mb-6 p-6">
                <div class="flex flex-wrap gap-8">
                    <a
                        v-for="product in products"
                        :key="product.id"
                        :href="product.url ?? ''"
                        target="_blank"
                        class="group max-w-[224px] text-sm"
                    >
                        <div
                            class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75"
                        >
                            <img
                                v-if="product.img_url"
                                :src="product.img_url"
                                :alt="product.name"
                                class="h-56 w-56 object-cover object-top"
                            />
                            <div v-else class="h-56 w-56 bg-gray-400"></div>
                        </div>
                        <h3 class="mt-4 font-medium text-gray-900">
                            {{ product.name }}
                        </h3>
                        <p class="break-words italic text-gray-500">
                            {{ product.description }}
                        </p>
                        <p class="mt-2 font-medium text-gray-900">
                            {{
                                product.price_with_vat
                                    ? product.price_with_vat
                                    : ''
                            }}
                            {{ product.currency }}
                        </p>
                    </a>
                </div>

                <div class="mt-4 flex items-center justify-center">
                    <AtomicButton
                        v-if="!isLastPage"
                        :disabled="loading"
                        color="white"
                        @click="getProducts"
                        >Load more</AtomicButton
                    >
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import IProduct from '@/types/Products/IProduct';

const props = defineProps({
    productFeedId: {
        type: Number,
        required: true,
    },
});

onBeforeMount(() => {
    getProducts();
});

const products = ref<IProduct[] | null>(null);
const isLastPage = ref(true);
const nextPage = ref(1);

const loading = ref(false);

defineEmits(['close']);

const getProducts = async () => {
    loading.value = true;
    try {
        const result = await axios.get(
            route('editor-preferences.products.index', {
                productFeed: props.productFeedId,
            }),
            {
                params: {
                    current_page: nextPage.value,
                },
            }
        );

        if (result.status === 200) {
            if (products.value) {
                products.value = [...products.value, ...result.data.data];
            } else {
                products.value = result.data.data;
            }

            if (result.data.next_page) {
                isLastPage.value = false;
                nextPage.value = result.data.next_page;
            } else {
                isLastPage.value = true;
            }
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};
</script>
