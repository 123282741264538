<template>
    <Action
        :items="templateActions"
        :activator-type="activatorType"
        :activator-text="`Selected (${selectedTemplates.length})`"
    >
        <template v-if="activatorType === 'custom'" #activator="{ onClick }">
            <AtomicButton
                color="yellow"
                size="lg"
                rounded="xl"
                class="relative shadow-lg"
                @click="onClick"
            >
                <div class="flex items-center justify-center">
                    Selected templates ({{selectedTemplates.length}})
                </div>

                <XMarkIcon
                    class="absolute flex items-center justify-center w-5 h-5 transition duration-200 ease-in-out transform bg-white border border-gray-200 rounded-full hover:scale-125 -top-2 -right-2"
                    @click="cancelMultipleSelect" 
                />

                <div class="ml-3">
                    <svg
                        class=""
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2 2V2.01M2 8V8.01M2 14V14.01M2 3C1.73478 3 1.48043 2.89464 1.29289 2.70711C1.10536 2.51957 1 2.26522 1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1C2.26522 1 2.51957 1.10536 2.70711 1.29289C2.89464 1.48043 3 1.73478 3 2C3 2.26522 2.89464 2.51957 2.70711 2.70711C2.51957 2.89464 2.26522 3 2 3ZM2 9C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7C2.26522 7 2.51957 7.10536 2.70711 7.29289C2.89464 7.48043 3 7.73478 3 8C3 8.26522 2.89464 8.51957 2.70711 8.70711C2.51957 8.89464 2.26522 9 2 9ZM2 15C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14C1 13.7348 1.10536 13.4804 1.29289 13.2929C1.48043 13.1054 1.73478 13 2 13C2.26522 13 2.51957 13.1054 2.70711 13.2929C2.89464 13.4804 3 13.7348 3 14C3 14.2652 2.89464 14.5196 2.70711 14.7071C2.51957 14.8946 2.26522 15 2 15Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </AtomicButton> 
        </template>
    </Action>
   
</template>

<script lang="ts" setup>
import IActionNode from '@/types/Atomic/IActions';
import { computed } from 'vue';
import axios from 'axios';
import route from 'ziggy-js';
import useConfirm from '@/composables/useConfirm';
import useTemplates from '../composables/useTemplates';
import { saveAs } from 'file-saver';
import { create } from '@composables/useNotifications';
import IFolder from '@/types/Templates/IFolder';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import IUser from '@/types/Members/IUser';
import usePusher from '@/composables/usePusher';
import JSZip from 'jszip';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useMultipleTemplateActions } from '../composables/useMultipleTemplateActions';

defineProps({
    activatorType: {
        type: String,
        default: 'button',
    },
})

const { show } = useConfirm();
const { allTeams } = useTeamsAndMembers();
const { folders, currentPath } = useFolders();
const { startEcho, stopEcho } = usePusher();

const { selectedTemplates, cancelMultipleSelect } = useTemplates();
const foldersAndTemplates = useFoldersAndTemplates();

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const userProps = computed(() => globalProps.value.user as IUser);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const {
    startLoading,
    cancelLoading,
    listenForEvents,
    stopListeningForEvents,
} = useMultipleTemplateActions(userProps.value.id);

onMounted(() => {
    startEcho();
    listenForEvents();
});

onUnmounted(() => {
    stopEcho();
    stopListeningForEvents();
});

const changeStatusTo = async (status: string|null) => {    
    try {
        startLoading();
        await axios.put(route('templates.multiple.update', {
            status: status,
            ids: selectedTemplates.value.map((t) => t.id) as number[],
        }))
    } catch (err) {
        cancelLoading();
    }
}

const templateActions = computed((): IActionNode[] => {
    let nodes: IActionNode[] = [
        {
            name: 'Set status',
            children: [
                {
                    name: 'Draft',
                    async onClick() {
                        changeStatusTo('draft');
                    },
                },
                {
                    name: 'In Review',
                    async onClick() {
                        changeStatusTo('in_review');
                    },
                },
                {
                    name: 'Sent',
                    async onClick() {
                        changeStatusTo('sent');
                    },
                },
                {
                    name: 'Default status',
                    async onClick() {
                        changeStatusTo(null);
                    },
                },
            ],
        },
        createDuplicateNode(),
        {
            name: 'Download',
            children: [
                {
                    name: 'JSON',
                    async onClick() {
            
                        try {
                            const res = await axios.post(
                                route('templates.multiple.downloads.json', {
                                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                                })
                            );
                            
                            if (res.data.success && Array.isArray(res.data.data)) {
                                const zip = new JSZip();

                                res.data.data.forEach((templateData: string, index: number) => {
                                    const fileName = `template_${index + 1}.json`;
                                    const fileContent = templateData;
                                    zip.file(fileName, fileContent);
                                });
    
                                zip.generateAsync({ type: "blob" })
                                    .then(function(content) {
                                        saveAs(content, "templates.zip");
                                    });
                            } else {
                                create({
                                    title: 'There was an error downloading templates',
                                    type: 'error',
                                });
                            }
                                   
                        } catch (err) {
                            create({
                                title: 'There was an error downloading templates',
                                type: 'error',
                            });
                        }

                    },
                },
                {
                    name: 'HTML with hosted images',
                    async onClick() {
                        try {
                            const res = await axios.post(
                                route('templates.multiple.downloads.hosted-html', {
                                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                                })
                            );
                            
                            if (res.data.success && Array.isArray(res.data.data)) {
                                const zip = new JSZip();

                                res.data.data.forEach((templateData: string, index: number) => {
                                    const fileName = `template_${index + 1}.html`;
                                    zip.file(fileName, templateData);
                                });
    
                                zip.generateAsync({ type: "blob" })
                                    .then(function(content) {
                                        saveAs(content, "templates.zip");
                                    });
                            } else {
                                create({
                                    title: 'There was an error downloading templates',
                                    type: 'error',
                                });
                            }
                                
                        } catch (err) {
                            create({
                                title: 'There was an error downloading templates',
                                type: 'error',
                            });
                        }
                    },
                },
            ],
        },

        {
            name: 'Delete templates',
            danger: true,
            async onClick() {
                const confirmed = await show({
                    header: 'Delete templates',
                    message: 'Do you really want to delete selected templates?',
                    type: 'danger',
                });

                if (confirmed) {
                    try {
                        startLoading();
                        await axios.delete(
                            route('templates.multiple.delete', {
                                ids: selectedTemplates.value.map((t) => t.id) as number[],
                            })
                        );
                    } catch (err) {
                        cancelLoading();
                    }
                }
            },
        },
    ];

    const moveTemplateToFolderNode = createFolderActionNode(folders.value);
    if (!foldersAndTemplates.searchingMode.value) {
        nodes.splice(1, 0, moveTemplateToFolderNode);
    }

    return nodes;
});

const createFolderActionNode = (folders: IFolder[]): IActionNode => {

    const move = async (folderId: number|null) => {
        try {
            startLoading();
            await axios.post(
                route('templates.multiple.move-to-folder', {
                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                    template_folder_id: folderId,
                })
            );
        } catch (err) {
            cancelLoading();
        }
    };


    const teamNodes: IActionNode[] = [];
    const moveToTeam = {
        name: 'Move to account',
        children: teamNodes,
    };
    const nodes: IActionNode[] = [];
    if (currentPath.value.length) {
        nodes.push({
            name: 'My templates',
            onClick: async () => {
               move(null)
            },
        });
    }
    if (folders.length) {
        folders.forEach((folder) => {
            nodes.push({
                name: folder.name,
                onClick: async () => {
                   move(folder.id)
                },
            });
        });
    }
    if (!currentPath.value.length && !folders.length) {
        nodes.push({
            name: 'You have no folders yet',
        });
    }
    if (allTeams.value.length > 1) {
        allTeams.value.forEach((f: ICurrentTeam) => {
            if (f.id !== teamProps.value.id) {
                teamNodes.push({
                    name: f.name,
                    onClick: async () => {
                        try {
                            startLoading();
                            await axios.post(
                                route('templates.multiple.move-to-team', {
                                    team_id: f.id,
                                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                                })
                            );

                        } catch (err) {
                            cancelLoading();
                        }
                    },
                });
            }
        });
        nodes.push(moveToTeam);
    }
    const node: IActionNode = {
        name: 'Move to folder',
        children: nodes,
    };
    return node;
};

const createDuplicateNode = (): IActionNode => {

    const duplicate = async () => {
        try {
            startLoading();
            await axios.post(
                route('templates.multiple.duplicate', {
                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                })
            );
        } catch (err) {
            cancelLoading();
        }
    };

    //User has only his own team
    if (allTeams.value.length === 1) {
        return {
            name: 'Duplicate',
            async onClick() {
                duplicate()
            },
        };
    }
    const teamNodes: IActionNode[] = [];
    let duplicateToTeam = {
        name: 'Duplicate to',
        children: teamNodes,
    };
    const duplicateTeamNodes: IActionNode[] = [
        {
            name: 'Duplicate',
            async onClick() {
                duplicate()
            },
        },
        duplicateToTeam,
    ];
    const duplicateNode: IActionNode = {
        name: 'Duplicate...',
        children: duplicateTeamNodes,
    };
    if (allTeams.value.length > 1) {
        allTeams.value.forEach((f: ICurrentTeam) => {
            if (f.id !== teamProps.value.id) {
                teamNodes.push({
                    name: f.name,
                    onClick: async () => {
                        try {
                            startLoading();
                            await axios.post(
                                route('templates.multiple.duplicate-to-team', {
                                    team_id: f.id,
                                    ids: selectedTemplates.value.map((t) => t.id) as number[],
                                })
                            );
  
                        } catch (err) {
                            cancelLoading();
                        }
                    },
                });
            }
        });
    }
    return duplicateNode;
};
</script>

<style></style>