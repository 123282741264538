<template>
    <div data-cy="templates-index">
        <div class="pb-80">
            <div
                class="mb-[19px] mt-[45px] flex flex-col gap-4 sm:flex-row sm:justify-between"
            >
            
                <Breadcrumb
                    v-if="!foldersAndTemplates.searchingMode.value"
                    :path-items="foldersAndTemplates.currentPath.value"
                    @path-clicked="setDir"
                ></Breadcrumb>

                <div v-else>
                    <p
                        class="mx-4 ml-0 text-2xl font-medium leading-normal text-gray-500 tracking-small"
                    >
                        Searching in all folders...
                    </p>
                </div>

                <div
                    class="flex flex-col flex-shrink-0 gap-4 mt-4 sm:mt-0 sm:flex-row"
                >
                    <div>
                        <DefinitionImportButton
                            @click="foldersAndTemplates.cancelSearch()"
                        />
                    </div>
                    <div class="flex-shrink-0 mt-4 sm:mt-0">
                        <AtomicButton
                            data-testid="create-new-template-button"
                            color="yellow"
                            size="lg"
                            @click="openNewTemplatesModal"
                        >
                            <template #icon>
                                <div class="mr-2">
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                                            stroke="#252F3F"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </template>
                            New template
                        </AtomicButton>
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col justify-between gap-4 sm:flex-row lg:items-end"
            >
            
                <div v-if="selectedTemplates.length === 0" class="flex flex-wrap items-center gap-4">
                    <AtomicButton
                        color="white"
                        size="lg"
                        class="flex-shrink-0"
                        @click="openNewFolderModal"
                    >
                        <template #icon>
                            <div class="mr-2">
                                <svg
                                    class="text-gray-500"
                                    width="16"
                                    height="12"
                                    viewBox="0 0 16 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2 0C0.895 0 0 0.895 0 2V10C0 11.105 0.895 12 2 12H14C15.105 12 16 11.105 16 10V4C16 2.895 15.105 2 14 2H9L7 0H2ZM9 5C9 4.448 8.552 4 8 4C7.448 4 7 4.448 7 5V6H6C5.448 6 5 6.448 5 7C5 7.552 5.448 8 6 8H7V9C7 9.552 7.448 10 8 10C8.552 10 9 9.552 9 9V8H10C10.552 8 11 7.552 11 7C11 6.448 10.552 6 10 6H9V5Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                        </template>
                        New folder
                    </AtomicButton>

                    <SortingButton
                        v-model:sortBy="selectedSortBy"
                        v-model:sortByDirection="selectedSortByDirection"
                        class="flex-shrink-0"
                    />

                    <PerPageButtonGroup
                        v-model="perPage"
                        :values="[20, 50, 100]"
                    ></PerPageButtonGroup>

                    <span class="inline-flex rounded-md shadow-sm">
                        <button
                            type="button"
                            class="relative inline-flex items-center px-2 py-2 transition duration-200 ease-in-out cursor-pointer umami--click--toggle-grid-view rounded-l-md border-t-1 border-b-1 border-l-1"
                            :class="{
                                'border-gray-300 bg-white text-gray-400 hover:bg-gray-50':
                                    templateView === 'list',
                                'border-indigo-600 bg-indigo-600 text-white':
                                    templateView === 'grid',
                            }"
                            @click="toggleTemplateView('grid')"
                        >
                            <Squares2X2Icon
                                class="w-5 h-5"
                                aria-hidden="true"
                            />
                        </button>
                        <button
                            data-helper-tooltip="list_view"
                            type="button"
                            class="relative inline-flex items-center px-2 py-2 -ml-px transition duration-200 ease-in-out cursor-pointer umami--click--toggle-list-view rounded-r-md border-r-1 border-t-1 border-b-1"
                            :class="{
                                'border-gray-300 bg-white text-gray-400 hover:bg-gray-50':
                                    templateView === 'grid',
                                'border-indigo-600 bg-indigo-600 text-white':
                                    templateView === 'list',
                            }"
                            @click="toggleTemplateView('list')"
                        >
                            <ListBulletIcon
                                class="w-5 h-5"
                                aria-hidden="true"
                            />
                        </button>
                        <HelperTooltip name="list_view" placement="top">
                            <div class="max-w-[200px]">
                                Manage your templates faster with a new compact
                                list.
                            </div>
                        </HelperTooltip>
                    </span>

                    
                </div>

                <div v-else class="flex flex-wrap items-center gap-4">

                    <CheckboxInput
                        size="lg"
                        label="Select all"
                        :checked="selectedTemplates.length === foldersAndTemplates.templates.value.length"
                        @change="selectAllTemplates"
                    />

                    <AtomicButton
                        color="white"
                        size="lg"
                        @click="cancelMultipleSelect"
                    >
                        <template #icon>
                            <XMarkIcon class="w-5 h-5 mr-2" />
                        </template>
                        Cancel
                    </AtomicButton>
         
                    <MultipleTemplateActions
                    />
                </div>
                <SearchInput
                    v-model="foldersAndTemplates.searchQuery.value"
                    placeholder="Search templates"
                    class="md:max-w-[258px] lg:max-w-none"
                />
            </div>

            <div
                v-if="
                    !foldersAndTemplates.loading.value &&
                    (foldersAndTemplates.folders.value.length > 0 ||
                        foldersAndTemplates.templates.value.length > 0)
                "
                class="flex mt-6"
                :class="{
                    'flex-col bg-white': templateView === 'list',
                    'flex-row flex-wrap gap-8': templateView === 'grid',
                }"
            >
                <Folder
                    v-for="folder in foldersAndTemplates.folders.value"
                    :key="folder.id"
                    :class="{
                        'max-w-80 w-full sm:w-80': templateView === 'grid',
                    }"
                    :folder="folder"
                    :view="templateView"
                    @rename-folder="openRenameFolderModal(folder)"
                    @delete-folder="openDeleteFolderModal(folder)"
                    @open-folder="setDir(folder)"
                >
                </Folder>

                <TemplateBox
                    v-for="template in foldersAndTemplates.templates.value"
                    :key="template.id"
                    :template="template"
                    :integrations="integrations"
                    :view="templateView"
                    @openPreview="openPreview"
                />
            </div>

            <div
                v-else-if="
                    !foldersAndTemplates.loading.value &&
                    foldersAndTemplates.folders.value.length === 0 &&
                    foldersAndTemplates.templates.value.length === 0
                "
                class="xl:mr-16"
            >
                <div
                    class="mt-6 flex w-full flex-col-reverse items-center justify-center rounded-[4px] border border-gray-200 text-center sm:flex sm:flex-row sm:text-left"
                >
                    <div v-if="!foldersAndTemplates.searchingMode.value">
                        <h2
                            class="max-w-md mb-2 text-2xl font-medium text-gray-600"
                        >
                            You haven’t created any templates
                            <span v-if="foldersAndTemplates.currentFolder.value"
                                >in this folder</span
                            >
                            yet.
                        </h2>

                        <p
                            class="max-w-md mb-8 text-sm font-medium text-gray-500"
                        >
                            You can create new template from scratch or choose
                            one of our 150+ professionaly designed templates to
                            start from.
                        </p>

                        <div
                            class="flex justify-center space-x-4 sm:justify-start"
                        >
                            <AtomicButton
                                color="yellow"
                                size="lg"
                                @click="openNewTemplatesModal"
                            >
                                <template #icon>
                                    <div class="mr-2">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                                                stroke="#252F3F"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </template>
                                New template
                            </AtomicButton>

                            <!-- <AtomicButton color="white"
                                >How to create template?</AtomicButton
                            > -->
                        </div>
                    </div>
                    <div v-else>
                        <h2
                            class="max-w-md mb-2 text-2xl font-medium text-gray-600"
                        >
                            We couldn’t find any templates or folders matching
                            your search.
                        </h2>
                        <p
                            class="max-w-md mb-8 text-sm font-medium text-gray-500"
                        >
                            You can create new template from scratch or choose
                            one of our 150+ professionaly designed templates to
                            start from.
                        </p>
                    </div>
                    <div class="flex-shrink-0 mt-10 ml-4">
                        <img
                            class="h-[240px] w-[240px]"
                            src="https://d5aoblv5p04cg.cloudfront.net/application/images/illustrations-empty.png"
                            alt="No templates added yet"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="flex justify-center mt-10">
                <LoadingSpinner size="large"></LoadingSpinner>
            </div>
            <div
                v-if="
                    !foldersAndTemplates.isLastPage.value &&
                    !foldersAndTemplates.loading.value
                "
                class="flex justify-center w-full mt-10"
            >
                <AtomicButton
                    :disabled="foldersAndTemplates.loading.value"
                    color="white"
                    @click="foldersAndTemplates.loadNextPage"
                    >Load more</AtomicButton
                >
            </div>

            
        </div>

        <NewTemplateModal
            v-if="newTemplateModalOpenend"
            :categories="categories"
            :keywords="keywords"
            @close="closeNewTemplatesModal"
            @open-template-preview="openTemplatePreview"
            @create-template-from-scratch="createFromScratch"
        />

        <PreviewNewTemplateModal
            v-if="selectedTemplateToPreview !== undefined"
            :template-type="selectedTemplateType"
            :template="selectedTemplateToPreview"
            :current-folder-id="
                foldersAndTemplates.currentFolder.value
                    ? foldersAndTemplates.currentFolder.value.id
                    : undefined
            "
            @close="selectedTemplateToPreview = undefined"
            @open-template-modal="closeTemplatePreview"
        ></PreviewNewTemplateModal>


        <div class="sticky z-10 mx-auto max-w-max bottom-10">
            <MultipleTemplateActions
                v-if="selectedTemplates.length > 0" 
                activator-type="custom"
            
            />
        </div>
    
    </div>
</template>

<script lang="ts" setup>
import { PropType, watch } from 'vue';

import IFolder from '@/types/Templates/IFolder';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import useConfirm from '@/composables/useConfirm';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import { IIntegration } from '@/types/Integrations/IIntegration';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/vue/24/outline';
import IMarketplaceTemplate from '@/types/Templates/IMarketplaceTemplate';
import useTemplates from './composables/useTemplates';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useDebounceFn } from '@vueuse/core';
import ISimpleTemplate from '@/types/Templates/ISimpleTemplate';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
    integrations: {
        type: Array as PropType<IIntegration<unknown>[]>,
        default: () => [],
    },
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);
const selectedSortBy = ref<string>('');
const selectedSortByDirection = ref<'asc' | 'desc'>('asc');
const selectedTemplateToPreview = ref<
    IPremadeTemplate | IMarketplaceTemplate
>();
const newTemplateModalOpenend = ref<boolean>(false);
const foldersAndTemplates = useFoldersAndTemplates();
const templateView = foldersAndTemplates.view;
const { show } = useConfirm();
const selectedTemplateType = ref<'premade-templates' | 'marketplace-templates'>(
    'premade-templates'
);
const perPage = ref<number>(foldersAndTemplates.per_page.value);
const { selectedTemplates, cancelMultipleSelect, selectAllTemplates } = useTemplates();

onMounted(async () => {
    await foldersAndTemplates.fetch();
});

watch(teamProps, async (newTeamProps, oldTeamProps) => {
    const newTeamId = newTeamProps.id;
    const oldTeamId = oldTeamProps.id;
    if (newTeamId !== oldTeamId) {
        await setDir();
    }
});

watch(selectedSortBy, () => {
    foldersAndTemplates.setSortBy(selectedSortBy.value);
});

watch(selectedSortByDirection, () => {
    foldersAndTemplates.setSortDirection(selectedSortByDirection.value);
});

watch(perPage, () => {
    foldersAndTemplates.setPerPage(perPage.value);
});

const debouncedSearch = useDebounceFn(() => {        
    foldersAndTemplates.search(foldersAndTemplates.searchQuery.value);
}, 220);

watch(foldersAndTemplates.searchQuery, () => {
    debouncedSearch()
});

const openNewTemplatesModal = () => {
    foldersAndTemplates.cancelSearch();
    newTemplateModalOpenend.value = true;
};

const closeNewTemplatesModal = () => {
    newTemplateModalOpenend.value = false;
};

const openRenameFolderModal = async (folder: IFolder) => {
    const newFolderName = await show({
        header: 'Rename folder',
        confirm: 'Rename',
        hasInput: true,
        inputData: {
            label: 'Folder name',
            defaultValue: folder.name,
        },
    });

    if (newFolderName !== undefined && typeof newFolderName === 'string') {
        await foldersAndTemplates.renameFolder(folder, newFolderName);
    }
};

const openDeleteFolderModal = async (folder: IFolder) => {
    const confirmed = await show({
        header: 'Delete folder',
        message: `Are you sure you want to delete folder "${folder.name}" and
                all its content?`,
        type: 'danger',
        confirm: 'Delete folder',
    });

    if (confirmed) {
        await foldersAndTemplates.deleteFolder(folder);
    }
};

const openPreview = (template: ISimpleTemplate) => {
    foldersAndTemplates.cancelSearch();

    router.visit(route('templates.edit', { template: template.id }), {
        preserveScroll: true,
        onSuccess: (response) => {
            if (response.component === 'Templates/index') {
                create({
                    title: 'Template preview error',
                    text: 'You must be subscribed to a Pro plan to access this page.',
                    type: 'error',
                });
            }
        },
        onError: (e) => {
            create({
                title: 'Template preview error',
                text: e.message ?? 'There was an error opening the template.',
                type: 'error',
            });
        },
    });
};

const setDir = async (folder?: IFolder) => {
    await foldersAndTemplates.setDir(folder);
};

const openNewFolderModal = async () => {
    foldersAndTemplates.cancelSearch();

    const newFolderName = await show({
        header: 'Create a new folder',
        confirm: 'Create folder',
        hasInput: true,
        inputData: {
            label: 'New folder name',
        },
    });

    if (newFolderName !== undefined && typeof newFolderName === 'string') {
        await foldersAndTemplates.createFolder(newFolderName);
    }
};

const openTemplatePreview = (
    template: IPremadeTemplate | IMarketplaceTemplate,
    type: 'premade-templates' | 'marketplace-templates'
) => {
    closeNewTemplatesModal();
    selectedTemplateToPreview.value = template;
    selectedTemplateType.value = type;
};
const closeTemplatePreview = () => {
    selectedTemplateToPreview.value = undefined;
    newTemplateModalOpenend.value = true;
};

const createFromScratch = () => {
    type CreateFromScratchDataShape = {
        premade_template_id: 1;
        template_folder_id?: number;
    };

    const data: CreateFromScratchDataShape = {
        premade_template_id: 1,
    };

    if (
        foldersAndTemplates.currentFolder.value &&
        foldersAndTemplates.currentFolder.value.id !== undefined
    ) {
        data.template_folder_id = foldersAndTemplates.currentFolder.value.id;
    }

    router.post(
        route('templates.store'),
        {
            ...data,
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'New template created',
                    text: 'Your new template has been successfully created.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Template not created',
                    text: 'There was an error creating your new template.',
                    type: 'error',
                });
            },
        }
    );
};

const toggleTemplateView = (view: 'list' | 'grid') => {
    foldersAndTemplates.setView(view);
};
</script>
