import useTemplates from './useTemplates';
import { create } from '@composables/useNotifications';

const { fetchData } = useTemplates();
const { initializeCurrentFolder } = useFolders();
const { cancelMultipleSelect } = useTemplates();
const foldersAndTemplates = useFoldersAndTemplates();

const timeout = ref(0);
const listeningForEvents = ref(false);

export const useFolderActions = (
    userId: number,
) => {
    
    const startLoading = () => {    
        foldersAndTemplates.loading.value = true;
    
        timeout.value = setTimeout(async () => {
            create({
                title: 'Folders are being processed. It may take a while.',
                type: 'info',
            });
            await fetchData();
            foldersAndTemplates.loading.value = false;
        }, 5000);
    }
    
    const cancelLoading = async () => {
        clearTimeout(timeout.value);
        await fetchData();
        foldersAndTemplates.loading.value = false;
        cancelMultipleSelect();
    }
    
    const listenForEvents = () => {   
        if (listeningForEvents.value === true) {
            return;
            }
        listeningForEvents.value = true;
        
        window.Echo.private('App.Topol.Users.User.' + userId)
            .listen('.App\\Topol\\Templates\\Events\\TemplateFolderDuplicated', async () => {
                handleEvent('Template folder duplicated', 'success');
                await initializeCurrentFolder();
            })
            .listen('.App\\Topol\\Templates\\Events\\DuplicateTemplateFolderExceptionThrown', async () => {
                handleEvent('Template folder duplication failed', 'error');
            });
    };

    const handleEvent = async (event: string, type: 'success'|'error') => {
        create({
            title: event,
            type: type,
        });
        foldersAndTemplates.resumeSearch();
        cancelLoading();
    }

    const stopListeningForEvents = () => {
        listeningForEvents.value = false;
    }

    return {
        startLoading,
        cancelLoading,
        listenForEvents,
        handleEvent,
        stopListeningForEvents,

    }
}

