<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Send testing email</div>
        </template>

        <template #body>
            <div class="p-6">
                <div class="flex flex-col">
                   

                    <div 
                        v-if="testingEmails.length > 0"
                        class="flex flex-col gap-4 pb-4 border-b border-gray-300"
                        >
                        <div v-for="email in testingEmails" :key="email" class="flex">
                            <CheckboxInput
                                :checked="selectedEmails.includes(email)"
                                size="lg"
                                :value="email"
                                class="mt-1"
                                :label="email"
                                @update:checked="update(email)"
                            />
                        </div>
                    </div>

                   
                    <TextInput
                        v-model="state.emails"
                        placeholder="Enter emails separated by comma"
                        :label="testingEmails.length > 0 ? 'Additional email addresses' : 'Email addresses'"
                        :class="testingEmails.length > 0 ? 'mt-4' : ''"
                        :error="error"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between p-6 border-t border-gray-200">
                <AtomicButton
                    color="white"
                    size="md"
                    @click="$emit('close')"
                >
                    Close
                </AtomicButton>

                <AtomicButton
                    color="yellow"
                    size="md"
                    :disabled="state.emails === '' && selectedEmails.length === 0"
                    @click="submit"
                >
                Send
                </AtomicButton>
            </div>
        </template>
        
    </Modal>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import useVuelidate from '@vuelidate/core';
import { email as emailValidator, required } from '@vuelidate/validators';

const props = defineProps({
    testingEmails: {
        type: Array as PropType<string[]>,
        required: true,
    },
});

const emit = defineEmits(['close', 'submit']);

const selectedEmails = ref<string[]>(
   [
    props.testingEmails[0],
   ]
);

const state = reactive({
    emails: ref<string>(''),
});

const rules = {
      emails: {
        required,
        emailsList: (value: string) => {
          if (!value) return true;
          const emailArray = value.split(',').map((email) => email.trim());
          return emailArray.every((email) => emailValidator.$validator(email));
        }
      }
    };

const v$ = useVuelidate(rules, state);
const error = ref('');

const submit = () => {
    v$.value.$touch();
    
    if (v$.value.$invalid) {
        error.value = 'Please enter valid email addresses';
        return;
    }

    const selectedEmailsValue = [...new Set([...selectedEmails.value, ...state.emails.split(',').map((email) => email.trim())])];

    emit('submit', selectedEmailsValue);
};

const update = (email: string) => {
    if (selectedEmails.value.includes(email)) {
        selectedEmails.value = selectedEmails.value.filter((e) => e !== email);
    } else {
        selectedEmails.value.push(email);
    }
};



</script>
