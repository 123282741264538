<template>
    <AuthLayout>
        <div
            class="mt-8 flex w-full flex-col items-center overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mt-4">
                <img
                    v-if="selectedImage === 1"
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/auth/giphy.gif"
                    class="h-[200px] w-[200px] rounded-full object-cover"
                />
                <img
                    v-else
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/auth/giphy2.gif"
                    class="h-[200px] w-[200px] rounded-full object-cover"
                />
            </div>

            <div class="mt-9 text-xl font-bold text-gray-800">
                All done! You’ve been logged out
            </div>

            <div class="mt-3 text-center text-sm text-gray-500">
                We’re sorry to see you go. Until next time!
            </div>

            <div class="mb-3 mt-9 w-full">
                <inertia-link :href="route('login')">
                    <MegaButton>Sign in again </MegaButton>
                </inertia-link>
            </div>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import MegaButton from '@/components/Atomic/Buttons/MegaButton.vue';

const selectedImage = Math.floor(Math.random() * 2);
</script>
