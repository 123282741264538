<template>
    <th
        scope="col"
        :class="{
            'px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500':
                props.header.text !== '',
        }"
        class="group"
    >
        <div>
            <div
                class="relative flex"
                :class="[
                    props.header.align === 'left' ? 'justify-start' : '',
                    props.header.align === 'center' ? 'justify-center' : '',
                    props.header.align === 'right' ? 'justify-end' : '',
                ]"
            >
                <div class="relative flex items-center">
                    {{ props.header.text }}

                    <div
                        v-if="props.header.sortable"
                        class="absolute -right-5 group-hover:block"
                        :class="{
                            block: isAscActive || isDescActive,
                            hidden: !isAscActive && !isDescActive,
                        }"
                    >
                        <button
                            :data-testid="'asc-sort-' + props.header.sortableId"
                            class="block"
                            @click="sortBy('asc')"
                        >
                            <ChevronUpIcon
                                class="w-4 h-4 -mb-1"
                                :class="[
                                    isAscActive
                                        ? 'text-gray-900'
                                        : 'text-gray-400',
                                ]"
                            ></ChevronUpIcon>
                        </button>

                        <button
                            :data-testid="
                                'desc-sort-' + props.header.sortableId
                            "
                            class="block"
                            @click="sortBy('desc')"
                        >
                            <ChevronDownIcon
                                class="w-4 h-4"
                                :class="[
                                    isDescActive
                                        ? 'text-gray-900'
                                        : 'text-gray-400',
                                ]"
                            ></ChevronDownIcon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </th>
</template>

<script lang="ts" setup>
import IDataTableHeader from '../types/IDataTableHeader';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';

const props = defineProps({
    header: {
        type: Object as () => IDataTableHeader,
        required: true,
    },
    sortBy: {
        type: String,
        default: '',
    },
    sortByDirection: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update:sortBy', 'update:sortByDirection']);

const isAscActive = computed(() => {
    return (
        props.sortByDirection === 'asc' &&
        props.sortBy === props.header.sortableId
    );
});

const isDescActive = computed(() => {
    return (
        props.sortByDirection === 'desc' &&
        props.sortBy === props.header.sortableId
    );
});

const sortBy = (direction: 'asc' | 'desc' | undefined) => {
    if (props.header.sortableId === undefined) {
        throw new Error(
            `Sortable column ${props.header.toString()} has no sortableId provded!`
        );
    }

    //Going from sorted state to default not sorted state
    if (props.sortByDirection === direction) {
        emit('update:sortBy', '');
        emit('update:sortByDirection', undefined);
    } else {
        emit('update:sortBy', props.header.sortableId);
        emit('update:sortByDirection', direction);
    }
};
</script>
