<template>
    <div>
        <label
            v-if="label"
            role="label"
            class="relative pr-3 pb-[3px] text-sm font-medium leading-normal text-gray-700"
        >
            {{ label }}
        </label>

        <FloatingDropdown
            class="w-full cursor-pointer"
            :is-open="isOpen"
            placement="bottom-start"
            @close="isOpen = false"
            @open="isOpen = true"
            @keydown.esc="isOpen = false"
        >
            <template #dropdown-button>
                <button
                    type="button"
                    class="group group relative w-full cursor-pointer select-none truncate rounded-md border border-gray-300 bg-white py-[7px] pl-3 pr-10 text-left text-sm text-gray-600 shadow-button ring-gray-300 ring-opacity-50 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                    <span
                        v-if="!value.length"
                        class="block truncate py-0.5 text-gray-400 group-hover:text-gray-600 group-focus:text-gray-600"
                    >
                        {{ placeholder }}
                    </span>

                    <div
                        v-if="value.length"
                        class="flex flex-wrap gap-2 truncate text-gray-600"
                    >
                        <div
                            v-for="(option, index) in value"
                            :key="index"
                            :data-testid="'selected' + option.title"
                            class="flex cursor-pointer items-center gap-[10px] rounded-md bg-orange-500 py-1 pl-2 pr-[10px] text-gray-800"
                            @click.stop="deleteOption(option)"
                        >
                            <div class="text-xs">{{ option.title }}</div>
                            <div>
                                <svg
                                    class="h-2 w-2"
                                    width="8"
                                    height="8"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.75403 0.55546C1.59686 0.403662 1.38636 0.319666 1.16786 0.321565C0.949365 0.323463 0.740354 0.411104 0.585847 0.565611C0.43134 0.720118 0.343699 0.929129 0.341801 1.14763C0.339902 1.36612 0.423898 1.57662 0.575696 1.73379L2.83171 3.9898L0.575696 6.24582C0.496104 6.32269 0.432619 6.41464 0.388945 6.51631C0.345271 6.61798 0.322282 6.72733 0.321321 6.83798C0.320359 6.94863 0.341444 7.05836 0.383344 7.16078C0.425245 7.26319 0.487123 7.35623 0.565367 7.43448C0.643611 7.51272 0.736654 7.5746 0.839068 7.6165C0.941481 7.6584 1.05121 7.67949 1.16186 7.67852C1.27251 7.67756 1.38186 7.65457 1.48353 7.6109C1.5852 7.56723 1.67716 7.50374 1.75403 7.42415L4.01004 5.16814L6.26605 7.42415C6.42322 7.57595 6.63372 7.65994 6.85222 7.65804C7.07072 7.65614 7.27973 7.5685 7.43424 7.414C7.58874 7.25949 7.67638 7.05048 7.67828 6.83198C7.68018 6.61348 7.59619 6.40298 7.44439 6.24582L5.18837 3.9898L7.44439 1.73379C7.59619 1.57662 7.68018 1.36612 7.67828 1.14763C7.67638 0.929128 7.58874 0.720118 7.43424 0.565611C7.27973 0.411104 7.07072 0.323463 6.85222 0.321565C6.63372 0.319666 6.42322 0.403662 6.26605 0.55546L4.01004 2.81147L1.75403 0.55546Z"
                                        fill="#252F3F"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                    >
                        <svg
                            class="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>
                </button>
            </template>
            <template #dropdown-content>
                <div class="w-full rounded-md bg-white shadow-lg">
                    <ul
                        tabindex="-1"
                        role="listbox"
                        class="max-h-60 overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <li
                            v-for="(option, index) in options"
                            :key="index"
                            class="relative select-none pr-4 text-base text-gray-700 hover:bg-gray-200 hover:text-white focus:outline-none sm:text-xs"
                        >
                            <span
                                class="flex items-center truncate py-3 pl-4 font-normal"
                            >
                                <CheckboxInput
                                    :label="option.title"
                                    size="md"
                                    :name="option.title"
                                    :checked="value.includes(option)"
                                    @update:checked="check(option, $event)"
                                />
                            </span>
                        </li>
                    </ul>
                </div>
            </template>
        </FloatingDropdown>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import IMultiSelectable from '@/types/Atomic/IMultiSelectable';

const props = defineProps({
    options: {
        type: Array as PropType<IMultiSelectable[]>,
        required: true,
    },
    value: {
        type: Array as PropType<IMultiSelectable[]>,
        default: () => [],
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
});

const isOpen = ref(false);

const emit = defineEmits(['update:value']);

const check = (option: IMultiSelectable, checked: boolean) => {
    let updatedValue = [...props.value];
    if (checked) {
        updatedValue.push(option);
    } else {
        updatedValue = updatedValue.filter((a) => a.title !== option.title);
    }
    emit('update:value', updatedValue);
};

const deleteOption = (option: IMultiSelectable) => {
    let updatedValue = [...props.value];
    updatedValue = updatedValue.filter((a) => a.title !== option.title);
    emit('update:value', updatedValue);
};
</script>

<style></style>
