<template>
    <div data-cy="plugin-settings-create-token-modal">
        <Modal v-if="!tokenCreated" size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">New API Token</div>
            </template>

            <template #body>
                <div class="mb-4 px-6">
                    <TextInput
                        v-model="v$.form.name.$model"
                        class="mt-5 w-full md:w-[470px]"
                        placeholder="Enter token name"
                        label="New Token Name"
                        data-cy="create-token-name"
                        :error="getError('name')"
                        required
                    />

                    <TextInput
                        v-model="v$.form.domains.$model"
                        class="mt-6 mb-6 w-full md:w-[470px]"
                        placeholder="Enter your domain(s)"
                        label="Token Domains"
                        data-cy="create-token-domains"
                        :error="getError('domains')"
                        required
                    >
                        <template #help>
                            List of allowed domains separated by comma.
                        </template>
                    </TextInput>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        data-cy="create-token-cancel"
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        size="md"
                        color="yellow"
                        data-cy="create-token-submit"
                        :disabled="loading || v$.$invalid"
                        @click="createApiToken"
                    >
                        Create new token
                    </AtomicButton>
                </div>
            </template>
        </Modal>

        <Modal v-else size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">New API Token</div>
            </template>

            <template #body>
                <div class="p-6">
                    <div
                        class="relative mt-3 flex rounded bg-yellow-100 pb-[13px] pl-3.5 pr-6 pt-3"
                    >
                        <svg
                            class="shrink-0"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.25685 3.09898C9.02185 1.73898 10.9788 1.73898 11.7428 3.09898L17.3228 13.019C18.0728 14.353 17.1098 15.999 15.5808 15.999H4.41985C2.88985 15.999 1.92685 14.353 2.67685 13.019L8.25685 3.09898ZM10.9998 13C10.9998 13.2652 10.8945 13.5196 10.707 13.7071C10.5194 13.8946 10.2651 14 9.99985 14C9.73463 14 9.48028 13.8946 9.29274 13.7071C9.10521 13.5196 8.99985 13.2652 8.99985 13C8.99985 12.7348 9.10521 12.4804 9.29274 12.2929C9.48028 12.1053 9.73463 12 9.99985 12C10.2651 12 10.5194 12.1053 10.707 12.2929C10.8945 12.4804 10.9998 12.7348 10.9998 13ZM9.99985 4.99998C9.73463 4.99998 9.48028 5.10534 9.29274 5.29287C9.10521 5.48041 8.99985 5.73476 8.99985 5.99998V8.99998C8.99985 9.2652 9.10521 9.51955 9.29274 9.70709C9.48028 9.89462 9.73463 9.99998 9.99985 9.99998C10.2651 9.99998 10.5194 9.89462 10.707 9.70709C10.8945 9.51955 10.9998 9.2652 10.9998 8.99998V5.99998C10.9998 5.73476 10.8945 5.48041 10.707 5.29287C10.5194 5.10534 10.2651 4.99998 9.99985 4.99998Z"
                                fill="#D69E2E"
                            />
                        </svg>
                        <div class="pl-3.5 text-sm leading-normal">
                            <p class="font-medium text-yellow-800">
                                API Token created
                            </p>
                            <p class="mt-[7px] text-yellow-700">
                                Here is your new API token. This is the only
                                time the token will ever be displayed, so be
                                sure not to lose it! You may revoke the token at
                                any time from your API settings.
                            </p>
                        </div>
                    </div>

                    <div class="relative">
                        <textarea
                            ref="token"
                            style="resize: none"
                            readonly
                            class="mt-5 block w-full rounded-md border border-gray-300 bg-white pt-2 pb-2.5 pr-[22px] pl-3 text-sm leading-normal text-gray-800 placeholder-gray-400 shadow-sm transition duration-100 ease-in-out hover:bg-gray-50 focus:border-2 focus:border-gray-600 focus:pl-[11px] focus:pt-[7px] focus:pr-[21px] focus:pb-[9px] focus:outline-none md:w-[470px]"
                            v-text="newTokenData?.token ?? ''"
                        ></textarea>

                        <transition
                            enter-active-class="transition duration-100 ease-out transform"
                            enter-from-class="scale-95 opacity-0"
                            enter-to-class="scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in transform"
                            leave-from-class="scale-100 opacity-100"
                            leave-to-class="scale-95 opacity-0"
                        >
                            <Tooltip
                                v-if="copiedSuccess"
                                class="absolute top-[-55px]"
                                style="left: 50%; transform: translate(-50%, 0)"
                            >
                                Copied to clipboard!
                            </Tooltip>
                        </transition>
                    </div>

                    <AtomicButton
                        class="relative mt-5"
                        color="white"
                        size="lg"
                        @click="copyTextArea"
                    >
                        <template #icon>
                            <div class="mr-2">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 2C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V12C7 12.5304 7.21071 13.0391 7.58579 13.4142C7.96086 13.7893 8.46957 14 9 14H15C15.5304 14 16.0391 13.7893 16.4142 13.4142C16.7893 13.0391 17 12.5304 17 12V6.414C16.9999 5.88361 16.7891 5.37499 16.414 5L14 2.586C13.625 2.2109 13.1164 2.00011 12.586 2H9Z"
                                        fill="#9FA6B2"
                                    />
                                    <path
                                        d="M3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6V16H13C13 16.5304 12.7893 17.0391 12.4142 17.4142C12.0391 17.7893 11.5304 18 11 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
                                        fill="#9FA6B2"
                                    />
                                </svg>
                            </div>
                        </template>
                        Copy token to clipboard
                    </AtomicButton>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        size="md"
                        color="yellow"
                        data-cy="create-token-finish"
                        @click="$emit('close')"
                    >
                        Finish
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import Modal from '@atomic/Modals/Modal.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import route from 'ziggy-js';
import axios from 'axios';
import { create } from '@/composables/useNotifications';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import IInputErrors from '@/types/Atomic/IInputErrors';

interface INewTokenData {
    token: string;
}

defineEmits(['close', 'create']);

const state = reactive({
    form: {
        name: '',
        domains: '',
    },
});

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        domains: {
            required,
            maxLength: maxLength(5000),
        },
    },
};

const v$ = useVuelidate(rules, state);

const errors = ref<IInputErrors>({});
const loading = ref(false);
const newTokenData = ref<INewTokenData | null>(null);
const tokenCreated = ref(false);
const copiedSuccess = ref(false);
const token = ref(null);

const createApiToken = async () => {
    v$.value.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        try {
            loading.value = true;
            const result = await axios.post(
                route('api-tokens.store'),
                state.form
            );

            if (result.data.success === true) {
                newTokenData.value = result.data.data;
                tokenCreated.value = true;
                create({
                    title: 'API Token created',
                    text: 'API Token has been successfully created.',
                    type: 'success',
                });
            }
        } catch (err) {
            errors.value = err.response.data.errors;
            create({
                title: 'API Token not created',
                text: 'There has been an error creating API Token.',
                type: 'error',
            });
        } finally {
            loading.value = false;
        }
    }
};
const copyTextArea = () => {
    let element = token.value as HTMLInputElement;
    element.select();
    document.execCommand('copy');

    copiedSuccess.value = true;
    setTimeout(() => {
        copiedSuccess.value = false;
    }, 1000);
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field]?.[0] ||
        ''
    );
};
</script>
