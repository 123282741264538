<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Export to SendPulse</div>
        </template>

        <template #body>
            <div class="p-6">
                <form>
                    <SelectInput
                        v-model="state.language"
                        class=""
                        placeholder="Choose language"
                        :data="languages"
                        label="Template language"
                        required
                        :error="getError('language')"
                        :disabled="loading"
                        @blurred="v$.language.$touch()"
                    />
                </form>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    :disabled="loading || v$.$invalid"
                    @click="exportToSendPulse"
                >
                    Export
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { PropType, reactive, ref } from 'vue';
import { IIntegration, ISendPulse } from '@/types/Integrations/IIntegration';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';

const emit = defineEmits(['close']);

const props = defineProps({
    integration: {
        required: true,
        type: Object as PropType<IIntegration<ISendPulse>>,
        default: null,
    },
    templateId: {
        required: true,
        type: Number,
    },
    templateName: {
        required: true,
        type: String,
    },
});

const state = reactive({
    language: 'en',
});

const rules = {
    language: {
        required,
    },
};

const v$ = useVuelidate(rules, state);

const loading = ref(false);
const languages = ref<ISelectable[]>([
    {
        title: 'English',
        value: 'en',
    },
    {
        title: 'Russian',
        value: 'ru',
    },
    {
        title: 'Ukrainian',
        value: 'ua',
    },
    {
        title: 'Turkish',
        value: 'tr',
    },
    {
        title: 'Spanish',
        value: 'es',
    },
    {
        title: 'Portuguese',
        value: 'pt',
    },
]);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || '';
};

const exportToSendPulse = async () => {
    loading.value = true;

    try {
        const response = await axios.post(
            route('templates.exports.sendpulse', {
                template: props.templateId,
            }),
            {
                language: state.language,
                integration_id: props.integration.id,
            }
        );

        if (response.data.success) {
            create({
                title: 'Successful export',
                text: 'Template has been successfully exported to SendPulse.',
                type: 'success',
            });
            emit('close');

            return;
        }
        create({
            title: 'Unsuccessful export',
            text: 'Template has not been exported to SendPulse.',
            type: 'error',
        });
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};
</script>
