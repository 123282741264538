<template>
    <SettingsTabs tab-key="other-settings">
        <div class="mb-[98px]">
            <div
                class="mt-[44px] grid-cols-layout pb-5 lg:grid"
                @submit.prevent="updateNotificationPreferences"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Email notifications
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Choose which emails you want to receive.
                    </p>
                </div>

                <form
                    class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
                >
                    <div class="flex flex-wrap px-6 pb-5 pt-[23px] gap-6 border-b border-gray-200">
                       <Toggle v-model="editorComments">
                            <span>
                                New comments from Editor
                            </span>
                        </Toggle>
                    </div>

                    <div class="flex justify-end">
                        <div class="pr-6 my-3">
                            <AtomicButton
                                type="submit"
                                color="yellow"
                                size="sm"
                                :disabled="
                                    loading
                                "
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </SettingsTabs>
</template>

<script lang="ts" setup>
import SettingsTabs from '../components/SettingsTabs.vue';

import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from '@inertiajs/vue3';

const inertiaProps = usePage<ITopolPageProps>();

const features = ref(JSON.parse(inertiaProps.props.user.features));
const notificationsEnabled = computed(() => {    
    if(features.value.comments_notifications_disabled === null){
        return true;
    } else if (features.value.comments_notifications_disabled === "1"){        
        return false;
    } else return true;

});

const loading = ref(false);

const editorComments = ref(notificationsEnabled.value);

const updateNotificationPreferences = () => {
    router.put(route('settings.features.update'), {
        feature: 'comments_notifications_disabled',
        enable: !editorComments.value,
    }, {
        onStart: () => {
            loading.value = true;
        },
        onFinish: () => {
            loading.value = false;
        },
        onSuccess: () => {
            create({
                title: 'Notification preferences updated',
                text: 'Your notification preferences have been updated.',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Error',
                text: 'An error occurred while updating your notification preferences.',
                type: 'error',
            });
        },
    });
};


</script>

<style></style>
