<template>
    <InvoicesTabs tab-key="legacy-invoices">
        <div class="mb-12">
            <div class="flex flex-col pb-5 mt-9 lg:flex-row lg:justify-between">
                <DataTable
                    class="w-full"
                    title="Invoices"
                    :headers="headers"
                    :items="legacyInvoices"
                >
                    <template #item-created_at="{ data }">
                        <div v-if="data" class="p-6 text-gray-600">
                            {{
                                format(
                                    new Date(data as string),
                                    'MMMM d, yyyy '
                                )
                            }}
                        </div>
                    </template>

                    <template #item-total="{ data }">
                        <div class="flex justify-center p-6 text-gray-500">
                            ${{ data }}
                        </div>
                    </template>

                    <template #item-custom_download="{ row }">
                        <div
                            class="flex justify-center p-6 text-sm font-medium cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                        >
                            <div
                                v-if="row.flexibee_id"
                                @click="downloadInvoice(row.flexibee_id)"
                            >
                                Download invoice
                            </div>
                        </div>
                    </template>

                    <template #item-custom_show="{ row }">
                        <AtomicButton
                            v-if="row.flexibee_id"
                            size="sm"
                            color="white"
                            @click="showInvoice(row.flexibee_id)"
                        >
                            <template #icon>
                                <MagnifyingGlassIcon class="w-6 h-6" />
                            </template>
                        </AtomicButton>
                    </template>
                </DataTable>
            </div>
        </div>
    </InvoicesTabs>
</template>
<script lang="ts" setup>
import { format } from 'date-fns';
import route from 'ziggy-js';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ILegacyInvoice from '@/types/Invoices/ILegacyInvoice';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import usePdf from '@/composables/usePdf';

defineProps<{
    legacyInvoices: IPaginationData<ILegacyInvoice>;
}>();

const { show, download } = usePdf();

const headers: IDataTableHeader[] = [
    {
        text: 'ID',
        id: 'id',

        sortable: true,
        sortableId: 'id',
    },
    {
        text: 'Date',
        id: 'created_at',

        sortable: true,
        sortableId: 'created_at',
    },
    {
        text: 'Invoice total',
        id: 'total',
        align: 'center',
        sortable: true,
        sortableId: 'total',
    },
    {
        text: '',
        id: 'custom_download',
    },
    {
        text: '',
        id: 'custom_show',
    },
];

const downloadInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    download(url, 'Invoice');
};

const showInvoice = (id: number) => {
    const url = route('kiosk.invoices.download-pdf', { invoice: id });
    show(url);
};
</script>

<style></style>
