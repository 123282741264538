import ICart from '@/types/Marketplace/ICart';
import { create } from '@composables/useNotifications';
import { ref } from 'vue';

const opened = ref(false);
const cart = ref<ICart>();
const loading = ref(false);

export default () => {
    const open = (): void => {
        opened.value = true;
    };

    const close = (): void => {
        opened.value = false;
    };

    const fetchCart = async () => {
        loading.value = true;
        try {
            const res = await axios.get(
                route('marketplace.shopping-cart-data.index')
            );

            if (res.data.success) {
                cart.value = res.data.data;
            }
        } catch (error) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    };
    const addTemplateToCart = (templateId: number) => {
        router.post(
            route('marketplace.templates-cart.store', {
                marketplaceTemplate: templateId,
            }),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Template added to cart',
                        type: 'success',
                    });
                    fetchCart();
                },
                onError: (e) => {
                    create({
                        title: 'Template not added to cart',
                        text: e.error,
                        type: 'error',
                    });
                },
            }
        );
    };

    const removeTemplateFromCart = (templateId: number) => {
        router.delete(
            route('marketplace.templates-cart.destroy', {
                marketplaceTemplate: templateId,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    fetchCart();
                },
            }
        );
    };

    return {
        fetchCart,
        opened,
        open,
        close,
        cart,
        loading,
        addTemplateToCart,
        removeTemplateFromCart,
    };
};
