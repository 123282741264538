<template>
    <div class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Autosave interval
            </h3>

            <p class="max-w-xs text-sm text-gray-500">
                Minimum interval is 30 seconds.
            </p>
        </div>
        <div
            class="mx-auto flex h-full w-full items-center rounded-md bg-white shadow-card lg:mx-0"
        >
            <div
                class="flex flex-wrap justify-start gap-4 px-6 pb-5 pt-[23px] sm:flex-nowrap"
            >
                <TextInput
                    :model-value="modelValue"
                    class="w-full max-w-xs"
                    type="number"
                    @update:modelValue="updateInterval"
                />

                <AtomicButton color="white" size="md" @click="resetToDefaults">
                    Reset
                </AtomicButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    defaultValue: {
        type: Number,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

const resetToDefaults = () => {
    emit('update:modelValue', props.defaultValue);
};

const updateInterval = (value: string) => {
    const number = parseInt(value);
    emit('update:modelValue', number);
};
</script>
