<template>
    <div
        draggable="true"
        :class="{
            'max-w-80 w-full sm:w-80 sm:max-w-none md:w-56': view === 'grid',
        }"
        data-testid="template-box"
        @mouseover="isHovered = true"
        @dragstart="startDrag($event)"
        @mouseleave="isHovered = false"
        @dragend="endDrag($event)"
    >
        <!-- Grid view -->
        <div
            v-if="view === 'grid'"
            class="relative w-full bg-white rounded-md max-w-80 group shadow-card sm:w-80 sm:max-w-none md:w-56"
            :class="{
                'template-shadow-selected': isSelected,
                'h-[364px]': !onlyImage,
                'h-[332px]': onlyImage,
            }"
        >
            <div
                class="absolute w-full bg-white rounded-md cursor-pointer sm:w-80 md:w-56"
                :class="{
                    'absolute w-80 rounded-md ring-2 ring-orange-500 md:w-56':
                        isSelected,
                }"
            >
                <div
                    class="relative flex justify-center overflow-hidden cursor-pointer"
                    :class="{ 'hover:shadow-lg': onlyImage }"
                    @click="openTemplate"
                >
                    <div
                        v-if="
                            (isHovered || selectedTemplates.length > 0) &&
                            !onlyImage && !marketplace
                        "
                        class="absolute top-2.5 left-3.5"
                        @click.stop
                    >
                        <CheckboxInput
                            :checked="isSelected"
                            size="lg"
                            @update:checked="selectTemplate(template as ISimpleTemplate)"
                        />
                    </div>
                    <img
                        :src="template.img_thumb_url"
                        :alt="template.name"
                        class="object-cover object-top w-56"
                        :class="{
                            'h-[300px] rounded-t-md': !onlyImage,
                            'h-[332px] rounded-md': onlyImage,
                        }"
                        @error="handleImageError"
                    />

                </div>

                <div
                    v-if="!onlyImage"
                    class="relative h-16 border-t border-gray-200"
                >
                    <div
                        v-if="status"
                        class="absolute top-[-12px] ml-[18px] rounded-md bg-gray-900 px-2 py-1 text-white"
                        @click="openTemplate"
                    >
                        <p class="text-xs font-medium">{{ status }}</p>
                    </div>
                    <div
                        class="relative pl-[18px] pr-5 pt-3 pb-[11px]"
                        @click="openTemplate"
                    >
                        <p
                            class="h-6 pr-3 text-sm leading-normal text-gray-800 truncate max-w-max"
                            @mouseover="showTooltip = true"
                            @mouseleave="showTooltip = false"
                        >
                            {{ template.name }}
                        </p>
                        <transition
                            enter-active-class="transition duration-100 ease-out transform"
                            enter-from-class="scale-95 opacity-0"
                            enter-to-class="scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in transform"
                            leave-from-class="scale-100 opacity-100"
                            leave-to-class="scale-95 opacity-0"
                        >
                            <Tooltip
                                v-if="showTooltip && template.name"
                                class="absolute left-0 bottom-16"
                            >
                                {{ template.name }}
                            </Tooltip>
                        </transition>

                        <p
                            v-if="template.updated_at"
                            class="mt-0.5 text-xs leading-normal text-gray-400"
                        >
                            {{
                                format(
                                    new Date(template?.updated_at),
                                    "d. L. yyyy '('HH:mm:ss')'"
                                )
                            }}
                        </p>
                    </div>

                    <transition
                        enter-active-class="transition duration-200 ease-out transform"
                        enter-from-class="scale-95 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in transform"
                        leave-from-class="scale-100 opacity-100"
                        leave-to-class="scale-95 opacity-0"
                    >
                        <div class="absolute bottom-5 right-2.5">
                            <TemplateActions
                                v-if="selectedTemplates.length === 0"
                                data-cy="template-box-actions"
                                class="hidden group-hover:block"
                                :is-hovered="isHovered"
                                :template="template"
                                :integrations="integrations"
                            />
                        </div>
                    </transition>
                </div>
            </div>

            <div
                v-if="onlyImage && isNew()"
                class="absolute top-2 right-2 cursor-pointer rounded-md bg-green-600 px-2 py-[3px] text-xs font-medium leading-[18px] text-gray-50 ring-1 ring-white"
                @click="openTemplate"
            >
                New
            </div>
        </div>

        <!-- List view -->
        <div
            v-if="view === 'list'"
            class="relative flex flex-row items-center justify-between gap-2 hover:bg-gray-50"
            :class="{
                'border-r-4 border-orange-500': isSelected,
            }"
        >
            <div
                v-if="(isHovered || selectedTemplates.length > 0) && !onlyImage && !marketplace "
                class="absolute left-0 z-10 top-2.5"
                @click.stop
            >
                <CheckboxInput
                    :checked="isSelected"
                    size="lg"
                    class="relative flex items-center justify-center w-12 h-12 pl-2 "
                    @update:checked="
                        selectTemplate(template as ISimpleTemplate)
                    "
                />

            
            </div>

            <div
                class="relative flex items-center w-full py-3 pl-12 pr-5 cursor-pointer justify-left"
                @click="openTemplate"
            >
                <div
                    class="relative h-[45px] w-[56px]"
                    @mouseenter="handleShowTemplatePreview"
                    @mouseleave="handleHideTemplatePreview"
                >
                    <img
                        :src="template.img_thumb_url"
                        :alt="template.name"
                        class="h-[45px] w-[56px] object-cover object-top"
                        @error="handleImageError"
                    />
                </div>

                <div class="relative flex flex-row gap-5 ml-5">
                    <div>
                        <div class="flex items-center justify-start h-6 gap-2">
                            <button type="button" class="flex items-center">
                                <p
                                    class="max-w-[120px] truncate text-sm font-medium leading-6 text-gray-900 md:max-w-xs lg:max-w-lg"
                                >
                                    {{ template.name }}
                                </p>
                            </button>

                            <div
                                v-if="status"
                                class="hidden px-2 py-1 text-white bg-gray-900 rounded-md sm:block"
                            >
                                <p class="text-xs font-medium">{{ status }}</p>
                            </div>
                        </div>

                        <div
                            class="flex items-center text-sm leading-normal text-gray-500 gap-x-2"
                        >
                            <div
                                class="flex items-center text-xs leading-5 text-gray-500 gap-x-2"
                            >
                                <p
                                    v-if="template.updated_at"
                                    class="hidden whitespace-nowrap sm:block"
                                >
                                    Last updated on
                                    {{
                                        format(
                                            new Date(template.updated_at),
                                            "d. L. yyyy '('HH:mm:ss')'"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Tooltip
                        v-if="showTooltipTemplate"
                        type="image"
                        class="absolute bottom-[65px] left-[-40px] h-[160px] w-56 rounded-md bg-white"
                    >
                        <img
                            :src="template.img_thumb_url"
                            :alt="template.name"
                            class="h-[160px] w-56 rounded-md object-cover object-top"
                            @error="handleImageError"
                        />
                    </Tooltip>
                </div>
            </div>
            <div class="relative">
                <div
                    v-if="!marketplace"
                    class="flex items-center flex-none py-3 pl-3 sm:pl-5"
                    :class="{
                        'pr-3 sm:gap-x-4 sm:pr-5': !isSelected,
                        'pr-2 sm:gap-x-4 sm:pr-4': isSelected,
                    }"
                >
                    <TemplateActions
                        v-if="selectedTemplates.length === 0"
                        is-hovered
                        :template="template"
                        :integrations="integrations"
                    />

                </div>
                <div v-if="marketplace">
                    <AtomicButton
                        color="yellow"
                        class="mr-4"
                        size="sm"
                        @click="openTemplate"
                        >open</AtomicButton
                    >
                </div>
            </div>

            <div
                class="absolute bottom-0 left-1/2 h-[1px] w-[calc(100%-16px)] -translate-x-1/2 transform bg-gray-200"
            ></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import TemplateActions from './TemplateActions.vue';
import { format } from 'date-fns';
import { PropType } from 'vue';
import ISimpleTemplate from '@/types/Templates/ITemplate';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import IKeyword from '@/types/Templates/IKeyword';
import { IIntegration } from '@/types/Integrations/IIntegration';
import useTemplates from '../composables/useTemplates';

const props = defineProps({
    template: {
        type: Object as PropType<ISimpleTemplate | IPremadeTemplate>,
        required: true,
    },
    onlyImage: {
        type: Boolean,
        default: false,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        default: () => [],
    },
    integrations: {
        type: Array as PropType<IIntegration<unknown>[]>,
        default: () => [],
    },
    view: {
        type: String as PropType<'grid' | 'list'>,
        default: 'grid',
    },
    marketplace: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['openPreview']);

const isHovered = ref(false);

const showTooltip = ref(false);
const drag = useDrag();
const showTooltipTemplate = ref(false);
const timeout = ref(0);
const { selectedTemplates, selectTemplate } = useTemplates();

const isSelected = computed(() => {
    const foundTemplate = selectedTemplates.value.find(
        (selectedTemplate) => selectedTemplate.id === props.template.id
    );
    return foundTemplate !== undefined;
});

const status = computed(() => {
    const data = [
        {
            title: 'Draft',
            id: 'draft',
        },
        {
            title: 'In Review',
            id: 'in_review',
        },
        {
            title: 'Sent',
            id: 'sent',
        },
    ];

    const found = data.find((f) => f.id === props.template.status);

    if (found) {
        return found.title;
    }

    return undefined;
});

const isNew = () => {
    const keywordFound = props.keywords.find(
        (keyword: IKeyword) => keyword.keyword === 'new'
    );
    return keywordFound !== undefined;
};

const startDrag = (event: DragEvent) => {
    if (event === undefined || event.dataTransfer === null) {
        return;
    }
    handleHideTemplatePreview();
    drag.startDrag(props.template.id.toString());

    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('templateId', props.template.id.toString());
};

const handleImageError = (event: Event) => {
    const target = event.target as HTMLImageElement;
    target.src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/512px-Solid_white.svg.png';
}

const openTemplate = (event: MouseEvent) => {
    if (event.shiftKey && props.template !== undefined && !props.onlyImage && !props.marketplace) {
        selectTemplate(props.template as ISimpleTemplate);
      } else {
         emit('openPreview', props.template);
      }

};

const endDrag = (event: DragEvent) => {
    if (event === undefined || event.dataTransfer === null) {
        return;
    }
    drag.endDrag();
};

const handleShowTemplatePreview = () => {
    timeout.value = setTimeout(() => {
        showTooltipTemplate.value = true;
    }, 300);
};

const handleHideTemplatePreview = () => {
    clearTimeout(timeout.value);
    showTooltipTemplate.value = false;
};
</script>

<style scoped>
.template-shadow-selected {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
