<template>
    <SubscriptionTabs tab-key="active-plugin-subscriptions" class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="subscriptions"
        >
        <template #item-user_id="{ data }">
            <div v-if="data" class="p-6">
                <a
                    :href="route('kiosk.users.activity.index', {user: data as number})"
                    target="_blank"
                    class="text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    >{{ data }}</a
                >
            </div>
        </template>
        </DataTable>
    </SubscriptionTabs>
</template>

<script lang="ts" setup>
import ISubscription from '@/types/Subscription/ISubscription';

import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import route from 'ziggy-js';

defineProps<{
    subscriptions: IPaginationData<ISubscription>;
}>();

const headers: IDataTableHeader[] = [
    { text: 'Account', id: 'user_id' },
    {
        text: 'User count (last 30 days)',
        id: 'count',
    },
];
</script>

<style></style>
