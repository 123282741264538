<template>
    <div
        class="mt-6 w-full rounded-md border border-blue-200 bg-blue-100 px-6 pt-6 pb-5 md:mt-0 md:ml-2 md:w-1/2 md:px-9 lg:ml-4 lg:w-full lg:max-w-[418px]"
    >
        <h4
            class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
        >
            Summary
        </h4>
        <div class="my-4">
            <div>
                <div
                    v-for="item in cart.shopping_cart_items"
                    :key="item.id"
                    class="flex w-full justify-between text-sm font-medium leading-normal text-gray-800"
                >
                    <div>{{ item.quantity }} x {{ item.product_name }}</div>

                    <div v-if="item.amount > 0">${{ item.amount / 100 }}</div>
                    <div v-else>Free</div>
                </div>
            </div>
        </div>

        <div class="border-t border-blue-200 pt-5">
            <div class="flex justify-between">
                <div
                    class="text-sm font-medium leading-normal tracking-tighter text-gray-800"
                >
                    Subtotal
                </div>
                <div class="text-sm font-medium text-gray-800">
                    ${{ cart.subtotal / 100 }}
                </div>
            </div>

            <div class="flex justify-between">
                <div
                    class="text-sm font-medium leading-normal tracking-tighter text-gray-800"
                >
                    Tax
                </div>
                <div class="text-sm font-medium text-gray-800">
                    ${{ (cart.total - cart.subtotal) / 100 }}
                </div>
            </div>

            <div class="flex justify-between">
                <div
                    class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
                >
                    Total
                </div>
                <div class="text-lg font-bold text-gray-800">
                    ${{ cart.total / 100 }}
                </div>
            </div>

            <div class="mt-6">
                <!-- <button
                    v-if="order"
                    class="h-15 w-full rounded-md bg-[#4299E1] px-2 text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2"
                    @click="handleGoToCheckout"
                >
                    Continue to checkout
                </button>

                <button
                    v-if="checkout"
                    class="h-15 w-full rounded-md bg-[#4299E1] px-2 text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2"
                    @click="handleBuy"
                >
                    Buy now
                </button> -->

                <button
                    v-if="order"
                    :disabled="loading"
                    class="h-15 w-full rounded-md bg-[#4299E1] px-2 text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-opacity-50"
                    @click="handleBuy"
                >
                    Buy now
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ICart from '@/types/Marketplace/ICart';
import { PropType } from 'vue';

defineProps({
    cart: {
        type: Object as PropType<ICart>,
        required: true,
    },
    checkout: {
        type: Boolean,
        required: true,
    },
    order: {
        type: Boolean,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['goToCheckout', 'buy']);

// const handleGoToCheckout = () => {
//     emit('goToCheckout');
// };

const handleBuy = () => {
    emit('buy');
};
</script>
