<template>
    <div>
        <div class="py-12">
            <div class="text-2xl font-medium text-gray-800">Edit template</div>

            <div class="flex mt-2">
                <inertia-link :href="route('kiosk.premade-templates.index')">
                    <AtomicButton size="sm" color="yellow">
                        <template #icon>
                            <BackspaceIcon class="w-6 h-6 mr-2" />
                        </template>
                        Back
                    </AtomicButton>
                </inertia-link>
            </div>
            <div
                class="p-6 mt-5 bg-white border border-gray-200 rounded-md shadow-md"
            >
                <div
                    class="flex flex-col pb-8 border-b border-gray-200 sm:flex-row"
                >
                    <div
                        class="flex flex-col sm:mr-10 lg:mr-5 lg:flex-row xl:mr-10"
                    >
                        <div class="lg:mr-5 xl:mr-10">
                            <TextInput
                                v-model="v$.form.name.$model"
                                label="Name"
                                class="w-full sm:w-80"
                                :error="getError('name')"
                                required
                                text-count
                                :text-count-max="40"
                            >
                        
                            <template #help>
                                Should be between 30 - 40 characters.
                            </template>
                        
                        </TextInput>

                        <TextInput :model-value="premadeTemplate.slug ?? ''" disabled label="Slug" class="w-full mt-6 sm:w-80"></TextInput>

                            <SelectInput
                                v-model="v$.form.type.$model"
                                :data="types"
                                placeholder="Type (FREE/PRO)"
                                label="Type"
                                class="w-full mt-6 sm:w-80"
                                required
                                :error="getError('type')"
                            ></SelectInput>

                            <TextAreaInput
                                v-model="v$.form.description.$model"
                                label="Description"
                                :cols="40"
                                :rows="8"
                                class="w-full mt-6 sm:w-80"
                                :error="getError('description')"
                                text-count
                                :text-count-max="160"
                            >
                        
                            <template #help>
                                Should be between 110 - 160 characters.
                            </template>
                        
                        </TextAreaInput>
                        </div>

                        <div>
                            <SelectInput
                                v-model="v$.form.category_id.$model"
                                :data="categoryItems"
                                placeholder="Category"
                                label="Category"
                                class="w-full sm:w-80"
                                required
                                :error="getError('category_id')"
                            ></SelectInput>

                            <TextInput
                                v-model="dateComputed"
                                label="Publish at"
                                type="datetime-local"
                                class="w-full mt-6 sm:w-80"
                            />

                            <AtomicButton
                                size="sm"
                                class="mt-2"
                                color="purple"
                                @click="handlePremadeTemplatePublish"
                            >
                                <span v-if="!premadeTemplate.publish_at"
                                    >Publish now</span
                                >
                                <span v-else>
                                    Unpublish / Cancel publishing</span
                                >
                            </AtomicButton>

                            <div class="mt-6">
                                <MultipleSelect
                                    v-model:value="selectedKeywords"
                                    label="Keywords"
                                    class="mt-1"
                                    placeholder="Select keywords"
                                    :options="keywords"
                                />

                                <div clas="flex justify-between">
                                    <TextInput
                                        v-model="keywordNew"
                                        label="Add keyword"
                                        class="mt-6"
                                        :error="getError('keywordNew')"
                                    ></TextInput>

                                    <AtomicButton
                                        class="mt-2"
                                        color="purple"
                                        size="sm"
                                        @click="createKeyword"
                                    >
                                        Create new keyword
                                    </AtomicButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full mt-10 overflow-y-auto sm:mt-0">
                        <inertia-link
                            :href="
                                route('kiosk.premade-templates.editor', {
                                    premadeTemplate: premadeTemplate.id,
                                })
                            "
                        >
                            <AtomicButton class="mt-2" color="yellow" size="sm">
                                Edit in editor
                            </AtomicButton>
                        </inertia-link>
                        <div class="mt-2 flex h-[400px] w-full border">
                            <iframe
                                :srcdoc="premadeTemplate.html"
                                class="w-full"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end mt-5">
                    <div class="flex items-center">
                        <AtomicButton
                            class="mr-2"
                            size="md"
                            color="red"
                            data-cy="delete-announcement-submit"
                            @click="deleteTemplate"
                        >
                            Delete
                            <template #icon>
                                <TrashIcon
                                    class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                                />
                            </template>
                        </AtomicButton>

                        <AtomicButton
                            size="md"
                            color="yellow"
                            :disabled="loading || v$.$invalid"
                            @click="updatePremadeTemplate"
                            >Update</AtomicButton
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import TextInput from '@atomic/Inputs/TextInput.vue';
import TextAreaInput from '@atomic/Inputs/TextAreaInput.vue';
import SelectInput from '@atomic/Inputs/SelectInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import {
    ref,
    computed,
    WritableComputedRef,
    reactive,
    onBeforeMount,
} from 'vue';
import route from 'ziggy-js';
import { BackspaceIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import MultipleSelect from '@/components/Atomic/Inputs/MultipleSelect.vue';
import { router } from '@inertiajs/vue3';
import { create } from '@composables/useNotifications';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import useConfirm from '@/composables/useConfirm';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';
import IMultiSelectable from '@/types/Atomic/IMultiSelectable';
import IInputErrors from '@/types/Atomic/IInputErrors';

interface IError {
    name?: string;
    description?: string;
    category_id?: string;
    type?: string;
    keywords?: string;
}

const props = defineProps<{
    errors: IError;
    keywords: IKeyword[];
    categories: ICategory[];
    premadeTemplate: IPremadeTemplate;
}>();

interface IForm {
    form: {
        name: string;
        description: string;
        type: string;
        category_id?: number;
        keywords: number[];
        template_id?: number;
        publish_at?: string;
    };
}

const dateComputed: WritableComputedRef<string> = computed({
    get(): string {
        return props.premadeTemplate.publish_at
            ? new Date(props.premadeTemplate.publish_at)
                  .toISOString()
                  .substring(0, 19)
            : '';
    },
    set(newValue: string): void {
        state.form.publish_at = newValue;
    },
});

const categoryItems = [] as ISelectable[];

onBeforeMount(() => {
    props.categories.forEach((a) => {
        categoryItems.push({
            title: a.title,
            value: a.id,
        });
    });

    props.keywords.forEach((a) => {
        props.premadeTemplate.keywords?.forEach((b) => {
            if (a.id === b.id) {
                selectedKeywords.value.push(a);
            }
        });
    });
});

const state: IForm = reactive({
    form: {
        name: props.premadeTemplate.name,
        description: props.premadeTemplate.description ?? '',
        type: props.premadeTemplate.type,
        category_id: props.premadeTemplate.category_id,
        keywords: [],
        publish_at: props.premadeTemplate.publish_at,
    },
});

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        description: {
            maxLength: maxLength(5000),
        },
        type: {
            required,
        },
        category_id: {
            required,
        },
    },
};

const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);

const selectedKeywords = ref<IMultiSelectable[]>([]);
const keywordNew = ref('');
const types = [
    { title: 'FREE', value: 'FREE' },
    { title: 'PRO', value: 'PRO' },
];
const { show } = useConfirm();

const updatePremadeTemplate = () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;

        selectedKeywords.value?.forEach((a: IKeyword) =>
            state.form.keywords.push(a.id)
        );

        router.put(
            route('kiosk.premade-templates.update', {
                premadeTemplate: props.premadeTemplate.id,
            }),
            state.form,
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Template updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Template not updated',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                    state.form.keywords = [];
                },
            }
        );
    }
};

const createKeyword = () => {
    router.post(
        route('kiosk.premade-templates.keywords.store'),
        {
            title: keywordNew.value,
        },
        {
            preserveScroll: true,

            onSuccess: () => {
                create({
                    title: 'Keyword created',
                    type: 'info',
                });

                keywordNew.value = '';
            },
        }
    );
};

const handlePremadeTemplatePublish = () => {
    if (!props.premadeTemplate.publish_at) {
        router.post(
            route('kiosk.premade-templates.publish', {
                premadeTemplate: props.premadeTemplate.id,
            }),
            {},
            {
                preserveScroll: true,
                preserveState: false,
            }
        );
    } else
        router.delete(
            route('kiosk.premade-templates.unpublish', {
                premadeTemplate: props.premadeTemplate.id,
            }),
            {
                preserveScroll: true,
                preserveState: false,
            }
        );
};

const deleteTemplate = async () => {
    const confirmed = await show({
        header: 'Delete premade template',
        message: `Are you sure you want to delete premade template?`,
        type: 'danger',
        confirm: 'Delete template',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.premade-templates.delete', {
                premadeTemplate: props.premadeTemplate.id,
            })
        );
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>

<style></style>
