<template>
    <LoadingSpinner
        v-if="paymentMethodsLoading"
        class="pb-10"
        size="large"
    />
    <div v-else>
        <div
            v-if="paymentMethods.length === 0"
            class="px-6 pt-5 pb-6 text-center rounded-md bg-gray-50"
        >
            <div class="mx-auto text-sm font-medium text-gray-800">
                You have no saved card yet, you can
                <span
                    class="cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                    @click="createPaymentMethod"
                >
                    add your card right now.
                </span>
            </div>
        </div>

        <div v-else>
            <div class="overflow-auto">
                <PaymentMethod
                    v-for="paymentMethod in paymentMethods"
                    :key="paymentMethod.token"
                    :payment-method="paymentMethod"
                    :is-selected="selectedPaymentMethodToken === paymentMethod.token"
                    @delete-payment-method="deletePaymentMethod"
                    @change-default-payment-method="changeDefaultPaymentMethod"
                />
            </div>
    
            <div
                class="mb-[27px] flex justify-center"
            >
                <div
                    class="mt-[19px] underline cursor-pointer text-sm text-gray-600 leading-normal hover:text-topol-blue-600"
                    @click="createPaymentMethod"
                >
                    <span>Choose another way to pay</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';

defineProps({
    paymentMethods: {
        type: Array as PropType<IPaymentMethod[]>,
        required: true,
    },
    paymentMethodsLoading: {
        type: Boolean,
        required: true,
    },
    selectedPaymentMethodToken: {
        type: String,
        default: '',
    },
});

const emit = defineEmits([
    'deletePaymentMethod', 
    'changeDefaultPaymentMethod', 
    'createPaymentMethod'
]);

const changeDefaultPaymentMethod = (selectedPaymentMethod: IPaymentMethod) => {
    emit('changeDefaultPaymentMethod', selectedPaymentMethod);
};

const deletePaymentMethod = (selectedPaymentMethod: IPaymentMethod) => {
    emit('deletePaymentMethod', selectedPaymentMethod);
};

const createPaymentMethod = () => {
    emit('createPaymentMethod');
};
</script>