<template>
    <div class="py-12" data-cy="kiosk-users-index">
        <DataTable
            data-cy="kiosk-users-table"
            title="Users"
            searchable
            invoice-input
            :headers="headers"
            :items="users"
            :filters="filters"
        >
            <template #item-profile_photo_path="{ data }">
                <div class="flex justify-center">
                    <img
                        v-if="data"
                        class="object-cover w-10 h-10 rounded-full"
                        :src="data"
                    />
                    <img
                        v-else
                        class="object-cover w-10 h-10 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png"
                    />
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex justify-center w-full">
                    <AtomicButton
                        color="white"
                        size="sm"
                        data-cy="kiosk-user-expand"
                        @click="handleShowUser(row)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="w-6 h-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/types/Atomic/IPaginationData';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import route from 'ziggy-js';
import IUser from '@/types/Members/IUser';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { IDataTableFilter } from '@/components/Atomic/DataTable/types/IDataTableFilter';
import { router } from '@inertiajs/vue3';

defineProps<{
    users: IPaginationData<IUser>;
}>();

const headers: IDataTableHeader[] = [
    { text: '', id: 'profile_photo_path' },
    {
        text: 'ID',
        id: 'id',
        sortable: true,
        sortableId: 'id',
    },
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Email',
        id: 'email',
        sortable: true,
        sortableId: 'email',
    },
    { text: '', id: 'custom' },
];

const filters: IDataTableFilter[] = [
    {
        column: 'type',
        label: 'User Type',
        values: [
            {
                label: 'Regular users',
                value: 'regular',
            },
            {
                label: 'Admins',
                value: 'admin',
            },
        ],
    },
    {
        column: 'trial',
        label: 'Trial',
        values: [
            {
                label: 'PRO Trial',
                value: 'pro_trial_ends_at',
            },
            {
                label: 'Plugin Trial',
                value: 'plugin_trial_ends_at',
            },
        ],
    },
];

const handleShowUser = function (user: IUser) {
    router.get(
        route('kiosk.users.activity.index', {
            user: user.id,
        })
    );
};
</script>

<style></style>
