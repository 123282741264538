
<template>
    <ActionSection class="pb-5 mt-5">
        <template #title>
            Browser Sessions
        </template>

        <template #description>
            Manage and log out your active sessions on other browsers and devices.
        </template>

        <template #content>
         
                <div class="text-sm text-gray-500 ">
                    If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
                </div>
    
                <!-- Other Browser Sessions -->
                <Sessions :sessions="sessions" />
        </template>
        <template #action>
            <div class="flex items-center justify-end">
                <ConfirmsPassword 
                    :skip-confirmation="true"
                    title="Log Out Other Browser Sessions"
                    button="Log Out Other Browser Sessions" 
                    content="Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices."
                    @confirmed="logoutOtherBrowserSessions"
                    >
       
                    <AtomicButton size="sm" color="yellow">
                        Log Out Other Browser Sessions
                    </AtomicButton>

                </ConfirmsPassword>
            </div>
        </template>

    </ActionSection>
</template>

<script setup lang="ts">

defineProps({
    sessions: Array,
});

const logoutOtherBrowserSessions = (password: string) => {
    router.delete(route('other-browser-sessions.destroy', {
        password: password,
    
    }), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                    title: 'Session logged out',
                    text: 'Your other browser sessions have been logged out.',
                    type: 'success',
                });
        },
        onError: () => {
            create({
                title: 'Error',
                text: 'An error occurred while logging out of your other browser sessions. Please try again.',
                type: 'error',
            });
        },
    });
};
</script>
