<template>
    <div
        class="hu-color-picker"
        :class="{ light: isLightTheme }"
        :style="{ width: totalWidth + 'px' }"
    >
        <div class="color-set">
            <Saturation
                ref="saturation"
                :color="rgbString"
                :hsv="hsv"
                :size="hueHeight"
                @selectSaturation="selectSaturation"
            />
            <Hue
                ref="hue"
                :hsv="hsv"
                :width="hueWidth"
                :height="hueHeight"
                @selectHue="selectHue"
            />
        </div>
        <div :style="{ height: previewHeight + 'px' }" class="color-show">
            <Preview
                :color="rgbaString"
                class="w-full"
                :height="previewHeight"
            />
        </div>
        <Box name="hex" :color="modelHex" @inputColor="inputHex" />
        <Colors
            :color="rgbaString"
            :colors-default="colorsDefault"
            @selectColor="selectColor"
        />

        <div class="flex justify-between gap-2 mt-5">
            <AtomicButton size="sm" color="white" @click.stop="$emit('delete')">
                Delete
            </AtomicButton>

            <AtomicButton size="sm" color="yellow" @click.stop="$emit('close')">
                Save
            </AtomicButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useColorPicker from './useColorPicker';

interface ColorValues {
    r: number;
    g: number;
    b: number;
    h: number;
    s: number;
    v: number;
}

const props = defineProps({
    color: {
        type: String,
        default: '#000000',
    },
    theme: {
        type: String,
        default: 'dark',
    },
    colorsDefault: {
        type: Array,
        default: () => [
            '#000000',
            '#FFFFFF',
            '#FF1900',
            '#F47365',
            '#FFB243',
            '#FFE623',
            '#6EFF2A',
            '#1BC7B1',
            '#00BEFF',
            '#2E81FF',
            '#5D61FF',
            '#FF89CF',
            '#FC3CAD',
            '#BF3DCE',
            '#8E00A7',
            'rgba(0,0,0,0)',
        ],
    },
});
const emit = defineEmits(['changeColor', 'delete', 'close']);

const { rgb2hex, setColorValue } = useColorPicker();

const hueWidth = 15;
const hueHeight = 152;
const previewHeight = 30;
const modelRgba = ref('');
const modelHex = ref('');

const saturation = ref<Element | null>(null);
const hue = ref<Element | null>(null);

const r = ref(0);
const g = ref(0);
const b = ref(0);
const a = ref(1);
const h = ref(0);
const s = ref(0);
const v = ref(0);

const isLightTheme = computed(() => {
    return props.theme === 'light';
});

const totalWidth = computed(() => {
    return hueHeight + (hueWidth + 8) * 2;
});

const rgba = computed(() => {
    return {
        r: r.value,
        g: g.value,
        b: b.value,
        a: a.value,
    }
});

const hsv = computed(() => {
    return {
        h: h.value,
        s: s.value,
        v: v.value,
    }
});

const rgbString = computed(() => {
    return `rgb(${r.value}, ${g.value}, ${b.value})`;
});

const rgbaStringShort = computed(() => {
    return `${r.value}, ${g.value}, ${b.value}, ${a.value}`;
});

const rgbaString = computed(() => {
    return `rgba(${rgbaStringShort.value})`;
});

const hexString = computed(() => {
    return rgb2hex(rgba.value, true);
});
  
onBeforeMount(() => {
    assignColorValues(setColorValue(props.color));

    setText();
});

watch(
    () => rgba.value,
    () => {
        emit('changeColor', {
            rgba: rgba.value,
            hsv: hsv.value,
            hex: modelHex.value,
        });
    });

const selectSaturation = (color: string) => {
    assignColorValues(setColorValue(color));
    
    setText();
};

const selectHue = (color: string) => {
    assignColorValues(setColorValue(color));

    setText();
    nextTick(() => {
        saturation.value.renderColor();
        saturation.value.renderSlide();
    });
};

const assignColorValues = ({ r: newR, g: newG, b: newB, h: newH, s: newS, v: newV }: ColorValues) => {
    r.value = newR;
    g.value = newG;
    b.value = newB;
    h.value = newH;
    s.value = newS;
    v.value = newV;
};

const inputHex = (color: string) => {
    assignColorValues(setColorValue(color));

    modelHex.value = color;
    modelRgba.value = rgbaStringShort.value;
    nextTick(() => {
        saturation.value.renderColor();
        saturation.value.renderSlide();
        hue.value.renderSlide();
    });
};

const setText = () => {
    modelHex.value = hexString.value;
    modelRgba.value = rgbaStringShort.value;
};

const selectColor = (color: string) => {
    assignColorValues(setColorValue(color));

    setText();
    nextTick(() => {
        saturation.value.renderColor();
        saturation.value.renderSlide();
        hue.value.renderSlide();
    });
};
</script>

<style scoped>
.hu-color-picker {
    padding: 10px;
    background: #f7f8f9;
    border-radius: var(--b-radius-small);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    z-index: 1;
}

.hu-color-picker.light {
    background: #f7f8f9;
}

.hu-color-picker.light .color-type .name {
    background: #e7e8e9;
}

.hu-color-picker.light .color-type .value {
    color: #666;
    background: #eceef0;
}

.hu-color-picker canvas {
    vertical-align: top;
}

.hu-color-picker .color-set {
    display: flex;
}

.hu-color-picker .color-show {
    margin-top: 8px;
    display: flex;
}
</style>
