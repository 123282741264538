import { ref } from 'vue';
import IFolder from '@/types/Templates/IFolder';
import IApiResponse from '@/types/IApiResponse';
import axios from 'axios';
import route from 'ziggy-js';
import IFolderPathItem from '@/types/Templates/IFolderPathItem';
import { create } from '@composables/useNotifications';

const folders = ref<IFolder[]>([]);
const loading = ref(false);
const currentFolder = ref<IFolder>();
const searchQuery = ref('');
const sortDirection = ref('asc');
const sortBy = ref('');

const currentPath = ref<IFolderPathItem[]>([]);

export const useFolders = () => {

    const fetchFolders = async (folderId: number|null) => {
        let url = '/template-folders?';

        if (folderId) {
            url += `template_folder_id=${folderId}&`;
        }

        if (searchQuery.value !== '') {
            url += `search=${searchQuery.value}&`;
        }

        if (sortBy.value !== '') {
            url += `sort_by=${sortBy.value}&`;
            url += `sort_by_direction=${sortDirection.value}`;
        }

        try {
            const res = await axios.get(url);
            folders.value = res.data.data.template_folders;
            currentPath.value = res.data.data.path;
        } catch (err) {
            () => undefined;
        }
    };

    const createFolder = async (folderName: string) => {
        loading.value = true;

        try {
            const foldersAxiosRes = await axios.post<IApiResponse<IFolder>>(
                route('template-folders.store'),
                {
                    name: folderName,
                    template_folder_id: currentFolder.value?.id,
                }
            );
            folders.value.push(foldersAxiosRes.data.data);
            create({
                title: 'Folder created',
                type: 'info',
            });
        } catch (err) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    };

    const search = async (searchQ: string) => {
        searchQuery.value = searchQ;
        await initializeCurrentFolder();
    };

    const renameFolder = async (folder: IFolder, newFolderName: string) => {
        loading.value = true;

        try {
            const folderAxiosRes = await axios.put<IApiResponse<IFolder>>(
                route('template-folders.update', { templateFolder: folder.id }),
                {
                    name: newFolderName,
                }
            );

            const id = folders.value.findIndex((f) => folder.uuid === f.uuid);

            folders.value.splice(id, 1, folderAxiosRes.data.data);
            create({
                title: 'Folder renamed',
                type: 'info',
            });
        } catch (err) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    };

    const deleteFolder = async (folder: IFolder) => {
        loading.value = true;

        try {
            await axios.delete(
                route('template-folders.delete', { templateFolder: folder.id })
            );

            const id = folders.value.findIndex((f) => folder.uuid === f.uuid);

            folders.value.splice(id, 1);
            create({
                title: 'Folder and its content deleted',
                type: 'info',
            });
        } catch (err) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    };

    // const breadcrumbFolders = computed((): IFolder[] => {
    //     const items: IFolder[] = [];

    //     if (currentFolder.value === undefined) {
    //         return items;
    //     }

    //     let folderIteration: IFolder = currentFolder.value;

    //     items.push(folderIteration);

    //     while (folderIteration.parent_template_folder_id !== null) {
    //         const found = folders.value.find(
    //             (f) => f.id === folderIteration.id
    //         );

    //         if (found !== undefined) {
    //             items.push(found);
    //             folderIteration = found;
    //         } else {
    //             break;
    //         }
    //     }

    //     return items;
    // });

    const setCurrentFolder = async (folder?: IFolder) => {      
        searchQuery.value = '';
        currentFolder.value = folder;
        if (folder) {
            router.visit(route('templates.index', {
                template_folder_id: folder.id
            }), { preserveState: true });
            await fetchFolders(folder.id);
        } else {
            router.visit(route('templates.index', {}), { preserveState: true });
            await fetchFolders(null);
        }
    };

    const initializeCurrentFolder = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const folderId = urlParams.get('template_folder_id');

        if (folderId) {
            try {
                const response = await axios.get(route(`template-folders.show`, { templateFolder: folderId }));
                if(response.data.success){
                    currentFolder.value = response.data.data;
                } else {
                    currentFolder.value = undefined;
                }
            } catch (err) {
                currentFolder.value = undefined;
            }
        } else {
            currentFolder.value = undefined;
        }

        await fetchFolders(currentFolder.value ? currentFolder.value.id : null);
    };

    const resetCurrentFolderWithoutFetching = async () => {
        currentFolder.value = undefined;
    };

    const setSortDirection = async (sortDir: 'asc' | 'desc') => {
        if (sortDir === 'asc' || sortDir === 'desc') {
            sortDirection.value = sortDir;
            await initializeCurrentFolder()
        } else {
            throw new TypeError(
                `Sorting direction: ${sortDir} is not supported. Supported directions: 'asc' or 'desc'.`
            );
        }
    };

    const setSortBy = async (sortByKey: string) => {
        //possible validation for sortByKey
        sortBy.value = ['created_at', 'updated_at', 'name'].includes(sortByKey)
            ? sortByKey
            : '';
        await initializeCurrentFolder()
    };

    return {
        createFolder,
        deleteFolder,
        renameFolder,
        loading,
        folders,
        currentFolder,
        setCurrentFolder,
        initializeCurrentFolder,
        resetCurrentFolderWithoutFetching,
        fetchFolders,
        currentPath,
        searchQuery,
        search,
        setSortDirection,
        setSortBy,
    };
};

export default useFolders;
