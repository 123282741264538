import { ISavedBlock } from '@topol.io/editor-vue';
import useConfirm from '@/composables/useConfirm';
import route from 'ziggy-js';
import axios from 'axios';
import { ref } from 'vue';
import IApiResponse from '@customTypes/IApiResponse';
import useEditorTranslations from './useEditorTranslations';

export default (
    initialSavedBlocks: ISavedBlock[],
    saveSavedBlockUrl: string,
    language: string
) => {
    const { translations: editorTranslations, getTranslations } = useEditorTranslations(language);

    // @ts-ignore
    const translations = ref(editorTranslations[language]);

    //store saved block locally for future mutations
    const savedBlocks = ref<ISavedBlock[]>(initialSavedBlocks);

    const { show } = useConfirm();

    const getSavedBlockNames = (): string[] => {
        const savedBlockNames: string[] = [];

        savedBlocks.value.map((savedBlock) => {
            savedBlockNames.push(savedBlock.name);
        });

        return savedBlockNames;
    };

    const createSavedBlock = async (newSavedBlockJson: ISavedBlock) => {
        const savedBlockNames = getSavedBlockNames();

        const name = await show({
            type: 'info',
            hasInput: true,
            header: translations.value.savedBlockCreateTitle,
            inputData: {
                label: translations.value.savedBlockCreateLabel,
                placeholder: translations.value.savedBlockCreatePlaceholder,
                validator: (text) => {
                    return !savedBlockNames.includes(text);
                },
                validationErrorMessage: translations.value.savedBlockExistsText,
            },
            confirm: translations.value.savedBlockCreateTitle,
            cancel: translations.value.cancel,
        });

        if (name) {
            try {
                await axios.post<IApiResponse<unknown>>(saveSavedBlockUrl, {
                    name: name,
                    definition: newSavedBlockJson.definition,
                });

                return await reloadSavedBlocks();
            } catch (err) {
                throw new Error(translations.value.savedBlockCreateFailed);
            }
        }
    };

    const editSavedBlock = async (templateSavedBlockId: number) => {
        const savedBlock = savedBlocks.value.find(
            (savedBlock) => savedBlock.id === templateSavedBlockId
        );

        if (savedBlock === undefined) {
            return;
        }

        const savedBlockNames = getSavedBlockNames();

        const newName = await show({
            type: 'info',
            hasInput: true,
            header: translations.value.rename,
            inputData: {
                label: translations.value.savedBlockCreateLabel,
                placeholder: translations.value.savedBlockCreatePlaceholder,
                validator: (text) => {
                    return !savedBlockNames.includes(text);
                },
                validationErrorMessage: translations.value.savedBlockExistsText,
                defaultValue: savedBlock.name,
            },
            confirm: translations.value.rename,
            cancel: translations.value.cancel,
        });

        if (newName) {
            try {
                await axios.put<IApiResponse<unknown>>(
                    route('template-saved-blocks.update', {
                        templateSavedBlock: savedBlock.id,
                    }),
                    {
                        name: newName,
                    }
                );

                return await reloadSavedBlocks();
            } catch (err) {
                throw new Error(translations.value.savedBlockEditFailed);
            }
        }
    };

    const deleteSavedBlock = async (savedBlockId: number) => {
        const savedBlockToBeDeleted = savedBlocks.value.find(
            (bl: ISavedBlock) => bl.id === savedBlockId
        );

        if (savedBlockToBeDeleted !== undefined) {
            const confirmed = await show({
                message: getTranslations('savedBlockDeleteText', savedBlockToBeDeleted.name),
                type: 'danger',
                header: translations.value.delete,
                confirm: translations.value.delete,
                cancel: translations.value.cancel,
            });

            if (confirmed) {
                try {
                    await axios.delete(
                        route('template-saved-blocks.delete', {
                            templateSavedBlock: savedBlockToBeDeleted.id,
                        }),
                        {}
                    );

                    return await reloadSavedBlocks();
                } catch (err) {
                    () => undefined;
                }
            }
        }
    };

    const prepareSavedBlocks = (savedBlocks: ISavedBlock[]): ISavedBlock[] => {
        return savedBlocks.map((savedBlock) => {
            return {
                id: savedBlock.id,
                name: savedBlock.name,
                definition: [JSON.parse(savedBlock.definition as string)],
            };
        });
    };

    const reloadSavedBlocks = async () => {
        try {
            const res = await axios.get<IApiResponse<ISavedBlock[]>>(
                route('template-saved-blocks.index')
            );

            const blocks = prepareSavedBlocks(res.data.data);
            savedBlocks.value = blocks;
            return blocks;
        } catch (err) {
            throw new Error(translations.value.savedBlockRefetchFailed);
        }
    };

    return {
        createSavedBlock,
        deleteSavedBlock,
        editSavedBlock,
        prepareSavedBlocks,
        savedBlocks,
    };
};
