<template>
    <div>
        <Modal v-if="pluginSubscription" size="xl" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    <span v-if="selected !== 8"> Change subscription </span>
                    <span v-else class="text-topol-alert-100">
                        Cancel subscription
                    </span>
                </div>
            </template>

            <template #body>
                <div class="px-6 pt-3 pb-6">
                    <div
                        class="flex flex-col rounded px-[14px] pt-3 pb-2.5 sm:flex-row"
                        :class="{
                            'bg-blue-100': selected !== 8,
                            'bg-red-100': selected === 8,
                        }"
                    >
                        <div
                            :class="{
                                'text-blue-600': selected !== 8,
                                'text-topol-alert-100': selected === 8,
                            }"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10V10ZM11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6ZM9 9C8.73478 9 8.48043 9.10536 8.29289 9.29289C8.10536 9.48043 8 9.73478 8 10C8 10.2652 8.10536 10.5196 8.29289 10.7071C8.48043 10.8946 8.73478 11 9 11V14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14C12 13.7348 11.8946 13.4804 11.7071 13.2929C11.5196 13.1054 11.2652 13 11 13V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9H9Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div
                            class="mt-2 text-sm sm:mt-0 sm:ml-[14px]"
                            :class="{
                                'text-blue-600': selected !== 8,
                                'text-red-600': selected === 8,
                            }"
                        >
                            You are currently using
                            <span
                                class="font-medium"
                                :class="{
                                    'text-blue-700': selected !== 8,
                                    'text-red-700': selected === 8,
                                }"
                                >{{ pluginSubscription.plan?.name }}</span
                            >
                            <span v-if="pluginSubscription.plan?.id === 4">
                                for up to 50 users.
                            </span>
                            <span v-else-if="pluginSubscription.plan?.id === 5">
                                for up to 1000 users.
                            </span>
                            <span v-else> for unlimited users. </span>
                            <span
                                v-if="
                                    !pluginSubscription.canceled_at &&
                                    pluginSubscription.next_billing_date
                                "
                            >
                                Your plan will renew on
                                {{
                                    format(
                                        new Date(
                                            pluginSubscription.next_billing_date
                                        ),
                                        'd MMMM, yyyy'
                                    )
                                }}.
                            </span>
                        </div>
                    </div>

                    <div
                        class="px-6 pt-4 pb-6 mt-5 border border-gray-200 rounded-md bg-gray-50"
                    >
                        <SelectInput
                            ref="selectInput"
                            v-model="selected"
                            :data="pluginPlans"
                            label="Choose your subscription"
                            placeholder="Choose subscription"
                            class="max-w-[400px]"
                            @update:modelValue="getPreparedSubscription"
                        />

                        <div
                            v-if="selected === 6 || selected === 7"
                            class="mt-4"
                        >
                            <RadioInput
                                v-model="selected"
                                :option="6"
                                name="period"
                                class="mb-3"
                                label="Month-to-month payment"
                                size="lg"
                                @update:model-value="getPreparedSubscription"
                            />

                            <div class="relative">
                                <RadioInput
                                    v-model="selected"
                                    :option="7"
                                    name="period"
                                    label="Annual payment"
                                    size="lg"
                                    @update:model-value="
                                        getPreparedSubscription
                                    "
                                />
                                <div
                                    class="absolute left-40 top-0 hidden rounded-md bg-orange-500 px-2 py-[3px] text-center text-xs font-medium leading-normal text-gray-900 sm:block"
                                >
                                    <span>SAVE 17% </span>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="pluginSubscription.plan?.id === selected"
                            class="mt-4"
                        >
                            <div
                                class="text-2xl leading-normal tracking-tighter text-gray-800"
                            >
                                <span class="font-bold">
                                    ${{
                                        (
                                            pluginSubscription.amount_with_tax /
                                            100
                                        ).toFixed(2)
                                    }}
                                </span>

                                <span
                                    >/{{
                                        pluginSubscription.period === 1
                                            ? 'month'
                                            : 'year'
                                    }}
                                </span>
                            </div>

                            <div class="max-w-[419px] text-sm text-gray-500">
                                <span v-if="selected === 4">
                                    Perfect fit for projects with growing user
                                    count. 50 users included in plan, additional
                                    user for $1.20/month. Paid monthly.
                                </span>
                                <span v-else-if="selected === 5">
                                    Solution for projects with an established
                                    user base. 1000 users included in plan,
                                    additional user for $0.30/month. Paid
                                    monthly.
                                </span>
                                <span v-else>
                                    No limits?. Fixed price, no hidden fees and
                                    unexpected costs. Unlimited users included.
                                    Paid monthly or yearly (save $1200/year +
                                    tax).
                                </span>
                            </div>

                            <div
                                v-if="
                                    selected === pluginSubscription.plan?.id &&
                                    !pluginSubscription.canceled_at
                                "
                                class="mt-[18px] text-sm"
                            >
                                <div class="text-gray-500">
                                    {{
                                        pluginSubscription.period === 1
                                            ? 'Month-to-month'
                                            : 'Annual'
                                    }}
                                    plan renews on
                                </div>

                                <div
                                    v-if="pluginSubscription.next_billing_date"
                                    class="font-medium text-gray-800"
                                >
                                    {{
                                        format(
                                            new Date(
                                                pluginSubscription.next_billing_date
                                            ),
                                            'd MMMM, yyyy'
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="pluginSubscription.canceled_at"
                                class="mt-[18px] text-sm text-gray-500"
                            >
                                <div>
                                    Subscription canceled, will be active until
                                </div>
                                <div class="font-medium text-gray-800">
                                    {{
                                        format(
                                            new Date(
                                                pluginSubscription.canceled_at
                                            ),
                                            'd MMMM, yyyy'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>

                        <div v-if="pluginSubscription.plan?.id !== selected">
                            <div v-if="selected !== 8 && preparedSubscription">
                                <div
                                    class="mt-4 text-2xl leading-normal tracking-tighter text-gray-800"
                                >
                                    <span class="font-bold">
                                        ${{
                                            (
                                                preparedSubscription.total / 100
                                            ).toFixed(2)
                                        }}
                                    </span>

                                    <span
                                        >/{{
                                            preparedSubscription
                                                .subscription_plan.period === 1
                                                ? 'month'
                                                : 'year'
                                        }}
                                    </span>
                                </div>

                                <div
                                    class="max-w-[419px] text-sm text-gray-500"
                                >
                                    <span v-if="selected === 4">
                                        Perfect fit for projects with growing
                                        user count. 50 users included in plan,
                                        additional user for $1.20/month. Paid
                                        monthly.
                                    </span>
                                    <span v-else-if="selected === 5">
                                        Solution for projects with an
                                        established user base. 1000 users
                                        included in plan, additional user for
                                        $0.30/month. Paid monthly.
                                    </span>
                                    <span v-else>
                                        No limits. Fixed price, no hidden fees
                                        and unexpected costs. Unlimited users
                                        included. Paid monthly or yearly (save
                                        $1200/year + tax).
                                    </span>
                                </div>

                                <div
                                    v-if="
                                        selected === 6 &&
                                        pluginSubscription.plan?.id === 7
                                    "
                                    class="mt-5 text-sm text-gray-500"
                                >
                                    Your current yearly subscription will
                                    continue until
                                    <span
                                        v-if="
                                            pluginSubscription.next_billing_date
                                        "
                                        class="font-medium text-gray-800"
                                    >
                                        {{
                                            format(
                                                new Date(
                                                    pluginSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}
                                    </span>
                                    and then will be switched to month-to-month
                                    payment. You will not be billed until then.
                                </div>

                                <div v-else class="mt-5 text-sm text-gray-500">
                                    <div>
                                        {{
                                            preparedSubscription
                                                .subscription_plan.period === 1
                                                ? 'Month-to-month'
                                                : 'Annual'
                                        }}
                                        billing starts on
                                    </div>
                                    <div
                                        v-if="
                                            pluginSubscription.next_billing_date
                                        "
                                        class="font-medium text-gray-800"
                                    >
                                        {{
                                            format(
                                                new Date(
                                                    pluginSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="selected === 8">
                                <div class="mt-4 text-sm text-gray-500">
                                    When cancelling your subscription,
                                    <span
                                        v-if="
                                            pluginSubscription.next_billing_date
                                        "
                                        class="font-medium text-gray-800"
                                        >your current subscription will continue
                                        until
                                        {{
                                            format(
                                                new Date(
                                                    pluginSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}.</span
                                    >
                                    After this date your subscription will be
                                    cancelled and you will be no longer able to
                                    use TOPOL Plugin. If you decide to continue
                                    with subscription, you can always subscribe
                                    again later.

                                    <p class="mt-2">Note that after your subscription is canceled, you will lose access to the images saved in our storage.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5">
                        <CheckboxInput
                            v-model:checked="confirmation"
                            size="md"
                            :disabled="
                                pluginSubscription.plan?.id === selected &&
                                !pluginSubscription.canceled_at
                            "
                            name="confirm"
                            :label="
                                selected !== 8
                                    ? 'Confirm that you want to change your subscription'
                                    : 'Confirm that you want to CANCEL your subscription'
                            "
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between p-6 border-t border-gray-200">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>

                    <AtomicButton
                        :disabled="
                            (pluginSubscription.plan?.id === selected &&
                                !pluginSubscription.canceled_at) ||
                            !confirmation ||
                            loading ||
                            (!preparedSubscription && selected !== 8)
                        "
                        size="md"
                        :color="selected === 8 ? 'red' : 'yellow'"
                        @click="handleChange"
                    >
                        <span v-if="selected !== 8">Change subscription</span>
                        <span v-else>Cancel subscription</span>
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
import ISubscription from '@/types/Subscription/ISubscription';
import { format } from 'date-fns';
import { ref } from 'vue';
import route from 'ziggy-js';
import axios from 'axios';
import IApiResponse from '@/types/IApiResponse';
import PreparedSubscription from '@/types/Subscription/PreparedSubscription';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';

const emit = defineEmits(['close', 'cancel']);

const props = defineProps<{
    pluginSubscription: ISubscription | null;
    selectedCard?: IPaymentMethod;
}>();

const confirmation = ref(false);

const pluginPlans = [
    {
        title: 'Topol Plugin for Startups',
        value: 4,
    },
    {
        title: 'Topol Plugin for Business',
        value: 5,
    },
    {
        title: 'Topol Plugin Unlimited (monthly)',
        value: 6,
    },
    {
        title: 'Topol Plugin Unlimited (yearly)',
        value: 7,
    },
    {
        title: 'Cancel Topol Plugin subscription',
        value: 8,
    },
];

const selected = ref(props.pluginSubscription?.plan?.id);
const selectInput = ref();
const preparedSubscription = ref();
const loading = ref(false);

const handleChange = () => {
    if (
        selected.value !== 8 &&
        selected.value !== props.pluginSubscription?.plan?.id
    ) {
        changeSubscription();
    }
    if (selected.value === 8) {
        cancelSubscription();
    }
    if (
        selected.value === props.pluginSubscription?.plan?.id &&
        props.pluginSubscription?.canceled_at
    ) {
        resumeSubscription();
    }
};

const changeSubscription = () => {
    if (!props.selectedCard === null || props.pluginSubscription === null) {
        return;
    }
    loading.value = true;
    router.post(
        route('subscriptions.switch', {
            subscription: props.pluginSubscription.id,
        }),
        {
            subscription_plan_id: selected.value ?? null,
        },
        {
            preserveState: false,
            onSuccess: () => {
                create({
                    title: 'Subscription switched',
                    text: 'Your subscription has been successfully updated.',
                    type: 'success',
                });
                emit('close');
            },
            onError: () => {
                create({
                    title: 'Subscription not switched',
                    text: 'There was an error updating your subscription.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const cancelSubscription = () => {
    if (!props.pluginSubscription) {
        return;
    }

    loading.value = true;
    router.post(
        route('subscriptions.cancel', {
            subscription: props.pluginSubscription.id,
        }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Subscription canceled',
                    text: 'Your PRO subscription has been successfully canceled.',
                    type: 'success',
                });
                emit('cancel');
            },
            onError: () => {
                create({
                    title: 'Subscription not canceled',
                    text: 'There was an error canceling your PRO subscription.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const resumeSubscription = () => {
    if (!props.pluginSubscription) {
        return;
    }
    loading.value = true;
    router.post(
        route('subscriptions.resume', {
            subscription: props.pluginSubscription.id,
        }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Subscription extended',
                    text: 'Your PRO subscription has been successfully extended.',
                    type: 'success',
                });
                emit('close');
            },
            onError: () => {
                create({
                    title: 'Subscription not extended',
                    text: 'There was an error extending your PRO subscription.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const getPreparedSubscription = async () => {
    if (
        selected.value !== 8 &&
        selected.value !== props.pluginSubscription?.plan?.id
    ) {
        const res = await axios.get<IApiResponse<PreparedSubscription>>(
            route('subscriptions.prepare', {
                subscription_plan_id: selected.value ?? null,
            })
        );
        preparedSubscription.value = res.data.data;
    }
};
</script>