const l = [
    {
        title: 'English',
        value: 'en',
    },
    {
        title: 'French',
        value: 'fr',
    },
    {
        title: 'Portuguese',
        value: 'pt',
    },
    {
        title: 'Spanish',
        value: 'es',
    },
    {
        title: 'Japanese',
        value: 'ja',
    },
    {
        title: 'Chinese',
        value: 'zh',
    },
    {
        title: 'Russian',
        value: 'ru',
    },
    {
        title: 'Turkish',
        value: 'tr',
    },
    {
        title: 'German',
        value: 'de',
    },
    {
        title: 'Swedish',
        value: 'sv',
    },
    {
        title: 'Dutch',
        value: 'nl',
    },
    {
        title: 'Italian',
        value: 'it',
    },
    {
        title: 'Romanian',
        value: 'ro',
    },
    {
        title: 'Czech',
        value: 'cs',
    },
    {
        title: 'Polish',
        value: 'pl',
    },
    {
        title: 'Korean',
        value: 'ko',
    },
    {
        title: 'Vietnamese',
        value: 'vi',
    },
    {
        title: 'Hebrew',
        value: 'he',
    },
];

export default l;
