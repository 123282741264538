const defaultFonts = [
    {
        label: 'Arial',
        style: 'Arial, sans-serif',
    },
    {
        label: 'Bitter',
        style: 'Bitter, Georgia, serif',
        url: 'https://fonts.googleapis.com/css?family=Bitter:400,700',
    },
    {
        label: 'Cabin',
        style: 'Cabin, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Cabin:400,700',
    },
    {
        label: 'Georgia',
        style: 'Georgia, sans-serif',
    },
    {
        label: 'Helvetica',
        style: 'Helvetica, sans-serif',
    },
    {
        label: 'Lato',
        style: 'Lato, Tahoma, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Lato:400,700',
    },
    {
        label: 'Merriweather',
        style: 'Merriweather, Georgia, serif',
        url: 'https://fonts.googleapis.com/css?family=Merriweather:400,700',
    },
    {
        label: 'Open Sans',
        style: 'Open Sans, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700',
    },
    {
        label: 'PT Sans',
        style: 'PT Sans, Trebuchet MS, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=PT+Sans:400,700',
    },
    {
        label: 'PT Serif',
        style: 'PT Serif, Georgia, serif',
        url: 'https://fonts.googleapis.com/css?family=PT+Serif:400,700',
    },
    {
        label: 'Roboto',
        style: 'Roboto, Tahoma, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Roboto:400,700',
    },
    {
        label: 'Ubuntu',
        style: 'Ubuntu, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Ubuntu:400,700',
    },
    {
        label: 'Verdana',
        style: 'Verdana, sans-serif',
    },
    {
        label: 'Poppins',
        style: 'Poppins, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Poppins:400,700',
    },
    {
        label: 'Oswald',
        style: 'Oswald, sans-serif',
        url: 'https://fonts.googleapis.com/css?family=Oswald:400,700',
    },
];

export default () => {
    const loadExternalStylesheet = (url: string) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);
    };

    return {
        loadExternalStylesheet,
        defaultFonts,
    };
};
