<template>
    <AuthLayout>
        <div
            class="w-full p-6 mt-8 overflow-hidden bg-white shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mt-[2px] mb-8 border-b border-gray-200 pb-5">
                <h1 class="text-xl font-bold text-center text-gray-800">
                    Sessions Limit Reached
                </h1>
            </div>

            <form @submit.prevent="submit">
                <div
                    class="flex flex-col items-center w-full gap-2 text-center border-gray-300"
                >
                    Your account has reached its session limit. To continue using TOPOL.io, please log out of other devices via the email we will send you.
                </div>

                <div class="flex flex-col items-center mt-10">
                    <AtomicButton
                        type="submit"
                        size="lg"
                        color="yellow"
                        :disabled="loading"
                    >
                        Send email with instructions
                    </AtomicButton>
                </div>

                <div class="mt-4 text-sm font-medium text-center">
                    <span
                        v-if="sentSuccess && !error"
                        class="text-green-600"
                    >
    
                        Email sent successfully!
                    </span>

                    <span v-if="error" class="text-topol-red-600">
                        {{ error }}
    
                    </span>
                </div>
            </form>
        </div>

        <div class="text-sm text-center text-gray-500 mt-11">
            Changed your mind?

            <inertia-link :href="route('login')">
                <span
                    class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    >Sign in with different account!</span
                >
            </inertia-link>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';

const loading = ref(false);
const sentSuccess = ref(false);
const error = ref('');

const submit = async () => {
    try {
        loading.value = true;
        const result = await axios.post(route('app.auth.session-limit-reached.store'));

        if(result.status === 204){
            error.value = '';
            sentSuccess.value = true;
        } 
    } catch (e) {
        if(e.response.status === 429){
            error.value = 'Too many requests. Please try again later.';
        } else {
            error.value = 'An error occurred. Please try again later.';
        }
    } finally {
        loading.value = false;
    }

};

</script>
