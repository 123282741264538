<template>
     <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Metrics">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import {
    PuzzlePieceIcon,
    RectangleStackIcon,
    SwatchIcon,
    CloudIcon,
    UserPlusIcon,
} from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';

defineProps({
    tabKey: {
        type: String,
        default: 'unused-plugins',
    },
});

let tabs: TabItem[] = [
    {
        title: 'Api Tokens',
        key: 'api-tokens',
        icon: PuzzlePieceIcon,
        url: route('kiosk.metrics.api-tokens.index'),
    },
    {
        title: 'Active subs',
        key: 'active-subscriptions',
        icon: SwatchIcon,
        url: route('kiosk.metrics.active-subscriptions.index'),
    },
    {
        title: 'New subs',
        key: 'new-subscriptions',
        icon: SwatchIcon,
        url: route('kiosk.metrics.new-subscriptions.index'),
    },
    {
        title: 'Integrations',
        key: 'integrations',
        icon: RectangleStackIcon,
        url: route('kiosk.metrics.integrations.index'),
    },
    {
        title: 'Unused api tokens',
        key: 'unused-api-tokens',
        icon: PuzzlePieceIcon,
        url: route('kiosk.metrics.unused-api-token.index'),
    },
    {
        title: 'Storage Usage',
        key: 'storage-usage',
        icon: CloudIcon,
        url: route('kiosk.metrics.storage-usage.index'),
    },
    {
        title: 'Socialite',
        key: 'socialite',
        icon: UserPlusIcon,
        url: route('kiosk.metrics.socialite.index'),
    },
];
</script>
