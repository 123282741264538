<template>
    <svg
        id="l"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1029 124.2"
        style="enable-background: new 0 0 1029 124.2"
        xml:space="preserve"
    >
        <g>
            <path
                d="M1.2,26.4l21.2-4l4-21.2c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2l4,21.2l21.2,4c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.3-1.1,1.3
		l-21.2,4l-4,21.2c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2l-4-21.2L1.2,28.8c-0.7,0-1.2-0.5-1.2-1.2C0,26.9,0.5,26.4,1.2,26.4
		L1.2,26.4z"
            ></path>
            <path
                d="M180.5,109.7v-1.5v-3v-12V69.3c0-8,0-15.9-0.1-23.9l-0.1-6l-0.1-3v-0.8l-0.1-1.2c-0.1-0.8-0.2-1.5-0.5-2.3
		c-0.9-3-2.9-5.6-5.5-7.2c-1.3-0.8-2.8-1.4-4.3-1.7c-0.8-0.1-1.5-0.2-2.3-0.2H167h-0.4h-0.7l-47.8-0.2l-23.9-0.1l-23.9-0.2h-0.1
		c-2.9,0-5.2,2.4-5.1,5.2c0,2.9,2.4,5.2,5.2,5.1l23.9-0.3l23.9-0.1l43.9-0.3l-52.5,42.6l-3.7,3l-1.9,1.5l-0.2,0.2
		c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.6,0.3-1.3,0.4-1.9,0.3c-0.7-0.1-1.2-0.2-1.9-0.7
		L84.5,67.3L55,43.1c0,0,0,0-0.1,0c-2.3-1.8-5.6-1.4-7.4,0.8c-1.8,2.3-1.4,5.6,0.8,7.4l29.8,23.9L93,87l0.7,0.5
		c0.2,0.2,0.5,0.4,0.8,0.5c0.5,0.4,1.1,0.6,1.7,0.9c1.1,0.5,2.4,0.9,3.6,1c2.5,0.3,5.1-0.1,7.4-1.2c0.6-0.3,1.1-0.6,1.7-0.9
		c0.5-0.3,1.1-0.8,1.3-1l1.9-1.5l3.7-3l55-44.7v1.7l-0.1,6c-0.1,8-0.1,15.9-0.1,23.9v23.9v12v3v1.5c0,0.5,0,1.1,0,1.2
		c0,0.8-0.4,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9h-0.1h-0.1h-0.2h-0.7H165h-3h-12h-23.9l-47.8-0.1H54.4h-12h-6H36h-0.1
		c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.5-0.1c-0.3-0.1-0.7-0.3-0.9-0.5c-0.6-0.5-1-1.1-1.1-1.8c0-0.2,0-0.3-0.1-0.6v-1.5v-3v-12
		l-0.1-23.9c0-2.8-2.3-5.1-5.2-5.2c-2.9,0-5.2,2.3-5.2,5.2l-0.1,23.9v12v3v1.5c0,0.7,0.1,1.6,0.2,2.4c0.5,3.2,2.3,6.2,4.8,8.2
		c1.3,1,2.7,1.8,4.3,2.3c0.8,0.2,1.6,0.4,2.4,0.5c0.4,0.1,0.8,0.1,1.2,0.1h0.6h0.4h6h12h23.9l47.8-0.1h23.9h12h3h1.5h0.7h0.2h0.3
		h0.6c3.2-0.2,6.3-1.6,8.5-3.9c2.3-2.3,3.6-5.4,3.8-8.6C180.5,110.5,180.5,110.2,180.5,109.7z"
            ></path>
            <g>
                <g>
                    <path
                        d="M300.5,99h-36.9l-7.9,22.6h-17.2l34-92.7h19.7l34,92.7h-17.8L300.5,99z M268.5,85.4h27.1l-13.5-38.1h-0.3L268.5,85.4z"
                    ></path>
                    <path
                        d="M366.8,50.1c15.8,0,26.2,9,27.6,22.5l-15,3.2c-0.6-7.1-4.7-12.8-12.5-12.8c-10.6,0-15.8,9.6-15.8,23.7
				c0,14.3,5.3,23.6,16,23.6c7.2,0,11.8-5.3,12.5-12.9l15.1,4c-2.4,13.6-12.8,21.8-27.6,21.8c-20.1,0-33-13.7-33-36.5
				S347,50.1,366.8,50.1z"
                    ></path>
                    <path
                        d="M452.3,108.6c-3.2,9.9-11.1,14.7-21.8,14.7c-11.5,0-21.7-7.2-21.7-22.5v-49h17.1V97c0,8,4,12.8,11.2,12.8
				c7.9,0,14.3-5.6,14.3-18.5V51.8h17.2v69.8h-14.3L452.3,108.6z"
                    ></path>
                    <path
                        d="M846.8,50.1c12.5,0,21.5,7.5,21.5,22.1v49.4h-17.1V77.3c0-7.8-3.1-12.8-11.2-12.8c-7.2,0-13.6,5-13.6,17.8v39.3h-17.2
				V77.3c0-7.8-3-12.8-11.1-12.8c-7.4,0-13.7,5.1-13.7,17.9v39.1h-17.1V51.8h14l2.1,13.5c3.2-9.7,11.5-15.1,21.4-15.1
				s17.6,5.3,20.3,14.7C828.4,55.8,837.1,50,846.8,50.1z"
                    ></path>
                    <path
                        d="M568.8,50.1c12.5,0,21.5,7.5,21.5,22.1v49.4h-17V77.3c0-7.8-3.1-12.8-11.2-12.8c-7.2,0-13.6,5-13.6,17.8v39.3h-17.2V77.3
				c0-7.8-3.1-12.8-11.1-12.8c-7.4,0-13.7,5.1-13.7,17.9v39.1h-17.1V51.8h14l2.1,13.5c3.2-9.7,11.5-15.1,21.4-15.1
				s17.6,5.3,20.3,14.7C550.4,55.8,559.1,50,568.8,50.1z"
                    ></path>
                    <path
                        d="M627.2,63.6c2.6-8.2,11-13.5,20.5-13.5c18.7,0,28.7,15.3,28.7,36.1c0,21.9-11.7,37-29.8,37c-10,0-18.2-5.3-20.7-14.1
				l-1.9,12.5h-13.9V21.4h17.1L627.2,63.6L627.2,63.6z M627.2,91.6c0,13.3,7.5,18.7,15,18.7c10,0,16.8-8,16.8-23.9
				c0-15.6-6.2-23-16.5-23c-7.8,0-15.3,6.1-15.3,19.4V91.6z"
                    ></path>
                    <path
                        d="M940.5,104.7c0,4,1.9,5.4,4.9,5.4c1.5,0,2.9-0.5,4-1.4l0.7,11.7c-3,1.8-6.5,2.7-10,2.6c-5.8,0-10.3-2.2-12.9-6.3
				c-0.9-1.6-1.6-3.4-2.1-5.1C922.4,119,914,123,905,123c-12.8,0-21.9-7.2-21.9-19.9c0-13.9,11.7-22.2,33.4-22.2
				c2.6,0,5.1,0.2,7.6,0.4v-7.7c0-6.7-3.6-10.7-10.8-10.7s-10.8,4.2-11,11l-16.1-2.5c1.4-13.5,12.3-21.2,27.9-21.2
				c17.3,0,26.4,7.8,26.4,22.5L940.5,104.7z M924.1,91.2c-1.5-0.1-3.6-0.4-5.7-0.4c-12.2,0-18.7,3.6-18.7,11.2
				c0,5.6,3.9,9.3,10.5,9.3c6.9,0,13.9-4.3,13.9-15.3V91.2z"
                    ></path>
                    <path
                        d="M744.8,104.7c0,4,1.9,5.4,4.9,5.4c1.5,0,2.9-0.5,4-1.4l0.7,11.7c-3,1.8-6.5,2.7-10,2.6c-5.8,0-10.3-2.2-12.9-6.3
				c-0.9-1.6-1.6-3.3-2.1-5.1c-2.7,7.4-11.1,11.4-20.1,11.4c-12.8,0-21.9-7.2-21.9-19.9c0-13.9,11.7-22.2,33.4-22.2
				c2.6,0,5.1,0.2,7.6,0.4v-7.7c0-6.7-3.6-10.7-10.8-10.7s-10.8,4.2-11,11l-16.1-2.5c1.4-13.5,12.4-21.3,27.9-21.3
				c17.3,0,26.4,7.8,26.4,22.5L744.8,104.7L744.8,104.7z M728.4,91.2c-1.5-0.1-3.6-0.4-5.7-0.4c-12.2,0-18.7,3.6-18.7,11.2
				c0,5.6,3.9,9.3,10.5,9.3c6.9,0,13.9-4.3,13.9-15.3V91.2z"
                    ></path>
                    <rect x="963" y="51.8" width="17" height="69.8"></rect>
                    <path
                        d="M955.9,30.7l12.5-2.4l2.4-12.5c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7l0,0l2.4,12.5l12.5,2.4c0.4,0.1,0.7,0.4,0.6,0.8
				c-0.1,0.3-0.3,0.5-0.6,0.6l-12.5,2.4L972.2,47c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7l-2.4-12.5l-12.5-2.4
				c-0.4-0.1-0.7-0.4-0.6-0.8C955.3,31,955.6,30.7,955.9,30.7z"
                    ></path>
                    <path
                        d="M1029,120c-3.9,2.1-8.3,3.1-12.8,3.1c-9.3,0-16.9-4.4-16.9-16.7v-85h17.1v81.7c0,4.3,1.9,6.2,5.7,6.2c2,0,4-0.6,5.7-1.8
				L1029,120z"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>
