<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">
                <span>
                    {{ email ? 'Edit' : 'Add' }}
                </span>
                
                email</div>
        </template>

        <template #body>
            <div class="p-6">
                <div class="flex flex-col">
                    <TextInput
                        v-model="state.email"
                        placeholder="Enter email"
                        label="Email"
                        :error="getError('email')"
                        />

                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between p-6 border-t border-gray-200">
                <AtomicButton
                    color="white"
                    size="md"
                    @click="$emit('close')"
                >
                    Close
                </AtomicButton>

                <AtomicButton
                    color="yellow"
                    size="md"
                    :disabled="state.email === ''"
                    @click="submit"
                >
                Submit
                </AtomicButton>
            </div>
        </template>
        
    </Modal>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { email as emailValidator, required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';

const props = defineProps({
    email: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['close', 'submit']);
const errors = ref<IInputErrors>({});

const state = reactive({
    email: props.email,
});

const rules = {
      email: {
        required,
        emailValidator
      }
    };

const v$ = useVuelidate(rules, state);

const submit = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {

        emit('submit', state.email);
    }
};

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};


</script>
