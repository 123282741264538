<template>
    <div class="py-12" data-cy="kiosk-announcements-index">
        <h1
            class="text-2xl font-medium leading-normal text-gray-800 tracking-small"
        >
            Announcements
        </h1>
        <div class="mt-4">
            <inertia-link :href="route('kiosk.announcements.create')">
                <AtomicButton size="sm" color="yellow">
                    Create
                    <template #icon>
                        <PlusCircleIcon
                            class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </inertia-link>
        </div>

        <DataTable
            class="mt-10"
            :headers="headers"
            :items="announcements"
            searchable
        >
            <template #item-id="{ data }">
                <div class="flex max-w-[100px] truncate p-6 text-gray-500">
                    <div>
                        {{ data }}
                    </div>
                </div>
            </template>

            <template #item-user="{ data }">
                <div class="flex p-6 text-gray-500">
                    <div>
                        {{ data.email }}
                    </div>
                </div>
            </template>

            <template #item-published_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>

                    <XCircleIcon v-else class="w-6 h-6 text-gray-600" />
                </div>
            </template>

            <template #item-custom_show="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.announcements.show', {
                            announcement: row.id as number,
                        })
                    "
                >
                    <AtomicButton
                        data-cy="announcement-show-index"
                        size="sm"
                        color="yellow"
                    >
                        <template #icon>
                            <BookOpenIcon
                                class="w-6 h-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-custom_edit="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.announcements.edit', {
                            announcement: row.id as number,
                        })
                    "
                >
                    <AtomicButton
                        size="sm"
                        color="white"
                        data-cy="announcement-edit-index"
                    >
                        <template #icon>
                            <PencilSquareIcon
                                class="w-6 h-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-custom_delete="{ row }">
                <AtomicButton
                    size="sm"
                    color="red"
                    data-cy="announcement-delete-index"
                    @click="deleteAnnouncement(row as IAnnouncement)"
                >
                    <template #icon>
                        <TrashIcon
                            class="w-6 h-6 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </template>

            <template #item-custom_publish="{ row }">
                <AtomicButton
                    size="sm"
                    color="purple"
                    data-cy="announcement-publish-index"
                    @click="handlePublishAnnouncement(row as IAnnouncement)"
                >
                    <span v-if="!row.published_at">Publish</span>
                    <span v-else>Unpublish</span>

                    <template v-if="!row.published_at" #icon>
                        <BellIcon class="w-6 h-6" />
                    </template>

                    <template v-else #icon>
                        <MinusCircleIcon class="w-6 h-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import route from 'ziggy-js';

import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    BookOpenIcon,
    BellIcon,
    XCircleIcon,
    MinusCircleIcon,
} from '@heroicons/vue/24/outline';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IAnnouncement from '@/types/Announcements/IAnnouncement';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import useConfirm from '@/composables/useConfirm';

defineProps<{
    announcements: IPaginationData<IAnnouncement>;
}>();

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id', sortable: true, sortableId: 'id' },
    { text: 'Author', id: 'user' },
    { text: 'Type', id: 'type', sortable: true, sortableId: 'type' },
    { text: 'Announcement', id: 'title', sortable: true, sortableId: 'title' },
    {
        text: 'Published',
        id: 'published_at',
        align: 'center',
        sortable: true,
        sortableId: 'published_at',
    },
    { text: '', id: 'custom_publish' },
    { text: '', id: 'custom_show' },
    { text: '', id: 'custom_edit' },
    { text: '', id: 'custom_delete' },
];

const deleteAnnouncement = async (announcement: IAnnouncement) => {
    const confirmed = await show({
        header: `Delete announcement`,
        message: `Are you sure you want to delete this announcement?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.announcements.destroy', {
                announcement: announcement.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Announcement deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Announcement not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const handlePublishAnnouncement = async (announcement: IAnnouncement) => {
    if (announcement.published_at) {
        router.post(
            route('kiosk.announcements.unpublish', {
                announcement: announcement.id,
            }),
            {},
            {
                preserveScroll: true,
            }
        );
    } else {
        router.post(
            route('kiosk.announcements.publish', {
                announcement: announcement.id,
            }),
            {},
            {
                preserveScroll: true,
            }
        );
    }
};
</script>
