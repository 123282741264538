<template>
    <svg
        enable-background="new 0 0 437.4 110.3"
        viewBox="0 0 437.4 110.3"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m57.2 58.5c-7.3 0-13.1 2.9-17.2 8.6-2.8-5.4-8.4-8.7-14.5-8.6-5.8-.2-11.3 2.3-15 6.8v-1c0-2.9-2.4-5.3-5.3-5.3s-5.2 2.4-5.2 5.3v40.3c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-27.9c2.4-3.9 6-8.3 12.5-8.3 6.2 0 8.6 3 8.6 10.8v25.4c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-27.9c2.4-3.9 6-8.3 12.5-8.3 6.2 0 8.6 3 8.6 10.8v25.4c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-27.1c.2-9.2-5-19-16.5-19z"
        />
        <path
            d="m107.6 58.5c-5.7 0-11.4 1.2-16.6 3.5-1.9.7-3.1 2.5-3.1 4.5 0 2.5 1.9 4.5 4.4 4.6h.1c.5 0 1-.1 1.5-.2 3.9-1.4 8-2.2 12.2-2.2 8.1 0 11.5 3 11.6 10.4h-10.4c-14.1-.1-22.6 5.9-22.6 16.1 0 9.9 8.2 15.1 16.3 15.1 6.1.2 12-1.9 16.7-5.8v.1c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-26.6c0-9.7-6.4-19.5-20.6-19.5zm-3.4 42.3c-5.9 0-9-2.2-9-6.5 0-1.6 0-6.4 13.2-6.4h9.3v6.5c-2.8 3-7.9 6.4-13.5 6.4z"
        />
        <path
            d="m148.3 39.7h.6c3.2 0 5.8 2.6 5.8 5.8v.4c0 3.2-2.6 5.8-5.8 5.8h-.6c-3.2 0-5.8-2.6-5.8-5.8v-.4c0-3.2 2.6-5.8 5.8-5.8z"
        />
        <path
            d="m148.5 58.9c-2.9 0-5.2 2.3-5.2 5.2v.1 40.3c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-40.3c0-2.9-2.3-5.3-5.3-5.3z"
        />
        <path
            d="m175.2 38.2c-2.9 0-5.2 2.3-5.2 5.2v.1 61c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-61c0-2.9-2.3-5.3-5.2-5.3-.1 0-.1 0-.1 0z"
        />
        <path
            d="m216.9 58.5c-7.5 0-13.7 2.8-17.9 8.1-3.6 4.6-5.6 10.9-5.6 17.8 0 16.2 9.5 25.9 25.3 25.9 8.7 0 13-2 17-4.3 1.7-.8 2.8-2.5 3-4.4 0-2.6-2.1-4.7-4.6-4.7h-.1c-.7 0-1.5.1-2.1.5-3.8 2-8 2.9-12.2 2.8-8.8 0-14-4.1-15.3-11.9h30.6c2.8.1 5.2-2.1 5.3-4.9 0-.1 0-.2 0-.4 0-16.8-12.1-24.5-23.4-24.5zm0 9.2c4.4 0 11.8 2.5 12.8 11.7h-25.6c1-8.1 7-11.7 12.8-11.7z"
        />
        <path
            d="m276.8 58.5h-.5c-5 0-9.6 2.4-13.8 7v-1.2c0-2.9-2.4-5.3-5.3-5.3s-5.3 2.4-5.2 5.3v40.3c.1 2.9 2.6 5.2 5.5 5 2.7-.1 4.9-2.3 5-5v-28.3c3.4-4.9 8.2-7.6 13.6-7.6h.5c2.8.2 5.1-1.9 5.3-4.7 0-.1 0-.2 0-.3.1-2.8-2.2-5.2-5-5.3 0 .1-.1.1-.1.1z"
        />
        <g fill="#21c16c">
            <path
                d="m422.4 0h-106.4c-8.3 0-15 6.7-15 15v95.3l20.7-20.5h100.8c8.3 0 15-6.7 15-15v-59.8c-.1-8.3-6.8-15-15.1-15zm-89.5 67.2c0 2.2-1.8 4-4 4s-4-1.8-4-4v-42.5c0-2.2 1.8-4 4-4s4 1.8 4 4zm18.6 0c0 2.2-1.8 4-4 4s-4-1.8-4-4v-28.1c0-2.2 1.8-4 4-4s4 1.8 4 4zm.5-40.9c0 2.4-1.9 4.3-4.3 4.3h-.4c-2.4 0-4.3-1.9-4.3-4.3v-.3c0-2.4 1.9-4.3 4.3-4.3h.4c2.4 0 4.3 1.9 4.3 4.3zm28.2 43.9c-1.8.9-3.8 1.3-5.9 1.3-6.9 0-10.6-3.3-10.6-9.6v-19.3h-3.6c-1.2 0-2.2-.9-2.2-2.1v-.1c0-.7.4-1.4 1-1.9l8.9-8.7c.5-.5 1.1-.8 1.8-.9 1.2 0 2.3 1 2.3 2.2v.1 4.2h6.4c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6h-6.4v18.8c0 2.7 1.4 2.9 3.3 2.9.8 0 1.6-.1 2.3-.3.5-.2 1.1-.2 1.6-.3 1.8 0 3.3 1.5 3.4 3.4-.2 1.3-1.1 2.6-2.3 3.1zm24.5-6.2c2.9.1 5.8-.6 8.4-1.9.5-.3 1.1-.4 1.6-.4 2 0 3.6 1.5 3.6 3.5v.1c-.1 1.4-.9 2.7-2.2 3.3-2.8 1.6-5.8 3-12 3-11.2 0-18-6.9-18-18.4 0-13.5 9-18.4 16.6-18.4 11.4 0 16.6 9.1 16.6 17.5.1 2.1-1.6 3.9-3.7 4-.1 0-.2 0-.3 0h-21c1.1 5 4.7 7.7 10.4 7.7z"
            />
            <path
                d="m402.9 41.9c-4.4-.1-8.1 3.2-8.5 7.6h17.1c-.5-4.4-4.2-7.7-8.6-7.6z"
            />
        </g>
    </svg>
</template>
