<template>
    <MetricsTabs tab-key="api-tokens">
        <DataTable
            class="w-full mt-10"
            title="Api Token Stats"
            :headers="headers"
            :items="stats"
            searchable
        >
        </DataTable>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IIntegrationMetrics from '@/types/Metrics/IIntegrationMetrics';

defineProps<{
    stats: IPaginationData<IIntegrationMetrics>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Id',
        id: 'user_id',
        sortable: true,
        sortableId: 'user_id',
    },
    {
        text: 'Email',
        id: 'email',
        sortable: true,
        sortableId: 'email',
    },
    {
        text: 'Plan',
        id: 'braintree_plan',
        sortable: true,
        sortableId: 'braintree_plan',
    },
    {
        text: 'Unique users',
        id: 'unique_users',
        sortable: true,
        sortableId: 'unique_users',
    },
    {
        text: 'Total requests',
        id: 'total_month_requests',
        sortable: true,
        sortableId: 'total_month_requests',
    },
];
</script>

<style></style>
