<template>
    <div class="relative">
        <Actions
            :close-event="!isHovered"
            :items="folderActions"
            activator-type="dots"
        />
    </div>
</template>

<script lang="ts" setup>
import IActionNode from '@/types/Atomic/IActions';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IUser from '@/types/Members/IUser';
import IFolder from '@/types/Templates/IFolder';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import Actions from '@atomic/Actions/Action.vue';
import { PropType } from 'vue';

const props = defineProps({
    isHovered: {
        type: Boolean,
        default: false,
    },
    folder: {
        type: Object as PropType<IFolder>,
        required: true,
    },
});

const emit = defineEmits(['renameFolder', 'deleteFolder']);

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);
const userProps = computed(() => globalProps.value.user as IUser);

const { startEcho, stopEcho } = usePusher();
const { allTeams } = useTeamsAndMembers();
const { folders, currentPath, currentFolder } = useFolders();

const {
    startLoading,
    cancelLoading,
    listenForEvents,
    stopListeningForEvents,
} = useFolderActions(userProps.value.id);

const createDuplicateNode = (): IActionNode => {
    const teamNodes: IActionNode[] = [];
    const folderNodes: IActionNode[] = [];
    let duplicateToTeam = {
        name: 'Duplicate to team',
        children: teamNodes,
    };
    let duplicateToFolder = {
        name: 'Duplicate to folder',
        children: folderNodes,
    };


    const duplicate = async ({ destinationFolderId, destinationTeamId }: 
    { destinationFolderId?: number; destinationTeamId?: number 

    } = {}) => {
        try {
            startLoading();
            startEcho();
            listenForEvents();
            
            await axios.post(
                route('template-folders.duplicate', {
                    templateFolder: props.folder.id,
                    destination_template_folder_id: destinationFolderId,
                    team_id: destinationTeamId,
                })
            );
        } catch (err) {
            cancelLoading();
            stopListeningForEvents();
            stopEcho();
        }
    };

    const duplicateTeamNodes: IActionNode[] = [
        {
            name: 'Duplicate',
            async onClick() {
                duplicate({
                    destinationFolderId: currentFolder.value?.id
                });
            },
        },
        duplicateToTeam,
        duplicateToFolder,
    ];
    const duplicateNode: IActionNode = {
        name: 'Duplicate...',
        children: duplicateTeamNodes,
    };
    if (allTeams.value.length > 1) {
        allTeams.value.forEach((team: ICurrentTeam) => {
            if (team.id !== teamProps.value.id) {
                teamNodes.push({
                    name: team.name,
                    onClick: async () => {
                        duplicate({
                            destinationTeamId: team.id,
                        });
                    },
                });
            }
        });
    }

    if (currentPath.value.length) {
        folderNodes.push({
            name: 'My templates',
            onClick: async () => {
                duplicate();
            },
        });
    }
    if (folders.value.length) {
        folders.value.forEach((folder) => {
            if(folder.id !== props.folder.id){
                folderNodes.push({
                    name: folder.name,
                    onClick: async () => {
                        duplicate({
                            destinationFolderId: folder.id,
                        });
                    },
                }); 
            }
        });
    } 
    if (!currentPath.value.length && (!folders.value.length || folderNodes.length === 0)) {
        folderNodes.push({
            name: 'You have no folders yet',
        });
    }

    if(allTeams.value.length === 1) {
        teamNodes.push({
                name: 'You have no teams yet',
            });
    }
    return duplicateNode;
};

const folderActions = computed((): IActionNode[] => {
    return [
    {
            name: 'Rename',
            async onClick() {
                emit('renameFolder');
            },
        },
        createDuplicateNode(),
        {
            name: 'Delete',
            danger: true,
            async onClick() {
                emit('deleteFolder');
            },
        },
    ];
});

</script>

<style></style>
