import { IIntegration } from './../../../types/Integrations/IIntegration';
import IActionNode from '@/types/Atomic/IActions';
import useTemplateExports from './useTemplateExports';

const useExportNodes = (integrationsProp: IIntegration<unknown>[]) => {
    const getLowerCaseTypeAndCapitalizeFirst = (
        integration: IIntegration<unknown>
    ) => {
        return integration.type
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    };

    const createIntegrationsNode = (
        templateId: number,
        templateName: string
    ): IActionNode | undefined => {
        const integrations: IActionNode[] = [];

        const { exportTemplate } = useTemplateExports(templateId, templateName);

        integrations.push({
            name: 'Ecomail',
            onClick: () => {
                window.open(
                    `https://ecomail.app/free?appTemplateId=${templateId}`,
                    '_blank'
                );
            },
        });
        if (integrationsProp.length === 0) {
            return {
                name: 'Export',
                children: integrations,
            };
        }

        integrationsProp.forEach((integration: IIntegration<unknown>) => {
            integrations.push({
                name:
                    getLowerCaseTypeAndCapitalizeFirst(integration) +
                    ' ' +
                    integration.name,
                onClick: async () => {
                    exportTemplate(integration);
                },
            });
        });

        return {
            name: 'Export',
            children: integrations,
        };
    };

    return {
        createIntegrationsNode,
    };
};

export default useExportNodes;
