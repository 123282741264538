<template>
    <div class="flex">
        <div v-if="labelNameFromFilter !== undefined" class="flex mr-1">
            <p
                class="flex h-[40px] items-center rounded-md bg-indigo-600 px-3 text-sm font-medium tracking-small text-white"
            >
                {{ labelNameFromFilter }}

                <button class="ml-2" @click="clearFilter">
                    <XMarkIcon
                        class="w-4 h-4 text-white hover:text-gray-200"
                    ></XMarkIcon>
                </button>
            </p>
        </div>

        <Menu
            v-if="filters !== undefined && filters.length > 0"
            as="div"
            class="relative inline-block text-left"
        >
            <div>
                <MenuButton
                    data-testid="open-filters-button"
                    class="inline-flex h-[40px] w-[40px] items-center justify-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                >
                    <FunnelIcon
                        v-if="!someFilterIsSelected"
                        class="w-5 h-5 text-gray-500"
                        aria-hidden="true"
                    />
                    <FunnelIconSolid
                        v-if="someFilterIsSelected"
                        class="w-5 h-5 text-indigo-600"
                        aria-hidden="true"
                    >
                    </FunnelIconSolid>
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    class="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <p class="py-2 pl-4 text-gray-700">Filter by</p>
                    <div
                        class="flex flex-col justify-center w-full px-3 py-3 space-y-3"
                    >
                        <MenuItem
                            v-for="(filter, i) in filters"
                            :key="i"
                            :data-testid="'filter-trigger-' + filter.column"
                        >
                            <Menu
                                as="div"
                                class="relative inline-block w-full text-left"
                            >
                                <div class="flex justify-center w-full">
                                    <MenuButton
                                        class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        @click.stop="() => {}"
                                    >
                                        {{ filter.label }}
                                        <ChevronDownIcon
                                            class="w-5 h-5 ml-2 -mr-1"
                                            aria-hidden="true"
                                        />
                                    </MenuButton>
                                </div>

                                <transition
                                    enter-active-class="transition duration-100 ease-out"
                                    enter-from-class="transform scale-95 opacity-0"
                                    enter-to-class="transform scale-100 opacity-100"
                                    leave-active-class="transition duration-75 ease-in"
                                    leave-from-class="transform scale-100 opacity-100"
                                    leave-to-class="transform scale-95 opacity-0"
                                >
                                    <MenuItems
                                        class="absolute right-[-12px] z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        <div class="py-1">
                                            <MenuItem
                                                v-for="(
                                                    filterValue, k
                                                ) in filter.values"
                                                :key="k"
                                                v-slot="{ active }"
                                                :data-testid="
                                                    'filter-' +
                                                    filterValue.value
                                                "
                                                @click="
                                                    onClick(
                                                        filter.column,
                                                        filterValue.value
                                                    )
                                                "
                                            >
                                                <p
                                                    :class="[
                                                        active
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-700',
                                                        'block cursor-pointer px-4 py-2 text-sm',
                                                    ]"
                                                >
                                                    {{ filterValue.label }}
                                                </p>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
    ChevronDownIcon,
    FunnelIcon as FunnelIconSolid,
    XMarkIcon,
} from '@heroicons/vue/24/solid';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

import {
    IDataTableFilter,
    IDataTableFilterValue,
} from '../types/IDataTableFilter';

const props = defineProps({
    filters: {
        type: Array as () => IDataTableFilter[],
        default: () => [],
    },
    modelValue: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(['update:modelValue']);

const someFilterIsSelected = computed(() => {
    return props.modelValue?.column !== '' && props.modelValue?.value !== '';
});

const selectedFilter = computed((): IDataTableFilter | undefined => {
    return props.filters?.find(
        (filter) => filter.column === props.modelValue?.column
    );
});

const selectedFilterValue = computed((): IDataTableFilterValue | undefined => {
    return selectedFilter.value?.values.find(
        (filterValue) => filterValue.value === props.modelValue?.value
    );
});

const labelNameFromFilter = computed((): string | undefined => {
    if (
        selectedFilter.value !== undefined &&
        selectedFilterValue.value !== undefined
    ) {
        return `${selectedFilter.value.label} - ${selectedFilterValue.value.label}`;
    }

    return undefined;
});

const clearFilter = () => {
    if (props.modelValue) {
        emit('update:modelValue', {
            column: '',
            value: '',
        });
    }
};

const onClick = (column: string, value: string) => {
    if (props.modelValue) {
        emit('update:modelValue', {
            column: column,
            value: value,
        });
    }
};
</script>
