<template>
    <div class="py-12">
        <h1
            class="text-2xl font-medium leading-normal tracking-small text-gray-800"
        >
            Edit
        </h1>

        <inertia-link :href="route('kiosk.premade-templates.categories.index')">
            <AtomicButton class="mt-2" color="yellow" size="sm">
                Back
                <template #icon>
                    <BackspaceIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <form class="flex flex-col" @submit.prevent="updateCategory">
            <TextInput
                v-model="v$.form.name.$model"
                class="mt-5 w-52 sm:w-80"
                label="Name"
                type="text"
                placeholder="max 255 characters"
                :error="getError('name')"
                required
            />

            <div class="mt-6 flex flex-row">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="sm"
                    :disabled="loading || v$.$invalid"
                >
                    Save changes
                </AtomicButton>
            </div>
        </form>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import route from 'ziggy-js';
import ICategory from '@/types/Templates/ICategory';
import { create } from '@/composables/useNotifications';
import { BackspaceIcon } from '@heroicons/vue/24/outline';
import { reactive, ref } from 'vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { router } from '@inertiajs/vue3';
import IInputErrors from '@/types/Atomic/IInputErrors';

const props = defineProps<{
    category: ICategory;
}>();

const state = reactive({
    form: {
        name: props.category.name,
    },
});

const rules = {
    form: {
        name: { required },
    },
};

const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);

const updateCategory = () => {
    v$.value.form.name.$touch();
    if (v$.value.form.name.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('kiosk.premade-templates.categories.update', {
                premadeTemplateCategory: props.category.id,
            }),
            state.form,
            {
                onSuccess: () => {
                    create({
                        title: 'Category updated',
                        type: 'success',
                    });
                    errors.value = {};
                    v$.value.$reset();
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Category not updated',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>

<style></style>
