<template>
    <BillingTabs tab-key="plugin">
        <div class="mb-[98px]">
            <div
                class="flex flex-col pb-5 mt-10 border-b border-gray-300 lg:flex-row lg:justify-between"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Plugin subscription
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Choose your preferred Plugin plan subscription.
                    </p>
                </div>
                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
                    <div class="mx-6 mt-[18px] mb-[25px]">
                        <PluginSubscriptionPlans
                            :subscription-plans="subscriptionPlans"
                            :countries="countries"
                            :selected-card="selectedPaymentMethod"
                            :plugin-subscription="pluginSubscription"
                            :should-allow-trial="shouldAllowTrial"
                            @subscription-order-modal-closed="
                                handleSubscriptionOrderModalClosed
                            "
                        />
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col pb-5 mt-5 border-b border-gray-300 lg:flex-row lg:justify-between"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Payment method
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Your default payment method.
                    </p>
                </div>
                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
                    <div class="mx-6 mb-6">
                            <p class="mb-2.5 mt-4 text-base font-medium text-gray-600">
                                Paying with Card
                            </p>
                            <PaymentMethods
                                class="mt-4"
                                billing-address-type="plugin"
                                @select-payment-method="handleSelectPaymentMethod"
                            />
                        </div>
                    </div>
            </div>

            <form
                id="billingAddress"
                class="flex flex-col pb-5 mt-5 lg:flex-row lg:justify-between"
                @submit.prevent="updateBillingAddress"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Billing information for Plugin
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        You can use same billing information as for your PRO
                        subscription or enter different billing details.
                    </p>
                </div>
                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
                    <div class="px-6 border-b border-gray-200">
                        <CheckboxInput
                            v-model:checked="isUsingProBillingAddress"
                            name="billingAsPro"
                            label="Use same billing as PRO subscription"
                            class="mt-5 mb-[18px]"
                        />

                        <TextInput
                            v-model="v$.newBillingAddress.name.$model"
                            required
                            class="w-full mt-10 sm:mr-6 sm:w-80"
                            label="Name and Surname"
                            placeholder="Enter name and surname"
                            :readonly="isUsingProBillingAddress"
                            :error="getError('name')"
                        />

                        <TextInput
                            v-model="state.newBillingAddress.company"
                            secondarylabel="Optional"
                            class="w-full mt-6 sm:mr-6 sm:w-80"
                            label="Company"
                            placeholder="Enter company name"
                            :readonly="isUsingProBillingAddress"
                        />

                        <div class="flex flex-wrap">
                            <TextInput
                                v-model="state.newBillingAddress.tax_id"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="Tax ID"
                                :readonly="isUsingProBillingAddress"
                            />
                            <TextInput
                                v-model="state.newBillingAddress.crn"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="CRN"
                                :readonly="isUsingProBillingAddress"
                            />
                        </div>

                        <TextInput
                            v-model="state.newBillingAddress.street"
                            class="w-full mt-10 sm:mr-6 sm:w-80"
                            label="Address"
                            :readonly="isUsingProBillingAddress"
                        />

                        <div class="flex flex-wrap">
                            <TextInput
                                v-model="state.newBillingAddress.city"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="City"
                                :readonly="isUsingProBillingAddress"
                            />
                            <TextInput
                                v-model="state.newBillingAddress.postal_code"
                                class="w-full mt-5 sm:mr-6 sm:w-max"
                                label="ZIP / Postal code"
                                :readonly="isUsingProBillingAddress"
                            />
                        </div>

                        <SelectInput
                            v-model="state.newBillingAddress.country"
                            class="w-full mt-5 sm:mr-6 sm:w-80"
                            placeholder="Select country"
                            :data="countries"
                            label="Country"
                            :readonly="isUsingProBillingAddress"
                            search
                        >
                        </SelectInput>

                        <TextInput
                            v-model="v$.newBillingAddress.email.$model"
                            optional="Used for sending invoices"
                            class="w-full mt-10 mb-6 sm:mr-6 sm:w-80"
                            label="E-mail address"
                            :error="getError('email')"
                            required
                            :readonly="isUsingProBillingAddress"
                        />
                    </div>

                    <div class="flex justify-end">
                        <div class="pr-6 my-3">
                            <AtomicButton
                                type="submit"
                                color="yellow"
                                size="sm"
                                :disabled="
                                    isUsingProBillingAddress ||
                                    loading ||
                                    v$.$invalid
                                "
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </BillingTabs>
</template>
<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { PropType } from 'vue';
import route from 'ziggy-js';
import SubscriptionPlan from '@/types/Subscription/SubscriptionPlan';
import BillingAddress from '@/types/Subscription/BillingAddress';
import { create } from '@composables/useNotifications';
import { required, email, maxLength } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';
import ISubscription from '@/types/Subscription/ISubscription';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';

const props = defineProps({
    subscriptionPlans: {
        type: Array as PropType<SubscriptionPlan[]>,
        required: true,
    },
    billingAddress: {
        type: Object as PropType<BillingAddress>,
        required: true,
    },
    countries: {
        type: Array as PropType<ISelectable[]>,
        required: true,
    },
    pluginSubscription: {
        type: Object as PropType<ISubscription> | null,
        required: false,
        default: null,
    },
    shouldAllowTrial: {
        type: Boolean,
        required: true,
    },
});

const isUsingProBillingAddress = ref(props.billingAddress.type === 'pro');
const loading = ref(false);
const errors = ref<IInputErrors>({});
const paymentMethodsComponentKey = ref(0);
const selectedPaymentMethod = ref<IPaymentMethod>();

const state = reactive({
    newBillingAddress: {
        name: props.billingAddress.name,
        email: props.billingAddress.email,
        company: props.billingAddress.company,
        tax_id: props.billingAddress.tax_id,
        crn: props.billingAddress.crn,
        country: props.billingAddress.country,
        street: props.billingAddress.street,
        city: props.billingAddress.city,
        postal_code: props.billingAddress.postal_code,
    },
});

const rules = {
    newBillingAddress: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
        },
    },
};

const v$ = useVuelidate(rules, state);

watch(
    () => isUsingProBillingAddress.value,
    (newValue) => {
        if (newValue === true) {
            useProAsPluginBillingAddress();
        } else {
            dontUseProAsPluginBillingAddress();
        }
    }
);

const updateBillingAddress = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('billing-addresses.update-plugin'),
            state.newBillingAddress,
            {
                preserveScroll: true,
                onSuccess: () => {
                    errors.value = {};
                    create({
                        title: 'Billing information updated',
                        text: 'Your Plugin billing information has been successfully updated.',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Billing information not updated',
                        text: 'There was an error updating your Plugin billing information.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const useProAsPluginBillingAddress = () => {
    v$.value.$reset();
    router.post(
        route('billing-addresses.use-pro-billing-address'),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                state.newBillingAddress = props.billingAddress;
                create({
                    title: 'Billing information updated',
                    type: 'info',
                });
            },
        }
    );
};

const dontUseProAsPluginBillingAddress = () => {
    router.delete(route('billing-addresses.dont-use-pro-billing-address'), {
        preserveScroll: true,
        onSuccess: () => {
            state.newBillingAddress = props.billingAddress;
            create({
                title: 'Billing information updated',
                type: 'info',
            });
        },
    });
};

const handleSubscriptionOrderModalClosed = () => {
    paymentMethodsComponentKey.value++;
};

const getError = (field: string) => {
    return (
        v$.value.newBillingAddress[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    selectedPaymentMethod.value = paymentMethod;
};
</script>

<style></style>
