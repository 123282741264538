import { ref, computed } from 'vue';
import ISimpleTemplate from '@/types/Templates/ISimpleTemplate';
import IApiResponse from '@/types/IApiResponse';
import axios from 'axios';
import route from 'ziggy-js';
import useFolders from '@/Pages/Templates/composables/useFolders';
import { create } from '@composables/useNotifications';
import IPaginationData from '@/types/Atomic/IPaginationData';
import { useStorage } from '@vueuse/core';

const templates = ref<ISimpleTemplate[]>([]);
const selectedTemplates = ref<ISimpleTemplate[]>([]);
const { currentFolder, initializeCurrentFolder } = useFolders();

const defaultPerPage = ref<number>(20);
const per_page = useStorage('templatePerPage', defaultPerPage);

export const useTemplates = () => {
    const resourceUrl = route('templates.index');

    const searchQuery = ref('');
    const currentPage = ref(1);

    const isLoading = ref(false);

    const lastPage = ref<number | undefined>(undefined);

    const sortBy = ref('');
    const sortDirection = ref('asc'); //asc || desc

    const moveTemplateToFolder = async (
        templateId: number,
        folderId?: number
    ) => {
        try {
            await axios.post(
                route('templates.move-to-folder', { template: templateId }),
                {
                    template_folder_id: folderId,
                }
            );
            create({
                title: 'Template moved',
                type: 'info',
            });
        } catch (err) {
            () => undefined;
        }

        await initializeCurrentFolder();
        await fetchData();
    };

    const duplicateTemplateToFolder = async (
        templateId: number,
        folderId?: number
    ) => {
        try {
            await axios.post(
                route('templates.duplicate-to-folder', { template: templateId }),
                {
                    template_folder_id: folderId,
                }
            );
            create({
                title: 'Template duplicated',
                type: 'info',
            });
        } catch (err) {
            () => undefined;
        }
        await initializeCurrentFolder();
        await fetchData();
    };

    const buildQuery = () => {
        let q = resourceUrl;

        q += '?';

        if (currentFolder.value?.id !== undefined) {
            q += `template_folder_id=${currentFolder.value?.id}&`;
        }

        q += `current_page=${currentPage.value}`;

        q += `&per_page=${per_page.value}`;

        if (searchQuery.value !== '') {
            q += `&search=${searchQuery.value}`;
        }
        if (sortBy.value !== '') {
            q += `&sort_by=${sortBy.value}`;
            q += `&sort_by_direction=${sortDirection.value}`;
        }

        return q;
    };

    const fetchData = async () => {
        try {
            if (currentPage.value === 1) {
                isLoading.value = true;

                const res = await axios.get<
                    IApiResponse<IPaginationData<ISimpleTemplate>>
                >(buildQuery());

                templates.value = res.data.data.data;
                lastPage.value = res.data.data.last_page;
            } else {
                fetchDataUntilCurrentPage();
            }
        } catch (err) {
            () => undefined;
        } finally {
            isLoading.value = false;
        }
    };

    const fetchDataUntilCurrentPage = async () => {
        const realCurrentPage = currentPage.value;
        currentPage.value = 1;
        await fetchData();

        for (let i = 2; i <= realCurrentPage; i++) {
            await loadNextPage();
        }

        currentPage.value = realCurrentPage;
    };

    const search = async (searchQ: string) => {
        currentPage.value = 1;
        searchQuery.value = searchQ;
        await fetchData();
    };

    const setSortDirection = async (sortDir: 'asc' | 'desc') => {
        if (sortDir === 'asc' || sortDir === 'desc') {
            sortDirection.value = sortDir;
            await fetchData();
        } else {
            throw new TypeError(
                `Sorting direction: ${sortDir} is not supported. Supported directions: 'asc' or 'desc'.`
            );
        }
    };

    const setSortBy = async (sortByKey: string) => {
        //possible validation for sortByKey
        sortBy.value = sortByKey;
        await fetchData();
    };

    const setCurrentPage = async (newPage: number) => {
        currentPage.value = newPage;
        await fetchData();
    };

    const loadNextPage = async () => {
        currentPage.value++;

        try {
            isLoading.value = true;
            const res = await axios.get<
                IApiResponse<IPaginationData<ISimpleTemplate>>
            >(buildQuery());

            templates.value.push(...res.data.data.data);
            lastPage.value = res.data.data.last_page;
        } catch (err) {
            () => undefined;
        } finally {
            isLoading.value = false;
        }
    };

    const importTemplate = async (definition: string) => {
        let folderId = null;

        if (currentFolder.value !== undefined) {
            folderId = currentFolder.value.id;
        }
        try {
            const response = await axios.post(route('templates.imports.json'), {
                definition: definition,
                template_folder_id: folderId,
            });

            if (response.data.success) {
                create({
                    title: 'Template imported',
                    type: 'info',
                });
            } else {                
                create({
                    title: 'Template has not been imported',
                    type: 'error',
                });
            }
        } catch (err) {
            () => undefined;
        }

        await fetchData();
    };

    const isLastPage = computed(() => {
        return currentPage.value === lastPage.value || lastPage.value === 0;
    });

    const setPerPage = async (newPerPage: number) => {
        per_page.value = newPerPage;
        await fetchData();
    };

    const selectTemplate = (template: ISimpleTemplate) => {  
        const foundTemplate = selectedTemplates.value.find(
            (selectedTemplate) => selectedTemplate.id === template.id
        );      

        if (foundTemplate !== undefined) {
            selectedTemplates.value = selectedTemplates.value.filter(
                (t) => t.id !== template.id
            );
        } else {
            selectedTemplates.value.push(template);
        }
    }

    const cancelMultipleSelect = () => {
        selectedTemplates.value = [];
    }

    const selectAllTemplates = () => {
        if (selectedTemplates.value.length === templates.value.length) {
            cancelMultipleSelect();
        } else {
            selectedTemplates.value = templates.value;
        }
    };

    return {
        fetchData,
        search,
        setSortBy,
        setSortDirection,
        setCurrentPage,
        currentPage,
        templates,
        moveTemplateToFolder,
        duplicateTemplateToFolder,
        importTemplate,
        loadNextPage,
        lastPage,
        isLastPage,
        isLoading,
        searchQuery,
        setPerPage,
        per_page,
        selectedTemplates,
        selectTemplate,
        cancelMultipleSelect,
        selectAllTemplates,
    };
};

export default useTemplates;
