<template>
    <Action
        :items="templateActions"
        activator-type="button"
        activator-text="Actions"
    />
</template>

<script lang="ts" setup>
import IActionNode from '@/types/Atomic/IActions';
import { PropType, computed } from 'vue';
import axios from 'axios';
import route from 'ziggy-js';
import ISimpleTemplate from '@/types/Templates/ISimpleTemplate';
import useConfirm from '@/composables/useConfirm';
import useTemplates from '../composables/useTemplates';
import { router } from '@inertiajs/vue3';
import { saveAs } from 'file-saver';
import useExportNodes from '../composables/useExportNodes';
import { create } from '@composables/useNotifications';
import { IIntegration } from '@/types/Integrations/IIntegration';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

const props = defineProps({
    template: {
        type: Object as PropType<ISimpleTemplate>,
        required: true,
    },
    integrations: {
        type: Array as PropType<IIntegration<unknown>[]>,
        default: () => [],
    },
});

const emit = defineEmits(['changeStatusTo', 'renameTemplate']);
const globalProps = computed(() => usePage<ITopolPageProps>().props);

const { show } = useConfirm();
const { fetchData } = useTemplates();
const { createIntegrationsNode } = useExportNodes(props.integrations);

const templateActions = computed((): IActionNode[] => {
    let nodes: IActionNode[] = [
        {
            name: 'Rename',
            async onClick() {
                const newNameResponse = await show({
                    header: 'Rename',
                    hasInput: true,
                    inputData: {
                        label: 'Template Name',
                        defaultValue: props.template.name,
                    },
                    confirm: 'Rename',
                });

                if (typeof newNameResponse === 'string') {
                    emit('renameTemplate', newNameResponse);
                }
            },
        },
        {
            name: 'Set status',
            children: [
                {
                    name: 'Draft',
                    isChecked: props.template.status === 'draft',
                    async onClick() {
                        emit('changeStatusTo', 'draft');
                    },
                },
                {
                    name: 'In Review',
                    isChecked: props.template.status === 'in_review',
                    async onClick() {
                        emit('changeStatusTo', 'in_review');
                    },
                },
                {
                    name: 'Sent',
                    isChecked: props.template.status === 'sent',
                    async onClick() {
                        emit('changeStatusTo', 'sent');
                    },
                },
            ],
        },
        {
            name: 'Duplicate',
            async onClick() {
                try {
                    const res = await axios.post(
                        route('templates.duplicate', {
                            template: props.template.id,
                        })
                    );

                    router.visit(
                        route('templates.edit', {
                            template: res.data.data.id,
                        })
                    );
                    create({
                        title: 'Template duplicated',
                        type: 'info',
                    });
                } catch (err) {
                    () => undefined;
                }
            },
        },

        {
            name: 'Download',
            children: [
                {
                    name: 'JSON',
                    async onClick() {
                        const res = await axios.post(
                            route('templates.downloads.json', {
                                template: props.template.id,
                            })
                        );

                        var fileToSave = new Blob([JSON.stringify(res.data)], {
                            type: 'application/json',
                        });

                        const name = props.template.name
                            ? props.template.name.replace(/\./g, '')
                            : 'untitled_template';
                        saveAs(fileToSave, name);
                    },
                },
                {
                    name: 'HTML',
                    children: [
                        {
                            name: 'HTML and images',
                            async onClick() {
                                try {
                                    const res = await axios.post(
                                        route(
                                            'templates.downloads.self-hosted-html',
                                            {
                                                template: props.template.id,
                                            }
                                        )
                                    );

                                    let name = props.template.name;

                                    if (name == null) {
                                        name = 'untitled_template';
                                    }
                                    if (res.data.success) {
                                        saveAs(res.data.data, name + '.zip');
                                    } else {
                                        create({
                                            title: 'There was an error downloading the template',
                                            type: 'error',
                                        });
                                    }
                                } catch (err) {
                                    create({
                                        title: 'There was an error downloading the template',
                                        type: 'error',
                                    });
                                }
                            },
                        },
                        {
                            name: 'HTML with hosted images',
                            async onClick() {
                                const res = await axios.post(
                                    route('templates.downloads.hosted-html', {
                                        template: props.template.id,
                                    })
                                );

                                if (res.data) {
                                    const fileToSave = new Blob([res.data], {
                                        type: 'text/html;charset=utf-8',
                                    });

                                    const name = props.template.name
                                        ? props.template.name.replace(/\./g, '')
                                        : 'untitled_template';

                                    saveAs(fileToSave, name);
                                } else {
                                    create({
                                        title: 'There was an error with the template content. Try opening the template in the editor, save it and then try again.',
                                        type: 'error',
                                    });
                                }
                            },
                        },
                    ],
                },
            ],
        },

        {
            name: 'Delete template',
            danger: true,
            async onClick() {
                const templateName = props.template.name
                    ? props.template.name
                    : 'Untitled';

                const confirmed = await show({
                    header: 'Delete template',
                    message: `Do you really want to delete the template: ${templateName}?`,
                    type: 'danger',
                });

                if (confirmed) {
                    try {
                        await axios.delete(
                            route('templates.delete', {
                                template: props.template.id,
                            })
                        );
                        create({
                            title: 'Template deleted',
                            text: 'Your template has been successfully deleted.',
                            type: 'success',
                        });
                        router.get(route('templates.index'));
                    } catch (err) {
                        () => undefined;
                    }

                    await fetchData();
                }
            },
        },
    ];

    const integrationNode = createIntegrationsNode(
        props.template.id,
        props.template.name
    );
    if (integrationNode !== undefined) {
        nodes.splice(4, 0, integrationNode);
    }

    if (globalProps.value.isAdmin) {
        nodes.splice(5,0,  {
            name: 'Create premade template',
            onClick() {
                router.get(route('kiosk.premade-templates.create', {
                    template: props.template.id
                }))
            },
        },)
    }

    return nodes;
});
</script>

<style></style>
