<template>
    <div class="w-full">
        <div
            v-if="loading"
            class="flex items-start justify-center w-full mt-96"
        >
            <LoadingSpinner size="large" :delay="false"></LoadingSpinner>
        </div>

        <TopolEditor
            v-show="!loading"
            :options="topolOptions"
            :stage="props.stage"
            @onSave="callbacks.onSave"
            @onSaveAndClose="callbacks.onSaveAndClose"
            @onTestSend="callbacks.onTestSend"
            @onInit="callbacks.onInit"
            @onBlockSave="callbacks.onBlockSave"
            @onBlockRemove="callbacks.onBlockRemove"
            @onBlockEdit="callbacks.onBlockEdit"
            @onAlert="callbacks.onAlert"
            @onClose="callbacks.onClose"
            @onLoaded="callbacks.onLoaded"
            @onTemplateRename="callbacks.onRenameTemplate"
            @updateTestingEmailAddresses="callbacks.updateTestingEmailAddresses"
        ></TopolEditor>
    </div>
</template>

<script lang="ts" setup>
import {
    TopolEditor,
    TopolPlugin,
    ITopolOptions,
    ISavedBlock,
    INotification,
    IStage,
} from '@topol.io/editor-vue';

import LoadingSpinner from '@components/Atomic/LoadingSpinner/LoadingSpinner.vue';

import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import axios from 'axios';

import useSavedBlocksService from './composables/useSavedBlocksService';
import useSendTestService from './composables/useSendTestService';
import { create as createNotification } from '@composables/useNotifications';

import useConfirm from '@composables/useConfirm';
import useFonts from '@/Pages/EditorPreferences/Fonts/composables/useFonts';
import useEditorTranslations from './composables/useEditorTranslations';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import usePusher from '@/composables/usePusher';

const { startEcho, stopEcho } = usePusher();
const { show } = useConfirm();

type CurrentUser = {
    id: number;
    name: string;
    profile_photo_path: string;
    profile_photo_url: string;
};

interface Props {
    apiKey: string;
    token: string;
    definition: string;
    saveUrl: string;
    backUrl: string;
    savedBlocks: ISavedBlock[];
    saveSavedBlockUrl: string;
    getAutosaveUrl: string;
    getConversationsUrl: string;
    getCommentsUrl: string;
    sendTestEmailUrl: string;
    createAutosaveUrl: string;
    getAutosavesUrl: string;
    theme: string;
    fontSizes: string;
    defaultFonts: string;
    customFonts: string;
    smartMergeTags: string;
    mergeTags: string;
    currentUser: CurrentUser;
    ownerId: string | number;
    templateId: number;
    language?: string;
    colors: string;
    title?: string;
    stage?: IStage;
    enableMergeTagPreview?: boolean;
    imageCompressionOptions: string;
    enableImageToTextRatio: boolean;
    chatAI: boolean;
    googlePromotionalAnnotation: boolean;
    lambdaStage: 'production' | 'develop';
    userRole: string;
    getFeedsUrl: string;
    getProductsUrl: string;
    templateStatus: string|null;
    enableComments?: boolean;
    renameTemplate?: boolean;
    htmlMinified: boolean;
    enableSectionVariants: boolean;
    isSubscribed: boolean;
    testingEmails: string;
}

const props = withDefaults(defineProps<Props>(), {
    language: 'en',
    title: '',
    stage: 'production',
    enableComments: true,
    renameTemplate: true,
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

onMounted(() => {
    listenForComments();
});

onUnmounted(() => {
    stopListeningForComments();
});

const listenForComments = () => {
    if(teamProps.value.personal_team === false){
       startEcho();

        window.Echo.private('template-conversations.' + props.templateId)
        .listen('.App\\Topol\\Templates\\Events\\TemplateConversationUpdated', (e) => {
            if(e.comment_by !== props.currentUser.id) {
                window.TopolPlugin.refreshComments(e.key)
            }
        });
    }
}

const stopListeningForComments = () => {
    stopEcho();
}

const { defaultFonts: allDefaultFonts } = useFonts();
const loading = ref(true);

const {
    createSavedBlock,
    deleteSavedBlock,
    editSavedBlock,
    prepareSavedBlocks,
} = useSavedBlocksService(props.savedBlocks, props.saveSavedBlockUrl, props.language ?? 'en');

const { sendTestEmail } = useSendTestService(props.sendTestEmailUrl);

const { translations: editorTranslations, getTranslations } = useEditorTranslations(props.language ?? 'en');

// @ts-ignore
const translations = ref(editorTranslations[props.language ?? 'en']);

const getEditorRole = () => {
    switch (props.userRole) {
        case 'owner':
        case 'admin':
            return 'manager';
        case 'editor':
            return 'editor';
        case 'viewer':
            return 'reader';
        default:
            return 'reader';
    }
};

const callbacks = {
    async onSave({ json, html }: { json: unknown; html: unknown }) {
        await axios.post(props.saveUrl, {
            definition: JSON.stringify(json),
            html: html,
        });

        createNotification({
            title:  translations.value.saveSuccess,
            type: 'success',
        });
    },

    async updateTestingEmailAddresses(emails: string[]) {
        await axios.put(route('editor-preferences.testing-emails.update'), {
            emails: emails,
        });
    },

    async onSaveAndClose({ json, html }: { json: unknown; html: unknown }) {
        await axios.post(props.saveUrl, {
            definition: JSON.stringify(json),
            html: html,
        });

        router.visit(props.backUrl);
    },

    async onBlockSave(json: ISavedBlock) {
        const newSavedBlocks = await createSavedBlock(json);

        if (newSavedBlocks !== undefined) {
            TopolPlugin.setSavedBlocks(newSavedBlocks);

            createNotification({
                title: translations.value.saveBlockSuccess,
                text: ``,
                type: 'success',
            });
        }
    },

    async onBlockRemove(templateSavedBlockId: number) {
        const newSavedBlocks = await deleteSavedBlock(templateSavedBlockId);

        if (newSavedBlocks !== undefined) {
            TopolPlugin.setSavedBlocks(newSavedBlocks);

            createNotification({
                title: translations.value.removeBlockSuccess,
                text: ``,
                type: 'success',
            });
        }
    },

    async onBlockEdit(templateSavedBlockId: number) {
        const newSavedBlocks = await editSavedBlock(templateSavedBlockId);

        if (newSavedBlocks !== undefined) {
            TopolPlugin.setSavedBlocks(newSavedBlocks);

            createNotification({
                title: translations.value.updateBlockSuccess,
                text: ``,
                type: 'success',
            });
        }
    },

    async onTestSend({ email, html }: { email: string[]; html: unknown }) {
        if(!props.isSubscribed){
            createNotification({
                title: 'Email not sent',
                text: 'You need to be a paid user to send test emails. Please select appropriate PRO subscription to continue.',
                type: 'error',
            });

            return;
        }

        try {
            const res = await sendTestEmail(email, html);

            if (res.data.success === true) {
                createNotification({
                    title: translations.value.sentEmailSuccessTitle,
                    text: getTranslations('sentEmailSuccess', email),
                    type: 'success',
                });
            } else {
                createNotification({
                    title: translations.value.sentEmailFailedTitle,
                    text:  getTranslations('sentEmailFailed', email),
                    type: 'error',
                });
            }
        } catch (err) {
            createNotification({
                title: translations.value.sentEmailFailedTitle,
                text:  getTranslations('sentEmailFailed', email),
                type: 'error',
            });
        }
    },

    onAlert(notification: INotification) {
        if (!notification.expectSideEffect) {
            createNotification({
                title: notification.title,
                text: notification.text,
                type: notification.type,
                persistent: notification.persistent,
            });
        }
    },

    onInit() {
       TopolPlugin.load(props.definition);
    },

    onLoaded() {
        loading.value = false;
    },

    async onClose() {
        const confirmed = await show({
            type: 'danger',
            header: translations.value.closeEditorTitle,
            message:
                translations.value.closeEditorText,
            confirm: translations.value.closeEditorConfirm,
            cancel: translations.value.cancel,
        });

        if (confirmed) {
            router.visit(props.backUrl);
        }
    },
    async onRenameTemplate() {
        const newTemplateName = await show({
            header: translations.value.templateRenameTitle,
            hasInput: true,
            inputData: {
                label: translations.value.templateRenameLabel,
                defaultValue: props.title,
            },
            confirm: translations.value.rename,
            cancel: translations.value.cancel,
        });

        if(typeof newTemplateName === 'string'){
            try {
                const result = await axios.put(
                route('templates.update', {
                    template: props.templateId,
                }),
                {
                    name: newTemplateName,
                    status: props.templateStatus,
                }
            );
                if(result.data.success){
                    createNotification({
                        title: translations.value.templateRenameSuccessTitle,
                        type: 'success',
                    });

                    window.TopolPlugin.setTemplateName(newTemplateName);
                    router.reload({only: ['title']})
                }
                else{
                    createNotification({
                        title: translations.value.templateRenameFailedTitle,
                        type: 'error',
                    });
                }

            } catch (e) {
                () => undefined
            }
        }

    },
};

const topolOptions: ITopolOptions = {
    apiAuthorizationHeader: 'Bearer ' + props.token,

    title: props.title,

    authorize: {
        apiKey: props.apiKey,
        userId: props.ownerId,
    },

    currentUser: {
        userId: props.currentUser.id,
        name: props.currentUser.name,
        profilePhotoUrl: props.currentUser.profile_photo_path ?? props.currentUser.profile_photo_url,
    },

    role: getEditorRole(),

    enableAutosaves: true,

    savedBlocks: prepareSavedBlocks(props.savedBlocks),

    api: {
        AUTOSAVES: props.getAutosavesUrl,
        GET_AUTOSAVE: props.getAutosaveUrl,
        AUTOSAVE: props.createAutosaveUrl,
        CONVERSATIONS: props.getConversationsUrl,
        COMMENTS: props.getCommentsUrl,
        FEEDS: props.getFeedsUrl,
        PRODUCTS: props.getProductsUrl,
    },

    windowBar: ['close', 'fullscreen'],

    smartMergeTags: JSON.parse(props.smartMergeTags),
    mergeTags: JSON.parse(props.mergeTags),

    mobileFirstEnabled: true,

    //we handle alerts on our own, to be able reflect our server request responses statuses
    disableAlerts: true,

    language: props.language,
    fontSizes: JSON.parse(props.fontSizes),

    theme: {
        preset: props.theme === 'light' ? 'light' : 'dark',
        colors: {
            active: '#FFC000',
            'active-light': '#FFC000',
        },
    },
    colors: JSON.parse(props.colors),

    betaFeatures: {
        carousel: true,
    },

    customFonts:
        props.customFonts === '[]' && props.defaultFonts === '[]'
            ? {
                  override: false,
                  fonts: [],
              }
            : {
                  override: true,
                  fonts:
                      props.defaultFonts === '[]'
                          ? allDefaultFonts.concat(
                                JSON.parse(props.customFonts)
                            )
                          : JSON.parse(props.defaultFonts).concat(
                                JSON.parse(props.customFonts)
                            ),
              },

    enableMergeTagPreview: props.enableMergeTagPreview ?? false,
    imageCompressionOptions: JSON.parse(props.imageCompressionOptions),
    enableImageToTextRatio: props.enableImageToTextRatio,
    chatAI: props.chatAI,
    googlePromotionalAnnotation: props.googlePromotionalAnnotation,
    lambdaStage: props.lambdaStage,
    stage: props.stage,
    enableComments: props.enableComments,
    renameTemplate: props.renameTemplate,
    htmlMinified: props.htmlMinified,
    enableSectionVariants: props.enableSectionVariants,
    imageEditorV2: true,
    testingEmails: JSON.parse(props.testingEmails),
};
</script>
