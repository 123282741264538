<template>
    <div class="relative bg-gray-100">
        <Navbar @handleSideBarMobile="() => {}" />

        <!--        <BlackFridayBanner-->
        <!--            v-if="shouldDisplayBlackFridayDeal"-->
        <!--            class="sticky z-20 top-14 md:relative md:top-0"-->
        <!--        />-->

        <!--        <CyberMondayBanner-->
        <!--            v-if="shouldDisplayBlackFridayDeal"-->
        <!--            class="sticky z-20 top-14 md:relative md:top-0"-->
        <!--        />-->

        <TrialEndedBanner
            v-if="shouldDisplayPluginTrialBanner && !globalProps.isAdmin && !shouldDisplayDeletedBanner"
            class="sticky z-20 top-14 md:relative md:top-0"
            :date="globalProps.user.plugin_trial_ends_at"
            type="plugin"
        />

        <TrialEndedBanner
            v-if="
                shouldDisplayProTrialBanner &&
                !shouldDisplayPluginTrialBanner &&
                !shouldDisplayPluginSubscriptionBanner &&
                !shouldDisplayProPersonalSubscriptionBanner &&
                !isPluginSubscribed &&
                !globalProps.isAdmin &&
                !shouldDisplayDeletedBanner
            "
            class="sticky z-20 top-14 md:relative md:top-0"
            :date="globalProps.user.pro_trial_ends_at"
            type="pro"
        />

        <SubscriptionBanner
            v-if="
                shouldDisplayPluginSubscriptionBanner &&
                !shouldDisplayPluginTrialBanner &&
                !globalProps.isAdmin && !shouldDisplayDeletedBanner
            "
            class="sticky z-20 top-14 md:relative md:top-0"
            type="plugin"
        />

        <SubscriptionBanner
            v-if="
                shouldDisplayProPersonalSubscriptionBanner &&
                !shouldDisplayPluginSubscriptionBanner &&
                !isPluginSubscribed &&
                !globalProps.isAdmin && !shouldDisplayDeletedBanner
            "
            class="sticky z-20 top-14 md:relative md:top-0"
            type="pro-personal"
        />

        <SubscriptionBanner
            v-if="
                shouldDisplayProTeamSubscriptionBanner && !globalProps.isAdmin && !shouldDisplayDeletedBanner
            "
            class="sticky z-20 top-14 md:relative md:top-0"
            type="pro-team"
        />

        <InfoBanner
            v-if="
                shouldDisplayDeletedBanner
            "
            class="sticky z-20 top-14 md:relative md:top-0"
            title="Your templates have been removed due to prolonged inactivity, and your profile is scheduled for permanent deletion in 30 days"
            text="For more information on how to restore them, please contact our support team at tereza@topol.io."
        />

        <div
            v-if="globalProps.user.isImpersonating"
            class="fixed left-0 bottom-0 z-[500] flex w-full flex-wrap items-center justify-between bg-gray-900 bg-opacity-50 px-10 py-2"
        >
            <div class="my-1 text-sm text-white">
                You are now seeing the app as: {{ globalProps.user.name }}
            </div>
            <AtomicButton size="sm" color="purple" @click="endImpersonation">
                <template #icon>
                    <EyeSlashIcon class="w-6 h-6 mr-2" />
                </template>
                Leave impersonation
            </AtomicButton>
        </div>

        <div
            class="grid min-h-[calc(100vh-56px)] md:min-h-[calc(100vh-64px)] grid-cols-1 md:grid-cols-[64px_1fr] xl:grid-cols-[256px_1fr]"
        >
            <Sidebar
                v-click-away="onClickAway"
                :opened="sidebarOpened"
                @close="sidebarOpened = false"
                @open="sidebarOpened = true"
            />
            <div
                class="mx-auto w-full px-5 pt-[56px] opacity-100 blur-none transition duration-300 ease-in-out sm:px-10 md:pt-0 xl:max-w-[1328px] xl:opacity-100 xl:blur-none"
                :class="{
                    'pointer-events-none md:opacity-90 md:blur-sm':
                        sidebarOpened,
                }"
            >
                <slot />
            </div>
        </div>

        <ConfirmDialog></ConfirmDialog>
        <Notifications></Notifications>

        <ShoppingCartModal />
    </div>
</template>
<script lang="ts" setup>
import Navbar from '@components/Navigation/Navbar.vue';
import Sidebar from '@components/Navigation/Sidebar.vue';
import { computed, onMounted, ref } from 'vue';
import ConfirmDialog from '@atomic/Dialogs/ConfirmDialog.vue';
import Notifications from '@/components/Notifications.vue';
import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import TrialEndedBanner from '../components/Navigation/components/TrialEndedBanner.vue';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { EyeSlashIcon } from '@heroicons/vue/24/outline';
import SubscriptionBanner from '@components/Navigation/components/SubscriptionBanner.vue';
import Bugsnag from '@bugsnag/js';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

onMounted(() => {
    const userObject = {
        id: globalProps.value.user.id,
        email: globalProps.value.user.email,
        current_team_id: teamProps.value.id,
    };
    Bugsnag.setUser(JSON.stringify(userObject));

    getData();
});

const {
    shouldDisplayProTrialBanner,
    shouldDisplayPluginTrialBanner,
    shouldDisplayPluginSubscriptionBanner,
    shouldDisplayProPersonalSubscriptionBanner,
    shouldDisplayProTeamSubscriptionBanner,
    shouldDisplayDeletedBanner,
    isPluginSubscribed,
    fetchBanners,
} = useBanners();

const { fetchTeams, getCurrentTeamMembersCount } = useTeamsAndMembers();

const getData = async () => {
    await fetchBanners();
    await getCurrentTeamMembersCount();
    await fetchTeams();  
};

const sidebarOpened = ref(false);
const onClickAway = () => {
    sidebarOpened.value = false;
};
const endImpersonation = () => {
    router.delete(route('kiosk.users.nonimpersonate'), {
        onSuccess: () => {
            getData();
        },
    });
};
</script>

<style></style>
