<template>
    <div>
        <div v-if="globalProps.isAdmin" class="relative pb-80">
            <div
                v-if="isLoading"
                class="absolute top-0 left-0 mt-10 flex justify-center"
            >
                <LoadingSpinner size="large"></LoadingSpinner>
            </div>

            <div
                class="mb-[19px] mt-[45px] flex flex-col sm:flex-row sm:justify-between"
            >
                <h1
                    class="text-2xl font-medium leading-normal tracking-small text-gray-800"
                >
                    Marketplace
                </h1>

                <inertia-link
                    :href="route('marketplace.created-templates.index')"
                    class="mt-4 flex-shrink-0 sm:mt-0"
                >
                    <AtomicButton color="white" size="lg">
                        <template #icon>
                            <ClipboardDocumentListIcon class="mr-1 h-5 w-5" />
                        </template>

                        Template Manager
                    </AtomicButton>
                </inertia-link>
            </div>

            <div class="mt-4 flex flex-wrap justify-between gap-4">
                <div class="flex flex-wrap gap-4">
                    <MarketplaceSortingButton
                        v-model:sortBy="selectedSortBy"
                        v-model:sortByDirection="selectedSortByDirection"
                        class="flex-shrink-0"
                    />

                    <PerPageButtonGroup
                        v-model="perPage"
                        :values="[20, 50, 100]"
                    ></PerPageButtonGroup>

                    <MultipleSelect
                        ref="MultipleSelectCategories"
                        v-model:value="selectedCategories"
                        placeholder="Select categories"
                        class="min-w-[170px] max-w-[240px]"
                        :options="categories"
                    />

                    <MultipleSelect
                        ref="MultipleSelectKeywords"
                        v-model:value="selectedKeywords"
                        placeholder="Select keywords"
                        class="min-w-[170px] max-w-[240px]"
                        :options="keywords"
                    />
                </div>

                <SearchInput
                    v-model="searchQuery"
                    placeholder="Search marketplace"
                    class="max-w-xs"
                />
            </div>

            <div class="mt-6 flex flex-row flex-wrap gap-8">
                <MarketplaceTemplateBox
                    v-for="template in templates"
                    :key="template.id"
                    :template="template"
                    @openPreview="openPreview"
                />
            </div>

            <div
                class="mt-10 flex w-full justify-center"
                :class="{
                    block: !isLastPage && !isLoading,
                    hidden: isLastPage || isLoading,
                }"
            >
                <AtomicButton
                    :disabled="isLoading"
                    color="white"
                    @click="loadNextPage"
                    >Load more</AtomicButton
                >
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { useDebounceFn } from '@vueuse/core';
import { PropType } from 'vue';
import route from 'ziggy-js';
import { ClipboardDocumentListIcon } from '@heroicons/vue/24/outline';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);

const selectedKeywords = ref<IKeyword[]>();
const selectedCategories = ref<ICategory[]>();

const selectedSortBy = ref<string>('');
const selectedSortByDirection = ref<'asc' | 'desc'>('asc');
const searchQuery = ref<string>('');

const perPage = ref<number>(20);

watch(selectedSortBy, () => {
    setSortBy(selectedSortBy.value);
});

watch(selectedSortByDirection, () => {
    setSortDirection(selectedSortByDirection.value);
});

const {
    templates,
    fetchData,
    search,
    setKeywords,
    setCategories,
    loadNextPage,
    isLoading,
    isLastPage,
    setSortBy,
    setSortDirection,
    setPerPage,
} = useMarketplaceTemplates('all');

watch(perPage, () => {
    setPerPage(perPage.value);
});

watch(selectedKeywords, () => {
    if (selectedKeywords.value !== undefined) {
        let keywords: number[] = [];
        selectedKeywords.value.forEach((keyword) => {
            keywords.push(keyword.id);
        });
        setKeywords(keywords);
    }
});

watch(selectedCategories, () => {
    if (selectedCategories.value !== undefined) {
        let categories: number[] = [];
        selectedCategories.value.forEach((category) => {
            categories.push(category.id);
        });
        setCategories(categories);
    }
});

watch(
    searchQuery,
    useDebounceFn(() => {
        search(searchQuery.value);
    }, 220)
);

onMounted(async () => {
    await fetchData();
});

const openPreview = (template: IMarketplaceTemplate) => {
    router.get(route('marketplace.all-templates.show', template.id));
};
</script>
