<template>
    <div
        class="flex flex-col px-6 pt-6 bg-white rounded-lg shadow-md datatable-width"
    >
        <div class="flex justify-between">
            <div class="flex items-center justify-between mb-2">
                <p
                    v-if="props.title"
                    class="ml-2 text-xl font-medium text-gray-600"
                >
                    {{ props.title }}
                </p>
            </div>

            <div
                class="flex flex-col items-end justify-end gap-1 mb-4 space-y-3 md:mr-1 md:gap-3 lg:flex-row-reverse"
            >
                <SearchInput
                    v-if="searchable"
                    v-model="paramState.search"
                    data-cy="kiosk-users-search"
                    data-testid="search-component"
                    @update:model-value="paramState.search_invoice = ''"
                />

                <TableDateInput v-if="dateInput" v-model:date-from="paramState.date_from" v-model:date-to="paramState.date_to" />

                <TableFilteration v-model="paramState.filter" :filters="filters"></TableFilteration>

                <TableInvoiceInput  v-if="invoiceInput" v-model="paramState.search_invoice" @update:model-value="paramState.search = ''" />
            </div>
        </div>

        <div class="-my-2 overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
                <div class="overflow-hidden sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <TableHeader v-model:sort-by="paramState.sort_by" v-model:sort-by-direction="paramState.sort_by_direction" :headers="props.headers"></TableHeader>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <TableRow
                                v-for="(item, i) in props.items.data"
                                :key="i"
                                :headers="props.headers"
                                :item="item"
                            >
                                <template
                                    v-for="(_, name) in $slots"
                                    #[name]="slotData"
                                >
                                    <div v-if="slotData" :key="name">
                                        <slot :name="name" v-bind="slotData" />
                                    </div>
                                </template>
                            </TableRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <TablePagination :param-state="paramState" :items="props.items"></TablePagination>
    </div>
</template>

<script lang="ts" setup>
import SearchInput from '@atomic/Inputs/SearchInput.vue';

import IDataTableHeader from './types/IDataTableHeader';
import IPaginationData from './types/IPaginationData';

import TableHeader from './components/TableHeader.vue';
import TableRow from './components/TableRow.vue';
import TablePagination from './components/TablePagination.vue';
import TableFilteration from './components/TableFilteration.vue';

import { IDataTableItem } from './types/IDataTableItem';
import { IDataTableFilter } from './types/IDataTableFilter';
import IDataTableUtilityParams from './types/IDataTableUtilityParmas';
import { reactive } from 'vue';

import { router } from '@inertiajs/vue3';
import { debouncedWatch, useUrlSearchParams } from '@vueuse/core';
import { useBrowserLocation } from '@vueuse/core';
import buildQueryFromObject from './utils/buildQueryFromObject';

interface DataTableProps {
    headers: IDataTableHeader[];
    items: IPaginationData<IDataTableItem>;
    title?: string;
    searchable?: boolean;
    filters?: IDataTableFilter[];
    dateInput?: boolean;
    invoiceInput?: boolean;
}

const props = withDefaults(defineProps<DataTableProps>(), {
    searchable: false,
    title: '',
    filters: undefined,
    dateInput: false,
    invoiceInput: false,
});

const params = computed(() => {
    const params = useUrlSearchParams();
    const nestedObject: Record<string, string | Record<string, string>> = {};

    Object.entries(params).map((entry) => {
        if (entry[0].includes('[')) {
            const filter = entry[0].split('[');
            const filterKey = filter[1].replace(']', '');

            if (typeof nestedObject.filter !== 'object') {
                nestedObject.filter = {};
            }
            nestedObject['filter'][filterKey] = entry[1].toString();
        } else {
            nestedObject[entry[0]] = entry[1].toString();
        }
    });

    return nestedObject;
});

const resolveDirection = (): 'asc' | 'desc' | undefined => {
    if (params.value.sort_by_direction === undefined) {
        return undefined;
    }

    if (params.value.sort_by_direction === 'asc') {
        return 'asc';
    }

    if (params.value.sort_by_direction === 'desc') {
        return 'desc';
    }

    throw new Error(
        `Unknown sort direction provided: ${params.value.sort_by_direction}`
    );
};

const resolveFilter = () => {
    if (params.value.filter === undefined) {
        return {
            column: '',
            value: '',
        };
    }

    return {
        // @ts-expect-error
        column: params.value.filter.column !== undefined ? params.value.filter.column : '',
        // @ts-expect-error
        value: params.value.filter.value !== undefined ? params.value.filter.value : '',
    };
};

const paramState = reactive<IDataTableUtilityParams>({
    search: params.value.search !== undefined ? params.value.search.toString() : '',
    sort_by: params.value.sort_by !== undefined ? params.value.sort_by.toString() : '',
    sort_by_direction: resolveDirection(),
    filter: resolveFilter(),
    date_from:
        params.value.date_from !== undefined ? params.value.date_from.toString() : '',
    date_to: params.value.date_to !== undefined ? params.value.date_to.toString() : '',
    search_invoice:
        params.value.search_invoice !== undefined
            ? params.value.search_invoice.toString()
            : '',
});

debouncedWatch(
    paramState,
    async () => {
        const currentRoute =
            useBrowserLocation().value.origin +
            (useBrowserLocation().value.pathname ?? '');
        if (currentRoute) {
            await router.get(
                currentRoute + '?' + buildQueryFromObject(paramState),
                undefined,
                {
                    preserveState: true,
                    preserveScroll: true,
                }
            );
        }
    },
    { debounce: 220 }
);


</script>
<style>
@media only screen and (max-width: 767px) {
    .datatable-width {
        max-width: calc(100vw - 24px);
    }
}

@media only screen and (max-width: 1327px) and (min-width: 768px) {
    .datatable-width {
        max-width: calc(100vw - 144px);
    }
}

@media only screen and (min-width: 1328px) {
    .datatable-width {
        max-width: calc(100vw - 304px);
    }
}
</style>
