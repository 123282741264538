<template>
    <!-- Grid View -->
    <div
        :class="{
            'w-full sm:w-80 md:w-56': view === 'grid',
        }"
        data-cy="template-folder"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
        @drop="onDrop($event)"
        @dragenter.prevent.stop="onDragEnter($event)"
        @dragover.prevent
        @dragleave.prevent.stop
        @dragend.prevent
        @dragstart.prevent
    >
        <!-- Grid View -->
        <div
            v-if="view === 'grid'"
            class="group flex h-[364px] w-full cursor-pointer flex-col rounded-md bg-white shadow-card sm:w-80 md:w-56"
            :class="{ 'ring-2 ring-[#FFCB6F]': isDragged }"
        >
            <div
                class="flex items-center justify-center grow"
                @click="goToFolder"
            >
                <div class="flex flex-col items-center border-gray-200">
                    <div
                        class="flex h-[170px] w-full items-center justify-center"
                    >
                        <FolderAnimation
                            :is-hovered="isHovered"
                            :is-dragged="isDragged"
                            size="lg"
                        />
                    </div>

                    <div
                        class="flex flex-col items-center mx-auto bg-gray-100 rounded-2lg"
                    >
                        <p
                            class="px-3 py-[1px] text-xs leading-normal text-gray-500"
                        >
                            <span v-if="folder.templates_total_count">{{
                                folder.templates_total_count
                            }}</span>
                            <span v-else>0</span>
                            <span v-if="folder.templates_total_count == 1">
                                file</span
                            >
                            <span v-else> files</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="">
                    <div class="relative flex justify-between">
                        <p
                            class="w-full h-10 px-4 text-sm leading-normal text-gray-800 truncate"
                            @click="goToFolder"
                        >
                            {{ folder.name }}
                        </p>

                        <div class="relative pr-4">
                            <FolderActions
                                data-cy="template-folder-actions"
                                class="hidden group-hover:block"
                                :is-hovered="isHovered"
                                :folder="folder"
                                @rename-folder="$emit('rename-folder')"
                                @delete-folder="$emit('delete-folder')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- List View -->
        <div
            v-if="view === 'list'"
            class="relative flex flex-row items-center justify-between gap-1 hover:bg-gray-50"
            :class="{ 'border-2 border-[#FFCB6F]': isDragged }"
        >
            <div
                class="flex items-center w-full pr-5 cursor-pointer justify-left"
                :class="{
                    'pl-11 py-3': !isDragged,
                    'pl-[42px] py-[10px]': isDragged,
                }"
                @click="goToFolder"
            >
                <FolderAnimation
                    :is-dragged="isDragged"
                    :is-hovered="isHovered"
                    size="sm"
                />

                <div class="flex flex-row gap-5 ml-6">
                    <div>
                        <button type="button" class="flex items-center">
                            <p
                                class="max-w-[120px] truncate text-sm font-medium leading-6 text-gray-900 md:max-w-xs lg:max-w-lg"
                            >
                                {{ folder.name }}
                            </p>
                        </button>

                        <div
                            class="flex items-center text-sm leading-normal text-gray-500 gap-x-2"
                        >
                            <div
                                class="rounded-2lg bg-gray-200 px-3 py-[1px] text-xs"
                            >
                                <span v-if="folder.templates_total_count">{{
                                    folder.templates_total_count
                                }}</span>
                                <span v-else>0</span>
                                <span v-if="folder.templates_total_count == 1">
                                    file</span
                                >
                                <span v-else> files</span>
                            </div>

                            <div
                                class="flex items-center text-xs leading-5 text-gray-500 gap-x-2"
                            >
                                <p class="hidden whitespace-nowrap sm:block">
                                    Last updated on
                                    {{
                                        format(
                                            new Date(folder.updated_at),
                                            "d. L. yyyy '('HH:mm:ss')'"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative">
                <div
                    v-if="!marketplace"
                    class="flex items-center flex-none py-3 pr-3 sm:gap-x-4 sm:px-5"
                >
                    <FolderActions
                        is-hovered
                        :folder="folder"
                        @rename-folder="$emit('rename-folder')"
                        @delete-folder="$emit('delete-folder')"
                        />
                </div>
            </div>

            <div
                class="absolute bottom-0 left-1/2 h-[1px] w-[calc(100%-16px)] -translate-x-1/2 transform bg-gray-200"
                :class="{ '-z-1': isDragged }"
            ></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import FolderActions from './FolderActions.vue';
import { PropType, ref } from 'vue';
import useTemplates from '../composables/useTemplates';
import IFolder from '@/types/Templates/IFolder';
import { format } from 'date-fns';

const props = defineProps({
    templateCount: {
        type: Number,
        default: 0,
    },
    folder: {
        type: Object as PropType<IFolder>,
        required: true,
    },
    view: {
        type: String as PropType<'grid' | 'list'>,
        default: 'grid',
    },
    marketplace: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['rename-folder', 'delete-folder', 'open-folder']);

const goToFolder = () => {
    emit('open-folder');
};

const isHovered = ref(false);
const lockElement = ref<EventTarget>();
const drag = useDrag();

const isDragged = computed(() => {
    return drag.isDraggedOn(props.folder.id.toString());
});

const { moveTemplateToFolder } = useTemplates();

const onDrop = async (event: DragEvent) => {
    if (event === undefined || event.dataTransfer === null) {
        return;
    }

    const templateId = drag.templateId.value;

    await moveTemplateToFolder(parseInt(templateId), props.folder.id);

    drag.endDrag();
};

const onDragEnter = (event: DragEvent) => {
    if (event === undefined || event.dataTransfer === null) {
        return;
    }
    if (lockElement.value === undefined) {
        if (event.relatedTarget !== null) {
            drag.folderDraggedOn(props.folder.id.toString());
        }
    }
};

watch(
    () => drag.drag.value,
    (newValue) => {
        if (newValue === false) {
            drag.endDrag();
        }
    }
);
</script>

<style scoped></style>
