<template>
    <div>
        <Modal :gray-header="false" size="md" @close="$emit('close')">
            <template #body>
                <div class="flex flex-col items-center px-4">
                    <svg
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="32"
                            cy="32"
                            r="28"
                            fill="white"
                            stroke="#C6F6D5"
                            stroke-width="8"
                        />
                        <path
                            d="M21 33L28.3333 40L43 26"
                            stroke="#48BB78"
                            stroke-width="6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <h4 class="mt-[22px] text-xl font-bold text-green-600">
                        Thank you for your payment
                    </h4>

                    <div
                        class="mt-3 max-w-sm text-center text-sm text-gray-500"
                    >
                        <p>
                            Your payment was successful. We have successfully
                            processed payment for your subscription to Topol,
                            and you may find
                            <inertia-link
                                :href="route('billing.invoices.index')"
                                class="font-medium text-topol-blue-500"
                                >your receipt here</inertia-link
                            >.
                        </p>
                        <p class="mt-5">
                            If you believe this charge to be in error or have
                            any questions, please email
                            <a
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                href="mailto:support@topol.io"
                                >support@topol.io</a
                            >.
                        </p>
                    </div>
                    <div class="pb-11">
                        <inertia-link
                            v-if="plan < 3 || plan > 8"
                            :href="route('home')"
                        >
                            <AtomicButton color="yellow" size="md" class="mt-8">
                                Let’s build some templates now!
                            </AtomicButton>
                        </inertia-link>

                        <div v-if="plan > 3 && plan < 9">
                            <AtomicButton
                                color="yellow"
                                size="md"
                                class="mt-8"
                                @click="enablePlugin"
                            >
                                Let’s setup your Plugin!
                            </AtomicButton>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';

defineProps({
    plan: {
        required: true,
        type: Number,
    },
});

defineEmits(['close']);

const enablePlugin = () => {
    router.post(
        route('plugin.enable'),
        {},
        {
            onSuccess: () => {
                create({
                    title: 'Plugin enabled',
                    text: 'Plugin has been successfully enabled.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Plugin not enabled',
                    text: 'There was an error enabling your Plugin.',
                    type: 'error',
                });
            },
        }
    );
};
</script>
