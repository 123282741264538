<template>
    <div class="relative flex items-center">
        <div class="flex items-center">
            <input
                :id="label"
                :checked="checked"
                :name="name"
                :label="label"
                :aria-checked="checked"
                type="radio"
                :value="option"
                :disabled="disabled"
                class="form-radio cursor-pointer rounded-full border shadow-button transition duration-150 ease-in-out focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-white disabled:shadow-none disabled:hover:border-gray-300"
                :class="{
                    'h-6 w-6 checked:bg-[length:10px_10px]': size === 'lg',
                    'h-4 w-4 checked:bg-[length:6px_6px]': size === 'md',
                    'checked-radio': checked && !disabled && !hasError,
                    'disabled-radio': disabled,
                    'error-radio border-topol-alert-100 bg-red-200 text-red-200 checked:border checked:border-topol-alert-100 checked:hover:border-topol-alert-100 checked:focus:border-topol-alert-100 disabled:border-gray-300 disabled:text-white':
                        hasError && !disabled,
                    'border-gray-300 text-orange-500': !hasError,
                }"
                @change="handleInput"
            />
        </div>
        <div class="text-sm leading-5">
            <label
                class="ml-2 cursor-pointer text-sm"
                :class="{
                    'text-gray-700': !hasError && !disabled,
                    'text-topol-alert-100': hasError && !disabled,
                    'text-gray-400': disabled,
                }"
                :for="label"
            >
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: [String, Number, Boolean],
        default: null,
    },
    size: {
        type: String,
        validator(value: string) {
            return ['md', 'lg'].includes(value);
        },
        default: 'md',
    },
    option: {
        type: [String, Number, Boolean],
        default: null,
    },
    name: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const handleInput = () => {
    if (props.disabled) {
        return;
    }

    emit('update:modelValue', props.option);
};

const checked = computed(() => {
    return props.modelValue === props.option;
});
</script>

<style scoped>
.checked-radio:checked {
    background-image: url('https://d5aoblv5p04cg.cloudfront.net/application/images/components/checked-radio.svg');
}

.disabled-radio:checked {
    background-image: url('https://d5aoblv5p04cg.cloudfront.net/application/images/components/disabled-radio.svg');
}

.error-radio:checked {
    background-image: url('https://d5aoblv5p04cg.cloudfront.net/application/images/components/error-radio.svg');
}
</style>
