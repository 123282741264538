<template>
    <div>
        <PaymentMethodsList 
            v-if="!createNewPaymentMethod" 
            :payment-methods="paymentMethods"
            :payment-methods-loading="paymentMethodsLoading"
            :selected-payment-method-token="selectedPaymentMethodToken"
            @delete-payment-method="deletePaymentMethod"
            @change-default-payment-method="changeDefaultPaymentMethod"
            @create-payment-method="handleCreatePaymentMethod"
        />
    
        <PaymentMethodsCreate 
            v-if="createNewPaymentMethod" 
            :billing-address-type="billingAddressType" 
            @close="handleCloseCreatePaymentMethod"
        />
    </div>
</template>

<script setup lang="ts">
import useConfirm from '@/composables/useConfirm';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import route from 'ziggy-js';
import IApiResponse from '@/types/IApiResponse';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';

const props = defineProps({
    billingAddressType: {
        required: true,
        type: String,
    },
});

const emit = defineEmits(['selectPaymentMethod', 'loading']);

const { show: showConfirmation } = useConfirm();
const createNewPaymentMethod = ref(false);
const paymentMethods = ref([] as IPaymentMethod[]);
const paymentMethodsLoading = ref(true);
const selectedPaymentMethodToken = ref('');

onMounted(async () => {
    await getPaymentMethods();
});

const handleCloseCreatePaymentMethod = async () => {
    createNewPaymentMethod.value = false;
    paymentMethodsLoading.value = true;
    await getPaymentMethods();
};

const handleCreatePaymentMethod = () => {    
    createNewPaymentMethod.value = true;
    emit('loading', true);
};

watch(() => paymentMethodsLoading.value, (value) => {    
    emit('loading', value);
});

const getPaymentMethods = async () => {
    try {
        const response = await axios.get<IApiResponse<IPaymentMethod[]>>(
            route('braintree.payment-methods.index', {
                billing_address_type: props.billingAddressType as string,
            })
        );
        paymentMethods.value = response.data.data;
        paymentMethods.value.map((paymentMethod: IPaymentMethod) => {
            if (paymentMethod.default) {                
                selectPaymentMethod(paymentMethod);
            }
        });
    } catch (e) {
        () => undefined;
    } finally {
        paymentMethodsLoading.value = false;
    }
};

const changeDefaultPaymentMethod = async (selectedPaymentMethod: IPaymentMethod) => {
    if (selectedPaymentMethod.default) {
        return;
    }
    
    try{
        paymentMethodsLoading.value = true;

        const response = await axios.post<IApiResponse<unknown>>(
            route('braintree.payment-methods.make-default'),
            {
                token: selectedPaymentMethod.token,
                type: props.billingAddressType,
            }
        );

        if (response.data.success) {
            paymentMethods.value.map((paymentMethod: IPaymentMethod) => {
                if (paymentMethod.token === selectedPaymentMethod.token) {
                    paymentMethod.default = true;
                } else {
                    paymentMethod.default = false;
                }

                return paymentMethod;
            });
            await selectPaymentMethod(selectedPaymentMethod);
        }
    } catch (e) {
        () => undefined;
    } finally {
        paymentMethodsLoading.value = false;
    }
};

const deletePaymentMethod = async (selectedPaymentMethod: IPaymentMethod) => {
    const confirmed = await showConfirmation({
        header: 'Delete card',
        message: `Do you really want to delete this card?`,
        type: 'danger',
    });

    if (!confirmed) {
        return;
    }
    
    try {
        paymentMethodsLoading.value = true;
        const response = await axios.post<IApiResponse<unknown>>(
            route('braintree.payment-methods.delete'),
            {
                token: selectedPaymentMethod.token,
                type: props.billingAddressType,
            }
        );
    
        if (response.data.success) {
            paymentMethods.value = paymentMethods.value.filter(
                (paymentMethod: IPaymentMethod) => {
                    return paymentMethod.token !== selectedPaymentMethod.token;
                }
            );

            create({
                title: 'Success',
                text: 'The payment method has been deleted successfully.',
                type: 'success',
            });
        } else {
            throw new Error('Failed to delete the card');
        }
    } catch (e) {
        create({
            title: 'Error',
            text: 'Failed to delete the payment method. Please try again or contact us for support.',
            type: 'error',
        })
    } finally {
        paymentMethodsLoading.value = false;
    }

};

const selectPaymentMethod = async (paymentMethod: IPaymentMethod) => {
    selectedPaymentMethodToken.value = paymentMethod.token;
    emit('selectPaymentMethod', paymentMethod);
};

</script>