<template>
    <div class="flex min-h-[40px] flex-wrap items-center gap-4">
        <button
            class="flex items-center justify-start text-left flex-nowrap"
            data-testid="my-templates-button"
            @click="$emit('path-clicked', undefined)"
        >
            <p
                class="mx-4 ml-0 text-2xl font-medium leading-normal tracking-small"
                :class="{
                    'text-gray-800': pathItems.length === 0,
                    'text-gray-500': pathItems.length !== 0,
                    underline:
                        isDraggedPathId === undefined &&
                        lockElement !== undefined,
                }"
                @drop="onDrop($event, undefined)"
                @dragenter.prevent.stop="onDragEnter($event, undefined)"
                @dragover.prevent
                @dragleave.prevent.stop="onDragLeave($event)"
                @dragend.prevent="onDragEnd()"
            >
                My templates
            </p>

            <div
                v-if="pathItems.length !== 0 || withTemplate"
                class="flex-shrink-0 w-6 h-6 text-gray-400"
            >
                <svg
                    class="w-6 h-6"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11 8.5L14.4645 11.9645L11 15.4289"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </button>

        <button
            v-for="(path, index) in pathItems"
            :key="index"
            class="flex items-center justify-start text-left flex-nowrap"
            @click="$emit('path-clicked', path)"
        >
            <p
                class="mr-4 text-2xl font-medium leading-normal tracking-small"
                :class="{
                    'text-gray-800':
                        index === pathItems.length - 1 && !withTemplate,
                    'text-gray-500':
                        index !== pathItems.length - 1 || withTemplate,
                    underline: isDraggedPathId === path.id,
                }"
                style="word-break: break-word"
                @drop="onDrop($event, path.id)"
                @dragenter.prevent.stop="onDragEnter($event, path.id)"
                @dragover.prevent
                @dragleave.prevent.stop="onDragLeave($event)"
                @dragend.prevent="onDragEnd()"
            >
                {{ path.name }}
            </p>

            <div
                v-if="index !== pathItems.length - 1 || withTemplate"
                class="flex-shrink-0 w-6 h-6 text-gray-400"
            >
                <svg
                    class="w-6 h-6"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11 8.5L14.4645 11.9645L11 15.4289"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import IFolderPathItem from '@/types/Templates/IFolderPathItem';
import useTemplates from '../composables/useTemplates';

defineProps({
    pathItems: {
        type: Array as PropType<IFolderPathItem[]>,
        required: true,
    },
    withTemplate: {
        type: Boolean,
        default: false,
    },
});

defineEmits(['path-clicked', 'go-home']);

const lockElement = ref<EventTarget>();
const isDraggedPathId = ref<number | undefined>();

const { moveTemplateToFolder } = useTemplates();

const onDrop = async (event: DragEvent, folderId?: number) => {
    if (event === undefined || event.dataTransfer === null) {
        return;
    }

    const templateId = event.dataTransfer.getData('templateId');
    await moveTemplateToFolder(parseInt(templateId), folderId);

    lockElement.value = undefined;
    isDraggedPathId.value = folderId;
};

const onDragEnter = (event: DragEvent, pathId?: number) => {
    if (lockElement.value === undefined) {
        if (event.relatedTarget !== null) {
            lockElement.value = event.relatedTarget;
            isDraggedPathId.value = pathId;
        }
    }
};

const onDragLeave = (event: DragEvent) => {
    if (lockElement.value === event.relatedTarget) {
        lockElement.value = undefined;
        isDraggedPathId.value = undefined;
    }
};

const onDragEnd = () => {
    lockElement.value = undefined;
    isDraggedPathId.value = undefined;
};
</script>
