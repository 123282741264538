import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from '@inertiajs/vue3';

export default () => {
    const inertiaProps = usePage<ITopolPageProps>();

    const featureDisabled = (value: string) => {
        if (!inertiaProps.props.user.features) {
            return true;
        } else {
            //@ts-ignore
            return !inertiaProps.props.user.features[value];
        }
    };

    const teamFeatureDisabled = (value: string) => {
        if (!inertiaProps.props.team.features) {
            return true;
        } else {
            //@ts-ignore
            return !inertiaProps.props.team.features[value];
        }
    };

    return {
        featureDisabled,
        teamFeatureDisabled,
    };
};
