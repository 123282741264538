import { ref } from 'vue';

import {
    IIntegration,
    ISendy,
    IAWeber,
    IZohoCampaigns,
    IZohoMail,
    IZohoMarketing,
    ISendPulse,
    IIContact,
} from '@/types/Integrations/IIntegration';

const sendyFormOpened = ref(false);
const aweberFormOpened = ref(false);
const icontactFormOpened = ref(false);
const zohoCampaignsFormOpened = ref(false);
const zohoMailFormOpened = ref(false);
const zohoMarketingFormOpened = ref(false);
const sendPulseFormOpened = ref(false);
const sendyFormIntegration = ref({} as IIntegration<ISendy>);
const aweberFormIntegration = ref({} as IIntegration<IAWeber>);
const zohoCampaignsFormIntegration = ref({} as IIntegration<IZohoCampaigns>);
const zohoMarketingFormIntegration = ref({} as IIntegration<IZohoMarketing>);
const zohoMailFormIntegration = ref({} as IIntegration<IZohoMail>);
const sendPulseFormIntegration = ref({} as IIntegration<ISendPulse>);
const icontactFormIntegration = ref({} as IIntegration<IIContact>);

const integrationsLoading = ref<number[]>([]);

export const useTemplateExports = (
    templateId: number,
    templateName: string
) => {
    const template = ref({
        id: templateId,
        name: templateName,
    });

    const exportTemplate = (integration: IIntegration<unknown>) => {
        switch (integration.type) {
            case 'BREVO':
                exportViaIntegration(integration.id, 'Brevo', 'brevo');
                break;

            case 'SENDY':
                // @ts-expect-error
                exportTemplateToSendy(integration);
                break;

            case 'MAILCHIMP':
                exportViaIntegration(integration.id, 'Mailchimp', 'mailchimp');
                break;

            case 'SENDGRID':
                exportViaIntegration(integration.id, 'Sendgrid', 'sendgrid');
                break;

            case 'CONSTANT_CONTACT':
                exportViaIntegration(
                    integration.id,
                    'Constant Contact',
                    'constant-contact'
                );
                break;

            case 'KLAVIYO':
                exportViaIntegration(integration.id, 'Klaviyo', 'klaviyo');
                break;

            case 'MAILJET':
                exportViaIntegration(integration.id, 'Mailjet', 'mailjet');
                break;

            case 'GMAIL':
                exportViaIntegration(integration.id, 'Gmail', 'gmail');
                break;

            case 'HUBSPOT':
                exportViaIntegration(integration.id, 'Hubspot', 'hubspot');
                break;

            case 'CAMPAIGN_MONITOR':
                exportViaIntegration(
                    integration.id,
                    'Campaign Monitor',
                    'campaign-monitor'
                );
                break;

            case 'ACTIVE_CAMPAIGN':
                exportViaIntegration(
                    integration.id,
                    'Active Campaign',
                    'active-campaign'
                );
                break;

            case 'MOOSEND':
                exportViaIntegration(integration.id, 'Moosend', 'moosend');
                break;

            case 'CLICKSEND':
                exportViaIntegration(integration.id, 'ClickSend', 'clicksend');
                break;

            case 'BENCHMARK_EMAIL':
                exportViaIntegration(
                    integration.id,
                    'Benchmark Email',
                    'benchmark-email'
                );
                break;

            case 'MAILGUN':
                exportViaIntegration(integration.id, 'Mailgun', 'mailgun');
                break;

            case 'AWEBER':
                // @ts-expect-error
                exportTemplateToAweber(integration);
                break;

            case 'MAILUP':
                exportViaIntegration(integration.id, 'MailUp', 'mailup');
                break;

            case 'MAILER_LITE':
                exportViaIntegration(
                    integration.id,
                    'MailerLite',
                    'mailer-lite'
                );
                break;

            case 'ACUMBAMAIL':
                exportViaIntegration(
                    integration.id,
                    'Acumbamail',
                    'acumbamail'
                );
                break;

            case 'GET_RESPONSE':
                exportViaIntegration(
                    integration.id,
                    'GetResponse',
                    'get-response'
                );
                break;

            case 'ZOHO_CAMPAIGNS':
                // @ts-expect-error
                exportTemplateToZohoCampaigns(integration);
                break;

            case 'ZOHO_MARKETING':
                // @ts-expect-error
                exportTemplateToZohoMarketing(integration);
                break;

            case 'ZOHO_MAIL':
                // @ts-expect-error
                exportTemplateToZohoMail(integration);
                break;

            case 'SENDPULSE':
                // @ts-expect-error
                exportTemplateToSendpulse(integration);
                break;

            case 'OUTLOOK_WEB':
                exportViaIntegration(
                    integration.id,
                    'Outlook Web',
                    'outlook-web'
                );
                break;

            case 'CLOSE':
                exportViaIntegration(integration.id, 'Close', 'close');
                break;

            case 'ICONTACT':
                // @ts-expect-error
                exportTemplateToIContact(integration);
                break;

            case 'CLEVERREACH':
                exportViaIntegration(
                    integration.id,
                    'CleverReach',
                    'cleverreach'
                );
                break;
        }
    };

    const exportViaIntegration = async (
        integrationId: number,
        integrationName: string,
        integrationRouteName: string
    ) => {
        try {
            integrationsLoading.value.push(integrationId);
            const res = await axios.post(
                route(`templates.exports.${integrationRouteName}`, {
                    template: template.value.id,
                }),
                {
                    integration_id: integrationId,
                }
            );
            if (res.data.success) {
                create({
                    title: 'Successful export',
                    text:
                        'Template has been successfully exported to ' +
                        integrationName,
                    type: 'success',
                });
            } else {
                create({
                    title: `Template export to ${integrationName} failed`,
                    type: 'error',
                });
            }
        } catch (err) {
            create({
                title: `Template export to ${integrationName} failed`,
                text: err.response.data.message,
                type: 'error',
            });
        } finally {
            integrationsLoading.value = integrationsLoading.value.filter(
                (id) => id !== integrationId
            );
        }
    };

    const exportTemplateToSendy = async (integration: IIntegration<ISendy>) => {
        sendyFormOpened.value = true;
        sendyFormIntegration.value = integration;
    };

    const exportTemplateToAweber = (integration: IIntegration<IAWeber>) => {
        aweberFormOpened.value = true;
        aweberFormIntegration.value = integration;
    };

    const exportTemplateToZohoCampaigns = (
        integration: IIntegration<IZohoCampaigns>
    ) => {
        zohoCampaignsFormOpened.value = true;
        zohoCampaignsFormIntegration.value = integration;
    };

    const exportTemplateToZohoMail = (integration: IIntegration<IZohoMail>) => {
        zohoMailFormOpened.value = true;
        zohoMailFormIntegration.value = integration;
    };

    const exportTemplateToZohoMarketing = (
        integration: IIntegration<IZohoMarketing>
    ) => {
        zohoMarketingFormOpened.value = true;
        zohoMarketingFormIntegration.value = integration;
    };

    const exportTemplateToSendpulse = (
        integration: IIntegration<ISendPulse>
    ) => {
        sendPulseFormOpened.value = true;
        sendPulseFormIntegration.value = integration;
    };

    const exportTemplateToIContact = (integration: IIntegration<IIContact>) => {
        icontactFormOpened.value = true;
        icontactFormIntegration.value = integration;
    };

    const closeSendyForm = () => {
        sendyFormOpened.value = false;
        sendyFormIntegration.value = {} as IIntegration<ISendy>;
    };

    const closeAweberForm = () => {
        aweberFormOpened.value = false;
        aweberFormIntegration.value = {} as IIntegration<IAWeber>;
    };

    const closeZohoCampaignsForm = () => {
        zohoCampaignsFormOpened.value = false;
        zohoCampaignsFormIntegration.value = {} as IIntegration<IZohoCampaigns>;
    };

    const closeZohoMarketingForm = () => {
        zohoMarketingFormOpened.value = false;
        zohoMarketingFormIntegration.value = {} as IIntegration<IZohoMarketing>;
    };

    const closeZohoMailForm = () => {
        zohoMailFormOpened.value = false;
        zohoMailFormIntegration.value = {} as IIntegration<IZohoMail>;
    };

    const closeSendPulseForm = () => {
        sendPulseFormOpened.value = false;
        sendPulseFormIntegration.value = {} as IIntegration<ISendPulse>;
    };

    const closeIContactForm = () => {
        icontactFormOpened.value = false;
        icontactFormIntegration.value = {} as IIntegration<IIContact>;
    };

    return {
        exportTemplate,
        exportTemplateToSendy,
        exportTemplateToAweber,
        exportTemplateToZohoCampaigns,
        exportTemplateToZohoMail,
        exportTemplateToSendpulse,
        exportTemplateToIContact,
        integrationsLoading,

        sendyFormOpened,
        aweberFormOpened,
        icontactFormOpened,
        zohoCampaignsFormOpened,
        zohoMarketingFormOpened,
        zohoMailFormOpened,
        sendPulseFormOpened,
        sendyFormIntegration,
        aweberFormIntegration,
        zohoCampaignsFormIntegration,
        zohoMarketingFormIntegration,
        zohoMailFormIntegration,
        sendPulseFormIntegration,
        icontactFormIntegration,

        closeSendyForm,
        closeAweberForm,
        closeZohoCampaignsForm,
        closeZohoMarketingForm,
        closeZohoMailForm,
        closeSendPulseForm,
        closeIContactForm,
    };
};

export default useTemplateExports;
