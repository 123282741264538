<template>
    <BillingTabs tab-key="invoices">
        <div class="relative mb-12 mt-9">
            <div class="flex flex-col pb-5 lg:flex-row lg:justify-between">
                <DataTable
                    class="w-full"
                    title="Invoices"
                    :headers="headers"
                    :items="invoices"
                >
                    <template #item-issued_at="{ data }">
                        <div v-if="data" class="p-6 text-gray-600">
                            {{
                                format(
                                    new Date(data as string),
                                    'MMMM d, yyyy '
                                )
                            }}
                        </div>
                    </template>

                    <template #item-type="{ data }">
                        <div
                            class="flex flex-wrap justify-center gap-1 text-xs font-medium text-gray-700"
                        >
                            <div
                                v-if="data === 'PRO'"
                                class="rounded-md bg-orange-200 px-2 py-[3px]"
                            >
                                PRO Subscription
                            </div>
                            <div
                                v-if="data === 'Team'"
                                class="rounded-md bg-orange-300 px-2 py-[3px]"
                            >
                                Team Subscription
                            </div>
                            <div
                                v-else-if="data === 'Plugin'"
                                class="rounded-md bg-teal-200 px-2 py-[3px]"
                            >
                                Plugin Subscription
                            </div>
                            <div
                                v-else-if="data === 'Additional data'"
                                class="rounded-md bg-purple-200 px-2 py-[3px]"
                            >
                                Additional data
                            </div>
                            <div
                                v-else
                                class="rounded-md bg-green-200 px-2 py-[3px]"
                            >
                                Additional users
                            </div>
                        </div>
                    </template>

                    <template #item-amount_currency="{ data }">
                        <div class="flex justify-center p-6 text-gray-500">
                            {{ data }}
                        </div>
                    </template>

                    <template #item-status="{ data }">
                        <div class="flex justify-center p-6 text-gray-500">
                            <span v-if="!data">Issued</span>
                            <span v-else-if="data === 'stavUhr.castUhr'"
                                >Paid (partially)</span
                            >
                            <span v-else>Paid</span>
                        </div>
                    </template>

                    <template #item-custom_download="{ row }">
                        <div
                            class="flex cursor-pointer justify-center p-6 text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            @click="downloadInvoice(row.id)"
                        >
                            Download invoice
                        </div>
                    </template>

                    <template #item-custom_show="{ row }">
                        <AtomicButton
                            size="sm"
                            color="white"
                            @click="showInvoice(row.id)"
                        >
                            <template #icon>
                                <MagnifyingGlassIcon class="h-6 w-6" />
                            </template>
                        </AtomicButton>
                    </template>
                </DataTable>
            </div>

            <div v-if="showLegacyInvoices" class="text-sm text-gray-500">
                <span class="font-medium"
                    >These are not the invoices you are looking for?</span
                >
                Try our
                <inertia-link
                    class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    :href="route('billing.legacy-invoices.index')"
                >
                    Legacy Invoices</inertia-link
                >.
            </div>
        </div>
    </BillingTabs>
</template>
<script lang="ts" setup>
import IInvoice from '@/types/Invoices/IInvoice';
import { format } from 'date-fns';
import route from 'ziggy-js';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import usePdf from '@/composables/usePdf';

defineProps<{
    invoices: IPaginationData<IInvoice>;
}>();

const showLegacyInvoices = ref(false);

const { show, download } = usePdf();

onMounted(async () => {
    const res = await axios.get(route('billing.legacy-invoices-show.index'));
    if (res.data.success) {
        showLegacyInvoices.value = res.data.data.showLegacyInvoices;
    }
});

const headers: IDataTableHeader[] = [
    {
        text: 'Date',
        id: 'issued_at',

        sortable: true,
        sortableId: 'datVyst',
    },
    {
        text: 'Description',
        id: 'type',
        align: 'center',
        sortable: true,
        sortableId: 'popis',
    },
    {
        text: 'Invoice total',
        id: 'amount_currency',
        align: 'center',
        sortable: true,
        sortableId: 'sumCelkemMen',
    },
    {
        text: 'Status',
        id: 'status',
        align: 'center',
        sortable: true,
        sortableId: 'stavUhrK',
    },
    {
        text: '',
        id: 'custom_download',
    },
    {
        text: '',
        id: 'custom_show',
    },
];

const downloadInvoice = (id: number) => {
    const url = route('billing.invoices.download-pdf', { invoice: id });
    download(url, 'Invoice');
};

const showInvoice = (id: number) => {
    const url = route('billing.invoices.download-pdf', { invoice: id });
    show(url);
};
</script>

<style></style>
