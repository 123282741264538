<template>
    <div
        v-if="hasHelperTooltipDisabled"
        ref="tooltip"
        class="absolute top-0 left-0 hidden lg:block"
    >
        <div
            class="relative z-[1] w-full rounded-md bg-gray-700 py-1 pl-3 pr-5 font-medium text-white shadow-md"
        >
            <div class="flex justify-center p-1 text-xs leading-5">
                <slot />
            </div>
            <div
                class="absolute z-[2] m-auto h-[14px] w-[14px] rounded-[2px] bg-gray-700"
                :class="{
                    'left-0 right-0 bottom-[-6px] rotate-45':
                        placement === 'top',
                    'left-[-6px] top-0 bottom-0 rotate-[135deg]':
                        placement === 'right',
                    'left-0 right-0 top-[-6px] rotate-[225deg]':
                        placement === 'bottom',
                    'right-[-6px] top-0 bottom-0 rotate-[-45deg]':
                        placement === 'left',
                }"
            ></div>

            <button
                type="button"
                class="absolute top-1 right-1"
                :class="[`umami--click--hide-tooltip-${name}`]"
                @click="hideHelperTooltip"
            >
                <XMarkIcon
                    class="h-4 w-4 text-white transition duration-200 ease-in-out hover:text-gray-100"
                />
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IUser from '@/types/Members/IUser';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { computePosition, autoUpdate, offset } from '@floating-ui/vue';
import { PropType } from 'vue';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    placement: {
        type: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
        default: 'top',
    },
});

const tooltip = ref<Element | null>(null);
const popcorn = ref<Element | null>(null);

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const userProps = computed(() => globalProps.value.user as IUser);

const hasHelperTooltipDisabled = computed(() => {
    //@ts-expect-error
    return !userProps.value.helper_tooltips?.[props.name];
});

onMounted(() => {
    if (hasHelperTooltipDisabled) {
        popcorn.value = document.querySelector(
            `[data-helper-tooltip="${props.name}"]`
        );
        addEventListener('click', handleClickParent);
        updatePosition();
        cleanup();
    }
});

onUnmounted(() => {
    removeEventListener('click', handleClickParent);
});

const updatePosition = () => {
    if (popcorn.value && tooltip.value) {
        computePosition(popcorn.value, tooltip.value as HTMLElement, {
            placement: props.placement,
            middleware: [offset(10)],
        }).then(({ x, y }) => {
            Object.assign(tooltip.value?.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }
};

const handleClickParent = (event: Event) => {
    if (popcorn.value?.contains(event.target as Node)) {
        hideHelperTooltip();
    }
};

const cleanup = () => {
    if (popcorn.value && tooltip.value) {
        autoUpdate(popcorn.value, tooltip.value as HTMLElement, updatePosition);
    }
};

async function hideHelperTooltip() {
    try {
        const res = await axios.put(
            route('users.helper-tooltips.update', {
                name: props.name,
            })
        );

        if (res.status === 204) {
            router.reload({ only: ['user'] });
        }
    } catch (e) {
        () => undefined;
    }
}
</script>
