<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2595.7 270.73">
        <g id="Logo_Dark_Full">
            <path
                fill="#2a2941"
                d="M1278.93,6.42c-10.33,0-18.28,2.21-18.28,11.77v9.34c0,9.54,7.95,12,18.28,12s18.3-2.48,18.3-12V18.19C1297.23,8.63,1289.28,6.42,1278.93,6.42Z"
            />
            <path
                fill="#2a2941"
                d="M1286.37,58.24h-14.59A8.75,8.75,0,0,0,1263,67V192.24a8.77,8.77,0,0,0,8.75,8.76h14.59a8.77,8.77,0,0,0,8.75-8.76V67A8.75,8.75,0,0,0,1286.37,58.24Z"
            />
            <path
                fill="#2a2941"
                d="M860.6,56.78c-21.21,0-36.06,9.27-47.19,24.12C806,66.05,791.67,56.78,771.52,56.78c-21.47,0-33.66,11.67-42.67,24.12V67a8.74,8.74,0,0,0-8.76-8.74H705.52A8.74,8.74,0,0,0,696.77,67V192.24a8.75,8.75,0,0,0,8.75,8.76h14.56a8.75,8.75,0,0,0,8.76-8.76V120.68c0-21.47,12.19-34.74,29.95-34.74s28.36,12.2,28.36,33.94v72.36a8.76,8.76,0,0,0,8.76,8.76h14.57a8.75,8.75,0,0,0,8.75-8.76V120.68c0-22.54,12.46-34.74,30-34.74,18,0,28.36,11.94,28.36,34.2v72.1a8.75,8.75,0,0,0,8.74,8.76h14.58a8.76,8.76,0,0,0,8.75-8.76V110.33C909.64,76.13,891.09,56.78,860.6,56.78Z"
            />
            <path
                fill="#2a2941"
                d="M2213.87,6.42c-10.33,0-18.3,2.21-18.3,11.77v9.34c0,9.54,8,12,18.3,12s18.3-2.48,18.3-12V18.19C2232.17,8.63,2224.22,6.42,2213.87,6.42Z"
            />
            <path
                fill="#2a2941"
                d="M2221.3,58.24h-14.58A8.75,8.75,0,0,0,2198,67V192.24a8.76,8.76,0,0,0,8.75,8.76h14.58a8.74,8.74,0,0,0,8.73-8.76V67A8.73,8.73,0,0,0,2221.3,58.24Z"
            />
            <path
                fill="#2a2941"
                d="M454.66,43.62a62.53,62.53,0,0,1,38.17,12.83,8.39,8.39,0,0,0,11.05-.74L515.1,44.5A8.41,8.41,0,0,0,514.4,32a94.59,94.59,0,0,0-59.74-20.88,95.81,95.81,0,0,0,0,191.62,94.67,94.67,0,0,0,59.74-20.88,8.43,8.43,0,0,0,.69-12.53l-11.22-11.22a8.42,8.42,0,0,0-6-2.45,8.31,8.31,0,0,0-5.09,1.71,62.51,62.51,0,0,1-38.16,12.81,63.26,63.26,0,0,1,0-126.52Z"
            />
            <path
                fill="#2a2941"
                d="M1446.77,58.24H1432.2a8.75,8.75,0,0,0-8.76,8.75V81c-10.33-14.59-24.92-24.13-47.72-24.13-32.87,0-65,26-65,72.63v.54c0,46.39,31.81,72.64,65,72.64,23.32,0,37.91-10.71,47.72-23.7l.26-.33v13c0,24.12-18.63,46.21-44.61,46.21-16,0-30.6-7.39-37.92-19.83a8.75,8.75,0,0,0-12-2.93l-6.16,4.09a8.79,8.79,0,0,0-3,12c12,19.69,33.41,32.79,60.45,32.79,46.4,0,75-33.13,75-72.9V67A8.75,8.75,0,0,0,1446.77,58.24Zm-26.24,71.83c0,26.77-18.39,44.82-39.33,44.82-21.22,0-37.87-17.25-37.87-44.82v-.54c0-27,16.92-44.78,37.87-44.78s39.33,18,39.33,44.78Z"
            />
            <path
                fill="#2a2941"
                d="M2125.63,56.9c-21.76,0-34.74,11-43.76,24V67a8.74,8.74,0,0,0-8.75-8.74h-14.58a8.74,8.74,0,0,0-8.73,8.74V192.24a8.75,8.75,0,0,0,8.73,8.76h14.58c4.83,0,8.75-3.92,8.75-10.07V115.5a30.76,30.76,0,0,1,61.52,0v76.74a8.76,8.76,0,0,0,8.73,8.76h14.59a8.76,8.76,0,0,0,8.75-8.76V110.45C2175.46,78.11,2157.15,56.9,2125.63,56.9Z"
            />
            <path
                fill="#2a2941"
                d="M1553.84,56.9c-21.75,0-34.73,11-43.75,24V67a8.74,8.74,0,0,0-8.75-8.74h-14.58A8.74,8.74,0,0,0,1478,67V192.24a8.75,8.75,0,0,0,8.73,8.76h14.58c4.83,0,8.75-3.92,8.75-10.07V115.5a30.76,30.76,0,0,1,61.52,0v76.74a8.75,8.75,0,0,0,8.73,8.76h14.58a8.76,8.76,0,0,0,8.75-8.76V110.45C1603.67,78.11,1585.37,56.9,1553.84,56.9Z"
            />
            <path
                fill="#2a2941"
                d="M2591.11,58.55c-.39-.18-.72-.3-.91-.37-.35-.09-.72-.18-1.12-.26a42.15,42.15,0,0,0-47.2,22.86V67a8.75,8.75,0,0,0-8.75-8.75h-14.59a8.77,8.77,0,0,0-8.75,8.75V192.25a8.77,8.77,0,0,0,8.75,8.75h14.59a8.76,8.76,0,0,0,8.75-8.75v-53c0-42.82,22.34-50.52,35.24-49.47,2.53.2,4.75.54,6.64.8,0,0,.7.08,1.08.1a4.89,4.89,0,0,0,.54,0,6.19,6.19,0,0,0,6.07-5l3.22-20.6a5.65,5.65,0,0,0,.07-.93A6.19,6.19,0,0,0,2591.11,58.55Z"
            />
            <path
                fill="#2a2941"
                d="M1959.27,56.9c-43,0-74.48,32.61-74.48,72.63v.69c0,40,31.53,72.63,74.48,72.63s74.48-32.61,74.48-72.63v-.69C2033.75,89.51,2002.22,56.9,1959.27,56.9Zm41.92,73v.44c0,24.48-17,44.28-41.92,44.55-24.89-.27-41.93-20.07-41.93-44.55v-.89c0-24.48,17-44.28,41.93-44.55,24.88.27,41.92,20.07,41.92,44.55Z"
            />
            <path
                fill="#2a2941"
                d="M2418.67,56.9c-43,0-74.48,32.61-74.48,72.63v.69c0,40,31.53,72.63,74.48,72.63s74.48-32.61,74.48-72.63v-.69C2493.15,89.51,2461.62,56.9,2418.67,56.9Zm41.93,73v.44c0,24.48-17,44.28-41.93,44.55-24.88-.27-41.92-20.07-41.92-44.55v-.89c0-24.48,17-44.28,41.92-44.55,24.89.27,41.93,20.07,41.93,44.55Z"
            />
            <path
                fill="#2a2941"
                d="M1859.43,13H1844.8a8.79,8.79,0,0,0-7,3.52L1777.52,98.9l-60.28-82.34a8.78,8.78,0,0,0-7-3.52h-14.63a8.76,8.76,0,0,0-8.75,8.76V192.24a8.76,8.76,0,0,0,8.75,8.76h14.59a8.76,8.76,0,0,0,8.75-8.76V74l53.24,68.31.12.14.43.55h0a6.34,6.34,0,0,0,9.52,0h0l.43-.55.12-.14L1836.09,74V192.24a8.76,8.76,0,0,0,8.75,8.76h14.59a8.76,8.76,0,0,0,8.75-8.76V21.8A8.76,8.76,0,0,0,1859.43,13Z"
            />
            <polygon
                fill="#2a2941"
                points="1777.53 145.16 1777.52 145.16 1777.51 145.16 1777.53 145.16"
            />
            <path
                fill="#2a2941"
                d="M1012,56.9c-22.8,0-37.38,9.42-47.72,24V67a8.75,8.75,0,0,0-8.75-8.75H940.9A8.75,8.75,0,0,0,932.15,67V250.84a8.77,8.77,0,0,0,8.75,8.77h14.58a8.77,8.77,0,0,0,8.75-8.77V178.68c9.82,13,24.4,24,47.72,24,33.14,0,65-26.25,65-72.64v-.54C1076.9,82.89,1044.82,56.9,1012,56.9Zm32.39,73.17c0,26.77-18.39,44.82-39.34,44.82-21.21,0-37.86-17.25-37.86-44.82v-.54c0-27,16.92-44.78,37.86-44.78s39.34,18,39.34,44.78Z"
            />
            <path
                fill="#2a2941"
                d="M675.61,178.34h0a6.18,6.18,0,0,1-6.18-6.18V67a8.75,8.75,0,0,0-8.75-8.75H646.1A8.75,8.75,0,0,0,637.35,67V80.9c-10.34-14.58-24.92-24-47.72-24-32.87,0-65,26-65,72.63v.54c0,46.39,31.81,72.64,65,72.64,24,0,41-10.77,48.25-24A30.76,30.76,0,0,0,667.47,201h8.14a6.19,6.19,0,0,0,6.18-6.19v-10.3A6.19,6.19,0,0,0,675.61,178.34Zm-41.12-48.27c0,26.77-18.4,44.82-39.34,44.82-21.21,0-37.86-17.25-37.86-44.82v-.54c0-27,16.92-44.78,37.86-44.78s39.34,18,39.34,44.78Z"
            />
            <path
                fill="#2a2941"
                d="M1241.52,178.34h0a6.19,6.19,0,0,1-6.19-6.18V67a8.74,8.74,0,0,0-8.75-8.75H1212a8.75,8.75,0,0,0-8.75,8.75V80.9c-10.34-14.58-24.92-24-47.72-24-32.87,0-65,26-65,72.63v.54c0,46.39,31.81,72.64,65,72.64,24,0,41-10.77,48.25-24A30.76,30.76,0,0,0,1233.37,201h8.15a6.19,6.19,0,0,0,6.18-6.19v-10.3A6.18,6.18,0,0,0,1241.52,178.34Zm-41.12-48.27c0,26.77-18.4,44.82-39.34,44.82-21.21,0-37.87-17.25-37.87-44.82v-.54c0-27,16.93-44.78,37.87-44.78s39.34,18,39.34,44.78Z"
            />
            <path
                fill="#2a2941"
                d="M2328.32,58.24h-30.46V19.17h0a6.11,6.11,0,0,0-1.12-3.5,6.18,6.18,0,0,0-8.61-1.52h0l-15.23,10.69a16.26,16.26,0,0,0-7.12,13.46V58.24h-10.33a8.77,8.77,0,0,0-8.77,8.74v9a8.77,8.77,0,0,0,8.77,8.75h10.33v77c0,30.75,16.68,41.09,40.3,41.09,12.51,0,25.37-4.83,25.37-4.83s4.35-1.31,3.25-6.38c-.37-1.75-2.55-13.73-2.55-13.73-.54-2.95-1.75-5.26-5.92-4.64a66.86,66.86,0,0,1-11.15.95c-10.87,0-17.21-5-17.21-17.5v-72h30.46a8.74,8.74,0,0,0,8.75-8.75V67A8.74,8.74,0,0,0,2328.32,58.24Z"
            />
            <path
                fill="#7856ff"
                d="M257.17,30.17a9.8,9.8,0,0,0-13.67-2.4L2.72,196.36a9.78,9.78,0,0,0,8,4.18H249.14a9.81,9.81,0,0,0,9.81-9.82V35.64a9.81,9.81,0,0,0-1.78-5.47"
            />
            <path
                fill="#7856ff"
                d="M16.4,27.76A9.8,9.8,0,0,0,1,35.64V191L115.59,97.11Z"
            />
        </g>
    </svg>
</template>
