<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">
        <template #header>
            <inertia-link :href="route('kiosk.teams.index')">
            <AtomicButton color="yellow" class="mt-4" size="sm">
                Back to All Teams
                <template #icon>
                    <BackspaceIcon
                        class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <TeamTabsProfile :selected-team="selectedTeam" />
        </template>

        <slot />

    </BasicTabs>
       
</template>

<script lang="ts" setup>
import {
    BackspaceIcon,
    PuzzlePieceIcon,
    UserGroupIcon,
    DocumentTextIcon,
    DocumentIcon,
    AdjustmentsVerticalIcon,
    ShieldExclamationIcon,
} from '@heroicons/vue/24/solid';

import { CreditCardIcon, DocumentPlusIcon } from '@heroicons/vue/24/outline';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

interface TeamTabProps {
    tabKey: string;
    selectedTeam: ICurrentTeam;
}

const props = withDefaults(defineProps<TeamTabProps>(), {
    tabKey: 'members',
});

const tabs: TabItem[] = [
    {
        title: 'Members',
        key: 'members',
        icon: UserGroupIcon,
        url: route('kiosk.teams.members.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Features',
        key: 'feature-management',
        icon: AdjustmentsVerticalIcon,
        url: route('kiosk.teams.feature-management.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Blocked Actions',
        key: 'team-blocked-actions',
        icon: ShieldExclamationIcon,
        url: route('kiosk.teams.blocked-actions.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'API tokens',
        key: 'team-api-tokens',
        icon: PuzzlePieceIcon,
        url: route('kiosk.teams.api-tokens.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Invoices',
        key: 'team-invoices',
        icon: DocumentTextIcon,
        url: route('kiosk.teams.invoices.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Legacy Invoices',
        key: 'legacy-invoices',
        icon: DocumentIcon,
        url: route('kiosk.teams.legacy-invoices.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Transactions',
        key: 'transactions',
        icon: CreditCardIcon,
        url: route('kiosk.teams.transactions.index', {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: 'Payment requests',
        key: 'payment-requests',
        icon: DocumentPlusIcon,
        url: route('kiosk.teams.payment-requests.index', {
            team: props.selectedTeam.id,
        }),
    }
];
</script>
