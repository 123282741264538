<template>
    <div class="relative flex items-center gap-2">
        <div class="h-3 w-3 rounded-full" :class="statusClass"></div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
    status: {
        type: String,
        required: true,
    },
});

const statusClass = computed(() => {
    switch (props.status) {
        case 'completed':
            return 'bg-green-500 shadow-green-700 shadow-even';
        case 'processing':
            return 'bg-yellow-500 shadow-yellow-700 shadow-even';
        case 'failed':
            return 'bg-red-500 shadow-red-700 shadow-even';
        default:
            return 'bg-gray-500 0';
    }
});
</script>
