<template>
    <div
        v-click-away="onClickAway"
        class="group relative"
        @keydown.esc="isOpen = false"
    >
        <button
            :disabled="disabled"
            type="button"
            class="flex h-10 w-full items-center justify-between rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-600 transition duration-100 ease-in-out hover:text-gray-800 hover:shadow-button focus:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-white"
            @click="isOpen = !isOpen"
        >
            <slot name="left-icon" />

            <p v-if="modelValue === ''">
                {{ placeholder }}
            </p>
            <p v-else>{{ modelValue }}</p>

            <div
                class="text-gray-500 group-hover:text-gray-600 group-focus:text-gray-600 group-disabled:text-white"
            >
                <slot name="right-icon" />
            </div>
        </button>

        <transition
            enter-active-class="transition duration-100 ease-out transform"
            enter-from-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in transform"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <div
                v-if="isOpen"
                ref="target"
                class="absolute z-20 mt-1 w-56 rounded-md bg-white shadow-lg"
            >
                <ul
                    v-click-away="onClickAway"
                    tabindex="-1"
                    role="listbox"
                    class="overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <li
                        v-for="(item, i) in items"
                        :key="i"
                        tabindex="0"
                        class="group relative cursor-pointer select-none py-2 pl-8 pr-4 text-base text-gray-700 hover:bg-gray-700 hover:text-white focus:outline-none"
                        role="option"
                        @click="select(item.id)"
                    >
                        <p
                            class="block truncate font-normal"
                            :class="{
                                'text-xs': type === 'medium',
                            }"
                        >
                            {{ item.title }}
                        </p>

                        <span
                            v-if="item.help"
                            class="whitespace-normal text-xs font-normal leading-normal text-gray-500 group-hover:text-white"
                            >{{ item.help }}</span
                        >

                        <span
                            class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                        >
                            <svg
                                v-show="isSelected(item.title)"
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import IDropdownItem from '@/types/Atomic/IDropdownItem';

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    items: {
        type: Array as PropType<IDropdownItem[]>,
        required: true,
    },
    placeholder: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: '',
    },
    type: {
        type: String as () => 'medium' | 'full' | 'ellipsis' | undefined,
        validator(value: string | undefined) {
            return (
                value === undefined ||
                ['medium', 'full', 'ellipsis'].includes(value)
            );
        },
        default: 'medium',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);

const onClickAway = () => {
    isOpen.value = false;
};

const isSelected = (value: string) => {
    if (props.modelValue === '') {
        return false;
    }

    if (props.modelValue === value || props.modelValue.includes(value)) {
        return true;
    }
};

const select = (value: string) => {
    isOpen.value = false;
    emit('update:modelValue', value);
};
</script>

<style scoped></style>
