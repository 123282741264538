<template>
    <div>
        <Modal size="sm" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">Invite team member</div>
            </template>

            <template #body>
                <div class="p-6">
                    <TextInput
                        v-model="state.form.email"
                        placeholder="Enter e-mail address"
                        label="E-mail address"
                        :error="getError('email')"
                        required
                        @blur="v$.form.email.$touch()"
                    />

                    <SelectInput
                        ref="SelectInput"
                        v-model="v$.form.role.$model"
                        placeholder="Choose new member's team role"
                        class="mt-6"
                        :data="data"
                        :error="getError('role')"
                        required
                        label="New member’s team role"
                    >
                    </SelectInput>

                    <div
                        v-if="
                            v$.form.role.$model &&
                            v$.form.role.$model !== 'viewer'
                        "
                        class="mt-6 text-sm text-gray-500"
                    >
                        Team members with Administrator or Editor roles are
                        billed aditionally according to your TOPOL Pro plan.
                        Billing starts only when new team member accepts your
                        invitation.
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between p-6 border-t border-gray-200">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>

                    <AtomicButton
                        :disabled="loading || v$.form.$invalid"
                        color="yellow"
                        size="md"
                        @click="sendInvite"
                    >
                        Invite team member
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import route from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import IInputErrors from '@/types/Atomic/IInputErrors';

const emit = defineEmits(['close', 'update:title']);

const state = reactive({
    form: {
        email: '',
        role: '',
    },
});
const rules = {
    form: {
        email: {
            required,
            email,
        },
        role: {
            required,
        },
    },
};
const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);
const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const data = computed(() => {
    if(teamProps.value.personal_team) {
        return [
            { title: 'Viewer', value: 'viewer' },
        ];
    }

    return [
        { title: 'Administrator', value: 'admin' },
        { title: 'Editor', value: 'editor' },
        { title: 'Viewer', value: 'viewer' },
    ]
}
);

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const sendInvite = () => {
    v$.value.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route('members.store'), state.form, {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Invitation sent',
                    type: 'info',
                });
                emit('close');
            },
            onError: (err) => {
                create({
                    title: 'Invitation not sent',
                    text: 'There was an error sending your invitation.',
                    type: 'error',
                });
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};


</script>

<style></style>
