<template>
    <div class="py-12">
        <inertia-link
            :href="
                route('kiosk.users.notifications.index', {
                    user: selectedUser.id,
                })
            "
        >
            <AtomicButton color="yellow" size="sm">
                Back to Notifications
                <template #icon>
                    <BackspaceIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <inertia-link
            :href="
                route('kiosk.users.notifications.edit', {
                    notification: notification.id,
                    user: selectedUser.id,
                })
            "
        >
            <AtomicButton class="mx-2 mt-4" size="sm" color="white">
                Edit
                <template #icon>
                    <PencilSquareIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <AtomicButton
            size="sm"
            color="red"
            @click.prevent="deleteNotification(notification)"
        >
            Delete
            <template #icon>
                <TrashIcon class="mr-2 h-6 w-6" />
            </template>
        </AtomicButton>

        <h1 class="mt-4 text-2xl font-medium text-gray-800">
            {{ notification.title }}
        </h1>

        <div
            class="mt-4 inline-flex rounded-md px-2 py-[3px] text-xs font-medium text-gray-700"
            :class="{
                'bg-blue-200': notification.type === 'info',
                'bg-indigo-200': notification.type === 'news',
                'bg-gray-200': notification.type === 'report',
                'bg-red-300': notification.type === 'critical',
                'bg-orange-300': notification.type === 'warning',
            }"
        >
            {{ notification.type }}
        </div>

        <div class="mt-4 flex text-gray-800">
            <h3 class="mr-2">Created:</h3>
            <div v-if="notification.created_at">
                {{
                    format(
                        new Date(notification.created_at),
                        "EEEE, d. L. yyyy 'at' H:m"
                    )
                }}
            </div>
        </div>

        <div></div>

        <div
            class="mt-4 text-base text-gray-800"
            v-html="notification.data"
        ></div>

        <a v-if="notification.button_text" :href="notification.button_url">
            <AtomicButton size="lg" color="purple" class="mt-2">
                {{ notification.button_text }}
            </AtomicButton>
        </a>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import route from 'ziggy-js';
import {
    PencilSquareIcon,
    TrashIcon,
    BackspaceIcon,
} from '@heroicons/vue/24/outline';

import IAnnouncement from '@/types/Announcements/IAnnouncement';
import useConfirm from '@/composables/useConfirm';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import IUser from '@/types/Members/IUser';

const props = defineProps<{
    selectedUser: IUser;
    notification: IAnnouncement;
}>();

const { show } = useConfirm();

const deleteNotification = async (notification: IAnnouncement) => {
    const confirmed = await show({
        header: `Delete notification`,
        message: `Are you sure you want to delete this notification?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.users.notifications.destroy', {
                notification: notification.id,
                user: props.selectedUser.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Notification deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Notification not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>

<style></style>
