<template>
    <div class="relative">
        <Actions
            :close-event="!isHovered"
            :items="templateActions"
            activator-type="dots"
            :disabled="disabled"
        />

        <ExportToSendy
            v-if="sendyFormOpened"
            :integration="sendyFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeSendyForm"
        />

        <ExportToAWeber
            v-if="aweberFormOpened"
            :integration="aweberFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeAweberForm"
        />

        <ExportToZohoCampaigns
            v-if="zohoCampaignsFormOpened"
            :integration="zohoCampaignsFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeZohoCampaignsForm"
        />

        <ExportToZohoMail
            v-if="zohoMailFormOpened"
            :integration="zohoMailFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeZohoMailForm"
        />

        <ExportToSendPulse
            v-if="sendPulseFormOpened"
            :integration="sendPulseFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeSendPulseForm"
        />

        <ExportToIContact
            v-if="icontactFormOpened"
            :integration="icontactFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeIContactForm"
        />

        <ExportToClipboardModal
            v-if="exportToClipboardModalShow"
            :html="htmlToClipboard"
            @close="closeExportToClipboardModal"
        />
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref } from 'vue';

import IActionNode from '@/types/Atomic/IActions';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import ITemplate from '@/types/Templates/ISimpleTemplate';
import IFolder from '@/types/Templates/IFolder';

import Actions from '@atomic/Actions/Action.vue';
import ExportToSendy from './ExportToSendy.vue';

import useConfirm from '@/composables/useConfirm';
import useTemplates from '../composables/useTemplates';
import useFolders from '@/Pages/Templates/composables/useFolders';
import useExportNodes from '../composables/useExportNodes';
import { create } from '@composables/useNotifications';

import { usePage } from '@inertiajs/vue3';
import axios from 'axios';
import { saveAs } from 'file-saver';
import route from 'ziggy-js';
import { IIntegration } from '@/types/Integrations/IIntegration';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';

const props = defineProps({
    template: {
        type: Object as PropType<ITemplate | IPremadeTemplate>,
        required: true,
    },
    isHovered: {
        type: Boolean,
        default: false,
    },
    integrations: {
        type: Array as PropType<IIntegration<unknown>[]>,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const {
    sendyFormOpened,
    aweberFormOpened,
    icontactFormOpened,
    zohoCampaignsFormOpened,
    zohoMailFormOpened,
    sendPulseFormOpened,
    sendyFormIntegration,
    aweberFormIntegration,
    zohoCampaignsFormIntegration,
    zohoMailFormIntegration,
    sendPulseFormIntegration,
    icontactFormIntegration,

    closeSendyForm,
    closeAweberForm,
    closeZohoCampaignsForm,
    closeZohoMailForm,
    closeSendPulseForm,
    closeIContactForm,
} = useTemplateExports(props.template.id, props.template.name);

const foldersAndTemplates = useFoldersAndTemplates();

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const { allTeams } = useTeamsAndMembers();
const { show } = useConfirm();
const { moveTemplateToFolder, duplicateTemplateToFolder } = useTemplates();
const { folders, currentPath } = useFolders();

const { createIntegrationsNode } = useExportNodes(props.integrations);
const exportToClipboardModalShow = ref(false);
const htmlToClipboard = ref('');

const createFolderActionNode = (folders: IFolder[]): IActionNode => {
    const teamNodes: IActionNode[] = [];
    const moveToTeam = {
        name: 'Move to account',
        children: teamNodes,
    };
    const nodes: IActionNode[] = [];
    if (currentPath.value.length) {
        nodes.push({
            name: 'My templates',
            onClick: async () => {
                await moveTemplateToFolder(props.template.id, undefined);
            },
        });
    }
    if (folders.length) {
        folders.forEach((f) => {
            nodes.push({
                name: f.name,
                onClick: async () => {
                    await moveTemplateToFolder(props.template.id, f.id);
                },
            });
        });
    }
    if (!currentPath.value.length && !folders.length) {
        nodes.push({
            name: 'You have no folders yet',
        });
    }
    if (allTeams.value.length > 1) {
        allTeams.value.forEach((f: ICurrentTeam) => {
            if (f.id !== teamProps.value.id) {
                teamNodes.push({
                    name: f.name,
                    onClick: async () => {
                        try {
                            await axios.post(
                                route('templates.move-to-team', {
                                    template: props.template.id,
                                    team_id: f.id,
                                })
                            );

                            //delete from foldersAndTemplates.templates.value
                            const id =
                                foldersAndTemplates.templates.value.findIndex(
                                    (t) => props.template.id === t.id
                                );
                            foldersAndTemplates.templates.value.splice(id, 1);

                            if (foldersAndTemplates.searchingMode.value) {
                                await foldersAndTemplates.resumeSearch();
                            }

                            create({
                                title: 'Template moved',
                                type: 'info',
                            });
                        } catch (err) {
                            () => undefined;
                        }
                    },
                });
            }
        });
        nodes.push(moveToTeam);
    }
    const node: IActionNode = {
        name: 'Move to folder',
        children: nodes,
    };
    return node;
};

const createDuplicateNode = (): IActionNode => {

    const teamNodes: IActionNode[] = [];
    const folderNodes: IActionNode[] = [];
    let duplicateToTeam = {
        name: 'Duplicate to team',
        children: teamNodes,
    };
    let duplicateToFolder = {
        name: 'Duplicate to folder',
        children: folderNodes,
    };
    
    const duplicateTeamNodes: IActionNode[] = [
        {
            name: 'Duplicate',
            async onClick() {
                try {
                    const templateAxiosRes = await axios.post(
                        route('templates.duplicate', {
                            template: props.template.id,
                        })
                    );

                    foldersAndTemplates.templates.value.push(
                        templateAxiosRes.data.data
                    );

                    if (foldersAndTemplates.searchingMode.value) {
                        await foldersAndTemplates.resumeSearch();
                    }
                    create({
                        title: 'Template duplicated',
                        type: 'info',
                    });
                } catch (err) {
                    () => undefined;
                }
            },
        },
        duplicateToTeam,
        duplicateToFolder,
    ];
    const duplicateNode: IActionNode = {
        name: 'Duplicate...',
        children: duplicateTeamNodes,
    };
    if (allTeams.value.length > 1) {
        allTeams.value.forEach((f: ICurrentTeam) => {
            if (f.id !== teamProps.value.id) {
                teamNodes.push({
                    name: f.name,
                    onClick: async () => {
                        try {
                            await axios.post(
                                route('templates.duplicate-to-team', {
                                    template: props.template.id,
                                    team_id: f.id,
                                })
                            );
                            create({
                                title: 'Template duplicated',
                                type: 'info',
                            });
                        } catch (err) {
                            () => undefined;
                        }
                    },
                });
            }
        });
    }

    if (currentPath.value.length) {
        folderNodes.push({
            name: 'My templates',
            onClick: async () => {
                await duplicateTemplateToFolder(props.template.id)
            },
        });
    }
    if (folders.value.length) {
        folders.value.forEach((folder) => {
            folderNodes.push({
                name: folder.name,
                onClick: async () => {
                   await duplicateTemplateToFolder(props.template.id, folder.id)
                },
            });
        });
    }
    if (!currentPath.value.length && !folders.value.length) {
        folderNodes.push({
            name: 'You have no folders yet',
        });
    }

    if(allTeams.value.length === 1) {
       teamNodes.push({
            name: 'You have no teams yet',
        });
    }
    return duplicateNode;
};

const changeTemplateStatusTo = async (statusId: string) => {
    try {
        const templateAxiosRes = await axios.put(
            route('templates.update', {
                template: props.template.id,
            }),
            {
                name: props.template.name || 'Untitled',
                status: statusId,
            }
        );
        const id = foldersAndTemplates.templates.value.findIndex(
            (t) => props.template.id === t.id
        );

        foldersAndTemplates.templates.value.splice(
            id,
            1,
            templateAxiosRes.data.data
        );

        if (foldersAndTemplates.searchingMode.value) {
            await foldersAndTemplates.resumeSearch();
        }
        create({
            title: 'Template status updated',
            type: 'info',
        });
    } catch (err) {
        () => undefined;
    }
};

const clearTemplateStatus = async () => {
    try {
        const templateAxiosRes = await axios.put(
            route('templates.update', {
                template: props.template.id,
            }),
            {
                name: props.template.name || 'Untitled',
                status: null,
            }
        );

        const id = foldersAndTemplates.templates.value.findIndex(
            (t) => props.template.id === t.id
        );

        foldersAndTemplates.templates.value.splice(
            id,
            1,
            templateAxiosRes.data.data
        );

        if (foldersAndTemplates.searchingMode.value) {
            await foldersAndTemplates.resumeSearch();
        }

        create({
            title: 'Template status updated',
            type: 'info',
        });
    } catch (err) {
        () => undefined;
    }
};

const closeExportToClipboardModal = () => {
    exportToClipboardModalShow.value = false;
    htmlToClipboard.value = '';
};

const templateActions = computed((): IActionNode[] => {
    const nodes: IActionNode[] = [
        {
            name: 'Rename',
            async onClick() {
                const newNameResponse = await show({
                    header: 'Rename',
                    hasInput: true,
                    inputData: {
                        label: 'Template Name',
                        defaultValue: props.template.name,
                    },
                    confirm: 'Rename',
                });
                if (
                    typeof newNameResponse === 'string' &&
                    newNameResponse.trim().length > 0
                ) {
                    try {
                        const templateAxiosRes = await axios.put(
                            route('templates.update', {
                                template: props.template.id,
                            }),
                            {
                                name: newNameResponse,
                                status: props.template.status,
                            }
                        );

                        const id =
                            foldersAndTemplates.templates.value.findIndex(
                                (t) => props.template.id === t.id
                            );

                        foldersAndTemplates.templates.value.splice(
                            id,
                            1,
                            templateAxiosRes.data.data
                        );

                        if (foldersAndTemplates.searchingMode.value) {
                            await foldersAndTemplates.resumeSearch();
                        }

                        create({
                            title: 'Template name updated',
                            type: 'info',
                        });
                    } catch (err) {
                        () => undefined;
                    }
                }
            },
        },
        {
            name: 'Set status',
            children: [
                {
                    name: 'Draft',
                    isChecked: props.template.status === 'draft',
                    async onClick() {
                        if (props.template.status === 'draft') {
                            await clearTemplateStatus();
                        } else {
                            await changeTemplateStatusTo('draft');
                        }
                    },
                },
                {
                    name: 'In Review',
                    isChecked: props.template.status === 'in_review',
                    async onClick() {
                        if (props.template.status === 'in_review') {
                            await clearTemplateStatus();
                        } else {
                            await changeTemplateStatusTo('in_review');
                        }
                    },
                },
                {
                    name: 'Sent',
                    isChecked: props.template.status === 'sent',
                    async onClick() {
                        if (props.template.status === 'sent') {
                            await clearTemplateStatus();
                        } else {
                            await changeTemplateStatusTo('sent');
                        }
                    },
                },
            ],
        },
        createDuplicateNode(),
        {
            name: 'Download',
            children: [
                {
                    name: 'JSON',
                    async onClick() {
                        const res = await axios.post(
                            route('templates.downloads.json', {
                                template: props.template.id,
                            })
                        );
                        var fileToSave = new Blob([JSON.stringify(res.data)], {
                            type: 'application/json',
                        });

                        const name = props.template.name
                            ? props.template.name.replace(/\./g, '')
                            : 'untitled_template';

                        saveAs(fileToSave, name);
                    },
                },
                {
                    name: 'HTML',
                    children: [
                        {
                            name: 'HTML and images',
                            async onClick() {
                                try {
                                    const res = await axios.post(
                                        route(
                                            'templates.downloads.self-hosted-html',
                                            {
                                                template: props.template.id,
                                            }
                                        )
                                    );
                                    let name = props.template.name;
                                    if (name == null) {
                                        name = 'untitled_template';
                                    }
                                    if (res.data.success) {
                                        saveAs(res.data.data, name + '.zip');
                                    } else {
                                        create({
                                            title: 'There was an error downloading the template',
                                            type: 'error',
                                        });
                                    }
                                } catch (err) {
                                    create({
                                        title: 'There was an error downloading the template',
                                        type: 'error',
                                    });
                                }
                            },
                        },
                        {
                            name: 'HTML with hosted images',
                            async onClick() {
                                const res = await axios.post(
                                    route('templates.downloads.hosted-html', {
                                        template: props.template.id,
                                    })
                                );

                                if (res.data) {
                                    const fileToSave = new Blob([res.data], {
                                        type: 'text/html;charset=utf-8',
                                    });

                                    const name = props.template.name
                                        ? props.template.name.replace(/\./g, '')
                                        : 'untitled_template';

                                    saveAs(fileToSave, name);
                                } else {
                                    create({
                                        title: 'There was an error with the template content. Try opening the template in the editor, save it and then try again.',
                                        type: 'error',
                                    });
                                }
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: 'Copy HTML',
            async onClick() {
                const res = await axios.post(
                    route('templates.downloads.hosted-html', {
                        template: props.template.id,
                    })
                );
                if (res.data) {
                    exportToClipboardModalShow.value = true;
                    htmlToClipboard.value = res.data;
                } else {
                    create({
                        title: 'There was an error with the template content. Try opening the template in the editor, save it and then try again.',
                        type: 'error',
                    });
                }
            },
        },
        {
            name: 'Delete template',
            danger: true,
            async onClick() {
                const templateName = props.template.name
                    ? props.template.name
                    : 'Untitled';
                const confirmed = await show({
                    header: 'Delete template',
                    message: `Do you really want to delete the template: ${templateName}?`,
                    type: 'danger',
                });
                if (confirmed) {
                    try {
                        await axios.delete(
                            route('templates.delete', {
                                template: props.template.id,
                            })
                        );

                        const id =
                            foldersAndTemplates.templates.value.findIndex(
                                (t) => props.template.id === t.id
                            );
                        foldersAndTemplates.templates.value.splice(id, 1);

                        if (foldersAndTemplates.searchingMode.value) {
                            await foldersAndTemplates.resumeSearch();
                        }
                        create({
                            title: 'Template deleted',
                            text: 'Your template has been successfully deleted.',
                            type: 'success',
                        });
                    } catch (err) {
                        () => undefined;
                    }
                }
            },
        },
    ];
    const moveTemplateToFolderNode = createFolderActionNode(folders.value);
    if (!foldersAndTemplates.searchingMode.value) {
        nodes.splice(1, 0, moveTemplateToFolderNode);
    }
    const integrationNode = createIntegrationsNode(
        props.template.id,
        props.template.name
    );
    if (integrationNode !== undefined) {
        nodes.splice(4, 0, integrationNode);
    }

    if (globalProps.value.isAdmin) {
        nodes.splice(7,0,  {
            name: 'Create premade template',
            onClick() {
                router.get(route('kiosk.premade-templates.create', {
                    template: props.template.id
                }))
            },
        },)
    }
    return nodes;
});
</script>

<style></style>
