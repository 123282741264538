<template>
    <EditorPreferencesTabs :features="features" tab-key="merge-tags">
        <form class="mt-[44px] mb-24" @submit.prevent="submit">
            <SmartMergeTagsEnabled v-model="form.smart_merge_tags" />

            <SmartMergeTagsSyntax
                v-model:start="form.smart_merge_tags_syntax_start"
                v-model:end="form.smart_merge_tags_syntax_end"
                :disabled="!form.smart_merge_tags"
            />

            <Tags
                v-model="form.merge_tags"
                :merge-tags="form.merge_tags"
                :mailchimp-integrations="mailchimpIntegrations"
            />

            <div class="flex flex-wrap justify-end gap-4 mt-4 sm:flex-nowrap">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="md"
                    :disabled="loading || !form.isDirty"
                >
                    Save changes
                </AtomicButton>
            </div>
        </form>
    </EditorPreferencesTabs>
</template>
<script lang="ts" setup>
import { IIntegration, IMailchimp } from '@/types/Integrations/IIntegration';
import IEditorSettings from '@/types/Settings/IEditorSettings';
import { useForm } from '@inertiajs/vue3';
import { PropType } from 'vue';

const props = defineProps({
    settings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
    mailchimpIntegrations: {
        type: Array as PropType<IIntegration<IMailchimp>[]>,
        required: true,
    },
    features: {
        type: Object,
        default: null,
    },
});

const loading = ref(false);

const mergeTags = ref([
    {
        name: 'Basic merge tags',
        items: [
            {
                value: '*|FIRST_NAME|*',
                text: 'First name',
                label: "Customer's first name",
            },
            {
                value: '*|LAST_NAME|*',
                text: 'Last name',
                label: "Customer's last name",
            },
            {
                value: '*|EMAIL|*',
                text: 'Email',
                label: "Customer's email",
            },
            {
                value: '*|COMPANY|*',
                text: 'Company',
                label: "Customer's company",
            },
            {
                value: '*|STREET|*',
                text: 'Street',
                label: "Customer's street",
            },
            {
                value: '*|CITY|*',
                text: 'City',
                label: "Customer's city",
            },
            {
                value: '*|ZIP_CODE|*',
                text: 'Zip Code',
                label: "Customer's zip code",
            },
            {
                value: '*|COUNTRY|*',
                text: 'Country',
                label: "Customer's country",
            },
            {
                value: '*|PHONE|*',
                text: 'Phone',
                label: "Customer's phone",
            },
            {
                value: '*|TITLE_BEFORE_NAME|*',
                text: 'Title before name',
                label: "Customer's title before name",
            },
            {
                value: '*|TITLE_AFTER_NAME|*',
                text: 'Title after name',
                label: "Customer's title after name",
            },
        ],
    },
]);

const form = useForm({
    smart_merge_tags: props.settings?.smart_merge_tags ?? false,
    smart_merge_tags_syntax_start:
        props.settings?.smart_merge_tags_syntax_start ?? '*|',
    smart_merge_tags_syntax_end:
        props.settings?.smart_merge_tags_syntax_end ?? '|*',
    merge_tags:
        props.settings?.merge_tags.length > 0
            ? props.settings?.merge_tags
            : mergeTags.value,
});

const submit = () => {
    form.put(route('editor-preferences.merge-tags.update'), {
        preserveScroll: true,
        onStart: () => {
            loading.value = true;
        },
        onFinish: () => {
            loading.value = false;
        },
        onSuccess: () => {
            create({
                title: 'Merge tags settings updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Merge tags settings not updated',
                text: 'There was an error updating merge tags.',
                type: 'error',
            });
        },
    });
};
</script>
```
