<template>
    <IntegrationModal
        title="GetResponse"
        documentation-url="https://support.topol.io/en/articles/8630999-export-to-getresponse"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createIntegration"
        @edit="updateIntegration"
    >
        <template #inputs>
            <div class="mb-6 w-full">
                <TextInput
                    v-model="v$.name.$model"
                    class="mt-6"
                    placeholder="Enter your preferred integration name"
                    label="Name"
                    :error="getError('name')"
                />

                <TextInput
                    v-model="v$.api_key.$model"
                    class="mt-6 w-full"
                    placeholder="Your GetResponse API key"
                    label="API key"
                    required
                    :error="getError('api_key')"
                />

                <CheckboxInput
                    v-model:checked="state.max_user"
                    class="mt-6"
                    size="md"
                    label="I am a GetResponse MAX user"
                />

                <div v-if="state.max_user" class="mt-6">
                    <div
                        class="text-gray-70 text-sm font-medium leading-normal"
                    >
                        Endpoint URL:
                    </div>

                    <div
                        class="mt-2 flex flex-wrap justify-start gap-4 text-sm"
                    >
                        <RadioInput
                            v-model="state.max_url"
                            option="pl"
                            name="pl"
                            label="https://api3.getresponse360.pl/v3"
                            size="md"
                        />
                        <RadioInput
                            v-model="state.max_url"
                            option="com"
                            name="com"
                            label="https://api3.getresponse360.com/v3"
                            size="md"
                        />
                    </div>
                </div>
            </div>
        </template>
    </IntegrationModal>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength, required, requiredIf } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IGetResponse, IIntegration } from '@/types/Integrations/IIntegration';
import useIntegrations from '../../composables/useIntegrations';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<IGetResponse>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});

const { getresponseIntegrationModalOpened } = useIntegrations();

const state = reactive({
    name: props.integration?.name || '',
    api_key: '',
    max_user: props.integration?.data.max_user || false,
    max_url: props.integration?.data.max_url || undefined,
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
    api_key: {
        required,
        maxLength: maxLength(255),
    },
    max_user: {
        required,
    },
    max_url: {
        requiredIf: requiredIf(() => state.max_user),
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};

const createIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;

        router.post(
            route('integrations.get-response.store'),
            {
                name: state.name,
                api_key: state.api_key,
                max_user: state.max_user,
                max_url: state.max_url,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Integration added',
                        text: 'GetResponse integration has been successfully added.',
                        type: 'success',
                    });
                    getresponseIntegrationModalOpened.value = false;
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not added',
                        text: 'There was an error adding GetResponse integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('integrations.get-response.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
                api_key: state.api_key,
                max_user: state.max_user,
                max_url: state.max_url,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating GetResponse integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
