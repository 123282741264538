<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">
                <span v-if="tag.item"> Update </span>
                <span v-else> Create </span>
                merge tag
            </div>
        </template>

        <template #body>
            <div class="mb-6 p-6">
                <SelectInput
                    v-if="!tag.item"
                    v-model="type"
                    class="mb-6"
                    placeholder="Select type"
                    label="Type"
                    :data="types"
                />

                <div v-if="tag.hasItems || type === 'nested'">
                    <TextInput
                        v-model="v$.data.name.$model"
                        label="Name"
                        placeholder="Name"
                        required
                        :error="getError('name')"
                    />
                </div>
                <div v-else class="flex flex-col gap-6">
                    <TextInput
                        v-model="v$.data.value.$model"
                        label="Value"
                        placeholder="Value"
                        required
                        :error="getError('value')"
                    >
                        <template #help> Text to be inserted </template>
                    </TextInput>
                    <TextInput
                        v-model="v$.data.text.$model"
                        label="Text"
                        placeholder="Text"
                        required
                        :error="getError('text')"
                    >
                        <template #help> Shown text in the menu </template>
                    </TextInput>
                    <TextInput
                        v-model="v$.data.label.$model"
                        label="Label"
                        placeholder="Label"
                        required
                        :error="getError('label')"
                    >
                        <template #help>
                            Shown description title in the menu
                        </template>
                    </TextInput>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    v-if="tag.item"
                    size="md"
                    color="yellow"
                    @click="updateTag"
                >
                    Update
                </AtomicButton>

                <AtomicButton
                    v-else
                    size="md"
                    color="yellow"
                    @click="createTag"
                >
                    Create
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import IInputErrors from '@/types/Atomic/IInputErrors';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';

const props = defineProps({
    tag: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['close', 'update', 'store']);

onMounted(() => {
    if (props.tag.itemIndex) {
        types.value.splice(1, 1);
    }
});

const errors = ref<IInputErrors>({});
const state = reactive({
    data: {
        name: props.tag.item?.name ?? '',
        value: props.tag.item?.value ?? '',
        text: props.tag.item?.text ?? '',
        label: props.tag.item?.label ?? '',
    },
});
const rules = {
    data: {
        name: {
            required: requiredIf(
                () => props.tag.hasItems || type.value === 'nested'
            ),
            maxLength: maxLength(255),
        },
        value: {
            required: requiredIf(
                () => !props.tag.hasItems && type.value === 'item'
            ),
            maxLength: maxLength(255),
        },
        text: {
            required: requiredIf(
                () => !props.tag.hasItems && type.value === 'item'
            ),
            maxLength: maxLength(255),
        },
        label: {
            required: requiredIf(
                () => !props.tag.hasItems && type.value === 'item'
            ),
            maxLength: maxLength(255),
        },
    },
};
const v$ = useVuelidate(rules, state);

const type = ref('item');

const types = ref([
    { value: 'item', title: 'Merge tag item' },
    { value: 'nested', title: 'Merge tags nested' },
]);
const createTag = () => {
    v$.value.data.$touch();
    if (v$.value.data.$error) {
        () => undefined;
    } else {
        if (type.value === 'nested') {
            state.data.text = '';
            state.data.label = '';
            state.data.value = '';
        } else {
            state.data.name = '';
        }
        emit('store', state.data);
    }
};

const updateTag = () => {
    v$.value.data.$touch();
    if (v$.value.data.$error) {
        () => undefined;
    } else {
        emit('update', state.data);
    }
};

const getError = (field: string) => {
    return (
        v$.value.data[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
