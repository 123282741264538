<template>
    <div>
        <div
            class="relative flex flex-row items-center justify-between gap-2 hover:bg-gray-50"
        >
            <div
                class="justify-left relative flex w-full cursor-pointer items-center px-5 py-3"
                @click="showTemplate"
            >
                <div
                    class="relative h-[45px] w-[56px]"
                    @mouseenter="handleShowTemplatePreview"
                    @mouseleave="handleHideTemplatePreview"
                >
                    <img
                        :src="template.image_url"
                        :alt="template.name"
                        class="h-[45px] w-[56px] object-cover object-top"
                    />
                </div>

                <div class="relative ml-5 flex flex-row gap-5">
                    <div>
                        <div class="flex h-6 items-center justify-start gap-2">
                            <button type="button" class="flex items-center">
                                <p
                                    class="max-w-[100px] truncate text-sm font-medium leading-6 text-gray-900 md:max-w-[220px] lg:max-w-lg"
                                >
                                    {{ template.name }}
                                </p>
                            </button>

                            <div
                                class="hidden min-w-[40px] rounded-md px-2 py-1 text-center text-xs font-medium text-gray-50 sm:block"
                                :class="{
                                    'bg-green-600': template.price === 0,
                                    'bg-indigo-600': template.price !== 0,
                                }"
                            >
                                <span v-if="template.price">
                                    ${{ template.price }}
                                </span>
                                <span v-else> Free </span>
                            </div>
                        </div>

                        <div
                            class="flex items-center gap-x-2 text-sm leading-normal text-gray-500"
                        >
                            <div
                                class="flex items-center gap-x-2 text-xs leading-5 text-gray-500"
                            >
                                <div
                                    class="whitespace-nowrap text-xs leading-5 text-gray-500"
                                >
                                    <p
                                        v-if="template.deleted_at"
                                        class="flex items-center gap-1"
                                    >
                                        <span class="hidden md:block">
                                            Archived on
                                        </span>

                                        <span>
                                            {{
                                                format(
                                                    new Date(
                                                        template.deleted_at
                                                    ),
                                                    'd. L. yyyy'
                                                )
                                            }}
                                            (UTC)
                                        </span>
                                    </p>
                                    <p
                                        v-else-if="template.published_at"
                                        class="flex items-center gap-1"
                                    >
                                        <span class="hidden md:block">
                                            Published on
                                        </span>

                                        <span>
                                            {{
                                                format(
                                                    new Date(
                                                        template.published_at
                                                    ),
                                                    'd. L. yyyy'
                                                )
                                            }}
                                            (UTC)
                                        </span>
                                    </p>

                                    <p v-else>
                                        <span class="hidden md:block">
                                            Not published
                                        </span>
                                    </p>
                                </div>

                                <svg
                                    viewBox="0 0 2 2"
                                    class="hidden h-0.5 w-0.5 fill-current lg:block"
                                >
                                    <circle cx="1" cy="1" r="1" />
                                </svg>

                                <div class="hidden whitespace-nowrap lg:block">
                                    Category:
                                    <span class="text-gray-700">
                                        {{ template.category?.name }}
                                    </span>
                                </div>

                                <svg
                                    viewBox="0 0 2 2"
                                    class="hidden h-0.5 w-0.5 fill-current lg:block"
                                >
                                    <circle cx="1" cy="1" r="1" />
                                </svg>

                                <div
                                    class="hidden max-w-xs items-end gap-1 truncate lg:flex"
                                >
                                    Keywords:
                                    <div
                                        v-for="(
                                            keyword, index
                                        ) in template.keywords"
                                        :key="keyword.id"
                                        class="text-gray-700"
                                        :class="{ hidden: index > 2 }"
                                    >
                                        {{ keyword.keyword }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div></div>
                    </div>

                    <Tooltip
                        v-if="showTooltipTemplate"
                        type="image"
                        class="absolute bottom-[65px] left-[-40px] z-20 h-[160px] w-56 rounded-md bg-white"
                    >
                        <img
                            :src="template.image_url"
                            :alt="template.name"
                            class="h-[160px] w-56 rounded-md object-cover object-top"
                        />
                    </Tooltip>
                </div>
            </div>
            <div class="relative">
                <div
                    class="flex flex-none items-center justify-between py-3 pr-3 sm:gap-x-4 sm:px-5"
                >
                    <div
                        class="hidden gap-1 text-sm leading-normal text-gray-500 sm:flex"
                    >
                        Purchased:
                        <span class="font-medium text-gray-700">
                            {{ template.team_count }}x
                        </span>
                    </div>

                    <AtomicButton
                        v-if="!template.deleted_at"
                        size="sm"
                        color="white"
                        @click="editTemplate"
                    >
                        <template #icon>
                            <PencilSquareIcon class="mr-1 h-5 w-5" />
                        </template>
                        <span class="hidden sm:block">Edit</span>
                    </AtomicButton>

                    <AtomicButton
                        v-else
                        size="sm"
                        color="white"
                        @click="restoreTemplate"
                    >
                        <template #icon>
                            <ArrowPathIcon class="mr-1 h-5 w-5" />
                        </template>
                        <span class="hidden sm:block">Restore</span>
                    </AtomicButton>
                </div>
            </div>

            <div
                class="absolute bottom-0 left-1/2 h-[1px] w-[calc(100%-16px)] -translate-x-1/2 transform bg-gray-200"
            ></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns';
import { PropType } from 'vue';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { ArrowPathIcon, PencilSquareIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    template: {
        type: Object as PropType<IMarketplaceTemplate>,
        required: true,
    },
});

const emit = defineEmits(['edit', 'restore']);

const showTooltipTemplate = ref(false);
const timeout = ref(0);

const handleShowTemplatePreview = () => {
    timeout.value = setTimeout(() => {
        showTooltipTemplate.value = true;
    }, 300);
};

const handleHideTemplatePreview = () => {
    clearTimeout(timeout.value);
    showTooltipTemplate.value = false;
};

const editTemplate = () => {
    emit('edit', props.template);
};

const showTemplate = () => {
    router.get(
        route('marketplace.all-templates.show', {
            marketplaceTemplate: props.template.id,
        })
    );
};

const restoreTemplate = () => {
    emit('restore', props.template);
};
</script>

<style scoped>
.template-shadow-selected {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
