<template>
    <Modal
        v-if="opened"
        :size="!cart || cart.shopping_cart_items.length === 0 ? 'xl' : 'xxl'"
        @close="onClose"
    >
        <template #header>
            <div v-if="order" class="text-gray-600">My cart</div>

            <div v-if="checkout" class="text-gray-600">Add payment method</div>
        </template>

        <template #body>
            <div class="flex justify-between p-6">
                <ShoppingCartModalOrder
                    v-if="
                        (order || checkout) &&
                        cart &&
                        cart.shopping_cart_items.length > 0
                    "
                    :cart="cart"
                />

                <!-- <ShoppingCartModalCheckout v-if="checkout && cart && cart.shopping_cart_items.length > 0" /> -->

                <ShoppingCartModalSummary
                    v-if="cart && cart.shopping_cart_items.length > 0"
                    :loading="loading"
                    :cart="cart"
                    :order="order"
                    :checkout="checkout"
                    @go-to-checkout="handleGoToCheckout"
                    @buy="handleBuy"
                />

                <div
                    v-if="!cart || cart.shopping_cart_items.length === 0"
                    class="mx-auto mt-4 mb-6 max-w-md text-center text-base text-gray-700"
                >
                    <div>
                        <p class="text-lg font-medium">
                            There are no items in your cart.
                        </p>
                        <p class="mt-2">
                            Visit
                            <button
                                class="cursor-pointer font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                @click="openMarketplace"
                            >
                                Marketplace
                            </button>
                            to browse templates.
                        </p>
                    </div>
                    <div class="mt-5">
                        <AtomicButton
                            size="md"
                            color="yellow"
                            @click="openMarketplace"
                        >
                            Go to Marketplace
                        </AtomicButton>
                    </div>
                </div>

                <ShoppingCartModalOrderResult
                    v-if="result"
                    :result="result"
                    @close="onCloseOrderResult"
                    @close-and-go-to-my-templates="closeAndRedirectToTemplates"
                />
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import Modal from '@atomic/Modals/Modal.vue';
import useMarketplaceCart from '@composables/useMarketplaceCart';
import route from 'ziggy-js';

const { opened, close, cart, fetchCart } = useMarketplaceCart();

const onClose = () => {
    close();
};

const onCloseOrderResult = () => {
    result.value = '';
    close();
    fetchCart();
};

const openMarketplace = () => {
    close();
    router.get(route('marketplace.all-templates.index'));
};

const closeAndRedirectToTemplates = () => {
    result.value = '';
    close();
    fetchCart();
    router.get(route('home'));
};

const order = ref(true);
const checkout = ref(false);
const loading = ref(false);
const result = ref('');
const showShoppingCartOrderCompleteModal = ref(false);

const handleGoToCheckout = () => {
    order.value = false;
    checkout.value = true;
};

const handleBuy = () => {
    if (!cart) {
        return;
    }
    result.value = 'success';
    showShoppingCartOrderCompleteModal.value = true;

    loading.value = true;
    router.post(
        route('marketplace.templates-order.store'),
        {},
        {
            onSuccess: () => {
                result.value = 'success';
                showShoppingCartOrderCompleteModal.value = true;
            },
            onError: () => {
                result.value = 'error';
                showShoppingCartOrderCompleteModal.value = true;
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};
</script>
