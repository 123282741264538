<template>
    <EditorPreferencesTabs :features="features" tab-key="appearance">
        <div class="mb-[98px]">
            <div
                class="mt-[44px] grid-cols-layout border-b border-gray-300 pb-5 lg:grid"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Editor language
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Select your preferred TOPOL Editor interface language.
                    </p>
                </div>
                <form
                    class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
                    @submit.prevent="updateLanguage"
                >
                    <div class="flex flex-wrap pl-6 border-b border-gray-200">
                        <SelectInput
                            v-model="selectedLanguage"
                            class="w-full pr-6 my-5 sm:mr-6 sm:w-80 sm:pr-0"
                            :data="languages"
                            placeholder="Select language"
                            label="Editor language"
                            search
                        />
                    </div>
                    <div class="flex justify-end">
                        <div class="pr-6 my-3">
                            <AtomicButton
                                :disabled="
                                    selectedLanguage === editorSettings.language
                                "
                                type="submit"
                                color="yellow"
                                size="sm"
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </form>
            </div>

            <form
                class="pb-5 mt-5 border-b border-gray-300 grid-cols-layout lg:grid"
                @submit.prevent="updateTheme"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Themes
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Change the appearance of TOPOL Editor with one of the
                        predefined color themes.
                    </p>
                </div>
                <div
                    class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
                >
                    <div
                        class="flex flex-wrap justify-center gap-6 border-b border-gray-200 px-6 pb-5 pt-[23px] lg:justify-between"
                    >
                        <div
                            class="bg-gray-300 border rounded-md shadow-sm cursor-pointer"
                            :class="{
                                'border-orange-500': mode === 'dark',
                                'border-gray-300': mode !== 'dark',
                            }"
                            @click="mode = 'dark'"
                        >
                            <div class="">
                                <img
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/editor-dark.svg"
                                    alt=""
                                />
                            </div>

                            <div
                                class="rounded-b-md bg-gray-50 pt-4 pb-[21px] pl-[14px]"
                            >
                                <RadioInput
                                    v-model="mode"
                                    option="dark"
                                    label="Dark mode (default)"
                                    name="editor"
                                />
                            </div>
                        </div>

                        <div
                            class="bg-gray-300 border rounded-md shadow-sm cursor-pointer"
                            :class="{
                                'border-orange-500': mode === 'light',
                                'border-gray-300': mode !== 'light',
                            }"
                            @click="mode = 'light'"
                        >
                            <div class="">
                                <img
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/editor-light.svg"
                                    alt=""
                                />
                            </div>

                            <div
                                class="rounded-b-md bg-gray-50 pt-4 pb-[21px] pl-[14px]"
                            >
                                <RadioInput
                                    v-model="mode"
                                    label="Light mode"
                                    option="light"
                                    name="editor"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-end">
                        <div class="pr-6 my-3">
                            <AtomicButton
                                :disabled="mode === editorSettings.theme"
                                type="submit"
                                color="yellow"
                                size="sm"
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </div>
            </form>

            <CustomColors
                :editor-settings="editorSettings"
                @update-colors="updateColors"
            />
        </div>
    </EditorPreferencesTabs>
</template>
<script lang="ts" setup>
import SelectInput from '@atomic/Inputs/SelectInput.vue';
import RadioInput from '@atomic/Inputs/RadioInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import route from 'ziggy-js';
import { PropType } from 'vue';
import languages from '@/data/editor-languages';
import IEditorSettings from '@/types/Settings/IEditorSettings';
import EditorPreferencesTabs from '../components/EditorPreferencesTabs.vue';
import { create } from '@composables/useNotifications';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    editorSettings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
    features: {
        type: Object,
        default: null,
    },
});

const mode = ref(props.editorSettings.theme);
const selectedLanguage = ref(props.editorSettings.language);

const form = useForm({
    editorSettings: {
        language: props.editorSettings.language,
        theme: props.editorSettings.theme,
        colors: props.editorSettings.colors,
    },
});

const updateLanguage = () => {
    form.editorSettings.language = selectedLanguage.value;
    form.put(route('editor-preferences.appearance.update'), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Editor language updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Language not updated',
                text: 'There was an error updating editor language.',
                type: 'error',
            });
        },
    });
};

const updateTheme = () => {
    form.editorSettings.theme = mode.value;

    form.put(route('editor-preferences.appearance.update'), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Editor theme updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Theme not updated',
                text: 'There was an error updating your editor theme.',
                type: 'error',
            });
        },
    });
};

const updateColors = (colors: Array<string>) => {
    form.editorSettings.colors = colors;

    form.put(route('editor-preferences.appearance.update'), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Editor custom colors updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Theme not updated',
                text: 'There was an error updating your custom colors.',
                type: 'error',
            });
        },
    });
};
</script>

<style></style>
