<template>
    <div>
        <div class="pb-5 mt-5 grid-cols-layout lg:grid">
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="mb-[5px] text-lg font-medium leading-normal tracking-small text-gray-800"
                >
                    Product feeds
                </h3>

                <p class="max-w-xs text-sm text-gray-500">
                    We provide an option to use predefined products. Learn more about product feeds and how to use them in our
                    <a
                        class="text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://topol.io/blog/how-to-automate-e-commerce-email-marketing-with-product-feeds"
                        target="_blank"
                        >blog article</a
                    >.
                </p>
            </div>

            <form
                class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
                @submit.prevent="createFeed"
            >
                <div class="flex flex-wrap pb-2 pl-6 border-b border-gray-200">
                    <TextInput
                        v-model="v$.form.name.$model"
                        class="w-full my-5 mr-6 sm:w-80"
                        label="Name"
                        placeholder="Enter name of your feed"
                        :error="getError('name')"
                    />

                    <TextInput
                        v-model="v$.form.url.$model"
                        class="w-full my-5 mr-6 sm:w-80"
                        label="Product feed URL (XML)"
                        placeholder="https://example.com/feed.xml"
                        :error="getError('url')"
                    />

                    <SelectInput
                        v-model="state.form.currency"
                        class="w-full my-5 mr-6 sm:w-40"
                        label="Currency"
                        placeholder="Select currency"
                        :data="currencies"
                    />
                </div>

                <div class="flex justify-end">
                    <div class="pr-6 my-3">
                        <AtomicButton
                            type="submit"
                            color="yellow"
                            size="sm"
                            :disabled="loading || v$.form.$invalid"
                        >
                            Create
                        </AtomicButton>
                    </div>
                </div>
            </form>
        </div>

        <div
            v-if="feedData.length > 0"
            class="pt-5 border-t border-gray-300 grid-cols-layout lg:grid"
        >
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="mb-[5px] text-lg font-medium leading-normal tracking-small text-gray-800"
                >
                    Manage Product feeds
                </h3>
                <p class="max-w-xs text-sm text-gray-500"></p>
            </div>
            <div
                class="flex flex-wrap items-center w-full gap-2 py-5 mx-auto bg-white rounded-md shadow-card lg:mx-0"
            >
                <div
                    v-for="(feed, index) in feedData"
                    :key="feed.id"
                    class="flex items-center justify-between w-full pb-2 mx-6 border-gray-200 border-b-1 last-of-type:border-b-0 last-of-type:pb-0"
                >
                    <div class="flex flex-col gap-1 text-gray-800">
                        <div class="flex items-center gap-2">
                            <a :href="feed.url" target="_blank">
                                {{ feed.name }}
                            </a>
                        </div>

                        <div class="flex flex-row items-center gap-2">
                            <StatusIndicator :status="feed.status" />
                            <div class="text-xs text-gray-600">
                                Status: {{ feed.status }}
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-2">
                        <AtomicButton
                            type="button"
                            color="white"
                            size="sm"
                            :disabled="loading"
                            @click="showFeed(feed)"
                        >
                            <template #icon>
                                <ShoppingBagIcon class="w-4 h-4 mr-2" />
                            </template>
                            Products
                        </AtomicButton>

                        <AtomicButton
                            type="button"
                            color="white"
                            size="sm"
                            :disabled="loading || feed.status === 'processing'"
                            @click="sync(feed)"
                        >
                            <template #icon>
                                <ArrowPathIcon class="w-4 h-4 mr-2" />
                            </template>
                            Sync
                        </AtomicButton>

                        <AtomicButton
                            type="button"
                            color="red"
                            size="sm"
                            :disabled="deleteLoading || loading"
                            @click="deleteFeed(feed)"
                        >
                            <template #icon>
                                <TrashIcon class="w-4 h-4 mr-2" />
                            </template>
                            Delete
                        </AtomicButton>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center mt-4">
            <AtomicButton
                v-if="nextPage"
                :disabled="loading"
                color="white"
                @click="loadProductFeedsNextPage"
                >Load more</AtomicButton
            >
        </div>

        <ProductsModal
            v-if="showProductsModal"
            :product-feed-id="selectedProductFeedId"
            @close="handleProductsModalClose"
        />
    </div>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import useConfirm from '@composables/useConfirm';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, url, } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import IProductFeed from '@/types/Products/IProductFeed';
import {
    ArrowPathIcon,
    ShoppingBagIcon,
    TrashIcon,
} from '@heroicons/vue/24/outline';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import useNewFeatures from '@/composables/useNewFeatures';

interface IForm {
    form: {
        name: string;
        url: string;
        currency: string;
    };
}

const props = defineProps({
    feeds: {
        type: Object as IPaginationData<IProductFeed>,
        required: true,
    },
});
const { show } = useConfirm();
const { setNewFeatureAsVisited, featureVisited } = useNewFeatures();

const loading = ref(false);
const deleteLoading = ref(false);
const errors = ref<IInputErrors>({});
const showProductsModal = ref(false);
const selectedProductFeedId = ref(0);

const state: IForm = reactive({
    form: {
        url: '',
        name: '',
        currency: '',
    },
});

const feedData = ref(props.feeds.data);
const nextPage = ref(props.feeds.next_page);

const rules = {
    form: {
        url: {
            required,
            maxLength: maxLength(5000),
            url,
        },
        name: {
            required,
            maxLength: maxLength(255),
        },
    },
};

const v$ = useVuelidate(rules, state);

onMounted(() => {
    if (!featureVisited('product_feeds')) {
        setNewFeatureAsVisited('product_feeds');
    }
});

const currencies = ref([
    {
        title: 'USD',
        value: 'USD',
    },
    {
        title: 'EUR',
        value: 'EUR',
    },
    {
        title: 'CZK',
        value: 'CZK',
    },
    {
        title: 'None',
        value: null,
    },
]);

const loadProductFeedsNextPage = async () => {
    if (nextPage.value) {
        loading.value = true;
        try {
            const result = await axios.get(
                route('editor-preferences.product-feeds.index'),
                {
                    params: {
                        current_page: nextPage.value,
                    },
                }
            );
            if (result) {
                feedData.value = [...feedData.value, ...result.data.data];
                nextPage.value = result.data.next_page;
            }
        } catch (e) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    }
};
const handleProductsModalClose = () => {
    showProductsModal.value = false;
    selectedProductFeedId.value = 0;
};

const createFeed = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('editor-preferences.product-feeds.store'),
            state.form,
            {
                preserveScroll: true,
                preserveState: 'errors',
                onFinish: () => {
                    loading.value = false;
                },
                onSuccess: () => {
                    create({
                        title: 'Product feed created successfully',
                        text: 'It may take few minutes for your Products to populate.',
                        type: 'success',
                    });
                },
                onError: (e) => {
                    errors.value = e;
                    create({
                        title: 'Failed to create product feed',
                        text: 'Please check your input and try again.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const sync = (feed: IProductFeed) => {
    router.put(
        route('editor-preferences.product-feeds.update', {
            productFeed: feed.id,
        }),
        {},
        {
            preserveScroll: true,
            preserveState: false,
            onFinish: () => {
                loading.value = false;
            },
            onSuccess: () => {
                create({
                    title: 'Product feed synchronization started',
                    text: 'It may take few minutes for your Products to populate.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Failed to synchronize product feed',
                    type: 'error',
                });
            },
        }
    );
};

const showFeed = (feed: IProductFeed) => {
    selectedProductFeedId.value = feed.id;
    showProductsModal.value = true;
};

const deleteFeed = async (feed: IProductFeed) => {
    const confirmed = await show({
        header: `Delete Product feed`,
        message: `Are you sure you want to delete this product feed?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (!confirmed) {
        return;
    }

    deleteLoading.value = true;
    router.delete(route('editor-preferences.product-feeds.destroy', feed.id), {
        preserveScroll: true,
        onFinish: () => {
            deleteLoading.value = false;
        },
        onSuccess: () => {
            create({
                title: 'Product feed deleted successfully',
                type: 'success',
            });
            feedData.value = feedData.value.filter(
                (item) => item.id !== feed.id
            );
        },
        onError: () => {
            create({
                title: 'Failed to delete Product feed',
                type: 'error',
            });
        },
    });
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
