<template>
    <div class="py-12">
        <div class="flex flex-col justify-between sm:flex-row sm:items-center">
            <div class="flex flex-col justify-between sm:flex-row">
                <div class="text-2xl font-medium text-gray-800">
                    Premade templates
                </div>

                <inertia-link
                    :href="route('kiosk.premade-templates.categories.index')"
                >
                    <AtomicButton class="ml-2" size="sm" color="white">
                        Manage categories
                    </AtomicButton>
                </inertia-link>

                <inertia-link
                    :href="route('kiosk.premade-templates.keywords.index')"
                >
                    <AtomicButton class="ml-2" size="sm" color="white">
                        Manage keywords
                    </AtomicButton>
                </inertia-link>
            </div>
            <div class="mt-2 flex gap-2 sm:mt-0">
                <inertia-link
                    :href="route('kiosk.premade-templates.order.index')"
                >
                    <AtomicButton size="md" color="purple">
                        Manage order
                    </AtomicButton>
                </inertia-link>
                <inertia-link :href="route('kiosk.premade-templates.create')">
                    <AtomicButton size="md" color="yellow">
                        <template #icon>
                            <PlusCircleIcon class="mr-2 h-6 w-6" />
                        </template>
                        Create new
                    </AtomicButton>
                </inertia-link>
            </div>
        </div>

        <DataTable
            data-cy="kiosk-premade-templates-table"
            class="mt-5"
            searchable
            :headers="headers"
            :items="premadeTemplates"
            :filters="filters"
        >
            <template #item-type="{ data }">
                <div class="flex justify-center text-xs text-gray-500">
                    <div
                        v-if="data === 'FREE'"
                        class="rounded-md bg-blue-300 px-2 py-[3px]"
                    >
                        {{ data }}
                    </div>

                    <div v-else class="rounded-md bg-green-200 px-2 py-[3px]">
                        {{ data }}
                    </div>
                </div>
            </template>

            <template #item-visible="{ data }">
                <div class="flex justify-center text-gray-500">
                    <EyeIcon v-if="data" class="h-6 w-6" />
                    <EyeSlashIcon v-else class="h-6 w-6" />
                </div>
            </template>

            <template #item-publish_at="{ data }">
                <div v-if="data" class="p-6">
                    {{ format(new Date(data as string), 'MMMM d, yyyy') }}
                </div>
            </template>

            <template #item-custom_show="{ row }">
                <div class="flex w-full justify-center">
                    <inertia-link
                        :href="
                            route('kiosk.premade-templates.edit', {
                                premadeTemplate: row.id as number,
                            })
                        "
                    >
                        <AtomicButton color="white" size="sm">
                            <template #icon>
                                <MagnifyingGlassIcon class="h-6 w-6" />
                            </template>
                        </AtomicButton>
                    </inertia-link>
                </div>
            </template>

            <template #item-custom_delete="{ row }">
                <div class="flex w-full justify-center">
                    <AtomicButton
                        color="red"
                        size="sm"
                        @click="deleteTemplate(row as IPremadeTemplate)"
                    >
                        <template #icon>
                            <TrashIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import route from 'ziggy-js';
import {
    PlusCircleIcon,
    MagnifyingGlassIcon,
    EyeIcon,
    EyeSlashIcon,
    TrashIcon,
} from '@heroicons/vue/24/outline';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { IDataTableFilter } from '@/components/Atomic/DataTable/types/IDataTableFilter';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import { format } from 'date-fns';
import useConfirm from '@/composables/useConfirm';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';

defineProps<{
    premadeTemplates: IPaginationData<IPremadeTemplate>;
}>();

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Type',
        id: 'type',
        align: 'center',
        sortable: true,
        sortableId: 'type',
    },
    {
        text: 'Description',
        id: 'description',
    },
    {
        text: 'Visibility',
        id: 'visible',
        align: 'center',
        sortable: true,
        sortableId: 'visible',
    },
    {
        text: 'Publish at',
        id: 'publish_at',
        sortable: true,
        sortableId: 'publish_at',
    },
    { text: '', id: 'custom_show' },
    { text: '', id: 'custom_delete' },
];

const filters: IDataTableFilter[] = [
    {
        column: 'type',
        label: 'Template type',
        values: [
            {
                label: 'FREE',
                value: 'FREE',
            },
            {
                label: 'PRO',
                value: 'PRO',
            },
        ],
    },
    {
        column: 'visible',
        label: 'Template visibility',
        values: [
            {
                label: 'Visible',
                value: '1',
            },
            {
                label: 'Not visible',
                value: '0',
            },
        ],
    },
];

const deleteTemplate = async (value: IPremadeTemplate) => {
    const confirmed = await show({
        header: 'Delete premade template',
        message: `Are you sure you want to delete premade template ${value.name}?`,
        type: 'danger',
        confirm: 'Delete template',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.premade-templates.delete', {
                premadeTemplate: value.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Template deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Template not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>

<style></style>
