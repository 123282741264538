<template>
    <div ref="saturationRef" class="saturation" @mousedown.prevent.stop="selectSaturation">
        <canvas ref="canvasSaturation" />
        <div :style="slideSaturationStyle" class="slide" />
    </div>
</template>

<script lang="ts" setup>
import useColorPicker from './useColorPicker';

const props = defineProps({
    color: {
        type: String,
        default: '#000000',
    },
    hsv: {
        type: Object,
        default: null,
    },
    size: {
        type: Number,
        default: 152,
    },
});

const emit = defineEmits(['selectSaturation']);

const slideSaturationStyle = ref({});
const canvasSaturation = ref<Element | null>(null);
const saturationRef = ref<Element | null>(null);

onMounted(() => {
    renderColor();
    renderSlide();
});

const { createLinearGradient } = useColorPicker();


const renderColor = () => {
    const canvas = canvasSaturation.value;

    const size = props.size;
    const ctx = canvas.getContext('2d');
    canvas.width = size;
    canvas.height = size;

    ctx.fillStyle = props.color;
    ctx.fillRect(0, 0, size, size);

    createLinearGradient(
        'l',
        ctx,
        size,
        size,
        '#FFFFFF',
        'rgba(255,255,255,0)'
    );
    createLinearGradient(
        'p',
        ctx,
        size,
        size,
        'rgba(0,0,0,0)',
        '#000000'
    );
};

const renderSlide = () => {
    slideSaturationStyle.value = {
        left: props.hsv.s * props.size - 5 + 'px',
        top: (1 - props.hsv.v) * props.size - 5 + 'px',
    };
};     

const selectSaturation = (e: Event) => {
    const { top: saturationTop, left: saturationLeft } =
        saturationRef.value.getBoundingClientRect();
    const ctx = e.target.getContext('2d');

    const mousemove = (e) => {
        let x = e.clientX - saturationLeft;
        let y = e.clientY - saturationTop;

        if (x < 0) {
            x = 0;
        }
        if (y < 0) {
            y = 0;
        }
        if (x > props.size) {
            x = props.size;
        }
        if (y > props.size) {
            y = props.size;
        }

        // 不通过监听数据变化来修改dom，否则当颜色为#ffffff时，slide会跑到左下角
        slideSaturationStyle.value = {
            left: x - 5 + 'px',
            top: y - 5 + 'px',
        };
        // 如果用最大值，选择的像素会是空的，空的默认是黑色
        const imgData = ctx.getImageData(
            Math.min(x, props.size - 1),
            Math.min(y, props.size - 1),
            1,
            1
        );
        const [r, g, b] = imgData.data;
        emit('selectSaturation', { r, g, b });
    };

    mousemove(e);

    const mouseup = () => {
        document.removeEventListener('mousemove', mousemove);
        document.removeEventListener('mouseup', mouseup);
    };

    document.addEventListener('mousemove', mousemove);
    document.addEventListener('mouseup', mouseup);
};

defineExpose({
    renderColor,
    renderSlide,
});
</script>

<style scoped>
.saturation {
    position: relative;
    cursor: pointer;
}

.saturation .slide {
    position: absolute;
    left: 100px;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
    pointer-events: none;
}
</style>
