
export default (language: string) => {
    const translations = {
        en: {
            saveSuccess: 'Template was successfully saved.',
            saveBlockSuccess: 'Saved block created!',
            removeBlockSuccess: 'Saved block was removed!',
            updateBlockSuccess: 'Saved block was updated!',
            sentEmailSuccessTitle: 'Email sent!',
            sentEmailSuccess: 'Email was successfully sent to {replace}.',
            sentEmailFailedTitle: 'Send email failed!',
            sentEmailFailed: 'Email has not been send to {replace}.',
            closeEditorTitle: 'Unsaved changes will be lost!',
            closeEditorText: 'Are you sure you want to close the editor? All unsaved changes will be lost.',
            closeEditorConfirm: 'Close Editor',
            templateRenameSuccessTitle: 'Template renamed!',
            templateRenameFailedTitle: 'Template rename failed!',
            templateRenameTitle: 'Rename Template',
            templateRenameLabel: 'Template name',
            cancel: 'Cancel',
            confirm: 'Confirm',
            rename: 'Rename',
            delete: 'Delete',
            savedBlockExistsText: 'Saved block with this name alerady exists.',
            savedBlockCreateTitle: 'Create new saved block',
            savedBlockCreateLabel: 'Saved block name',
            savedBlockCreatePlaceholder: 'Enter new saved block name',
            savedBlockCreateFailed: 'Creating saved block failed!',
            savedBlockEditFailed: 'Editting saved block failed!',
            savedBlockRefetchFailed: 'Re-fetching saved blocks failed!',
            savedBlockDeleteText: 'Are you sure you want to delete saved block {replace} and all its content?`'
        },
        fr: {
            saveSuccess: 'Le modèle a été enregistré avec succès.',
            saveBlockSuccess: 'Bloc enregistré créé !',
            removeBlockSuccess: 'Le bloc enregistré a été supprimé !',
            updateBlockSuccess: 'Le bloc enregistré a été mis à jour !',
            sentEmailSuccessTitle: 'Email envoyé !',
            sentEmailSuccess: 'L\'email a été envoyé avec succès à {replace}.',
            sentEmailFailedTitle: 'Échec de l\'envoi de l\'email !',
            sentEmailFailed: 'L\'email n\'a pas été envoyé à {replace}.',
            closeEditorTitle: 'Les modifications non enregistrées seront perdues !',
            closeEditorText: 'Êtes-vous sûr de vouloir fermer l\'éditeur ? Toutes les modifications non enregistrées seront perdues.',
            closeEditorConfirm: 'Fermer l\'éditeur',
            templateRenameSuccessTitle: 'Modèle renommé !',
            templateRenameFailedTitle: 'Le renommage du modèle a échoué !',
            templateRenameLabel: "Nom du modèle",
            cancel: "Annuler",
            confirm: "Confirmer",
            rename: "Renommer",
            delete: "Supprimer",
            savedBlockExistsText: "Un bloc enregistré avec ce nom existe déjà.",
            savedBlockCreateTitle: "Créer un nouveau bloc enregistré",
            savedBlockCreateLabel: "Nom du bloc enregistré",
            savedBlockCreatePlaceholder: "Entrez le nouveau nom du bloc enregistré",
            savedBlockCreateFailed: "La création du bloc enregistré a échoué !",
            savedBlockEditFailed: "L'édition du bloc enregistré a échoué !",
            savedBlockRefetchFailed: "La récupération des blocs enregistrés a échoué !",
            savedBlockDeleteText: "Êtes-vous sûr de vouloir supprimer le bloc enregistré {replace} et tout son contenu ?"
        },
        pt: {
            saveSuccess: 'O modelo foi salvo com sucesso.',
            saveBlockSuccess: 'Bloco salvo criado!',
            removeBlockSuccess: 'Bloco salvo foi removido!',
            updateBlockSuccess: 'Bloco salvo foi atualizado!',
            sentEmailSuccessTitle: 'Email enviado!',
            sentEmailSuccess: 'O email foi enviado com sucesso para {replace}.',
            sentEmailFailedTitle: 'Falha ao enviar o email!',
            sentEmailFailed: 'O email não foi enviado para {replace}.',
            closeEditorTitle: 'As alterações não salvas serão perdidas!',
            closeEditorText: 'Tem certeza de que deseja fechar o editor? Todas as alterações não salvas serão perdidas.',
            closeEditorConfirm: 'Fechar Editor',
            templateRenameSuccessTitle: 'Modelo renomeado!',
            templateRenameFailedTitle: 'Falha ao renomear o modelo!',
            templateRenameTitle: "Renomear Modelo",
            templateRenameLabel: "Nome do modelo",
            cancel: "Cancelar",
            confirm: "Confirmar",
            rename: "Renomear",
            delete: "Excluir",
            savedBlockExistsText: "Já existe um bloco salvo com esse nome.",
            savedBlockCreateTitle: "Criar novo bloco salvo",
            savedBlockCreateLabel: "Nome do bloco salvo",
            savedBlockCreatePlaceholder: "Digite o novo nome do bloco salvo",
            savedBlockCreateFailed: "Falha ao criar o bloco salvo!",
            savedBlockEditFailed: "Falha ao editar o bloco salvo!",
            savedBlockRefetchFailed: "Falha ao recarregar os blocos salvos!",
            savedBlockDeleteText: "Tem certeza de que deseja excluir o bloco salvo {replace} e todo o seu conteúdo?"
        },
        es: {
            saveSuccess: 'La plantilla se guardó correctamente.',
            saveBlockSuccess: '¡Bloque guardado creado!',
            removeBlockSuccess: '¡Se eliminó el bloque guardado!',
            updateBlockSuccess: '¡Se actualizó el bloque guardado!',
            sentEmailSuccessTitle: 'Correo electrónico enviado.',
            sentEmailSuccess: 'El correo electrónico se envió correctamente a {replace}.',
            sentEmailFailedTitle: 'Error al enviar el correo electrónico.',
            sentEmailFailed: 'No se envió el correo electrónico a {replace}.',
            closeEditorTitle: '¡Se perderán los cambios no guardados!',
            closeEditorText: '¿Estás seguro de que quieres cerrar el editor? Se perderán todos los cambios no guardados.',
            closeEditorConfirm: 'Cerrar Editor',
            templateRenameSuccessTitle: 'Plantilla renombrada!',
            templateRenameFailedTitle: '¡Fallo al renombrar la plantilla!',
            templateRenameTitle: "Renombrar Plantilla",
            templateRenameLabel: "Nombre de la plantilla",
            cancel: "Cancelar",
            confirm: "Confirmar",
            rename: "Renombrar",
            delete: "Eliminar",
            savedBlockExistsText: "Ya existe un bloque guardado con este nombre.",
            savedBlockCreateTitle: "Crear nuevo bloque guardado",
            savedBlockCreateLabel: "Nombre del bloque guardado",
            savedBlockCreatePlaceholder: "Ingrese el nuevo nombre del bloque guardado",
            savedBlockCreateFailed: "La creación del bloque guardado falló.",
            savedBlockEditFailed: "La edición del bloque guardado falló.",
            savedBlockRefetchFailed: "La recuperación de los bloques guardados falló.",
            savedBlockDeleteText: "¿Estás seguro de que quieres eliminar el bloque guardado {replace} y todo su contenido?"
        },
        ja: {
            saveSuccess: 'テンプレートは正常に保存されました。',
            saveBlockSuccess: '保存されたブロックが作成されました！',
            removeBlockSuccess: '保存されたブロックが削除されました！',
            updateBlockSuccess: '保存されたブロックが更新されました！',
            sentEmailSuccessTitle: 'メール送信完了！',
            sentEmailSuccess: 'メールが正常に送信されました：{replace}。',
            sentEmailFailedTitle: 'メールの送信に失敗しました！',
            sentEmailFailed: 'メールが{replace}に送信されませんでした。',
            closeEditorTitle: '保存されていない変更は失われます！',
            closeEditorText: 'エディタを閉じてもよろしいですか？保存されていない変更は失われます。',
            closeEditorConfirm: 'エディタを閉じる',
            templateRenameSuccessTitle: 'テンプレート名を変更しました！',
            templateRenameFailedTitle: 'テンプレート名の変更に失敗しました！',
            templateRenameTitle: "テンプレートの名前を変更する",
            templateRenameLabel: "テンプレート名",
            cancel: "キャンセル",
            confirm: "確認",
            rename: "名前を変更",
            delete: "削除",
            savedBlockExistsText: "この名前の保存ブロックが既に存在します。",
            savedBlockCreateTitle: "新しい保存ブロックを作成する",
            savedBlockCreateLabel: "保存ブロック名",
            savedBlockCreatePlaceholder: "新しい保存ブロック名を入力",
            savedBlockCreateFailed: "保存ブロックの作成に失敗しました。",
            savedBlockEditFailed: "保存ブロックの編集に失敗しました。",
            savedBlockRefetchFailed: "保存ブロックの再取得に失敗しました。",
            savedBlockDeleteText: "本当に保存ブロック {replace} とその全ての内容を削除しますか？"
        },
        zh: {
            saveSuccess: '模板已成功保存。',
            saveBlockSuccess: '保存的区块已创建！',
            removeBlockSuccess: '保存的区块已移除！',
            updateBlockSuccess: '保存的区块已更新！',
            sentEmailSuccessTitle: '邮件发送成功！',
            sentEmailSuccess: '邮件已成功发送至 {replace}。',
            sentEmailFailedTitle: '邮件发送失败！',
            sentEmailFailed: '邮件未发送至 {replace}。',
            closeEditorTitle: '未保存的更改将丢失！',
            closeEditorText: '确定要关闭编辑器吗？所有未保存的更改将丢失。',
            closeEditorConfirm: '关闭编辑器',
            templateRenameSuccessTitle: '模板重命名成功！',
            templateRenameFailedTitle: '模板重命名失败！',
            templateRenameTitle: "重命名模板",
            templateRenameLabel: "模板名称",
            cancel: "取消",
            confirm: "确认",
            rename: "重命名",
            delete: "删除",
            savedBlockExistsText: "已存在此名称的保存块。",
            savedBlockCreateTitle: "创建新的保存块",
            savedBlockCreateLabel: "保存块名称",
            savedBlockCreatePlaceholder: "输入新的保存块名称",
            savedBlockCreateFailed: "创建保存块失败！",
            savedBlockEditFailed: "编辑保存块失败！",
            savedBlockRefetchFailed: "重新获取保存块失败！",
            savedBlockDeleteText: "您确定要删除保存块 {replace} 及其所有内容吗？"
        },
        ru: {
            saveSuccess: 'Шаблон успешно сохранен.',
            saveBlockSuccess: 'Сохраненный блок создан!',
            removeBlockSuccess: 'Сохраненный блок был удален!',
            updateBlockSuccess: 'Сохраненный блок был обновлен!',
            sentEmailSuccessTitle: 'Письмо отправлено!',
            sentEmailSuccess: 'Письмо успешно отправлено на {replace}.',
            sentEmailFailedTitle: 'Ошибка отправки письма!',
            sentEmailFailed: 'Письмо не было отправлено на {replace}.',
            closeEditorTitle: 'Несохраненные изменения будут потеряны!',
            closeEditorText: 'Вы уверены, что хотите закрыть редактор? Все несохраненные изменения будут потеряны.',
            closeEditorConfirm: 'Закрыть редактор',
            templateRenameSuccessTitle: 'Шаблон переименован!',
            templateRenameFailedTitle: 'Не удалось переименовать шаблон!',
            templateRenameTitle: "Переименовать Шаблон",
            templateRenameLabel: "Название шаблона",
            cancel: "Отмена",
            confirm: "Подтвердить",
            rename: "Переименовать",
            delete: "Удалить",
            savedBlockExistsText: "Блок с таким именем уже существует.",
            savedBlockCreateTitle: "Создать новый сохраненный блок",
            savedBlockCreateLabel: "Название сохраненного блока",
            savedBlockCreatePlaceholder: "Введите новое название сохраненного блока",
            savedBlockCreateFailed: "Не удалось создать сохраненный блок!",
            savedBlockEditFailed: "Не удалось отредактировать сохраненный блок!",
            savedBlockRefetchFailed: "Не удалось обновить сохраненные блоки!",
            savedBlockDeleteText: "Вы уверены, что хотите удалить сохраненный блок {replace} и весь его контент?"
              
        },
        tr: {
            saveSuccess: 'Şablon başarıyla kaydedildi.',
            saveBlockSuccess: 'Kaydedilmiş blok oluşturuldu!',
            removeBlockSuccess: 'Kaydedilmiş blok kaldırıldı!',
            updateBlockSuccess: 'Kaydedilmiş blok güncellendi!',
            sentEmailSuccessTitle: 'E-posta gönderildi!',
            sentEmailSuccess: 'E-posta {replace} adresine başarıyla gönderildi.',
            sentEmailFailedTitle: 'E-posta gönderme başarısız!',
            sentEmailFailed: 'E-posta {replace} adresine gönderilemedi.',
            closeEditorTitle: 'Kaydedilmemiş değişiklikler kaybolacak!',
            closeEditorText: 'Editörü kapatmak istediğinizden emin misiniz? Kaydedilmemiş tüm değişiklikler kaybolacak.',
            closeEditorConfirm: 'Editörü Kapat',
            templateRenameSuccessTitle: 'Şablon yeniden adlandırıldı!',
            templateRenameFailedTitle: 'Şablonun adı değiştirilemedi!',
            templateRenameTitle: 'Şablonu Yeniden Adlandır',
            templateRenameLabel: "Şablon adı",
            cancel: "İptal",
            confirm: "Onayla",
            rename: "Yeniden Adlandır",
            delete: "Sil",
            savedBlockExistsText: "Bu isimde kaydedilmiş bir blok zaten var.",
            savedBlockCreateTitle: "Yeni kaydedilmiş blok oluştur",
            savedBlockCreateLabel: "Kaydedilmiş blok adı",
            savedBlockCreatePlaceholder: "Yeni kaydedilmiş blok adını girin",
            savedBlockCreateFailed: "Kaydedilmiş blok oluşturma başarısız oldu!",
            savedBlockEditFailed: "Kaydedilmiş blok düzenleme başarısız oldu!",
            savedBlockRefetchFailed: "Kaydedilmiş blokları yeniden çekme başarısız oldu!",
            savedBlockDeleteText: "Kaydedilmiş bloğu {replace} ve tüm içeriğini silmek istediğinizden emin misiniz?" 
        },
        de: {
            saveSuccess: 'Vorlage wurde erfolgreich gespeichert.',
            saveBlockSuccess: 'Gespeicherter Block wurde erstellt!',
            removeBlockSuccess: 'Gespeicherter Block wurde entfernt!',
            updateBlockSuccess: 'Gespeicherter Block wurde aktualisiert!',
            sentEmailSuccessTitle: 'E-Mail gesendet!',
            sentEmailSuccess: 'Die E-Mail wurde erfolgreich an {replace} gesendet.',
            sentEmailFailedTitle: 'E-Mail senden fehlgeschlagen!',
            sentEmailFailed: 'Die E-Mail wurde nicht an {replace} gesendet.',
            closeEditorTitle: 'Ungespeicherte Änderungen gehen verloren!',
            closeEditorText: 'Sind Sie sicher, dass Sie den Editor schließen möchten? Alle ungespeicherten Änderungen gehen verloren.',
            closeEditorConfirm: 'Editor schließen',
            templateRenameSuccessTitle: 'Vorlage umbenannt!',
            templateRenameFailedTitle: 'Vorlage konnte nicht umbenannt werden!',
            templateRenameTitle: "Vorlage Umbenennen",
            templateRenameLabel: "Vorlagenname",
            cancel: "Abbrechen",
            confirm: "Bestätigen",
            rename: "Umbenennen",
            delete: "Löschen",
            savedBlockExistsText: "Ein gespeicherter Block mit diesem Namen existiert bereits.",
            savedBlockCreateTitle: "Neuen gespeicherten Block erstellen",
            savedBlockCreateLabel: "Name des gespeicherten Blocks",
            savedBlockCreatePlaceholder: "Neuen Namen des gespeicherten Blocks eingeben",
            savedBlockCreateFailed: "Erstellen des gespeicherten Blocks fehlgeschlagen!",
            savedBlockEditFailed: "Bearbeiten des gespeicherten Blocks fehlgeschlagen!",
            savedBlockRefetchFailed: "Neuladen der gespeicherten Blöcke fehlgeschlagen!",
            savedBlockDeleteText: "Sind Sie sicher, dass Sie den gespeicherten Block {replace} und seinen gesamten Inhalt löschen möchten?"
            
        },
        sv: {
            saveSuccess: 'Mallen sparades framgångsrikt.',
            saveBlockSuccess: 'Sparad block skapades!',
            removeBlockSuccess: 'Sparad block togs bort!',
            updateBlockSuccess: 'Sparad block uppdaterades!',
            sentEmailSuccessTitle: 'E-post skickat!',
            sentEmailSuccess: 'E-post skickades framgångsrikt till {replace}.',
            sentEmailFailedTitle: 'Misslyckades att skicka e-post!',
            sentEmailFailed: 'E-post har inte skickats till {replace}.',
            closeEditorTitle: 'Osparade ändringar kommer att förloras!',
            closeEditorText: 'Är du säker på att du vill stänga redigeraren? Alla osparade ändringar kommer att förloras.',
            closeEditorConfirm: 'Stäng redigeraren',
            templateRenameSuccessTitle: 'Mallen har döpts om!',
            templateRenameFailedTitle: 'Det gick inte att byta namn på mallen!',
            templateRenameTitle: "Byt namn på mall",
            templateRenameLabel: "Mallnamn",
            cancel: "Avbryt",
            confirm: "Bekräfta",
            rename: "Byt namn",
            delete: "Radera",
            savedBlockExistsText: "Ett sparat block med detta namn finns redan.",
            savedBlockCreateTitle: "Skapa nytt sparat block",
            savedBlockCreateLabel: "Namn på sparat block",
            savedBlockCreatePlaceholder: "Ange nytt namn för det sparade blocket",
            savedBlockCreateFailed: "Misslyckades med att skapa sparat block!",
            savedBlockEditFailed: "Misslyckades med att redigera sparat block!",
            savedBlockRefetchFailed: "Misslyckades med att hämta sparade block på nytt!",
            savedBlockDeleteText: "Är du säker på att du vill ta bort det sparade blocket {replace} och all dess innehåll?"
              
        },
        nl: {
            saveSuccess: 'Sjabloon is succesvol opgeslagen.',
            saveBlockSuccess: 'Opgeslagen blok aangemaakt!',
            removeBlockSuccess: 'Opgeslagen blok is verwijderd!',
            updateBlockSuccess: 'Opgeslagen blok is bijgewerkt!',
            sentEmailSuccessTitle: 'E-mail verzonden!',
            sentEmailSuccess: 'E-mail is succesvol verzonden naar {replace}.',
            sentEmailFailedTitle: 'Verzenden e-mail mislukt!',
            sentEmailFailed: 'E-mail is niet verzonden naar {replace}.',
            closeEditorTitle: 'Niet-opgeslagen wijzigingen gaan verloren!',
            closeEditorText: 'Weet je zeker dat je de editor wilt sluiten? Alle niet-opgeslagen wijzigingen gaan verloren.',
            closeEditorConfirm: 'Editor sluiten',
            templateRenameSuccessTitle: 'Sjabloon hernoemd!',
            templateRenameFailedTitle: 'Hernoemen van sjabloon mislukt!',
            templateRenameTitle: "Sjabloon Hernoemen",
            templateRenameLabel: "Sjabloonnaam",
            cancel: "Annuleren",
            confirm: "Bevestigen",
            rename: "Hernoemen",
            delete: "Verwijderen",
            savedBlockExistsText: "Een opgeslagen blok met deze naam bestaat al.",
            savedBlockCreateTitle: "Nieuw opgeslagen blok creëren",
            savedBlockCreateLabel: "Naam van opgeslagen blok",
            savedBlockCreatePlaceholder: "Voer de nieuwe naam van het opgeslagen blok in",
            savedBlockCreateFailed: "Opgeslagen blok aanmaken mislukt!",
            savedBlockEditFailed: "Bewerken van opgeslagen blok mislukt!",
            savedBlockRefetchFailed: "Opgeslagen blokken opnieuw ophalen mislukt!",
            savedBlockDeleteText: "Weet u zeker dat u het opgeslagen blok {replace} en al zijn inhoud wilt verwijderen?"
        },
        it: {
            saveSuccess: 'Il modello è stato salvato con successo.',
            saveBlockSuccess: 'Blocco salvato creato!',
            removeBlockSuccess: 'Il blocco salvato è stato rimosso!',
            updateBlockSuccess: 'Il blocco salvato è stato aggiornato!',
            sentEmailSuccessTitle: 'Email inviata!',
            sentEmailSuccess: 'L\'email è stata inviata con successo a {replace}.',
            sentEmailFailedTitle: 'Invio email fallito!',
            sentEmailFailed: 'L\'email non è stata inviata a {replace}.',
            closeEditorTitle: 'Le modifiche non salvate andranno perse!',
            closeEditorText: 'Sei sicuro di voler chiudere l\'editor? Tutte le modifiche non salvate andranno perse.',
            closeEditorConfirm: 'Chiudi Editor',
            templateRenameSuccessTitle: 'Template rinominato!',
            templateRenameFailedTitle: 'Rinomina del template fallita!',
            templateRenameTitle: "Rinomina Modello",
            templateRenameLabel: "Nome del modello",
            cancel: "Annulla",
            confirm: "Conferma",
            rename: "Rinomina",
            delete: "Elimina",
            savedBlockExistsText: "Un blocco salvato con questo nome esiste già.",
            savedBlockCreateTitle: "Crea nuovo blocco salvato",
            savedBlockCreateLabel: "Nome del blocco salvato",
            savedBlockCreatePlaceholder: "Inserisci il nuovo nome del blocco salvato",
            savedBlockCreateFailed: "Creazione del blocco salvato fallita!",
            savedBlockEditFailed: "Modifica del blocco salvato fallita!",
            savedBlockRefetchFailed: "Ricaricamento dei blocchi salvati fallito!",
            savedBlockDeleteText: "Sei sicuro di voler eliminare il blocco salvato {replace} e tutto il suo contenuto?" 
        },
        ro: {
            saveSuccess: 'Șablonul a fost salvat cu succes.',
            saveBlockSuccess: 'Bloc salvat creat!',
            removeBlockSuccess: 'Blocul salvat a fost eliminat!',
            updateBlockSuccess: 'Blocul salvat a fost actualizat!',
            sentEmailSuccessTitle: 'Email trimis!',
            sentEmailSuccess: 'Emailul a fost trimis cu succes la {replace}.',
            sentEmailFailedTitle: 'Trimiterea emailului a eșuat!',
            sentEmailFailed: 'Emailul nu a fost trimis la {replace}.',
            closeEditorTitle: 'Modificările nesalvate vor fi pierdute!',
            closeEditorText: 'Sigur doriți să închideți editorul? Toate modificările nesalvate vor fi pierdute.',
            closeEditorConfirm: 'Închideți Editorul',
            templateRenameSuccessTitle: 'Șablon redenumit!',
            templateRenameFailedTitle: 'Redenumirea șablonului a eșuat!',
            templateRenameTitle: "Redenumește Șablon",
            templateRenameLabel: "Numele șablonului",
            cancel: "Anulează",
            confirm: "Confirmă",
            rename: "Redenumește",
            delete: "Șterge",
            savedBlockExistsText: "Un bloc salvat cu acest nume există deja.",
            savedBlockCreateTitle: "Creează bloc salvat nou",
            savedBlockCreateLabel: "Nume bloc salvat",
            savedBlockCreatePlaceholder: "Introduceți noul nume al blocului salvat",
            savedBlockCreateFailed: "Crearea blocului salvat a eșuat!",
            savedBlockEditFailed: "Editarea blocului salvat a eșuat!",
            savedBlockRefetchFailed: "Reîncărcarea blocurilor salvate a eșuat!",
            savedBlockDeleteText: "Sunteți sigur că doriți să ștergeți blocul salvat {replace} și tot conținutul său?"     
        },
        cs: {
            saveSuccess: 'Šablona byla úspěšně uložena.',
            saveBlockSuccess: 'Uložený blok byl vytvořen!',
            removeBlockSuccess: 'Uložený blok byl odstraněn!',
            updateBlockSuccess: 'Uložený blok byl aktualizován!',
            sentEmailSuccessTitle: 'Email odeslán!',
            sentEmailSuccess: 'Email byl úspěšně odeslán na {replace}.',
            sentEmailFailedTitle: 'Odeslání emailu selhalo!',
            sentEmailFailed: 'Email nebyl odeslán na {replace}',
            closeEditorTitle: 'Neuložené změny budou ztraceny!',
            closeEditorText: 'Opravdu chcete zavřít editor? Všechny neuložené změny budou ztraceny.',
            closeEditorConfirm: 'Zavřít Editor',
            templateRenameSuccessTitle: 'Šablona přejmenována!',
            templateRenameFailedTitle: 'Přejmenování šablony se nezdařilo!',
            templateRenameTitle: "Přejmenovat šablonu",
            templateRenameLabel: "Název šablony",
            cancel: "Zrušit",
            confirm: "Potvrdit",
            rename: "Přejmenovat",
            delete: "Smazat",
            savedBlockExistsText: "Uložený blok s tímto názvem již existuje.",
            savedBlockCreateTitle: "Vytvořit nový uložený blok",
            savedBlockCreateLabel: "Název uloženého bloku",
            savedBlockCreatePlaceholder: "Zadejte nový název uloženého bloku",
            savedBlockCreateFailed: "Vytvoření uloženého bloku se nezdařilo!",
            savedBlockEditFailed: "Úprava uloženého bloku se nezdařila!",
            savedBlockRefetchFailed: "Načtení uložených bloků se nezdařilo!",
            savedBlockDeleteText: "Opravdu chcete smazat uložený blok {replace} a veškerý jeho obsah?"  
        },
        pl: {
            saveSuccess: 'Szablon został pomyślnie zapisany.',
            saveBlockSuccess: 'Zapisany blok został utworzony!',
            removeBlockSuccess: 'Zapisany blok został usunięty!',
            updateBlockSuccess: 'Zapisany blok został zaktualizowany!',
            sentEmailSuccessTitle: 'Email został wysłany!',
            sentEmailSuccess: 'Email został pomyślnie wysłany na adres {replace}.',
            sentEmailFailedTitle: 'Wysyłanie emaila nie powiodło się!',
            sentEmailFailed: 'Email nie został wysłany na adres {replace}.',
            closeEditorTitle: 'Nie zapisane zmiany zostaną utracone!',
            closeEditorText: 'Czy na pewno chcesz zamknąć edytor? Wszystkie niezapisane zmiany zostaną utracone.',
            closeEditorConfirm: 'Zamknij Edytor',
            templateRenameSuccessTitle: 'Szablon został zmieniony!',
            templateRenameFailedTitle: 'Nie udało się zmienić nazwy szablonu!',
            templateRenameTitle: "Zmień nazwę szablonu",
            templateRenameLabel: "Nazwa szablonu",
            cancel: "Anuluj",
            confirm: "Potwierdź",
            rename: "Zmień nazwę",
            delete: "Usuń",
            savedBlockExistsText: "Blok zapisany pod tą nazwą już istnieje.",
            savedBlockCreateTitle: "Utwórz nowy zapisany blok",
            savedBlockCreateLabel: "Nazwa zapisanego bloku",
            savedBlockCreatePlaceholder: "Wprowadź nową nazwę zapisanego bloku",
            savedBlockCreateFailed: "Tworzenie zapisanego bloku nie powiodło się!",
            savedBlockEditFailed: "Edycja zapisanego bloku nie powiodła się!",
            savedBlockRefetchFailed: "Ponowne pobieranie zapisanych bloków nie powiodło się!",
            savedBlockDeleteText: "Czy na pewno chcesz usunąć zapisany blok {replace} i całą jego zawartość?"  
        },
        ko: {
            saveSuccess: '템플릿이 성공적으로 저장되었습니다.',
            saveBlockSuccess: '저장된 블록이 생성되었습니다!',
            removeBlockSuccess: '저장된 블록이 제거되었습니다!',
            updateBlockSuccess: '저장된 블록이 업데이트되었습니다!',
            sentEmailSuccessTitle: '이메일 전송 성공!',
            sentEmailSuccess: '이메일이 성공적으로 {replace}에게 전송되었습니다.',
            sentEmailFailedTitle: '이메일 전송 실패!',
            sentEmailFailed: '이메일이 {replace}에게 전송되지 않았습니다.',
            closeEditorTitle: '저장되지 않은 변경 사항이 손실됩니다!',
            closeEditorText: '정말로 편집기를 닫으시겠습니까? 모든 저장되지 않은 변경 사항이 손실됩니다.',
            closeEditorConfirm: '편집기 닫기',
            templateRenameSuccessTitle: '템플릿 이름이 변경되었습니다!',
            templateRenameFailedTitle: '템플릿 이름 변경 실패!',
            templateRenameTitle: "템플릿 이름 변경",
            templateRenameLabel: "템플릿 이름",
            cancel: "취소",
            confirm: "확인",
            rename: "이름 변경",
            delete: "삭제",
            savedBlockExistsText: "이 이름의 저장된 블록이 이미 있습니다.",
            savedBlockCreateTitle: "새 저장된 블록 생성",
            savedBlockCreateLabel: "저장된 블록 이름",
            savedBlockCreatePlaceholder: "새 저장된 블록 이름 입력",
            savedBlockCreateFailed: "저장된 블록 생성 실패!",
            savedBlockEditFailed: "저장된 블록 편집 실패!",
            savedBlockRefetchFailed: "저장된 블록 다시 가져오기 실패!",
            savedBlockDeleteText: "저장된 블록 {replace} 및 모든 내용을 정말 삭제하시겠습니까?"  
        },
        vi: {
            saveSuccess: 'Mẫu đã được lưu thành công.',
            saveBlockSuccess: 'Khối đã lưu được tạo!',
            removeBlockSuccess: 'Khối đã lưu đã bị xóa!',
            updateBlockSuccess: 'Khối đã lưu đã được cập nhật!',
            sentEmailSuccessTitle: 'Email đã được gửi!',
            sentEmailSuccess: 'Email đã được gửi thành công đến {replace}.',
            sentEmailFailedTitle: 'Gửi email thất bại!',
            sentEmailFailed: 'Email không được gửi đến {replace}.',
            closeEditorTitle: 'Những thay đổi chưa lưu sẽ bị mất!',
            closeEditorText: 'Bạn có chắc chắn muốn đóng trình soạn thảo không? Tất cả những thay đổi chưa lưu sẽ bị mất.',
            closeEditorConfirm: 'Đóng Trình soạn thảo',
            templateRenameSuccessTitle: 'Mẫu đã được đổi tên!',
            templateRenameFailedTitle: 'Đổi tên mẫu không thành công!',
            templateRenameTitle: "Đổi tên mẫu",
            templateRenameLabel: "Tên mẫu",
            cancel: "Hủy bỏ",
            confirm: "Xác nhận",
            rename: "Đổi tên",
            delete: "Xóa",
            savedBlockExistsText: "Khối đã lưu với tên này đã tồn tại.",
            savedBlockCreateTitle: "Tạo khối đã lưu mới",
            savedBlockCreateLabel: "Tên khối đã lưu",
            savedBlockCreatePlaceholder: "Nhập tên mới cho khối đã lưu",
            savedBlockCreateFailed: "Tạo khối đã lưu thất bại!",
            savedBlockEditFailed: "Chỉnh sửa khối đã lưu thất bại!",
            savedBlockRefetchFailed: "Tải lại các khối đã lưu thất bại!",
            savedBlockDeleteText: "Bạn có chắc chắn muốn xóa khối đã lưu {replace} và tất cả nội dung của nó?"   
        },
        he: {
            saveSuccess: 'התבנית נשמרה בהצלחה.',
            saveBlockSuccess: 'בלוק המורכב נשמר בהצלחה!',
            removeBlockSuccess: 'בלוק המורכב נמחק בהצלחה!',
            updateBlockSuccess: 'בלוק המורכב עודכן בהצלחה!',
            sentEmailSuccessTitle: 'הודעת דוא"ל נשלחה!',
            sentEmailSuccess: 'הודעת הדוא"ל נשלחה בהצלחה לכתובת {replace}.',
            sentEmailFailedTitle: 'שליחת הדוא"ל נכשלה!',
            sentEmailFailed: 'הדוא"ל לא נשלח לכתובת {replace}.',
            closeEditorTitle: 'שינויים שלא נשמרו יאבדו!',
            closeEditorText: 'האם אתה בטוח שברצונך לסגור את העורך? כל השינויים שאינם נשמרים יאבדו.',
            closeEditorConfirm: 'סגור עורך',
            templateRenameSuccessTitle: 'התבנית שונתה!',
            templateRenameFailedTitle: 'שינוי שם התבנית נכשל!',
            templateRenameTitle: "שנה שם תבנית",
            templateRenameLabel: "שם התבנית",
            cancel: "ביטול",
            confirm: "אישור",
            rename: "שינוי שם",
            delete: "מחיקה",
            savedBlockExistsText: "קיים כבר בלוק שמור עם שם זה.",
            savedBlockCreateTitle: "צור בלוק שמור חדש",
            savedBlockCreateLabel: "שם הבלוק השמור",
            savedBlockCreatePlaceholder: "הזן שם חדש לבלוק השמור",
            savedBlockCreateFailed: "יצירת הבלוק השמור נכשלה!",
            savedBlockEditFailed: "עריכת הבלוק השמור נכשלה!",
            savedBlockRefetchFailed: "טעינת הבלוקים השמורים מחדש נכשלה!",
            savedBlockDeleteText: "האם אתה בטוח שברצונך למחוק את הבלוק השמור {replace} ואת כל התוכן שלו?"  
        },
    };

    const getTranslations = (key: string, replace?: string|string[]) => {
        if(Array.isArray(replace)) {
            replace = replace.join(', ');
        }
        
        // @ts-expect-error
        const translationString = translations[language] ? translations[language][key] : translations['en'][key];

        return translationString.replace('{replace}', replace);
    };

    return {
       translations,
       getTranslations,
    };

}


