<template>
    <div>
        <Modal data-cy="create-team-modal" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">
                    Publish new marketplace template
                </div>
            </template>
            <template #body>
                <div class="bg-white">
                    <form
                        class="flex justify-center px-6 sm:px-0"
                        @submit.prevent="submit"
                    >
                        <div class="mt-4 flex flex-col gap-6 pb-6">
                            <!-- <div class="overflow-auto sm:w-96 lg:w-[420px] h-96">
                                <img
                                    :src="'https://s3-eu-west-1.amazonaws.com/topolio/screens/team-3260/screenshots/252844.jpg?v=1678390998'"
                                    class="object-cover object-top w-full h-[1200px]"
                                />
                            </div> -->

                            <TextInput
                                v-model="v$.form.name.$model"
                                required
                                class="w-full sm:w-96 lg:w-[420px]"
                                label="Title"
                                placeholder="Enter title (max. 50 characters)"
                                :error="getError('name')"
                            />

                            <TextAreaInput
                                v-model="v$.form.description.$model"
                                required
                                :rows="6"
                                class="w-full sm:w-96 lg:w-[420px]"
                                label="Description"
                                placeholder="Enter description (max. 200 characters)"
                                :error="getError('description')"
                            />

                            <SelectInput
                                v-model="v$.form.category_id.$model"
                                :data="categoryItems"
                                placeholder="Category"
                                label="Category"
                                class="w-full sm:w-96 lg:w-[420px]"
                                required
                                :error="getError('category_id')"
                            ></SelectInput>

                            <MultipleSelect
                                v-model:value="selectedKeywords"
                                label="Keywords"
                                class="w-full sm:w-96 lg:w-[420px]"
                                placeholder="Select keywords"
                                :options="keywords"
                            />

                            <CheckboxInput
                                v-model:checked="v$.form.free.$model"
                                size="lg"
                                name="free"
                                class="mt-2"
                                label="This template is available for free download"
                                :has-error="v$.form.free.$error"
                            />

                            <div
                                class="flex justify-between gap-4 border-t border-gray-300 pt-6"
                            >
                                <AtomicButton
                                    color="white"
                                    @click="$emit('back')"
                                >
                                    Cancel
                                </AtomicButton>

                                <AtomicButton color="yellow" type="submit">
                                    Publish
                                </AtomicButton>
                            </div>
                        </div>
                    </form>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import IInputErrors from '@/types/Atomic/IInputErrors';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ITemplate from '@/types/Templates/ITemplate';
import { PropType } from 'vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import ISelectable from '@/types/Atomic/ISelectable';
import { sameAs } from '@vuelidate/validators';

const props = defineProps({
    template: {
        type: Object as PropType<ITemplate>,
        required: true,
    },
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const categoryItems = [] as ISelectable[];
const selectedKeywords = ref([]);

onBeforeMount(() => {
    props.categories.forEach((a: ICategory) => {
        categoryItems.push({
            title: a.title as string,
            value: a.id,
        });
    });
});

defineEmits(['publish', 'back', 'close']);

interface IForm {
    form: {
        name: string;
        description: string;
        category_id?: number;
        keywords: number[];
        free: boolean;
    };
}

const state: IForm = reactive({
    form: {
        name: props.template.name,
        description: '',
        category_id: undefined,
        keywords: [],
        free: true,
    },
});
const rules = {
    form: {
        name: { required },
        description: { required },
        category_id: {
            required,
        },
        free: {
            checked: sameAs(true),
        },
    },
};
const v$ = useVuelidate(rules, state);
const loading = ref(false);
const errors = ref<IInputErrors>({});

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;

        try {
            selectedKeywords.value.forEach((a: IKeyword) =>
                state.form.keywords.push(a.id)
            );

            router.post(
                route('marketplace.created-templates.store', {
                    template: props.template.id,
                }),
                state.form,
                {
                    onSuccess: () => {
                        create({
                            title: 'Template published',
                            type: 'success',
                        });
                        errors.value = {};
                        v$.value.$reset();
                    },
                    onError: (err) => {
                        errors.value = err;
                        create({
                            title: 'Template not published',
                            type: 'error',
                        });
                    },
                    onFinish: () => {
                        loading.value = false;
                    },
                }
            );
        } catch (err) {
            () => undefined;
        } finally {
            loading.value = false;
        }
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
