<template>
    <svg xmlns="http://www.w3.org/2000/svg">
        <path
            d="M93.68 25.86h-2.606v1.3c-.905-1-2.132-1.57-3.532-1.57-3.058 0-5.277 2.537-5.277 6.04 0 3.375 2.218 5.848 5.255 5.848 1.42 0 2.498-.473 3.554-1.612v1.57c0 2.343-.926 3.504-2.864 3.504-1.034 0-2.068-.516-2.52-1.31-.258-.45-.345-.88-.43-1.85h-2.54c.022 3.375 2.11 5.482 5.47 5.482 1.917 0 3.597-.774 4.523-2.17.754-1.075.97-2.064.97-4.257zm-5.664 2.02c1.874 0 3.166 1.462 3.166 3.6 0 2.193-1.25 3.655-3.123 3.655-1.895 0-3.123-1.42-3.123-3.612-.022-2.193 1.206-3.633 3.08-3.633z"
            fill="#c02126"
        />
        <path
            d="M43.542 25.86v11.244h2.584V31.3c0-2.386.646-3.418 2.1-3.418 1.163 0 1.723.967 1.723 2.988v6.235h2.584V31.3c0-2.408.646-3.418 2.1-3.418 1.25 0 1.723.838 1.723 2.967v6.256h2.584V30.16c0-3-1.292-4.6-3.8-4.6-1.378 0-2.52.58-3.317 1.655-.7-1.075-1.745-1.655-2.994-1.655-1.12 0-1.7.3-2.67 1.333V25.86zm25.112 0v1.3c-1.12-1.14-2.1-1.612-3.5-1.612-3.015 0-5.32 2.515-5.32 5.848 0 3.526 2.24 6.04 5.384 6.04 1.357 0 2.24-.408 3.446-1.526v1.182h2.606V25.86zm-3.058 2.02c1.895 0 3.2 1.505 3.2 3.612 0 2.17-1.314 3.633-3.2 3.633-1.83 0-3.123-1.526-3.123-3.633 0-2.085 1.335-3.612 3.123-3.612zm10.316-2.02h-2.606v11.244h2.606zm-1.27-6.256c-.97 0-1.723.752-1.723 1.677S73.694 23 74.6 23c.948 0 1.723-.774 1.723-1.72s-.754-1.677-1.68-1.677zm3.66-2.858h2.606v20.36h-2.606z"
            fill="#646569"
        />
        <path
            d="M97.837 25.86H95.23v6.514c0 1.763.323 2.73 1.185 3.655.905.924 2.132 1.42 3.618 1.42 1.508 0 2.735-.473 3.64-1.42.86-.924 1.185-1.892 1.185-3.655V25.86h-2.606v6.47c0 1.913-.7 2.795-2.218 2.795-1.508 0-2.197-.88-2.197-2.795zm8.743 0v11.244h2.606v-5.418c0-2.773.646-3.784 2.4-3.784 1.615 0 2.1.73 2.1 2.945v6.278h2.606v-6.9c0-3.16-1.314-4.644-4.07-4.644-1.228 0-2.003.344-3.015 1.333v-1.032h-2.628zm-85.365-2.437a6.63 6.63 0 0 0-6.624 6.613 6.63 6.63 0 0 0 6.624 6.613 6.63 6.63 0 0 0 6.624-6.613 6.63 6.63 0 0 0-6.624-6.613zm-10.312 6.613a10.31 10.31 0 0 1 10.312-10.294c5.692 0 10.312 4.6 10.312 10.294 0 .393-.036.75-.072 1.108-.072 1 .645 1.75 1.647 1.75 1.683 0 1.862-2.18 1.862-2.895 0-7.613-6.16-13.76-13.786-13.76S7.393 22.387 7.393 30s6.16 13.76 13.786 13.76c4.046 0 7.663-1.75 10.205-4.504l2.83 2.36c-3.187 3.574-7.842 5.826-13.034 5.826-9.668 0-17.474-7.828-17.474-17.443 0-9.65 7.842-17.443 17.474-17.443 9.668 0 17.474 7.828 17.474 17.443 0 3.86-1.862 7.006-5.55 7.006-1.647 0-2.614-.75-3.187-1.573-1.826 2.93-5.05 4.86-8.773 4.86-5.622.036-10.24-4.575-10.24-10.258zm10.312-3.038c1.683 0 3.044 1.358 3.044 3.002a3.04 3.04 0 1 1-6.087 0c.036-1.644 1.36-3.002 3.044-3.002z"
            fill="#c02126"
        />
    </svg>
</template>
