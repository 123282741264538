<template>
    <div class="relative">
        <AtomicButton size="sm" color="white" @click="copyToClipboard">
       <template #icon>
           <LinkIcon class="w-5 h-5" />
       </template>
       </AtomicButton>

       <transition
            enter-active-class="transition duration-100 ease-out transform"
            enter-from-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in transform"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <Tooltip
                v-if="copiedSuccess"
                class="absolute top-[-80px]"
                style="left: 50%; transform: translate(-50%, 0)"
            >
                Copied to clipboard!
            </Tooltip>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core';
import { LinkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    code: {
        type: String,
        required: true,
    },
});

const clipboard = useClipboard();
const copiedSuccess = ref(false);

const copyToClipboard = () => {
    clipboard.copy(props.code);

    copiedSuccess.value = true;
    setTimeout(() => {
        copiedSuccess.value = false;
    }, 1000);
};
</script>