<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Open AI Logs">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';

import {
    CommandLineIcon,
    UserGroupIcon,
    UserIcon,
} from '@heroicons/vue/24/outline';

defineProps({
    tabKey: {
        type: String,
        default: 'all-logs',
    },
});

let tabs: TabItem[] = [
    {
        title: 'All logs',
        key: 'all-logs',
        icon: CommandLineIcon,
        url: route('kiosk.open-ai.index'),
    },
    {
        title: 'Total per team',
        key: 'total-per-team',
        icon: UserGroupIcon,
        url: route('kiosk.open-ai.total-per-team.index'),
    },
    {
        title: 'Users per team',
        key: 'users-per-team',
        icon: UserIcon,
        url: route('kiosk.open-ai.user-per-team.index', {
            team: 3620,
        }),
    },
];
</script>
