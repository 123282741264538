<template>
    <div>
        <div class="pb-5 mt-5 grid-cols-layout lg:grid">
            <div class="mb-6 lg:mt-4 lg:mb-0">
                <h3
                    class="mb-[5px] text-lg font-medium leading-normal tracking-small text-gray-800"
                >
                    Webhook
                </h3>

                <p class="max-w-xs text-sm text-gray-500">
                    
                    You can use our webhook to receive data when a template is saved in Editor.
                    Read more about webhooks in our
                    <a
                        class="text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://support.topol.io/en/articles/9743943-webhooks-in-topol-pro"
                        target="_blank"
                        >documentation</a
                    >.
                </p>
            </div>

            <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
                <div v-if="!webhook" class="flex justify-center">
                    <div class="flex flex-col mx-6 my-5">
                        <p class="text-base text-gray-700">
                            There is no webhook set up yet.
                        </p>
    
                        <AtomicButton
                                type="submit"
                                color="yellow"
                                size="md"
                                class="mx-auto mt-4"
                                @click="showWebhooksModal = true"
                            >
                               Create
                        </AtomicButton>
                    </div>
                </div>

                <div v-else>
                    
                    <div class="flex flex-col gap-4 px-6 pt-6 pb-6 border-b border-gray-200 sm:pb-8 sm:flex-row sm:justify-between">
                        <div>
                            <div class="text-sm text-gray-500">Webhook URL</div>
                            <div class="text-sm text-gray-800 break-all">{{ webhook.url }}</div>

                            <div class="mt-2 text-sm text-gray-500">Automatic sync enabled</div>
                            <div class="text-sm text-gray-800">{{ webhook.enabled ? 'Yes' : 'No' }}</div>
                        </div>

                        <div class="min-w-[150px]">
                            <div class="text-sm text-gray-500 ">Signature Secret Key</div>
                            <div class="text-sm font-medium cursor-pointer text-topol-blue-500 hover:text-topol-blue-600" @click="showSecretKeyModal = true">Show</div> 
                        </div>

                    </div>

                    <div class="flex justify-end gap-2 px-6 py-3">
                        <AtomicButton
                            type="submit"
                            color="white"
                            size="sm"
                            :disabled="loading"
                            @click="showWebhooksModal = true"
                        >
                            Edit
                        </AtomicButton>

                        <AtomicButton
                            type="submit"
                            color="red"
                            size="sm"
                            :disabled="loading"
                            @click="deleteWebhook"
                        >
                            Delete
                        </AtomicButton>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <WebhooksModal
            v-if="showWebhooksModal"
            :webhook="webhook"
            @close="showWebhooksModal = false"
        />
        <WebhooksSecretKeyModal
            v-if="showSecretKeyModal"
            :webhook="webhook"
            @close="showSecretKeyModal = false"
        />
</template>

<script lang="ts" setup>
import useNewFeatures from '@/composables/useNewFeatures';
import { PropType } from 'vue';
import IWebhook from '@/types/Webhooks/IWebhook';
import useConfirm from '@composables/useConfirm';

const props = defineProps({
    webhook: {
        type: Object as PropType<IWebhook>,
        default: null,
    },
});
const { setNewFeatureAsVisited, featureVisited } = useNewFeatures();

const showWebhooksModal = ref(false);
const showSecretKeyModal = ref(false);
const loading = ref(false);
const { show } = useConfirm();

onMounted(() => {
    if (!featureVisited('webhook_template_saved')) {
        setNewFeatureAsVisited('webhook_template_saved');
    }
});

const deleteWebhook = async () => {
    const confirmed = await show({
        type: 'danger',
        header: 'Delete webhook',
        message: 'Are you sure you want to delete this webhook?',
    });

    if (confirmed) {
        loading.value = true;

        router.delete(route('webhooks.template-saved.destroy', {
            webhook: props.webhook.id,
        }), {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Webhook deleted',
                    text: 'Your webhook has been deleted successfully.',
                    type: 'success',
                });
            },
            onError: (err) => {
                create({
                    title: 'Error',
                    text: 'An error occurred while deleting the webhook.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        })
    }
};



</script>
