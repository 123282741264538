<template>
    <form
        class="pb-5 mt-5 grid-cols-layout lg:grid"
        @submit.prevent="updateColors"
    >
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Custom colors
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                You can customize the predefined colors in color picker in the
                editor.
            </p>
        </div>
        <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
            <div class="border-b border-gray-200 px-6 pb-5 pt-[23px]">
                <div class="flex flex-wrap justify-start gap-4">
                    <div
                        v-for="(color, index) in colors"
                        :key="index"
                        class="group relative flex w-[120px] cursor-pointer items-center gap-2 rounded-md bg-gray-50 p-2 text-sm font-medium text-gray-600 transition duration-200 hover:shadow-md"
                        :class="{
                            'ring-1 ring-gray-300':
                                selectedColorIndex !== index,
                            'ring-2 ring-orange-500':
                                selectedColorIndex === index,
                        }"
                        @click="handleShowPicker(index)"
                    >
                        <ColorPicker
                            v-if="showPicker && selectedColorIndex === index"
                            :id="'element-' + index"
                            v-click-away="closeColorPicker"
                            class="absolute bottom-12"
                            :color="color"
                            @close="closeColorPicker"
                            @delete="deleteColor(index)"
                            @changeColor="changeColor"
                        />

                        <div
                            v-if="color === 'rgba(0,0,0,0)'"
                            :style="{ background: `url(${imgAlphaBase64})` }"
                            class="h-4 w-4 rounded-[3px]"
                        />
                        <div
                            v-else
                            :style="{
                                background:
                                    selectedColorIndex === index && newColor
                                        ? newColor
                                        : color,
                            }"
                            class="h-4 w-4 rounded-[3px]"
                        />

                        {{
                            color === 'rgba(0,0,0,0)'
                                ? 'transparent'
                                : selectedColorIndex === index && newColor
                                ? newColor
                                : color
                        }}
                    </div>

                    <AtomicButton size="md" color="white" @click="addColor">
                        Add color
                    </AtomicButton>
                </div>
            </div>

            <div class="flex justify-between gap-4 px-6 py-3">
                <AtomicButton
                    :disabled="colors.length === 0"
                    type="button"
                    color="white"
                    size="sm"
                    @click="deleteAllColors"
                >
                    Delete all
                </AtomicButton>

                <div class="flex gap-4">
                    <AtomicButton
                        type="button"
                        color="white"
                        size="sm"
                        @click="resetColors"
                    >
                        Reset to default
                    </AtomicButton>

                    <AtomicButton
                        :disabled="saveDisabled"
                        type="submit"
                        color="yellow"
                        size="sm"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>
        </div>
    </form>
</template>
<script lang="ts" setup>
import IEditorSettings from '@/types/Settings/IEditorSettings';
import { PropType } from 'vue';
import useColorPicker from '@atomic/ColorPicker/useColorPicker';

const props = defineProps({
    editorSettings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
});

const emit = defineEmits(['updateColors']);

const { createAlphaSquare } = useColorPicker();

onMounted(() => {
    imgAlphaBase64.value = createAlphaSquare(4).toDataURL();
});

const selectedColorIndex = ref<number | null>(null);
const showPicker = ref(false);
const newColor = ref('');
const defaultColors = [
    '#000000',
    '#FFFFFF',
    '#FF1900',
    '#F47365',
    '#FFB243',
    '#FFE623',
    '#6EFF2A',
    '#1BC7B1',
    '#00BEFF',
    '#2E81FF',
    '#5D61FF',
    '#FF89CF',
    '#FC3CAD',
    '#BF3DCE',
    '#8E00A7',
    'rgba(0,0,0,0)',
];
const colors = ref(
    props.editorSettings.colors.length !== 0
        ? [...props.editorSettings.colors]
        : defaultColors
);
const imgAlphaBase64 = ref('');

const saveDisabled = computed(() => {
    return (
        JSON.stringify(colors.value) ===
        JSON.stringify(props.editorSettings.colors)
    );
});

const handleShowPicker = (index: number) => {
    closeColorPicker();
    selectedColorIndex.value = index;
    showPicker.value = true;
    nextTick(() => {
        setElementPosition();
    });
};

const setElementPosition = () => {
    const element = document.querySelector(
        '#element-' + selectedColorIndex.value
    );

    if (element) {
        const bounding = element.getBoundingClientRect();

        if (
            bounding.right + bounding.width >
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
            element.classList.add('right-0');
        } else {
            element.classList.add('left-0');
        }
    }
};

const closeColorPicker = () => {
    if (selectedColorIndex.value === null) return;

    if (newColor.value !== '') {
        colors.value[selectedColorIndex.value] = newColor.value;
    }

    selectedColorIndex.value = null;
    showPicker.value = false;
    newColor.value = '';
};

const changeColor = (e) => {
    if (e.hex === '#000000' && e.rgba.a === 0) {
        newColor.value = 'rgba(0,0,0,0)';
        return;
    }
    newColor.value = e.hex;
};

const deleteColor = (index: number) => {
    closeColorPicker();
    colors.value.splice(index, 1);
};

const addColor = () => {
    colors.value.push('#000000');
    handleShowPicker(colors.value.length - 1);
};

const deleteAllColors = () => {
    colors.value = [];
};

const resetColors = () => {
    colors.value = defaultColors;
};

const updateColors = () => {
    emit('updateColors', colors.value);
};
</script>
