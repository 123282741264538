<template>
    <IntegrationModal
        title="MailerLite"
        documentation-url="https://support.topol.io/en/articles/8630975-export-to-mailerlite"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createIntegration"
        @edit="updateIntegration"
    >
        <template #inputs>
            <div class="w-full mb-6">
                <TextInput
                    v-model="v$.name.$model"
                    class="mt-6"
                    placeholder="Enter your preferred integration name"
                    label="Name"
                    :error="getError('name')"
                />

                <TextInput
                    v-model="v$.api_key.$model"
                    class="w-full mt-6"
                    placeholder="Your MailerLite API key"
                    label="API key"
                    required
                    :error="getError('api_key')"
                />

                <TextInput
                    v-model="v$.sender_name.$model"
                    class="w-full mt-6"
                    placeholder="Your MailerLite sender name"
                    label="Sender name"
                    required
                    :error="getError('sender_name')"
                />

                <TextInput
                    v-model="v$.sender_email.$model"
                    class="w-full mt-6"
                    placeholder="Your MailerLite sender email"
                    label="Sender email"
                    required
                    :error="getError('sender_email')"
                >
                    <template #help>
                        <div>
                            Use verified email address from your MailerLite
                            account
                        </div>
                    </template>
                </TextInput>
            </div>
        </template>
    </IntegrationModal>
</template>
<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IBrevo, IIntegration } from '@/types/Integrations/IIntegration';
import useIntegrations from '../../composables/useIntegrations';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<IBrevo>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});
const { mailerliteIntegrationModalOpened } = useIntegrations();
const state = reactive({
    name: props.integration?.name || '',
    api_key: '',
    sender_name: props.integration?.data.sender.name || '',
    sender_email: props.integration?.data.sender.email || '',
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
    api_key: {
        required,
        maxLength: maxLength(5000),
    },
    sender_name: {
        required,
        maxLength: maxLength(255),
    },
    sender_email: {
        required,
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};

const createIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('integrations.mailer-lite.store'),
            {
                name: state.name,
                api_key: state.api_key,
                sender_name: state.sender_name,
                sender_email: state.sender_email,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Integration added',
                        text: 'MailerLite integration has been successfully added.',
                        type: 'success',
                    });
                    mailerliteIntegrationModalOpened.value = false;
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not added',
                        text: 'There was an error adding MailerLite integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('integrations.mailer-lite.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
                api_key: state.api_key,
                sender_name: state.sender_name,
                sender_email: state.sender_email,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating MailerLite integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
