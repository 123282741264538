<template>
    <div>
        <slot />

        <ConfirmDialog></ConfirmDialog>
        <Notifications></Notifications>
    </div>
</template>

<script lang="ts" setup>
import ConfirmDialog from '@atomic/Dialogs/ConfirmDialog.vue';
import Notifications from '@/components/Notifications.vue';
import { onMounted, computed } from 'vue';
import Bugsnag from '@bugsnag/js';
import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

onMounted(() => {
    const userObject = {
        id: globalProps.value.user?.id ?? null,
        email: globalProps.value.user?.email ?? null,
        current_team_id: teamProps.value?.id ?? null,
    };

    Bugsnag.setUser(JSON.stringify(userObject));
});
</script>
