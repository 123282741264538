<template>
    <div class="py-12">
        <h1
            class="text-2xl font-medium leading-normal tracking-small text-gray-800"
        >
            Admin users
        </h1>
        <div class="mt-10 flex flex-col sm:flex-row">
            <TextInput
                v-model="form.email"
                class="w-52 sm:mr-6 sm:w-80"
                label="New admin"
                placeholder="Provide valid e-mail address"
            />
            <div class="mt-4 sm:mt-[27px]">
                <AtomicButton
                    class=""
                    color="yellow"
                    size="sm"
                    :disabled="!form.email"
                    @click="handlePromoteUser"
                    >Promote user</AtomicButton
                >
            </div>
        </div>
        <DataTable class="mt-10" :headers="headers" :items="admins">
            <template #item-profile_photo_path="{ data, row }">
                <div class="flex justify-center">
                    <div
                        class="h-10 w-10 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                    >
                        <img
                            :src="data ? data : row.profile_photo_url"
                            class="h-10 w-10 rounded-full object-cover"
                        />
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <AtomicButton
                    color="red"
                    size="sm"
                    @click="handleDemoteAdmin(row)"
                >
                    <template #icon>
                        <UserMinusIcon class="h-6 w-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { UserMinusIcon } from '@heroicons/vue/24/outline';
import TextInput from '@atomic/Inputs/TextInput.vue';
import IUser from '@/types/Members/IUser';
import route from 'ziggy-js';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { useForm } from '@inertiajs/vue3';
import useConfirm from '@/composables/useConfirm';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';

type AdminForm = {
    email: string;
};

defineProps<{
    admins: IPaginationData<IUser>;
}>();

const headers: IDataTableHeader[] = [
    { text: '', id: 'profile_photo_path' },
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Email',
        id: 'email',
        sortable: true,
        sortableId: 'email',
    },
    { text: '', id: 'custom' },
];

const { show } = useConfirm();

const form = useForm<AdminForm>({
    email: '',
});

const handleDemoteAdmin = async (user: IUser) => {
    const confirmed = await show({
        header: `Demote admin ${user.name}`,
        message: `Are you sure you want to demote admin ${user.name}?`,
        type: 'danger',
        confirm: 'Demote',
    });
    if (confirmed) {
        demoteAdmin(user);
    }
};

const demoteAdmin = (user: IUser) => {
    router.delete(
        route('kiosk.admins.delete', {
            user: user.id,
        }),
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Admin demoted',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Admin not demoted',
                    type: 'error',
                });
            },
        }
    );
};

const promoteUser = () => {
    form.post(route('kiosk.admins.store'), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'User promoted',
                type: 'success',
            });
            form.email = '';
        },
        onError: () => {
            create({
                title: 'User not promoted',
                type: 'error',
            });
        },
    });
};

const handlePromoteUser = async () => {
    const confirmed = await show({
        header: `Promote user`,
        message: `Are you sure you want to promote user with e-mail ${form.email}?`,
        type: 'info',
        confirm: 'Promote',
    });
    if (confirmed) {
        promoteUser();
    }
};
</script>

<style></style>
