<template>
    <Tabs :selected-team="selectedTeam" tab-key="members">
        <DataTable
            class="mt-12"
            :title="`Members of ${selectedTeam.name}`"
            searchable
            :headers="headers"
            :items="members"
        >
            <template #item-email_verified_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex w-full justify-center">
                    <AtomicButton
                        color="white"
                        size="sm"
                        @click="handleShowUser(row as IUser)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IUser from '@/types/Members/IUser';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';

import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

import Tabs from '../components/TeamTabs.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';

defineProps<{
    selectedTeam: ICurrentTeam;
    members: IPaginationData<IUser>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Role',
        id: 'role',
        sortable: true,
        sortableId: 'role',
    },
    {
        text: 'Joined',
        id: 'email_verified_at',
        align: 'center',
    },
    {
        text: '',
        id: 'custom',
    },
];

const handleShowUser = function (user: IUser) {
    router.get(
        route('kiosk.users.activity.index', {
            user: user.id,
        })
    );
};
</script>
