<template>
    <div class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Merge tags
            </h3>

            <p class="max-w-xs text-sm text-gray-500">
                Merge tags, special links or special content offers a convenient
                method for seamlessly inserting text snippets directly within
                the text editor, as well as into any text field as you type.
            </p>
        </div>

        <div
            class="mx-auto h-full w-full rounded-md bg-white p-6 shadow-card lg:mx-0"
        >
            <div class="flex justify-end">
                <AtomicButton
                    v-if="mailchimpIntegrations.length > 0"
                    color="white"
                    size="md"
                    class="mb-6"
                    @click="showMailchimpSyncModal = true"
                >
                    <template #icon>
                        <ArrowPathIcon class="mr-2 h-5 w-5" />
                    </template>

                    {{ hasMailchimpGroup ? 'Update ' : 'Add ' }} Mailchimp tags
                </AtomicButton>
            </div>

            <div class="flex items-center">
                <div class="flex flex-col flex-wrap gap-6 sm:flex-row">
                    <div
                        v-for="(group, groupIndex) in tags"
                        :key="groupIndex"
                        class="w-full rounded-md border border-gray-300 bg-white text-sm sm:w-[300px]"
                    >
                        <div
                            class="group flex cursor-pointer items-center justify-between rounded-t-md bg-gray-200 p-2 font-medium text-gray-800"
                        >
                            <div class="flex items-center gap-2">
                                <span class="py-1">{{ group.name }}</span>
                                <PencilSquareIcon
                                    class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                    @click="editItem(groupIndex)"
                                />
                            </div>
                            <XMarkIcon
                                class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                @click="deleteItem(groupIndex)"
                            />
                        </div>

                        <div
                            v-for="(item, itemIndex) in group.items"
                            :key="itemIndex"
                            class="cursor-pointer border-b border-gray-200"
                        >
                            <div
                                v-if="'text' in item && item.text"
                                class="group flex cursor-pointer items-center justify-between p-2 text-gray-600 hover:bg-gray-100"
                            >
                                <div class="flex items-center gap-2">
                                    <span class="py-1">{{ item.text }}</span>
                                    <PencilSquareIcon
                                        class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                        @click="editItem(groupIndex, itemIndex)"
                                    />
                                </div>
                                <XMarkIcon
                                    class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                    @click="deleteItem(groupIndex, itemIndex)"
                                />
                            </div>

                            <div v-if="'name' in item && item.name">
                                <div
                                    class="group flex cursor-pointer items-center justify-between p-2 text-gray-600 hover:bg-gray-100"
                                >
                                    <div class="flex items-center gap-2">
                                        <span class="py-1">{{
                                            item.name
                                        }}</span>
                                        <PencilSquareIcon
                                            class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                            @click="
                                                editItem(groupIndex, itemIndex)
                                            "
                                        />
                                    </div>

                                    <XMarkIcon
                                        class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                        @click="
                                            deleteItem(groupIndex, itemIndex)
                                        "
                                    />
                                </div>

                                <div
                                    v-for="(
                                        nestedItem, nestedItemIndex
                                    ) in item.items"
                                    :key="nestedItemIndex"
                                    class="group flex cursor-pointer items-center justify-between py-2 pl-4 pr-2 text-gray-500 hover:bg-gray-100"
                                >
                                    <div
                                        v-if="
                                            'text' in nestedItem &&
                                            nestedItem.text
                                        "
                                        class="flex items-center gap-2"
                                    >
                                        <span class="py-1">{{
                                            nestedItem.text
                                        }}</span>
                                        <PencilSquareIcon
                                            class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                            @click="
                                                editItem(
                                                    groupIndex,
                                                    itemIndex,
                                                    nestedItemIndex
                                                )
                                            "
                                        />
                                    </div>

                                    <XMarkIcon
                                        class="hidden h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700 group-hover:block"
                                        @click="
                                            deleteItem(
                                                groupIndex,
                                                itemIndex,
                                                nestedItemIndex
                                            )
                                        "
                                    />
                                </div>

                                <div
                                    class="group flex h-10 cursor-pointer items-center justify-center hover:bg-gray-100"
                                    @click="createItem(groupIndex, itemIndex)"
                                >
                                    <PlusCircleIcon
                                        class="h-6 w-6 text-gray-500 group-hover:text-gray-700"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="group flex h-10 cursor-pointer items-center justify-center rounded-b-md hover:bg-gray-100"
                            @click="createItem(groupIndex)"
                        >
                            <PlusCircleIcon
                                class="h-6 w-6 text-gray-500 group-hover:text-gray-700"
                            />
                        </div>
                    </div>

                    <div
                        class="group flex h-10 w-full cursor-pointer items-center justify-center gap-2 rounded-md rounded-b-md border border-gray-300 bg-gray-200 font-medium hover:bg-gray-100 sm:w-[300px]"
                        @click="addGroup"
                    >
                        New group
                        <PlusCircleIcon
                            class="h-6 w-6 text-gray-500 group-hover:text-gray-700"
                            @click="addGroup"
                        />
                    </div>
                </div>
            </div>
        </div>

        <TagItemModal
            v-if="showTagItemModal"
            :tag="selectedItem"
            @close="handleCloseTagItemModal"
            @update="updateItem"
            @store="storeItem"
        />

        <MailchimpSyncModal
            v-if="showMailchimpSyncModal"
            :mailchimp-integrations="mailchimpIntegrations"
            @close="showMailchimpSyncModal = false"
            @store="addMailchimpGroup"
        />
    </div>
</template>

<script lang="ts" setup>
import {
    PlusCircleIcon,
    PencilSquareIcon,
    XMarkIcon,
    ArrowPathIcon,
} from '@heroicons/vue/24/outline';
import useConfirm from '@/composables/useConfirm';
import { PropType } from 'vue';
import IMergeTagItem from '@/types/Settings/IMergeTagItem';
import IMergeTagGroup from '@/types/Settings/IMergeTagGroup';
import IMailchimpList from '@/types/Settings/IMailchimpList';
import { IIntegration, IMailchimp } from '@/types/Integrations/IIntegration';

interface SelectedItem {
    item: IMergeTagItem | IMergeTagGroup | null;
    groupIndex: number | null;
    itemIndex: number | null | undefined;
    nestedItemIndex: number | null | undefined;
    hasItems: boolean;
}

type StoreItemData = {
    name: string;
    text: string;
    label: string;
    value: string;
};

const props = defineProps({
    modelValue: {
        type: Array as PropType<IMergeTagGroup[]>,
        required: true,
    },
    mailchimpIntegrations: {
        type: Array as PropType<IIntegration<IMailchimp>[]>,
        default: () => [],
    },
});

const emit = defineEmits(['update:modelValue']);

const { show: showConfirmation } = useConfirm();

const selectedItem = ref<SelectedItem>({
    item: null,
    groupIndex: null,
    itemIndex: null,
    nestedItemIndex: null,
    hasItems: false,
});

const showMailchimpSyncModal = ref(false);
const showTagItemModal = ref(false);

const tags = ref<IMergeTagGroup[]>(props.modelValue);

const handleCloseTagItemModal = () => {
    selectedItem.value.item = null;
    selectedItem.value.groupIndex = null;
    selectedItem.value.itemIndex = null;
    selectedItem.value.nestedItemIndex = null;
    selectedItem.value.hasItems = false;
    showTagItemModal.value = false;
};

const createItem = (groupIndex: number, itemIndex?: number) => {
    selectedItem.value.groupIndex = groupIndex;
    selectedItem.value.itemIndex = itemIndex;
    selectedItem.value.nestedItemIndex = null;
    selectedItem.value.hasItems = false;
    showTagItemModal.value = true;
};

const storeItem = (data: StoreItemData) => {
    const { groupIndex, itemIndex } = selectedItem.value;

    const item =
        data.name.length > 0
            ? {
                  name: data.name,
                  items: [],
              }
            : {
                  text: data.text,
                  value: data.value,
                  label: data.label,
              };

    if (typeof groupIndex === 'number') {
        if (typeof itemIndex === 'number') {
            const itemObject = tags.value[groupIndex].items[itemIndex];
            if ('items' in itemObject) {
                // @ts-ignore
                itemObject.items.push(item);
            }
        } else {
            // @ts-ignore
            tags.value[groupIndex].items.push(item);
        }

        emit('update:modelValue', tags.value);
    }

    handleCloseTagItemModal();
};

const editItem = (
    groupIndex: number,
    itemIndex?: number,
    nestedItemIndex?: number
) => {
    let item = null;
    if (typeof nestedItemIndex === 'number' && typeof itemIndex === 'number') {
        const itemObject = tags.value[groupIndex].items[itemIndex];
        if ('items' in itemObject) {
            item = itemObject.items[nestedItemIndex];
        }
    } else if (typeof itemIndex === 'number') {
        item = tags.value[groupIndex].items[itemIndex];
    } else {
        item = tags.value[groupIndex];
    }
    if (item) {
        selectedItem.value.item = item;
        selectedItem.value.groupIndex = groupIndex;
        selectedItem.value.itemIndex = itemIndex;
        selectedItem.value.nestedItemIndex = nestedItemIndex;
        selectedItem.value.hasItems = 'value' in item ? false : true;
        showTagItemModal.value = true;
    }
};

const updateItem = (data: StoreItemData) => {
    const { groupIndex, itemIndex, nestedItemIndex, hasItems } =
        selectedItem.value;

    if (
        typeof nestedItemIndex === 'number' &&
        typeof itemIndex === 'number' &&
        typeof groupIndex === 'number'
    ) {
        const itemObject = tags.value[groupIndex].items[itemIndex];
        if (hasItems && 'items' in itemObject) {
            const item = itemObject.items[nestedItemIndex];
            if ('name' in item) {
                item.name = data.name;
            }
        } else if (!hasItems && 'items' in itemObject) {
            const item = itemObject.items[nestedItemIndex];
            if ('text' in item) {
                item.text = data.text;
                item.value = data.value;
                item.label = data.label;
            }
        }
    } else if (
        typeof itemIndex === 'number' &&
        typeof groupIndex === 'number'
    ) {
        if (hasItems) {
            const item = tags.value[groupIndex].items[itemIndex];
            if ('name' in item) {
                item.name = data.name;
            }
        } else {
            const item = tags.value[groupIndex].items[itemIndex];
            if ('text' in item) {
                item.text = data.text;
                item.value = data.value;
                item.label = data.label;
            }
        }
    } else {
        if (typeof groupIndex === 'number') {
            if (hasItems) {
                tags.value[groupIndex].name = data.name;
            } else {
                const item = tags.value[groupIndex];
                if ('text' in item && 'value' in item && 'label' in item) {
                    item.text = data.text;
                    item.value = data.value;
                    item.label = data.label;
                }
            }
        }
    }
    emit('update:modelValue', tags.value);
    handleCloseTagItemModal();
};

const addGroup = async () => {
    const name = await showConfirmation({
        header: 'Add group',
        hasInput: true,
        inputData: {
            label: 'Group name',
        },
    });

    if (
        typeof name === 'string' &&
        tags.value.findIndex((tag) => tag.name === name) === -1
    ) {
        tags.value.push({
            name,
            items: [],
        });
        emit('update:modelValue', tags.value);
    }
};

const deleteItem = async (
    groupIndex: number,
    itemIndex?: number,
    nestedItemIndex?: number
) => {
    const confirmed = await showConfirmation({
        header: 'Delete item',
        message: `Do you really want to delete this item?`,
        type: 'danger',
    });

    if (confirmed) {
        if (
            typeof nestedItemIndex === 'number' &&
            typeof itemIndex === 'number'
        ) {
            const itemObject = tags.value[groupIndex].items[itemIndex];
            if ('items' in itemObject) {
                itemObject.items.splice(nestedItemIndex, 1);
            }
        } else if (typeof itemIndex === 'number') {
            tags.value[groupIndex].items.splice(itemIndex, 1);
        } else {
            tags.value.splice(groupIndex, 1);
        }
        emit('update:modelValue', tags.value);
    }
};

const hasMailchimpGroup = computed(() => {
    return tags.value.find(
        (tag) => tag.name.includes('Mailchimp') !== undefined
    );
});

const addMailchimpGroup = async (
    list: IMailchimpList,
    integrationId: number
) => {
    const result = await axios.get(
        route('integrations.mailchimp.merge-tags.index', {
            list: list.id,
            integration: integrationId,
        })
    );

    if (result.status === 200) {
        const mailchimpTags = result.data;

        if (
            tags.value.find((tag) => tag.name === `Mailchimp-${list.name}`) ===
            undefined
        ) {
            tags.value.push({
                name: `Mailchimp-${list.name}`,
                items: mailchimpTags,
            });
        } else {
            const mailchimpGroupIndex = tags.value.findIndex(
                (tag) => tag.name === `Mailchimp-${list.name}`
            );

            tags.value[mailchimpGroupIndex].items = mailchimpTags;
        }

        showMailchimpSyncModal.value = false;
    }
};
</script>
