<template>
    <div class="py-12">
        <div class="flex justify-end">
            <DateInput @set-interval="handleSetInterval" />
        </div>

        <TileStats :stats="tileStats" />

        <MainStats :date-from="dateFrom" :date-to="dateTo" />

        <SpecialTileStats
            :date-from="dateFrom"
            :date-to="dateTo"
            :subscription-stats="subscriptionStats"
            :integration-stats="integrationStats"
            :mrr-stats="mrrStats"
        />

        <div
            class="inline-flex mt-5 bg-white divide-gray-200 rounded-lg shadow"
        >
            <div class="px-3 py-5 text-gray-900 sm:p-5">
                See Umami analytics
                <ul class="mt-1 text-sm">
                    <li>
                        •
                        <a
                            href="https://master.d3pihb25dhhnn5.amplifyapp.com/share/0L1K8MQpQQvoGJ6P/topol.io"
                            target="_blank"
                            class="text-topol-blue-500 hover:text-topol-blue-600"
                            >Topol Web</a
                        >
                    </li>
                    <li>
                        •
                        <a
                            href="https://master.d3pihb25dhhnn5.amplifyapp.com/share/wh9KAZkShIWSMqvR/app.topol.io"
                            target="_blank"
                            class="text-topol-blue-500 hover:text-topol-blue-600"
                            >Topol App</a
                        >
                    </li>
                    <li>
                        •
                        <a
                            href="https://master.d3pihb25dhhnn5.amplifyapp.com/share/GAhZfMHwfibDCEs8/docs.topol.io"
                            target="_blank"
                            class="text-topol-blue-500 hover:text-topol-blue-600"
                            >Topol Docs</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const dateFrom = ref('');
const dateTo = ref('');

const subscriptionStats = ref([]);
const integrationStats = ref([]);
const mrrStats = ref([]);
const tileStats = ref([]);

onMounted(async () => {
    const today = new Date();
    const last30Days = new Date(today);
    last30Days.setDate(last30Days.getDate() - 30);
    dateFrom.value = last30Days.toISOString().split('T')[0];
    dateTo.value = today.toISOString().split('T')[0];

    fetchTileStats();
});

const fetchTileStats = async () => {
    const response = await axios.get(
        route('kiosk.dashboard-tile-data.index', {
            date_to: dateTo.value,
            date_from: dateFrom.value,
        })
    );
    if (response.status === 200) {
        tileStats.value = response.data.tileStats;
        subscriptionStats.value = response.data.subscriptionStats;
        integrationStats.value = response.data.integrationStats;
        mrrStats.value = response.data.mrrStats;
    }
};

const handleSetInterval = (from: string, to: string) => {
    dateFrom.value = from;
    dateTo.value = to;

    fetchTileStats();
};
</script>
