<template>
    <BasicTabs :tabs="tabs" :tab-key="props.tabKey">
        <template #header>
            <inertia-link :href="route('api-tokens.index')">
            <AtomicButton color="yellow" size="sm">
                Back to Api Tokens
                <template #icon>
                    <BackspaceIcon
                        class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>
        <div class="flex items-center mt-4">
            <h1
                class="mr-4 text-2xl font-medium leading-normal text-gray-800 tracking-small"
            >
                Editor Options for token
            </h1>
            <div
                class="inline-flex items-center justify-center h-10 px-4 text-sm font-medium leading-10 text-gray-600 bg-white border border-gray-300 rounded-md"
            >
                {{ props.token.name }}
            </div>
        </div>
        </template>

        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { withDefaults } from 'vue';

import { Cog8ToothIcon, NewspaperIcon } from '@heroicons/vue/24/solid';
import { BackspaceIcon } from '@heroicons/vue/24/outline';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import IToken from '@/types/Plugin/IToken';

interface EditorOptionsTabProps {
    tabKey: 'options' | 'premade-templates';
    token: IToken;
}

const props = withDefaults(defineProps<EditorOptionsTabProps>(), {
    tabKey: 'options',
    token: Object,
});

const tabs: TabItem[] = [
    {
        title: 'Options',
        key: 'options',
        icon: Cog8ToothIcon,
        url: route('api-tokens.editor-options.show', {
            apiToken: props.token.id,
        }),
    },
    {
        title: 'Premade Templates',
        key: 'premade-templates',
        icon: NewspaperIcon,
        url: route('api-tokens.premade-templates.show', {
            apiToken: props.token.id,
        }),
    },
];
</script>
