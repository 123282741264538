<template>
    <div>
        <h4 class="font-medium leading-normal text-gray-600">
            Subscription details
        </h4>
        <p
            v-if="team.personal_team"
            class="text-sm leading-normal text-gray-500"
        >
            Your PRO subscription breakdown. For Plugin subscription details
            <inertia-link
                :href="route('billing.plugin-subscriptions.index')"
                class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                >click here</inertia-link
            >.
        </p>

        <div
            class="mt-4 flex flex-col items-start rounded bg-yellow-100 px-[14px] pt-3 pb-2.5 sm:flex-row"
        >
            <div>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.25685 3.0991C9.02185 1.7391 10.9788 1.7391 11.7428 3.0991L17.3228 13.0191C18.0728 14.3531 17.1098 15.9991 15.5808 15.9991H4.41985C2.88985 15.9991 1.92685 14.3531 2.67685 13.0191L8.25685 3.0991ZM10.9998 13.0001C10.9998 13.2653 10.8945 13.5197 10.707 13.7072C10.5194 13.8947 10.2651 14.0001 9.99985 14.0001C9.73463 14.0001 9.48028 13.8947 9.29274 13.7072C9.10521 13.5197 8.99985 13.2653 8.99985 13.0001C8.99985 12.7349 9.10521 12.4805 9.29274 12.293C9.48028 12.1055 9.73463 12.0001 9.99985 12.0001C10.2651 12.0001 10.5194 12.1055 10.707 12.293C10.8945 12.4805 10.9998 12.7349 10.9998 13.0001ZM9.99985 5.0001C9.73463 5.0001 9.48028 5.10546 9.29274 5.29299C9.10521 5.48053 8.99985 5.73488 8.99985 6.0001V9.0001C8.99985 9.26532 9.10521 9.51967 9.29274 9.70721C9.48028 9.89474 9.73463 10.0001 9.99985 10.0001C10.2651 10.0001 10.5194 9.89474 10.707 9.70721C10.8945 9.51967 10.9998 9.26532 10.9998 9.0001V6.0001C10.9998 5.73488 10.8945 5.48053 10.707 5.29299C10.5194 5.10546 10.2651 5.0001 9.99985 5.0001Z"
                        fill="#D69E2E"
                    />
                </svg>
            </div>
            <div class="mt-3 text-sm sm:mt-0 sm:ml-[14px]">
                <p class="font-medium text-yellow-800">
                    Your current Topol Pro subscription is now listed as a
                    legacy plan
                </p>
                <p class="text-yellow-700">
                    Don't worry, you can still make any desired changes to your
                    subscription. Feel free to
                    <a href="mailto:tereza@topol.io" class="font-medium"
                        >contact our Topol support</a
                    >.
                </p>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import route from 'ziggy-js';

const team = computed(() => usePage<ITopolPageProps>().props.team);
</script>
