<template>
    <div>
        <h4 class="font-medium leading-normal text-gray-600">
            Subscription details
        </h4>
        <p class="text-sm leading-normal text-gray-500">
            Your Plugin subscription breakdown. For PRO subscription details
            <inertia-link
                :href="route('billing.pro-subscriptions.index')"
                class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                >click here</inertia-link
            >.
        </p>

        <div
            v-if="
                !pluginSubscription &&
                (shouldDisplayPluginTrialBanner ||
                    shouldDisplayPluginSubscriptionBanner)
            "
            class="mt-4 flex flex-col items-start rounded bg-yellow-100 px-[14px] pt-3 pb-2.5 sm:flex-row"
        >
            <div>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.25685 3.0991C9.02185 1.7391 10.9788 1.7391 11.7428 3.0991L17.3228 13.0191C18.0728 14.3531 17.1098 15.9991 15.5808 15.9991H4.41985C2.88985 15.9991 1.92685 14.3531 2.67685 13.0191L8.25685 3.0991ZM10.9998 13.0001C10.9998 13.2653 10.8945 13.5197 10.707 13.7072C10.5194 13.8947 10.2651 14.0001 9.99985 14.0001C9.73463 14.0001 9.48028 13.8947 9.29274 13.7072C9.10521 13.5197 8.99985 13.2653 8.99985 13.0001C8.99985 12.7349 9.10521 12.4805 9.29274 12.293C9.48028 12.1055 9.73463 12.0001 9.99985 12.0001C10.2651 12.0001 10.5194 12.1055 10.707 12.293C10.8945 12.4805 10.9998 12.7349 10.9998 13.0001ZM9.99985 5.0001C9.73463 5.0001 9.48028 5.10546 9.29274 5.29299C9.10521 5.48053 8.99985 5.73488 8.99985 6.0001V9.0001C8.99985 9.26532 9.10521 9.51967 9.29274 9.70721C9.48028 9.89474 9.73463 10.0001 9.99985 10.0001C10.2651 10.0001 10.5194 9.89474 10.707 9.70721C10.8945 9.51967 10.9998 9.26532 10.9998 9.0001V6.0001C10.9998 5.73488 10.8945 5.48053 10.707 5.29299C10.5194 5.10546 10.2651 5.0001 9.99985 5.0001Z"
                        fill="#D69E2E"
                    />
                </svg>
            </div>
            <div class="mt-3 text-sm sm:mt-0 sm:ml-[14px]">
                <p
                    v-if="
                        shouldDisplayPluginTrialBanner &&
                        user.plugin_trial_ends_at
                    "
                    class="font-medium text-yellow-800"
                >
                    Your free 60-days trial period has ended on
                    {{
                        format(
                            new Date(user.plugin_trial_ends_at),
                            'MMMM d, yyyy'
                        )
                    }}.
                </p>
                <p class="text-yellow-700">
                    Your Plugin API Tokens are now suspended, please select
                    appropriate Plugin subscription to continue.
                </p>
            </div>
        </div>
        <div
            v-if="!pluginSubscription && user.plugin_trial_ends_at === null"
            class="mt-4 flex flex-col items-start rounded bg-blue-100 px-[14px] pt-3 pb-2.5 sm:flex-row"
        >
            <div class="mt-0.5">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM9 4C9 4.26522 8.89464 4.51957 8.70711 4.70711C8.51957 4.89464 8.26522 5 8 5C7.73478 5 7.48043 4.89464 7.29289 4.70711C7.10536 4.51957 7 4.26522 7 4C7 3.73478 7.10536 3.48043 7.29289 3.29289C7.48043 3.10536 7.73478 3 8 3C8.26522 3 8.51957 3.10536 8.70711 3.29289C8.89464 3.48043 9 3.73478 9 4ZM7 7C6.73478 7 6.48043 7.10536 6.29289 7.29289C6.10536 7.48043 6 7.73478 6 8C6 8.26522 6.10536 8.51957 6.29289 8.70711C6.48043 8.89464 6.73478 9 7 9V12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13H9C9.26522 13 9.51957 12.8946 9.70711 12.7071C9.89464 12.5196 10 12.2652 10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11V8C9 7.73478 8.89464 7.48043 8.70711 7.29289C8.51957 7.10536 8.26522 7 8 7H7Z"
                        fill="#3182CE"
                    />
                </svg>
            </div>
            <div class="mt-3 text-sm sm:mt-0 sm:ml-[14px]">
                <p class="text-blue-600">
                    You can try Topol Plugin for
                    <span class="font-medium">60-days FREE Trial</span> period
                    or choose your preferred Plugin Plan right now.
                </p>
            </div>
        </div>

        <div
            v-if="!pluginSubscription"
            class="px-6 pt-4 mt-3 border border-gray-200 rounded-md bg-gray-50 pb-7"
        >
            <div>
                <div class="flex flex-wrap justify-between">
                    <div class="max-w-md 2xl:max-w-lg">
                        <h3
                            class="text-2xl font-bold leading-normal tracking-tighter text-gray-800"
                        >
                            Choose your Plugin subscription plan
                        </h3>

                        <p class="text-sm leading-normal text-gray-500">
                            All plans offer the same features, only difference
                            is number of allowed users.
                        </p>
                    </div>
                </div>

                <div
                    class="mt-6 w-full cursor-pointer rounded-md border bg-white pl-4 pr-4 pt-[18px] pb-[22px] transition duration-200 ease-in-out sm:pr-6"
                    :class="{
                        'border-gray-200': pluginPlan !== 4,
                        'border-orange-500': pluginPlan === 4,
                    }"
                    @click="pluginPlan = 4"
                >
                    <div class="flex flex-col sm:flex-row sm:justify-between">
                        <div class="flex">
                            <div class="hidden sm:block">
                                <RadioInput
                                    v-model="pluginPlan"
                                    :option="4"
                                    name="plugin"
                                    label=""
                                    size="lg"
                                />
                            </div>
                            <div class="ml-2">
                                <div class="text-base font-bold text-gray-600">
                                    Plugin for Startup
                                    <span class="font-normal">($60/month)</span>
                                </div>
                                <p
                                    class="mt-1.5 max-w-lg text-gray-500 sm:pr-2 md:pr-4"
                                >
                                    Perfect fit for projects with growing user
                                    count. 50 users included in plan, additional
                                    user for $1.20/month. Paid monthly.
                                </p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 mt-4 sm:mt-0">
                            <AtomicButton
                                size="md"
                                color="yellow"
                                @click="handleOpenOrderModal(4)"
                            >
                                Subscribe now
                            </AtomicButton>
                        </div>
                    </div>
                </div>

                <div
                    class="mt-4 w-full cursor-pointer rounded-md border bg-white pl-4 pr-4 pt-[18px] pb-[22px] transition duration-200 ease-in-out sm:pr-6"
                    :class="{
                        'border-gray-200': pluginPlan !== 5,
                        'border-orange-500': pluginPlan === 5,
                    }"
                    @click="pluginPlan = 5"
                >
                    <div class="flex flex-col sm:flex-row sm:justify-between">
                        <div class="flex">
                            <div class="hidden sm:block">
                                <RadioInput
                                    v-model="pluginPlan"
                                    :option="5"
                                    name="plugin"
                                    label=""
                                    size="lg"
                                />
                            </div>
                            <div class="ml-2">
                                <div class="text-base font-bold text-gray-600">
                                    Plugin for Business
                                    <span class="font-normal"
                                        >($300/month)</span
                                    >
                                </div>
                                <p
                                    class="mt-1.5 max-w-lg text-gray-500 sm:pr-2 md:pr-4"
                                >
                                    Solution for projects with an established
                                    user base. 1000 users included in plan,
                                    additional user for $0.30/month. Paid
                                    monthly.
                                </p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 mt-4 sm:mt-0">
                            <AtomicButton
                                size="md"
                                color="yellow"
                                @click="handleOpenOrderModal(5)"
                            >
                                Subscribe now
                            </AtomicButton>
                        </div>
                    </div>
                </div>

                <div
                    class="mt-4 w-full cursor-pointer rounded-md border bg-white pl-4 pr-4 pt-[18px] pb-[22px] transition duration-200 ease-in-out sm:pr-6"
                    :class="{
                        'border-gray-200': pluginPlan !== 6,
                        'border-orange-500': pluginPlan === 6,
                    }"
                    @click="pluginPlan = 6"
                >
                    <div class="flex flex-col sm:flex-row sm:justify-between">
                        <div class="flex">
                            <div class="hidden sm:block">
                                <RadioInput
                                    v-model="pluginPlan"
                                    :option="6"
                                    name="plugin"
                                    label=""
                                    size="lg"
                                />
                            </div>
                            <div class="ml-2">
                                <div class="text-base font-bold text-gray-600">
                                    Plugin Unlimited
                                    <span class="font-normal"
                                        >($600/month or $6000/year)</span
                                    >
                                </div>
                                <p
                                    class="mt-1.5 max-w-lg text-gray-500 sm:pr-2 md:pr-4"
                                >
                                    No limits. Fixed price, no hidden fees and
                                    unexpected costs. Unlimited users included.
                                    Paid monthly or yearly (save $1200/year).
                                </p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 mt-4 sm:mt-0">
                            <AtomicButton
                                size="md"
                                color="yellow"
                                @click="handleOpenOrderModal(6)"
                            >
                                Subscribe now
                            </AtomicButton>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <a
                        href="https://topol.io/tariff-plugin#plugin-pricing"
                        target="_blank"
                        class="text-sm font-medium cursor-pointer text-topol-blue-500"
                    >
                        Compare our Plugin subscription plans
                    </a>
                </div>
            </div>
        </div>

        <div
            v-if="!pluginSubscription && shouldAllowTrial"
            class="mt-4 rounded-md border border-gray-200 bg-gray-50 px-4 pt-4 pb-[26px] sm:px-6 md:pr-[28px]"
        >
            <div class="flex flex-col sm:flex-row sm:justify-between">
                <div>
                    <div class="text-2xl font-bold text-gray-800">
                        Start 60-days FREE Trial
                    </div>
                    <div class="mt-[3px] text-sm text-gray-500">
                        Start your TOPOL Plugin trial by creating API Tokens.
                    </div>
                    <div class="mt-4">
                        <a
                            href="https://docs.topol.io"
                            target="_blank"
                            class="text-sm font-medium cursor-pointer text-topol-blue-500"
                        >
                            How to work with TOPOL Plugin
                        </a>
                    </div>
                </div>

                <div class="flex-shrink-0 mt-4 sm:mt-0">
                    <AtomicButton
                        size="md"
                        color="white"
                        :disabled="loading"
                        @click="handleStartFreeTrial"
                    >
                        Start free trial
                    </AtomicButton>
                </div>
            </div>
        </div>

        <div
            v-if="pluginSubscription"
            class="px-6 mt-3 border border-gray-200 rounded-md bg-gray-50"
        >
            <div
                class="flex flex-col justify-between gap-1 md:flex-row lg:flex-col xl:flex-row"
            >
                <div class="pt-6 pb-7">
                    <div
                        v-if="
                            pluginSubscription.plan?.braintree_plan ===
                            'plugin_50'
                        "
                        class="inline-flex rounded-md bg-purple-300 px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        Plugin for Startup
                    </div>

                    <div
                        v-else-if="
                            pluginSubscription.plan?.braintree_plan ===
                            'plugin_1000'
                        "
                        class="inline-flex rounded-md bg-blue-300 px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        Plugin for Business
                    </div>

                    <div
                        v-else
                        class="inline-flex rounded-md bg-gradient-to-r from-[#FFB660] via-[#FF9E4A] to-[#FF8533] px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        {{ pluginSubscription.plan?.name }}
                    </div>

                    <div
                        class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                    >
                        <span class="font-bold">
                            ${{
                                pluginSubscription.amount_with_tax
                                    ? (
                                          pluginSubscription.amount_with_tax /
                                          100
                                      ).toFixed(2)
                                    : 0
                            }}
                        </span>
                        <span
                            >/{{
                                pluginSubscription.period === 1
                                    ? 'month'
                                    : 'year'
                            }}
                        </span>
                    </div>

                    <div class="text-sm text-gray-500">
                        <span
                            v-if="
                                pluginSubscription.plan?.braintree_plan ===
                                'plugin_50'
                            "
                        >
                            50 prepaid users
                        </span>
                        <span
                            v-else-if="
                                pluginSubscription.plan?.braintree_plan ===
                                'plugin_1000'
                            "
                        >
                            1000 prepaid users
                        </span>
                        <span v-else>Unlimited prepaid users</span>
                    </div>

                    <div
                        v-if="!pluginSubscription.canceled_at"
                        class="mt-[18px] text-sm"
                    >
                        <div class="text-gray-500">
                            {{
                                pluginSubscription.period === 1
                                    ? 'Month-to-month'
                                    : 'Annual'
                            }}
                            plan renews on
                        </div>

                        <div
                            v-if="pluginSubscription.next_billing_date"
                            class="font-medium text-gray-800"
                        >
                            {{
                                format(
                                    new Date(
                                        pluginSubscription.next_billing_date
                                    ),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </div>
                    </div>
                    <div v-else class="mt-[18px] text-sm text-gray-500">
                        <div>Subscription canceled, will be active until</div>
                        <div class="font-medium text-gray-800">
                            {{
                                format(
                                    new Date(pluginSubscription.canceled_at),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </div>
                    </div>

                    <div class="mt-7">
                        <inertia-link
                            :href="route('billing.invoices.index')"
                            class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                            ><p>Your invoices</p></inertia-link
                        >
                    </div>

                    <div
                        class="mt-[3px] cursor-pointer text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                        @click="subscriptionSwitchModal = true"
                    >
                        Change your subscription
                    </div>
                </div>

                <div class="w-1/3 min-w-[200px] pt-6">
                    <div
                        v-if="
                            pluginSubscription.next_billing_date &&
                            !pluginSubscription.canceled_at
                        "
                    >
                        <div
                            class="inline-flex rounded-md bg-indigo-300 px-3 py-[3px] text-xs font-medium text-gray-700"
                        >
                            Next invoice
                        </div>

                        <div
                            v-if="!dataTrafficUsersLoading"
                            class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                        >
                            <span
                                v-if="!uniqueUsers.addOnQuantity"
                                class="font-bold"
                            >
                                ${{
                                    pluginSubscription.amount_with_tax
                                        ? (
                                              pluginSubscription.amount_with_tax /
                                              100
                                          ).toFixed(2)
                                        : 0
                                }}
                            </span>

                            <span
                                v-if="
                                    uniqueUsers.addOnQuantity &&
                                    uniqueUsers.addOnAmount
                                "
                                class="font-bold"
                            >
                                ${{
                                    pluginSubscription.amount_with_tax
                                        ? (
                                              pluginSubscription.amount_with_tax /
                                                  100 +
                                              addonPrice
                                          ).toFixed(2)
                                        : 0
                                }}
                            </span>
                        </div>

                        <div
                            v-if="!dataTrafficUsersLoading"
                            class="text-sm font-normal text-gray-500"
                        >
                            <div>
                                ${{
                                    pluginSubscription.amount_with_tax
                                        ? (
                                              pluginSubscription.amount_with_tax /
                                              100
                                          ).toFixed(2)
                                        : 0
                                }}
                                basic monthly fee
                            </div>
                            <div
                                v-if="
                                    uniqueUsers.addOnQuantity &&
                                    uniqueUsers.addOnAmount
                                "
                            >
                                +{{ uniqueUsers.addOnQuantity }} users above
                                plan (${{ uniqueUsers.addOnAmount }}/user)
                            </div>
                        </div>
                        <div
                            v-if="dataTrafficUsersLoading"
                            class="flex justify-start mt-5"
                        >
                            <LoadingSpinner size="medium" />
                        </div>
                    </div>
                </div>

                <div
                    class="border-gray-200 pt-6 pb-7 lg:min-w-[200px] xl:border-l xl:pl-6"
                >
                    <div
                        class="inline-flex rounded-md bg-teal-200 px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        Data traffic
                    </div>

                    <div
                        v-if="dataTrafficUsersLoading"
                        class="flex justify-start mt-5"
                    >
                        <LoadingSpinner size="medium" />
                    </div>
                    <div
                        v-if="!dataTrafficUsersLoading"
                        class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                    >
                        <span v-if="dataTraffic?.price" class="font-bold">
                            ${{ dataTraffic.price.toFixed(2) }}
                        </span>
                        <span v-else class="font-bold"> $0 </span>
                    </div>

                    <div
                        v-if="dataTraffic?.bytes && !dataTrafficUsersLoading"
                        class="text-sm text-gray-500"
                    >
                        for {{ formatBytes(dataTraffic.bytes) }} above limit
                    </div>
                    <div
                        v-if="
                            dataTraffic?.billing_date &&
                            !dataTrafficUsersLoading
                        "
                        class="mt-[39px] text-sm text-gray-500"
                    >
                        <p>Next payment for data traffic on</p>
                        <p class="font-medium text-gray-800">
                            {{
                                format(
                                    new Date(dataTraffic.billing_date),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </p>
                    </div>

                    <div v-if="!dataTrafficUsersLoading" class="mt-7">
                        <inertia-link
                            href="/usage-statistics"
                            class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                            ><p>Data traffic details</p></inertia-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <SubscriptionOrderModal
            v-if="subscriptionOrderModal"
            :subscription-plan-id="pluginPlan"
            :countries="countries"
            :personal="false"
            @close="closeSubscriptionOrderModal"
            @reopen="reopenSubscriptionOrderModal"
        />

        <PluginSubscriptionSwitchModal
            v-if="subscriptionSwitchModal"
            :selected-card="selectedCard"
            :plugin-subscription="pluginSubscription"
            @close="subscriptionSwitchModal = false"
            @cancel="handleCancelSubscription"
        />

        <SubscriptionCancelReasonModal
            v-if="subscriptionCancelReasonModal && pluginSubscription?.id"
            :subscription-id="pluginSubscription?.id"
            @close="subscriptionCancelReasonModal = false"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { format } from 'date-fns';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import IExceededTrafficData from '@/types/UsageStatistics/IExceededTrafficData';
import axios from 'axios';
import { formatBytes } from '@utils/formatBytes';
import ISelectable from '@/types/Atomic/ISelectable';
import IPluginUniqueUsers from '@/types/Plugin/IPluginUniqueUsers';
import ISubscription from '@/types/Subscription/ISubscription';

const props = defineProps<{
    selectedCard?: IPaymentMethod;
    countries: ISelectable[];
    pluginSubscription: ISubscription | null;
    shouldAllowTrial: boolean;
}>();

onMounted(async () => {
    if (props.pluginSubscription !== null) {
        dataTrafficUsersLoading.value = true;
        await getDataTraffic();
        await getUniqueUsers();
        dataTrafficUsersLoading.value = false;
    }
});

const {
    shouldDisplayPluginTrialBanner,
    shouldDisplayPluginSubscriptionBanner,
} = useBanners();

const user = computed(() => usePage<ITopolPageProps>().props.user);
const subscriptionOrderModal = ref(false);
const subscriptionSwitchModal = ref(false);
const subscriptionCancelReasonModal = ref(false);

const pluginPlan = ref(0);
const loading = ref(false);
const uniqueUsers = ref({} as IPluginUniqueUsers);
const dataTrafficUsersLoading = ref(false);
const dataTraffic = ref<IExceededTrafficData>();

const addonPrice = computed(() => {
    if (uniqueUsers.value.addOnQuantity && uniqueUsers.value.addOnAmount) {
        return uniqueUsers.value.addOnQuantity * uniqueUsers.value.addOnAmount;
    } else return 0;
});

const emit = defineEmits<{
    (event: 'subscriptionOrderModalClosed'): void;
}>();

const reopenSubscriptionOrderModal = async () => {
    await closeSubscriptionOrderModal();
    subscriptionOrderModal.value = true;
};
const closeSubscriptionOrderModal = () => {
    subscriptionOrderModal.value = false;
    emit('subscriptionOrderModalClosed');
};

const handleStartFreeTrial = () => {
    loading.value = true;
    router.post(
        route('plugin.enable'),
        {},
        {
            onSuccess: () => {
                create({
                    title: 'Plugin free trial started',
                    text: 'Plugin has been successfully enabled.',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Plugin free trial not started',
                    text: 'There was an error enabling your Plugin.',
                    type: 'error',
                });
            },
        }
    );
};

const handleOpenOrderModal = async (planId: number) => {
    pluginPlan.value = planId;
    subscriptionOrderModal.value = true;
};

const getDataTraffic = async () => {
    try {
        const res = await axios.get(
            route('billing.plugin-subscriptions.data-traffic.index')
        );

        if (res.data.success) {
            dataTraffic.value = res.data.data;
        }
    } catch (error) {
        () => undefined;
    }
};

const getUniqueUsers = async () => {
    try {
        const resUsers = await axios.get(
            route('api-tokens.unique-users.index')
        );

        if (resUsers.data.success) {
            uniqueUsers.value = resUsers.data.data;
        }
    } catch (error) {
        () => undefined;
    }
};

const handleCancelSubscription = () => {
    subscriptionSwitchModal.value = false;
    subscriptionCancelReasonModal.value = true;
};
</script>

<style scoped></style>