<template>
    <div class="max-w-80 w-full sm:w-80 sm:max-w-none md:w-56">
        <div
            class="max-w-80 group relative h-[380px] w-full rounded-md bg-white shadow-card sm:w-80 sm:max-w-none md:w-56"
            :class="{
                'template-shadow-selected': isSelected,
                'opacity-70': template.owned_by_current_team,
            }"
        >
            <div
                class="absolute w-full cursor-pointer rounded-md bg-white sm:w-80 md:w-56"
                :class="{
                    'absolute w-80 rounded-md ring-2 ring-orange-500 md:w-56':
                        isSelected,
                }"
            >
                <div
                    class="relative flex cursor-pointer justify-center overflow-hidden"
                >
                    <div v-if="isSelected" class="absolute top-3 left-3">
                        <CheckboxInput
                            :checked="isSelected"
                            size="md"
                            name="selected"
                            @change="removeFromCart"
                        />
                    </div>

                    <img
                        :src="template.image_url"
                        :alt="template.name"
                        class="h-[300px] w-56 rounded-t-md object-cover object-top"
                        @click="openTemplate"
                    />
                </div>

                <div class="relative h-20 border-t border-gray-200">
                    <div
                        class="relative flex items-center justify-between px-3 pt-3"
                        @click="openTemplate"
                    >
                        <p
                            class="h-6 max-w-max truncate pr-3 text-sm leading-normal text-gray-800"
                            @mouseover="showTooltip = true"
                            @mouseleave="showTooltip = false"
                        >
                            {{ template.name }}
                        </p>
                        <transition
                            enter-active-class="transition duration-100 ease-out transform"
                            enter-from-class="scale-95 opacity-0"
                            enter-to-class="scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in transform"
                            leave-from-class="scale-100 opacity-100"
                            leave-to-class="scale-95 opacity-0"
                        >
                            <Tooltip
                                v-if="showTooltip && template.name"
                                class="absolute left-2 bottom-10"
                            >
                                {{ template.name }}
                            </Tooltip>
                        </transition>
                    </div>
                    <div class="flex h-8 items-center justify-between px-3">
                        <div
                            class="flex flex-shrink-0 items-center gap-2"
                            @click="openTemplate"
                        >
                            <div class="h-6 w-6">
                                <img
                                    class="h-6 w-6 rounded-full object-cover ring-[1px] ring-gray-300"
                                    :src="
                                        template.author?.profile_photo_path ??
                                        template.author?.profile_photo_url
                                    "
                                    alt="Author"
                                />
                            </div>

                            <div class="text-xs text-gray-700">
                                {{ template.author.name.split(' ')[0] }}
                            </div>
                        </div>

                        <div v-if="!template.owned_by_current_team">
                            <AtomicButton
                                v-if="!isSelected"
                                color="yellow"
                                size="xs"
                                @click="addToCart"
                            >
                                <template #icon>
                                    <ShoppingCartIcon class="mr-1 h-4 w-4" />
                                </template>
                                Add
                            </AtomicButton>

                            <div
                                v-else
                                class="text-right text-xs font-medium text-green-600"
                            >
                                Added
                            </div>
                        </div>
                        <div
                            v-else
                            class="text-right text-xs font-medium text-topol-blue-500"
                        >
                            Already owned
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="!template.owned_by_current_team"
                class="absolute top-3 right-3 cursor-pointer rounded-md px-2 py-[3px] text-xs font-medium leading-[18px] text-gray-50 ring-1 ring-white"
                :class="{
                    'bg-green-600': template.price === 0,
                    'bg-indigo-600': template.price !== 0,
                }"
            >
                <span v-if="template.price"> ${{ template.price }} </span>
                <span v-else> Free </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { ShoppingCartIcon } from '@heroicons/vue/24/solid';
const { cart, addTemplateToCart, removeTemplateFromCart } =
    useMarketplaceCart();

const props = defineProps({
    template: {
        type: Object as PropType<IMarketplaceTemplate>,
        required: true,
    },
});

const emit = defineEmits(['openPreview']);

const showTooltip = ref(false);

const isSelected = computed(() => {
    if (!cart.value) {
        return false;
    }
    return cart.value.shopping_cart_items.some(
        (item) => item.marketplace_template_id === props.template.id
    );
});

const openTemplate = () => {
    emit('openPreview', props.template);
};

const addToCart = () => {
    addTemplateToCart(props.template.id);
};

const removeFromCart = () => {
    removeTemplateFromCart(props.template.id);
};
</script>

<style scoped>
.template-shadow-selected {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
