import { ref, computed } from 'vue';
import IApiResponse from '@/types/IApiResponse';
import axios from 'axios';
import route from 'ziggy-js';
import IPaginationData from '@/types/Atomic/IPaginationData';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';

const templates = ref<IPremadeTemplate[]>([]);

export const usePredefinedTemplates = () => {
    const resourceUrl = route('premade-templates.index');

    const searchQuery = ref('');
    const currentPage = ref(1);
    const per_page = ref(20);
    const lastPage = ref<number | undefined>(undefined);

    const sortBy = ref('');
    const sortDirection = ref('asc'); //asc || desc

    const categories = ref<number[]>();
    const keywords = ref<number[]>();
    const isLoading = ref(false);

    const buildQuery = () => {
        let q = resourceUrl;

        q += `?current_page=${currentPage.value}`;

        q += `&per_page=${per_page.value}`;

        if (searchQuery.value !== '') {
            q += `&search=${searchQuery.value}`;
        }
        if (sortBy.value !== '') {
            q += `&sort_by=${sortBy.value}`;
            q += `&sort_by_direction=${sortDirection.value}`;
        }

        if (categories.value !== undefined) {
            categories.value.forEach((k) => {
                q += '&categories[]=' + k;
            });
        }

        if (keywords.value !== undefined) {
            keywords.value.forEach((k) => {
                q += '&keywords[]=' + k;
            });
        }

        return q;
    };

    const fetchData = async () => {
        try {
            isLoading.value = true;
            const res = await axios.get<
                IApiResponse<IPaginationData<IPremadeTemplate>>
            >(buildQuery());

            templates.value = res.data.data.data;
            lastPage.value = res.data.data.last_page;
        } catch (err) {
            () => undefined;
        } finally {
            isLoading.value = false;
        }
    };

    const search = async (searchQ: string) => {
        currentPage.value = 1;
        searchQuery.value = searchQ;
        await fetchData();
    };

    const setSortDirection = async (sortDir: 'asc' | 'desc') => {
        if (sortDir === 'asc' || sortDir === 'desc') {
            sortDirection.value = sortDir;
            await fetchData();
        } else {
            throw new TypeError(
                `Sorting direction: ${sortDir} is not supported. Supported directions: 'asc' or 'desc'.`
            );
        }
    };

    const setCategories = async (categoriesIds: number[]) => {
        currentPage.value = 1;
        categories.value = categoriesIds;
        await fetchData();
    };

    const setKeywords = async (keywordsIds: number[]) => {
        currentPage.value = 1;
        keywords.value = keywordsIds;
        await fetchData();
    };

    const setSortBy = async (sortByKey: string) => {
        //possible validation for sortByKey
        sortBy.value = sortByKey;
        await fetchData();
    };

    const setCurrentPage = async (newPage: number) => {
        currentPage.value = newPage;
        await fetchData();
    };

    const loadNextPage = async () => {
        currentPage.value++;

        try {
            isLoading.value = true;
            const res = await axios.get<
                IApiResponse<IPaginationData<IPremadeTemplate>>
            >(buildQuery());

            templates.value.push(...res.data.data.data);
            lastPage.value = res.data.data.last_page;
        } catch (err) {
            () => undefined;
        } finally {
            isLoading.value = false;
        }
    };

    const isLastPage = computed(() => {
        return currentPage.value === lastPage.value;
    });

    return {
        fetchData,
        search,
        setSortBy,
        setSortDirection,
        setCurrentPage,
        setCategories,
        setKeywords,
        currentPage,
        templates,
        isLastPage,
        isLoading,
        loadNextPage,
        searchQuery,
    };
};

export default usePredefinedTemplates;
