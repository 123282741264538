<template>
    <div class="flex-shrink-0">
        <label
            class="focus-ring-offset-2 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-3 py-[9px] text-sm font-medium text-gray-600 transition duration-100 ease-in-out hover:text-gray-800 hover:shadow-button focus:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500"
            for="json-upload"
        >
            <svg
                class="mr-3 h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                ></path>
            </svg>

            Import template
        </label>

        <input
            id="json-upload"
            :key="key"
            ref="jsonInput"
            type="file"
            class="hidden"
            accept=".json"
            @change="onFileAdded"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import useTemplates from '../composables/useTemplates';

const { importTemplate } = useTemplates();
const key = ref<number>(0);

const onFileAdded = async (e: Event) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
        if (e.target !== null && e.target.result !== null) {
            await importTemplate(e.target.result as string);

            key.value++;
        }
    };

    const target = e.target as HTMLInputElement;

    if (target !== null && target.files !== null && target.files.length === 1) {
        reader.readAsText(target.files[0]);
    }
};
</script>
