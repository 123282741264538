<template>    
    <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Editor settings">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { ArrowsRightLeftIcon, ShoppingBagIcon, SunIcon, TagIcon, CogIcon } from '@heroicons/vue/24/solid';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import { LanguageIcon } from '@heroicons/vue/24/outline';
import useNewFeatures from '@/composables/useNewFeatures';

const { featureVisited } = useNewFeatures();

defineProps({
    tabKey: {
        type: String,
        default: 'profile',
    },
    features: {
        type: Object,
        default: null,
    },
});

const tabs: TabItem[] = [
    {
        title: 'Appearance',
        key: 'appearance',
        icon: SunIcon,
        url: route('editor-preferences.appearance.index'),
    },
    {
        title: 'Custom fonts',
        key: 'fonts',
        icon: LanguageIcon,
        url: route('editor-preferences.fonts.index'),
    },
    {
        title: 'Merge tags',
        key: 'merge-tags',
        icon: TagIcon,
        url: route('editor-preferences.merge-tags.index'),
    },
    {
        title: 'Products',
        key: 'products',
        icon: ShoppingBagIcon,
        url: route('editor-preferences.product-feeds.index'),
        new_feature: !featureVisited('product_feeds'),
    },
    {
        title: 'Webhooks',
        key: 'webhooks',
        icon: ArrowsRightLeftIcon,
        url: route('webhooks.template-saved.index'),
        new_feature: !featureVisited('webhook_template_saved'),
    },
    {
        title: 'Miscellaneous',
        key: 'miscellaneous',
        icon: CogIcon,
        url: route('editor-preferences.miscellaneous.index'),
    }
];
</script>
