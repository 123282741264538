import { getMessageForError } from './../utils/getMessageForError';
import axios, { AxiosError } from 'axios';
import { create } from '@/composables/useNotifications';

axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error: AxiosError) => {
        if (error.response?.status === 422) {
            return Promise.reject(error);
        }
        
        create({
            title: 'An error has occured.',
            text: getMessageForError(error),
            type: 'error',
            persistent: false,
        });

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);
