<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Export to iContact</div>
        </template>

        <template #body>
            <div class="p-6">
                <form @submit.prevent="exportToIContact">
                    <SelectInput
                        v-model="state.folder_id"
                        placeholder="Choose folder ID"
                        :data="folders"
                        label="Client Folder ID"
                        required
                        :error="getError('folder_id')"
                        :disabled="loading"
                        search
                        @blurred="v$.folder_id.$touch()"
                    />

                    <SelectInput
                        v-model="state.campaign_id"
                        class="mt-6"
                        placeholder="Choose campaign ID"
                        :data="campaigns"
                        label="Campaign ID"
                        required
                        :error="getError('campaign_id')"
                        :disabled="loading"
                        search
                        @blurred="v$.campaign_id.$touch()"
                    />
                </form>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    :disabled="loading || v$.$invalid"
                    @click="exportToIContact"
                >
                    Export
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { PropType, reactive, ref } from 'vue';
import { IIContact, IIntegration } from '@/types/Integrations/IIntegration';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';

const emit = defineEmits(['close']);

const props = defineProps({
    integration: {
        required: true,
        type: Object as PropType<IIntegration<IIContact>>,
        default: null,
    },
    templateId: {
        required: true,
        type: Number,
    },
    templateName: {
        required: true,
        type: String,
    },
});

const state = reactive({
    folder_id: '',
    campaign_id: '',
});

const rules = {
    folder_id: {
        required,
    },
    campaign_id: {
        required,
    },
};

const v$ = useVuelidate(rules, state);

const loading = ref(false);
const folders = ref<ISelectable[]>([]);
const campaigns = ref<ISelectable[]>([]);

onMounted(() => {
    getFolders();
});

watch(
    () => state.folder_id,
    () => {
        getCampaigns();
    }
);

const getFolders = async () => {
    try {
        loading.value = true;
        const res = await axios.get(
            route('integrations.icontact.folders.show', {
                integration: props.integration.id,
            })
        );

        if (res.data.success === true) {
            folders.value = res.data.data;

            if (res.data.data.length > 0) {
                state.folder_id = res.data.data[0].value;
                getCampaigns();
            }
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const getCampaigns = async () => {
    try {
        loading.value = true;
        const res = await axios.get(
            route('integrations.icontact.campaigns.show', {
                integration: props.integration.id,
                folder_id: state.folder_id,
            })
        );

        if (res.data.success === true) {
            campaigns.value = res.data.data;

            if (res.data.data.length > 0) {
                state.campaign_id = res.data.data[0].value;
            }
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || '';
};

const exportToIContact = async () => {
    loading.value = true;

    try {
        const response = await axios.post(
            route('templates.exports.icontact', {
                template: props.templateId,
            }),
            {
                folder_id: state.folder_id,
                campaign_id: state.campaign_id,
                integration_id: props.integration.id,
            }
        );

        if (response.data.success) {
            create({
                title: 'Successful export',
                text: 'Template has been successfully exported to iContact.',
                type: 'success',
            });
            emit('close');

            return;
        }
        create({
            title: 'Unsuccessful export',
            text: 'Template has not been exported to iContact.',
            type: 'error',
        });
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};
</script>
