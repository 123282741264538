<template>
    <form class="grid-cols-layout lg:grid" @submit.prevent="save">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Default fonts
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                You can define default fonts available in Topol editor.
            </p>
        </div>
        <div
            class="relative mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
        >
            <div
                class="flex flex-wrap gap-2 border-b border-gray-200 px-6 py-5"
            >
                <FontComponent
                    v-for="font in defaultFonts"
                    :key="font.label"
                    :font="font"
                    :checked="
                        selectedFonts.find((f) => f.style === font.style) !==
                        undefined
                    "
                    @select-font="selectFont(font)"
                />
            </div>

            <div class="flex justify-end">
                <div class="my-3 pr-6">
                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="sm"
                        :disabled="saveDisabled"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import useFonts from '../composables/useFonts';
import IEditorSettings from '@/types/Settings/IEditorSettings';
import IFont from '@/types/Settings/IFont';

const props = defineProps({
    settings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
});

const emit = defineEmits(['save']);

const { loadExternalStylesheet, defaultFonts } = useFonts();

const selectedFonts = ref(
    props.settings.default_fonts.length > 0
        ? props.settings.default_fonts
        : defaultFonts
);

const saveDisabled = computed(() => {
    return (
        selectedFonts.value.length === 0 ||
        JSON.stringify(props.settings.default_fonts) ===
            JSON.stringify(selectedFonts.value) ||
        (selectedFonts.value.length >= 15 &&
            props.settings.default_fonts.length === 0)
    );
});
const selectFont = (font: IFont) => {
    const found = selectedFonts.value.find((f) => f.style === font.style);

    if (found) {
        selectedFonts.value = selectedFonts.value.filter(
            (f) => f.style !== font.style
        );
    } else {
        selectedFonts.value = [...selectedFonts.value, font];
    }
};

const save = () => {
    const fonts =
        selectedFonts.value.length > 0 && selectedFonts.value.length < 15
            ? selectedFonts.value
            : [];
    emit('save', fonts);
};

onBeforeMount(() => {
    const defaultFontUrls = defaultFonts
        .map((font) => font.url)
        .filter((url) => url !== undefined);

    defaultFontUrls.forEach((url) => loadExternalStylesheet(url as string));
});
</script>
