<template>
    <div class="px-6 pt-4 pb-6">
        <h3 class="text-lg font-medium text-gray-900">Plugin Integration</h3>
        <div class="mt-2 text-gray-700">
            <p class="mt-2 text-sm">
                Detailed documentation on Plugin integration can be found
                <a
                    class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    href="https://docs.topol.io/guide/introduction.html"
                    target="_blank"
                    >here</a
                >.
            </p>
        </div>

        <div
            class="flex justify-between max-w-xl mt-4 text-gray-500 border-b border-gray-300"
        >
            <button
                v-for="tab in tabs"
                :key="tab"
                class="transition duration-200 ease-in-out cursor-pointer hover:text-gray-600"
                :class="{
                    'border-b-2 border-purple-500 pb-0 text-gray-800':
                        selectedTab === tab,
                    'pb-[2px]': selectedTab !== tab,
                }"
                @click="selectTab(tab)"
            >
                {{ tab }}
            </button>
        </div>

        <div
            v-if="selectedTab === 'HTML'"
            class="mt-4 text-sm text-gray-700 md:max-w-[520px] lg:max-w-none"
        >
            <div>
                Once you get your API key, first, insert this HTML within your
                application:
            </div>
            <highlightjs autodetect :code="highlightData.jstsCode" />
            <div>
                This is where the plugin will be displayed. (It's important to
                set an explicit height and width for the element, otherwise the
                height and width of the div could be too small for the TOPOL.io
                builder.)
            </div>
            <highlightjs autodetect :code="highlightData.jstsInit" />
        </div>

        <div
            v-if="selectedTab === 'JS/TS package'"
            class="mt-4 text-sm text-gray-700 md:max-w-[520px] lg:max-w-none"
        >
            <div>Install Editor from NPM or Yarn using:</div>
            <highlightjs autodetect :code="highlightData.packageInstall" />
            <div>
                <p>Add HTML element Editor will load to:</p>
            </div>
            <highlightjs autodetect :code="highlightData.packageHtml" />
            <p>
                In your JS or TS file import TopolPlugin, initialize the editor,
                as an ID provide the id you defined the HTML (in this example it
                is #app).
            </p>
            <p>
                For more options see the docs for TopolOptions
                <a
                    class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    href="https://docs.topol.io/guide/getting-started.html"
                    target="_blank"
                    >configuration</a
                >.
            </p>

            <highlightjs autodetect :code="highlightData.packageScript" />

            You can find full documentation on Editor package
            <a
                class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                href="https://www.npmjs.com/package/@topol.io/editor"
                target="_blank"
                >here</a
            >.
        </div>

        <div
            v-if="selectedTab === 'Vue.js 2 (beta)'"
            class="mt-4 text-sm text-gray-700 md:max-w-[520px] lg:max-w-none"
        >
            <div class="p-4 my-4 bg-yellow-100 border-l-4 border-yellow-400">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationTriangleIcon
                            class="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-yellow-900">
                            Currently only works using Vite
                        </p>
                    </div>
                </div>
            </div>

            <div>Install Editor from NPM or Yarn using:</div>
            <highlightjs autodetect :code="highlightData.vue2Install" />
            <div>
                <p>
                    In your Vue component import and add the simplest options.
                </p>
                <p>
                    For more options see the docs for TopolOptions
                    <a
                        class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://docs.topol.io/guide/getting-started.html"
                        target="_blank"
                        >configuration</a
                    >.
                </p>
            </div>
            <highlightjs autodetect :code="highlightData.vue2Script" />

            <highlightjs autodetect :code="highlightData.vue2Template" />

            You can find full documentation on Vue 2
            <a
                class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                href="https://www.npmjs.com/package/@topol.io/editor-vue2"
                target="_blank"
                >here</a
            >.
        </div>

        <div
            v-if="selectedTab === 'Vue.js 3 (beta)'"
            class="mt-4 text-sm text-gray-700 md:max-w-[520px] lg:max-w-none"
        >
            <div class="p-4 my-4 bg-yellow-100 border-l-4 border-yellow-400">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationTriangleIcon
                            class="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-yellow-900">
                            Currently only works using Vite
                        </p>
                    </div>
                </div>
            </div>
            <div>Install Editor from NPM or Yarn using:</div>
            <highlightjs autodetect :code="highlightData.vue3Install" />
            <div>
                <p>
                    In your Vue component import and add the simplest options.
                </p>
                <p>
                    For more options see the docs for TopolOptions
                    <a
                        class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://docs.topol.io/guide/getting-started.html"
                        target="_blank"
                        >configuration</a
                    >.
                </p>
            </div>
            <highlightjs autodetect :code="highlightData.vue3Script" />

            <highlightjs autodetect :code="highlightData.vue3Template" />

            You can find full documentation on Vue 3
            <a
                class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                href="https://www.npmjs.com/package/@topol.io/editor-vue"
                target="_blank"
                >here</a
            >.
        </div>

        <div
            v-if="selectedTab === 'React (beta)'"
            class="mt-4 text-sm text-gray-700 md:max-w-[520px] lg:max-w-none"
        >
            <div class="p-4 my-4 bg-yellow-100 border-l-4 border-yellow-400">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <ExclamationTriangleIcon
                            class="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-yellow-900">
                            Currently only works using Vite
                        </p>
                    </div>
                </div>
            </div>
            <div>Install Editor from NPM or Yarn using:</div>
            <highlightjs autodetect :code="highlightData.reactInstall" />
            <div>
                <p>
                    In your React component import and add the simplest options.
                </p>
                <p>
                    For more options see the docs for TopolOptions
                    <a
                        class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        href="https://docs.topol.io/guide/getting-started.html"
                        target="_blank"
                        >configuration</a
                    >.
                </p>
            </div>
            <highlightjs autodetect :code="highlightData.reactScript" />

            <highlightjs autodetect :code="highlightData.reactTemplate" />

            You can find full documentation on React
            <a
                class="inline font-medium text-topol-blue-500 hover:text-topol-blue-600"
                href="https://www.npmjs.com/package/@topol.io/editor-react"
                target="_blank"
                >here</a
            >.
        </div>
    </div>
</template>

<script lang="ts" setup>
import 'highlight.js/lib/common';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import { ref } from 'vue';
import IToken from '@/types/Plugin/IToken';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

const props = defineProps<{
    token: IToken;
}>();

const highlightjs = hljsVuePlugin.component;

const selectedTab = ref('HTML');
const tabs = [
    'HTML',
    'JS/TS package',
    'Vue.js 2 (beta)',
    'Vue.js 3 (beta)',
    'React (beta)',
];

const selectTab = (tab: string) => {
    selectedTab.value = tab;
};

const highlightData = {
    jstsCode:
        '<div id="app" style="position: absolute; width: 100%; height: 100%;"></div>',
    jstsInit: `<!--Import Editor Loader-->
    <${'script'} src="https://d5aoblv5p04cg.cloudfront.net/editor-3/loader/build.js" type="text/javascript"></${'script'}>


    <!--Define Plugin Options-->
    <${'script'}>
    const TOPOL_OPTIONS = {
        id: "#app",
        authorize: {
            apiKey: "${props.token.token}",
            userId: "UserID",
        },
        callbacks: {
            onSave: function(json, html) {
               //work with JSON and HTML
            }
        }
    };

    TopolPlugin.init(TOPOL_OPTIONS);

    fetch('https://tlapi.github.io/topol-editor/templates/1.json')
        .then(response => response.text())
        .then(template => {
            //Load the template in Editor
            TopolPlugin.load(template)
        }
    );
    </${'script'}>`,
    packageInstall: `npm install @topol.io/editor

//or

yarn add @topol.io/editor`,
    packageHtml: `<div id="app" style="position: absolute; width: 100%; height: 100%"></div>`,
    packageScript: `import TopolPlugin from "@topol.io/editor";

    const TOPOL_OPTIONS = {
      id: "#app",
      authorize: {
        apiKey: "YOUR_API_TOKEN",
        userId: "some-user-id",
      },
      callbacks: {
        onSave(json, html) {},
      },
    };

    TopolPlugin.init(TOPOL_OPTIONS);`,

    vue2Install: `npm install @topol.io/editor-vue2

//or

yarn add @topol.io/editor-vue2`,
    vue2Script: `import { TopolEditor } from '@topol.io/editor-vue2';

    const customOptions = {
      authorize: {
        apiKey: "${props.token.token}",
        userId: "some-user-id",
      },
    };`,
    vue2Template: `<TopolEditor :options="customOptions"> </TopolEditor>`,

    vue3Install: `npm install @topol.io/editor-vue

//or

yarn add @topol.io/editor-vue`,
    vue3Script: `import { TopolEditor } from '@topol.io/editor-vue';

    const customOptions = {
      authorize: {
        apiKey: "${props.token.token}",
        userId: "some-user-id",
      },
    };`,
    vue3Template: `<TopolEditor :options="customOptions"> </TopolEditor>`,
    reactInstall: `npm install @topol.io/editor-react

//or

yarn add @topol.io/editor-react`,
    reactScript: `import TopolEditor from '@topol.io/editor-react';

    const customOptions = {
      authorize: {
        apiKey: "${props.token.token}",
        userId: "some-user-id",
      },
    };`,
    reactTemplate: `<TopolEditor options={customOptions}> </TopolEditor>`,
};
</script>
