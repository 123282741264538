import IApiResponse from '@customTypes/IApiResponse';
import { useReCaptcha } from 'vue-recaptcha-v3';
import axios from 'axios';

export default (sendTestEmailUrl: string) => {
    const reCaptcha = useReCaptcha();

    const sendTestEmail = async (emails: string[], html: unknown) => {
        try {
            await reCaptcha?.recaptchaLoaded();

            const token = await reCaptcha?.executeRecaptcha(
                'editorSendTestEmail'
            );

            if (token === undefined) {
                throw new Error('Verifying recaptcha token failed');
            }

            return await axios.post<IApiResponse<unknown>>(sendTestEmailUrl, {
                html: html,
                emails: emails,
                recaptcha_token: token,
            });
        } catch (err) {
            throw new Error('Sending test email failed!');
        }
    };

    return {
        sendTestEmail,
    };
};
