<template>
    <Modal size="xl" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">API token</div>
        </template>

        <template #body>
            <div class="w-full px-[26px] pt-[26px] pb-6">
                <div
                    class="relative mt-3 flex rounded bg-yellow-100 pb-[13px] pl-3.5 pr-6 pt-3"
                >
                    <svg
                        class="shrink-0"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.25685 3.09898C9.02185 1.73898 10.9788 1.73898 11.7428 3.09898L17.3228 13.019C18.0728 14.353 17.1098 15.999 15.5808 15.999H4.41985C2.88985 15.999 1.92685 14.353 2.67685 13.019L8.25685 3.09898ZM10.9998 13C10.9998 13.2652 10.8945 13.5196 10.707 13.7071C10.5194 13.8946 10.2651 14 9.99985 14C9.73463 14 9.48028 13.8946 9.29274 13.7071C9.10521 13.5196 8.99985 13.2652 8.99985 13C8.99985 12.7348 9.10521 12.4804 9.29274 12.2929C9.48028 12.1053 9.73463 12 9.99985 12C10.2651 12 10.5194 12.1053 10.707 12.2929C10.8945 12.4804 10.9998 12.7348 10.9998 13ZM9.99985 4.99998C9.73463 4.99998 9.48028 5.10534 9.29274 5.29287C9.10521 5.48041 8.99985 5.73476 8.99985 5.99998V8.99998C8.99985 9.2652 9.10521 9.51955 9.29274 9.70709C9.48028 9.89462 9.73463 9.99998 9.99985 9.99998C10.2651 9.99998 10.5194 9.89462 10.707 9.70709C10.8945 9.51955 10.9998 9.2652 10.9998 8.99998V5.99998C10.9998 5.73476 10.8945 5.48041 10.707 5.29287C10.5194 5.10534 10.2651 4.99998 9.99985 4.99998Z"
                            fill="#D69E2E"
                        />
                    </svg>
                    <div class="pl-3.5 text-sm leading-normal">
                        <p class="font-medium text-yellow-800">
                            API Token created
                        </p>
                        <p class="mt-[7px] text-yellow-700">
                            Here is your new API token. This is the only time
                            the token will ever be displayed, so be sure not to
                            lose it!
                        </p>
                    </div>
                </div>

                <div
                    ref="input"
                    class="relative mt-7 rounded-md border border-gray-300 bg-gray-50 px-6 py-3 text-sm leading-7 text-gray-800 shadow-sm"
                >
                    <p>
                        {{ apiToken }}
                    </p>

                    <transition
                        enter-active-class="transition duration-100 ease-out transform"
                        enter-from-class="scale-95 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in transform"
                        leave-from-class="scale-100 opacity-100"
                        leave-to-class="scale-95 opacity-0"
                    >
                        <Tooltip
                            v-if="copiedSuccess"
                            class="absolute top-[-55px]"
                            style="left: 50%; transform: translate(-50%, 0)"
                        >
                            Copied to clipboard!
                        </Tooltip>
                    </transition>
                </div>

                <AtomicButton
                    class="mt-5"
                    color="white"
                    size="sm"
                    @click="copyToClipboard"
                >
                    <template #icon>
                        <DocumentDuplicateIcon
                            class="mr-2 h-5 w-5 text-gray-400 transition duration-200"
                        />
                    </template>
                    Copy to clipboard
                </AtomicButton>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton size="md" color="yellow" @click="$emit('close')">
                    Finish
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import Modal from '@atomic/Modals/Modal.vue';
import { useClipboard } from '@vueuse/core';
import Tooltip from '@/components/Atomic/Miscellaneous/Tooltip.vue';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
    apiToken: {
        type: String,
        required: true,
    },
});

defineEmits(['close']);
const copiedSuccess = ref(false);
const copyToClipboard = () => {
    const clipboard = useClipboard();
    clipboard.copy(props.apiToken);
    copiedSuccess.value = true;
    setTimeout(() => {
        copiedSuccess.value = false;
    }, 1000);
};
</script>
