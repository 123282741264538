<template>
    <div>
        <div>
            <h4 class="font-medium leading-normal text-gray-600">
                Subscription details
            </h4>
            <p
                v-if="team.personal_team"
                class="text-sm leading-normal text-gray-500"
            >
                Your PRO subscription breakdown. For Plugin subscription details
                <inertia-link
                    :href="route('billing.plugin-subscriptions.index')"
                    class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                    >click here</inertia-link
                >.
            </p>

            <div
                v-if="!proSubscription && !team.isTrialingPro"
                class="mt-4 flex flex-col items-start rounded bg-yellow-100 px-[14px] pt-3 pb-2.5 sm:flex-row"
            >
                <div>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.25685 3.0991C9.02185 1.7391 10.9788 1.7391 11.7428 3.0991L17.3228 13.0191C18.0728 14.3531 17.1098 15.9991 15.5808 15.9991H4.41985C2.88985 15.9991 1.92685 14.3531 2.67685 13.0191L8.25685 3.0991ZM10.9998 13.0001C10.9998 13.2653 10.8945 13.5197 10.707 13.7072C10.5194 13.8947 10.2651 14.0001 9.99985 14.0001C9.73463 14.0001 9.48028 13.8947 9.29274 13.7072C9.10521 13.5197 8.99985 13.2653 8.99985 13.0001C8.99985 12.7349 9.10521 12.4805 9.29274 12.293C9.48028 12.1055 9.73463 12.0001 9.99985 12.0001C10.2651 12.0001 10.5194 12.1055 10.707 12.293C10.8945 12.4805 10.9998 12.7349 10.9998 13.0001ZM9.99985 5.0001C9.73463 5.0001 9.48028 5.10546 9.29274 5.29299C9.10521 5.48053 8.99985 5.73488 8.99985 6.0001V9.0001C8.99985 9.26532 9.10521 9.51967 9.29274 9.70721C9.48028 9.89474 9.73463 10.0001 9.99985 10.0001C10.2651 10.0001 10.5194 9.89474 10.707 9.70721C10.8945 9.51967 10.9998 9.26532 10.9998 9.0001V6.0001C10.9998 5.73488 10.8945 5.48053 10.707 5.29299C10.5194 5.10546 10.2651 5.0001 9.99985 5.0001Z"
                            fill="#D69E2E"
                        />
                    </svg>
                </div>
                <div class="mt-3 text-sm sm:mt-0 sm:ml-[14px]">
                    <div
                        v-if="
                            shouldDisplayProTrialBanner ||
                            shouldDisplayProTeamSubscriptionBanner ||
                            shouldDisplayProPersonalSubscriptionBanner
                        "
                        class="font-medium text-yellow-800"
                    >
                        <span
                            v-if="
                                shouldDisplayProTrialBanner &&
                                user.pro_trial_ends_at
                            "
                        >
                            Your free 14-days trial period has ended on
                            {{
                                format(
                                    new Date(user.pro_trial_ends_at),
                                    'MMMM d, yyyy'
                                )
                            }}.
                        </span>
                        You are now switched to our Free&nbsp;plan.
                    </div>

                    <div class="text-yellow-700">
                        To continue using Topol
                        {{ team.personal_team ? 'PRO' : 'Team' }} with all it’s
                        features you need to subscribe to a paid plan.
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!proSubscription"
            class="px-6 pt-4 mt-3 border border-gray-200 rounded-md bg-gray-50 pb-7"
        >
            <div>
                <div class="flex flex-wrap justify-between">
                    <div class="max-w-md 2xl:max-w-lg">
                        <h3
                            class="text-2xl font-bold leading-normal tracking-tighter text-gray-800"
                        >
                            Switch to Topol
                            {{ team.personal_team ? 'PRO' : 'Team' }} Plan
                        </h3>

                        <p
                            v-if="team.personal_team"
                            class="text-sm leading-normal text-gray-500"
                        >
                            $10 when billed annually. $15 when billed
                            month-to-month.
                        </p>
                        <p
                            v-if="!team.personal_team"
                            class="text-sm leading-normal text-gray-500"
                        >
                            $15 when billed annually. $20 when billed
                            month-to-month.
                            <span
                                >Including additional 3 team seats and 5GB
                                monthly data traffic.</span
                            >
                        </p>
                        <p
                            v-if="!team.personal_team"
                            class="mt-5 text-sm leading-normal text-gray-500"
                        >
                            You can add additional team seats for $10/seat with
                            annual billing or $15/seat with month-to-month
                            billing.
                        </p>

                        <div class="mt-4">
                            <a
                                href="https://topol.io/pricing"
                                target="_blank"
                                class="text-sm font-medium cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                            >
                                Compare our Free and
                                {{ team.personal_team ? 'PRO' : 'Team' }} plans
                            </a>
                        </div>
                    </div>

                    <AtomicButton
                        size="md"
                        color="yellow"
                        class="mt-[11px]"
                        @click="subscriptionOrderModal = true"
                    >
                        Subscribe now
                    </AtomicButton>
                </div>
            </div>
        </div>
        <div
            v-if="proSubscription"
            class="px-6 mt-3 border border-gray-200 rounded-md bg-gray-50"
        >
            <div
                class="flex flex-col justify-between md:flex-row lg:flex-col xl:flex-row"
            >
                <div class="pt-6 pb-7">
                    <div
                        class="inline-flex rounded-md bg-purple-300 px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        {{
                            proSubscription.period === 1
                                ? 'Month-to-month'
                                : 'Annual plan'
                        }}
                    </div>

                    <div
                        class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                    >
                        <span class="font-bold">
                            ${{
                                proSubscription.amount_with_tax
                                    ? (
                                          proSubscription.amount_with_tax / 100
                                      ).toFixed(2)
                                    : 0
                            }}
                        </span>
                        <span
                            >/{{
                                proSubscription.period === 1 ? 'month' : 'year'
                            }}
                        </span>
                    </div>
                    <div
                        v-if="!team.personal_team"
                        class="text-sm text-gray-500"
                    >
                        for {{ proSubscription.totalPaidSeats }} team seats<br />
                        (3 included)
                    </div>

                    <div v-else class="text-sm text-gray-500">
                        for your personal account
                    </div>

                    <div
                        v-if="!proSubscription.canceled_at"
                        class="mt-[18px] text-sm"
                    >
                        <div class="text-gray-500">
                            {{
                                proSubscription.period === 1
                                    ? 'Month-to-month'
                                    : 'Annual'
                            }}
                            plan renews on
                        </div>

                        <div
                            v-if="proSubscription.next_billing_date"
                            class="font-medium text-gray-800"
                        >
                            {{
                                format(
                                    new Date(proSubscription.next_billing_date),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </div>
                    </div>
                    <div v-else class="mt-[18px] text-sm text-gray-500">
                        <div>Subscription canceled, will be active until</div>
                        <div class="font-medium text-gray-800">
                            {{
                                format(
                                    new Date(proSubscription.canceled_at),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </div>
                    </div>

                    <div class="mt-7">
                        <inertia-link
                            href="/billing/invoices"
                            class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                            ><p>Your invoices</p></inertia-link
                        >
                    </div>

                    <div
                        class="mt-[3px] cursor-pointer text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                        @click="subscriptionSwitchModal = true"
                    >
                        Change your subscription
                    </div>
                </div>

                <div class="pt-6 lg:min-w-[200px]">
                    <div
                        v-if="
                            proSubscription.next_billing_date &&
                            !proSubscription.canceled_at
                        "
                    >
                        <div
                            class="inline-flex rounded-md bg-indigo-300 px-3 py-[3px] text-xs font-medium text-gray-700"
                        >
                            Next invoice
                        </div>

                        <div
                            class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                        >
                            <span
                                v-if="
                                    proSubscription.nextMonthAdditionalUsersCount ===
                                    0
                                "
                                class="font-bold"
                            >
                                ${{
                                    proSubscription.amount_with_tax
                                        ? (
                                              proSubscription.amount_with_tax /
                                              100
                                          ).toFixed(2)
                                        : 0
                                }}
                            </span>

                            <span v-else class="font-bold">
                                ${{
                                    proSubscription.nextMonthAdditionalUsersTotal
                                        ? (
                                              proSubscription.nextMonthAdditionalUsersTotal /
                                              100
                                          ).toFixed(2)
                                        : 0
                                }}
                            </span>
                        </div>

                        <div
                            v-if="
                                proSubscription.nextMonthAdditionalUsersCount !==
                                0
                            "
                            class="text-sm text-gray-500"
                        >
                            for
                            {{ proSubscription.nextMonthAdditionalUsersCount }}
                            additional team seats
                        </div>

                        <div
                            v-else-if="
                                proSubscription.nextMonthAdditionalUsersCount ===
                                    0 && !team.personal_team
                            "
                            class="text-sm text-gray-500"
                        >
                            for
                            {{ proSubscription.totalPaidSeats }}
                            team seats <br />(3 included)
                        </div>

                        <div v-else class="text-sm text-gray-500">
                            for 1 member
                        </div>

                        <div
                            v-if="
                                proSubscription.nextMonthAdditionalUsersCount !==
                                0
                            "
                            class="mt-[38px] text-sm"
                        >
                            <div class="text-gray-500">
                                {{
                                    proSubscription.nextMonthAdditionalUsersCount
                                }}
                                team seats renew monthly on
                            </div>

                            <div
                                v-if="proSubscription.nextMonthBillingDate"
                                class="font-medium text-gray-800"
                            >
                                {{
                                    format(
                                        new Date(
                                            proSubscription.nextMonthBillingDate
                                        ),
                                        'd MMMM, yyyy'
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="border-gray-200 pt-6 pb-7 lg:min-w-[200px] xl:border-l xl:pl-6"
                >
                    <div
                        class="inline-flex rounded-md bg-teal-200 px-3 py-[3px] text-xs font-medium text-gray-700"
                    >
                        Data traffic
                    </div>

                    <div
                        v-if="dataTrafficLoading"
                        class="flex justify-start mt-5"
                    >
                        <LoadingSpinner size="medium" />
                    </div>

                    <div
                        v-if="!dataTrafficLoading"
                        class="mt-5 text-2xl leading-normal tracking-tighter text-gray-800"
                    >
                        <span v-if="dataTraffic?.price" class="font-bold">
                            ${{ dataTraffic.price.toFixed(2) }}
                        </span>
                        <span v-else class="font-bold"> $0 </span>
                    </div>

                    <div
                        v-if="dataTraffic?.bytes && !dataTrafficLoading"
                        class="text-sm text-gray-500"
                    >
                        for {{ formatBytes(dataTraffic.bytes) }} above limit
                    </div>
                    <div
                        v-if="dataTraffic?.billing_date && !dataTrafficLoading"
                        class="mt-[39px] text-sm text-gray-500"
                    >
                        <p>Next payment for data traffic on</p>
                        <p class="font-medium text-gray-800">
                            {{
                                format(
                                    new Date(dataTraffic.billing_date),
                                    'd MMMM, yyyy'
                                )
                            }}
                        </p>
                    </div>

                    <div v-if="!dataTrafficLoading" class="mt-7">
                        <inertia-link
                            href="/usage-statistics"
                            class="text-sm font-medium leading-normal text-topol-blue-500 hover:text-topol-blue-600"
                            ><p>Data traffic details</p></inertia-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <!-- <div v-if="proSubscription" class="max-w-lg mt-6 text-sm text-gray-500">
            Team admins will receive an email a few days before payment each
            month recapping what the bill will be and highlighting new editors.
            Team admins will have the chance to adjust permissions before
            payment is collected.
        </div> -->

        <SubscriptionOrderModal
            v-if="subscriptionOrderModal"
            :subscription-plan-id="team.personal_team ? 2 : 10"
            :countries="countries"
            :personal="team.personal_team"
            @close="closeSubscriptionOrderModal"
            @reopen="reopenSubscriptionOrderModal"
        />

        <ProSubscriptionSwitchModal
            v-if="subscriptionSwitchModal"
            :selected-card="selectedCard"
            :pro-subscription="proSubscription"
            @close="subscriptionSwitchModal = false"
            @cancel="handleCancelSubscription"
        />
        <SubscriptionCancelReasonModal
            v-if="subscriptionCancelReasonModal && proSubscription"
            :subscription-id="proSubscription.id"
            @close="subscriptionCancelReasonModal = false"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import { usePage } from '@inertiajs/vue3';
import route from 'ziggy-js';

import { format } from 'date-fns';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';
import axios from 'axios';
import { formatBytes } from '@utils/formatBytes';
import IExceededTrafficData from '@/types/UsageStatistics/IExceededTrafficData';
import ISelectable from '@/types/Atomic/ISelectable';
import ISubscription from '@/types/Subscription/ISubscription';

const props = defineProps<{
    selectedCard?: IPaymentMethod;
    countries: ISelectable[];
    proSubscription: ISubscription | null;
    isTrialingPro: boolean;
}>();

onMounted(async () => {
    if (props.proSubscription !== null) {
        await getDataTraffic();
    }
});

const {
    shouldDisplayProTrialBanner,
    shouldDisplayProPersonalSubscriptionBanner,
    shouldDisplayProTeamSubscriptionBanner,
} = useBanners();

const user = computed(() => usePage<ITopolPageProps>().props.user);
const team = computed(() => usePage<ITopolPageProps>().props.team);
const subscriptionOrderModal = ref(false);
const subscriptionSwitchModal = ref(false);
const subscriptionCancelReasonModal = ref(false);

const dataTraffic = ref<IExceededTrafficData>();
const dataTrafficLoading = ref(false);

const emit = defineEmits<{
    (event: 'subscriptionOrderModalClosed'): void;
}>();

const reopenSubscriptionOrderModal = async () => {
    await closeSubscriptionOrderModal();
    subscriptionOrderModal.value = true;
};
const closeSubscriptionOrderModal = () => {
    subscriptionOrderModal.value = false;
    emit('subscriptionOrderModalClosed');
};

const getDataTraffic = async () => {
    dataTrafficLoading.value = true;
    try {
        const res = await axios.get(
            route('billing.pro-subscriptions.data-traffic.index')
        );
        if (res.data.success) {
            dataTraffic.value = res.data.data;
        }
    } catch (error) {
        () => undefined;
    }
    dataTrafficLoading.value = false;
};

const handleCancelSubscription = () => {
    subscriptionSwitchModal.value = false;
    subscriptionCancelReasonModal.value = true;
};
</script>

<style scoped></style>