<template>
    <div>
        <div class="relative h-full p-6">
            <p class="text-base font-normal text-gray-900">MRR stats</p>
            <canvas ref="chartCtx"></canvas>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, PropType } from 'vue';
import Chart from 'chart.js/auto';
import IStat from '@/types/Stats/IStat';

const props = defineProps({
    stats: {
        type: Array as PropType<IStat[]>,
        default: () => [],
    },
});

const chartCtx = ref<HTMLCanvasElement>();
let myChart = null as Chart | null;

onMounted(() => {
    if (chartCtx.value === undefined) {
        return;
    }
    myChart = new Chart(chartCtx.value, {
        type: 'polarArea',
        data: {
            labels: props.stats.map((stat: IStat) => stat.name),
            datasets: [
                {
                    label: 'My First Dataset',
                    data: props.stats.map((stat: IStat) => stat.count),
                    backgroundColor: [
                        'rgb(88,80,200)',
                        'rgb(88,80,140)',
                        'rgb(88,80,180)',
                        'rgb(88,80,236)',
                        'rgb(224,36,10',
                        'rgb(255,192,0)',
                        'rgb(255,192,100)',
                    ],
                    hoverOffset: 4,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        generateLabels: function (myChart) {
                            var data = myChart.data.datasets[0].data;
                            return data.map(function (value, index) {
                                return {
                                    text: myChart.data.labels
                                        ? myChart.data.labels[index] +
                                          ': ' +
                                          value
                                        : 0,
                                    fillStyle:
                                        myChart.data.datasets[0]
                                            .backgroundColor[index],
                                };
                            });
                        },
                    },
                },
                title: {
                    display: true,
                },
                tooltip: {
                    backgroundColor: '#252F3F',
                    caretSize: 0,
                    caretPadding: 5,
                    boxHeight: 12,
                    boxWidth: 12,
                    multiKeyBackground: 'rgba(0, 0, 0, 0)',
                    cornerRadius: 8,
                    bodySpacing: 8,
                    padding: 8,

                    xAlign: 'center',
                    bodyFont: {
                        family: 'Inter',
                        size: 13,
                        weight: 'normal',
                    },
                },
            },
        },
    });

    myChart?.render();
});

watch(
    () => props.stats,
    () => {
        if (myChart?.data?.datasets[0].data) {
            (myChart.data.labels = props.stats.map((stat: IStat) => stat.name)),
                (myChart.data.datasets[0].data = props.stats.map(
                    (stat: IStat) => stat.count
                )),
                myChart.update();
        }
    }
);
</script>
<style scoped>
/* .chart-calculated-width {
    width: calc(100% - 20px);
} */
</style>
