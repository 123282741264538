<template>
    <div>
        <div class="flex items-center justify-between">
            <label
                v-if="label"
                role="label"
                :for="id"
                class="relative pr-3 pb-[3px] text-sm font-medium leading-normal text-gray-700"
                :class="{ 'text-gray-400': disabled }"
            >
                {{ label }}
                <img
                    v-if="required"
                    class="absolute top-0 right-0 w-2 h-2"
                    data-testid="input-required-indicator"
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/components/star-required.svg"
                    alt=""
                />
            </label>

            <span
                v-if="secondarylabel"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm leading-normal text-gray-500"
                >{{ secondarylabel }}</span
            >

            <InputCount
                v-if="!secondarylabel && textCount"
                :count="modelValue.toString().length"
                :max="textCountMax"
            />

            <span
                v-if="headeraction"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm font-medium leading-normal"
                :class="{
                    'text-gray-400 ': disabled,
                    'cursor-pointer text-topol-blue-500 hover:text-topol-blue-600':
                        !disabled,
                }"
                @click="!disabled ? $emit('headeraction') : null"
                >{{ headeraction }}</span
            >
        </div>

        <div class="relative">
            <textarea
                :id="id"
                :value="modelValue"
                :required="required"
                :placeholder="placeholder"
                :disabled="disabled"
                :rows="rows"
                :cols="cols"
                class="block w-full px-3 py-2 text-sm transition duration-100 ease-in-out bg-white border rounded-md shadow-button focus:outline-none disabled:cursor-not-allowed disabled:text-gray-300 disabled:placeholder-gray-300 disabled:shadow-none disabled:hover:bg-white"
                :class="{
                    'border-topol-alert-100 text-topol-alert-100 placeholder-topol-red-300 ':
                        error,
                    'border-gray-300 text-gray-600 placeholder-gray-400 hover:bg-gray-50 focus:border-blue-400 focus:text-gray-800':
                        !error,
                }"
                @input="onInput"
                @blur="$emit('blur')"
            />

            <div
                v-if="hasHelpSlot && !error"
                class="absolute mt-[3px] text-xs leading-5"
                :class="{
                    'text-gray-400': disabled,
                    'text-gray-500': !disabled,
                }"
            >
                <slot name="help" />
            </div>

            <div
                v-if="error"
                class="absolute mt-[3px] flex items-center text-topol-alert-100"
            >
                <svg
                    data-testid="input-error-icon-indicator"
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                    />
                </svg>

                <div
                    data-testid="input-error-message"
                    class="ml-2 text-xs leading-5"
                >
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

defineProps({
    error: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    secondarylabel: {
        type: String,
        default: '',
    },
    rows: {
        type: Number,
        default: null,
    },
    cols: {
        type: Number,
        default: null,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    resize: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    headeraction: {
        type: String,
        default: '',
    },
    textCount: {
        type: Boolean,
        default: false,
    },
    textCountMax: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(['update:modelValue', 'headeraction', 'blur']);

const slots = useSlots();
const id = Math.floor(Math.random() * 100);

const onInput = (e: Event) => {
    const inputEvent = e.target as HTMLInputElement;

    if (inputEvent.value !== null) {
        emit('update:modelValue', inputEvent.value);
    }
};

const hasHelpSlot = computed(() => {
    return !!slots.help;
});
</script>

<style></style>
