<template>
    <button
        :disabled="disabled"
        :type="type"
        class="inline-flex items-center justify-center font-medium transition duration-100 ease-in-out border rounded-md group hover:shadow-button focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed"
        :class="{
            'border-transparent bg-indigo-600 text-gray-100 hover:text-white focus:text-white focus:ring-indigo-600 disabled:bg-gray-300 disabled:text-white':
                color === 'purple',
            'border-transparent bg-orange-500 text-gray-700 hover:text-gray-800 focus:text-gray-800 focus:ring-orange-500 disabled:bg-gray-300 disabled:text-white':
                color === 'yellow',
            ' border border-gray-300 bg-white text-gray-600 focus:ring-orange-500  disabled:bg-gray-300 disabled:text-white group-hover:text-gray-800 group-focus:text-gray-800':
                color === 'white',
            'border-transparent bg-topol-alert-100 text-white focus:ring-orange-500 disabled:bg-red-400':
                color === 'red',
            'h-6 w-6': size === 'xs' && hasIconOnly,
            'h-6 px-2 text-xs': size === 'xs' && !hasIconOnly,
            'h-8 px-3 text-xs leading-8': size === 'sm',
            'h-10 px-4 text-sm leading-10': size === 'md',
            'h-10 text-sm leading-10': size === 'lg',
            'pr-4 pl-2.5': size === 'lg' && hasIcon,
            'px-4': size === 'lg' && !hasIcon,
            'rounded-2.5xl px-7 text-sm leading-10': rounded === 'lg',
            'rounded-2.5xl pl-5 pr-6 text-sm leading-10': rounded === 'xl',
            'h-10 w-10': hasIconOnly && size !== 'xs',
        }"
        @click="(e) => $emit('click', e)"
    >
        <div
            v-if="hasIcon"
            :class="{
                'text-gray-500 group-hover:text-gray-600 group-focus:text-gray-600 group-disabled:text-white':
                    color === 'white',
            }"
        >
            <slot name="icon"></slot>
        </div>
        <slot />
    </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const slots = useSlots();

defineProps({
    /**
     * Is button disabled
     * @values true, false
     */
    disabled: {
        type: Boolean,
        default: false,
    },

    /**
     * The size of the button
     * @values sm, md, lg, xs
     */
    size: {
        type: String,
        default: 'md',
    },

    /**
     * The color of the button
     * @values yellow, red, purple, white, transparent
     */
    color: {
        type: String,
        default: 'yellow',
    },

    /**
     * The border radius of the button
     * @values md, lg, xl
     */
    rounded: {
        type: String,
        default: 'md',
    },

    type: {
        type: String as () => 'button' | 'submit' | 'reset' | undefined,
        validator(value: string | undefined) {
            return (
                value === undefined ||
                ['button', 'submit', 'reset'].includes(value)
            );
        },
        default: 'button',
    },
});

defineEmits(['click']);

/**
 * If Button has olny icon (not text)
 * @returns boolean
 */
const hasIconOnly = computed(() => {
    return !!slots.icon && !slots.default;
});

const hasIcon = computed(() => {
    return !!slots.icon;
});
</script>
