<template>
    <Tabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-notifications"
    >
        <div class="mt-12">
            <inertia-link
                :href="
                    route('kiosk.users.notifications.create', {
                        user: selectedUser.id,
                    })
                "
            >
                <AtomicButton size="sm" color="yellow">
                    Create
                    <template #icon>
                        <PlusCircleIcon
                            class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </inertia-link>
        </div>

        <DataTable
            class="mt-10"
            :headers="headers"
            :items="notifications"
            searchable
        >
            <template #item-id="{ data }">
                <div class="flex max-w-[100px] truncate p-6 text-gray-500">
                    <div>
                        {{ data }}
                    </div>
                </div>
            </template>

            <template #item-created_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom_show="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.users.notifications.show', {
                            user: selectedUser.id as number,
                            notification: row.id as number,
                        })
                    "
                >
                    <AtomicButton size="sm" color="yellow">
                        <template #icon>
                            <BookOpenIcon
                                class="h-6 w-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-custom_edit="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.users.notifications.edit', {
                            notification: row.id as number,
                            user: selectedUser.id as number,
                        })
                    "
                >
                    <AtomicButton size="sm" color="white">
                        <template #icon>
                            <PencilSquareIcon
                                class="h-6 w-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-custom_delete="{ row }">
                <AtomicButton
                    size="sm"
                    color="red"
                    @click="deleteNotification(row as IAnnouncement)"
                >
                    <template #icon>
                        <TrashIcon class="h-6 w-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IUser from '@/types/Members/IUser';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    BookOpenIcon,
} from '@heroicons/vue/24/outline';

import Tabs from '../components/UserTabs.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IAnnouncement from '@/types/Announcements/IAnnouncement';
import route from 'ziggy-js';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import useConfirm from '@/composables/useConfirm';
import { create } from '@/composables/useNotifications';
import { router } from '@inertiajs/vue3';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const { show } = useConfirm();

const props = defineProps<{
    selectedUser: IUser;
    notifications: IPaginationData<IAnnouncement>;
    userPersonalTeam: ICurrentTeam;
}>();

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id', sortable: true, sortableId: 'id' },
    { text: 'Type', id: 'type', sortable: true, sortableId: 'type' },
    { text: 'Title', id: 'title', sortable: true, sortableId: 'title' },
    {
        text: 'Created',
        id: 'created_at',
        align: 'center',
        sortable: true,
        sortableId: 'created_at',
    },
    { text: '', id: 'custom_show' },
    { text: '', id: 'custom_edit' },
    { text: '', id: 'custom_delete' },
];

const deleteNotification = async (notification: IAnnouncement) => {
    const confirmed = await show({
        header: `Delete notification`,
        message: `Are you sure you want to delete this notification?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.users.notifications.destroy', {
                notification: notification.id,
                user: props.selectedUser.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Notification deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Notification not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
