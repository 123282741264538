<template>
    <div class="flex">
        <Menu as="div" class="relative inline-block text-left">
            <div class="flex items-center gap-2">
                <div>
                    <p
                        class="flex h-10 items-center rounded-md bg-indigo-600 px-3 text-sm font-medium tracking-small text-white"
                    >
                        <span v-if="selectedRange">{{
                            selectedRange.title
                        }}</span>
                        <span v-else>{{ dateFrom }} - {{ dateTo }}</span>
                    </p>
                </div>

                <MenuButton
                    data-testid="open-filters-button"
                    class="inline-flex h-10 items-center justify-center rounded-md border border-gray-300 bg-white px-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                >
                    <CalendarIcon
                        class="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    class="absolute right-0 z-10 mt-2 w-96 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div class="flex flex-row divide-x">
                        <div class="w-56 py-6">
                            <button
                                v-for="(range, index) in ranges"
                                :key="index"
                                class="w-full px-4 py-1 text-left text-sm"
                                :class="{
                                    'bg-indigo-600 text-white':
                                        range.value === selectedRange?.value,
                                    'text-gray-500 hover:bg-gray-100':
                                        range.value !== selectedRange?.value,
                                }"
                                @click="handleSelectRange(range)"
                            >
                                {{ range.title }}
                            </button>
                        </div>

                        <div
                            class="relative flex flex-wrap items-center gap-4 p-6"
                        >
                            <TextInput
                                v-model="dateFrom"
                                label="From"
                                type="date"
                                class="w-32"
                            />

                            <TextInput
                                v-model="dateTo"
                                label="To"
                                type="date"
                                class="w-32"
                            />

                            <AtomicButton
                                class="mt-4 w-40"
                                color="white"
                                @click="setInterval"
                            >
                                Set
                            </AtomicButton>
                        </div>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script lang="ts" setup>
import ISelectable from '@/types/Atomic/ISelectable';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { CalendarIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['setInterval']);

const dateFrom = ref('');
const dateTo = ref('');

const today = new Date();

const setInterval = () => {
    selectedRange.value = null;
    emit('setInterval', dateFrom.value, dateTo.value);
};

const handleSelectRange = (range: ISelectable) => {
    selectedRange.value = range;

    if (range.value === 'yesterday') {
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        dateFrom.value = yesterday.toISOString().split('T')[0];
        dateTo.value = yesterday.toISOString().split('T')[0];
    }

    if (range.value === 'last_7_days') {
        const last7Days = new Date(today);
        last7Days.setDate(last7Days.getDate() - 7);
        dateFrom.value = last7Days.toISOString().split('T')[0];
        dateTo.value = today.toISOString().split('T')[0];
    }

    if (range.value === 'last_30_days') {
        const last30Days = new Date(today);
        last30Days.setDate(last30Days.getDate() - 30);
        dateFrom.value = last30Days.toISOString().split('T')[0];
        dateTo.value = today.toISOString().split('T')[0];
    }

    if (range.value === 'this_month') {
        const month = today.getMonth();
        const year = today.getFullYear();
        const startOfMonth = new Date(year, month, 1);
        const nextDay = new Date(startOfMonth);
        nextDay.setDate(nextDay.getDate() + 1);
        dateFrom.value = nextDay.toISOString().slice(0, 10);
        dateTo.value = today.toISOString().slice(0, 10);
    }

    if (range.value === 'last_month') {
        const month = today.getMonth();
        const year = today.getFullYear();
        const startOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);
        const nextDay = new Date(startOfMonth);
        nextDay.setDate(nextDay.getDate() + 1);
        dateFrom.value = nextDay.toISOString().slice(0, 10);
        dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
    }

    if (range.value === 'this_year') {
        const year = today.getFullYear();
        const startOfYear = new Date(year, 0, 1);
        const nextDay = new Date(startOfYear);
        nextDay.setDate(nextDay.getDate() + 1);
        dateFrom.value = nextDay.toISOString().slice(0, 10);
        dateTo.value = today.toISOString().slice(0, 10);
    }

    emit('setInterval', dateFrom.value, dateTo.value);
};

const selectedRange = ref<ISelectable | null>({
    title: 'Last 30 days',
    value: 'last_30_days',
});

const ranges: ISelectable[] = [
    {
        title: 'Yesterday',
        value: 'yesterday',
    },
    {
        title: 'Last 7 days',
        value: 'last_7_days',
    },
    {
        title: 'Last 30 days',
        value: 'last_30_days',
    },
    {
        title: 'This month',
        value: 'this_month',
    },
    {
        title: 'Last month',
        value: 'last_month',
    },
    {
        title: 'This year',
        value: 'this_year',
    },
];
</script>
