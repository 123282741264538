<template>
    <form class="grid-cols-layout lg:grid" @submit.prevent="save">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Custom fonts
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                You can set specific self-hosted and Google fonts to suit your
                needs.
            </p>
        </div>
        <div
            class="relative w-full mx-auto bg-white rounded-md shadow-card lg:mx-0"
        >
            <div
                class="flex flex-wrap gap-2 px-6 py-5 border-b border-gray-200"
            >
                <FontComponent
                    v-for="(font, index) in customFonts"
                    :key="index"
                    :font="font"
                    :checked="font.enabled === true"
                    editable
                    @edit-font="showEditFont(index)"
                    @select-font="selectFont(font)"
                />

                <div
                    class="w-[150px] cursor-pointer rounded-md border border-gray-300 p-2 shadow-sm hover:shadow-md"
                    @click="showCustomFontModal = true"
                >
                    <div
                        class="flex flex-col items-center justify-center py-[2px] text-sm text-gray-600 hover:text-gray-700"
                    >
                        <PlusIcon class="w-5 h-5" />
                        <div class="mt-1">Add new</div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <div class="pr-6 my-3">
                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="sm"
                        :disabled="saveDisabled"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>

            <CustomFontsModal
                v-if="showCustomFontModal"
                :font="selectedFontIndex !== null ? customFonts[selectedFontIndex] : {}"
                @close="closeCustomFontModal"
                @save="saveFont"
                @delete="deleteFont"
            />
        </div>
    </form>
</template>

<script lang="ts" setup>
import IEditorSettings from '@/types/Settings/IEditorSettings';
import IFont from '@/types/Settings/IFont';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { PropType } from 'vue';
import useFonts from '../composables/useFonts';
import useConfirm from '@composables/useConfirm';

const props = defineProps({
    settings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
});
const emit = defineEmits(['save']);

const { show } = useConfirm();

onBeforeMount(() => {
    customFonts.value.forEach((font) => {
        if (font.url) {
            loadExternalStylesheet(font.url);
        }
    });
});

const { loadExternalStylesheet } = useFonts();
const showCustomFontModal = ref(false);
const customFonts = ref(props.settings.custom_fonts ?? []);
const initialFonts = JSON.stringify(props.settings.custom_fonts);
const selectedFontIndex = ref<number | null>(null);

const saveDisabled = computed(() => {
    return initialFonts === JSON.stringify(customFonts.value);
});

const selectFont = (font: IFont) => {
    font.enabled = !font.enabled;
};

const showEditFont = (index: number) => {
    showCustomFontModal.value = true;
    selectedFontIndex.value = index;
};

const closeCustomFontModal = () => {
    showCustomFontModal.value = false;
    selectedFontIndex.value = null;
};

const saveFont = (font: IFont) => {
    if (selectedFontIndex.value !== null) {
        customFonts.value[selectedFontIndex.value] = font;
    } else {    
        customFonts.value = [...customFonts.value, font];
    }

    showCustomFontModal.value = false;

    selectedFontIndex.value = null;
    save();
};

const deleteFont = async () => {
    const confirmed = await show({
        type: 'danger',
        header: "Delete font",
        message: "Are you sure you want to delete this font?"
    });

    if (confirmed && selectedFontIndex.value !== null) {
        customFonts.value = customFonts.value.filter(
            (font, index) => index !== selectedFontIndex.value
        );
    
        showCustomFontModal.value = false;
        selectedFontIndex.value = null;
        save();
    }
};

const save = () => {
    emit('save', customFonts.value);
};
</script>
