const buildQueryFromObject = (
    obj: Record<string, string | number | Record<string, string | number>>,
    prefix?: string
): string => {
    const query = [];

    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const currentKey = prefix ? prefix + '[' + key + ']' : key;

            const currentValue = obj[key] as string;

            if (currentValue === '' || currentValue === undefined) {
                continue;
            }

            query.push(
                currentValue !== null && typeof currentValue === 'object'
                    ? buildQueryFromObject(currentValue, currentKey)
                    : encodeURIComponent(currentKey) +
                          '=' +
                          encodeURIComponent(currentValue)
            );
        }
    }
    return query.join('&');
};

export default buildQueryFromObject;
