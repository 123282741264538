import '../css/app.css';
import Vue3TouchEvents from 'vue3-touch-events';
import VueClickAway from 'vue3-click-away';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import './plugins/axios';

import 'highlight.js/styles/stackoverflow-light.css';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

hljs.registerLanguage('javascript', javascript);

//@ts-expect-error
import dynamicImportPolyfill from 'dynamic-import-polyfill';

// This needs to be done before any dynamic imports are used.
dynamicImportPolyfill.initialize();

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';

import Layout from '@/Layouts/Default.vue';
import { Link } from '@inertiajs/vue3';

import withUUID from 'vue-uuid';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue, { BugsnagPluginVueResult } from '@bugsnag/plugin-vue';

const appEl = document.getElementById('app')?.dataset.page;

if (appEl === undefined) {
    throw new Error('Missing Main Page Element!');
}

let bugsnagVue: BugsnagPluginVueResult | undefined = undefined;

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
    });

    bugsnagVue = Bugsnag.getPlugin('vue');
}

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    progress: {
        color: '#FFC000',
    },

    setup({ el, App, plugin }) {
        const app = createApp({
            render: () =>
                h(App, {
                    initialPage: JSON.parse(appEl),
                    resolveComponent: async (name: string) => {
                        const pages = import.meta.glob('./Pages/**/*.vue');
                        const importPage = pages[`./Pages/${name}.vue`];
                        if (!importPage) {
                            throw new Error(
                                `Unknown page ${name}. Is it located under Pages with a .vue extension?`
                            );
                        }
                        //@ts-ignore
                        return importPage().then((module) => {
                            const page = module.default;
                            if (name.includes('Auth')) {
                                return page;
                            }
                            page.layout = page.layout || Layout;
                            return page;
                        });
                    },
                }),
        })
            .use(plugin)
            .use(VueClickAway)
            .use(Vue3TouchEvents)
            .use(hljsVuePlugin)
            .use(withUUID)
            .use(VueReCaptcha, {
                siteKey: '6LeowvAdAAAAAFTChiD9pWNmWmt7uTyMRpncRGya',
                loaderOptions: {
                    autoHideBadge: true,
                },
            })
            .use(FloatingVue)

            .component('InertiaLink', Link);

        if (bugsnagVue) {
            app.use(bugsnagVue);
        }

        app.mount(el);
    },
});
