<template>
    <Tabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-activity"
    >
        <DataTable
            class="mt-12"
            title="Activities"
            searchable
            :headers="headers"
            :items="activities"
        >
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IActivity from '@/types/Members/IActivity';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IUser from '@/types/Members/IUser';

import Tabs from '../components/UserTabs.vue';

defineProps<{
    selectedUser: IUser;
    activities: IPaginationData<IActivity>;
    userPersonalTeam: ICurrentTeam;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Description',
        id: 'description',
    },
    {
        text: 'Subject ID',
        id: 'subject_id',
    },
    {
        text: 'Subject Type',
        id: 'subject_type',
    },
    {
        text: 'Time',
        id: 'created_at',
    },
];
</script>
