<template>
    <MemberTabs tab-key="invitations">
        <div class="mt-12 pb-80">
            <DataTable
                v-if="isAdmin || isOwner"
                :headers="headersInvitations"
                :items="invitations"
                searchable
                title="Team invitations"
            >
                <template #item-token="{ data }">
                    <div v-if="data" class="px-6 text-gray-500">
                        {{ teamProps.name }}
                    </div>
                </template>

                <template #item-created_at="{ data, row }">
                    <div class="px-6">
                        <div
                            class="inline-block rounded-md bg-orange-200 px-2 py-[3px] text-xs font-medium text-gray-700"
                        >
                            Invitation pending ({{
                                differenceInCalendarDays(
                                    new Date(),
                                    new Date(data as string)
                                )
                            }}
                            <span
                                v-html="
                                    differenceInCalendarDays(
                                        new Date(),
                                        new Date(data as string)
                                    ) !== 1
                                        ? 'days)'
                                        : 'day)'
                                "
                            ></span>

                            <span
                                class="ml-[10px] cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                                @click="remindInvitation(row as IInvitation)"
                                >Remind</span
                            >
                        </div>
                    </div>
                </template>
                <template #item-role="{ data }">
                    <div class="px-6">
                        <div
                            class="inline-block rounded-md bg-blue-200 px-2 py-[3px] text-sm"
                        >
                            {{ data }}
                        </div>
                    </div>
                </template>
                <template #item-custom="{ row }">
                    <AtomicButton
                        class="mx-4"
                        size="sm"
                        color="white"
                        @click="deleteInvitation(row as IInvitation)"
                    >
                        <template #icon>
                            <TrashIcon class="w-5 h-5 text-gray-400" />
                        </template>
                    </AtomicButton>
                </template>
            </DataTable>
        </div>
    </MemberTabs>
</template>

<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IInvitation from '@/types/Members/IInvitation';
import MemberTabs from './components/MemberTabs.vue';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';
import { TrashIcon } from '@heroicons/vue/24/outline';
import usePermissions from '@/composables/usePermissions';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from '@inertiajs/vue3';
import { differenceInCalendarDays } from 'date-fns';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import useConfirm from '@/composables/useConfirm';

defineProps<{
    invitations: IPaginationData<IInvitation>;
}>();

const { isAdmin, isOwner } = usePermissions();

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const { show: showConfirmation } = useConfirm();

const headersInvitations: IDataTableHeader[] = [
    { text: 'E-mail', id: 'email' },
    { text: 'Team', id: 'token' },
    {
        text: 'Invitation pending',
        id: 'created_at',
    },
    {
        text: 'Permissions / Role',
        id: 'role',
        align: 'left',
    },
    { text: '', id: 'custom' },
];

const remindInvitation = (value: IInvitation) => {
    const form = {
        email: value.email,
        role: value.role,
    };
    router.post(route('members.store'), form, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Invitation reminded',
                type: 'info',
            });
        },
        onError: () => {
            create({
                title: 'Invitation not reminded',
                text: 'There was an error reminding your invitation.',
                type: 'error',
            });
        },
    });
};

const deleteInvitation = async (value: IInvitation) => {
    const confirmed = await showConfirmation({
        header: 'Delete invitation',
        message: `Do you really want to delete this invitation?`,
        type: 'danger',
    });

    if (!confirmed) {
        return;
    }

    router.get(
        route('team.invitation.refuse', { token: value.token }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Invitation deleted',
                    type: 'info',
                });
            },
            onError: () => {
                create({
                    title: 'Invitation not deleted',
                    text: 'There was an error deleting your invitation.',
                    type: 'error',
                });
            },
        }
    );
};
</script>
