<template>
    <AuthLayout>
        <div
            class="w-full p-6 mt-8 overflow-hidden bg-white shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mt-[2px] mb-8 border-b border-gray-200 pb-5">
                <h1 class="text-xl font-bold text-center text-gray-800">
                    Sign in to your account
                </h1>

                <div
                    v-if="status"
                    class="mt-4 mb-2 text-sm font-medium text-center text-green-600"
                >
                    {{ status }}
                </div>
            </div>

            <form @submit.prevent="submit">
                <div
                    class="flex flex-col items-center w-full gap-2 border-gray-300"
                >
                    <SocialiteButton
                        login
                        provider="google"
                        label="Google"
                        url="google.auth.index"
                        class="w-full"
                    />

                    <SocialiteButton
                        login
                        provider="facebook"
                        label="Facebook"
                        url="facebook.auth.index"
                        class="w-full"
                    />

                    <SocialiteButton
                        login
                        provider="github"
                        label="GitHub"
                        url="github.auth.index"
                        class="w-full"
                    />

                    <SocialiteButton
                        login
                        provider="gitlab"
                        label="GitLab"
                        url="gitlab.auth.index"
                        class="w-full"
                    />
                </div>

                <h5 class="pb-4 mt-4 font-medium text-center text-gray-600">
                    or
                </h5>

                <div class="pt-4 border-t border-gray-300">
                    <TextInput
                        id="email"
                        ref="input"
                        v-model="state.form.email"
                        type="email"
                        required
                        suppress-required-icon
                        autofocus
                        autocomplete="email"
                        name="email"
                        label="Email address"
                        placeholder="Enter your email address"
                        :error="getError('email')"
                        @blur="v$.form.email.$touch()"
                        @update:model-value="updateServerErrors('email')"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        id="password"
                        ref="input"
                        v-model="v$.form.password.$model"
                        suppress-required-icon
                        type="password"
                        name="password"
                        required
                        autocomplete="current-password"
                        label="Password"
                        placeholder="Enter your password"
                        :error="getError('password')"
                    />
                </div>

                <div class="block mt-6">
                    <CheckboxInput
                        v-model:checked="remember"
                        size="lg"
                        name="remember"
                        label="Remember me"
                    />
                </div>

                <div class="flex flex-col items-center mt-10">
                    <MegaButton
                        id="login-button"
                        :disabled="loading"
                        type="submit"
                    >
                        Sign in
                    </MegaButton>

                    <inertia-link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="pb-2 mt-6 text-xs font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        >Forgot your password?</inertia-link
                    >
                </div>
            </form>
        </div>

        <div class="text-sm text-center text-gray-500 mt-11">
            Don't have an account?

            <inertia-link :href="route('register')">
                <span
                    class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    >Create one!</span
                >
            </inertia-link>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import TextInput from '@atomic/Inputs/TextInput.vue';
import CheckboxInput from '@atomic/Inputs/CheckboxInput.vue';
import { reactive, ref } from 'vue';
import route from 'ziggy-js';
import { useReCaptcha } from 'vue-recaptcha-v3';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { router } from '@inertiajs/vue3';
import MegaButton from '@/components/Atomic/Buttons/MegaButton.vue';
import IInputErrors from '@/types/Atomic/IInputErrors';
import Bugsnag from '@bugsnag/js';

defineProps({
    canResetPassword: {
        type: Boolean,
        required: true,
    },
    status: {
        type: String,
        default: () => null,
    },
});

const reCaptcha = useReCaptcha();
const state = reactive({
    form: {
        email: '',
        password: '',
        remember: '',
        recaptcha_token: '',
    },
});
const rules = {
    form: {
        email: { required, email },
        password: { required },
    },
};
const v$ = useVuelidate(rules, state);
const remember = ref(false);
const loading = ref(false);
const errors = ref<IInputErrors>({});

const dev = ref(false);

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('dev') === 'true') {
        dev.value = true;
    }
});

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        let token = undefined;

        try {
            await reCaptcha?.recaptchaLoaded();
            token = await reCaptcha?.executeRecaptcha("login");

            if (token === undefined) {
                throw new Error();
            }
        } catch (e) {
            loading.value = false;
            errors.value = {
                email: "ReCaptcha validation failed.",
            };
            return;
        }
        state.form.recaptcha_token = token;
        state.form.remember = remember.value ? 'on' : '';
        router.post(route('login'), state.form, {
            preserveScroll: true,
            onError: (err) => {
                if (typeof err === 'object' && err !== null) {
                    const inputErrors = err as IInputErrors;
                    errors.value = inputErrors;
                } else {
                    Bugsnag.notify(JSON.stringify(err));
                }
            },
            onFinish: () => {
                loading.value = false;
                state.form.password = '';
                v$.value.$reset();
            },
        });
    }
};

const updateServerErrors = (field: string) => {
    if (errors.value?.[field]) {
        errors.value[field] = '';
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
