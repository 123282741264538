<template>
    <div class="p-6 mt-10 bg-white rounded-md shadow-md">
        <div
            class="flex flex-col sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="flex flex-col sm:flex-row sm:items-center">
                <div
                    class="h-20 w-20 shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                >
                    <img
                        :src="
                            selectedTeam.profile_photo_path
                                ? selectedTeam.profile_photo_path
                                : selectedTeam.profile_photo_url
                        "
                        class="object-cover w-20 h-20 bg-white rounded-full"
                    />
                </div>
                <div class="flex flex-col mt-2 sm:mt-0 sm:ml-10">
                    <h2 class="text-2xl font-bold text-gray-800">
                        {{ selectedTeam.name }}
                    </h2>
                    <div class="text-xl font-medium text-gray-500">
                        {{ selectedTeam.email }}
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <AtomicButton
                        size="lg"
                        color="yellow"
                        class="mt-5 sm:mt-0"
                        @click="transferToTeamModal = true"
                    >
                        <template #icon>
                            <ChevronDoubleRightIcon class="w-6 h-6 mr-2" />
                        </template>
                        Transfer to team
                    </AtomicButton>

                    <AtomicButton
                        v-if="!selectedTeam.flexibee_id"
                        size="sm"
                        color="yellow"
                        class="mt-5 ml-2 sm:mt-0"
                        @click="createFlexibeeId(selectedTeam)"
                    >
                        <template #icon>
                            <DocumentTextIcon class="w-6 h-6 mr-2" />
                        </template>
                        Create Flexibee ID
                    </AtomicButton>

                    <AtomicButton
                        size="lg"
                        color="red"
                        class="mt-5 ml-2 sm:mt-0"
                        @click="handleDeleteTeam(selectedTeam)"
                    >
                        <template #icon>
                            <TrashIcon class="w-6 h-6 mr-2" />
                        </template>
                        Delete team
                    </AtomicButton>
                </div>
            </div>
        </div>
        <div class="grid gap-4 mt-5 sm:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="text-sm font-medium text-gray-800">ID:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{ selectedTeam.id }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Flexibee ID:
                    </div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            selectedTeam.flexibee_id
                                ? 'TOP_' + selectedTeam.id
                                : '-'
                        }}
                    </div>
                </div>

                <div class="flex mt-1">
                    <div class="text-sm font-medium text-gray-800">
                        Created:
                    </div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            format(
                                new Date(selectedTeam.created_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>

                <div class="mt-1 text-sm text-gray-500">
                    <div class="font-medium text-gray-800">Subscription:</div>

                    <div v-if="selectedTeam.proSubscription">
                        PRO
                        <span>
                            ({{
                                selectedTeam.proSubscription.braintree_plan
                            }})</span
                        >
                    </div>
                    <div v-if="selectedTeam.pluginSubscription">
                        Plugin
                        <span>
                            ({{
                                selectedTeam.pluginSubscription.braintree_plan
                            }})</span
                        >
                    </div>
                    <div v-if="selectedTeam.hasLegacySubscription">Legacy</div>
                </div>
            </div>

            <div class="flex flex-col">
                <div class="text-sm font-medium text-gray-80">
                    Team trial ends:

                    <TextInput
                        v-model="teamTrialDateComputed"
                        type="datetime-local"
                        class="w-48 mt-3"
                    />
                    <AtomicButton
                        size="sm"
                        color="purple"
                        class="mt-2"
                        @click="handleExtendTrial"
                    >
                        Set/Extend Team Trial
                    </AtomicButton>
                </div>
            </div>
        </div>
    </div>

    <TransferToTeam
        v-if="transferToTeamModal"
        :team="selectedTeam"
        @close="transferToTeamModal = false"
    />
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import {
    TrashIcon,
    ChevronDoubleRightIcon,
    DocumentTextIcon,
} from '@heroicons/vue/24/outline';

import route from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import useConfirm from '@/composables/useConfirm';
import { create } from '@/composables/useNotifications';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import axios from 'axios';
import TransferToTeam from '@/Pages/Kiosk/Teams/components/TransferToTeam.vue';

const props = defineProps<{
    selectedTeam: ICurrentTeam;
}>();

const { show } = useConfirm();

const teamTrialDate = ref('');
const transferToTeamModal = ref(false);

const handleDeleteTeam = async (selectedTeam: ICurrentTeam) => {
    const confirmed = await show({
        header: `Delete team ${selectedTeam.name}`,
        message: `Are you sure you want to delete team ${selectedTeam.name}?`,
        type: 'danger',
        confirm: 'Delete team',
    });
    if (confirmed) {
        await deleteTeam(selectedTeam);
    }
};

const deleteTeam = async (selectedTeam: ICurrentTeam) => {
    try {
        const res = await axios.delete(
            route('kiosk.teams.delete', { team: selectedTeam.id })
        );
        if (res.data.success) {
            create({
                title: `Team deleted`,
                type: 'success',
            });
            router.get(route('kiosk.teams.index'));
        } else {
            create({
                title: 'Team not deleted',
                text: `There was an error deleting team.`,
                type: 'error',
            });
        }
    } catch (err) {
        create({
            title: 'Team not deleted',
            text: `There was an error deleting team.`,
            type: 'error',
        });
    }
};

const createFlexibeeId = async (team: ICurrentTeam) => {
    const response = await axios.post(
        route('kiosk.teams.flexibee.store', {
            team: team.id,
        })
    );

    if (response.status === 200) {
        create({
            title: `Flexibee ID created`,
            type: 'success',
        });
        team.flexibee_id = response.data.data.flexibee_id;
    } else {
        create({
            title: 'Flexibee ID not created',
            text: `There was an error creating Flexibee ID.`,
            type: 'error',
        });
    }
};

const handleExtendTrial = async () => {
    const confirmed = await show({
        header: `Extend team trial`,
        message: `Are you sure you want to update trial date of team?`,
        type: 'info',
        confirm: 'Update',
    });

    if (confirmed) {
        router.put(
            route('kiosk.teams.trial-extend.update', {
                team: props.selectedTeam.id,
            }),
            {
                date: teamTrialDate.value,
            },
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    create({
                        title: `Trial extended`,
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Trial not extended',
                        text: `There was an error extending trial.`,
                        type: 'error',
                    });
                },
                onFinish: () => {
                    teamTrialDate.value = '';
                },
            }
        );
    }
};

const teamTrialDateComputed = computed({
    get: function () {
        return props.selectedTeam.trial_ends_at
            ? new Date(props.selectedTeam.trial_ends_at)
                  .toISOString()
                  .substring(0, 19)
            : '';
    },
    set: function (newValue: string) {
        teamTrialDate.value = newValue;
    },
});
</script>
