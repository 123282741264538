<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Update API token permissions</div>
        </template>

        <template #body>
            <div class="my-6 flex w-full flex-wrap border-gray-200 px-6 pl-6">
                <div class="w-full pb-2">
                    <label
                        role="label"
                        class="relative pr-3 text-sm font-medium leading-normal text-gray-700"
                        >Permissions</label
                    >
                </div>
                <div class="w-1/2">
                    <CheckboxInput
                        v-model:checked="form.read"
                        name="readPermission"
                        label="Read"
                        size="md"
                        class="pb-5"
                    />
                </div>
                <div class="w-1/2">
                    <CheckboxInput
                        v-model:checked="form.create"
                        :option="true"
                        name="createPermission"
                        label="Create"
                        size="md"
                    />
                </div>
                <div class="w-1/2">
                    <CheckboxInput
                        v-model:checked="form.update"
                        name="updatePermission"
                        label="Update"
                        size="md"
                    />
                </div>
                <div class="w-1/2">
                    <CheckboxInput
                        v-model:checked="form.delete"
                        name="deletePermission"
                        label="Delete"
                        size="md"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>
                <AtomicButton
                    color="yellow"
                    size="md"
                    :disabled="loading || !isDifferent"
                    @click="saveApiTokenPermissions()"
                >
                    Save
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '@atomic/Modals/Modal.vue';
import CheckboxInput from '@atomic/Inputs/CheckboxInput.vue';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';

const emits = defineEmits(['close']);
const props = defineProps({
    apiToken: {
        type: Object,
        required: true,
    },
});

const form = ref({
    read:
        props.apiToken.abilities &&
        (Object.values(props.apiToken.abilities).includes('read') ||
            Object.values(props.apiToken.abilities).includes('*')),
    create:
        props.apiToken.abilities &&
        (Object.values(props.apiToken.abilities).includes('create') ||
            Object.values(props.apiToken.abilities).includes('*')),
    update:
        props.apiToken.abilities &&
        (Object.values(props.apiToken.abilities).includes('update') ||
            Object.values(props.apiToken.abilities).includes('*')),
    delete:
        props.apiToken.abilities &&
        (Object.values(props.apiToken.abilities).includes('delete') ||
            Object.values(props.apiToken.abilities).includes('*')),
});

const isDifferent = ref(false);

const loading = ref(false);
const saveApiTokenPermissions = async () => {
    loading.value = true;

    router.put(
        route('settings.api-tokens.update-permissions', props.apiToken.id),
        form.value,
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'API token permissions updated successfully',
                    type: 'success',
                });
                loading.value = false;
                emits('close');
            },
            onError: () => {
                create({
                    title: 'API token permissions has not been updated',
                    type: 'error',
                });
                loading.value = false;
            },
        }
    );
};

watch(
    form,
    () => {
        if (
            !props.apiToken.abilities &&
            (form.value.read ||
                form.value.create ||
                form.value.update ||
                form.value.delete)
        ) {
            isDifferent.value = true;
        } else {
            isDifferent.value =
                form.value.read !=
                    Object.values(props.apiToken.abilities).includes('read') ||
                form.value.create !=
                    Object.values(props.apiToken.abilities).includes(
                        'create'
                    ) ||
                form.value.update !=
                    Object.values(props.apiToken.abilities).includes(
                        'update'
                    ) ||
                form.value.delete !=
                    Object.values(props.apiToken.abilities).includes('delete');
        }
    },
    { deep: true }
);
</script>
