<template>
    <SubscriptionTabs tab-key="recent-subscriptions" class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="subscriptions"
            :filters="filters"
        >
            <template #item-type="{ data }">
                <div
                    class="flex justify-center rounded-md px-2 py-[3px] text-center text-xs text-gray-500"
                    :class="{
                        'bg-orange-200': data === 'pro',
                        'bg-teal-200': data === 'plugin',
                        'bg-green-200': data === 'additional users',
                    }"
                >
                    {{ data }}
                </div>
            </template>

            <template #item-amount="{ data, row }">
                <div class="flex justify-center text-gray-500">
                    {{ ((data as number) / 100).toFixed(2) }}

                    {{ row.currency }}
                </div>
            </template>

            <template #item-period="{ data, row }">
                <div class="flex justify-center text-gray-500">
                    {{ data }}

                    {{ row.period_unit }}
                </div>
            </template>

            <template #item-trial_ends_at="{ data }">
                <div v-if="data" class="text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>

            <template #item-canceled_at="{ data }">
                <div v-if="data" class="text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>

            <template #item-next_billing_date="{ data }">
                <div v-if="data" class="text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>

            <template #item-created_at="{ data }">
                <div v-if="data" class="text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>
        </DataTable>
    </SubscriptionTabs>
</template>

<script lang="ts" setup>
import ISubscription from '@/types/Subscription/ISubscription';

import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import { format } from 'date-fns';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { IDataTableFilter } from '@/components/Atomic/DataTable/types/IDataTableFilter';

defineProps<{
    subscriptions: IPaginationData<ISubscription>;
}>();

const headers: IDataTableHeader[] = [
    { text: 'Team ID', id: 'team_id', sortable: true, sortableId: 'team_id' },
    {
        text: 'Type',
        id: 'type',
        sortable: true,
        sortableId: 'type',
    },
    {
        text: 'Braintree plan',
        id: 'braintree_plan',
        sortable: true,
        sortableId: 'braintree_plan',
    },
    {
        text: 'Amount',
        id: 'amount',
        sortable: true,
        sortableId: 'amount',
    },
    {
        text: 'Period',
        id: 'period',
        sortable: true,
        sortableId: 'period',
    },
    {
        text: 'Additional users',
        id: 'paid_seats',
        sortable: true,
        sortableId: 'paid_seats',
    },
    {
        text: 'Trial until',
        id: 'trial_ends_at',
        sortable: true,
        sortableId: 'trial_ends_at',
    },
    {
        text: 'Canceled at',
        id: 'canceled_at',
        sortable: true,
        sortableId: 'canceled_at',
    },
    {
        text: 'Next billing',
        id: 'next_billing_date',
        sortable: true,
        sortableId: 'next_billing_date',
    },
    {
        text: 'Created',
        id: 'created_at',
        sortable: true,
        sortableId: 'created_at',
    },
    { text: '', id: 'custom' },
];

const filters: IDataTableFilter[] = [
    {
        column: 'braintree_plan',
        label: 'Plan',
        values: [
            {
                label: 'PRO (yearly)',
                value: 'Pro_yearly_120',
            },
            {
                label: 'PRO (monthly)',
                value: 'Pro_monthly_15',
            },
            {
                label: 'Plugin 50',
                value: 'plugin_50',
            },
            {
                label: 'Plugin 1000',
                value: 'plugin_1000',
            },
            {
                label: 'Plugin Unlimited (monthly)',
                value: 'plugin_unlimited_monthly',
            },
            {
                label: 'Plugin Unlimited (yearly)',
                value: 'plugin_unlimited_yearly',
            },
            {
                label: 'Additional users',
                value: 'pro_additional_users',
            },
        ],
    },
];
</script>

<style></style>
