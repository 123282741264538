<template>
    <Tabs :selected-team="selectedTeam" tab-key="feature-management">
        <div class="p-6 mt-12 text-gray-900 bg-white rounded-md shadow-md">
            <div class="grid grid-cols-4">
                <div>
                    <div class="text-base font-medium text-gray-700">
                        {{ selectedTeam.name }}
                    </div>

                    <div>
                        <div
                            v-for="(feature, index) in newFeatures"
                            :key="index"
                            class="flex items-center my-2"
                        >
                            <div class="mr-4 text-sm text-gray-600">
                                {{ feature.feature }}:
                            </div>

                            <CheckboxInput
                                v-if="feature.value === true || feature.value === false"
                                    v-model:checked="feature.value"
                                    size="md"
                                    :name="feature.feature"
                                    @change="
                                        updateFeature(feature, selectedTeam)
                                    "
                                />

                            <SelectInput
                                v-else
                                v-model="feature.value"
                                class="w-full sm:w-40"
                                placeholder="Select"
                                :data="featureOptions(feature)"
                                :name="feature.feature"
                                @update:modelValue="
                                    updateFeature(feature, selectedTeam)
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Tabs>
</template>
<script lang="ts" setup>
import Tabs from '../components/TeamTabs.vue';
import CheckboxInput from '@/components/Atomic/Inputs/CheckboxInput.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

interface IFeature {
    feature: string;
    value: string|boolean;
    data: string[]|null;
}

defineProps<{
    selectedTeam: ICurrentTeam;
    newFeatures: IFeature[];
}>();

const updateFeature = (feature: IFeature, team: ICurrentTeam) => {
    router.put(
        route('kiosk.teams.feature-management.update', {
            team: team.id,
            feature: feature.feature,
            value: feature.value,
        }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Feature updated',
                    type: 'success',
                });
            },
            onError: () => {
                create({
                    title: 'Feature update failed',
                    type: 'error',
                });
            },
        }
    );
};

const featureOptions = (feature: IFeature) => {
    if (feature.data === null) {
        return [];
    }

    return feature.data.map((option: string) => {
        return { value: option, title: option };
    }); 
}
</script>
