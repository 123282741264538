<template>
    <div
        class="flex items-center justify-between border-b border-gray-100 py-2 text-base text-gray-600"
    >
        <div>
            <span class="text-sm"> {{ item.quantity }}x </span>
            <button
                class="font-medium hover:text-gray-800"
                @click="showTemplate"
            >
                {{ item.product_name }}
            </button>
        </div>

        <div class="flex items-center gap-4 text-sm">
            <div v-if="item.amount > 0">${{ item.amount / 100 }}</div>
            <div v-else>Free</div>

            <Action :items="actions" activator-type="dots" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import IActionNode from '@/types/Atomic/IActions';
import ICartItem from '@/types/Marketplace/ICartItem';
import { PropType } from 'vue';
const { removeTemplateFromCart, close } = useMarketplaceCart();
import route from 'ziggy-js';

const props = defineProps({
    item: {
        type: Object as PropType<ICartItem>,
        required: true,
    },
});

const showTemplate = () => {
    if (!props.item.marketplace_template_id) return;
    close();
    router.get(
        route(
            'marketplace.all-templates.show',
            props.item.marketplace_template_id
        )
    );
};

const actions: IActionNode[] = [
    {
        name: 'Show',
        onClick() {
            showTemplate();
        },
    },
    {
        name: 'Remove',
        onClick() {
            if (!props.item.marketplace_template_id) return;
            removeTemplateFromCart(props.item.marketplace_template_id);
        },
    },
];
</script>
