<template>
    <div>
        <Modal size="full" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">Make payment request</div>
            </template>

            <template #body>
                <div class="justify-between p-6 border-t border-gray-200">
                    <div class="flex gap-2">
                        <SelectInput
                            v-model="type"
                            class="my-6 w-52 sm:w-80"
                            label="Target"
                            :data="[
                                {
                                    title: 'PRO',
                                    value: 'pro',
                                },
                                {
                                    title: 'Plugin',
                                    value: 'plugin',
                                },
                            ]"
                        />

                        <TextInput
                            :model-value="personalTeam.id"
                            class="my-6 w-52 sm:w-80"
                            label="Target ID"
                            readonly
                        />
                    </div>

                    <div class="flex gap-2">
                        <TextInput
                            v-model="name"
                            class="my-6 w-52 sm:w-80"
                            label="Name"
                        />

                        <TextInput
                            v-model="dueDate"
                            class="my-6 w-52 sm:w-80"
                            label="Due date"
                            type="date"
                        />
                    </div>

                    <div class="p-4 mt-4 bg-white rounded-md shadow-sm">
                        <div class="text-lg font-medium text-gray-900">
                            Items
                        </div>

                        <div class="mt-4">
                            <div class="grid grid-cols-6 gap-x-5">
                                <div>Name</div>
                                <div>Pricing list item</div>
                                <div>Price</div>
                                <div>Quantity</div>
                                <div>Total</div>
                                <div></div>
                            </div>
                        </div>
                        <div
                            v-for="(item, index) in items"
                            :key="index"
                            class="grid items-center grid-cols-6 mt-4 gap-x-5"
                        >
                            <TextInput v-model="item.name" />
                            <SelectInput
                                v-model="item.pricing_list_item_id"
                                :data="pricingListItems"
                            />
                            <TextInput v-model="item.price" type="number" />
                            <TextInput v-model="item.quantity" type="number" />
                            <TextInput
                                :model-value="item.price * item.quantity"
                                readonly
                                type="number"
                            />

                            <AtomicButton
                                class="w-10"
                                color="white"
                                size="md"
                                @click="items.splice(index, 1)"
                            >
                                <template #icon>
                                    <TrashIcon class="w-6 h-6" />
                                </template>
                            </AtomicButton>
                        </div>

                        <div class="mt-4">
                            <AtomicButton
                                color="white"
                                size="md"
                                @click="
                                    items.push({
                                        name: '',
                                        price: 0,
                                        quantity: 1,
                                        pricing_list_item_id: 'TOP-DATA-21',
                                    })
                                "
                            >
                                Add item
                            </AtomicButton>
                        </div>

                        <div class="flex justify-end">
                            <div>
                                <div class="text-lg font-medium text-gray-600">
                                    Total: ${{
                                        items.reduce(
                                            (acc, item) =>
                                                acc +
                                                item.price * item.quantity,
                                            0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div
                    class="flex justify-end gap-3 p-6 border-t border-gray-200"
                >
                    <AtomicButton
                        color="yellow"
                        size="md"
                        @click="createPaymentRequest()"
                    >
                        Make payment request
                    </AtomicButton>

                    <AtomicButton
                        color="white"
                        size="md"
                        @click="emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { TrashIcon } from '@heroicons/vue/24/outline';
import IUser from '@/types/Members/IUser';
import ICurrentTeam from '@/types/Members/ICurrentTeam';

const emit = defineEmits(['close']);

const props = defineProps<{
    selectedUser: IUser;
    personalTeam: ICurrentTeam;
}>();

const type = ref('pro');
const name = ref('');
const dueDate = ref('');
const items = reactive([
    {
        name: '',
        price: 0,
        quantity: 1,
        pricing_list_item_id: 'TOP-DATA-21',
    },
]);
const pricingListItems = ref([]);

const createPaymentRequest = async function () {
    const data = {
        type: type.value,
        team_id: props.personalTeam.id,
        name: name.value,
        due_date: dueDate.value,
        items: [],
    };

    items.forEach((item) => {
        data.items.push({
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            pricing_list_item_id: item.pricing_list_item_id,
        });
    });
    
    await axios.post(route('kiosk.payment-requests.store'), data);

    emit('close');
};

const getPricingListItems = function () {
    axios.get(route('kiosk.pricing-list-items.index')).then((response) => {
        pricingListItems.value = response.data.data;
    });
};

onMounted(() => {
    getPricingListItems();
});
</script>
