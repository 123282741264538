<template>
    <SelectInput
        placeholder="Per page"
        :data="values"
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
    />
</template>

<script lang="ts" setup>
defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
});

const values = [
    {
        title: '20',
        value: 20,
    },
    {
        title: '50',
        value: 50,
    },
    {
        title: '100',
        value: 100,
    },
];

defineEmits(['update:modelValue']);
</script>
