<template>
    <Modal size="full" data-cy="new-template-modal" @close="$emit('close')">
        <template #header>
            <h2 class="text-lg">Create new template</h2>
        </template>

        <template #body>
            <div class="bg-gray-100 pb-10">
                <NewTemplateModalTab
                    v-if="globalProps.isAdmin"
                    class="mx-4 w-full"
                    @select="handleSelectTemplatesType"
                />

                <NewTemplateModalTemplates
                    :key="templateType"
                    :type="templateType"
                    :categories="categories"
                    :keywords="keywords"
                    @disable="$emit('disable')"
                    @open-template-preview="openPreview"
                    @create-template-from-scratch="
                        $emit('create-template-from-scratch')
                    "
                />
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import Modal from '@atomic/Modals/Modal.vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import IMarketplaceTemplate from '@/types/Templates/IMarketplaceTemplate';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);

const templateType = ref<'premade-templates' | 'marketplace-templates'>(
    'premade-templates'
);

const emit = defineEmits([
    'close',
    'disable',
    'open-template-preview',
    'create-template-from-scratch',
    'change-template-type',
]);

const handleSelectTemplatesType = async (
    typeValue: 'premade-templates' | 'marketplace-templates'
) => {
    templateType.value = typeValue;
};

const openPreview = (template: IPremadeTemplate | IMarketplaceTemplate) => {
    emit('open-template-preview', template, templateType.value);
};
</script>

<style></style>
