<template>
    <BillingTabs tab-key="pro">
        <div class="mb-[98px]">
            <div
                class="flex flex-col pb-5 mt-10 border-b border-gray-300 lg:flex-row lg:justify-between"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        v-if="globalProps.team.personal_team"
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        PRO subscription
                    </h3>
                    <h3
                        v-else
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Team subscription
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        You can add new members to your teams at any time. If
                        you previously chose the annual plan, additional team
                        members will be charged monthly at $15/mo until the
                        annual plan is up for renewal.
                    </p>
                </div>

                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
                    <div class="mx-6 mt-[18px] mb-[25px]">
                        <ProSubscriptionPlans
                            v-if="!hasLegacySubscription"
                            :subscription-plans="subscriptionPlans"
                            :selected-card="selectedPaymentMethod"
                            :countries="countries"
                            :pro-subscription="proSubscription"
                            :is-trialing-pro="isTrialingPro"
                            @subscription-order-modal-closed="
                                handleSubscriptionOrderModalClosed
                            "
                        />
                        <div v-else>
                            <SubscriptionProLegacyInfo />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col pb-5 mt-5 border-b border-gray-300 lg:flex-row lg:justify-between"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Payment method
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Your default payment method.
                    </p>
                </div>
                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
        
                    <div class="mx-6 mb-6">
                        <p class="mb-2.5 mt-4 text-base font-medium text-gray-600">
                            Paying with Card
                        </p>
                        <PaymentMethods
                            class="mt-4"
                            billing-address-type="pro"
                            @select-payment-method="handleSelectPaymentMethod"
                        />
                    </div>
         
                </div>
            </div>

            <form
                class="flex flex-col mt-5 lg:flex-row lg:justify-between"
                @submit.prevent="updateBillingAddress"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0 lg:mr-2 lg:w-1/3">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Billing information for
                        {{ globalProps.team.personal_team ? 'PRO' : 'Team' }}
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Please enter your full name and correct e-mail address.
                        Your name will be displayed when you invite someone else
                        to collaborate with you or your team.
                    </p>
                </div>
                <div
                    class="mx-auto w-full max-w-[852px] rounded-md bg-white shadow-card lg:mx-0 lg:ml-16"
                >
                    <div class="px-6 border-b border-gray-200">
                        <TextInput
                            v-model="v$.newBillingAddress.name.$model"
                            required
                            class="w-full mt-10 sm:mr-6 sm:w-80"
                            label="Name and Surname"
                            placeholder="Enter name and surname"
                            :error="getError('name')"
                        />

                        <TextInput
                            v-model="state.newBillingAddress.company"
                            secondarylabel="Optional"
                            class="w-full mt-6 sm:mr-6 sm:w-80"
                            label="Company"
                            placeholder="Enter company name"
                        />

                        <div class="flex flex-wrap">
                            <TextInput
                                v-model="state.newBillingAddress.tax_id"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="Tax ID"
                            />
                            <TextInput
                                v-model="state.newBillingAddress.crn"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="CRN"
                            />
                        </div>

                        <TextInput
                            v-model="state.newBillingAddress.street"
                            class="w-full mt-10 sm:mr-6 sm:w-80"
                            label="Address"
                        />

                        <div class="flex flex-wrap">
                            <TextInput
                                v-model="state.newBillingAddress.city"
                                class="w-full mt-5 sm:mr-6 sm:w-80"
                                label="City"
                            />
                            <TextInput
                                v-model="state.newBillingAddress.postal_code"
                                class="w-full mt-5 sm:mr-6 sm:w-max"
                                label="ZIP / Postal code"
                            />
                        </div>

                        <SelectInput
                            v-model="state.newBillingAddress.country"
                            class="w-full mt-5 sm:mr-6 sm:w-80"
                            placeholder="Select country"
                            :data="countries"
                            label="Country"
                            search
                        >
                        </SelectInput>

                        <TextInput
                            v-model="v$.newBillingAddress.email.$model"
                            optional="Used for sending invoices"
                            class="w-full mt-10 mb-6 sm:mr-6 sm:w-80"
                            label="E-mail address"
                            :error="getError('email')"
                            required
                        />
                    </div>

                    <div class="flex justify-end">
                        <div class="pr-6 my-3">
                            <AtomicButton
                                type="submit"
                                color="yellow"
                                size="sm"
                                :disabled="loading || v$.$invalid"
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </BillingTabs>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';

import { required, email, maxLength } from '@vuelidate/validators';
import { create } from '@composables/useNotifications';

import { reactive, PropType, ref } from 'vue';

import route from 'ziggy-js';
import SubscriptionPlan from '@/types/Subscription/SubscriptionPlan';
import BillingAddress from '@/types/Subscription/BillingAddress';
import ISelectable from '@/types/Atomic/ISelectable';

import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { computed } from 'vue';
import ISubscription from '@/types/Subscription/ISubscription';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';

const props = defineProps({
    subscriptionPlans: {
        type: Array as PropType<SubscriptionPlan[]>,
        required: true,
    },
    billingAddress: {
        type: Object as PropType<BillingAddress>,
        required: true,
    },
    countries: {
        type: Array as PropType<ISelectable[]>,
        required: true,
    },
    proSubscription: {
        type: Object as PropType<ISubscription>,
        default: () => ({}),
    },
    isTrialingPro: {
        type: Boolean,
        required: true,
    },
    hasLegacySubscription: {
        type: Boolean,
        required: true,
    },
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const loading = ref(false);
const errors = ref<IInputErrors>({});
const selectedPaymentMethod = ref<IPaymentMethod>();
const paymentMethodsComponentKey = ref(0);

const state = reactive({
    newBillingAddress: {
        name: props.billingAddress.name,
        email: props.billingAddress.email,
        company: props.billingAddress.company,
        tax_id: props.billingAddress.tax_id,
        crn: props.billingAddress.crn,
        country: props.billingAddress.country,
        street: props.billingAddress.street,
        city: props.billingAddress.city,
        postal_code: props.billingAddress.postal_code,
    },
});

const rules = {
    newBillingAddress: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
        },
    },
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return (
        v$.value.newBillingAddress[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const handleSubscriptionOrderModalClosed = () => {
    paymentMethodsComponentKey.value++;
};

const updateBillingAddress = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('billing-addresses.update-pro'),
            state.newBillingAddress,
            {
                preserveScroll: true,
                onSuccess: () => {
                    errors.value = {};
                    create({
                        title: 'Billing information updated',
                        text: 'Your PRO billing information has been successfully updated.',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Billing information not updated',
                        text: 'There was an error updating your PRO billing information.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    selectedPaymentMethod.value = paymentMethod;
};
</script>

<style></style>