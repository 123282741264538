<template>
    <div class="">
        <div
            class="mt-[45px] flex flex-col items-start gap-4 sm:flex-row sm:items-start sm:justify-between"
        >
            <div class="flex flex-wrap items-center justify-start gap-3 grow">
                <Breadcrumb
                    with-template
                    :path-items="currentPath"
                    @path-clicked="setDir"
                ></Breadcrumb>
                <div
                    v-if="!loading"
                    class="relative flex pr-8 cursor-pointer group"
                    @click="handleRenameTemplateClick"
                >
                    <h1
                        v-tooltip="{
                            content:
                                localTemplateRef.name?.length > 50
                                    ? localTemplateRef.name
                                    : '',
                            delay: {
                                show: 850,
                                hide: 150,
                            },
                        }"
                        class="text-2xl font-medium text-gray-800"
                        style="word-break: break-word"
                    >
                        {{
                            localTemplateRef.name
                                ? localTemplateRef.name
                                : 'Untitled'
                        }}
                    </h1>

                    <div class="absolute hidden pr-3 ml-2 -right-2 top-1 group-hover:block">
                        <PencilIcon class="w-5 h-5" />
                    </div>
                </div>
            </div>

            <div
                class="flex flex-wrap items-center justify-between flex-shrink-0 gap-4 sm:flex-nowrap sm:gap-0"
            >
                <div>
                    <EditTemplateActions
                        class="sm:mr-4"
                        :template="localTemplateRef"
                        :integrations="integrations"
                        @change-status-to="
                            (status) => changeTemplateStatusTo(status)
                        "
                        @rename-template="changeTemplateName"
                    />
                </div>

                <div class="relative">

                    <AtomicButton
                        :disabled="isViewer"
                        color="yellow"
                        size="lg"
                        class="flex-shrink-0"
                        @click="goToEditor()"
                        @mouseover="disabledEditButtonTooltipOpened = true"
                        @mouseleave="disabledEditButtonTooltipOpened= false"
                    >
                        <template #icon>
                            <div class="mr-2">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.16683 4.16664H5.00016C4.55814 4.16664 4.13421 4.34223 3.82165 4.65479C3.50909 4.96736 3.3335 5.39128 3.3335 5.83331V15C3.3335 15.442 3.50909 15.8659 3.82165 16.1785C4.13421 16.491 4.55814 16.6666 5.00016 16.6666H14.1668C14.6089 16.6666 15.0328 16.491 15.3453 16.1785C15.6579 15.8659 15.8335 15.442 15.8335 15V10.8333M14.6552 2.98831C14.8089 2.82912 14.9928 2.70215 15.1962 2.6148C15.3995 2.52746 15.6182 2.48148 15.8395 2.47956C16.0608 2.47763 16.2803 2.5198 16.4851 2.6036C16.6899 2.6874 16.876 2.81116 17.0325 2.96765C17.189 3.12414 17.3127 3.31022 17.3965 3.51505C17.4803 3.71988 17.5225 3.93934 17.5206 4.16064C17.5187 4.38194 17.4727 4.60064 17.3853 4.80398C17.298 5.00732 17.171 5.19123 17.0118 5.34497L9.85683 12.5H7.50016V10.1433L14.6552 2.98831Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </template>
                        Edit template
    
                    </AtomicButton>

                    <transition
                        enter-active-class="transition duration-100 ease-out transform"
                        enter-from-class="scale-95 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in transform"
                        leave-from-class="scale-100 opacity-100"
                        leave-to-class="scale-95 opacity-0"
                    >
                        <Tooltip
                            v-if="isViewer && disabledEditButtonTooltipOpened"
                            class="absolute left-2 top-14"
                            placement="bottom"
                        >
                            You don't have permission to edit this template.
                        </Tooltip>
                    </transition>
                </div>
            </div>
        </div>

        <div
            class="flex flex-col min-h-[calc(100vh-200px)] mt-4 bg-white rounded mb-9 lg:mb-0 shadow-card lg:w-full lg:flex-row "
        >
            <div
                class="border-gray-300 sm:w-[550px] md:w-[590px] md:shrink-0 lg:border-r xl:w-[680px] 2xl:w-[720px] mx-auto"
            >
                <div class="border-gray-300 lg:border-b">
                    <div
                        class="my-3 mx-auto hidden h-8 w-[267px] items-center justify-between rounded-md bg-gray-100 text-xs font-medium text-gray-500 sm:flex"
                    >
                        <button
                            class="flex items-center justify-center w-full h-full px-2 transition duration-200 ease-in-out rounded-l-md focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'mobile',
                            }"
                            @click="resolution = 'mobile'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'mobile',
                                    'text-gray-400': resolution !== 'mobile',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 18H12.01M8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V5C18 4.46957 17.7893 3.96086 17.4142 3.58579C17.0391 3.21071 16.5304 3 16 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Mobile</p>
                        </button>

                        <button
                            class="flex items-center justify-center w-full h-full px-2 transition duration-200 ease-in-out rounded-r-md focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'desktop',
                            }"
                            @click="resolution = 'desktop'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'desktop',
                                    'text-gray-400': resolution !== 'desktop',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 13H21M9.75 17L9 20L8 21H16L15 20L14.25 17H9.75ZM5 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Desktop</p>
                        </button>
                    </div>
                </div>

                <iframe
                    :srcdoc="templateHtml"
                    class="h-[800px] lg:h-[calc(100%-60px)] transition-all duration-200 ease-out bg-white rounded-t sm:rounded-t-none"
                    :class="{
                        'mx-auto w-full sm:w-[550px] md:w-[490px] xl:w-[670px] 2xl:w-[700px]':
                            resolution === 'desktop',
                        'mx-auto border-l border-r border-gray-300 sm:w-[375px]':
                            resolution === 'mobile',
                    }"
                ></iframe>
            </div>

            <div class="border-t border-gray-300 lg:border-t-0">
                <div class="mt-[38px] px-10 lg:px-4 2xl:ml-10 2xl:pr-[73px]">
                    <h3 class="text-base font-medium text-gray-600">
                        Download template
                    </h3>
                    <div>
                        <AtomicButton
                            color="purple"
                            size="md"
                            class="mt-3.5 mr-4"
                            :disabled="loadingHtmlAndImages"
                            @click="downloadHtmlAndImages"
                            >HTML and images</AtomicButton
                        >
                        <AtomicButton
                            color="purple"
                            size="md"
                            class="mt-3.5 mr-4"
                            @click="downloadHtmlWithHostedImages"
                            >HTML with hosted images</AtomicButton
                        >
                    </div>
                    <p
                        class="max-w-md mt-5 text-sm leading-normal text-gray-500"
                    >
                        Keep in mind that for option with hosted images
                        additional charges for traffic will apply. Check
                        <a
                            href="https://support.topol.io/en/"
                            target="_blank"
                            class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            >PRO Documentation</a
                        >
                        for more about hosted images or
                        <a
                            href="https://topol.io/pricing"
                            target="_blank"
                            class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            >see pricing options</a
                        >.
                    </p>

                    <AtomicButton
                        color="yellow"
                        size="md"
                        class="mt-3.5 mr-4"
                        @click="copyHtmlWithHostedImagesToClipboard"
                        >Copy HTML code</AtomicButton
                    >

                    <h3
                        class="mt-[39px] mb-3.5 text-base font-medium text-gray-600"
                    >
                        Export template
                    </h3>
                    <div class="flex flex-wrap">
                        <button
                            v-for="integration in integrations"
                            :key="integration.id"
                            type="button"
                            class="mt-2 inline-flex w-full items-center justify-between rounded-md border border-gray-300 bg-white py-2.5 pl-2.5 pr-4 text-sm font-medium text-gray-600 shadow-sm transition duration-100 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
                            :disabled="
                                integrationsLoading.includes(integration.id)
                            "
                            @click="exportTemplate(integration)"
                        >
                            <div class="flex items-center">
                                <img
                                    :src="
                                        getImagePathForIntegration(integration)
                                    "
                                    class="w-6 h-6"
                                    alt=""
                                />

                                <span class="mx-2 text-left"
                                    >{{
                                        getLowerCaseTypeAndCapitalizeFirst(
                                            integration
                                        )
                                    }}
                                    {{
                                        integration.name
                                            ? "'" + integration.name + "'"
                                            : ''
                                    }}</span
                                >
                            </div>

                            <div
                                v-if="
                                    !integrationsLoading.includes(
                                        integration.id
                                    )
                                "
                            >
                                <svg
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L4.46447 4.96447L1 8.42893"
                                        stroke="#9FA6B2"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>

                            <div v-else>
                                <LoadingSpinner delay size="small" />
                            </div>
                        </button>
                        <a
                            class="mt-2 inline-flex h-10 w-full items-center justify-between rounded-md border border-gray-300 bg-white pl-2.5 pr-4 text-sm font-medium leading-10 text-gray-600 shadow-sm transition duration-100 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
                            target="_blank"
                            :href="
                                'https://ecomail.app/free?appTemplateId=' +
                                templateData.id
                            "
                        >
                            <div class="flex items-center">
                                <img
                                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/ecomail.png"
                                    alt=""
                                    class="w-6"
                                />
                                <span class="ml-2">Ecomail</span>
                            </div>

                            <div>
                                <svg
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1.5L4.46447 4.96447L1 8.42893"
                                        stroke="#9FA6B2"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </a>

                        <p class="mt-2 text-sm leading-normal text-gray-500">
                            For additional export options setup more
                            integrations
                            <InertiaLink
                                href="/integrations"
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >in&nbsp;Integrations&nbsp;section.
                            </InertiaLink>
                        </p>
                    </div>

                    <h3 class="mt-[39px] text-base font-medium text-gray-600">
                        Template testing
                    </h3>
                    <div class="relative">
                        <AtomicButton
                            :disabled="!isSubscribed && !globalProps.isAdmin"
                            color="white"
                            size="sm"
                            class="relative mt-3.5 mr-4"
                            @click="openExpiringLinkModal"
                        >
                            <span v-if="!copiedFiveMinutes"
                                >Generate share link</span
                            >
                        </AtomicButton>

                        <AtomicButton
                            :disabled="!isSubscribed && !globalProps.isAdmin"
                            color="white"
                            size="sm"
                            class="mt-3.5"
                            @click="showSendTestEmailModal = true"
                        >
                            <span>Send testing email</span>
                        </AtomicButton>

                        <div v-if="!isSubscribed && !globalProps.isAdmin">
                            <p class="mt-2 text-sm leading-normal text-gray-500">
                            You need to be subscribed to use these features. Check our subscription plans
                            <InertiaLink
                                href="/billing/pro-subscriptions"
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                >here.
                            </InertiaLink>
                        </p>
                            </div>
                    </div>

                    <div class="mt-11 max-w-[260px]">
                        <SelectInput
                            :model-value="localTemplateRef.status"
                            placeholder="Set template status"
                            label="Template Status"
                            :data="statusDropdownData"
                            @update:model-value="
                                (status) => changeTemplateStatusTo(status)
                            "
                        />
                    </div>

                    <div v-if="webhook">
                        <h3 class="mt-10 text-base font-medium text-gray-600">
                            Webhook manual trigger
                        </h3>
                        <div class="relative">
                            <AtomicButton
                                :disabled="webhookLoading"
                                color="white"
                                size="sm"
                                class="relative mt-4"
                                @click="handleCallWebhook"
                            >
                                Send template data
                            </AtomicButton>
                        </div>
                    </div>


                    <div class="mt-[44px] flex pb-[44px] text-xs xl:mb-0">
                        <div class="flex flex-col text-gray-500">
                            <p>Last modified</p>
                            <p class="mt-2">Created</p>
                        </div>

                        <div class="flex flex-col ml-3 text-gray-800">
                            <p v-if="template.updatedAt">
                                {{
                                    format(
                                        new Date(template.updatedAt),
                                        "EEEE, d. L. yyyy 'at' HH:mm"
                                    )
                                }}
                            </p>
                            <p v-if="template.createdAt" class="mt-2">
                                {{
                                    format(
                                        new Date(template.createdAt),
                                        "EEEE, d. L. yyyy 'at' HH:mm"
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SendTestEmailModal
            v-if="showSendTestEmailModal"
            :testing-emails="testingEmails"
            @close="showSendTestEmailModal = false"
            @submit="sendTestEmail"
        />

        <ExpiringLinkModal
            v-if="expiringLinkModal"
            :template-data-id="templateData.id"
            @close="expiringLinkModal = false"
        />

        <ExportToSendy
            v-if="sendyFormOpened"
            :integration="sendyFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeSendyForm"
        />

        <ExportToAWeber
            v-if="aweberFormOpened"
            :integration="aweberFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeAweberForm"
        />

        <ExportToZohoCampaigns
            v-if="zohoCampaignsFormOpened"
            :integration="zohoCampaignsFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeZohoCampaignsForm"
        />

        <ExportToZohoMail
            v-if="zohoMailFormOpened"
            :integration="zohoMailFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeZohoMailForm"
        />

        <ExportToZohoMarketing
            v-if="zohoMarketingFormOpened"
            :integration="zohoMarketingFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeZohoMarketingForm"
        />

        <ExportToSendPulse
            v-if="sendPulseFormOpened"
            :integration="sendPulseFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeSendPulseForm"
        />

        <ExportToIContact
            v-if="icontactFormOpened"
            :integration="icontactFormIntegration"
            :template-name="template.name"
            :template-id="template.id"
            @close="closeIContactForm"
        />

        <ExportToClipboardModal
            v-if="exportToClipboardModalShow"
            :html="htmlToClipboard"
            @close="closeExportToClipboardModal"
        />
    </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';

import { IIntegration } from '@/types/Integrations/IIntegration';

import { PencilIcon } from '@heroicons/vue/24/outline';
import route from 'ziggy-js';
import axios from 'axios';
import { format } from 'date-fns';
import ISelectable from '@/types/Atomic/ISelectable';
import ISimpleTemplate from '@/types/Templates/ISimpleTemplate';
import useConfirm from '@/composables/useConfirm';
import useTemplateExports from './composables/useTemplateExports';

import { saveAs } from 'file-saver';
import { create } from '@composables/useNotifications';
import { Link as InertiaLink } from '@inertiajs/vue3';
import { useReCaptcha } from 'vue-recaptcha-v3';
import IFolder from '@/types/Templates/IFolder';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import IWebhook from '@/types/Webhooks/IWebhook';

const props = defineProps({
    templateData: {
        type: Object as PropType<ISimpleTemplate>,
        required: true,
    },

    templateHtml: {
        type: String,
        required: true,
    },

    integrations: {
        type: Array as PropType<IIntegration<unknown>[]>,
        required: true,
    },
    webhook: {
        type: Object as PropType<IWebhook>,
        default: null,
    },
    testingEmails: {
        type: Array as PropType<string[]>,
        required: true,
    },
});

const reCaptcha = useReCaptcha();
const resolution = ref<'desktop' | 'mobile'>('desktop');
const localTemplateRef = ref(props.templateData);

const { show } = useConfirm();
const expiringLinkModal = ref(false);
const exportToClipboardModalShow = ref(false);
const htmlToClipboard = ref('');
const loading = ref(false);
const webhookLoading = ref(false);
const loadingHtmlAndImages = ref(false);
const showSendTestEmailModal = ref(false);

const { currentPath } = useFoldersAndTemplates();
const setDir = async (folder?: IFolder) => {
    const params = {
        ...(folder ? { template_folder_id: folder.id } : {})
    };

    router.visit(route('templates.index', params), { preserveState: false });
};

const { isViewer } = usePermissions();

const disabledEditButtonTooltipOpened = ref(false);

const template = ref({
    id: props.templateData.id,
    name: props.templateData.name,
    createdAt: props.templateData.created_at,
    updatedAt: props.templateData.updated_at,
});

const copiedFiveMinutes = ref(false);

const { isSubscribed } = useBanners();
const globalProps = computed(() => usePage<ITopolPageProps>().props);

const {
    exportTemplate,
    integrationsLoading,

    sendyFormOpened,
    aweberFormOpened,
    icontactFormOpened,
    zohoCampaignsFormOpened,
    zohoMarketingFormOpened,
    zohoMailFormOpened,
    sendPulseFormOpened,
    sendyFormIntegration,
    aweberFormIntegration,
    zohoCampaignsFormIntegration,
    zohoMarketingFormIntegration,
    zohoMailFormIntegration,
    sendPulseFormIntegration,
    icontactFormIntegration,

    closeSendyForm,
    closeAweberForm,
    closeZohoCampaignsForm,
    closeZohoMarketingForm,
    closeZohoMailForm,
    closeSendPulseForm,
    closeIContactForm,
} = useTemplateExports(props.templateData.id, props.templateData.name);

const statusDropdownData: ISelectable[] = [
    {
        title: 'Draft',
        value: 'draft',
    },
    {
        title: 'In Review',
        value: 'in_review',
    },
    {
        title: 'Sent',
        value: 'sent',
    },
];

const changeTemplateStatusTo = async (statusId: string | number) => {
    let found = statusDropdownData.find((status) => statusId === status.value);

    if (found === undefined) {
        return;
    }

    let newStatusId: string | null = statusId.toString();

    if (statusId === localTemplateRef.value.status) {
        newStatusId = null;
    }

    try {
        await axios.put(
            route('templates.update', {
                template: props.templateData.id,
            }),
            {
                name: localTemplateRef.value.name || 'Untitled',
                status: newStatusId,
            }
        );
        create({
            title: 'Template status updated',
            type: 'info',
        });
    } catch (err) {
        () => undefined;
    }

    if (newStatusId === null) {
        localTemplateRef.value.status = undefined;
    } else {
        if (found.value !== undefined) {
            localTemplateRef.value.status = found.value.toString();
        }
    }
};

const handleRenameTemplateClick = async () => {
    const newNameResponse = await show({
        header: 'Rename',
        hasInput: true,
        inputData: {
            label: 'Template Name',
            defaultValue: localTemplateRef.value.name,
        },
        confirm: 'Rename',
    });

    if (typeof newNameResponse === 'string') {
        changeTemplateName(newNameResponse);
    }
};

const changeTemplateName = async (newName: string) => {
    try {
        await axios.put(
            route('templates.update', {
                template: localTemplateRef.value.id,
            }),
            {
                name: newName,
                status: localTemplateRef.value.status,
            }
        );
        localTemplateRef.value.name = newName;
        create({
            title: 'Template name updated',
            type: 'info',
        });
    } catch (err) {
        () => undefined;
    }
};

const sendTestEmail = async (emails: string[]) => {
        showSendTestEmailModal.value = false;
        
        await reCaptcha?.recaptchaLoaded();

        const token = await reCaptcha?.executeRecaptcha('sendTestEmail');

        if (token === undefined) {
            return;
        }

        try {
            await axios.post(
                route('templates.send-test-email', {
                    template: props.templateData.id,
                }),
                {
                    emails: emails,
                    recaptcha_token: token,
                }
            );
            create({
                title: 'Test email(s) sent',
                type: 'success',
            });
        } catch (err) {
            () => undefined;
        }
};

const openExpiringLinkModal = async () => {
    expiringLinkModal.value = true;
};

const downloadHtmlAndImages = async () => {
    try {
        loadingHtmlAndImages.value = true;
        const res = await axios.post(
            route('templates.downloads.self-hosted-html', {
                template: props.templateData.id,
            })
        );

        let name = localTemplateRef.value.name;

        if (name == null) {
            name = 'untitled_template';
        }
        if (res.data.success) {
            saveAs(res.data.data, name + '.zip');
        } else {
            create({
                title: 'There was an error downloading the template',
                type: 'error',
            });
        }
    } catch (err) {
        create({
            title: 'There was an error downloading the template',
            type: 'error',
        });
    } finally {
        loadingHtmlAndImages.value = false;
    }
};

const downloadHtmlWithHostedImages = async () => {
    try {
        const res = await axios.post(
            route('templates.downloads.hosted-html', {
                template: props.templateData.id,
            })
        );

        if (res.data) {
            const fileToSave = new Blob([res.data], {
                type: 'text/html;charset=utf-8',
            });

            saveAs(
                fileToSave,
                localTemplateRef.value.name || 'untitled_template'
            );
        } else {
            create({
                title: 'There was an error with the template content. Try opening the template in the editor, save it and then try again.',
                type: 'error',
            });
        }
    } catch (err) {
        () => undefined;
    }
};

const copyHtmlWithHostedImagesToClipboard = async () => {
    const res = await axios.post(
        route('templates.downloads.hosted-html', {
            template: props.templateData.id,
        })
    );

    if (res.data) {
        exportToClipboardModalShow.value = true;
        htmlToClipboard.value = res.data;
    } else {
        create({
            title: 'There was an error with the template content. Try opening the template in the editor, save it and then try again.',
            type: 'error',
        });
    }
};

const closeExportToClipboardModal = () => {
    exportToClipboardModalShow.value = false;
    htmlToClipboard.value = '';
};

const goToEditor = () => {
    router.visit(route('templates.editor', { template: template.value.id }));
};

const getImagePathForIntegration = (integration: IIntegration<unknown>) => {
    if (integration.type === 'SENDY') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/sendy_square.png';
    }

    if (integration.type === 'ACTIVE_CAMPAIGN') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/active_campaign_square.png';
    }

    if (integration.type === 'KLAVIYO') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/klaviyo_square.png';
    }

    if (integration.type === 'MAILJET') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/mailjet_square.png';
    }

    if (integration.type === 'GMAIL') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/gmail_square.png';
    }

    if (integration.type === 'CLICKSEND') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/clicksend_square.png';
    }

    if (integration.type === 'BENCHMARK_EMAIL') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/benchmark_email_square2.png';
    }

    if (integration.type === 'MAILUP') {
        return 'https://d5aoblv5p04cg.cloudfront.net/application/images/mailup_square.png';
    }

    return (
        'https://d5aoblv5p04cg.cloudfront.net/application/images/' +
        integration.type.toLowerCase() +
        '.png'
    );
};

const getLowerCaseTypeAndCapitalizeFirst = (
    integration: IIntegration<unknown>
) => {
    return integration.type
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

const handleCallWebhook = () => {
    webhookLoading.value = true;
    router.post(route('templates.call-template-saved-webhook', {
        template: template.value.id,
    }), {

    }, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Webhook called',
                text: 'Your webhook has been called successfully.',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Error',
                text: 'An error occurred while calling the webhook.',
                type: 'error',
            });
        },
        onFinish: () => {
            webhookLoading.value = false;
        },
    })
}
</script>
