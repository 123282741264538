<template>
    <div class="my-10">
        <div class="flex flex-col justify-between pb-6 sm:flex-row">
            <div>
                <h1 class="text-2xl font-medium text-gray-800">
                    Usage Statistics
                </h1>
                <p class="mt-2 max-w-lg text-sm text-gray-600">
                    Track your data usage statistics here. Topol only counts
                    image files towards your data usage, and up to 50GB of data
                    can be stored for free. For more information on pricing,
                    please visit our
                    <a
                        href="https://support.topol.io/en/articles/8333237-calculating-your-data-traffic"
                        target="_blank"
                        class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                        >support page</a
                    >.
                </p>
            </div>

            <SelectInput
                v-model="selected"
                class="mt-5 w-full max-w-[320px] sm:mt-0 sm:w-80 sm:max-w-none"
                placeholder="Select subscription / token"
                :data="selectData"
                label=""
                :readonly="false"
                @update:model-value="fetchData"
            >
            </SelectInput>
        </div>

        <div
            class="w-full overflow-x-auto bg-white py-[18px] sm:overflow-hidden"
        >
            <div
                class="flex flex-col items-center justify-between gap-2 px-6 sm:flex-row"
            >
                <div
                    class="flex flex-col flex-wrap gap-2 sm:flex-row sm:items-center"
                >
                    <div class="mr-6">
                        <p class="font-medium text-gray-600">
                            Current monthly usage
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                            {{ format(startOfMonth(new Date()), 'd. M. Y') }} -
                            {{ format(new Date(), 'd. M. Y') }}
                        </p>
                    </div>

                    <div class="flex items-center gap-2">
                        <button
                            v-if="usage !== undefined"
                            class="mt-5 h-[36px] max-w-[200px] rounded-md border-2 border-purple-200 px-2 text-lg font-medium text-purple-700 sm:mt-0 sm:max-w-none"
                        >
                            {{ formatBytes(usage.totalBytes) }}
                        </button>

                        <button
                            v-if="usage?.price"
                            class="mt-5 h-[36px] max-w-[200px] rounded-md border-2 border-blue-200 px-2 text-lg font-medium text-blue-700 sm:mt-0 sm:max-w-none"
                        >
                            $ {{ usage.price }}
                        </button>
                    </div>
                </div>

                <div class="mt-5 flex gap-4 sm:mt-0">
                    <CheckboxInput
                        :checked="selectedDataSet.includes('thisMonth')"
                        name="This month"
                        label="This month"
                        color="purple"
                        :class="{
                            'opacity-75':
                                !selectedDataSet.includes('thisMonth'),
                        }"
                        @update:checked="handleSelectDataSet('thisMonth')"
                    >
                        This month
                    </CheckboxInput>

                    <CheckboxInput
                        :checked="selectedDataSet.includes('lastMonth')"
                        name="Last month"
                        label="Last month"
                        color="blue"
                        :class="{
                            'opacity-75':
                                !selectedDataSet.includes('lastMonth'),
                        }"
                        @update:checked="handleSelectDataSet('lastMonth')"
                    >
                        Last month
                    </CheckboxInput>
                </div>
            </div>
            <div
                v-if="!loading && usage !== undefined"
                class="max-w-full p-6 sm:overflow-x-auto"
            >
                <Chart
                    :selected-data-set="selectedDataSet"
                    :usage="usage"
                ></Chart>
            </div>

            <div v-else class="p-6">
                <div
                    class="flex h-64 max-w-[1127px] items-center justify-center"
                >
                    <LoadingSpinner size="large"></LoadingSpinner>
                </div>
            </div>

            <div class="mt-9 hidden border-b border-gray-200 sm:block"></div>

            <div class="mt-10 px-6">
                <HistoryTable :logs="logs" :loading="loading"></HistoryTable>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Chart from './components/Chart.vue';
import { ref, onMounted } from 'vue';
import ITransferredBytes from '@/types/UsageStatistics/ITransferredBytes';
import { format, startOfMonth } from 'date-fns';
import { formatBytes } from '@utils/formatBytes';
import route from 'ziggy-js';
import IToken from '@/types/Plugin/IToken';
import SelectInput from '@atomic/Inputs/SelectInput.vue';
import ISelectable from '@/types/Atomic/ISelectable';
import axios from 'axios';
import IApiResponse from '@/types/IApiResponse';
import LoadingSpinner from '@/components/Atomic/LoadingSpinner/LoadingSpinner.vue';
import HistoryTable from './components/HistoryTable.vue';
import IHistoryLog from '@/types/UsageStatistics/IHistoryLog';

const usage = ref<ITransferredBytes>();
const logs = ref<IHistoryLog[]>();
const loading = ref(false);

const props = defineProps<{
    token?: string;
}>();

const selectData: ISelectable[] = [
    {
        title: 'PRO subscription',
        value: 'pro',
    },
];

const selectedDataSet = ref(['thisMonth', 'lastMonth']);

const handleSelectDataSet = (dataset: string) => {
    if (selectedDataSet.value.includes(dataset)) {
        selectedDataSet.value = selectedDataSet.value.filter(
            (item) => item !== dataset
        );
    } else {
        if (!selectedDataSet.value.length) {
            selectedDataSet.value = [dataset];
        } else {
            selectedDataSet.value = ['thisMonth', 'lastMonth'];
        }
    }
};

const getStatisticsData = async (apiTokenId = '') => {
    const res = await axios.get<
        IApiResponse<{
            usage: ITransferredBytes;
            tokens?: IToken[];
            history_logs?: IHistoryLog[];
        }>
    >(route('gcs-transferred-bytes.index', { apiToken: apiTokenId }));

    return res.data.data;
};

onMounted(async () => {
    loading.value = true;
    const data = await getStatisticsData(props.token);
    usage.value = data.usage;
    logs.value = data.history_logs;
    data.tokens?.forEach((a) =>
        selectData.push({
            title: a.name + ' (API Token)',
            value: a.id,
        })
    );
    if (props.token) {
        selected.value = props.token;
    }
    loading.value = false;
});

const selected = ref('pro');

const fetchData = async (value: string) => {
    loading.value = true;
    if (value !== 'pro') {
        const data = await getStatisticsData(value);
        usage.value = data.usage;
        logs.value = data.history_logs;
        loading.value = false;
    } else {
        const data = await getStatisticsData();
        usage.value = data.usage;
        logs.value = data.history_logs;
        loading.value = false;
    }
};
</script>
