<template>
    <div class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Window bar
            </h3>

            <p class="max-w-xs text-sm text-gray-500">
                You can add additional window bar which brings an option to use
                fullscreen button and close button.
            </p>
        </div>
        <div
            class="mx-auto flex h-full w-full items-center rounded-md bg-white shadow-card lg:mx-0"
        >
            <div class="flex flex-wrap justify-start gap-6 px-6 pb-5 pt-[23px]">
                <AtomicButton
                    v-for="option in windowBarOptions"
                    :key="option"
                    size="sm"
                    :color="modelValue.includes(option) ? 'yellow' : 'white'"
                    @click="updateOptions(option)"
                >
                    {{ option }}
                </AtomicButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

const props = defineProps({
    modelValue: {
        type: Array as PropType<string[]>,
        required: true,
    },
});
const emit = defineEmits(['update:modelValue']);

const windowBarOptions = ['fullscreen', 'close'];

const updateOptions = (option: string) => {
    let updatedValue = [...props.modelValue];
    if (!updatedValue.includes(option)) {
        updatedValue.push(option);
    } else {
        updatedValue = updatedValue.filter((a) => a !== option);
    }
    emit('update:modelValue', updatedValue);
};
</script>
