import { IIntegration } from '@/types/Integrations/IIntegration';

const sendgridIntegrationModalOpened = ref(false);
const sendyIntegrationModalOpened = ref(false);
const brevoIntegrationModalOpened = ref(false);
const constantcontactIntegrationModalOpened = ref(false);
const mailchimpIntegrationModalOpened = ref(false);
const klaviyoIntegrationModalOpened = ref(false);
const mailjetIntegrationModalOpened = ref(false);
const gmailIntegrationModalOpened = ref(false);
const hubspotIntegrationModalOpened = ref(false);
const campaignmonitorIntegrationModalOpened = ref(false);
const activecampaignIntegrationModalOpened = ref(false);
const moosendIntegrationModalOpened = ref(false);
const clicksendIntegrationModalOpened = ref(false);
const benchmarkemailIntegrationModalOpened = ref(false);
const mailgunIntegrationModalOpened = ref(false);
const aweberIntegrationModalOpened = ref(false);
const mailupIntegrationModalOpened = ref(false);
const mailerliteIntegrationModalOpened = ref(false);
const acumbamailIntegrationModalOpened = ref(false);
const getresponseIntegrationModalOpened = ref(false);
const zohocampaignsIntegrationModalOpened = ref(false);
const zohomailIntegrationModalOpened = ref(false);
const zohomarketingIntegrationModalOpened = ref(false);
const sendpulseIntegrationModalOpened = ref(false);
const outlookwebIntegrationModalOpened = ref(false);
const closeIntegrationModalOpened = ref(false);
const icontactIntegrationModalOpened = ref(false);
const cleverReachIntegrationModalOpened = ref(false);

const selectedIntegration = ref<IIntegration<T> | undefined>(undefined);

export default () => {
    return {
        sendgridIntegrationModalOpened,
        sendyIntegrationModalOpened,
        brevoIntegrationModalOpened,
        constantcontactIntegrationModalOpened,
        mailchimpIntegrationModalOpened,
        klaviyoIntegrationModalOpened,
        mailjetIntegrationModalOpened,
        gmailIntegrationModalOpened,
        hubspotIntegrationModalOpened,
        campaignmonitorIntegrationModalOpened,
        activecampaignIntegrationModalOpened,
        moosendIntegrationModalOpened,
        clicksendIntegrationModalOpened,
        benchmarkemailIntegrationModalOpened,
        mailgunIntegrationModalOpened,
        aweberIntegrationModalOpened,
        mailupIntegrationModalOpened,
        mailerliteIntegrationModalOpened,
        acumbamailIntegrationModalOpened,
        getresponseIntegrationModalOpened,
        zohocampaignsIntegrationModalOpened,
        zohomailIntegrationModalOpened,
        zohomarketingIntegrationModalOpened,
        sendpulseIntegrationModalOpened,
        outlookwebIntegrationModalOpened,
        closeIntegrationModalOpened,
        icontactIntegrationModalOpened,
        cleverReachIntegrationModalOpened,
        selectedIntegration,
    };
};
