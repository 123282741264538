<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Export to Zoho Marketing Automation</div>
        </template>

        <template #body>
            <div class="p-6">
                <form>
                    <MultipleSelect
                        v-model:value="state.lists"
                        placeholder="Choose lists"
                        :options="lists"
                        label="Lists"
                        required
                        :error="getError('lists')"
                        :disabled="loading"
                    />

                    <SelectInput
                        v-model="state.topic_id"
                        class="mt-6"
                        placeholder="Choose topic"
                        :data="topics"
                        label="Topic"
                        required
                        :error="getError('topic_id')"
                        :disabled="loading"
                        search
                        @blurred="v$.topic_id.$touch()"
                    />
                </form>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between p-6 border-t border-gray-200">
                <AtomicButton color="white" size="md" @click="$emit('close')">
                    Cancel
                </AtomicButton>

                <AtomicButton
                    size="md"
                    color="yellow"
                    :disabled="loading || v$.$invalid"
                    @click="exportToZohoMarketing"
                >
                    Export
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { PropType, reactive, ref } from 'vue';
import {
    IIntegration,
    IZohoMarketing,
} from '@/types/Integrations/IIntegration';
import axios from 'axios';
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ISelectable from '@/types/Atomic/ISelectable';

const emit = defineEmits(['close']);

const props = defineProps({
    integration: {
        required: true,
        type: Object as PropType<IIntegration<IZohoMarketing>>,
        default: null,
    },
    templateId: {
        required: true,
        type: Number,
    },
    templateName: {
        required: true,
        type: String,
    },
});

const state = reactive({
    topic_id: '',
    lists: [],
});

const rules = {
    topic_id: {
        required,
    },
    lists: {
        required,
    },
};

const v$ = useVuelidate(rules, state);

const loading = ref(false);
const topics = ref<ISelectable[]>([]);
const lists = ref<ISelectable[]>([]);

onMounted(() => {
    getLists();
    getTopics();
});

const getLists = async () => {
    try {
        loading.value = true;
        const res = await axios.get(
            route('integrations.zoho-marketing.lists.show', {
                integration: props.integration.id,
            })
        );

        if (res.data.success === true) {
            lists.value = res.data.data;
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const getTopics = async () => {
    try {
        loading.value = true;
        const res = await axios.get(
            route('integrations.zoho-marketing.topics.show', {
                integration: props.integration.id,
            })
        );

        if (res.data.success === true) {
            topics.value = res.data.data;
        }
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || '';
};

const exportToZohoMarketing = async () => {
    loading.value = true;
    const lists = state.lists.map((list: ISelectable) => list.value);

    try {
        const response = await axios.post(
            route('templates.exports.zoho-marketing', {
                template: props.templateId,
            }),
            {
                topic_id: state.topic_id,
                lists: lists,
                integration_id: props.integration.id,
            }
        );

        if (response.data.success) {
            create({
                title: 'Successful export',
                text: 'Template has been successfully exported to Zoho Marketing Automation.',
                type: 'success',
            });
            emit('close');

            return;
        }
        create({
            title: 'Unsuccessful export',
            text: 'Template has not been exported to Zoho Marketing Automation.',
            type: 'error',
        });
    } catch (e) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};
</script>
