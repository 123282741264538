<template>
    <Tabs :selected-team="selectedTeam" tab-key="team-blocked-actions">
        <div>
            <h3
                class="mt-12 text-xl font-medium leading-normal tracking-tighter text-gray-600"
            >
                Add blocked actions for team:
            </h3>
            <div class="mt-2 flex gap-2">
                <div
                    v-for="item in blockable"
                    :key="item"
                    class="inline-flex cursor-pointer rounded-md bg-red-200 px-2 py-[3px] hover:bg-red-300"
                    @click="handleAddBlockedAction(item)"
                >
                    {{ item }}
                </div>
            </div>
        </div>

        <DataTable
            class="mt-8"
            :title="`Blocked Actions of ${selectedTeam.name} `"
            :headers="headers"
            :items="blockedActions"
        >
            <template #item-admin="{ data }">
                <div class="flex p-6 text-gray-500">
                    <div>
                        {{ data.email }}
                    </div>
                </div>
            </template>

            <template #item-created_at="{ data }">
                <div class="flex justify-center p-6 text-gray-500">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <AtomicButton
                    color="red"
                    size="sm"
                    @click="handleDelete(row.id as number)"
                >
                    <template #icon>
                        <TrashIcon class="h-6 w-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </Tabs>
</template>
<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import IBlockedAction from '@/types/BlockedActions/IBlockedAction';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';

import Tabs from '../components/TeamTabs.vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import AtomicButton from '@/components/Atomic/Buttons/AtomicButton.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { format } from 'date-fns';
import useConfirm from '@/composables/useConfirm';

const props = defineProps<{
    selectedTeam: ICurrentTeam;
    blockedActions: IPaginationData<IBlockedAction>;
    blockable: string[];
}>();

const { show } = useConfirm();

const headers: IDataTableHeader[] = [
    {
        text: 'Action name',
        id: 'action_name',
    },
    {
        text: 'Created by',
        id: 'admin',
    },
    {
        text: 'Date',
        id: 'created_at',
        align: 'center',
    },
    {
        text: '',
        id: 'custom',
    },
];

const handleAddBlockedAction = async (item: string) => {
    const confirmed = await show({
        header: 'Add blocked action',
        message: `Are you sure you want add blocked action "${item}"?`,
        type: 'danger',
        confirm: 'Add',
    });
    if (confirmed) {
        router.post(
            route('kiosk.teams.blocked-actions.store', {
                team: props.selectedTeam.id,
            }),
            { blockedAction: item },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Blocked action has been added',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Blocked action has not been added',
                        text: 'There was an error adding the blocked action.',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const handleDelete = async (id: number) => {
    const confirmed = await show({
        header: 'Delete blocked action',
        message: `Are you sure you want to delete this blocked action?`,
        type: 'danger',
        confirm: 'Delete',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.teams.blocked-actions.destroy', { blockedAction: id }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Blocked action has been deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Blocked action has not been expired',
                        text: 'There was an error deleting the blocked action.',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
