<template>
    <div class="flex gap-4 mt-12">
        <div class="w-1/2 mt-[18px] bg-white">
            <div
                class="w-full h-full border border-gray-300 rounded-md"
            >
            <PaymentMethods
                class="p-6"
                :billing-address-type="paymentRequest.type"
                @select-payment-method="handleSelectPaymentMethod"
                @loading="paymentMethodsLoading = $event"
            />

                <div class="flex flex-col lg:flex-row lg:justify-between">
                    <div v-show="!paymentMethodsLoading" class="w-full text-left">
                        <div
                            class="p-6 border-t border-gray-300"
                        >
                            <CheckboxInput
                                v-model:checked="invoiceDetails"
                                size="lg"
                                name="invoiceDetails"
                                label="Add invoice details"
                            />
                        </div>


                        <InvoiceDetails 
                            v-if="invoiceDetails" 
                            :billing-address="billingAddress" 
                            :countries="countries"
                            @updated-billing="emit('updatedBilling')"
                            @loading-invoice-details="loadingInvoiceDetails = $event"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="w-1/2 mt-[18px]">
            <div class="w-full px-6 pt-6 pb-5 bg-blue-100 border border-blue-200 rounded-md">
                <h4 class="text-lg font-medium leading-normal tracking-tighter text-gray-800"> Summary </h4>
                <div
                    v-for="item in paymentRequest.items" :key="item.id"
                    class="mt-4 flex items-start justify-between pb-[14px]">
                    <div>
                        <p class="text-sm font-medium leading-normal text-gray-800">{{ item.name }}</p>
                    </div>
                    <div class="text-sm font-medium leading-normal text-gray-800">${{ item.total }}</div>
                </div>

                <div class="pt-5 border-t border-blue-200">
                    <div class="flex justify-between">
                        <div class="text-lg font-medium leading-normal tracking-tighter text-gray-800"> Total</div>
                        <div class="text-lg font-bold text-gray-800"> ${{ paymentRequest.total }}</div>
                    </div>

                    <div class="mt-6">
                        <button 
                        :disabled="
                            loading ||
                            loadingInvoiceDetails ||
                            !selectedCard.token ||
                            paymentMethodsLoading
                        "
                        
                        class="flex h-15 w-full items-center justify-center rounded-md bg-[#4299E1] text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-white"
                        @click="handleSubmit" 
                        > Continue to checkout </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import axios from "axios";
import route from "ziggy-js";
import {create} from "@composables/useNotifications";
import {router} from "@inertiajs/vue3";
import {ICreditCardPaymentMethod, IPaymentMethod} from "@/types/Subscription/IPaymentMethod";
import {PropType, ref} from "vue";
import IApiResponse from "@/types/IApiResponse";
import {client as BraintreeClient, Client, threeDSecure as BraintreeThreeDSecure} from "braintree-web";
import {
    ThreeDSecure,
    ThreeDSecureVerifyOptions,
} from 'braintree-web/three-d-secure';
import ISelectable from "@/types/Atomic/ISelectable";
import BillingAddress from "@/types/Subscription/BillingAddress";

interface IselectedCard {
    token: string;
    type: 'Card' | 'PayPal' | '';
}

const props = defineProps({
    paymentRequest: {
        type: Object,
        required: true,
    },
    billingAddress: {
        type: Object as PropType<BillingAddress>,
        required: true,
    },
    countries: {
        required: true,
        type: Array as PropType<ISelectable[]>,
    },
});

const emit = defineEmits(['success', 'error', 'updatedBilling']);

const selectedCard = ref({ token: '', type: '' } as IselectedCard);

const paymentMethodsLoading = ref(true);
const loading = ref(false);
const invoiceDetails = ref(false);
const loadingInvoiceDetails = ref(false);
const threeDSecureParameters = ref<ThreeDSecureVerifyOptions>();
const threeDSecureInstance = ref<ThreeDSecure>();
const gatewayInstance = ref<Client>();
const paymentGatewayToken = ref('');

const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    selectedCard.value = {
        token: paymentMethod.token,
        type: (paymentMethod as ICreditCardPaymentMethod).bin !== undefined ? 'Card' : 'PayPal',
    };
};

const verifyCard = async (nonce: string) => {
    try {
        await getThreeDSecureParameters(nonce);

        await initThreeDSecureClient();

        if (
            threeDSecureInstance.value === undefined ||
            threeDSecureParameters.value === undefined
        ) {
            throw new Error('Undefined 3d secure instance');
        }

        //For some unknown reason braintree only accepts zero as string...
        if (threeDSecureParameters.value.amount === 0) {
            //@ts-ignore
            threeDSecureParameters.value.amount = '0';
        }

        return threeDSecureInstance.value.verifyCard(
            threeDSecureParameters.value
        );
    } catch (err) {
        () => undefined;
    }
};

const getThreeDSecureParameters = async (nonce: string) => {
    const response = await axios.post<IApiResponse<ThreeDSecureVerifyOptions>>(
        route('braintree.three-d-secure-parameters'),
        {
            billing_address_type: props.paymentRequest.type as string,
            amount: props.paymentRequest.total * 100,
            card_token: selectedCard.value.token,
        }
    );

    threeDSecureParameters.value = response.data.data;
    threeDSecureParameters.value.amount =
        threeDSecureParameters.value.amount / 100;
    threeDSecureParameters.value.nonce = nonce;

    //@ts-ignore
    threeDSecureParameters.value.onLookupComplete = (data, next) => {
        next();
    };
};

const initThreeDSecureClient = async () => {
    try {
        threeDSecureInstance.value = await BraintreeThreeDSecure.create({
            version: 2,
            client: gatewayInstance.value,
        });
    } catch (err) {
        throw new Error('ThreeDSecure init error!');
    }
};

const createClientInstance = async () => {
    if (gatewayInstance.value) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await gatewayInstance.value.teardown(() => {});
    }

    if (threeDSecureInstance.value) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await threeDSecureInstance.value.teardown(() => {});
    }

    await getPaymentGatewayToken();

    try {
        gatewayInstance.value = await BraintreeClient.create({
            authorization: paymentGatewayToken.value,
        });
    } catch (err) {
        () => undefined;
    }
};

const getPaymentGatewayToken = async () => {
    try {
        const response = await axios.post<IApiResponse<string>>(
            route('braintree.payment-gateway-token'),
            {
                billing_address_type: props.paymentRequest.type as string,
            }
        );

        paymentGatewayToken.value = response.data.data;
    } catch (err) {
        () => undefined;
    }
};


const handleSubmit = async () => {
    try {
        loading.value = true;

        await pay();
    } catch (err) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const pay = async () => {
    if (selectedCard.value === null) {
        return;
    }

    const response = await axios.post(
        route('braintree.payment-method.nonce.store'),
        {
            token: selectedCard.value.token,
        }
    );

    if (response.status !== 200) {
        return;
    }

    let nonce = response.data.data.nonce;

    if (selectedCard.value.type === 'Card') {
        await createClientInstance();
        const verifyCardResponse = await verifyCard(nonce);

        if (verifyCardResponse === undefined) {
            throw new Error('Card verification failed');
        }
        if (
            verifyCardResponse.liabilityShifted === false &&
            verifyCardResponse.liabilityShiftPossible === true
        ) {
            create({
                title: 'Subscription not created',
                text: 'There was an error with 3D secure authentication.',
                type: 'error',
            });
            return;
        }

        nonce = verifyCardResponse.nonce;
    }

    return new Promise((resolve, reject) => {
        router.post(
            route('payment-requests.pay', {
                paymentRequest: props.paymentRequest.uuid,
            }),
            {
                nonce: nonce,
            },
            {
                preserveScroll: true,
                onSuccess: (response) => {
                    create({
                        title: 'Payment success',
                        text: 'Payment request has been paid.',
                        type: 'success',
                    });
                    resolve(response);
                },
                onError: (response) => {
                    create({
                        title: 'Payment failed',
                        text:
                            'Following error occurred "' +
                            response.message +
                            '" when charging you card.',
                        type: 'error',
                    });
                    reject(response);
                },
            }
        );
    });
};
</script>