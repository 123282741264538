<template>
    <div class="flex">
        <div v-if="dateIsSelected" class="flex mr-1">
            <p
                class="flex h-[40px] items-center rounded-md bg-indigo-600 px-3 text-sm font-medium tracking-small text-white"
            >
                {{ dateFrom }} - {{ dateTo }}

                <button class="ml-2" @click="resetInterval">
                    <XMarkIcon
                        class="w-4 h-4 text-white hover:text-gray-200"
                    ></XMarkIcon>
                </button>
            </p>
        </div>

        <Menu as="div" class="relative inline-block text-left">
            <div>
                <MenuButton
                    data-testid="open-filters-button"
                    class="inline-flex h-[40px] w-[40px] items-center justify-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                >
                    <CalendarIcon
                        v-if="!dateIsSelected"
                        class="w-5 h-5 text-gray-500"
                        aria-hidden="true"
                    />
                    <CalendarIconSolid
                        v-if="dateIsSelected"
                        class="w-5 h-5 text-indigo-600"
                        aria-hidden="true"
                    >
                    </CalendarIconSolid>
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    class="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div class="relative flex flex-wrap items-center gap-4 p-6">
                        <TextInput
                            v-model="dateFromVal"
                            label="From"
                            type="date"
                            class="w-32"
                        />

                        <TextInput
                            v-model="dateToVal"
                            label="To"
                            type="date"
                            class="w-32"
                        />

                        <AtomicButton
                            class="w-40 mt-4"
                            color="white"
                            @click="setInterval"
                        >
                            Set
                        </AtomicButton>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import {
    XMarkIcon,
    CalendarIcon as CalendarIconSolid,
} from '@heroicons/vue/24/solid';
import { CalendarIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    dateTo: {
        type: String,
        default: '',
    },
    dateFrom: {
        type: String,
        default: '',
    },
});
const emit = defineEmits(['update:dateTo', 'update:dateFrom']);

const dateIsSelected = computed(() => {
    return props.dateFrom && props.dateTo;
});

const dateFromVal = ref('');
const dateToVal = ref('');

const setInterval = () => {
    if (dateFromVal.value && dateToVal.value) {
        emit('update:dateTo', dateToVal.value)
        emit('update:dateFrom', dateFromVal.value)
    }
};

const resetInterval = () => {
    dateFromVal.value = '';
    dateToVal.value = '';

    emit('update:dateTo', dateToVal.value)
    emit('update:dateFrom', dateFromVal.value)
};
</script>
