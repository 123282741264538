<template>
    <form
        class="pb-5 mt-5 grid-cols-layout lg:grid"
        @submit.prevent="updateTestingEmails"
    >
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Testing Emails
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                Enter the emails addresses which will be used for sending testing emails.
            </p>
        </div>
        <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
           
                       
            <div class="flex items-center p-6">
                <div class="flex flex-col flex-wrap gap-6 sm:flex-row">
                    <div
                        class="w-full rounded-md border border-gray-300 bg-white text-sm sm:w-[300px]"
                    >
                        <div
                            v-for="email in emails"
                            :key="email"
                            class="border-b border-gray-200 cursor-pointer"
                        >
                            <div
                      
                                class="flex items-center justify-between p-2 text-gray-600 cursor-pointer group hover:bg-gray-100"
                            >
                                <div class="flex items-center gap-2">
                                    <span class="py-1">{{email}}</span>
                                    <PencilSquareIcon
                                        class="flex-shrink-0 hidden w-6 h-6 text-gray-500 cursor-pointer hover:text-gray-700 group-hover:block"
                                        @click="editItem(email)"
                                    />
                                </div>
                                <XMarkIcon
                                    class="flex-shrink-0 hidden w-6 h-6 text-gray-500 cursor-pointer hover:text-gray-700 group-hover:block"
                                    @click="deleteItem(email)"
                                />
                            </div>
                        </div>
                        <div
                        v-if="emails.length < 5"
                            class="flex items-center justify-center h-10 cursor-pointer group rounded-b-md hover:bg-gray-100"
                            @click="createItem"
                        >
                            <PlusCircleIcon
                                class="w-6 h-6 text-gray-500 group-hover:text-gray-700"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end gap-4 px-6 py-3">
                    <AtomicButton
                        type="submit"
                        color="yellow"
                        size="sm"
                        :disabled="saveDisabled"
                        >
                        Save changes
                    </AtomicButton>
            </div>
        </div>

        <TestingEmailsModal
            v-if="showModal"
            :email="selectedEmail"
            @close="handleCloseModal"
            @submit="updateEmails"
        />
    </form>

</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import { XMarkIcon, PencilSquareIcon, PlusCircleIcon } from '@heroicons/vue/20/solid';
import useConfirm from '@composables/useConfirm';

const props = defineProps({
    modelValue: {
        type: Array as PropType<string[]>,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const { show: showConfirmation } = useConfirm();
const showModal = ref(false);
const selectedEmail = ref('');

const handleCloseModal = () => {
    showModal.value = false;
    selectedEmail.value = '';
};

const saveDisabled = computed(() => {
    if (props.modelValue.length !== emails.value.length) {
        return false;
    }

    return props.modelValue.every((email, index) => email === emails.value[index]);
});

const emails = ref([...props.modelValue]);

const updateEmails = (email: string) => {
    const foundDuplicate = emails.value.find((e) => e === email);
    if(foundDuplicate) {
        handleCloseModal();
        return;
    }

    const index = emails.value.findIndex((e) => e === selectedEmail.value);

    if (index === -1) {
        emails.value.push(email);
    } else {
        emails.value[index] = email;
    }

    handleCloseModal();
}

const updateTestingEmails = () => {
    emit('update:modelValue', emails.value);
};

const createItem = () => {
    if(emails.value.length >= 5) {
        showConfirmation({
            header: 'Maximum limit reached',
            message: 'You can only add up to 5 testing emails.',
            type: 'info',
        });
        return;
    }
    selectedEmail.value = '';
    showModal.value = true;
};

const editItem = (email: string) => {
    selectedEmail.value = email;
    showModal.value = true;
};

const deleteItem = async (email: string) => {
    const confirmed = await showConfirmation({
        header: 'Delete email',
        message: `Do you really want to delete this ${email}?`,
        type: 'danger',
    });

    if (!confirmed) {
        return;
    }

    emails.value = emails.value.filter((e) => e !== email);
};

</script>
