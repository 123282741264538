<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 184 37"
        style="enable-background: new 0 0 184 37"
        xml:space="preserve"
    >
        <g class="aw-wordmark">
            <path
                d="M36.5,33.3L50.9,4.8c0.3-0.6,0.5-0.9,0.8-0.9h0.1c0.3,0,0.4,0.3,0.8,0.9l14.5,28.6c0.3,0.5,0,0.9-0.6,0.9h-4.2 c-0.6,0-0.8-0.2-1.2-0.9l-2.7-5.6H45.2l-2.7,5.6c-0.4,0.7-0.6,0.9-1.2,0.9h-4.2C36.5,34.3,36.3,33.8,36.5,33.3 M56.6,23.1l-4.7-10 h-0.1l-4.7,10H56.6z"
            ></path>
            <path
                d="M60.3,3.8c0.7,0,1,0.2,1.2,0.9L70,22.6l7.4-17.9c0-0.1,0.1-0.3,0.1-0.3c0.2-0.4,0.3-0.5,0.6-0.5h0.7c0.3,0,0.4,0.2,0.6,0.5 c0.1,0.1,0.1,0.2,0.2,0.3l7.3,17.9l8.6-17.9C95.7,4,96,3.8,96.6,3.8h4.4c0.7,0,0.8,0.2,0.5,0.9L87.3,33.3c-0.3,0.6-0.4,0.9-0.7,0.9 h-0.4c-0.3,0-0.4-0.3-0.7-0.9l-7.1-17.5l-7.1,17.5c-0.3,0.6-0.4,0.9-0.7,0.9h-0.4c-0.3,0-0.4-0.3-0.7-0.9L55.5,4.7 c-0.3-0.7-0.3-0.9,0.4-0.9H60.3z"
            ></path>
            <path
                d="M107.4,11.7c7,0,10.6,5.6,10.6,12c0,0.8-0.1,1-0.9,1l-15.6,0c0.6,3.7,3.5,5.3,6.2,5.3c1.4,0,3.4-0.3,5.7-1.5 c0.4-0.2,0.6-0.3,0.8-0.3c0.2,0,0.3,0.1,0.6,0.4l1.3,1.6c0.4,0.6,0.5,0.8,0.1,1.2c-2,2-5.5,3-8.6,3c-6.4,0-11.4-5.1-11.4-11.3 S101.2,11.7,107.4,11.7 M112.7,21.1c-0.3-2.6-2-5.2-5.4-5.2c-2.9,0-5.1,2.3-5.7,5.2H112.7z"
            ></path>
            <path
                d="M125.4,15.4c0.6-1.1,3-3.7,7-3.7c5.7,0,9.8,5.1,9.8,11.3c0,6-4,11.3-9.8,11.3c-4,0-6.3-2.1-7.9-4l-0.6,2.6 c-0.4,0.6-0.6,0.9-1.1,0.9h-1.6c-0.7,0-0.9-0.2-0.9-0.9l0.1-31.5c0-0.7,0.2-0.9,0.9-0.9h3.3c0.7,0,0.9,0.2,0.9,0.9L125.4,15.4z
 M137.1,23c0-3.7-2.5-6.7-5.8-6.7c-3.4,0-5.9,3.1-5.9,6.7c0,3.7,2.5,6.8,5.9,6.8C134.6,29.7,137.1,26.8,137.1,23"
            ></path>
            <path
                d="M155.5,11.7c7,0,10.6,5.6,10.6,12c0,0.8-0.1,1-0.9,1l-15.6,0c0.6,3.7,3.5,5.3,6.2,5.3c1.4,0,3.4-0.3,5.7-1.5 c0.4-0.2,0.6-0.3,0.8-0.3c0.2,0,0.3,0.1,0.6,0.4l1.3,1.6c0.4,0.6,0.5,0.8,0.1,1.2c-2,2-5.5,3-8.6,3c-6.4,0-11.4-5.1-11.4-11.3 S149.3,11.7,155.5,11.7 M160.8,21.1c-0.3-2.6-2-5.2-5.4-5.2c-2.9,0-5.1,2.3-5.7,5.2H160.8z"
            ></path>
            <path
                d="M173.4,23.7v9.7c0,0.7-0.2,0.9-0.9,0.9h-3.3c-0.7,0-0.9-0.2-0.9-0.9V13.5c0-0.7,0.2-0.9,0.9-0.9h1.6c0.6,0,0.8,0.3,1.1,0.9 l1,3.1c1.2-2.2,4.3-4.6,9.4-4.6c0.8,0,0.9,0.3,0.8,0.8l-0.5,3.3c-0.1,0.7-0.3,0.9-1,0.9C176.8,17.2,173.4,20.3,173.4,23.7"
            ></path>
        </g>
        <g class="aw-brandmark" fill="#246be8">
            <path
                d="M20.8,2c-0.8,0-1.6,0.4-2.1,1c-3.6,4.6-5.8,10-6.7,15.4c-0.9,4.7-0.5,10.7,1.1,16.3c0.3,1.1,0.9,2,5.4,2l0,0l0,0 c12.2,0,17.7-4.8,17.7-17.3l0,0C36.2,7.1,31,2,20.8,2 M20.5,35.1c-0.7,0.4-1.7,0.1-2-0.6c0,0,0,0,0,0l0,0c-2.5-4.7-3.3-10-2.3-15.3 c1-5.3,3.6-10,7.6-13.6l0,0c0,0,0,0,0,0c0.6-0.5,1.6-0.5,2.1,0.1c0.6,0.6,0.5,1.5-0.1,2.1c0,0,0,0,0,0l0,0c-3.4,3-5.8,7.1-6.6,11.9 c-0.9,4.7,0,9.4,2,13.4l0,0c0,0,0,0,0,0.1C21.5,33.8,21.2,34.7,20.5,35.1 M29.7,12.9C29.7,12.9,29.7,12.9,29.7,12.9 c-2.5,2-4.3,4.6-4.9,7.7c-0.6,3.1,0,6.2,1.6,8.8l0,0c0,0,0,0,0,0.1c0.3,0.4,0.1,1-0.4,1.2c-0.5,0.2-1.1,0.1-1.4-0.4c0,0,0,0,0,0 l0,0c-1.8-2.9-2.5-6.4-1.8-9.9c0.7-3.5,2.7-6.5,5.5-8.8l0,0c0,0,0,0,0.1-0.1c0.4-0.3,1.1-0.3,1.4,0.1C30.2,12,30.1,12.6,29.7,12.9 M6.2,32.5c0.1,0.6-0.6,1-1.1,0.6c-2.7-2.4-4.3-5.5-4.3-13.8l0,0C0.8,8.6,5,4,10.7,2.5c0.6-0.2,1,0.5,0.7,0.9 c-2.6,4.3-4.4,9-5.3,14C5.2,22.4,5.3,27.6,6.2,32.5z"
            ></path>
        </g>
    </svg>
</template>
