<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="176.197"
        height="42.957"
        viewBox="0 0 176.197 42.957"
    >
        <g transform="translate(-2.003 -3.543)">
            <path
                d="M65.3,43.1c-4.4,0-7.5-4-7.5-9.4,0-4.6,2.3-9.5,7.6-9.5s7.4,5.3,7.4,9.4c0,5.5-3.2,9.5-7.5,9.5m.2-22.3c-6.8,0-12.3,4.8-12.3,13,0,7.7,5.2,12.6,11.8,12.6,6,0,12.3-3.9,12.3-13,.1-7.5-4.7-12.6-11.8-12.6"
                fill="#135a9d"
            ></path>
            <path
                d="M92.8,21.3a9.279,9.279,0,0,0-8.2,4.6h-.1l-.3-4.1H80.1c.1,2,.2,4.1.2,6.6V46.3h4.6V31.6a7.612,7.612,0,0,1,.3-2.1,6.479,6.479,0,0,1,6.1-4.6c4.2,0,5.8,3.3,5.8,7.2V46.2h4.4V31.7c.1-8.4-5.2-10.4-8.7-10.4"
                fill="#135a9d"
            ></path>
            <path
                d="M128.6,43.1a3.933,3.933,0,0,1-4.2-4.2c0-4.5,5-5.5,9.8-5.5h.7v4.2a3.194,3.194,0,0,1-.3,1.4,6.151,6.151,0,0,1-6,4.1m1.3-22.3a15.449,15.449,0,0,0-8.1,2.2l1,2.9a12.494,12.494,0,0,1,6.5-1.8c5.1,0,5.6,3.6,5.6,5.7v.6h-.2c-9.4,0-14.6,3.2-14.6,9.1,0,3.5,2.6,7,7.5,7a9.072,9.072,0,0,0,7.5-3.6h.2l.3,3.1h4.1a34.925,34.925,0,0,1-.4-5.9V30.9c0-5-1.9-10.1-9.4-10.1"
                fill="#135a9d"
            ></path>
            <path
                d="M154.9,20.9c-8.1,0-13.3,5.5-13.3,13s4.8,12.5,12.3,12.5a16.713,16.713,0,0,0,7.1-1.5l-.8-3.3a12.97,12.97,0,0,1-5.5,1.2c-4.9,0-8.6-3.5-8.6-9.2,0-5.1,3.1-9.3,8.8-9.3a10.724,10.724,0,0,1,5.2,1.2l1-3.4a14.616,14.616,0,0,0-6.2-1.2"
                fill="#135a9d"
            ></path>
            <path
                d="M23,18a7.972,7.972,0,0,1-.3,1.5,2.286,2.286,0,0,1-.7,1,2.384,2.384,0,0,1-1.1.6c-.6.1-1.2.2-1.7.3H17.6a1.777,1.777,0,0,1-1-.4,1.222,1.222,0,0,1-.4-.9V18.6a5.768,5.768,0,0,1,.3-1.4,3.592,3.592,0,0,1,.7-1,2.384,2.384,0,0,1,1.1-.6,5.079,5.079,0,0,1,1.7-.3h1.7a1.389,1.389,0,0,1,1,.5,1.222,1.222,0,0,1,.4.9c0,.4-.1.9-.1,1.3M18.6,44.6a.52.52,0,0,1-.2.4.85.85,0,0,1-.5.3,3.553,3.553,0,0,1-1,.2c-.4.1-.9.1-1.5.2H13.9a2.769,2.769,0,0,1-.9-.1c-.2,0-.3-.1-.4-.3a.6.6,0,0,1-.1-.4l2.8-21a.52.52,0,0,1,.2-.4.85.85,0,0,1,.5-.3c.3-.1.6-.2,1-.3.5-.1,1-.1,1.5-.2H20a2.769,2.769,0,0,1,.9.1c.2,0,.3.1.5.3a.6.6,0,0,1,.1.4Z"
                fill="#135a9d"
            ></path>
            <path
                d="M43.3,11.2c-10.7,0-18,7.2-18,17.9,0,11.2,7.3,17.3,17,17.3a22,22,0,0,0,9.1-1.7l-.9-3.5a17.592,17.592,0,0,1-7.3,1.4C34.9,42.7,30,37.3,30,29c0-9,5.4-14.1,13.4-14.1a15.827,15.827,0,0,1,6.9,1.4l1.1-3.6c-1.1-.6-3.8-1.5-8.1-1.5"
                fill="#135a9d"
            ></path>
            <path
                d="M111.6,15.5l-4.4,1.4v4.4h-3.7v3.4h3.8V38c0,2.9.5,5.1,1.8,6.4a6.468,6.468,0,0,0,4.9,1.9,10.879,10.879,0,0,0,4-.7l-.2-3.3a8.8,8.8,0,0,1-2.6.3c-2.5,0-3.4-1.7-3.4-4.8V24.7h6.5V21.3h-6.5l-.2-5.8"
                fill="#135a9d"
            ></path>
            <path
                d="M171.7,15.5l-4.4,1.4v4.4h-3.7v3.4h3.8V38c0,2.9.4,5.1,1.7,6.4a6.013,6.013,0,0,0,4.7,1.9,10.723,10.723,0,0,0,3.9-.7l-.2-3.3a8.075,8.075,0,0,1-2.5.3c-2.4,0-3.3-1.7-3.3-4.8V24.7h6.5V21.3h-6.5V15.5"
                fill="#135a9d"
            ></path>
            <path
                d="M14.6,9.3a1.268,1.268,0,0,0-.6.1,10.834,10.834,0,0,0-5.1,6.4A9.9,9.9,0,0,0,10,23.5a1.326,1.326,0,0,0,1,.6.735.735,0,0,0,.6-.2,1.135,1.135,0,0,0,.4-1.6,7.225,7.225,0,0,1-.8-5.9,8,8,0,0,1,3.9-4.9,1.165,1.165,0,0,0,.5-1.6,1.1,1.1,0,0,0-1-.6"
                fill="#135a9d"
            ></path>
            <path
                d="M7,23.9a11.084,11.084,0,0,1-1.2-8.7,13.745,13.745,0,0,1,6.8-8.4,1.766,1.766,0,0,0,.8-2.3,1.682,1.682,0,0,0-2.2-.8c-.1,0-.1.1-.2.1A17.8,17.8,0,0,0,2.5,14.4,14.237,14.237,0,0,0,4.1,25.6a1.755,1.755,0,0,0,2.3.6A1.64,1.64,0,0,0,7,23.9"
                fill="#135a9d"
            ></path>
        </g>
    </svg>
</template>
