<template>
    <div class="py-12">
        <inertia-link :href="route('kiosk.blocked-domains.index')">
            <AtomicButton class="mr-2" color="yellow" size="sm">
                Back to Blocked Domains
                <template #icon>
                    <BackspaceIcon
                        class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <h1
            class="mt-2 text-2xl font-medium leading-normal text-gray-800 tracking-small"
        >
            Edit
        </h1>

        <form class="flex flex-col" @submit.prevent="updateDomain">
            <TextInput
                v-model="v$.form.domain.$model"
                class="mt-5 w-52 sm:w-80"
                label="domain"
                type="text"
                placeholder="max 255 characters"
                :error="getError('domain')"
                required
            />

            <div class="flex flex-row mt-6">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="sm"
                    :disabled="loading || v$.$invalid"
                >
                    Save changes
                </AtomicButton>

                <AtomicButton
                    class="ml-2"
                    size="sm"
                    color="red"
                    @click.prevent="deleteDomain(domain)"
                >
                    Delete
                    <template #icon>
                        <TrashIcon class="w-6 h-6 mr-2" />
                    </template>
                </AtomicButton>
            </div>
        </form>
    </div>
</template>

<script lang="ts" setup>
import { TrashIcon, BackspaceIcon } from '@heroicons/vue/24/outline';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import route from 'ziggy-js';
import { PropType } from 'vue';

import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import useConfirm from '@/composables/useConfirm';
import IBlockedDomain from '@/types/Plugin/IBlockedDomain';
import IInputErrors from '@/types/Atomic/IInputErrors';

const props = defineProps({
    domain: {
        type: Object as PropType<IBlockedDomain>,
        required: true,
    },
});

const { show } = useConfirm();
const state = reactive({
    form: {
        domain: props.domain.domain,
    }
});

const rules = {
    form: {
        domain: {
            required,
            maxLength: maxLength(255),
        },
    },
};

const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});
const loading = ref(false);

const updateDomain = () => {
    v$.value.$touch();
    if (v$.value.form.domain.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.patch(
            route('kiosk.blocked-domains.update', {
                domain: props.domain.id,
            }),
            state.form,
            {
                preserveScroll: true,
                onError: (err) => {
                    errors.value = err;
                },
                onSuccess: () => {
                    errors.value = {};
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const deleteDomain = async (domain: IBlockedDomain) => {
    const confirmed = await show({
        header: `Delete domain`,
        message: `Are you sure you want to delete this domain?`,
        type: 'danger',
        confirm: 'Delete',
    });

    if (confirmed) {
        router.delete(
            route('kiosk.blocked-domains.destroy', {
                domain: domain.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Domain deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Domain not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>

<style></style>
