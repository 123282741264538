<template>
    <EditorPreferencesTabs :features="features" tab-key="fonts">
        <div
            class="mt-[44px] mb-24 flex flex-col gap-5 divide-y divide-gray-300"
        >
            <FontSizesPro :settings="settings" @save="saveFontSizes" />

            <DefaultFonts
                class="pt-5"
                :settings="settings"
                @save="saveDefaultFonts"
            />

            <CustomFonts
                class="pt-5"
                :settings="settings"
                @save="saveCustomFonts"
            />
        </div>
    </EditorPreferencesTabs>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import { PropType } from 'vue';
import IEditorSettings from '@/types/Settings/IEditorSettings';
import EditorPreferencesTabs from '../components/EditorPreferencesTabs.vue';
import { create } from '@composables/useNotifications';
import { useForm } from '@inertiajs/vue3';
import IFont from '@/types/Settings/IFont';

defineProps({
    settings: {
        type: Object as PropType<IEditorSettings>,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        default: () => {},
    },
    features: {
        type: Object,
        default: null,
    },
});

const saveFontSizes = (fontSizes) => {
    const form = useForm({
        font_sizes: fontSizes,
    });

    form.put(route('editor-preferences.font-sizes.update'), {
        preserveScroll: true,
        preserveState: false,
        onSuccess: () => {
            create({
                title: 'Editor font sizes updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Font sizes not updated',
                text: 'There was an error updating editor font sizes.',
                type: 'error',
            });
        },
    });
};

const saveDefaultFonts = (fonts: IFont[]) => {
    const form = useForm({
        default_fonts: fonts,
    });

    form.put(route('editor-preferences.default-fonts.update'), {
        preserveScroll: true,
        preserveState: false,
        onSuccess: () => {
            create({
                title: 'Default fonts updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Fonts not updated',
                text: 'There was an error updating editor default fonts.',
                type: 'error',
            });
        },
    });
};

const saveCustomFonts = (fonts: IFont[]) => {
    const form = useForm({
        custom_fonts: fonts,
    });

    form.put(route('editor-preferences.custom-fonts.update'), {
        preserveScroll: true,
        preserveState: false,
        onSuccess: () => {
            create({
                title: 'Custom fonts updated',
                type: 'success',
            });
        },
        onError: () => {
            create({
                title: 'Fonts not updated',
                text: 'There was an error updating editor custom fonts.',
                type: 'error',
            });
        },
    });
};
</script>
