<template>
    <div>
        <div
            class="mt-[45px] flex flex-col items-start gap-4 sm:flex-row sm:items-start sm:justify-between"
        >
            <div class="flex flex-wrap items-center justify-start gap-3">
                <div class="flex flex-wrap items-center gap-4">
                    <inertia-link
                        :href="route('marketplace.all-templates.index')"
                    >
                        <button
                            class="flex flex-nowrap items-center justify-start text-left"
                            data-testid="my-templates-button"
                        >
                            <p
                                class="mx-4 ml-0 text-2xl font-medium leading-normal tracking-small text-gray-800"
                            >
                                Marketplace
                            </p>

                            <div class="h-6 w-6 flex-shrink-0 text-gray-400">
                                <svg
                                    class="h-6 w-6"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11 8.5L14.4645 11.9645L11 15.4289"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </button>
                    </inertia-link>

                    <div class="group relative flex">
                        <h1
                            class="text-2xl font-medium text-gray-800"
                            style="word-break: break-word"
                        >
                            {{ template.name }}
                        </h1>
                    </div>
                </div>
            </div>

            <div
                class="mt-4 flex flex-shrink-0 flex-col gap-4 sm:mt-0 sm:flex-row"
            >
                <inertia-link :href="route('marketplace.all-templates.index')">
                    <AtomicButton color="white" size="lg" class="flex-shrink-0">
                        <template #icon>
                            <ArrowUturnLeftIcon class="mr-2 h-5 w-5" />
                        </template>
                        Go back
                    </AtomicButton>
                </inertia-link>
            </div>
        </div>

        <div
            class="mt-4 mb-9 flex flex-col rounded bg-white shadow-card lg:w-full lg:flex-row"
        >
            <div
                class="border-gray-300 sm:w-[550px] md:w-[590px] md:shrink-0 lg:border-r xl:w-[680px] 2xl:w-[720px]"
            >
                <div class="border-gray-300 sm:border-b">
                    <div
                        class="my-3 mx-auto hidden h-8 w-[267px] items-center justify-between rounded-md bg-gray-100 text-xs font-medium text-gray-500 sm:flex"
                    >
                        <button
                            class="flex h-full w-full items-center justify-center rounded-l-md px-2 transition duration-200 ease-in-out focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'mobile',
                            }"
                            @click="resolution = 'mobile'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'mobile',
                                    'text-gray-400': resolution !== 'mobile',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 18H12.01M8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V5C18 4.46957 17.7893 3.96086 17.4142 3.58579C17.0391 3.21071 16.5304 3 16 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Mobile</p>
                        </button>

                        <button
                            class="flex h-full w-full items-center justify-center rounded-r-md px-2 transition duration-200 ease-in-out focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'desktop',
                            }"
                            @click="resolution = 'desktop'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'desktop',
                                    'text-gray-400': resolution !== 'desktop',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 13H21M9.75 17L9 20L8 21H16L15 20L14.25 17H9.75ZM5 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Desktop</p>
                        </button>
                    </div>
                </div>

                <iframe
                    :srcdoc="templateHtml"
                    class="h-[800px] rounded-t bg-white transition-all duration-200 ease-out sm:rounded-t-none"
                    :class="{
                        'mx-auto w-full sm:w-[550px] md:w-[490px] xl:w-[670px] 2xl:w-[700px]':
                            resolution === 'desktop',
                        'mx-auto border-l border-r border-gray-300 sm:w-[375px]':
                            resolution === 'mobile',
                    }"
                ></iframe>
            </div>

            <div class="border-t border-gray-300 lg:border-t-0">
                <div class="mt-14 px-10 lg:px-4 2xl:ml-10 2xl:pr-[73px]">
                    <h3 class="text-2xl font-medium text-gray-700">
                        {{ template.name }}
                    </h3>

                    <p class="mt-4 max-w-sm text-base text-gray-600">
                        {{ template.description }}
                    </p>

                    <div class="mt-4 text-sm text-gray-600">
                        <div>
                            <p>Category:</p>
                            <div
                                class="inline-block border-b-2 border-orange-500 text-gray-800 transition duration-200 ease-in-out"
                            >
                                {{ template.category.name }}
                            </div>
                        </div>

                        <div class="mt-2">Keywords:</div>

                        <div class="flex flex-wrap gap-2">
                            <div
                                v-for="keyword in template.keywords"
                                :key="keyword.id"
                                class="border-b-2 border-orange-500 text-gray-800 transition duration-200 ease-in-out"
                            >
                                {{ keyword.keyword }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="mt-6 flex flex-col items-center gap-4 sm:flex-row"
                    >
                        <div
                            class="h-10 cursor-default rounded-md px-7 text-center text-sm font-medium leading-10 text-gray-50 ring-1 ring-white"
                            :class="{
                                'bg-green-600': template.price === 0,
                                'bg-indigo-600': template.price !== 0,
                            }"
                        >
                            <span v-if="template.price">
                                ${{ template.price }}
                            </span>
                            <span v-else> Free </span>
                        </div>

                        <AtomicButton
                            v-if="!isInCart && !template.owned_by_current_team"
                            size="lg"
                            color="yellow"
                            @click="addToCart"
                        >
                            <template #icon>
                                <ShoppingCartIcon class="mr-2 h-5 w-5" />
                            </template>
                            Add to cart
                        </AtomicButton>

                        <div
                            v-else-if="isInCart"
                            class="text-sm font-bold text-green-600"
                        >
                            Already in cart
                        </div>

                        <div
                            v-else
                            class="text-sm font-bold text-topol-blue-500"
                        >
                            Already owned
                        </div>
                    </div>

                    <div class="mt-6 pb-10">
                        <div class="flex items-center gap-2 text-xs xl:mb-0">
                            <div class="flex flex-col text-gray-500">
                                <p>Created by</p>
                            </div>

                            <div>
                                <div
                                    class="flex flex-shrink-0 items-center gap-2"
                                >
                                    <div class="h-8 w-8">
                                        <img
                                            class="h-8 w-8 rounded-full object-cover ring-[1px] ring-gray-300"
                                            :src="
                                                template.author
                                                    .profile_photo_path ??
                                                template.author
                                                    .profile_photo_url
                                            "
                                            :alt="template.author.name"
                                        />
                                    </div>

                                    <div class="text-base text-gray-700">
                                        {{ template.author.name }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- TODO -->
                        <!-- <inertia-link :href="route('marketplace.all-templates.index')" class="mt-2 text-xs font-medium text-topol-blue-500 hover:text-topol-blue-600">
                            See more templates from {{ template.author.name }}
                        </inertia-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';
import { ShoppingCartIcon } from '@heroicons/vue/24/solid';
import { PropType } from 'vue';
import route from 'ziggy-js';
const { addTemplateToCart, cart } = useMarketplaceCart();

const props = defineProps({
    template: {
        type: Object as PropType<IMarketplaceTemplate>,
        required: true,
    },
    templateHtml: {
        type: String,
        required: true,
    },
});

const resolution = ref<'desktop' | 'mobile'>('desktop');

const addToCart = () => {
    addTemplateToCart(props.template.id);
};

const isInCart = computed(() => {
    if (!cart.value) {
        return false;
    }
    return cart.value.shopping_cart_items.some(
        (item) => item.marketplace_template_id === props.template.id
    );
});
</script>
