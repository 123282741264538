<template>
    <div class="mt-[45px]" data-cy="plugin-settings-index">
        <div class="flex flex-wrap justify-between">
            <h1
                class="text-2xl font-medium leading-normal tracking-small text-gray-800"
            >
                Plugin
            </h1>
            <AtomicButton
                v-if="teamPluginEnabled"
                color="yellow"
                size="lg"
                data-cy="plugin-settings-create-token"
                @click="createTokenModal = true"
            >
                <template #icon>
                    <PlusCircleIcon class="mr-2 h-6 w-6" />
                </template>
                New API Token
            </AtomicButton>
        </div>

        <div
            v-if="!teamPluginEnabled"
            class="mt-[19px] rounded-md bg-white shadow-card"
        >
            <div class="px-6">
                <div class="flex flex-col sm:flex-row">
                    <svg
                        class="mt-10 ml-6 flex shrink-0 sm:ml-0"
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 11V22M19 27H19.02M37 19C37 21.3638 36.5344 23.7044 35.6298 25.8883C34.7252 28.0722 33.3994 30.0565 31.7279 31.7279C30.0565 33.3994 28.0722 34.7252 25.8883 35.6298C23.7044 36.5344 21.3638 37 19 37C16.6362 37 14.2956 36.5344 12.1117 35.6298C9.92784 34.7252 7.94353 33.3994 6.27208 31.7279C4.60062 30.0565 3.27475 28.0722 2.37017 25.8883C1.46558 23.7044 1 21.3638 1 19C1 14.2261 2.89642 9.64773 6.27208 6.27208C9.64773 2.89642 14.2261 1 19 1C23.7739 1 28.3523 2.89642 31.7279 6.27208C35.1036 9.64773 37 14.2261 37 19Z"
                            stroke="#FFC000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div class="ml-6 mt-6 sm:mt-[46px]">
                        <p class="font-medium leading-normal text-gray-800">
                            In order to use TOPOL.io Plugin you need to enable
                            it for your account first.
                        </p>
                        <div
                            class="mt-3.5 max-w-[550px] text-sm leading-normal text-gray-500"
                        >
                            <p>
                                TOPOL.io Plugin is a version of TOPOL.io editor
                                which can be embedded into your platform. You
                                will get a white label version of email template
                                editor that can be used by your users.
                            </p>
                            <br />
                            <p>
                                <a href="https://docs.topol.io/" target="_blank"
                                    ><span
                                        class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                        >Check out our documentation</span
                                    ></a
                                >
                                how to create API Token, embed plugin, setup
                                custom data storage and more.
                            </p>
                        </div>
                        <AtomicButton
                            color="yellow"
                            size="lg"
                            class="mt-[31px] mb-[67px]"
                            data-cy="enable-plugin"
                            @click="enablePlugin"
                        >
                            <template #icon>
                                <div class="mr-2">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.8333 2.5V8.33333H16.6666L9.16658 17.5V11.6667H3.33325L10.8333 2.5Z"
                                            stroke="#252F3F"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </template>
                            Setup plugin
                        </AtomicButton>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mb-20">
            <div class="mt-4 grid-cols-layout pb-5 lg:grid">
                <div class="mb-6 lg:mt-4 lg:mb-0">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Active API Tokens
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        API tokens are used to access Topol Plugin.
                    </p>
                </div>

                <div
                    v-if="filteredTokens.length === 0"
                    class="rounded-md bg-white shadow-card"
                >
                    <div class="px-6">
                        <div class="flex flex-col sm:flex-row">
                            <svg
                                class="mt-10 ml-6 flex shrink-0 sm:ml-0"
                                width="38"
                                height="38"
                                viewBox="0 0 38 38"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19 11V22M19 27H19.02M37 19C37 21.3638 36.5344 23.7044 35.6298 25.8883C34.7252 28.0722 33.3994 30.0565 31.7279 31.7279C30.0565 33.3994 28.0722 34.7252 25.8883 35.6298C23.7044 36.5344 21.3638 37 19 37C16.6362 37 14.2956 36.5344 12.1117 35.6298C9.92784 34.7252 7.94353 33.3994 6.27208 31.7279C4.60062 30.0565 3.27475 28.0722 2.37017 25.8883C1.46558 23.7044 1 21.3638 1 19C1 14.2261 2.89642 9.64773 6.27208 6.27208C9.64773 2.89642 14.2261 1 19 1C23.7739 1 28.3523 2.89642 31.7279 6.27208C35.1036 9.64773 37 14.2261 37 19Z"
                                    stroke="#FFC000"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>

                            <div class="ml-6 mt-6 sm:mt-[46px]">
                                <p
                                    class="font-medium leading-normal text-gray-800"
                                >
                                    You have no active API Tokens at the moment.
                                </p>
                                <div
                                    class="mb-12 mt-3.5 max-w-[550px] text-sm leading-normal text-gray-500"
                                >
                                    <p>
                                        To embed TOPOL.io Plugin first create an
                                        active API Token for your app.
                                    </p>
                                    <br />
                                    <p>
                                        <a
                                            href="https://docs.topol.io"
                                            target="_blank"
                                            ><span
                                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                                                >Check out our
                                                documentation</span
                                            ></a
                                        >
                                        how to create API Token, embed plugin,
                                        setup custom data storage and more.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Tokens
                    v-else
                    class="-mb-10"
                    :tokens="filteredTokens"
                    @selectModal="showRemoveTokenModal"
                    @googleModal="showGoogleModal"
                    @amazonModal="showAmazonModal"
                    @openAISettingsModal="showOpenAISettingsModal"
                    @showCopyTokenModal="showCopyTokenModal"
                />
            </div>

            <div
                v-if="filteredTokens.length > 0"
                class="grid-cols-layout border-t border-gray-300 pt-5 lg:grid"
            >
                <div class="mb-6 lg:mt-4 lg:mb-0">
                    <h3
                        class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Disable plugin
                    </h3>
                </div>
                <div class="rounded-md bg-white shadow-card">
                    <div class="border-b border-gray-200 px-6">
                        <p class="pt-6 text-sm leading-normal text-gray-500">
                            After disabling plugin, all of your active API
                            Tokens will be expired.<br />
                            You will not be able to initialize TOPOL.io editor
                            in your application.
                        </p>
                        <div class="mt-[23px] mb-6">
                            <label
                                class="flex"
                                data-cy="disable-plugin-delete-confirmation"
                            >
                                <CheckboxInput
                                    v-model:checked="deleteConfirmation"
                                    class="mt-[1px]"
                                    size="md"
                                    name="deleteConfirmation"
                                />
                                <p
                                    class="ml-1 cursor-pointer select-none text-sm leading-normal text-gray-900"
                                >
                                    Confirm that you want to disable TOPOL.io
                                    Plugin on your account.
                                </p>
                            </label>
                        </div>
                    </div>

                    <div class="flex justify-end">
                        <div class="my-3 pr-6">
                            <AtomicButton
                                :disabled="!deleteConfirmation"
                                data-cy="disable-plugin"
                                color="red"
                                size="sm"
                                @click="disableModal = true"
                            >
                                Disable plugin
                            </AtomicButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <CreateTokenModal v-if="createTokenModal" @close="closeCreateTokenModal" />

    <DisableModal
        v-if="disableModal"
        data-cy="plugin-disable-modal"
        @disable="disablePlugin"
        @close="disableModal = false"
    />

    <RemoveTokenModal
        v-if="removeTokenModal"
        :token="selectedToken"
        :loading="removeTokenLoading"
        @remove="removeToken"
        @close="removeTokenModal = false"
    />
    <StorageModal
        v-if="storageModal"
        :amazon="amazon"
        :google="google"
        :token="selectedToken"
        @close="closeStorageModal"
        @deleteModal="deleteStorageModalShow"
    />
    <DeleteStorageModal
        v-if="deleteStorageModal"
        :amazon="amazon"
        :google="google"
        :token="selectedToken"
        @close="closeDeleteStorageModal"
        @deleteStorage="deleteStorage"
    />
    <OpenAISettingsModal
        v-if="openAISettingsModal"
        :token="selectedToken"
        @close="openAISettingsModal = false"
        @save="saveOpenAISettings"
        @delete="deleteOpenAISettings"
    />
    <CopyTokenApiKeyModal
        v-if="copyTokenModal"
        :token-key="selectedToken.token"
        @close="copyTokenModal = false"
    />
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { router } from '@inertiajs/vue3';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import CheckboxInput from '@atomic/Inputs/CheckboxInput.vue';
import axios from 'axios';
import route from 'ziggy-js';
import IToken from '@/types/Plugin/IToken';
import { create } from '@composables/useNotifications';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import ISubscription from '@/types/Subscription/ISubscription';

//TODO prevent blank domains to be added

const { show: showConfirmation } = useConfirm();

const props = defineProps({
    teamPluginEnabled: {
        type: Boolean,
        default: false,
    },
    tokens: {
        type: Array as PropType<IToken[]>,
        default: () => [],
    },
    pluginSubscription: {
        type: Object as PropType<ISubscription>,
        default: () => null,
    },
    features: {
        type: Object,
        default: () => ({}),
    },
});

const amazon = ref(false);
const google = ref(false);
const storageModal = ref(false);
const createTokenModal = ref(false);
const disableModal = ref(false);
const selectedTokenId = ref('');
const removeTokenModal = ref(false);
const removeTokenLoading = ref(false);
const deleteStorageModal = ref(false);
const openAISettingsModal = ref(false);
const deleteConfirmation = ref(false);
const copyTokenModal = ref(false);
const selectedToken = ref({} as IToken);

const filteredTokens = computed(() => {
    return Object.values(props.tokens);
});

const deleteStorageModalShow = () => {
    storageModal.value = false;
    deleteStorageModal.value = true;
};

const deleteStorage = async (id: string) => {
    selectedTokenId.value = id;
    try {
        const res = await axios.delete(
            route('api-tokens.storage.delete', {
                apiToken: selectedTokenId.value,
            })
        );
        if (res.status === 204) {
            create({
                title: 'Custom storage detached',
                text: 'Your custom storage has been successfully detached. You are now using default Google Cloud storage.',
                type: 'success',
            });
            router.visit(route('api-tokens.index') + '#storage-options', {
                preserveScroll: true,
            });
            deleteStorageModal.value = false;
        }
    } catch (err) {
        () => undefined;
    }
};

const closeStorageModal = () => {
    amazon.value = false;
    google.value = false;
    storageModal.value = false;
};

const closeDeleteStorageModal = () => {
    amazon.value = false;
    google.value = false;
    deleteStorageModal.value = false;
};

const showGoogleModal = (id: string) => {
    const token = filteredTokens.value.find((a) => a.id === id);
    if (token) {
        selectedToken.value = token;
    }
    selectedTokenId.value = id;
    google.value = true;
    storageModal.value = true;
};

const showAmazonModal = (id: string) => {
    const token = filteredTokens.value.find((a) => a.id === id);
    if (token) {
        selectedToken.value = token;
    }
    selectedTokenId.value = id;
    amazon.value = true;
    storageModal.value = true;
};

const showOpenAISettingsModal = (id: string) => {
    const token = props.tokens.find((a) => a.id === id);
    if (token) {
        selectedToken.value = token;
        openAISettingsModal.value = true;
    }
};

const showCopyTokenModal = (id: string) => {
    const token = props.tokens.find((a) => a.id === id);
    if (token) {
        selectedToken.value = token;
        copyTokenModal.value = true;
    }
};

const saveOpenAISettings = async (key: string) => {
    try {
        const res = await axios.post(
            route('api-tokens.open-ai-settings.store', {
                apiToken: selectedToken.value.id,
            }),
            {
                key: key,
            }
        );
        if (res.status === 200) {
            create({
                title: 'OpenAI settings saved',
                text: 'Your OpenAI settings have been successfully saved.',
                type: 'success',
            });
            router.reload({ only: ['tokens'] });
        }
    } catch (e) {
        () => undefined;
    } finally {
        openAISettingsModal.value = false;
    }
};

const deleteOpenAISettings = async () => {
    const confirmed = await showConfirmation({
        header: 'Confirmation',
        message: `Do you really want disable your OpenAI Text Assistant?`,
        type: 'danger',
    });

    if (confirmed) {
        try {
            const res = await axios.delete(
                route('api-tokens.open-ai-settings.delete', {
                    apiToken: selectedToken.value.id,
                })
            );
            if (res.status === 204) {
                create({
                    title: 'OpenAI Text Assistant disabled',
                    text: 'Your OpenAI Text Assistant has been successfully disabled.',
                    type: 'success',
                });
                router.reload({ only: ['tokens'] });
            }
        } catch (e) {
            () => undefined;
        } finally {
            openAISettingsModal.value = false;
        }
    }
};

const enablePlugin = () => {
    const globalProps = computed(() => usePage<ITopolPageProps>().props);
    if (
        props.pluginSubscription ||
        (globalProps.value.user.plugin_trial_ends_at &&
            new Date(globalProps.value.user.plugin_trial_ends_at) > new Date())
    ) {
        router.post(
            route('plugin.enable'),
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Plugin enabled',
                        text: 'Plugin has been successfully enabled.',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Plugin not enabled',
                        text: 'There was an error enabling your Plugin.',
                        type: 'error',
                    });
                },
            }
        );
    } else router.get(route('billing.plugin-subscriptions.index'));
};

const disablePlugin = () => {
    router.post(
        route('plugin.disable'),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Plugin disabled',
                    text: 'Plugin has been successfully disabled.',
                    type: 'success',
                });
                disableModal.value = false;
                deleteConfirmation.value = false;
            },
            onError: () => {
                create({
                    title: 'Plugin not disabled',
                    text: 'There was an error disabling your Plugin.',
                    type: 'error',
                });
            },
        }
    );
};

const showRemoveTokenModal = (id: string) => {
    const token = filteredTokens.value.find((a) => a.id === id);
    if (token) {
        selectedToken.value = token;
    }
    selectedTokenId.value = id;
    removeTokenModal.value = true;
};

const closeCreateTokenModal = () => {
    createTokenModal.value = false;
    router.reload({ only: ['tokens'] });
};

const removeToken = async () => {
    removeTokenLoading.value = true;

    try {
        const res = await axios.delete(
            route('api-tokens.delete', {
                apiToken: selectedTokenId.value,
            })
        );
        if (res.status === 204) {
            create({
                title: 'API Token removed',
                text: 'API Token has been successfully removed.',
                type: 'success',
            });
            router.visit(route('api-tokens.index'), {
                preserveScroll: true,
            });
            removeTokenModal.value = false;
        }
    } catch (err) {
        () => undefined;
    } finally {
        removeTokenLoading.value = false;
    }
};
</script>
