<template>
    <div class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Custom font sizes
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                Define font size options available in TinyMCE text editor. Font
                size is set in pixels.
            </p>
        </div>
        <div
            class="relative mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
        >
            <div
                class="flex flex-wrap items-end justify-start gap-4 border-b border-gray-200 px-6 py-5 pt-[23px]"
            >
                <TextInput
                    :model-value="fontSizes"
                    label="Available font sizes (pixels)"
                    placeholder="Enter font sizes"
                    class="w-full max-w-xs"
                    @update:modelValue="updateFontSizes"
                />
                <AtomicButton
                    type="button"
                    color="white"
                    size="md"
                    @click="reset"
                >
                    Reset
                </AtomicButton>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';

const props = defineProps({
    modelValue: {
        type: Array as PropType<number[]>,
        required: true,
    },
    defaultValue: {
        type: Array as PropType<number[]>,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const reset = () => {
    emit('update:modelValue', props.defaultValue);
};

const fontSizes = computed(() => {
    return props.modelValue.join(',');
});

const updateFontSizes = (value: string) => {
    const fontSizes = value
        .split(',')
        .map((item) => parseInt(item))
        .filter((item) => !isNaN(item) && item > 0 && item <= 120);

    emit('update:modelValue', fontSizes);
};
</script>
