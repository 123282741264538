<template>
    <div>
        <Editor v-bind="{ ...props }"></Editor>
    </div>
</template>

<script setup lang="ts">
import Editor from '@components/Editor/index.vue';
import { ISavedBlock, IStage } from '@topol.io/editor-vue';

type CurrentUser = {
    id: number;
    name: string;
    profile_photo_path: string;
    profile_photo_url: string;
};

interface Props {
    apiKey: string;
    token: string;
    definition: string;
    saveUrl: string;
    backUrl: string;
    savedBlocks: ISavedBlock[];
    saveSavedBlockUrl: string;
    getAutosaveUrl: string;
    sendTestEmailUrl: string;
    createAutosaveUrl: string;
    getAutosavesUrl: string;
    theme: string;
    fontSizes: string;
    language?: string;
    title?: string;
    currentUser: CurrentUser;
    ownerId: string | number;
    templateId: number;
    templateStatus?: string|null;
    lambdaStage: 'production' | 'develop';
    chatAI?: boolean;
    userRole?: string;
    stage?: IStage;
    getConversationsUrl?: string;
    getCommentsUrl?: string;
    enableComments?: boolean;
    getFeedsUrl?: string;
    getProductsUrl?: string;
    defaultFonts?: string;
    customFonts?: string;
    smartMergeTags?: string;
    mergeTags?: string;
    colors?: string;
    imageCompressionOptions?: string;
    enableImageToTextRatio?: boolean;
    googlePromotionalAnnotation?: boolean;
    renameTemplate?: boolean;
    htmlMinified?: boolean;
    enableSectionVariants?: boolean;
    isSubscribed?: boolean;
    testingEmails: string;
}

const props = withDefaults(defineProps<Props>(), {
    language: 'en',
    title: '',
    dev: false,
    chatAI: false,
    userRole: 'owner',
    stage: 'production',
    templateStatus: null,
    getConversationsUrl: '',
    getCommentsUrl: '',
    enableComments: false,
    getFeedsUrl: '',
    getProductsUrl: '',
    defaultFonts: '[]',
    customFonts: '[]',
    smartMergeTags: '0',
    mergeTags: '[]',
    colors: '[]',
    imageCompressionOptions: '[]',
    enableImageToTextRatio: false,
    googlePromotionalAnnotation: false,
    renameTemplate: false,
    htmlMinified: false,
    enableSectionVariants: false,
    isSubscribed: true,
    

});

</script>

<script lang="ts">
import BlankLayout from '@/Layouts/Blank.vue';

export default {
    layout: BlankLayout,
};
</script>
