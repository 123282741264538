<template>
    <BlockedResourcesTabs tab-key="blocked-domains">
        <AtomicButton
            class="mt-10 mb-2 mr-2"
            size="sm"
            color="yellow"
            @click="createNew = true"
        >
            Add
            <template #icon>
                <PlusCircleIcon class="w-6 h-6 mr-2" />
            </template>
        </AtomicButton>

        <form
            v-if="createNew"
            class="flex flex-col"
            @submit.prevent="saveDomain"
        >
            <TextInput
                v-model="v$.form.domain.$model"
                class="mt-5 w-52 sm:w-80"
                label="Name"
                type="text"
                placeholder="max 255 characters"
                :error="getError('domain')"
                required
            />
            <div class="flex flex-row mt-6">
                <AtomicButton
                    type="submit"
                    color="yellow"
                    size="sm"
                    class="mr-2"
                    :disabled="loading || v$.$invalid"
                >
                    Save
                </AtomicButton>

                <AtomicButton
                    v-if="createNew"
                    color="white"
                    size="sm"
                    @click.prevent="handleClose"
                >
                    Cancel
                </AtomicButton>
            </div>
        </form>

        <DataTable class="mt-5" :items="domains" :headers="headers" searchable>
            <template #item-custom_edit="{ row }">
                <inertia-link
                    :href="
                        route('kiosk.blocked-domains.edit', {
                            domain: row.id,
                        })
                    "
                >
                    <AtomicButton size="sm" color="white">
                        Edit
                        <template #icon>
                            <PencilSquareIcon class="w-6 h-6 mr-2" />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>

            <template #item-domain="{ row }">
               <div>
                    <p class="break-all relative text-gray-500 lg:max-w-[270px]">
                        {{ row.domain }}
                    </p>
               </div>
            </template>

            <template #item-custom_delete="{ row }">
                <AtomicButton size="sm" color="red" @click="deleteDomain(row)">
                    <template #icon>
                        <TrashIcon class="w-6 h-6" />
                    </template>
                </AtomicButton>
            </template>
        </DataTable>
    </BlockedResourcesTabs>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import TextInput from '@atomic/Inputs/TextInput.vue';
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import route from 'ziggy-js';

import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
} from '@heroicons/vue/24/outline';
import { reactive, ref } from 'vue';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import { create } from '@/composables/useNotifications';
import useConfirm from '@/composables/useConfirm';
import { router } from '@inertiajs/vue3';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import IBlockedDomain from '@/types/Plugin/IBlockedDomain';
import IInputErrors from '@/types/Atomic/IInputErrors';

interface IError {
    domain?: string;
}

defineProps<{
    errors: IError;
    domains: IPaginationData<IBlockedDomain>;
}>();

const createNew = ref(false);
const loading = ref(false);
const { show } = useConfirm();
const state = reactive({
    form: {
        domain: '',
    },
});

const rules = {
    form: {
        domain: { required },
    },
};
const v$ = useVuelidate(rules, state);
const errors = ref<IInputErrors>({});

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id' },
    { text: 'Domain', id: 'domain', sortable: true, sortableId: 'domain' },
    { text: '', id: 'custom_edit' },
    { text: '', id: 'custom_delete' },
];

const saveDomain = () => {
    v$.value.form.domain.$touch();
    if (v$.value.form.domain.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route('kiosk.blocked-domains.store'), state.form, {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Domain added',
                    type: 'success',
                });
                errors.value = {};
                v$.value.$reset();
                createNew.value = false;
                state.form.domain = '';
            },
            onError: (err) => {
                errors.value = err;
                create({
                    title: 'Domain not added',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const deleteDomain = async (domain: IBlockedDomain) => {
    const confirmed = await show({
        header: `Delete domain`,
        message: `Are you sure you want to delete this domain?`,
        type: 'danger',
        confirm: 'Delete',
    });
    if (confirmed) {
        router.delete(
            route('kiosk.blocked-domains.destroy', {
                domain: domain.id,
            }),
            {
                preserveScroll: true,

                onSuccess: () => {
                    create({
                        title: 'Domain deleted',
                        type: 'success',
                    });
                },
                onError: () => {
                    create({
                        title: 'Domain not deleted',
                        type: 'error',
                    });
                },
            }
        );
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

const handleClose = () => {
    v$.value.$reset();
    state.form.domain = '';
    createNew.value = false;
    errors.value = {};
};
</script>

<style></style>
