<template>
    <div class="py-12" data-cy="kiosk-plugins-index">
        <DataTable
            data-cy="kiosk-plugins-table"
            title="Api Tokens"
            searchable
            :headers="headers"
            :items="apiTokens"
        >
            <template #item-expires_at="{ data }">
                <div class="flex justify-center p-6 text-gray-500">
                    <span
                        v-if="
                            data &&
                            differenceInCalendarDays(
                                new Date(),
                                new Date(data as string)
                            ) >= 0
                        "
                    >
                        true</span
                    >

                    <span v-else> false</span>
                </div>
            </template>

            <template #item-last_used_at="{ data }">
                <div class="flex justify-center p-6 text-gray-600">
                    <span v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </span>

                    <span v-else>Never</span>
                </div>
            </template>

            <template #item-created_at="{ data }">
                <div v-if="data" class="flex justify-center p-6 text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy') }}
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/types/Atomic/IPaginationData';

import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';

import IToken from '@/types/Plugin/IToken';
import { differenceInCalendarDays, format } from 'date-fns';

defineProps<{
    apiTokens: IPaginationData<IToken>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Email',
        id: 'email',
        sortable: true,
        sortableId: 'email',
    },
    {
        text: 'Name',
        id: 'name',
        sortable: true,
        sortableId: 'name',
    },
    {
        text: 'Domains',
        id: 'domains',
    },

    {
        text: 'Expired',
        id: 'expires_at',
        align: 'center',
        sortable: true,
        sortableId: 'expires_at',
    },

    {
        text: 'Last used at',
        id: 'last_used_at',
        align: 'center',
        sortable: true,
        sortableId: 'last_used_at',
    },
    {
        text: 'Created',
        id: 'created_at',
        align: 'center',
        sortable: true,
        sortableId: 'created_at',
    },
];
</script>

<style></style>
