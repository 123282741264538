<template>
    <AuthLayout>
        <div
            class="w-full p-6 mt-8 overflow-hidden bg-white shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="text-sm text-center text-gray-500 ">
               To continue using TOPOL.io, you should log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.
            </div>
    
            <Sessions :sessions="sessions" /> 

            <div class="flex flex-col items-center mt-10">
                <ConfirmsPassword 
                    :skip-confirmation="true"
                    title="Log Out Other Browser Sessions"
                    button="Log Out Other Browser Sessions" 
                    content="Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices."
                    @confirmed="logoutOtherBrowserSessions"
                >
                    <AtomicButton
                        size="lg"
                        >
                        Log Out Other Browser Sessions
                    </AtomicButton>
                </ConfirmsPassword>
            </div>
        </div>

        <div class="text-sm text-center text-gray-500 mt-11">
            Changed your mind?

            <inertia-link :href="route('login')">
                <span
                    class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    >Sign in with different account!</span
                >
            </inertia-link>
        </div>
    </AuthLayout>
</template>

<script setup lang="ts">
import { useForm } from '@inertiajs/vue3';
import route from 'ziggy-js';

const props = defineProps({
    sessions: Array,
    email: String,
});

const form = useForm({
    email: props.email,
    password: '',
});

const logoutOtherBrowserSessions = (password: string) => {
    form.password = password;

    form.delete(route('auth.other-browser-sessions.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                    title: 'Session logged out',
                    text: 'Your other browser sessions have been logged out.',
                    type: 'success',
                });

            router.get('/login');
        },
        onError: () => {
            create({
                title: 'Error',
                text: 'An error occurred while logging out of your other browser sessions. Please try again.',
                type: 'error',
            });
        },
        onFinish: () => form.reset(),
    });
};
</script>

