<template>
    <div class="relative">
        <div class="flex items-center justify-between">

            <label
                v-if="label"
                role="label"
                :for="id"
                class="relative pr-3 pb-[3px] text-sm font-medium leading-normal text-gray-700"
                :class="{ 'text-gray-400': disabled }"
            >
                {{ label }}
                <svg
                    v-if="required && !suppressRequiredIcon"
                    class="absolute top-0 right-0 w-2 h-2"
                    data-testid="input-required-indicator"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.21731 8H4.78269L4.64563 5.06944L7.21371 6.65972L8 5.34028L5.27322 4L8 2.65972L7.21371 1.34028L4.64563 2.93056L4.78269 0H3.21731L3.35437 2.93056L0.786294 1.34028L0 2.65972L2.72678 4L0 5.34028L0.786294 6.65972L3.35437 5.06944L3.21731 8Z"
                        fill="#E53E3E"
                    />
                </svg>
            </label>

            <span
                v-if="secondarylabel"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm leading-normal text-gray-500"
                >{{ secondarylabel }}</span
            >

            <InputCount
                v-if="!secondarylabel && textCount"
                :count="modelValue.toString().length"
                :max="textCountMax"
            />

            <span
                v-if="headeraction"
                data-testid="input-secondary-label"
                class="pb-[3px] text-sm font-medium leading-normal"
                :class="{
                    'text-gray-400 ': disabled,
                    'cursor-pointer text-topol-blue-500 hover:text-topol-blue-600':
                        !disabled,
                }"
                @click="!disabled ? $emit('headeraction') : null"
                >{{ headeraction }}</span
            >
        </div>

        <div class="relative">
            <div
                v-if="hasIconLeft"
                data-testid="icon-left-slot"
                class="absolute left-0 top-2.5 ml-2 flex h-5 w-5 items-center"
                :class="{
                    'text-red-600': error && !disabled,
                    'text-gray-500': !error && !disabled,
                    'text-gray-300': disabled,
                    'text-gray-600': focus && !error && !disabled,
                }"
            >
                <slot name="icon-left" />
            </div>

            <div
                v-if="hasIconRight"
                data-testid="icon-right-slot"
                class="absolute right-0 top-2.5 mr-2 flex h-5 w-5 items-center"
                :class="{
                    'text-red-600': error && !disabled,
                    'text-gray-500': !error && !disabled,
                    'text-gray-300': disabled,
                    'text-gray-600': focus && !error && !disabled,
                }"
            >
                <slot name="icon-right" />
            </div>

            <input
                :id="id"
                ref="input"
                :value="modelValue"
                :type="type"
                :readonly="readonly"
                :required="required"
                :disabled="disabled"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :name="name"
                class="block w-full h-10 px-3 text-sm transition duration-100 ease-in-out bg-white border rounded-md shadow-button focus:outline-none disabled:cursor-not-allowed disabled:text-gray-300 disabled:placeholder-gray-300 disabled:shadow-none disabled:hover:bg-white"
                :class="{
                    'border-topol-alert-100 text-topol-alert-100 placeholder-topol-red-300 ':
                        error,
                    'border-gray-300 text-gray-600 placeholder-gray-400 hover:bg-gray-50 focus:border-blue-400 focus:text-gray-800':
                        !error && !readonly,
                    'pl-8': hasIconLeft,
                    'cursor-not-allowed border-gray-300 bg-gray-50 text-gray-400':
                        readonly,
                    'pr-8': hasIconRight,
                }"
                @focus="focus = true"
                @blur="handleBlur"
                @input="onInput"
            />
            <div
                v-if="hasHelpSlot && !error"
                class="absolute mt-[3px] text-xs leading-5"
                :class="{
                    'text-gray-400': disabled,
                    'text-gray-500': !disabled,
                }"
            >
                <slot name="help" />
            </div>

            <div
                v-if="error"
                class="absolute mt-[3px] flex items-center text-topol-alert-100"
            >
                <svg
                    data-testid="input-error-icon-indicator"
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                    />
                </svg>

                <div
                    data-testid="input-error-message"
                    class="ml-2 text-xs leading-5"
                >
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, nextTick } from 'vue';

const props = defineProps({
    error: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    secondarylabel: {
        type: String,
        default: '',
    },
    headeraction: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },

    required: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: '',
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    suppressRequiredIcon: {
        type: Boolean,
        default: false,
    },
    autocomplete: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        default: '',
    },
    textCount: {
        type: Boolean,
        default: false,
    },
    textCountMax: {
        type: Number,
        default: 0,
    },
});

const slots = useSlots();

const emit = defineEmits(['update:modelValue', 'blur', 'headeraction']);

const focus = ref(false);

const id = props.name + Math.floor(Math.random() * 100).toString();

const hasIconLeft = computed(() => {
    return !!slots['icon-left'];
});

const hasIconRight = computed(() => {
    return !!slots['icon-right'];
});

const hasHelpSlot = computed(() => {
    return !!slots.help;
});

const handleBlur = () => {
    emit('blur');
    focus.value = false;
};

const onInput = (e: Event) => {
    const inputEvent = e.target as HTMLInputElement;

    if (inputEvent.value !== null) {
        emit('update:modelValue', inputEvent.value);
    }
};

const input = ref<HTMLInputElement>();

onMounted(async () => {
    if (input.value !== undefined && props.autofocus) {
        await nextTick();
        input.value.focus();
    }
});
</script>

<style></style>
