<template>
    <div
        class="flex flex-col items-start justify-between md:flex-row"
        :class="{
            'items-stretch': !invoiceDetails,
            'items-start': invoiceDetails,
        }"
    >
        <div
            class="w-full rounded-md border border-gray-300 md:mr-2 md:w-1/2 md:max-w-[462px] lg:mr-4 lg:w-full"
        >
            <PaymentMethods
                class="p-6"
                :billing-address-type="preparedSubscription.subscription_plan.type"
                @select-payment-method="handleSelectPaymentMethod"
                @loading="paymentMethodsLoading = $event"
            />
          
            <div class="flex flex-col lg:flex-row lg:justify-between">

                <div v-show="!paymentMethodsLoading" class="w-full text-left">
                    <div
                        class="p-6 border-t border-gray-300"
                    >
                        <CheckboxInput
                            v-model:checked="invoiceDetails"
                            size="lg"
                            name="invoiceDetails"
                            label="Add invoice details"
                        />
                    </div>


                    <InvoiceDetails 
                        v-if="invoiceDetails" 
                        :billing-address="billingAddress" 
                        :countries="countries"
                        @updated-billing="emit('updatedBilling')"
                        @loading-invoice-details="loadingInvoiceDetails = $event"
                    />
                </div>
            </div>
        </div>

        <div
            class="mt-6 w-full rounded-md border border-blue-200 bg-blue-100 px-6 pt-6 pb-5 md:mt-0 md:ml-2 md:w-1/2 md:max-w-[418px] md:px-9 lg:ml-4 lg:w-full max-h-[380px]"
        >
            <h4
                class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
            >
                Summary
            </h4>

            <div class="border-b border-blue-200">
                <div class="mt-4 flex items-start justify-between pb-[14px]">
                    <div>
                        <p
                            class="text-sm font-medium leading-normal text-gray-800"
                        >
                            Topol
                            {{ preparedSubscription.subscription_plan.name }}
                        </p>
                        <p
                            v-if="
                                preparedSubscription.subscription_plan.type ===
                                    'pro' && !personal
                            "
                            class="mt-[3px] text-sm leading-normal text-gray-500"
                        >
                            with
                            {{
                                preparedSubscription.additional_users_to_pay_count >=
                                0
                                    ? preparedSubscription.additional_users_to_pay_count
                                    : 0
                            }}
                            team seats (+3 seats free)
                        </p>
                        <p
                            class="mt-[3px] text-sm leading-normal text-gray-500"
                        >
                            <span
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan === 'plugin_50'
                                "
                            >
                                50
                            </span>
                            <span
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan === 'plugin_1000'
                                "
                            >
                                1000
                            </span>
                            <span
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .braintree_plan ===
                                        'plugin_unlimited_monthly' ||
                                    preparedSubscription.subscription_plan
                                        .braintree_plan ===
                                        'plugin_unlimited_yearly'
                                "
                            >
                                Unlimited
                            </span>
                            <span
                                v-if="
                                    preparedSubscription.subscription_plan
                                        .type === 'plugin'
                                "
                            >
                                users included in plan.
                            </span>
                        </p>

                        <p
                            class="mt-[3px] text-sm leading-normal text-gray-500"
                        >
                            {{
                                preparedSubscription.subscription_plan
                                    .period === 12
                                    ? 'Annual'
                                    : 'Monthly'
                            }}
                            plan renews
                            {{ format(new Date(date), 'MMMM d, yyyy') }}
                        </p>
                    </div>

                    <div
                        class="text-sm font-medium leading-normal text-gray-800"
                    >
                        ${{ preparedSubscription.real_total_without_sale }}/{{
                            preparedSubscription.subscription_plan.period === 1
                                ? 'month'
                                : 'year'
                        }}
                    </div>
                </div>

                <!-- <div
                    v-if="preparedSubscription.discount !== 0"
                    class="-mt-1 flex justify-between pb-[14px] text-sm font-bold text-gray-800"
                >
                    <p>BLACK FRIDAY</p>
                    <p>-${{ preparedSubscription.real_discount_with_tax }}</p>
                </div> -->
            </div>

            <div class="mt-5">
                <div class="flex justify-between">
                    <div
                        class="text-lg font-medium leading-normal tracking-tighter text-gray-800"
                    >
                        Total due today
                    </div>
                    <div class="text-lg font-bold text-gray-800">
                        ${{ preparedSubscription.total / 100 }}
                    </div>
                </div>
                <div class="mt-6">
                    <button
                        :disabled="
                            loading ||
                            loadingInvoiceDetails ||
                            !selectedCard.token ||
                            paymentMethodsLoading
                        "
                        class="flex h-15 w-full items-center justify-center rounded-md bg-[#4299E1] text-center text-lg font-bold text-white transition duration-200 ease-in-out hover:shadow-button focus:ring-2 focus:ring-[#4299E1] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-white"
                        @click="handleSubmit"
                    >
                        <div class="relative">
                            Subscribe now

                            <LoadingSpinner
                                v-if="loading || loadingInvoiceDetails"
                                size="medium"
                                class="absolute top-0 right-[-40px] hidden sm:block"
                            />
                        </div>
                    </button>

                    <div class="pb-3 mt-5 text-xs text-gray-500">
                        You’ll be charged for individual products at the end of
                        their free trial(s). Your subscription will continue
                        until you cancel.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { create } from '@composables/useNotifications';

import axios from 'axios';
import { PropType } from 'vue';
import route from 'ziggy-js';

import BillingAddress from '@/types/Subscription/BillingAddress';
import ISelectable from '@/types/Atomic/ISelectable';
import PreparedSubscription from '@/types/Subscription/PreparedSubscription';
import { router } from '@inertiajs/vue3';
import {ICreditCardPaymentMethod, IPaymentMethod} from '@/types/Subscription/IPaymentMethod';
import { add, format } from 'date-fns';
import IApiResponse from '@/types/IApiResponse';
import {
    ThreeDSecure,
    ThreeDSecureVerifyOptions,
} from 'braintree-web/three-d-secure';

import {
    client as BraintreeClient,
    threeDSecure as BraintreeThreeDSecure,
    Client,
} from 'braintree-web';

interface ISelectedCard {
    token: string;
    type: 'Card' | 'PayPal' | '';
}

const props = defineProps({
    billingAddress: {
        required: true,
        type: Object as PropType<BillingAddress>,
    },
    subscriptionPlanId: {
        required: false,
        type: Number,
        default: null,
    },
    preparedSubscription: {
        required: true,
        type: Object as PropType<PreparedSubscription>,
    },
    countries: {
        required: true,
        type: Array as PropType<ISelectable[]>,
    },
    seats: {
        required: false,
        type: Number,
        default: 0,
    },
    personal: {
        required: false,
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['success', 'error', 'updatedBilling']);

const paymentMethodsLoading = ref(true);
const invoiceDetails = ref(false);
const loadingInvoiceDetails = ref(false);
const loading = ref(false);
const selectedCard = ref({ token: '', type: '' } as ISelectedCard);
const threeDSecureParameters = ref<ThreeDSecureVerifyOptions>();
const threeDSecureInstance = ref<ThreeDSecure>();
const gatewayInstance = ref<Client>();
const paymentGatewayToken = ref('');

const date = computed(() => {
    if (props.preparedSubscription.subscription_plan.period === 1) {
        return add(new Date(), {
            months: 1,
        });
    } else
        return add(new Date(), {
            years: 1,
        });
});

const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    selectedCard.value = {
        token: paymentMethod.token,
        type: (paymentMethod as ICreditCardPaymentMethod).bin !== undefined ? 'Card' : 'PayPal',
    };
};

const handleSubmit = async () => {
    try {
        loading.value = true;
        await buySubscription();
    } catch (err) {
        () => undefined;
    } finally {
        loading.value = false;
    }
};

const buySubscription = async () => {
    if (selectedCard.value === null) {
        return;
    }
    
    await createClientInstance();

    let verifyCardResponse;
    
    if (selectedCard.value.type === 'Card') {
        try {
            const response = await axios.post(
                route('braintree.payment-method.nonce.store'),
                {
                    token: selectedCard.value.token,
                }
            );

            if (response.status !== 200 || response.data.success !== true) {
                throw new Error('Card verification failed');
            } else {
                const nonce = response.data.data.nonce;

                verifyCardResponse = await verifyCard(nonce);

                if (verifyCardResponse === undefined) {
                    throw new Error('Card verification failed');
                }
                if (
                    verifyCardResponse.liabilityShifted === false &&
                    verifyCardResponse.liabilityShiftPossible === true
                ) {
                    throw new Error('Card verification failed');
                }
            }
        } catch (err) {
            create({
                title: 'Subscription not created',
                text: 'There was an error with card verification.',
                type: 'error',
            });
            return;
        }
    }
    
    return new Promise((resolve, reject) => {
        router.post(
            route('subscriptions.store'),
            {
                subscription_plan_id: props.subscriptionPlanId,
                type: selectedCard.value.type,
                nonce: verifyCardResponse ? verifyCardResponse.nonce : '',
                token: selectedCard.value.token,
                additional_users: props.seats,
            },
            {
                preserveScroll: true,
                onSuccess: (response) => {
                    emit('success');
                    create({
                        title: 'Subscription created',
                        text: 'Your subscription has been successfully created.',
                        type: 'success',
                    });
                    fetchBanners();
                    resolve(response);
                },
                onError: (response) => {
                    emit('error', response.message);
                    create({
                        title: 'Subscription not created',
                        text:
                            'Following error occurred "' +
                            response.message +
                            '" when creating your subscription.',
                        type: 'error',
                    });
                    reject(response);
                },
            }
        );
    });
};

const verifyCard = async (nonce: string) => {
    try {
        await getThreeDSecureParameters(nonce);

        await initThreeDSecureClient();

        if (
            threeDSecureInstance.value === undefined ||
            threeDSecureParameters.value === undefined
        ) {
            throw new Error('Undefined 3d secure instance');
        }

        //For some unknown reason braintree only accepts zero as string...
        if (threeDSecureParameters.value.amount === 0) {
            //@ts-ignore
            threeDSecureParameters.value.amount = '0';
        }

        return threeDSecureInstance.value.verifyCard(
            threeDSecureParameters.value
        );
    } catch (err) {
        () => undefined;
    }
};

const getThreeDSecureParameters = async (nonce: string) => {
    const response = await axios.post<IApiResponse<ThreeDSecureVerifyOptions>>(
        route('braintree.three-d-secure-parameters'),
        {
            billing_address_type:
                props.preparedSubscription.subscription_plan.type,
            amount: props.preparedSubscription.total,
            card_token: selectedCard.value.token,
        }
    );

    threeDSecureParameters.value = response.data.data;
    threeDSecureParameters.value.amount =
        threeDSecureParameters.value.amount / 100;
    threeDSecureParameters.value.nonce = nonce;

    //@ts-ignore
    threeDSecureParameters.value.onLookupComplete = (data, next) => {
        next();
    };
};

const initThreeDSecureClient = async () => {
    try {
        threeDSecureInstance.value = await BraintreeThreeDSecure.create({
            version: 2,
            client: gatewayInstance.value,
        });
    } catch (err) {
        throw new Error('ThreeDSecure init error!');
    }
};

const createClientInstance = async () => {
    if (gatewayInstance.value) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await gatewayInstance.value.teardown(() => {});
    }

    if (threeDSecureInstance.value) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await threeDSecureInstance.value.teardown(() => {});
    }

    await getPaymentGatewayToken();

    try {
        gatewayInstance.value = await BraintreeClient.create({
            authorization: paymentGatewayToken.value,
        });
    } catch (err) {
        () => undefined;
    }
};

const getPaymentGatewayToken = async () => {
    try {
        const response = await axios.post<IApiResponse<string>>(
            route('braintree.payment-gateway-token'),
            {
                billing_address_type:
                    props.preparedSubscription.subscription_plan.type,
            }
        );

        paymentGatewayToken.value = response.data.data;
    } catch (err) {
        () => undefined;
    }
};


const fetchBanners = () => {
    const { fetchBanners } = useBanners();
    fetchBanners();
};
</script>

<style scoped>
div.braintree-hosted-fields-focused {
    border: 1px solid #63b3ed;
}

div.braintree-hosted-fields-invalid {
    border: 1px solid #e02424;
}
</style>