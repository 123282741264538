<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 676 296"
        style="enable-background: new 0 0 676 296"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    d="M359.8,140.8H388l33.1,83.7l32.9-83.7h27.5v114.9H461v-87.6l-36.2,87.6h-9.9l-35-87.6v87.6h-20.1V140.8z"
                />
                <path
                    d="M552.5,245.4c-6.4,8.5-15.7,11.8-26.1,11.8c-15.8,0-28.2-8-28.2-24.5c0-23.3,24.6-27,44.2-27h9.9v-1.3
			c0-12-2.8-19.6-16.4-19.6c-8.2,0-15.7,4.2-18.7,12l-15.6-6.7c5.6-14.8,21.3-20.2,36.3-20.2c19.9,0,34.3,9.6,34.3,30.9v54.9h-19.8
			V245.4z M542.2,218.1c-9.3,0-24,1.1-24,13.8c0,8.1,5.9,11.1,13.4,11.1c10.2,0,20.7-5.3,20.7-16.7v-8.2H542.2z"
                />
                <path
                    d="M604.1,136.1c6.5,0,11.7,4.8,11.7,11.5c0,6.6-5,11.4-11.6,11.4c-6.5,0-12-4.9-12-11.5
			C592.2,140.8,597.6,136.1,604.1,136.1z M594,171.8h20.1v83.9H594V171.8z"
                />
                <path d="M637.4,137.6l20.1-8.7v126.8h-20.1V137.6z" />
            </g>
            <g>
                <path
                    d="M360,104.4L397,51h-33.2V40.5h50.6v5.2l-36.1,53.4h35v10.5H360V104.4z"
                />
                <path
                    d="M446,58c15.4,0,27.2,10.8,27.2,26.3c0,15.9-12.4,26.4-27.7,26.4c-15.4,0-27.8-10.7-27.8-26.1C417.7,68.6,430.8,58,446,58z
			M460.2,84.3c0-9.3-4.7-17.1-14.7-17.1c-10.2,0-14.3,8.1-14.3,17.4c0,9,4.8,16.6,14.6,16.6C456,101.3,460.2,93.2,460.2,84.3z"
                />
                <path
                    d="M482.1,36.1h12.1v31c3.3-6.3,9.9-9.1,16.9-9.1c12.1,0,18.2,8.5,18.2,20v31.7H517V79.2c0-7.3-3.8-11.3-10.8-11.3
			c-7.8,0-12.1,4.8-12.1,12v29.7h-12.1V36.1z"
                />
                <path
                    d="M565.4,58c15.4,0,27.2,10.8,27.2,26.3c0,15.9-12.4,26.4-27.7,26.4c-15.4,0-27.8-10.7-27.8-26.1
			C537.1,68.6,550.2,58,565.4,58z M579.6,84.3c0-9.3-4.7-17.1-14.7-17.1c-10.2,0-14.3,8.1-14.3,17.4c0,9,4.8,16.6,14.6,16.6
			C575.3,101.3,579.6,93.2,579.6,84.3z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    fill="#F9B21D"
                    d="M235.4,198.6c-4.7,0-8.5-3.8-8.5-8.5v-46.2c0-4.8-3.9-8.7-8.7-8.7H82.6c-4.8,0-8.7,3.9-8.7,8.7v9.7
			c0,4.7-3.8,8.5-8.5,8.5c-4.7,0-8.5-3.8-8.5-8.5v-9.7c0-14.1,11.5-25.7,25.7-25.7h135.6c14.1,0,25.7,11.5,25.7,25.7v46.2
			C243.9,194.8,240.1,198.6,235.4,198.6z"
                />
            </g>
            <g>
                <path
                    fill="#226DB4"
                    d="M269.7,292.9H31.1c-14.1,0-25.7-11.5-25.7-25.7v-67.8c0-4.7,3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5v67.8
			c0,4.8,3.9,8.7,8.7,8.7h238.6c4.8,0,8.7-3.9,8.7-8.7V123.4c0-2.7-1.2-5.1-3.3-6.8L155.8,21.9c-3.2-2.5-7.6-2.5-10.8,0L25.7,116.6
			c-2.1,1.6-3.3,4.1-3.3,6.8v29.1c0,3.2,1.8,6.1,4.6,7.7l119.3,63.5c2.6,1.4,5.6,1.4,8.2,0l76.9-41c4.1-2.2,9.3-0.6,11.5,3.5
			c2.2,4.1,0.6,9.3-3.5,11.5l-76.9,41c-7.6,4-16.6,4-24.1,0L19,175.1c-8.4-4.5-13.6-13.2-13.6-22.7v-29.1c0-7.9,3.6-15.2,9.7-20.1
			L134.4,8.6c4.5-3.6,10.2-5.6,16-5.6l0,0c5.8,0,11.4,2,16,5.6l119.3,94.7c6.2,4.9,9.7,12.2,9.7,20.1v143.9
			C295.4,281.4,283.9,292.9,269.7,292.9z"
                />
            </g>
        </g>
    </svg>
</template>
