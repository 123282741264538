<template>
    <div>
        <ul class="mt-4 flex flex-row flex-wrap gap-[6px]">
            <li
                v-for="item in colorsDefault"
                :key="item"
                class="cursor-pointer transition-all duration-200 hover:scale-[1.4]"
                @click="selectColor(item)"
            >
                <div
                    v-if="item === 'rgba(0,0,0,0)'"
                    :style="{ background: `url(${imgAlphaBase64})` }"
                    class="h-4 w-4 rounded-[3px]"
                />
                <div
                    v-else
                    :style="{ background: item }"
                    class="h-4 w-4 rounded-[3px]"
                />
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import useColorPicker from './useColorPicker';

defineProps({
    color: {
        type: String,
        default: '#000000',
    },
    colorsDefault: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(['selectColor']);

const { createAlphaSquare } = useColorPicker();

const imgAlphaBase64 = ref('');

onMounted(() => {
    imgAlphaBase64.value = createAlphaSquare(4).toDataURL();
});

const selectColor = (color: string) => {
    emit('selectColor', color);
};
   

</script>

<style scoped>
.colors .item {
    transition: all 0.1s;
}

.colors .item:hover {
    transform: scale(1.4);
}
</style>
