<template>
    <Modal size="full" @close="emit('close')">
        <template #header>
            <h2 v-if="!templateLoading" class="text-lg">
                {{ templatePreview?.name }}
            </h2>
        </template>

        <template #body>
            <div class="bg-white border-b border-gray-300">
                <div
                    class="relative flex flex-col-reverse items-center justify-end lg:flex-row lg:justify-center"
                >
                    <div
                        class="my-3 flex h-8 w-[267px] items-center justify-between rounded-md bg-gray-100 text-xs font-medium text-gray-500"
                    >
                        <button
                            class="flex items-center justify-center w-full h-full px-2 transition duration-200 ease-in-out rounded-l-md focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'mobile',
                            }"
                            @click="resolution = 'mobile'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'mobile',
                                    'text-gray-400': resolution !== 'mobile',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 18H12.01M8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V5C18 4.46957 17.7893 3.96086 17.4142 3.58579C17.0391 3.21071 16.5304 3 16 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Mobile</p>
                        </button>

                        <button
                            class="flex items-center justify-center w-full h-full px-2 transition duration-200 ease-in-out rounded-r-md focus:outline-none"
                            :class="{
                                'bg-gray-200 text-gray-600':
                                    resolution === 'desktop',
                            }"
                            @click="resolution = 'desktop'"
                        >
                            <svg
                                class="mr-2"
                                :class="{
                                    'text-gray-600': resolution === 'desktop',
                                    'text-gray-400': resolution !== 'desktop',
                                }"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 13H21M9.75 17L9 20L8 21H16L15 20L14.25 17H9.75ZM5 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17Z"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p>Desktop</p>
                        </button>
                    </div>
                    <div
                        class="flex flex-wrap-reverse items-center gap-2 px-2 mt-2 sm:gap-0 lg:absolute lg:right-0 lg:top-2 lg:mt-0 lg:flex-nowrap lg:px-0"
                    >
                        <AtomicButton
                            color="white"
                            class="mr-4"
                            @click="emit('openTemplateModal')"
                            >Go back</AtomicButton
                        >
                        <AtomicButton
                            color="yellow"
                            class="lg:mr-6 umami--click--create-template-from-premade"
                            @click="createNewTemplate"
                        >
                            <template #icon>
                                <div>
                                    <svg
                                        class="mr-2 h-[17px] w-[17x]"
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.16732 3.16664H3.00065C2.55862 3.16664 2.1347 3.34223 1.82214 3.65479C1.50958 3.96736 1.33398 4.39128 1.33398 4.83331V14C1.33398 14.442 1.50958 14.8659 1.82214 15.1785C2.1347 15.491 2.55862 15.6666 3.00065 15.6666H12.1673C12.6093 15.6666 13.0333 15.491 13.3458 15.1785C13.6584 14.8659 13.834 14.442 13.834 14V9.83331M12.6557 1.98831C12.8094 1.82912 12.9933 1.70215 13.1966 1.6148C13.4 1.52746 13.6187 1.48148 13.84 1.47956C14.0613 1.47763 14.2807 1.5198 14.4856 1.6036C14.6904 1.6874 14.8765 1.81116 15.033 1.96765C15.1895 2.12414 15.3132 2.31022 15.397 2.51505C15.4808 2.71988 15.523 2.93934 15.5211 3.16064C15.5191 3.38194 15.4732 3.60064 15.3858 3.80398C15.2985 4.00732 15.1715 4.19123 15.0123 4.34497L7.85732 11.5H5.50065V9.14331L12.6557 1.98831Z"
                                            stroke="#252F3F"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </template>
                            Start with template
                        </AtomicButton>
                    </div>
                </div>
            </div>
            <iframe
                v-if="!templateLoading"
                class="h-[800px] rounded-t bg-white sm:rounded-t-none"
                :srcdoc="templatePreview?.html"
                :class="{
                    'mx-auto w-full sm:w-[550px] md:w-[490px] xl:w-[670px] 2xl:w-[700px]':
                        resolution === 'desktop',
                    'mx-auto border-l border-r border-gray-300 sm:w-[375px]':
                        resolution === 'mobile',
                }"
            ></iframe>

            <div
                v-if="templateLoading"
                class="flex h-[800px] justify-center rounded-t bg-white sm:rounded-t-none"
            >
                <LoadingSpinner size="large" />
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { create } from '@composables/useNotifications';

import Modal from '@atomic/Modals/Modal.vue';
import { router } from '@inertiajs/vue3';
import route from 'ziggy-js';
import IPremadeTemplate from '@/types/Templates/IPremadeTemplate';
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import axios from 'axios';
import LoadingSpinner from '@/components/Atomic/LoadingSpinner/LoadingSpinner.vue';
import IMarketplaceTemplate from '@/types/Templates/IMarketplaceTemplate';

const props = defineProps<{
    currentFolderId?: number;
    template: IPremadeTemplate | IMarketplaceTemplate;
    templateType: 'premade-templates' | 'marketplace-templates';
}>();

const emit = defineEmits<{
    // eslint-disable-next-line no-unused-vars
    (e: 'close'): void;
    // eslint-disable-next-line no-unused-vars
    (e: 'openTemplateModal'): void;
}>();

const templateLoading = ref(false);
const templatePreview = ref();

onMounted(async () => {
    templateLoading.value = true;

    if (props.templateType === 'premade-templates') {
        await getPremadeTemplate();
    } else {
        await getMarketplaceTemplate();
    }
});

const getPremadeTemplate = async () => {
    try {
        const res = await axios.get(
            route('premade-templates.show', {
                premadeTemplate: props.template.id,
            })
        );

        if (res.data.success) {
            templatePreview.value = res.data.data;
        }
    } catch (err) {
        () => undefined;
    } finally {
        templateLoading.value = false;
    }
};

const getMarketplaceTemplate = async () => {
    try {
        const res = await axios.get(
            route('marketplace.bought-templates.show', {
                marketplaceTemplate: props.template.id,
            })
        );

        if (res.data.success) {
            templatePreview.value = res.data.data;
        }
    } catch (err) {
        () => undefined;
    } finally {
        templateLoading.value = false;
    }
};

const createNewTemplate = () => {
    router.post(
        route('templates.store'),
        {
            premade_template_id:
                props.templateType === 'premade-templates'
                    ? props.template.id
                    : undefined,
            marketplace_template_id:
                props.templateType === 'marketplace-templates'
                    ? props.template.id
                    : undefined,
            template_folder_id: props.currentFolderId,
        },
        {
            preserveScroll: true,
            onSuccess: (response) => {
                if (response.component === 'Templates/index') {
                    create({
                        title: 'Template not created',
                        text: 'You must be subscribed to a Pro plan to access this page.',
                        type: 'error',
                    });
                } else {
                    create({
                        title: 'New template created',
                        text: 'Your new template has been successfully created.',
                        type: 'success',
                    });
                }
            },
            onError: (e) => {
                create({
                    title: 'Template not created',
                    text: e.message ?? 'There was an error creating your new template.',
                    type: 'error',
                });
            },
        }
    );
};
const resolution = ref('desktop');
</script>
