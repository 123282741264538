<template>
    <AuthLayout>
        <div
            class="w-full p-6 mt-8 overflow-hidden bg-white shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mt-[2px] mb-8 border-b border-gray-200 pb-5">
                <h1 class="text-xl font-bold text-center text-gray-800">
                    Create new account
                </h1>
            </div>

            <form @submit.prevent="submit">
                <div class="flex flex-col items-center w-full gap-2">
                    <SocialiteButton
                        provider="google"
                        label="Google"
                        url="google.auth.index"
                        :invitation-token="invitationToken"
                        class="w-full"
                    />

                    <SocialiteButton
                        provider="facebook"
                        label="Facebook"
                        url="facebook.auth.index"
                        :invitation-token="invitationToken"
                        class="w-full"
                    />

                    <SocialiteButton
                        provider="github"
                        label="GitHub"
                        url="github.auth.index"
                        :invitation-token="invitationToken"
                        class="w-full"
                    />

                    <SocialiteButton
                        provider="gitlab"
                        label="GitLab"
                        url="gitlab.auth.index"
                        :invitation-token="invitationToken"
                        class="w-full"
                    />
                </div>

                <h5 class="pb-4 mt-4 font-medium text-center text-gray-600">
                    or
                </h5>

                <div class="pt-4 border-t border-gray-300">
                    <TextInput
                        v-model="state.form.email"
                        type="email"
                        autocomplete="email"
                        name="email"
                        required
                        data-testid="email"
                        suppress-required-icon
                        autofocus
                        label="Email address"
                        placeholder="Enter your email address"
                        :error="getError('email')"
                        @blur="v$.form.email.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="v$.form.name.$model"
                        type="text"
                        autocomplete="name"
                        name="name"
                        required
                        suppress-required-icon
                        data-testid="name"
                        label="Your name"
                        placeholder="Enter your name"
                        :error="getError('name')"
                    />
                </div>

                <div class="mt-6">
                    <SelectInput
                        v-model="state.form.country"
                        placeholder="Choose your country of residence"
                        :data="countries"
                        label="Country"
                        required
                        suppress-required-icon
                        data-testid="country"
                        :error="getError('country')"
                        :disabled="countriesLoading"
                        search
                        @blurred="v$.form.country.$touch()"
                    >
                    </SelectInput>
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="state.form.password"
                        type="password"
                        autocomplete="new-password"
                        name="password"
                        required
                        suppress-required-icon
                        label="Set password"
                        data-testid="password"
                        placeholder="Set your password"
                        :error="getError('password')"
                        @blur="v$.form.password.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="state.form.password_confirmation"
                        type="password"
                        autocomplete="new-password"
                        name="password_confirmation"
                        required
                        suppress-required-icon
                        label="Confirm password"
                        data-testid="password-confirm"
                        placeholder="Your password again, please"
                        :error="getError('password_confirmation')"
                        @blur="v$.form.password_confirmation.$touch()"
                    />
                </div>

                <div class="flex flex-col items-center pb-3 mt-10">
                    <MegaButton
                        data-testid="register-button"
                        :disabled="loading"
                        type="submit"
                        >Create account
                    </MegaButton>
                </div>
            </form>
        </div>
        <div class="text-sm text-center text-gray-500 mt-11">
            Already have an account?

            <inertia-link :href="route('login')">
                <span
                    class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                    >Sign in!</span
                >
            </inertia-link>
        </div>

        <!-- First Promoter -->
        <component
            :is="'script'"
            src="https://cdn.firstpromoter.com/fpr.js"
            async
        ></component>
    </AuthLayout>
</template>

<script lang="ts" setup>
import TextInput from '@atomic/Inputs/TextInput.vue';
import route from 'ziggy-js';
import SelectInput from '../../components/Atomic/Inputs/SelectInput.vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { computed, reactive, ref } from 'vue';
import {
    required,
    email,
    maxLength,
    minLength,
    helpers,
    sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { router } from '@inertiajs/vue3';
import MegaButton from '@/components/Atomic/Buttons/MegaButton.vue';
import axios from 'axios';

const props = defineProps({
    email: {
        required: false,
        type: String,
        default: '',
    },
    invitationToken: {
        required: false,
        type: String,
        default: '',
    },
});

const reCaptcha = useReCaptcha();

interface IForm {
    form: {
        name: string;
        email: string;
        password: string;
        password_confirmation: string;
        terms: boolean;
        country: string;
        recaptcha_token: string;
        invitation_token: string;
    };
}

const state: IForm = reactive({
    form: {
        name: '',
        email: props.email,
        password: '',
        password_confirmation: '',
        terms: true,
        country: '',
        recaptcha_token: '',
        invitation_token: props.invitationToken,
    },
});

const passwordRef = computed(() => state.form.password);

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
            maxLength: maxLength(255),
        },
        password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(255),
        },
        password_confirmation: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(255),
            sameAsPassword: helpers.withMessage(
                'Passwords do not match',
                sameAs(passwordRef)
            ),
        },
        country: {
            required,
        },
    },
};

const countries = ref([]);
const v$ = useVuelidate(rules, state);
const errors = ref({});
const loading = ref(false);
const countriesLoading = ref(false);
const dev = ref(false);

onMounted(async () => {
    countriesLoading.value = true;
    const res = await axios.get(route('countries.index'));
    countries.value = res.data.data;
    countriesLoading.value = false;

    //First promoter
    // @ts-ignore
    (function (w) {
        w.fpr =
            w.fpr ||
            function () {
                w.fpr.q = w.fpr.q || [];
                w.fpr.q[arguments[0] == 'set' ? 'unshift' : 'push'](arguments);
            };
    })(window);
    fpr('init', { cid: 'l3vfa0xc' });
    fpr('click');

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('dev') === 'true') {
        dev.value = true;
    }
});

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        let token = undefined;

        try {
            await reCaptcha?.recaptchaLoaded();
            token = await reCaptcha?.executeRecaptcha("register");

            if (token === undefined) {
                throw new Error();
            }
        } catch (e) {
            loading.value = false;
            errors.value = {
                email: "ReCaptcha validation failed.",
            };
            return;
        }
        state.form.recaptcha_token = token;

        router.post(route('register'), state.form, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onSuccess: () => {
                //First promoter
                // @ts-ignore
                window.fpr('referral', { email: state.form.email });
            },
            onFinish: () => {
                loading.value = false;
                state.form.password = '';
                state.form.password_confirmation = '';
                v$.value.$reset();
            },
        });
    }
};

const getError = (field: string) => {
    // @ts-ignore
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
