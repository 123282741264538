<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div
        class="flex items-center justify-between py-3 bg-white border-t border-gray-200"
    >
        <!-- MOBILE -->
        <div class="flex space-x-3 lg:hidden">
            <TButton
                v-if="props.items.current_page - 1 > 0"
                color="white"
                @click="changePage(props.items.current_page - 1)"
                >Previous</TButton
            >
            <TButton
                v-if="props.items.next_page !== null"
                color="white"
                @click="changePage(props.items.next_page)"
                >Next</TButton
            >

            <div class="flex items-center">
                <p class="mr-2 text-sm text-gray-700">
                    Showing
                    {{ ' ' }}
                    <span class="font-medium"> {{ showingRecords }}</span>
                    {{ ' ' }}
                    to
                    {{ ' ' }}
                    <span class="font-medium">{{ pageRecords }}</span>
                </p>
                <p class="text-sm">
                    {{ ' ' }}
                    of
                    {{ ' ' }}
                    <span class="font-medium">{{ items.total_records }}</span>
                    {{ ' ' }}
                    results
                </p>
            </div>
        </div>

        <!-- DESKTOP -->
        <div
            class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-between"
        >
            <div class="flex items-center">
                <p class="mr-2 text-sm text-gray-700">
                    Showing
                    {{ ' ' }}
                    <span class="font-medium"> {{ showingRecords }}</span>
                    {{ ' ' }}
                    to
                    {{ ' ' }}
                    <span class="font-medium">{{ pageRecords }}</span>
                </p>
                <p class="text-sm">
                    {{ ' ' }}
                    of
                    {{ ' ' }}
                    <span class="font-medium">{{ items.total_records }}</span>
                    {{ ' ' }}
                    results
                </p>
            </div>
            <div>
                <Pagination
                    :items="items"
                    @change-page="changePage"
                ></Pagination>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import IPaginationData from '@/types/Atomic/IPaginationData';
import { router } from '@inertiajs/vue3';
import TButton from '../../Buttons/AtomicButton.vue';

import { IDataTableItem } from '../types/IDataTableItem';
import Pagination from './Pagination/Pagination.vue';
import buildQueryFromObject from '../utils/buildQueryFromObject';
import { useBrowserLocation } from '@vueuse/core';

// eslint-disable-next-line no-undef
const props = defineProps<{
    items: IPaginationData<IDataTableItem>;
    paramState: Record<string, unknown>;
}>();

const pageRecords = computed((): number => {
    if (props.items.total_records < props.items.per_page) {
        return props.items.total_records;
    }

    if (
        props.items.total_records <
        props.items.current_page * props.items.per_page
    ) {
        return props.items.total_records;
    }

    return props.items.current_page * props.items.per_page;
});

const showingRecords = computed((): number => {
    if (props.items.total_records === 0) {
        return 0;
    }

    return (
        props.items.current_page * props.items.per_page -
        props.items.per_page +
        1
    );
});

const changePage = (to: number | undefined) => {
    if (to === undefined) {
        return;
    }

    const payload = {
        current_page: to,
        ...props.paramState,
    };
    const currentRoute =
        useBrowserLocation().value.origin +
        (useBrowserLocation().value.pathname ?? '');
    if (currentRoute) {
        router.get(
            currentRoute + '?' + buildQueryFromObject(payload),
            undefined,
            {
                preserveState: true,
                preserveScroll: true,
            }
        );
    }
};
</script>
