<template>
    <MetricsTabs tab-key="socialite">
        <DataTable
            class="w-full mt-10"
            title="Socialite"
            :headers="headers"
            :items="data"
        >
        </DataTable>
    </MetricsTabs>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import ISocialiteMetrics from '@/types/Metrics/ISocialiteMetrics';

defineProps<{
    data: IPaginationData<ISocialiteMetrics>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Title',
        id: 'title',
    },
    {
        text: 'Count',
        id: 'count',
    },
];
</script>

<style></style>
