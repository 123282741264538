<template>
    <Modal size="md" @close="$emit('close')">
        <template #header>
            <div class="text-gray-600">Webhook Settings</div>
        </template>

        <template #body>
            <div class="p-6">
                <div class="flex flex-col">
                    <TextInput
                        v-model="v$.form.url.$model"
                        class="w-full sm:w-80"
                        label="URL"
                        placeholder="e.g. https://example.com/webhook"
                        :error="getError('url')"
                    />

                    <Toggle 
                        v-model="state.form.enabled"
                        class="mt-6" 
                    >
                        <span>
                            Automatically send data when a template is saved
                        </span>
                    </Toggle>

                   <div 
                    v-if="webhook" 
                    class="mt-4" 
                    @click="showSecretKeyModal = true"
                   >
                        <AtomicButton
                            color="white"
                            size="md"
                        >
                            Show Secret Key
                        </AtomicButton>
                   </div>
                </div>
            </div>
            
        </template>
        <template #footer>
            <div class="flex justify-between p-6 border-t border-gray-200">
                <AtomicButton
                    data-cy="create-token-cancel"
                    color="white"
                    size="md"
                    @click="$emit('close')"
                >
                    Cancel
                </AtomicButton>
                <AtomicButton
                    :disabled="loading || v$.form.$invalid"
                    size="md"
                    color="yellow"
                    @click="submit"
                >
                    <span v-if="webhook">
                        Update
                    </span>
                    <span v-else>
                        Create
                    </span>
                </AtomicButton>
            </div>
        </template>

    </Modal>
    <WebhooksSecretKeyModal
        v-if="showSecretKeyModal"
        :webhook="webhook"
        @close="showSecretKeyModal = false"
    />
</template>

<script lang="ts" setup>
import IWebhook from '@/types/Webhooks/IWebhook';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, url, } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';

interface IForm {
    form: {
        url: string;
        enabled: boolean;
    };
}

const props = defineProps({
    webhook: {
        type: Object as PropType<IWebhook>,
        default: null,
    },
});

const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});
const showSecretKeyModal = ref(false);

const state: IForm = reactive({
    form: {
        url: props.webhook?.url || '',
        enabled: props.webhook?.enabled || false,
    },
});

const rules = {
    form: {
        url: {
            required,
            maxLength: maxLength(5000),
            url,
        },
    },
};

const v$ = useVuelidate(rules, state);


const submit = async () => {
    if (v$.value.form.$invalid) {
        return;
    }

    if(props.webhook){
        updateWebhook();
    } else {
        createWebhook();
    }
};

const createWebhook = () => {
    loading.value = true;

    router.post(route('webhooks.template-saved.store'), { 
        url: state.form.url,
        enabled: state.form.enabled,
    }, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Webhook created',
                text: 'Your webhook has been created successfully.',
                type: 'success',
            });
        },
        onError: (err) => {
            errors.value = err;
            create({
                title: 'Error',
                text: 'An error occurred while creating the webhook.',
                type: 'error',
            });
        },
        onFinish: () => {
            loading.value = false;
            emit('close')
        },
    })
};

const updateWebhook = () => {
    loading.value = true;

    router.put(route('webhooks.template-saved.update', {
        webhook: props.webhook.id,
    }), {
        url: state.form.url,
        enabled: state.form.enabled,
    }, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: 'Webhook updated',
                text: 'Your webhook has been updated successfully.',
                type: 'success',
            });
        },
        onError: (err) => {
            errors.value = err;
            create({
                title: 'Error',
                text: 'An error occurred while updating the webhook.',
                type: 'error',
            });
        },
        onFinish: () => {
            loading.value = false;
            emit('close')
        },
    })
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};
</script>
