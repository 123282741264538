<template>
    <div class="h-64 w-96">
        <canvas ref="chartCtx" width="250" height="250"></canvas>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';

const chartCtx = ref<HTMLCanvasElement>();

onMounted(() => {
    const myChart = new Chart(chartCtx.value, {
        type: 'bar',
        data: {
            labels: [
                '01.01.2021',
                '01.02.2021',
                '01.03.2021',
                '01.04.2021',
                '01.05.2021',
                '01.06.2021',
            ],
            datasets: [
                {
                    label: 'GB of data transfered',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',

                    borderColor: 'rgba(54, 162, 235, 1)',

                    borderWidth: 1,
                },
            ],
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
        },
    });

    myChart.render();
});
</script>
<style></style>
