<template>
    <TeamTabs
        :selected-team="selectedTeam"
        tab-key="payment-requests"
    >
         <DataTable
             class="mt-12"
             :headers="headers"
             :items="paymentRequests"
             title="Payment requests"
             searchable
             invoice-input
         >
             <template #item-team_id="{ data }">
                 <div v-if="data" class="flex justify-center">
                     <inertia-link
                         :href="route('kiosk.teams.members.index', {team: data as number})"
                         class="text-sm font-medium text-topol-blue-500 hover:text-topol-blue-600"
                     >{{ data }}</inertia-link>
                 </div>
             </template>

             <template #item-copy="{ row }">
                <div class="p-6">
                    <CopyPaymentRequestUrl :payment-request="row" />
                </div>
            </template>
 
             <template #item-type="{ data }">
                 <div
                     class="flex justify-center rounded-md px-2 py-[3px] text-center text-xs text-gray-500"
                     :class="{
                         'bg-orange-200': data === 'pro',
                         'bg-teal-200': data === 'plugin',
                     }"
                 >
                     {{ data }}
                 </div>
             </template>
 
             <template #item-total="{ data }">
                 <div class="p-6 text-gray-500">
                     ${{ data }}
                 </div>
             </template>
 
             <template #item-due_date="{ data }">
                 <div v-if="data" class="p-6 text-gray-600">
                     {{
                         format(
                             new Date(data as string),
                             'd. L. yyyy'
                         )
                     }}
                 </div>
             </template>
 
             <template #item-status="{ data }">
                 <div
                     class="flex justify-center rounded-md px-1 py-[3px] text-center text-xs text-gray-500"
                     :class="{
                         'bg-orange-300': data === 'pending',
                         'bg-teal-300': data === 'paid',
                         'bg-red-300': data === 'cancelled' || data === 'refunded' || data === 'failed',
                     }"
                 >
                     {{ data }}
                 </div>
             </template>
 
             <template #item-created_at="{ data }">
                 <div v-if="data" class="p-6 text-gray-600">
                     {{
                         format(
                             new Date(data as string),
                             'd. L. yyyy'
                         )
                     }}
                 </div>
             </template>
 
             <template #item-custom="{ row }">
                <div class="p-6">

                    <AtomicButton size="sm" color="yellow" @click="sendEmail(row.id as number)">
                        <template #icon>
                            <PaperAirplaneIcon
                                class="w-6 h-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </div>
       
            </template>
         </DataTable>
        </TeamTabs>
 </template>
 
 <script lang="ts" setup>
 import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
 import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
 import IPaymentRequest from '@/types/PaymentRequests/IPaymentRequest';
 import { format } from 'date-fns';
 import route from 'ziggy-js';
import { PropType } from 'vue';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { PaperAirplaneIcon } from '@heroicons/vue/24/outline';

 defineProps({
     paymentRequests: {
         type: Object as IPaginationData<IPaymentRequest>,
         required: true,
     },
    selectedTeam: {
        type: Object as PropType<ICurrentTeam>,
        required: true,
    },
 });
 
 const headers: IDataTableHeader[] = [
     {
         text: 'ID',
         id: 'id',
         sortable: true,
         sortableId: 'id',
     },
     { text: 'Link', id: 'copy', },
     {
         text: 'Team ID',
         id: 'team_id',
         sortable: true,
         sortableId: 'team_id',
     },
     {
         text: 'Transaction ID',
         id: 'transaction_id',
     },
     {
         text: 'Invoice ID',
         id: 'invoice_id',
     },
     {
         text: 'Name',
         id: 'name',
         sortable: true,
         sortableId: 'name',
     },
     {
         text: 'Type',
         id: 'type',
         sortable: true,
         sortableId: 'type',
     },
     {
         text: 'Total',
         id: 'total',
         sortable: true,
         sortableId: 'total',
     },
     {
         text: 'Due date',
         id: 'due_date',
         sortable: true,
         sortableId: 'due_date',
     },
     {
         text: 'Status',
         id: 'status',
         sortable: true,
         sortableId: 'status',
     },
     {
         text: 'Created',
         id: 'created_at',
         sortable: true,
         sortableId: 'created_at',
     },
     {
         text: 'Errors',
         id: 'errors',
     },
     { text: '', id: 'custom', },
 ];

const { show } = useConfirm();

const sendEmail = async (id: number) => {
    const confirmed = await show({
        type: 'info',
        header: 'Send email',
        message: 'Are you sure you want to send an email with payment request to the owner?',
        confirm: 'Send email',
    });

    if(confirmed){        
        try {
            const result = await axios.post(route('kiosk.payment-requests.email.store', { paymentRequest: id }));
            
            if (result.data.success) {
                create({
                title: 'Email sent',
                type: 'success',
            });
            }
        } catch (e) {            
            () => undefined;
        }
    }
};
 </script>
 