<template>
    <IntegrationModal
        title="Sendy"
        documentation-url="https://support.topol.io/en/articles/6386414-export-to-sendy-co"
        :invalid-validation="v$.$invalid"
        :loading="loading"
        :edit-mode="integration !== undefined"
        @close="$emit('close')"
        @create="createIntegration"
        @edit="updateIntegration"
    >
        <template #inputs>
            <div class="mb-6 w-full">
                <TextInput
                    v-model="v$.name.$model"
                    class="mt-6"
                    placeholder="Enter your preferred integration name"
                    label="Name"
                    :error="getError('name')"
                />

                <TextInput
                    v-model="v$.apiKey.$model"
                    class="mt-6 w-full"
                    placeholder="Your Sendy API key"
                    label="API key"
                    required
                    :error="getError('apiKey')"
                />

                <TextInput
                    v-model="v$.installationUrl.$model"
                    class="mt-6 w-full"
                    placeholder="https://sendy.yourdomain.test"
                    label="Installation url"
                    required
                    :error="getError('installationUrl')"
                />

                <TextInput
                    v-model="v$.fromName.$model"
                    class="mt-6 w-full"
                    placeholder="John Doe"
                    label="From name"
                    :error="getError('fromName')"
                />

                <TextInput
                    v-model="v$.fromEmail.$model"
                    class="mt-6 w-full"
                    placeholder="john@doe.test"
                    label="From email"
                    :error="getError('fromEmail')"
                />

                <TextInput
                    v-model="v$.replyTo.$model"
                    class="mt-6 w-full"
                    placeholder="john@doe.test"
                    label="Reply to"
                    :error="getError('replyTo')"
                />

                <TextInput
                    v-model="v$.brandId.$model"
                    class="mt-6 w-full"
                    placeholder="1"
                    label="Brand ID"
                    :error="getError('brandId')"
                />
            </div>
        </template>
    </IntegrationModal>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import { create } from '@composables/useNotifications';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import IInputErrors from '@/types/Atomic/IInputErrors';
import { PropType } from 'vue';
import { IIntegration, ISendy } from '@/types/Integrations/IIntegration';
import useIntegrations from '../../composables/useIntegrations';

const props = defineProps({
    integration: {
        type: Object as PropType<IIntegration<ISendy>>,
        required: false,
        default: undefined,
    },
});
const emit = defineEmits(['close']);

const loading = ref(false);
const errors = ref<IInputErrors>({});
const { sendyIntegrationModalOpened } = useIntegrations();
const state = reactive({
    name: props.integration?.name || '',
    apiKey: '',
    installationUrl: props.integration?.data.installationUrl || '',
    fromName: props.integration?.data.fromName || '',
    fromEmail: props.integration?.data.fromEmail || '',
    replyTo: props.integration?.data.replyTo || '',
    brandId: props.integration?.data.brandId || '',
});

const rules = {
    name: {
        maxLength: maxLength(255),
    },
    apiKey: {
        required,
        maxLength: maxLength(255),
    },
    installationUrl: {
        required,
        maxLength: maxLength(255),
    },
    fromName: {},
    fromEmail: {},
    replyTo: {},
    brandId: {},
};

const v$ = useVuelidate(rules, state);

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || errors.value?.[field] || '';
};

const createIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(
            route('integrations.sendy.store'),
            {
                name: state.name,
                apiKey: state.apiKey,
                installationUrl: state.installationUrl,
                fromName: state.fromName,
                fromEmail: state.fromEmail,
                replyTo: state.replyTo,
                brandId: state.brandId,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Integration added',
                        text: 'Sendy integration has been successfully added.',
                        type: 'success',
                    });
                    sendyIntegrationModalOpened.value = false;
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not added',
                        text: 'There was an error adding Sendy integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const updateIntegration = () => {
    v$.value.$touch();
    if (v$.value.$error || !props.integration) {
        () => undefined;
    } else {
        loading.value = true;
        router.put(
            route('integrations.sendy.update', {
                integration: props.integration.id,
            }),
            {
                name: state.name,
                apiKey: state.apiKey,
                installationUrl: state.installationUrl,
                fromName: state.fromName,
                fromEmail: state.fromEmail,
                replyTo: state.replyTo,
                brandId: state.brandId,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('close');
                    create({
                        title: 'Integration updated',
                        type: 'success',
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: 'Integration not updated',
                        text: 'There was an error updating Sendy integration.',
                        type: 'error',
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
