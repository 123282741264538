<template>
    <TableDropdown
        class="cursor-pointer"
        :is-open="isOpen"
        placement="bottom-start"
        @close="isOpen = false"
        @open="isOpen = true"
    >
        <template #dropdown-button>
            <button
                type="button"
                class="flex h-10 min-w-[160px] items-center justify-between rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-600 transition duration-100 ease-in-out hover:text-gray-800 hover:shadow-button focus:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-white"
            >
                <slot name="left-icon"> </slot>

                <p>{{ selected.title }}</p>

                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.0001 4.99984V5.00817M10.0001 9.99984V10.0082M10.0001 14.9998V15.0082M10.0001 5.83317C9.77907 5.83317 9.56711 5.74537 9.41083 5.58909C9.25455 5.43281 9.16675 5.22085 9.16675 4.99984C9.16675 4.77882 9.25455 4.56686 9.41083 4.41058C9.56711 4.2543 9.77907 4.1665 10.0001 4.1665C10.2211 4.1665 10.4331 4.2543 10.5893 4.41058C10.7456 4.56686 10.8334 4.77882 10.8334 4.99984C10.8334 5.22085 10.7456 5.43281 10.5893 5.58909C10.4331 5.74537 10.2211 5.83317 10.0001 5.83317ZM10.0001 10.8332C9.77907 10.8332 9.56711 10.7454 9.41083 10.5891C9.25455 10.4328 9.16675 10.2209 9.16675 9.99984C9.16675 9.77882 9.25455 9.56686 9.41083 9.41058C9.56711 9.2543 9.77907 9.1665 10.0001 9.1665C10.2211 9.1665 10.4331 9.2543 10.5893 9.41058C10.7456 9.56686 10.8334 9.77882 10.8334 9.99984C10.8334 10.2209 10.7456 10.4328 10.5893 10.5891C10.4331 10.7454 10.2211 10.8332 10.0001 10.8332ZM10.0001 15.8332C9.77907 15.8332 9.56711 15.7454 9.41083 15.5891C9.25455 15.4328 9.16675 15.2209 9.16675 14.9998C9.16675 14.7788 9.25455 14.5669 9.41083 14.4106C9.56711 14.2543 9.77907 14.1665 10.0001 14.1665C10.2211 14.1665 10.4331 14.2543 10.5893 14.4106C10.7456 14.5669 10.8334 14.7788 10.8334 14.9998C10.8334 15.2209 10.7456 15.4328 10.5893 15.5891C10.4331 15.7454 10.2211 15.8332 10.0001 15.8332Z"
                        stroke="#9FA6B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </template>
        <template #dropdown-content>
            <div
                class="right-0 z-20 my-1 max-h-[43vh] w-[284px] overflow-auto rounded-md bg-white shadow-lg"
            >
                <ul
                    tabindex="-1"
                    role="listbox"
                    class="rounded-md py-[5px] text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <li
                        v-for="(item, i) in items"
                        :key="i"
                        tabindex="0"
                        :class="{
                            'border-b border-gray-200':
                                item.subtitle && item.value !== 'viewer',
                        }"
                        role="option"
                        class="group relative cursor-pointer select-none py-2 pl-[44px] pr-3 text-base text-gray-700 hover:bg-gray-700 hover:text-white focus:outline-none"
                        @click="select(item.value)"
                    >
                        <p
                            class="block text-sm font-medium text-gray-800 truncate group-hover:text-white"
                        >
                            {{ item.title }}
                        </p>

                        <span
                            v-if="item.subtitle"
                            class="text-xs font-normal leading-normal text-gray-500 whitespace-normal group-hover:text-white"
                            >{{ item.subtitle }}</span
                        >

                        <span class="absolute flex items-center left-3 top-2">
                            <svg
                                v-show="isSelected(item.value)"
                                class="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </TableDropdown>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import IMemberDropdownData from '@/types/Members/IMemberDropdownData';

const props = defineProps({
    items: {
        type: Array as PropType<IMemberDropdownData[]>,
        default: () => [],
    },
    placeholder: {
        type: String,
        default: '',
    },
    selected: {
        type: Object as PropType<IMemberDropdownData>,
        required: true,
    },
});

const emit = defineEmits(['select']);

const isOpen = ref(false);

const isSelected = (value: string) => {
    return props.selected.value === value;
};

const select = (value: string) => {
    isOpen.value = false;
    emit('select', value);
};
</script>

<style></style>
