<template>
    <OpenAILogsTabs tab-key="all-logs" class="py-12">
        <DataTable
            class="mt-10"
            :headers="headers"
            :items="logs"
            title="Open AI logs"
            date-input
            searchable
        >
            <template #item-api_token_id="{ data }">
                <div class="p-6 text-gray-500">
                    {{ data }}
                </div>
            </template>

            <template #item-timestamp="{ data }">
                <div class="p-6 text-gray-500">
                    <div v-if="data">
                        {{ format(new Date(data as string), 'd. L. yyyy') }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <inertia-link
                    :href="route('kiosk.open-ai.user-per-team.index', { team: row.team_id as number })"
                    class="p-6 text-gray-500 hover:text-blue-500"
                >
                    <AtomicButton size="sm" color="white">
                        <template #icon>
                            <MagnifyingGlassIcon
                                class="w-6 h-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>
        </DataTable>
    </OpenAILogsTabs>
</template>

<script lang="ts" setup>
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IOpenAILog from '@/types/OpenAILogs/IOpenAILog';
import { format } from 'date-fns';
import route from 'ziggy-js';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

defineProps<{
    logs: IPaginationData<IOpenAILog>;
}>();

const headers: IDataTableHeader[] = [
    {
        text: 'Team ID',
        id: 'team_id',
        sortable: true,
        sortableId: 'team_id',
    },
    {
        text: 'Team name',
        id: 'team_name',
        sortable: true,
        sortableId: 'team_name',
    },
    {
        text: 'Api token ID',
        id: 'api_token_id',
        sortable: true,
        sortableId: 'api_token_id',
    },
    {
        text: 'User',
        id: 'api_token_user_id',
        sortable: true,
        sortableId: 'api_token_user_id',
    },
    {
        text: 'Hostname',
        id: 'hostname',
        sortable: true,
        sortableId: 'hostname',
    },
    {
        text: 'Total tokens',
        id: 'total_tokens',
        sortable: true,
        sortableId: 'total_tokens',
    },
    {
        text: 'Date',
        id: 'timestamp',
        sortable: true,
        sortableId: 'timestamp',
    },
    { text: '', id: 'custom' },
];
</script>
