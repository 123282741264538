export const formatBytes = (
    bytes: number,
    decimals = 1,
    to?: string
): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let i;

    if (to) {
        i = sizes.findIndex((size) => size === to);
    } else {
        i = Math.floor(Math.log(bytes) / Math.log(k));
    }

    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString(
            'cs-CZ'
        ) +
        ' ' +
        sizes[i]
    );
};
