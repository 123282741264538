import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from '@inertiajs/vue3';

export default () => {
    const globalProps = computed(() => usePage<ITopolPageProps>().props);

    const featureVisited = (value: string) => {
        if (!globalProps.value.user.new_features_visited) {            
            return false;
        } else {
            //@ts-ignore            
            return globalProps.value.user.new_features_visited[value];
        }
    };

    const setNewFeatureAsVisited = async (value: string) => {
        try{
            await axios.put(route('users.new-features-visited.update', { key: value }))
            
        } catch (e) {

            
           () => undefined;
        }
    }

    return {
        featureVisited,
        setNewFeatureAsVisited,
    };
};
