<template>
    <div>
        <div class="px-6 border-b border-gray-200">
            <TextInput
                v-model="v$.newBillingAddress.name.$model"
                required
                class="w-full -mt-1"
                label="Name and Surname"
                placeholder="Enter name and surname"
                :error="getError('name')"
            />

            <TextInput
                v-model="state.newBillingAddress.company"
                secondarylabel="Optional"
                class="w-full mt-5"
                label="Company"
                placeholder="Enter company name"
            />

            <div class="flex flex-wrap">
                <TextInput
                    v-model="state.newBillingAddress.tax_id"
                    class="w-full mt-5"
                    label="Tax ID"
                />
                <TextInput
                    v-model="state.newBillingAddress.crn"
                    class="w-full mt-5"
                    label="CRN"
                />
            </div>

            <SelectInput
                ref="SelectInput"
                v-model="state.newBillingAddress.country"
                class="w-full mt-5"
                placeholder="Select country"
                :data="countries"
                label="Country"
            />

            <TextInput
                v-model="state.newBillingAddress.street"
                class="w-full mt-5"
                label="Address"
            />

            <div class="flex flex-wrap">
                <TextInput
                    v-model="state.newBillingAddress.city"
                    class="w-full mt-5"
                    label="City"
                />
                <TextInput
                    v-model="
                        state.newBillingAddress.postal_code
                    "
                    class="w-full mt-5"
                    label="ZIP / Postal code"
                />
            </div>

            <TextInput
                v-model="v$.newBillingAddress.email.$model"
                optional="Used for sending invoices"
                class="w-full mt-5 pb-7"
                label="E-mail address"
                :error="getError('email')"
                required
            />

            <div class="flex justify-end">
                <div class="mb-4">
                    <AtomicButton
                        color="yellow"
                        size="sm"
                        :disabled="
                            loadingInvoiceDetails || v$.$invalid
                        "
                        @click="handleUpdateInvoiceDetails"
                    >
                        Save changes
                    </AtomicButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import BillingAddress from '@/types/Subscription/BillingAddress';
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';
import { PropType } from 'vue';
import IInputErrors from '@/types/Atomic/IInputErrors';
import ISelectable from '@/types/Atomic/ISelectable';

const props = defineProps({
    billingAddress: {
        required: true,
        type: Object as PropType<BillingAddress>,
    },
    countries: {
        required: true,
        type: Array as PropType<ISelectable[]>,
    },
});

const emit = defineEmits(['updatedBillingInformation', 'loadingInvoiceDetails']);

const loadingInvoiceDetails = ref(false);
const errors = ref<IInputErrors>({});

watch(
    () => loadingInvoiceDetails.value,
    (value) => {
        emit('loadingInvoiceDetails', value)
    }
)

const state = reactive({
    newBillingAddress: {
        name: props.billingAddress.name,
        email: props.billingAddress.email,
        company: props.billingAddress.company,
        tax_id: props.billingAddress.tax_id,
        crn: props.billingAddress.crn,
        country: props.billingAddress.country,
        street: props.billingAddress.street,
        city: props.billingAddress.city,
        postal_code: props.billingAddress.postal_code,
    },
});

const rules = {
    newBillingAddress: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
        },
    },
};

const v$ = useVuelidate(rules, state);

const updateBillingInformation = () => {
    if (props.billingAddress.type === 'pro') {
        router.put(
            route('billing-addresses.update-pro'),
            state.newBillingAddress,
            {
                preserveScroll: true,
                onFinish: () => {
                    emit('updatedBillingInformation');
                    loadingInvoiceDetails.value = false;
                },
            }
        );
    }
    if (props.billingAddress.type === 'plugin') {
        router.put(
            route('billing-addresses.update-plugin'),
            state.newBillingAddress,
            {
                preserveScroll: true,
                onFinish: () => {
                    emit('updatedBillingInformation');

                    loadingInvoiceDetails.value = false;
                },
            }
        );
    }
};

const handleUpdateInvoiceDetails = async () => {
    loadingInvoiceDetails.value = true;
    v$.value.$touch();
    if (v$.value.$error) {
        loadingInvoiceDetails.value = false;
    } else {
        updateBillingInformation();
    }
};

const getError = (field: string) => {
    return (
        v$.value.newBillingAddress[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ''
    );
};

</script>