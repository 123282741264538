<template>
    <div class="pb-5 mt-5 border-b border-gray-300 grid-cols-layout lg:grid">
        <div class="mb-6 lg:mt-4 lg:mb-0">
            <h3
                class="text-gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
            >
                Two Factor Authentication
            </h3>
            <p class="max-w-xs text-sm text-gray-500">
                Two-Factor Authentication (2FA) can be used to help protect your
                account from unauthorized access by requiring you to enter a
                security code when you sign in.
            </p>
        </div>
        <div class="w-full mx-auto bg-white rounded-md shadow-card lg:mx-0">
            <div class="px-6 pb-6 border-b">
                <p
                    v-if="!twoFactorEnabled"
                    class="pt-6 leading-normal text-gray-800 text-md"
                >
                    Two-Factor Authentication is
                    <span class="font-medium text-topol-red-600"
                        >not enabled</span
                    >
                </p>
                <p v-else class="pt-6 leading-normal text-gray-800 text-md">
                    Two-Factor Authentication is
                    <span class="font-medium text-green-600">enabled</span>
                </p>
                <p class="pt-6 text-sm leading-normal text-gray-500">
                    When Two-Factor Authentication (2FA) is enabled, when you
                    sign in you’ll be required to use the security code provided
                    by your Authenticator App of choice. You can learn more
                    about 2FA in our documentation.
                </p>

                <ConfirmsPassword
                    v-if="twoFactorEnabled"
                    @confirmed="showRecoveryCodes = true"
                >
                    <AtomicButton class="mt-6" color="white" size="sm">
                        Show recovery codes
                    </AtomicButton>
                </ConfirmsPassword>
                <ConfirmsPassword
                    v-if="twoFactorEnabled"
                    @confirmed="showPairingCodes = true"
                >
                    <AtomicButton class="mt-6 ml-3" color="white" size="sm">
                        Pairing QR code
                    </AtomicButton>
                </ConfirmsPassword>
            </div>

            <div class="flex justify-end">
                <div class="pr-6 my-3">
                    <ConfirmsPassword
                        v-if="!twoFactorEnabled"
                        @confirmed="showTwoFactorAuthentication = true"
                    >
                        <AtomicButton color="yellow" size="sm">
                            Enable 2FA for Sign in
                        </AtomicButton>
                    </ConfirmsPassword>

                    <ConfirmsPassword
                        v-else
                        @confirmed="disableTwoFactorAuthentication"
                    >
                        <AtomicButton
                            color="yellow"
                            size="sm"
                            :class="{ 'opacity-25': loading }"
                            :disabled="loading"
                        >
                            Disable 2FA for Sign in
                        </AtomicButton>
                    </ConfirmsPassword>
                </div>
            </div>
        </div>

        <TwoFactorAuthenticationModal
            v-if="showTwoFactorAuthentication"
            @close="showTwoFactorAuthentication = false"
            @enabled="enabled = true"
        />
        <TwoFactorAuthenticationRecoveryCodesModal
            v-if="showRecoveryCodes"
            @close="showRecoveryCodes = false"
        />
        <TwoFactorAuthenticationPairingCodesModal
            v-if="showPairingCodes"
            @close="showPairingCodes = false"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';

const globalProps = computed(() => usePage<ITopolPageProps>().props);
const loading = ref(false);
const showRecoveryCodes = ref(false);
const showTwoFactorAuthentication = ref(false);
const showPairingCodes = ref(false);
const enabled = ref(false);

const twoFactorEnabled = computed(() => {
    return globalProps.value.user.two_factor_enabled || enabled.value;
});

const disableTwoFactorAuthentication = () => {
    loading.value = true;
    router.delete('/user/two-factor-authentication', {
        preserveScroll: true,
        onSuccess: () => {
            loading.value = false;
            enabled.value = false;
        },
    });
};
</script>
