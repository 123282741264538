import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import { FunctionalComponent } from "vue";
import { MaybeRef } from "@vueuse/core";

import {
    NewspaperIcon,
    PuzzlePieceIcon,
    UserGroupIcon,
    RectangleStackIcon,
    Cog8ToothIcon,
    CreditCardIcon,
    UserIcon,
    UsersIcon,
    SwatchIcon,
    DocumentTextIcon,
    SpeakerWaveIcon,
    IdentificationIcon,
    ChartPieIcon,
    ChartBarIcon,
    CpuChipIcon,
    MusicalNoteIcon,
    ComputerDesktopIcon,
    CommandLineIcon,
    BuildingStorefrontIcon,
    CursorArrowRaysIcon,
    NoSymbolIcon,
    DocumentPlusIcon,
} from '@heroicons/vue/24/outline';

interface ILink {
    name: string;
    href: string;
    icon: FunctionalComponent;
    count?: MaybeRef<number>;
    id: string;
    tooltip?: string;
    new_feature_visited?: boolean;
}

const { shouldDisplayProPersonalSubscriptionBanner, isPluginSubscribed } = useBanners();
const { membersCount } = useTeamsAndMembers();
const { featureVisited } = useNewFeatures();

export default () => {
    const page = computed(() => usePage<ITopolPageProps>().url);
    const isPersonalTeam = computed(
        () => usePage<ITopolPageProps>().props.team.personal_team
        );
    const globalProps = computed(() => usePage<ITopolPageProps>().props);

    const linksApp = computed((): ILink[] => {
        const links: ILink[] = [
            {
                name: 'Templates',
                href: route('templates.index'),
                icon: NewspaperIcon,
                count: undefined,
                id: '/templates',
            },
            {
                name: 'Usage statistics',
                href: route('usage-statistics'),
                icon: ChartPieIcon,
                count: undefined,
                id: '/usage-statistics',
            },
            {
                name: 'Integrations',
                href: route('integrations.index'),
                icon: RectangleStackIcon,
                count: undefined,
                id: '/integrations',
            },
        ];
        if (
            isPersonalTeam.value
        ) {
            links.splice(1, 0, {
                name: 'Plugin',
                href: route('api-tokens.index'),
                icon: PuzzlePieceIcon,
                count: undefined,
                id: '/plugin',
            });

            if (!shouldDisplayProPersonalSubscriptionBanner.value && globalProps.value.team_role.key === 'owner') {
                links.splice(1, 0, {
                    name: 'Editor settings',
                    href: route('editor-preferences.appearance.index'),
                    icon: CursorArrowRaysIcon,
                    count: undefined,
                    id: '/editor-preferences',
                    new_feature_visited: !featureVisited('product_feeds'),
                });
            }
        }
        if (!isPersonalTeam.value || isPluginSubscribed.value) {
            links.splice(1, 0, {
                name: 'Members',
                href: route('members.index'),
                icon: UserGroupIcon,
                count: membersCount.value,
                id: '/members',
            });
        }
        if (globalProps.value.isAdmin) {
            links.splice(3, 0, {
                name: 'Marketplace',
                href: route('marketplace.all-templates.index'),
                icon: BuildingStorefrontIcon,
                count: undefined,
                id: '/marketplace',
            });
        }
            if (
                globalProps.value.team_role.key === 'owner' ||
                globalProps.value.team_role.key === 'admin'
            ) {
            links.splice(6, 0, {
                name: isPersonalTeam.value === true ? 'Settings' : 'Team Settings',
                href:
                    isPersonalTeam.value === true
                        ? route('settings.profile-settings.index')
                        : route('settings.team-settings.index'),
                icon: Cog8ToothIcon,
                count: undefined,
                id: '/settings',
            });
        }
        if (globalProps.value.team_role.key === 'owner') {
            links.splice(7, 0, {
                name: 'Billing',
                href: route('billing.pro-subscriptions.index'),
                icon: CreditCardIcon,
                count: undefined,
                id: '/billing/',
            });
        }
        return links;
    });

    const linksKiosk = computed((): ILink[] => {
        return [
            {
                name: 'Dashboard',
                href: route('kiosk.dashboard.index'),
                icon: ComputerDesktopIcon,
                id: '/kiosk/dashboard',
            },
            {
                name: 'Users',
                href: route('kiosk.users.index'),
                icon: UserIcon,
                id: '/kiosk/users',
            },
            {
                name: 'Teams',
                href: route('kiosk.teams.index'),
                icon: UsersIcon,
                id: '/kiosk/teams',
            },
            {
                name: 'Admins',
                href: route('kiosk.admins.index'),
                icon: IdentificationIcon,
                id: '/kiosk/admins',
            },
            {
                name: 'Subscriptions',
                href: route('kiosk.subscriptions.index'),
                icon: SwatchIcon,
                id: '/kiosk/subscriptions',
            },
            {
                name: 'Announcements',
                href: route('kiosk.announcements.index'),
                icon: SpeakerWaveIcon,
                id: '/kiosk/announcements',
            },
            {
                name: 'Api Tokens',
                href: route('kiosk.api-tokens.index'),
                icon: PuzzlePieceIcon,
                id: '/kiosk/tokens',
            },
            {
                name: 'Open AI Stats',
                href: route('kiosk.open-ai.index'),
                icon: CommandLineIcon,
                id: '/kiosk/open-ai',
            },
            {
                name: 'Premade templates',
                href: route('kiosk.premade-templates.index'),
                icon: NewspaperIcon,
                id: '/kiosk/premade-templates',
            },
            {
                name: 'Metrics',
                href: route('kiosk.metrics.api-tokens.index'),
                icon: ChartBarIcon,
                id: '/kiosk/metrics',
            },
            {
                name: 'Blocked resources',
                href: route('kiosk.blocked-domains.index'),
                icon: NoSymbolIcon,
                id: '/kiosk/blocked-resources',
            },
            {
                name: 'Invoices',
                href: route('kiosk.invoices.index'),
                icon: DocumentTextIcon,
                id: '/kiosk/invoices',
            },
            {
                name: 'Payment requests',
                href: route('kiosk.payment-requests.index'),
                icon: DocumentPlusIcon,
                id: '/kiosk/payment-requests',
            },
            {
                name: 'Testing logs',
                href: route('kiosk.testing-logs.index'),
                icon: CpuChipIcon,
                id: '/kiosk/testing-logs',
            },
            {
                name: 'Lambdas',
                href: route('kiosk.lambdas.index'),
                icon: MusicalNoteIcon,
                id: '/kiosk/lambdas',
            },
        ];
    });

    const links = computed(() => {
        return page.value.startsWith("/kiosk")
            ? linksKiosk.value
            : linksApp.value;
    });

    return {
        links,
    };
};
