<template>
    <div>
        <Modal v-if="proSubscription" size="xl" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">Change subscription</div>
            </template>

            <template #body>
                <div class="px-6 pt-3 pb-6">
                    <div
                        class="flex flex-col rounded bg-blue-100 px-[14px] pt-3 pb-2.5 sm:flex-row"
                    >
                        <div>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10V10ZM11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6ZM9 9C8.73478 9 8.48043 9.10536 8.29289 9.29289C8.10536 9.48043 8 9.73478 8 10C8 10.2652 8.10536 10.5196 8.29289 10.7071C8.48043 10.8946 8.73478 11 9 11V14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14C12 13.7348 11.8946 13.4804 11.7071 13.2929C11.5196 13.1054 11.2652 13 11 13V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9H9Z"
                                    fill="#3182CE"
                                />
                            </svg>
                        </div>
                        <div
                            class="mt-2 text-sm text-blue-600 sm:mt-0 sm:ml-[14px]"
                        >
                            You are currently using
                            {{
                                proSubscription.period === 1
                                    ? 'Month-to-month'
                                    : 'Annual'
                            }}
                            subscription plan for Topol
                            {{ team.personal_team ? 'PRO' : 'Team' }}, including
                            <span v-if="!team.personal_team">
                                {{ proSubscription.totalPaidSeats }} pre-paid
                                member seats.
                            </span>
                            <span v-else> 1 member seat. </span>
                            <span
                                v-if="
                                    !proSubscription.canceled_at &&
                                    proSubscription.next_billing_date
                                "
                            >
                                Your plan will renew on
                                {{
                                    format(
                                        new Date(
                                            proSubscription.next_billing_date
                                        ),
                                        'd MMMM, yyyy'
                                    )
                                }}.
                            </span>
                        </div>
                    </div>
                    <div
                        class="px-6 pt-4 pb-6 mt-5 border border-gray-200 rounded-md bg-gray-50"
                    >
                        <SelectInput
                            ref="selectInput"
                            v-model="selected"
                            :data="proPlans"
                            label="Choose your subscription"
                            placeholder="Choose subscription"
                            class="max-w-[400px]"
                            @update:modelValue="getPreparedSubscription"
                        />

                        <div
                            v-if="selected === proSubscription.plan?.id"
                            class="mt-3"
                        >
                            <div
                                class="text-2xl leading-normal tracking-tighter text-gray-800"
                            >
                                <span class="font-bold">
                                    ${{ proSubscription.amount_with_tax / 100 }}
                                </span>
                                <span
                                    >/{{
                                        proSubscription.period === 1
                                            ? 'month'
                                            : 'year'
                                    }}
                                </span>
                            </div>
                            <div
                                v-if="!team.personal_team"
                                class="text-sm text-gray-500"
                            >
                                <span v-if="proSubscription.period === 1"
                                    >$15</span
                                >
                                <span v-if="proSubscription.period !== 1"
                                    >$10</span
                                >
                                per seat per 1 month<br />
                                for {{ proSubscription.totalPaidSeats }} team
                                seats (3 included)
                            </div>

                            <div v-else class="text-sm text-gray-500">
                                for your personal account
                            </div>

                            <div
                                v-if="!proSubscription.canceled_at"
                                class="mt-[18px] text-sm"
                            >
                                <div class="text-gray-500">
                                    {{
                                        proSubscription.period === 1
                                            ? 'Month-to-month'
                                            : 'Annual'
                                    }}
                                    plan renews on
                                </div>

                                <div
                                    v-if="proSubscription.next_billing_date"
                                    class="font-medium text-gray-800"
                                >
                                    {{
                                        format(
                                            new Date(
                                                proSubscription.next_billing_date
                                            ),
                                            'd MMMM, yyyy'
                                        )
                                    }}
                                </div>
                            </div>
                            <div v-else class="mt-[18px] text-sm text-gray-500">
                                <div>
                                    Subscription canceled, will be active until
                                </div>
                                <div class="font-medium text-gray-800">
                                    {{
                                        format(
                                            new Date(
                                                proSubscription.canceled_at
                                            ),
                                            'd MMMM, yyyy'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>

                        <div v-if="proSubscription.plan?.id !== selected">
                            <div class="mt-4 text-sm text-gray-500">
                                <span v-if="selected === 1 || selected === 9">
                                    When switching to annual billing,
                                    <span class="font-medium text-gray-800"
                                        >you will be able to use Topol PRO
                                        features
                                        <span v-if="!team.personal_team">
                                            for
                                            {{ proSubscription.totalPaidSeats }}
                                            member seats
                                        </span>
                                        starting immediately.</span
                                    >
                                    After this date you will be billed on annual
                                    basis.
                                </span>
                                <span v-if="selected === 2 || selected === 10"
                                    >When switching to month-to-month billing,
                                    <span
                                        v-if="proSubscription.next_billing_date"
                                        class="font-medium text-gray-800"
                                        >you will be able to use Topol PRO
                                        features
                                        <span v-if="!team.personal_team">
                                            for
                                            {{ proSubscription.totalPaidSeats }}
                                            member seats
                                        </span>
                                        without change until
                                        {{
                                            format(
                                                new Date(
                                                    proSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}.</span
                                    >
                                    After this date you will be billed on
                                    month-to-month basis.</span
                                >
                                <span v-if="selected === 3"
                                    >When switching to free plan,
                                    <span
                                        v-if="proSubscription.next_billing_date"
                                        class="font-medium text-gray-800"
                                        >you will be able to use Topol PRO
                                        <span v-if="!team.personal_team">
                                            for
                                            {{ proSubscription.totalPaidSeats }}
                                            member seats
                                        </span>
                                        {{
                                            format(
                                                new Date(
                                                    proSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}.</span
                                    >
                                    After this date you will be switched to FREE
                                    plan without PRO and Team features.</span
                                >

                                <p class="mt-2">Note that after your subscription is canceled, you will lose access to the images saved in our storage.</p>
                            </div>
                            <div v-if="selected !== 3 && preparedSubscription">
                                <div
                                    class="mt-[22px] flex items-start gap-2 text-2xl leading-normal tracking-tighter text-gray-800"
                                >
                                    <div>
                                        <span
                                            class="font-bold text-gray-500 line-through"
                                            >${{
                                                preparedSubscription.total_without_sale /
                                                100
                                            }}</span
                                        >
                                    </div>
                                    <div>
                                        <span class="font-bold"
                                            >${{
                                                preparedSubscription.total / 100
                                            }}</span
                                        >
                                        <span
                                            >/{{
                                                preparedSubscription
                                                    .subscription_plan
                                                    .period === 1
                                                    ? 'month'
                                                    : 'year'
                                            }}
                                        </span>
                                    </div>

                                    <!-- <div
                                        v-if="preparedSubscription.subscription_plan.period === 12"
                                        class="inline-flex rounded-md bg-black px-3 py-[3px] text-xs font-medium text-white uppercase font-bold tracking-wide"
                                    >
                                        Black friday deal
                                    </div> -->
                                </div>
                                <div
                                    v-if="!team.personal_team"
                                    class="text-sm text-gray-500"
                                >
                                    {{
                                        preparedSubscription.subscription_plan
                                            .period === 1
                                            ? '$15'
                                            : '$10'
                                    }}
                                    per seat per 1 month<br />
                                    for
                                    {{
                                        preparedSubscription.additional_users_to_pay_count +
                                        3
                                    }}
                                    team seats (3 included)
                                </div>

                                <div v-else class="text-sm text-gray-500">
                                    for your personal account
                                </div>

                                <div class="mt-5 text-sm text-gray-500">
                                    <div>
                                        {{
                                            preparedSubscription.period === 1
                                                ? 'Month-to-month'
                                                : 'Annual'
                                        }}
                                        billing starts on
                                    </div>
                                    <div
                                        v-if="proSubscription.next_billing_date"
                                        class="font-medium text-gray-800"
                                    >
                                        {{
                                            format(
                                                new Date(
                                                    proSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="selected === 3">
                                <div
                                    class="mt-[22px] text-2xl font-bold leading-normal tracking-tighter text-gray-800"
                                >
                                    Topol FREE
                                </div>
                                <div class="text-sm text-gray-500">
                                    1 user, without PRO and Team features.
                                </div>
                                <div class="mt-5 text-sm text-gray-500">
                                    <div>Free plan starts on</div>
                                    <div
                                        v-if="proSubscription.next_billing_date"
                                        class="font-medium text-gray-800"
                                    >
                                        {{
                                            format(
                                                new Date(
                                                    proSubscription.next_billing_date
                                                ),
                                                'd MMMM, yyyy'
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5">
                        <CheckboxInput
                            v-model:checked="confirmation"
                            size="md"
                            :disabled="
                                proSubscription.plan?.id === selected &&
                                !proSubscription.canceled_at
                            "
                            name="confirm"
                            :label="
                                selected !== 3
                                    ? 'Confirm that you want to change your subscription'
                                    : 'Confirm that you want to CANCEL your subscription'
                            "
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between p-6 border-t border-gray-200">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>

                    <AtomicButton
                        :disabled="
                            (proSubscription.plan?.id === selected &&
                                !proSubscription.canceled_at) ||
                            !confirmation ||
                            loading ||
                            (!preparedSubscription && selected !== 3)
                        "
                        size="md"
                        color="yellow"
                        @click="handleChange"
                    >
                        <span v-if="selected !== 3">Change subscription</span>
                        <span v-else
                            ><span class="sm:hidden">Cancel</span>
                            <span class="hidden sm:block"
                                >Cancel subscription and switch to FREE
                                plan</span
                            ></span
                        >
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
import ISubscription from '@/types/Subscription/ISubscription';
import { format } from 'date-fns';
import { computed, onMounted, ref } from 'vue';
import route from 'ziggy-js';
import axios from 'axios';
import IApiResponse from '@/types/IApiResponse';
import PreparedSubscription from '@/types/Subscription/PreparedSubscription';
import { router } from '@inertiajs/vue3';
import { create } from '@/composables/useNotifications';
import { IPaymentMethod } from '@/types/Subscription/IPaymentMethod';
import { usePage } from '@inertiajs/vue3';
import { ITopolPageProps } from '@/types/TopolPageProps/TopolPageProps';
import { verifyCardByToken } from '@/composables/useBilling';

const emit = defineEmits(['close', 'cancel']);

const props = defineProps<{
    proSubscription: ISubscription | null;
    selectedCard?: IPaymentMethod;
}>();

const team = computed(() => usePage<ITopolPageProps>().props.team);

onMounted(() => {
    if (
        props.proSubscription?.plan?.id === 1 ||
        props.proSubscription?.plan?.id === 9
    ) {
        selected.value = team.value.personal_team ? 1 : 9;
        proPlans[0].title += ' (current plan)';
    } else {
        selected.value = team.value.personal_team ? 2 : 10;
        proPlans[1].title += ' (current plan)';
    }
});

const confirmation = ref(false);

const proPlans = [
    {
        title: team.value.personal_team
            ? 'Topol PRO - Annual billing'
            : 'Topol Team - Annual billing',
        value: team.value.personal_team ? 1 : 9,
    },
    {
        title: team.value.personal_team
            ? 'Topol PRO - Month-to-month billing'
            : 'Topol Team - Month-to-month billing',
        value: team.value.personal_team ? 2 : 10,
    },
    {
        title: 'Topol FREE - Free plan',
        value: 3,
    },
];

const selected = ref(team.value.personal_team ? 1 : 9);
const selectInput = ref();
const preparedSubscription = ref();
const loading = ref(false);

const handleChange = () => {
    if (
        selected.value !== 3 &&
        selected.value !== props.proSubscription?.plan?.id
    ) {
        changeSubscription();
    }
    if (selected.value === 3) {
        cancelSubscription();
    }
    if (
        selected.value === props.proSubscription?.plan?.id &&
        props.proSubscription.canceled_at
    ) {
        resumeSubscription();
    }
};

const changeSubscription = async () => {    
    if (!props.selectedCard === null || props.proSubscription === null) {
        return;
    }
    loading.value = true;

    const verifyCardResponse = await verifyCardByToken(
        props.selectedCard?.token,
        'pro',
        preparedSubscription.value.total
    );

    if (verifyCardResponse === undefined) {
        loading.value = false;
        throw new Error('Card verification failed');
    }
    if (
        verifyCardResponse.liabilityShifted === false &&
        verifyCardResponse.liabilityShiftPossible === true
    ) {
        loading.value = false;
        create({
            title: 'Subscription not created',
            text: 'There was an error with 3D secure authentication.',
            type: 'error',
        });
        return;
    }

    router.post(
        route('subscriptions.switch', {
            subscription: props.proSubscription.id,
        }),
        {
            subscription_plan_id: selected.value,
            nonce: verifyCardResponse.nonce,
        },
        {
            preserveState: false,
            onSuccess: () => {
                create({
                    title: 'Subscription switched',
                    text: 'Your subscription has been successfully updated.',
                    type: 'success',
                });
                emit('close');
            },
            onError: (err) => {
                create({
                    title: 'Subscription not switched',
                    text: 'There was an error updating your subscription.',
                    type: 'error',
                });
                if (err.error && err.error[0] === 'users-bug') {
                    create({
                        title: 'Subscription not switched',
                        text: 'Please contact our support at tereza@topol.io to switch your subscription.',
                        type: 'info',
                    });
                }
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const cancelSubscription = () => {
    if (!props.proSubscription) {
        return;
    }
    loading.value = true;
    router.post(
        route('subscriptions.cancel', {
            subscription: props.proSubscription.id,
        }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Subscription canceled',
                    text: 'Your PRO subscription has been successfully canceled.',
                    type: 'success',
                });
                emit('cancel');
            },
            onError: () => {
                create({
                    title: 'Subscription not canceled',
                    text: 'There was an error canceling your PRO subscription.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const resumeSubscription = () => {
    if (!props.proSubscription) {
        return;
    }
    loading.value = true;
    router.post(
        route('subscriptions.resume', {
            subscription: props.proSubscription.id,
        }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: 'Subscription extended',
                    text: 'Your PRO subscription has been successfully extended.',
                    type: 'success',
                });
                emit('close');
            },
            onError: () => {
                create({
                    title: 'Subscription not extended',
                    text: 'There was an error extending your PRO subscription.',
                    type: 'error',
                });
            },
            onFinish: () => {
                loading.value = false;
            },
        }
    );
};

const getPreparedSubscription = async () => {
    if (
        selected.value !== 3 &&
        selected.value !== props.proSubscription?.plan?.id
    ) {
        const res = await axios.get<IApiResponse<PreparedSubscription>>(
            route('subscriptions.prepare', {
                subscription_plan_id: selected.value,
            })
        );
        preparedSubscription.value = res.data.data;
    }
};
</script>