<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">
        <template #header>
            <inertia-link :href="route('kiosk.users.index')">
                <AtomicButton color="yellow" class="mt-4" size="sm">
                    Back to All Users
                    <template #icon>
                        <BackspaceIcon
                            class="w-6 h-6 mr-2 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </inertia-link>
    
            <UserTabsProfile
                :selected-user="selectedUser"
                :personal-team="personalTeam"
            />
        </template>
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import {
    BackspaceIcon,
    PuzzlePieceIcon,
    UserGroupIcon,
    DocumentTextIcon,
    DocumentIcon,
    AdjustmentsVerticalIcon,
    ShieldExclamationIcon,
} from '@heroicons/vue/24/solid';

import {
    ClipboardDocumentListIcon,
    CreditCardIcon,
    DocumentPlusIcon,
    MegaphoneIcon,
} from '@heroicons/vue/24/outline';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import IUser from '@/types/Members/IUser';
import ICurrentTeam from '@/types/Members/ICurrentTeam';
import { PropType } from 'vue';

const props = defineProps({
    tabKey: {
        type: String,
        default: 'user-activity',
    },
    selectedUser: {
        type: Object as PropType<IUser>,
        required: true,
    },
    personalTeam: {
        type: Object as PropType<ICurrentTeam>,
        required: true,
    },
});

const tabs: TabItem[] = [
    {
        title: 'Activity',
        key: 'user-activity',
        icon: ClipboardDocumentListIcon,
        url: route('kiosk.users.activity.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Notifications',
        key: 'user-notifications',
        icon: MegaphoneIcon,
        url: route('kiosk.users.notifications.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Features',
        key: 'feature-management',
        icon: AdjustmentsVerticalIcon,
        url: route('kiosk.users.feature-management.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Blocked Actions',
        key: 'user-blocked-actions',
        icon: ShieldExclamationIcon,
        url: route('kiosk.users.blocked-actions.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Teams',
        key: 'user-teams',
        icon: UserGroupIcon,
        url: route('kiosk.users.teams.index', { user: props.selectedUser.id }),
    },
    {
        title: 'API tokens',
        key: 'user-api-tokens',
        icon: PuzzlePieceIcon,
        url: route('kiosk.users.api-tokens.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Invoices',
        key: 'user-invoices',
        icon: DocumentTextIcon,
        url: route('kiosk.users.invoices.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Legacy Invoices',
        key: 'legacy-invoices',
        icon: DocumentIcon,
        url: route('kiosk.users.legacy-invoices.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Transactions',
        key: 'transactions',
        icon: CreditCardIcon,
        url: route('kiosk.users.transactions.index', {
            user: props.selectedUser.id,
        }),
    },
    {
        title: 'Payment requests',
        key: 'payment-requests',
        icon: DocumentPlusIcon,
        url: route('kiosk.users.payment-requests.index', {
            user: props.selectedUser.id,
        }),
    }
];
</script>
