<template>
   <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Integrations">
        <slot />
        <IntegrationModals />

    </BasicTabs>
</template>

<script lang="ts" setup>
import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import { CheckCircleIcon, RectangleStackIcon } from '@heroicons/vue/24/outline';

interface DataTableProps {
    tabKey: string;
}

withDefaults(defineProps<DataTableProps>(), {
    tabKey: 'active',
});

const tabs: TabItem[] = [
    {
        title: 'Active',
        key: 'active',
        icon: CheckCircleIcon,
        url: route('integrations.index'),
    },
    {
        title: 'Available',
        key: 'available',
        icon: RectangleStackIcon,
        url: route('integrations.available.index'),
    },
];
</script>
