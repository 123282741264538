<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">

        <template #header>
            <div class="flex flex-wrap justify-between gap-4">
            <h1
                class="text-2xl font-medium leading-normal text-gray-800 tracking-small"
            >
                Template Manager
            </h1>

            <div class="flex flex-row flex-wrap gap-4">
                <inertia-link :href="route('marketplace.all-templates.index')">
                    <AtomicButton color="white" size="lg" class="flex-shrink-0">
                        <template #icon>
                            <BuildingStorefrontIcon class="w-5 h-5 mr-2" />
                        </template>
                        Marketplace
                    </AtomicButton>
                </inertia-link>

                <AtomicButton
                    data-testid="create-new-template-button"
                    color="yellow"
                    size="lg"
                    @click="showAddTemplateModal = true"
                >
                    <template #icon>
                        <div class="mr-2">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 6.5V9M9 9V11.5M9 9H11.5M9 9H6.5M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
                                    stroke="#252F3F"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </template>
                    Add template
                </AtomicButton>
            </div>
        </div>
        </template>

        <slot />

        <NewMarketplaceTemplateModal
            v-if="showAddTemplateModal"
            :categories="categories"
            :keywords="keywords"
            @close="showAddTemplateModal = false"
            @template-created="fetchData"
        />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import route from 'ziggy-js';
import TabItem from '@/types/Settings/TabItem';
import {
    ArchiveBoxIcon,
    BuildingStorefrontIcon,
    NewspaperIcon,
} from '@heroicons/vue/24/outline';

defineProps({
    categories: {
        type: Array,
        required: true,
    },
    keywords: {
        type: Array,
        required: true,
    },
    tabKey: {
        type: String,
        required: true,
        default: 'manager',
    },
});

const tabs: TabItem[] = [
    {
        title: 'Templates',
        key: 'manager',
        icon: NewspaperIcon,
        url: route('marketplace.created-templates.index'),
    },
    {
        title: 'Archive',
        key: 'archive',
        icon: ArchiveBoxIcon,
        url: route('marketplace.created-archived-templates.index'),
    },
];

const showAddTemplateModal = ref(false);

const { fetchData } = useMarketplaceTemplates('created');
</script>
