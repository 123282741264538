<template>
    <div>
        <div
            v-for="token in tokens"
            :key="token.id"
            class="w-full mb-10 bg-white rounded-md shadow-card"
        >
            <div class="px-6 pt-4 pb-6">
                <div
                    class="flex flex-wrap items-center justify-between mb-7 sm:flex-nowrap"
                >
                    <h4 class="font-medium leading-normal text-gray-800">
                        {{ token.name }}
                    </h4>

                    <AtomicButton
                        color="white"
                        size="sm"
                        data-cy="plugin-settings-remove-token"
                        @click="$emit('selectModal', token.id)"
                    >
                        Remove token
                    </AtomicButton>
                </div>

                <div
                    class="flex flex-wrap items-start justify-between sm:flex-nowrap"
                >
                    <div
                        class="mb-4 mr-2 flex min-w-[180px] flex-col text-sm leading-normal sm:mb-0"
                    >
                        <p class="text-gray-500">Last active (UTC)</p>
                        <p
                            v-if="token.last_used_at"
                            class="font-medium text-gray-800"
                        >
                            {{
                                format(
                                    new Date(token.last_used_at),
                                    "KK:mm:ss a '&nbsp;' d'&nbsp;'LLL,'&nbsp;'yyyy"
                                )
                            }}
                        </p>
                        <p v-else>Never</p>

                        <button
                            type="button"
                            class="justify-left mt-[3px] flex font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            @click="$emit('showCopyTokenModal', token.id)"
                        >
                            Show api key
                        </button>

                        <inertia-link 
                        v-if="globalProps.team.owner_id === globalProps.user.id"
                        href="/billing/invoices"
                            ><p
                                class="mt-[19px] font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            >
                                See invoices
                            </p></inertia-link
                        >
                        
                        <inertia-link 
                        v-if="globalProps.team.owner_id === globalProps.user.id"
                        href="/billing/plugin-subscriptions"
                            ><p
                                class="mt-[3px] font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            >
                                {{
                                    isPluginSubscribed
                                        ? 'Your active subscription'
                                        : 'Get your subscription'
                                }}
                            </p></inertia-link
                        >
                    </div>

                    <div
                        class="relative mb-4 mr-2 flex min-w-[180px] flex-col text-sm leading-normal sm:mb-0"
                    >
                        <p class="text-gray-500">Data traffic</p>

                        <a
                            :href="
                                route('usage-statistics', {
                                    apiToken: token.id,
                                })
                            "
                            target="_blank"
                        >
                            <p
                                class="font-medium text-topol-blue-500 hover:text-topol-blue-600"
                            >
                                See data traffic details
                            </p>
                        </a>
                    </div>

                    <div
                        class="relative mb-4 mr-2 flex min-w-[180px] flex-col text-sm leading-normal sm:mb-0"
                    >
                        <p class="text-gray-500">OpenAI Text Assistant</p>

                        <p
                            class="font-medium cursor-pointer text-topol-blue-500 hover:text-topol-blue-600"
                            @click="$emit('openAISettingsModal', token.id)"
                        >
                            Edit your settings
                        </p>
                    </div>
                </div>
                <TokenSettings
                    :key="token.id"
                    :token="token"
                    @googleModal="$emit('googleModal', token.id)"
                    @amazonModal="$emit('amazonModal', token.id)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import AtomicButton from '@atomic/Buttons/AtomicButton.vue';
import { format } from 'date-fns';
import { PropType, ref } from 'vue';
import IToken from '@/types/Plugin/IToken';
import TokenSettings from './TokenSettings.vue';
import route from 'ziggy-js';

defineProps({
    tokens: {
        type: Array as PropType<IToken[]>,
        default: () => [],
    },
});

defineEmits([
    'selectModal',
    'googleModal',
    'amazonModal',
    'openAISettingsModal',
    'showCopyTokenModal',
]);

onMounted(() => {
    getIsPluginSubscribed();
});

const globalProps = computed(() => usePage<ITopolPageProps>().props);

const isPluginSubscribed = ref(false);

const getIsPluginSubscribed = async () => {
    try {
        const response = await axios.get(route('user.plugin-subscribed.index'));

        if (response.data.success) {
            isPluginSubscribed.value = response.data.data;
        }
    } catch (err) {
        () => undefined;
    }
};
</script>

<style></style>
