<template>
    <TemplateManagerTabs
        class="pb-80"
        tab-key="archive"
        :categories="categories"
        :keywords="keywords"
    >
        <div v-if="!isLoading" class="mt-6 flex flex-col bg-white">
            <MarketplaceTemplateManagerTemplateListBox
                v-for="template in templates"
                :key="template.id"
                :template="template"
                @restore="handleRestoreTemplate"
            />
        </div>

        <div v-if="!isLastPage" class="mt-10 flex w-full justify-center">
            <AtomicButton
                :disabled="isLoading"
                color="white"
                @click="loadNextPage"
                >Load more</AtomicButton
            >
        </div>

        <div v-if="templates.length === 0 && !isLoading">
            <div
                class="mt-6 flex w-full flex-col-reverse items-center justify-center rounded-[4px] border border-gray-200 text-center sm:flex sm:flex-row sm:text-left"
            >
                <div>
                    <h2
                        class="mb-2 max-w-md text-2xl font-medium text-gray-600"
                    >
                        You have no archived templates.
                    </h2>
                </div>
                <div class="mt-10 ml-4 flex-shrink-0">
                    <img
                        class="h-[240px] w-[240px]"
                        src="https://d5aoblv5p04cg.cloudfront.net/application/images/illustrations-empty.png"
                        alt="No templates added yet"
                    />
                </div>
            </div>
        </div>
    </TemplateManagerTabs>
</template>

<script lang="ts" setup>
import IMarketplaceTemplate from '@/types/Marketplace/IMarketplaceTemplate';
import { PropType } from 'vue';
import ICategory from '@/types/Templates/ICategory';
import IKeyword from '@/types/Templates/IKeyword';
import route from 'ziggy-js';
import { create } from '@/composables/useNotifications';

defineProps({
    categories: {
        type: Array as PropType<ICategory[]>,
        required: true,
    },
    keywords: {
        type: Array as PropType<IKeyword[]>,
        required: true,
    },
});

const { templates, fetchData, isLastPage, isLoading, loadNextPage } =
    useMarketplaceTemplates('created-archived');

onBeforeMount(async () => {
    await fetchData();
});

const { show } = useConfirm();

const handleRestoreTemplate = async (template: IMarketplaceTemplate) => {
    const confirmed = await show({
        type: 'info',
        header: 'Restore template',
        message:
            'Are you sure you want to restore the template? This will make it available for use again.',
    });

    if (confirmed) {
        router.post(
            route('marketplace.created-archived-templates.store'),
            {
                marketplace_template_id: template.id,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: 'Template restored',
                        type: 'success',
                    });
                    fetchData();
                },
                onError: () => {
                    create({
                        title: 'Failed to restore template',
                        type: 'error',
                    });
                },
            }
        );
    }
};
</script>
