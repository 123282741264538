<template>
    <BlockedResourcesTabs tab-key="blocked-buckets">
        <DataTable class="mt-5" :items="buckets" :headers="headers" searchable>
            <template #item-bucket_blocked_at="{ data }">
                <div v-if="data" class="p-6 text-gray-600">
                    {{ format(new Date(data as string), 'd. L. yyyy HH:mm') }}
                </div>
            </template>

            <template #item-custom_show="{ row }">
                <inertia-link
                    :href="row.model_type === 'user' ?
                        route('kiosk.users.activity.index', {
                            user: row.user_id as number,
                        }) : row.model_type === 'team' ? route('kiosk.users.teams.index', {
                            user: row.user_id as number,
                        }) : route('kiosk.users.api-tokens.index', {
                            user: row.user_id as number,
                        })
                    "
                >
                    <AtomicButton size="sm" color="white">
                        <template #icon>
                            <MagnifyingGlassIcon
                                class="h-6 w-6 transition duration-200 group-hover:text-gray-600"
                            />
                        </template>
                    </AtomicButton>
                </inertia-link>
            </template>
        </DataTable>
    </BlockedResourcesTabs>
</template>

<script lang="ts" setup>
import DataTable from '@/components/Atomic/DataTable/DataTable.vue';
import route from 'ziggy-js';
import IDataTableHeader from '@/components/Atomic/DataTable/types/IDataTableHeader';
import IPaginationData from '@/components/Atomic/DataTable/types/IPaginationData';
import { format } from 'date-fns';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import IBlockedBucket from '@/types/BlockedResources/IBlockedBucket';

interface IError {
    domain?: string;
}

defineProps<{
    errors: IError;
    buckets: IPaginationData<IBlockedBucket>;
}>();

const headers: IDataTableHeader[] = [
    { text: 'ID', id: 'id' },
    { text: 'Name', id: 'name' },
    {
        text: 'Type',
        id: 'model_type',
        sortable: true,
        sortableId: 'model_type',
    },
    {
        text: 'Blocked at',
        id: 'bucket_blocked_at',
        sortable: true,
        sortableId: 'bucket_blocked_at',
    },
    { text: '', id: 'custom_show' },
];
</script>

<style></style>
