import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default () => {
    const startEcho = () => { 
        if (window.Pusher !== undefined && window.Echo !== undefined) {
            return;
        }   
                
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'beabf7065bb2c3bb61c7',
            // key: '10850db7fe1b40c0dd98', //test key 
            cluster: 'eu',
            forceTLS: true,
        });
    }

    const stopEcho = () => {
        window.Echo?.disconnect();

        if(window.Echo !== undefined){
            window.Echo = undefined;
        }
    }

    return {
        startEcho,
        stopEcho
    };
};
